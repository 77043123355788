import React, { Fragment } from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { FieldArray } from "react-final-form-arrays"
import { withStyles } from "@material-ui/core/styles"
import { Flex, Box } from "rebass"
import {
  styles,
  StyledTableAction,
  StyledTableCell,
  StyledTableRow,
  StyledTableSmall,
  StyledTableCellRight,
} from "../../po/components/styles"
import RowPoItem from "./RowPoItem"
// import ListPOItems from "./ListPOItems"

const renderOptionsList = (
  { fields, meta: { error, submitFailed } },
  props
) => {
  return (
    <Fragment>
      {fields.map((item, index) => (
        <StyledTableRow key={index}>
          <RowPoItem {...props} item={item} index={index} fields={fields} />
        </StyledTableRow>
      ))}
    </Fragment>
  )
}

const FormFields = (props) => {
  const { translate, classes } = props
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <Box width={[1]} py={[3]}>
        <TableContainer component={"div"}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  {translate("resources.inventory.fields.name")}
                </StyledTableCell>
                <StyledTableCellRight>
                  {translate("resources.inventory.fields.available")}
                </StyledTableCellRight>
                <StyledTableCellRight>
                  {translate("resources.inventory.fields.ordered")}
                </StyledTableCellRight>
                <StyledTableCellRight>
                  {translate("resources.inventory.fields.min")}
                </StyledTableCellRight>
                <StyledTableCellRight>
                  {translate("resources.inventory.fields.max")}
                </StyledTableCellRight>
                <StyledTableSmall>
                  {translate("resources.inventory.fields.to_order")}
                </StyledTableSmall>
                <StyledTableAction />
              </TableRow>
            </TableHead>
            <TableBody>
              <FieldArray
                name="items"
                component={(rest) => renderOptionsList(rest, { ...props })}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Flex>
  )
}

export default withStyles(styles)(FormFields)
