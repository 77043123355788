import React from "react";
import { Flex, Box, Text } from "rebass";
import { find } from "lodash";
import { LabelField } from "./items";
import NumberFormat from "react-number-format";
import Button from "@material-ui/core/Button";

export const getTotalOrder = (promotions) => {
  let totalOrderQty = 0;
  let totalOrderAmt = 0;
  promotions.map((data) => {
    totalOrderQty = totalOrderQty + data.tPromoOrderQty;
    totalOrderAmt = totalOrderAmt + data.tPromoOrderAmt;

    return "";
  });
  return {
    totalOrderQty,
    totalOrderAmt,
  };
};
export const getTotalOrderPromo = (promotions, item) => {
  let totalOrderQty = 0;
  let totalOrderAmt = 0;
  promotions.map((data) => {
    if (
      data.item !== null
        ? data.item.id === item.id &&
          data.item.promotion_type === item.promotion_type
        : false
    ) {
      totalOrderQty = totalOrderQty + data.tPromoOrderQty;
      totalOrderAmt = totalOrderAmt + data.tPromoOrderAmt;
    }

    return "";
  });
  return {
    totalOrderQty,
    totalOrderAmt,
  };
};
export const getTotalFreePromo = (promotions, item) => {
  let totalOrderQty = 0;
  let totalOrderAmt = 0;
  promotions.map((data) => {
    if (
      data.item !== null
        ? data.item.id === item.id &&
          data.item.promotion_type === item.promotion_type
        : false
    ) {
      totalOrderQty = totalOrderQty + data.tPromoOrderQty;
      totalOrderAmt = totalOrderAmt + data.tPromoOrderAmt;
    }

    return "";
  });
  return {
    totalOrderQty,
    totalOrderAmt,
  };
};

export const getOldListPromo = (promotions, item) => {
  const oldListPromo = find(promotions, function (o) {
    return o.item !== null
      ? o.item.id === item.id && o.item.promotion_type === item.promotion_type
      : false;
  });
  return oldListPromo;
};

export const getOldListFreePromo = (promotions, item) => {
  const oldListPromo = find(promotions, function (o) {
    return o.item !== null
      ? o.item.id === item.id && o.item.promotion_type === item.promotion_type
      : false;
  });
  return oldListPromo;
};

const HeaderOrdersItem = (props) => {
  const { formData, onAddItem, translate } = props;
  const { items } = formData;
  const allTotal = getTotalOrder(items);
  // console.log(allTotal)
  let totalOrderQty = allTotal.totalOrderQty;
  let totalOrderAmt = allTotal.totalOrderAmt;
  return (
    <Box width={[1]}>
      <Flex width={[1]} flexDirection={"row"} p={[3]}>
        <Box width={[1, 2 / 5]} p={[2]}>
          <Text fontSize={[2]} fontWeight={"bold"}>
            {translate("resources.po.fields.order_cart")}
          </Text>
        </Box>
        <Box
          width={[1, 3 / 5]}
          style={{ backgroundColor: "rgba(0,156,125,0.15)" }}
        >
          <Flex
            width={[1]}
            flexDirection={"row"}
            p={[3]}
            justifyContent={"space-between"}
          >
            <Flex
              width={[1]}
              pr={[2]}
              flexDirection={"row"}
              justifyContent={"flex-start"}
            >
              <LabelField fontWeight={"bold"} style={{ width: 140 }}>
                {translate("resources.po.listgrid.total_order_qty")}
              </LabelField>
              <LabelField>{totalOrderQty}</LabelField>
            </Flex>
            <Flex
              width={[1]}
              flexDirection={"row"}
              pl={[2]}
              justifyContent={"flex-start"}
            >
              <LabelField fontWeight={"bold"} style={{ width: 140 }}>
                {translate("resources.po.listgrid.total_order_amt")}
              </LabelField>
              <LabelField>
                <NumberFormat
                  value={totalOrderAmt}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                  decimalScale={2}
                />
              </LabelField>
            </Flex>
          </Flex>

          <Flex
            width={[1]}
            flexDirection={"row"}
            p={[3]}
            justifyContent={"space-between"}
            style={{ backgroundColor: "#F3F3F3" }}
          >
            <Flex
              width={[1]}
              flexDirection={"row"}
              justifyContent={"flex-start"}
              pr={[2]}
            >
              <LabelField fontWeight={"normal"} style={{ width: 140 }} />
            </Flex>
            <Flex
              width={[1]}
              flexDirection={"row"}
              justifyContent={"flex-start"}
              pl={[2]}
            >
              <LabelField fontWeight={"bold"} style={{ width: 140 }}>
                MTD Target
              </LabelField>
              <LabelField>50,800/90,000</LabelField>
            </Flex>
          </Flex>
          <Flex
            width={[1]}
            flexDirection={"row"}
            p={[3]}
            justifyContent={"space-between"}
          >
            <Flex
              width={[1]}
              flexDirection={"row"}
              justifyContent={"flex-start"}
              pr={[2]}
            >
              <LabelField fontWeight={"normal"} style={{ width: 140 }} />
            </Flex>
            <Flex
              width={[1]}
              flexDirection={"row"}
              justifyContent={"flex-start"}
              pl={[2]}
            >
              <LabelField fontWeight={"bold"} style={{ width: 140 }}>
                YTD Target
              </LabelField>
              <LabelField>50,800/90,000</LabelField>
            </Flex>
          </Flex>
        </Box>
      </Flex>

      <Flex
        width={[1]}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"flex-end"}
        px={[3]}
        py={[2]}
      >
        <Button
          variant="contained"
          color="primary"
          style={{
            borderRadius: 24,
            textTransform: "capitalize",
            paddingLeft: 32,
            paddingRight: 32,
          }}
          onClick={() => onAddItem()}
        >
          {translate("resources.buttons.add_normal_price")}
        </Button>
      </Flex>
    </Box>
  );
};

export default HeaderOrdersItem;
