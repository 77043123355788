import React from "react";
import { Flex } from "rebass";
import {
  TextInput,
  BooleanInput,
  required,
  SelectInput,
  ReferenceInput,
  NumberInput,
} from "react-admin";
import { RaTextInput } from "../../../components";

const FormFieldDealers = (props) => {
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <BooleanInput source='active' label='resources.settings.fields.active' />
      <ReferenceInput
        label='resources.settings.fields.country'
        source='country_id'
        reference='countries'
        sort={{ field: "name", order: "ASC" }}
        perPage={100}
        style={{ width: 200 }}
        validate={required()}
      >
        <SelectInput optionText='name' />
      </ReferenceInput>

      <TextInput
        source='name'
        validate={required()}
        // style={{ width: 600 }}
        fullWidth={true}
        label='resources.settings.fields.dealer_name'
        InputLabelProps={{ shrink: true }}
      />
      <NumberInput
        source='auto_receipt_day'
        style={{ width: 200, margin: 0 }}
        label={"resources.settings.fields.auto_receipt"}
      />
      <RaTextInput
        source='code'
        validate={required()}
        style={{ width: 300 }}
        label='resources.settings.fields.code'
        InputLabelProps={{ shrink: true }}
      />
      <RaTextInput
        source='email'
        validate={required()}
        style={{ width: 400 }}
        label='resources.settings.fields.email'
        type='email'
        InputLabelProps={{ shrink: true }}
      />
      <TextInput
        source='address'
        multiline
        label='resources.settings.fields.address'
        InputLabelProps={{ shrink: true }}
        fullWidth
      />
      <TextInput
        source='phone'
        type='phone'
        style={{ width: 300 }}
        label='resources.settings.fields.phone'
        InputLabelProps={{ shrink: true }}
      />
    </Flex>
  );
};

export default FormFieldDealers;
