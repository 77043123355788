import React from "react";
import { Flex, Box } from "rebass";
import { get } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { styles, StyledTableCell, StyledTableRow } from "./styles";
const RowDealerTarget = (props) => {
  const { data, translate, classes } = props;

  return (
    <Box width={[1]}>
      <Flex width={[1]} pr={[4]}>
        <TableContainer component={"div"}>
          <Table className={classes.table} aria-label='customized table'>
            <TableHead>
              <TableRow className={classes.header}>
                <StyledTableCell>
                  {translate("resources.targets.fields.country")}
                </StyledTableCell>
                <StyledTableCell>
                  {translate("resources.targets.fields.dealer")}
                </StyledTableCell>
                <StyledTableCell>
                  {translate("resources.targets.fields.mc")}
                </StyledTableCell>
                <StyledTableCell>
                  {translate("resources.targets.fields.target_setting")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 &&
                data.map((item, index) => {
                  // const { result } = item
                  // console.log(item);
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell component='td' scope='row'>
                        {get(item, "country", "")}
                      </StyledTableCell>
                      <StyledTableCell component='td' scope='row'>
                        {get(item, "dealer_id", "")}
                      </StyledTableCell>
                      <StyledTableCell component='td' scope='row'>
                        {get(item, "mc", "")}
                      </StyledTableCell>
                      <StyledTableCell component='td' scope='row'>
                        {get(item, "target_setting", "")}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Flex>
    </Box>
  );
};

export default withStyles(styles)(RowDealerTarget);
