import React, { Component } from "react";
import { find, filter, flattenDepth, uniqBy, get } from "lodash";

import { AgGridReact } from "ag-grid-react";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "../../Expand";
import { getProducts, getAllProducts } from "../../../../restClient";
import { defaultColumnDefs } from "./defaultValue";
import { getPromoType, PromoType_B } from "./utils";

/**
     * ProductGroup  => group_id =>  611

SubProductGroup  => subgroup_id   621

ProductCode  => code =>  "901126"

ProductShortCode  => short_code   "901126" 
     */
// /products?_sort=&_order=&search=&category_id=&group_id=&subgroup_id=

class ArListGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      message: "",
      show: false,
      rowData: [],
      columnDefs: defaultColumnDefs,
      defaultColDef: {
        flex: 1,
        editable: true,
        sortable: true,
        filter: true,
        cellStyle: {
          "line-height": "40px",
        },
      },
      getRowNodeId: function (data) {
        return data.code;
      },
      listPromo: [],
      item: props.item,
      expanded: "panelGridPromo",
    };
  }
  componentDidMount() {
    const { item, promoType, tempList } = this.props;
    const myURl = getPromoType(promoType, item);
    // console.log(promoType);
    if (Array.isArray(myURl)) {
      getAllProducts(myURl)
        .then((res) => {
          // console.log(res);
          const result = flattenDepth(res, 1);
          const response = uniqBy(result, "id");
          if (response && response.length > 0) {
            const myLatest = response.map((item) => {
              let newItem = item;
              const objUrl = find(tempList, function (o) {
                // console.log(promoType, o.id);
                return o.product_id === item.id;
              });
              if (objUrl && objUrl.product_id) {
                newItem["qty"] = objUrl.qty;
                newItem["subtotal"] = objUrl.subtotal;
              } else {
                newItem["qty"] = 0;
                newItem["subtotal"] = 0;
              }
              newItem["moq_group_index"] = 0;
              if (
                promoType === "Buy X Get Y" ||
                promoType === "Buy AB Get CD"
              ) {
                newItem["foc"] = false;
              }
              newItem["cr"] = get(item, "cr", false) === true ? "CR" : "";
              newItem["dg"] = get(item, "dg", false) === true ? "DG" : "";
              newItem["target_id"] = get(item, "target_id", 0);
              return newItem;
            });

            this.setState({
              rowData: myLatest,
            });
          }
        })
        .catch((e) => {});
    } else {
      if (myURl !== "") {
        getProducts(myURl)
          .then((response) => {
            // console.log("response", response);
            if (response && response.length > 0) {
              const myLatest = response.map((item) => {
                let newItem = item;
                const objUrl = find(tempList, function (o) {
                  // console.log(promoType, o.id);
                  return o.product_id === item.id;
                });

                if (objUrl && objUrl.product_id) {
                  // console.log(promoType, objUrl);
                  newItem["qty"] = objUrl.qty;
                  newItem["subtotal"] = objUrl.subtotal;
                } else {
                  newItem["qty"] = 0;
                  newItem["subtotal"] = 0;
                }
                newItem["moq_group_index"] = 0;
                if (
                  promoType === "Buy X Get Y" ||
                  promoType === "Buy AB Get CD"
                ) {
                  newItem["foc"] = false;
                }

                newItem["target_id"] = get(item, "target_id", 0);
                return newItem;
              });
              this.setState({
                rowData: myLatest,
              });
            }
          })
          .catch((e) => {
            // console.log(e);
          });
      }
    }
  }
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onCellChanged(params, locale) {
    locale.onUpdateItem(params);
  }

  onUpdateItem = (params) => {
    if (params.data.qty && params.data.qty > 0) {
      this.setOrderAmtDefault(params.data.code, params.data);
    }
  };

  handleChange = (panel) => (event, newExpanded) => {
    this.setState({
      expanded: newExpanded ? panel : false,
    });
  };

  setOrderAmtDefault(ProductCode, data) {
    const { promoType } = this.props;
    if (ProductCode) {
      const { tempList, item } = this.props;
      let newListPromo = tempList;
      if (newListPromo.length === 0) {
        let itemProduct = {
          product_id: data.id,
          qty: data.qty,
          price: data.price,
          subtotal: data.subtotal,
          promotions: item,
          class4: data.class4,
        };

        if (promoType === "Buy X Get Y" || promoType === "Buy AB Get CD") {
          itemProduct["foc"] = false;
        }
        itemProduct["target_id"] = data.target_id;
        newListPromo.push(itemProduct);
      } else {
        let newList = filter(newListPromo, function (o) {
          return o.product_id !== data.id;
        });
        const updateItem = find(newListPromo, function (o) {
          return o.product_id === data.id;
        });
        newListPromo = newList;
        let newItemProduct = {
          product_id: data.id,
          qty: data.qty,
          price: data.price,
          subtotal: data.subtotal,
          promotions: item,
          class4: data.class4,
        };
        if (updateItem && updateItem.id) {
          newItemProduct = {
            ...updateItem,
            product_id: data.id,
            qty: data.qty,
            price: data.price,
            subtotal: data.subtotal,
            promotions: item,
            class4: data.class4,
          };
        }
        if (promoType === "Buy X Get Y" || promoType === "Buy AB Get CD") {
          newItemProduct["foc"] = false;
        }
        newItemProduct["target_id"] = data.target_id;
        newListPromo.push(newItemProduct);
      }
      var rowNode = this.gridApi.getRowNode(ProductCode);
      rowNode.setDataValue(
        "subtotal",
        Number(data.qty * data.price).toFixed(2)
      );
      this.updateTempList(newListPromo);
    }
  }

  updateTempList = (newListPromo) => {
    this.props.setTempList(newListPromo);
  };

  render() {
    const { translate, promoType } = this.props;

    // console.log(this.state.rowData);
    const isMoreHeight =
      promoType === PromoType_B[0] || promoType === PromoType_B[1]
        ? "20vh"
        : "52vh";
    return (
      <ExpansionPanel
        expanded={this.state.expanded === "panelGridPromo"}
        onChange={this.handleChange("panelGridPromo")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='promotion_items-content'
          id='promotion_items-header'
        >
          <Typography>
            {translate("resources.po.listgrid.label.promo_items")} (
            {this.state.rowData.length}{" "}
            {this.state.rowData.length > 1
              ? translate("resources.po.listgrid.label.items")
              : translate("resources.po.listgrid.label.item")}
            )
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          style={{ width: "100%%", padding: 0, paddingTop: 4 }}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <div className='grid-wrapper'>
              <div
                id='myGrid'
                style={{
                  height: isMoreHeight,
                  width: "100%",
                }}
                className='ag-theme-balham'
              >
                <AgGridReact
                  rowHeight={40}
                  headerHeight={40}
                  rowData={this.state.rowData}
                  columnDefs={this.state.columnDefs}
                  defaultColDef={this.state.defaultColDef}
                  animateRows={true}
                  getRowNodeId={this.state.getRowNodeId}
                  onGridReady={this.onGridReady}
                  onCellValueChanged={(params) =>
                    this.onCellChanged(params, this)
                  }
                />
              </div>
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default ArListGrid;
