import React, { Fragment, useState, useEffect } from "react";
//useRef,
import {
  find,
  filter,
  flattenDepth,
  uniqBy,
  get,
  map,
  orderBy,
  toLower,
} from "lodash";
import { Box, Flex, Text } from "rebass";
import { AgGridReact } from "ag-grid-react";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "./Expand";
import { getProductsWithId, getAllProductsWithId } from "../../restClient";
import { defaultFreeColumnDefs } from "./defaultValue";
import {
  getPromoTypeFree,
  getTotalSelectedQtyFree,
  getRegisterNumber,
} from "./utils";
import { getTotalFreeXY } from "./utilsABCD";

export default (props) => {
  const {
    translate,
    promoType,
    item,
    tempList,
    setTempList,
    setShow,
    setMessageError,
    tempListBuy,
    tempListFree,
    setTempListFree,
    countryId,
    // isOAAsossiate,
    country,
  } = props;
  const countryCode = toLower(get(country, "code", ""));
  const [isRequest, setIsRequest] = useState(true);
  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [state, setState] = useState({
    search: "",
    message: "",
    show: false,
    columnDefs: defaultFreeColumnDefs(false),
    defaultColDef: {
      flex: 1,
      editable: true,
      sortable: true,
      filter: true,
      cellStyle: {
        "line-height": "40px",
      },
    },
    getRowNodeId: function (data) {
      return data.code;
    },
    listPromo: [],
    item: item,
    expanded: "panelGridFreePromo",
  });
  const myURl = getPromoTypeFree(promoType, item);

  useEffect(() => {
    if (isRequest) {
      if (Array.isArray(myURl)) {
        getAllProductsWithId(myURl)
          .then((res) => {
            const result = flattenDepth(res, 1);
            const dataProduct = filter(result, function (o) {
              return o.active;
            });
            const response = uniqBy(dataProduct, "id");
            if (response && response.length > 0) {
              const myLatest = response.map((item) => {
                let newItem = item;
                const objUrl = find(tempList, function (o) {
                  return o.product_id === item.id && o.foc;
                });
                if (objUrl && objUrl.product_id) {
                  newItem["qty"] = objUrl.qty;
                  newItem["subtotal"] = 0;
                } else {
                  newItem["qty"] = 0;
                  newItem["subtotal"] = 0;
                }
                newItem["growth"] = get(item, `class4.${countryId}`, "");
                newItem["cr"] = get(item, "cr", false);
                //=== true ? "CR" : "";
                newItem["dg"] = get(item, "dg", false);
                //=== true ? "DG" : "";
                newItem["foc"] = true;
                newItem["target_id"] = get(item, "target_id", 0);
                newItem["registration_number"] = getRegisterNumber(
                  item,
                  countryCode
                );
                return newItem;
              });

              const myTempFree = filter(myLatest, function (oo) {
                return oo.foc && oo.qty > 0;
              });

              setRowData(myLatest);
              setTempListFree(myTempFree);
            }
            setIsRequest(false);
          })
          .catch((e) => {
            setIsRequest(false);
          });
      } else {
        if (myURl !== "") {
          getProductsWithId(myURl)
            .then((response) => {
              if (response && response.length > 0) {
                const dataProduct = filter(response, function (o) {
                  return o.active;
                });
                const responseData = uniqBy(dataProduct, "id");
                const myLatest = responseData.map((item) => {
                  let newItem = item;
                  const objUrl = find(tempList, function (o) {
                    return o.product_id === item.id && o.foc;
                  });
                  if (objUrl && objUrl.qty) {
                    newItem["qty"] = objUrl.qty;
                  } else {
                    newItem["qty"] = 0;
                  }
                  newItem["subtotal"] = 0;
                  newItem["growth"] = get(item, `class4.${countryId}`, "");
                  newItem["cr"] = get(item, "cr", false);
                  // === true ? "CR" : "";
                  newItem["dg"] = get(item, "dg", false);
                  //=== true ? "DG" : "";
                  newItem["foc"] = true;
                  newItem["target_id"] = get(item, "target_id", 0);
                  newItem["registration_number"] = getRegisterNumber(
                    item,
                    countryCode
                  );
                  return newItem;
                });
                // onUpdateState(myLatest);
                const myTempFree = filter(myLatest, function (oo) {
                  return oo.foc && oo.qty > 0;
                });
                setRowData(myLatest);
                setTempListFree(myTempFree);
              } else {
                if (response && response.id) {
                  let newItem = response;
                  const objUrl = find(tempList, function (o) {
                    return o.product_id === response.id && o.foc;
                  });
                  if (objUrl && objUrl.qty) {
                    newItem["qty"] = objUrl.qty;
                  } else {
                    newItem["qty"] = 0;
                  }
                  newItem["subtotal"] = 0;
                  newItem["growth"] = get(response, `class4.${countryId}`, "");
                  newItem["cr"] = get(response, "cr", false);
                  // get(response, "cr", false) === true ? "CR" : "";
                  newItem["dg"] = get(response, "dg", false);
                  // get(response, "dg", false) === true ? "DG" : "";
                  newItem["foc"] = true;
                  newItem["target_id"] = get(response, "target_id", 0);
                  newItem["registration_number"] = getRegisterNumber(
                    response,
                    countryCode
                  );
                  setRowData([newItem]);
                  if (newItem.foc && newItem.qty > 0) {
                    setTempListFree([newItem]);
                  } else {
                    setTempListFree([]);
                  }
                }
              }
              setIsRequest(false);
            })
            .catch((e) => {
              setIsRequest(false);
            });
        } else {
          setIsRequest(false);
        }
      }
    }
    return () => setIsRequest(false);
  }, [myURl, tempList, isRequest, setTempListFree, countryId, countryCode]);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  const handleChange = (panel) => (event, newExpanded) => {
    setState({
      ...state,
      expanded: newExpanded ? panel : false,
    });
  };

  const setOrderAmtDefault = (ProductCode, data, newListRaw) => {
    if (ProductCode) {
      let newListPromo = tempList;
      // const newList = filter(newListPromo, function (o) {
      //   return o.product_id === data.id && o.foc;
      // });
      // console.log("newList", newList);
      // if (newList.length === 0) {
      //   const itemProduct = {
      //     product_id: data.id,
      //     qty: data.qty,
      //     price: 0,
      //     subtotal: 0,
      //     promotions: item,
      //     class4: data.class4,
      //     foc: true,
      //     target_id: data.target_id,
      //     new_existing: get(data, "new_existing", ""),
      //   };

      //   newListPromo.push(itemProduct);
      //   setTempList(newListPromo);
      // } else
      let newTempList = [];
      const newListNonFree = filter(newListPromo, function (o) {
        return !o.foc && o.qty > 0;
      });
      const lFree = newListRaw.map((itemFree) => {
        return {
          product_id: itemFree.id,
          qty: itemFree.qty,
          price: 0,
          subtotal: 0,
          promotions: item,
          class4: itemFree.class4,
          foc: true,
          target_id: itemFree.target_id,
          new_existing: get(data, "new_existing", ""),
        };
      });

      const listFree = sortOrderByName(rowData, lFree);

      newTempList = [...newListNonFree, ...listFree];
      setTempList(newTempList);
      // let newList1 = filter(newListRaw, function (o) {
      //   return o.id !== data.id && o.foc;
      // });
      // newList1.push(data);
      const myList = filter(newListRaw, function (oo) {
        return oo.qty > 0 && oo.foc;
      });
      // console.log("myList", myList);
      // const myListFree = sortOrderByName(rowData, myList);
      setTempListFree(myList);
    }
  };

  const sortOrderByName = (rowData, list) => {
    const newList = map(list, function (o) {
      const itemProduct = find(rowData, function (i) {
        return i.id === o.product_id;
      });
      return { ...o, product_name: get(itemProduct, "packaging", "") };
    });
    const sortByName = orderBy(newList, "product_name", "asc");
    let mySortOrder = item && item.id ? item.id * 200 : 1;
    const newSortByName = map(sortByName, function (ii) {
      const { product_name, ...rest } = ii;
      rest["sort_order"] = mySortOrder++;
      return { ...rest };
    });

    return newSortByName;
  };

  const onCellEditingStopped = (params) => {
    let newListRaw = [];
    map(rowData, function (o) {
      if (o.foc && o.qty > 0) {
        // console.log(o);
        if (o.id === get(params, "data.id")) {
          o["qty"] = get(params, "data.qty", 0);
        }
        newListRaw.push(o);
      }
      return o;
    });
    if (params.data.qty > 0) {
      const qtyTotalFree = getTotalFreeXY(item, tempListBuy, promoType);
      const qtySelected =
        tempListFree && tempListFree.length > 0
          ? getTotalSelectedQtyFree(tempListFree) > 0
            ? getTotalSelectedQtyFree(tempListFree)
            : getTotalSelectedQtyFree(tempListFree) + params.data.qty
          : params.data.qty;

      let rowNode1 = gridApi.getRowNode(params.data.code);
      rowNode1.setDataValue("subtotal", 0);
      if (qtyTotalFree === 0) {
        setShow(true);
        setMessageError(`Please select Promotion Items first`);
        rowNode1.setDataValue("qty", 0);
      }
      if (qtySelected > qtyTotalFree) {
        setShow(true);
        setMessageError(`Free Item is ${qtyTotalFree}`);
        rowNode1.setDataValue("qty", 0);
      }
      let latestRowData = rowData;
      if (rowNode1.data) {
        const newDataRow = rowNode1.data;
        const objIndex = latestRowData.findIndex(
          (obj) => obj.id === newDataRow.id
        );
        latestRowData[objIndex] = newDataRow;
        setRowData(latestRowData);
        newListRaw = [];
        map(latestRowData, function (o) {
          if (o.foc && o.qty > 0) {
            if (o.id === get(params, "data.id")) {
              o["qty"] = get(params, "data.qty", 0);
            }
            newListRaw.push(o);
          }
          return o;
        });
      }
    }
    // console.log("newListRaw", newListRaw);
    setOrderAmtDefault(params.data.code, params.data, newListRaw);
  };

  const qtyTotalFree = getTotalFreeXY(item, tempListBuy, promoType);
  const qtySelected = getTotalSelectedQtyFree(tempListFree);

  return (
    <Fragment>
      <ExpansionPanel
        expanded={state.expanded === "panelGridFreePromo"}
        onChange={handleChange("panelGridFreePromo")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='free_items-content'
          id='free_items-header'
        >
          <Typography>
            {translate("resources.promotions.panel.free_items")} (
            {rowData.length}{" "}
            {rowData.length > 1
              ? translate("resources.po.listgrid.label.items")
              : translate("resources.po.listgrid.label.item")}
            )
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          style={{ width: "100%%", padding: 0, paddingTop: 4 }}
        >
          <Box width={[1]}>
            <Flex
              width={[1]}
              flexDirection={"row"}
              sx={{
                "&>div": {
                  p: [1],
                },
              }}
            >
              <Box>
                <Text fontSize={[0]}>
                  {translate("resources.po.listgrid.label.entitled")}
                </Text>
                <Text color={"red"}>{qtyTotalFree}</Text>
              </Box>
              <Box>
                <Text fontSize={[0]}>
                  {translate("resources.po.listgrid.label.selected")}
                </Text>
                <Text>{qtySelected}</Text>
              </Box>
              <Box>
                <Text fontSize={[0]}>
                  {translate("resources.po.listgrid.label.remaining")}
                </Text>
                <Text style={{ color: "#009C7D" }}>
                  {qtyTotalFree - qtySelected}
                </Text>
              </Box>
            </Flex>
            <div style={{ width: "100%" }}>
              <div className='grid-wrapper'>
                <div
                  id='myGridFree'
                  style={{
                    height: "22vh",
                    width: "100%",
                  }}
                  className='ag-theme-balham'
                >
                  <AgGridReact
                    rowHeight={40}
                    headerHeight={40}
                    rowData={rowData}
                    columnDefs={state.columnDefs}
                    defaultColDef={state.defaultColDef}
                    animateRows={true}
                    getRowNodeId={state.getRowNodeId}
                    onGridReady={onGridReady}
                    onCellEditingStopped={(params) =>
                      onCellEditingStopped(params)
                    }
                  />
                </div>
              </div>
            </div>
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Fragment>
  );
};
