import React, { Fragment, useState } from "react";
import { Flex } from "rebass";
import { useTranslate } from "react-admin";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import { Link, useRouteMatch, useLocation } from "react-router-dom";
import { Divider, Tabs, Tab } from "@material-ui/core";
import { TabPanel, a11yProps, Title, CreateButton } from "../components";
import {
  CustomerList,
  ButtonUploadCsv,
  DialogShowListError,
} from "./components";
import PriceBookDealerList from "./List";

const tabs = [
  {
    id: "dealer",
    name: "resources.pricebook.tabs.dealer",
    link: "/priceBooks",
  },
  {
    id: "customer",
    name: "resources.pricebook.tabs.customer",
    link: "/priceBooks/customer",
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export default (props) => {
  const translate = useTranslate();
  const [listError, setListError] = useState({ open: false, list: [] });
  const classes = useStyles();
  const theme = useTheme();
  const match = useRouteMatch();
  const location = useLocation();
  const url = match ? match.url : location.pathname;
  let indexTab = url === "/priceBooks/customer" ? 1 : 0;
  const [value, setValue] = React.useState(indexTab);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const onCloseDialog = (value) => {
    setListError({
      open: false,
      list: [],
    });
  };

  const onSubmitUpload = (values) => {
    if (values && values.length > 0) {
      setListError({
        open: true,
        list: values,
      });
    }
  };
  // const onUpload = () => {
  //   if (indexTab === 0) {
  //     //dealer
  //   } else {
  //     //customer
  //   }
  // };
  return (
    <Fragment>
      <div className={classes.root}>
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"flex-end"}
          pb={[3]}
          pt={[3]}
        >
          <Title fontWeight={"bold"} fontSize={[3]}>
            {translate(`resources.pricebook.title`)}
          </Title>
          <Flex flexDirection={"row"} justifyContent={"flex-end"}>
            <CreateButton
              isCustomUrl={true}
              basePath={`/priceBooks/create?type=${
                indexTab === 0 ? "dealer" : "customer"
              }`}
              sizeIcon={"xsmall"}
            />
            <ButtonUploadCsv
              type={indexTab === 0 ? "Dealer" : "Customer"}
              onSubmitUpload={onSubmitUpload}
            />
            {/* <UploadButton sizeIcon={"xsmall"} onClick={onUpload} /> */}
          </Flex>
        </Flex>
        <Tabs value={value} indicatorColor='primary' onChange={handleChange}>
          {tabs.map((choice, index) => (
            <Tab
              key={choice.id}
              label={translate(choice.name)}
              component={Link}
              to={choice.link}
              {...a11yProps("priceBooks", index)}
            />
          ))}
        </Tabs>
        <Divider />
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel
            value={value}
            index={0}
            dir={theme.direction}
            name={"priceBooks"}
          >
            <PriceBookDealerList {...props} myType={"Dealer"} />
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            dir={theme.direction}
            name={"priceBooks"}
          >
            <CustomerList {...props} myType={"Customer"} />
          </TabPanel>
        </SwipeableViews>
      </div>
      <DialogShowListError
        setOpen={(v) => onCloseDialog(v)}
        open={listError.open}
        list={listError.list}
        translate={translate}
      />
    </Fragment>
  );
};
