import React from "react";
import moment from "moment";
import { get, sumBy } from "lodash";
import { TextField, useTranslate, useQueryWithStore } from "react-admin";
import { Flex, Box } from "rebass";
// import { useQuery } from "react-query";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  BetterList,
  Title,
  CreateButton,
  BetterEditButton,
} from "../components";
import { showDealer } from "../utils";
import { ButtonSODetail } from "../screens/reports";
// import { RestApi } from "../restClient";
// const cacheTimeMinute = 1000 * 60 * 30;
const DateField = ({ record, source }) => {
  const value = get(record, source, "");
  return <span>{value !== "" ? moment(value).format("DD-MMM-YYYY") : ""}</span>;
};

const QtyField = ({ record }) => {
  //useQueryWithStore
  const { loading, data } = useQueryWithStore({
    type: "getOne",
    resource: "sellOuts",
    payload: { id: record.id },
  });

  // const { isLoading, data } = useQuery(
  //   ["sellout", record.id],
  //   () => RestApi(`/sellOuts/${record.id}`).then((res) => res.json()),
  //   { cacheTime: cacheTimeMinute }
  // );
  if (loading) {
    return <LinearProgress variant='query' />;
  }
  const myQty = get(data, "items", []);
  const totalQty = sumBy(myQty, "qty");
  return <span>{totalQty}</span>;
};

const ShowButton = (props) => {
  const { record } = props;
  const isDraft = get(record, "status", "") !== "Draft";
  if (isDraft) {
    return <ButtonSODetail {...props} />;
  }
  return (
    <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
      <ButtonSODetail {...props} />
      <BetterEditButton {...props} />
    </Flex>
  );
};

const SelloutAction = ({ basePath, permissions, ...rest }) => {
  // console.log(permissions)
  const translate = useTranslate();

  return (
    <Flex
      width={[1]}
      pt={[3]}
      px={[2]}
      flexDirection={"row"}
      justifyContent={"space-between"}
    >
      <Box>
        <Title fontWeight={"bold"} fontSize={[3]}>
          {translate(`resources.sellout.title.sellout`)}
        </Title>
      </Box>
      <Box>
        <CreateButton basePath={basePath} />
      </Box>
    </Flex>
  );
};

const ListSellout = (props) => {
  // console.log(props)
  const { hasShow, hasEdit, permissions, ...rest } = props;
  return (
    <BetterList {...props} actions={<SelloutAction {...rest} />}>
      <DateField source='created_at' label={"resources.sellout.fields.date"} />
      <TextField
        source='customer_name'
        label={"resources.sellout.fields.ref_customer"}
      />
      <QtyField source='total' label={"resources.sellout.fields.qty"} />
      <TextField source='status' label={"resources.sellout.fields.status"} />
      {showDealer(permissions) && <ShowButton {...props} />}
    </BetterList>
  );
};
export default ListSellout;
