export default {
  products: {
    name: "Products",
    new_product: "New Product",
    edit_product: "Edit Product",
    show_product: "Product",
    title_expand: "Restrictions",
    title_registration: "Registration Number",
    tabs: {
      info: "Info",
      variants: "Variants",
      images: "Images",
      attributes: "Attributes",
      custom: "Customizations",
      bundles: "Bundles",
      details: "Details",
      analytics: "Analytics",
      country: "Country",
    },
    fields: {
      packaging: "Packing",
      moq: "MOQ",
      active: "Active",
      name: "Name",
      default_price: "Default Price",
      id: "ID",
      sg: "SG",
      my: "MY",
      th: "TH",
      photos: "Photos",
      type: "Type",
      remarks: "Remarks",
      terms: "Terms",
      categories: "Categories",
      expiry_days: "Expiry Days",
      points: "Points",
      value: "Value",
      point_need: "Points Needed",
      reward_value: "Reward Value",
      sku: "SKU",
      min_order_value: "Minimum Order Value",
      reward_validity: "Reward Validity (Days)",
      not_in_branches: "Not in Branches",
      cost: "Cost",
      price: "Price",
      margin: "Margin",
      slug: "Slug",
      brand: "Brand",
      sort_order: "Sort Order",
      shipping_weight: "Shipping Weight (kg)",
      discount: "Discount",
      unit_price: "Unit Price",
      description: "Description",
      unit: "Unit",
      value_number: "Value (in number)",
      option_title: "Option Title",
      option_page: "Option Content Page",
      option_type: "Option Type",
      option_required: "Required",
      title: "Title",
      addvalue: "Add Value",
      product: "Product",
      code: "Code",
      threshold: "Threshold",
      name_sku: "Name & SKU",
      variant: "Variant",
      options: "Options",
      attribute: "Attribute",
      filters: "Filters",
      product_image: "Product Image",
      category: "Category",
      group: "Group",
      classifications: "Classifications",
      class_1: "Classification 1",
      class_2: "Classification 2",
      class_3: "Classification 3",
      target_setting: "Target Setting",
      product_link: "Product Link",
      dg: "DG",
      cr: "CR",
      new_existing: "New/Existing",
      product_packaging: "Product Packaging",
      product_name: "Product Name",
      product_code: "Product Code",
      short_code: "Short Code",
      product_group: "Product Group",
      sub_product_group: "Sub Product Group",
      global_code: "Global Code",
      effective_date: "Effective Date",
    },
    note_text: {
      image_variant:
        "Important: Please complete Variant information first before upload product image.",
      slug: "Product url",
      point_need: "Loyalty points needed to redeem this reward",
      reward_value: "Amount to be deducted from subtotal",
      sku: "Restricted to the above SKUs only",
      min_order_value:
        "Minimum order amount in the shopping cart during checkout",
      reward_validity:
        "Number of days reward is valid after redemption. Put 0 if no expiry",
      not_in_branches: "List of branches not participate for this product",
      check_name_product:
        "Please fill name field on the info tab and complete Variant Information to upload images",
    },
  },
};
