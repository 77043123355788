import React from "react";
import {
  TextField,
  useTranslate,
  Filter,
  useNotify,
  SearchInput,
  ReferenceInput,
  // SelectInput,
  DateInput,
  AutocompleteInput,
} from "react-admin";
import moment from "moment";
import { get } from "lodash";
import { Box, Flex } from "rebass";
import {
  BetterList,
  ButtonEditView,
  CreateButton,
  Title,
  // CountryField,
  ActiveField,
} from "../components";
import { SingleCountryField } from "../components/product";
import { ButtonUploadCsv } from "./components";

// import { ROLE_USER } from "../utils";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  hiddenOnSmallScreens: {
    width: "400px",
  },
  product: {
    width: 300,
  },
}));

const RaDateField = ({ record, format = "DD-MMM-YYYY" }) => {
  const myDate = get(record, "start_date", "");
  const myDate1 = get(record, "end_date", "");
  return (
    <span>
      {moment(myDate).format("DD-MMM")}/{moment(myDate1).format(format)}
    </span>
  );
};

const PromotionFilter = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const { basePath, hasList, hasShow, hasEdit, ...rest } = props;
  // const onBroadcast = () => {};
  return (
    <Box width={[1]} pb={[2]}>
      <Flex
        pt={[2]}
        pl={[2]}
        pr={[2]}
        flexDirection={"row"}
        justifyContent={"space-between"}
        style={{
          right: 16,
        }}
      >
        <Box width={[1]} pt={[2]}>
          <Title fontWeight={"bold"} fontSize={[3]}>
            {translate(`resources.promotions.title.promotions`)}
          </Title>
        </Box>
        <Flex flexDirection={"row"} justifyContent={"flex-end"}>
          {/* {permissions === ROLE_USER.super_admin && (
            
          )} */}

          <CreateButton
            basePath={basePath}
            sizeIcon={"xsmall"}
            style={{ textTransform: "capitalize" }}
            label={"resources.buttons.new"}
          />
          {/* <UploadButton
            label={"resources.buttons.broadcast_promotion"}
            sizeIcon={"xsmall"}
            onClick={onBroadcast}
            isHideIcon={true}
          /> */}
          <ButtonUploadCsv
            label={"resources.buttons.upload_promotions"}
            translate={translate}
            urlPath={"/promotions/import"}
            notify={notify}
          />
        </Flex>
      </Flex>

      <Flex width={[1]} flexDirection={"row"} justifyContent={"flex-end"}>
        <Filter {...rest}>
          <DateInput
            source={"date"}
            label={"resources.products.fields.effective_date"}
            alwaysOn
            parse={(ve) => {
              console.log(moment(ve).toISOString());
              return moment(ve).toISOString();
            }}
          />
          <ReferenceInput
            source={"dealer_id"}
            reference={"dealers"}
            label={"resources.promotions.fields.dealers"}
            sort={{ field: "name", order: "ASC" }}
            perPage={300}
            alwaysOn
            className={props.classes.product}
          >
            <AutocompleteInput optionText={"name"} />
          </ReferenceInput>
          <SearchInput source='search' alwaysOn style={{ width: 256 }} />
        </Filter>
      </Flex>
    </Box>
  );
};
const PromotionAction = ({ basePath, permissions }) => {
  // console.log(permissions)
  return <Flex flexDirection={"row"}></Flex>;
};

const PromotionsList = (props) => {
  const { permissions } = props;
  const classes = useStyles();
  return (
    <BetterList
      {...props}
      actions={<PromotionAction permissions={permissions} />}
      perPage={15}
      filters={
        <PromotionFilter
          permissions={permissions}
          {...props}
          classes={classes}
        />
      }
      empty={false}
    >
      <TextField
        label={"resources.promotions.fields.name"}
        source='name'
        headerClassName={classes.hiddenOnSmallScreens}
        cellClassName={classes.hiddenOnSmallScreens}
      />
      <TextField label={"resources.promotions.fields.type"} source='type' />
      <TextField
        label={"resources.promotions.fields.promotion_type"}
        source='promotion_type'
        style={{ width: 200 }}
      />
      <ActiveField
        label={"resources.promotions.fields.active"}
        source='active'
      />
      <TextField label={"resources.promotions.fields.status"} source='status' />
      <SingleCountryField
        label={"resources.promotions.fields.dealers"}
        source='dealer'
        optionsText={"name"}
      />
      {/* <CountryField
        label={"resources.promotions.fields.country"}
        source='country'
        optionsText={"name"}
      /> */}
      {/* <ReferenceManyField
        label='resources.promotions.fields.country'
        reference='countries'
        target='country'
      >
        <SingleFieldList>
          <ChipField source='name' />
        </SingleFieldList>
      </ReferenceManyField> */}
      <RaDateField
        {...props}
        label={"resources.promotions.fields.start"}
        source='start_date'
        style={{ width: 200 }}
      />
      {/* <ActiveField
        label={"resources.promotions.fields.active"}
        source='active'
      /> */}
      <ButtonEditView {...props} />
    </BetterList>
  );
};

export default PromotionsList;
