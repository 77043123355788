const payloadDefault = {
  pagination: { page: 1, perPage: 1000 },
  sort: { field: "packaging", order: "ASC" },
}
const payloadCategoriesDefault = {
  pagination: { page: 1, perPage: 1000 },
  sort: { field: "name", order: "ASC" },
}
const payloadGroupDefault = {
  pagination: { page: 1, perPage: 1000 },
  sort: { field: "name", order: "ASC" },
}

export { payloadDefault, payloadCategoriesDefault, payloadGroupDefault }
