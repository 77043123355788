import React, { Component } from "react";
import { find, filter, flattenDepth, uniqBy, get } from "lodash";
import { Box, Flex, Text } from "rebass";
import { AgGridReact } from "ag-grid-react";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "../../Expand";
import { getProducts, getAllProducts } from "../../../../restClient";
import { defaultColumnDefs } from "./defaultValue";
import { getPromoTypeFree, getTotalQtyFreeProductPromo } from "./utils";

const getTotalSelectedQtyFreeProduct = (list) => {
  let freeTotal = 0;
  list.map((product) => {
    if (product.subtotal * 1 === 0 && product.qty) {
      freeTotal = freeTotal + product.qty * 1;
    }
    return "";
  });
  return freeTotal;
};

class ArFreeListGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      message: "",
      show: false,
      rowData: [],
      columnDefs: defaultColumnDefs,
      defaultColDef: {
        flex: 1,
        editable: true,
        sortable: true,
        filter: true,
        cellStyle: {
          "line-height": "40px",
        },
      },
      getRowNodeId: function (data) {
        return data.code;
      },
      listPromo: [],
      item: props.item,
      expanded: "panelGridFreePromo",
    };
  }
  componentDidMount() {
    const { item, promoType, tempList } = this.props;
    const myURl = getPromoTypeFree(promoType, item);
    // console.log(tempList, listSelected);
    if (Array.isArray(myURl)) {
      getAllProducts(myURl)
        .then((res) => {
          const result = flattenDepth(res, 1);
          const response = uniqBy(result, "id");
          if (response && response.length > 0) {
            const myLatest = response.map((item) => {
              let newItem = item;
              const objUrl = find(tempList, function (o) {
                // console.log(promoType, o.id);
                return o.product_id === item.id;
              });
              if (objUrl && objUrl.product_id) {
                newItem["qty"] = objUrl.qty;
                newItem["subtotal"] = objUrl.subtotal;
              } else {
                newItem["qty"] = 0;
                newItem["subtotal"] = 0;
              }
              newItem["foc"] = true;
              newItem["target_id"] = get(item, "target_id", 0);
              return newItem;
            });

            this.setState({
              rowData: myLatest,
            });
          }
        })
        .catch((e) => {
          // console.log(e);
        });
    } else {
      if (myURl !== "") {
        getProducts(myURl)
          .then((response) => {
            // console.log(response);
            if (response && response.length > 0) {
              const myLatest = response.map((item) => {
                let newItem = item;
                const objUrl = find(tempList, function (o) {
                  // console.log(promoType, o.id);
                  return o.product_id === item.id;
                });
                if (objUrl && objUrl.product_id) {
                  newItem["qty"] = objUrl.qty;
                  newItem["subtotal"] = objUrl.subtotal;
                } else {
                  newItem["qty"] = 0;
                  newItem["subtotal"] = 0;
                }
                newItem["foc"] = true;
                newItem["target_id"] = get(item, "target_id", 0);
                return newItem;
              });

              this.setState({
                rowData: myLatest,
              });
            }
          })
          .catch((e) => {
            // console.log(e);
          });
      }
    }
  }
  onGridReady = (params) => {
    this.gridFreeApi = params.api;
    this.gridFreeColumnApi = params.columnApi;
  };

  onCellChanged(params, locale) {
    locale.onUpdateItem(params);
  }

  onUpdateItem = (params) => {
    if (params.data.qty && params.data.qty > 0) {
      this.setOrderAmtDefault(params.data.code, params.data);
    }
  };

  handleChange = (panel) => (event, newExpanded) => {
    this.setState({
      expanded: newExpanded ? panel : false,
    });
  };

  setOrderAmtDefault(ProductCode, data) {
    if (ProductCode) {
      const { tempList, item } = this.props;
      let newListPromo = tempList;
      if (newListPromo.length === 0) {
        const itemProduct = {
          product_id: data.id,
          qty: data.qty,
          price: 0,
          subtotal: 0,
          promotions: item,
          class4: data.class4,
          foc: true,
          target_id: data.target_id,
        };

        newListPromo.push(itemProduct);
      } else {
        let newList = filter(newListPromo, function (o) {
          return o.product_id !== data.id;
        });
        const updateItem = find(newListPromo, function (o) {
          return o.product_id === data.id;
        });
        newListPromo = newList;
        //data.price
        let newItemProduct = {
          product_id: data.id,
          qty: data.qty,
          price: 0,
          subtotal: 0,
          promotions: item,
          class4: data.class4,
          foc: true,
          target_id: data.target_id,
        };
        if (updateItem && updateItem.id) {
          newItemProduct = {
            ...updateItem,
            product_id: data.id,
            qty: data.qty,
            price: 0,
            subtotal: 0,
            promotions: item,
            class4: data.class4,
            foc: true,
            target_id: data.target_id,
          };
        }
        newListPromo.push(newItemProduct);
      }
      var rowNode = this.gridFreeApi.getRowNode(ProductCode);
      rowNode.setDataValue("subtotal", 0);
      this.updateTempList(newListPromo);
    }
  }

  updateTempList = (newListPromo) => {
    this.props.setTempList(newListPromo);
  };

  render() {
    const { translate, tempList, thisQuantity, item, promoType } = this.props;

    const qtyTotalFree = getTotalQtyFreeProductPromo(
      item,
      thisQuantity,
      promoType
    );
    const qtySelected = getTotalSelectedQtyFreeProduct(tempList);
    // console.log(qtySelected, qtyTotalFree);
    // console.log(thisQuantity);
    return (
      <ExpansionPanel
        expanded={this.state.expanded === "panelGridFreePromo"}
        onChange={this.handleChange("panelGridFreePromo")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='free_items-content'
          id='free_items-header'
        >
          <Typography>
            {translate("resources.promotions.panel.free_items")} (
            {this.state.rowData.length}{" "}
            {this.state.rowData.length > 1
              ? translate("resources.po.listgrid.label.items")
              : translate("resources.po.listgrid.label.item")}
            )
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          style={{ width: "100%%", padding: 0, paddingTop: 4 }}
        >
          <Box width={[1]}>
            <Flex
              width={[1]}
              flexDirection={"row"}
              sx={{
                "&>div": {
                  p: [1],
                },
              }}
            >
              <Box>
                <Text fontSize={[0]}>
                  {translate("resources.po.listgrid.label.entitled")}
                </Text>
                <Text color={"red"}>{qtyTotalFree}</Text>
              </Box>
              <Box>
                <Text fontSize={[0]}>
                  {translate("resources.po.listgrid.label.selected")}
                </Text>
                <Text>{qtySelected}</Text>
              </Box>
              <Box>
                <Text fontSize={[0]}>
                  {translate("resources.po.listgrid.label.remaining")}
                </Text>
                <Text style={{ color: "#009C7D" }}>
                  {qtyTotalFree - qtySelected}
                </Text>
              </Box>
            </Flex>
            <div style={{ width: "100%" }}>
              <div className='grid-wrapper'>
                <div
                  id='myGridFree'
                  style={{
                    height: "22vh",
                    width: "100%",
                  }}
                  className='ag-theme-balham'
                >
                  <AgGridReact
                    rowHeight={40}
                    headerHeight={40}
                    rowData={this.state.rowData}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    animateRows={true}
                    getRowNodeId={this.state.getRowNodeId}
                    onGridReady={this.onGridReady}
                    onCellValueChanged={(params) =>
                      this.onCellChanged(params, this)
                    }
                  />
                </div>
              </div>
            </div>
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default ArFreeListGrid;
