import React, { useRef } from "react";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { includes } from "lodash";
import {
  useTranslate,
  getFormInitialValues,
  useDataProvider,
  sanitizeEmptyValues,
  useNotify,
} from "react-admin";
import { Box } from "rebass";
import CustomFormView from "./CustomFormView";
import { HeaderCustomForm } from "./utils";

const CustomSimpleOlehForm = ({
  initialValues,
  defaultValue,
  saving,
  showNotification,
  sourceApi,
  location,
  match,
  history,
  staticContext,
  typePostUrl,
  pathUrl,
  title,
  isShowBack,
  labelBack,
  customBackUrl,
  isHideTitle,
  ...props
}) => {
  const notify = useNotify();
  let redirect = useRef(props.redirect);
  // We don't use state here for two reasons:
  // 1. There no way to execute code only after the state has been updated
  // 2. We don't want the form to rerender when redirect is changed
  const setRedirect = (newRedirect) => {
    redirect.current = newRedirect;
  };

  const finalInitialValues = getFormInitialValues(
    initialValues,
    defaultValue,
    props.record
  );
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const { pathname } = location;
  let paramsUrl = pathname;

  if (typePostUrl === "CREATE") {
    const check = `/create`;
    paramsUrl = pathname.substring(0, pathname.length - check.length);
    if (includes(paramsUrl, "attributeSet")) {
      paramsUrl = "/attributes";
    }
  } else if (typePostUrl === "UPDATE") {
    const check = `/${pathUrl}/`;
    const n = pathname.indexOf(check);
    const record_id = pathname.substring(n + check.length, pathname.length);
    paramsUrl = pathname.replace(`/${pathUrl}/${record_id}`, `/${pathUrl}`);
  }
  if (customBackUrl && customBackUrl !== "") {
    paramsUrl = customBackUrl;
  }

  const submit = (values) => {
    const finalValues = sanitizeEmptyValues(finalInitialValues, values);

    if (typePostUrl === "CREATE") {
      const params = {
        data: finalValues,
      };

      dataProvider
        .create(sourceApi, params)
        .then(({ data }) => {
          if (data) {
            if (data.message && data.message !== "") {
              notify(data.message, "warning", {
                smart_count: 1,
              });
            } else {
              notify("ra.notification.created", "success", {
                smart_count: 1,
              });
              history.push(paramsUrl);
            }
          }
        })
        .catch((error) => {
          if (error && error.message && error.message !== "") {
            notify(error.message, "warning", {
              smart_count: 1,
            });
          } else {
            notify("resources.notification.please_try_again", "warning", {
              smart_count: 1,
            });
          }
        });
    } else if (typePostUrl === "UPDATE") {
      // console.log(finalValues);
      const params = {
        id: finalValues.id,
        data: values,
        previousData: finalValues,
      };
      dataProvider
        .update(sourceApi, params)
        .then(({ data }) => {
          // console.log(data);
          if (data) {
            if (data.message && data.message !== "") {
              notify(data.message, "warning", {
                smart_count: 1,
              });
            } else {
              notify("resources.notification.updated", "success", {
                smart_count: 1,
              });
              history.push(paramsUrl);
            }
          }
          // if (data) {
          // notify("resources.notification.updated", "success", {
          //   smart_count: 1,
          // });
          // }
        })
        .catch((error) => {
          // console.log(error.message);
          if (error && error.message && error.message !== "") {
            notify(error.message, "warning", {
              smart_count: 1,
            });
          } else {
            notify("resources.notification.please_try_again", "warning", {
              smart_count: 1,
            });
          }
        });
    }
  };

  return (
    <Box width={[1]}>
      <HeaderCustomForm
        translate={translate}
        title={title}
        finalInitialValues={finalInitialValues}
        paramsUrl={paramsUrl}
        isShowBack={isShowBack}
        labelBack={labelBack}
        isHideTitle={isHideTitle}
      />
      <Form
        key={props.version}
        initialValues={finalInitialValues}
        onSubmit={submit}
        mutators={{
          ...arrayMutators,
          updateField: ([field, value], state, utils) => {
            utils.changeValue(state, field, () => value);
          },
        }}
        keepDirtyOnReinitialize
        destroyOnUnregister
        subscription={defaultSubscription}
        {...props}
        render={({
          form: {
            mutators: { updateField },
          },
          ...formProps
        }) => (
          <CustomFormView
            saving={formProps.submitting || saving}
            translate={translate}
            setRedirect={setRedirect}
            {...props}
            {...formProps}
            updateField={(key, value) => updateField(key, value)}
          />
        )}
      />
    </Box>
  );
};

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
};

export default CustomSimpleOlehForm;
