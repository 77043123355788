// import {
//   AUTH_LOGIN,
//   AUTH_LOGOUT,
//   AUTH_ERROR,
//   AUTH_CHECK,
//   AUTH_GET_PERMISSIONS,
// } from "react-admin"
import { replace } from "lodash";
import {
  loginClient,
  TOKEN,
  PROFILE,
  ROLE,
  LOCALE,
  TOKEN_NOTIF,
} from "./restClient";
import { ROLE_USER } from "./utils";

export const clearLocalStorage = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(TOKEN_NOTIF);
  localStorage.removeItem(PROFILE);
  localStorage.removeItem(ROLE);
  localStorage.removeItem(LOCALE);
};

export default {
  login: (params) => {
    if (params.email && params.password) {
      return loginClient(JSON.stringify(params))
        .then((response) => response.json())
        .then((response) => {
          // console.log(response);
          if (response && response.token) {
            localStorage.setItem(TOKEN, response.token);
            localStorage.setItem(TOKEN_NOTIF, response.get_stream_token);
            localStorage.setItem(PROFILE, JSON.stringify(response.profile));
            localStorage.setItem(ROLE, response.profile.role);
            if (ROLE_USER.super_admin !== response.profile.role) {
              localStorage.setItem(LOCALE, "id");
            } else {
              localStorage.setItem(LOCALE, "en");
            }
            return Promise.resolve(response);
          } else {
            return Promise.reject();
          }
        })
        .catch((e) => {
          console.error(e);
          return Promise.reject();
        });
    } else {
      return Promise.reject();
    }
  },
  logout: (params) => {
    clearLocalStorage();
    // console.log("masuk logout", params);
    return Promise.resolve();
  },
  checkAuth: (params) => {
    // console.log(window.location);
    const myHref = window.location.href;
    const myOrigin = window.location.origin;
    const myRedirect = replace(myHref, `${myOrigin}/#`, "");
    return localStorage.getItem(TOKEN) || localStorage.getItem(TOKEN) !== null
      ? Promise.resolve()
      : Promise.reject({
          redirectTo: `/login?redirectTo=${myRedirect}`,
        });
  },
  checkError: (error) => {
    const { status } = error;
    if (status === 401 || status === 403) {
      clearLocalStorage();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: (params) => {
    // console.log(params);
    const role = localStorage.getItem(ROLE);
    return Promise.resolve(role);
  },
  getIdentity: () => Promise.resolve(localStorage.getItem(PROFILE)),
};

// export default (type, params) => {
//   // called when the user attempts to log in
//   if (type === AUTH_LOGIN) {
//     console.log("AUTH_LOGIN", params)
//     if (params.username && params.password) {
//       return loginClient(JSON.stringify(params))
//         .then((response) => response.json())
//         .then((response) => {
//           // console.log(response)
//           localStorage.setItem(TOKEN, response.token)
//           localStorage.setItem(PROFILE, JSON.stringify(response.profile))
//           localStorage.setItem(ROLE, response.profile.role)
//           return Promise.resolve()
//         })
//         .catch((e) => {
//           console.error(e)
//           return Promise.reject()
//         })
//     }
//   }
//   // called when the user clicks on the logout button
//   if (type === AUTH_LOGOUT) {
//     clearLocalStorage()
//     // console.log("masuk logout", params)
//     return Promise.resolve()
//   }
//   // called when the API returns an error
//   if (type === AUTH_ERROR) {
//     const { status } = params
//     if (status === 401 || status === 403) {
//       clearLocalStorage()
//       return Promise.reject()
//     }
//     return Promise.resolve()
//   }
//   // called when the user navigates to a new location
//   if (type === AUTH_CHECK) {
//     const { resource } = params
//     if (
//       resource === "registration" ||
//       resource === "setup" ||
//       resource === "approved"
//     ) {
//       // check credentials for the posts resource
//       // console.log(params)
//       return Promise.resolve()
//     }

//     return localStorage.getItem(TOKEN) ? Promise.resolve() : Promise.reject()
//   }
//   if (type === AUTH_GET_PERMISSIONS) {
//     const role = localStorage.getItem(ROLE)
//     return Promise.resolve(role)
//   }
//   return Promise.reject("Unknown method")
// }
