import React from "react";
// import { DataGrid } from "@material-ui/data-grid";
// import { columns, columnsDealer, sortModel, sortModelDealer } from "./";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import {
  NumberParser2Decimals,
  NumberParserPercent,
  lowerCaseComparator,
  NumberParser2DecimalsWithMinus,
} from "../utils";

/**
 * { field: "category", headerName: "Category", width: 200 },
  { field: "target_setting", headerName: "Target Setting", width: 200 },
  { field: "speed_growth", headerName: "Speed/Growth", width: 200 },
  { field: "last_year", headerName: "Last Year", width: 160, ...numberdesimal },
  { field: "target", headerName: "Target", width: 130, ...numberdesimal },
  { field: "invoiced", headerName: "Invoiced", width: 160, ...numberdesimal },
  { field: "gap", headerName: "GAP", width: 130, ...numberdesimal },
  { field: "ratio", headerName: "Ratio %", width: 130, ...numberdesimal },
 */
const defaultFreeColumnDefsDealer = [
  {
    field: "category",
    headerName: "Category",
    width: 200,
    editable: false,
    resizable: true,
  },
  {
    field: "target_setting",
    headerName: "Target Setting",
    minWidth: 200,
    editable: false,
    resizable: true,
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },
  {
    field: "speed_growth",
    headerName: "Speed/Growth",
    width: 130,
    editable: false,
    resizable: true,
  },
  {
    field: "last_year",
    headerName: "Last Year",
    width: 100,
    editable: false,
    resizable: true,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
    valueFormatter: NumberParser2Decimals,
  },
  {
    field: "target",
    headerName: "Target",
    width: 100,
    editable: false,
    resizable: true,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    field: "invoiced",
    headerName: "Invoiced",
    width: 100,
    editable: false,
    resizable: true,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    field: "gap",
    headerName: "GAP",
    width: 100,
    editable: false,
    resizable: true,
    valueFormatter: NumberParser2DecimalsWithMinus,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    field: "ratio",
    headerName: "Achievement %",
    width: 100,
    editable: false,
    resizable: true,
    valueFormatter: NumberParserPercent,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
];
const defaultFreeColumnDefs = [
  {
    field: "country",
    headerName: "Country",
    width: 130,
    editable: false,
    resizable: true,
  },
  {
    field: "dealer",
    headerName: "Dealer",
    width: 200,
    editable: false,
    resizable: true,
  },
  {
    field: "category",
    headerName: "Category",
    width: 200,
    editable: false,
    resizable: true,
  },
  {
    field: "target_setting",
    headerName: "Target Setting",
    minWidth: 200,
    editable: false,
    resizable: true,
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },
  {
    field: "speed_growth",
    headerName: "Speed/Growth",
    width: 130,
    editable: false,
    resizable: true,
  },
  {
    field: "last_year",
    headerName: "Last Year",
    width: 100,
    editable: false,
    resizable: true,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
    valueFormatter: NumberParser2Decimals,
  },
  {
    field: "target",
    headerName: "Target",
    width: 100,
    editable: false,
    resizable: true,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    field: "invoiced",
    headerName: "Invoiced",
    width: 100,
    editable: false,
    resizable: true,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    field: "gap",
    headerName: "GAP",
    width: 100,
    editable: false,
    resizable: true,
    valueFormatter: NumberParser2DecimalsWithMinus,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    field: "ratio",
    headerName: "Achievement %",
    width: 100,
    editable: false,
    resizable: true,
    valueFormatter: NumberParserPercent,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
];

export default (props) => {
  // const [pageSize, setPageSize] = React.useState(20);
  const { list, dealerId } = props;
  const state = {
    columnDefs:
      dealerId !== null ? defaultFreeColumnDefsDealer : defaultFreeColumnDefs,
    defaultColDef: {
      flex: 1,
      editable: false,
      sortable: true,
      filter: true,
      minWidth: 100,
      // cellStyle: {
      //   "line-height": "40px",
      // },
    },
    getRowNodeId: function (data) {
      return data.category;
    },
  };

  // const onPageSizeChange = (e) => {
  //   // console.log(e);
  //   setPageSize(e.pageSize);
  // };
  // const heightContainer =
  //   list.length > pageSize
  //     ? pageSize * 55 + 110
  //     : list.length > 0
  //     ? pageSize * 55 + 110
  //     : 300;
  // const sortModelDefault = dealerId !== null ? sortModelDealer : sortModel;
  // const columnDefaultDealer = dealerId !== null ? columnsDealer : columns;
  return (
    // <div style={{ height: heightContainer, width: "100%" }}>
    //   <DataGrid
    //     sortModel={sortModelDefault}
    //     rows={list}
    //     columns={columnDefaultDealer}
    //     pageSize={20}
    //     rowsPerPageOptions={[10, 20, 25, 50]}
    //     onPageSizeChange={onPageSizeChange}
    //   />
    // </div>
    <div className='grid-wrapper'>
      <div
        id='myGridInvoiced'
        className='ag-theme-balham'
        style={{ height: "50vh", width: "100%" }}
      >
        <AgGridReact
          rowHeight={40}
          headerHeight={40}
          rowData={list}
          columnDefs={state.columnDefs}
          defaultColDef={state.defaultColDef}
          animateRows={true}
        />
      </div>
    </div>
  );
};
