import React, { useState, useEffect } from "react";
import { keyBy } from "lodash";
import { Box, Flex } from "rebass";
import {
  useQueryWithStore,
  Loading,
  TextField,
  ReferenceField,
} from "react-admin";

import {
  Pagination,
  RaDateField,
  Datagrid,
  ActiveField,
  ButtonShowEditView,
} from "../../components";
import MenuIcon from "@material-ui/icons/Menu";

export default (props) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [isRequest, setIsRequest] = useState(false);
  const { data, total, loading } = useQueryWithStore({
    type: "getList",
    resource: "priceBooks",
    payload: {
      pagination: { page, perPage },
      sort: { field: "id", order: "ASC" },
      filter: { type: "Customer" },
    },
  });
  useEffect(() => {
    let timeout = setTimeout(() => {
      if (isRequest) {
        setIsRequest(false);
      }
    }, 500);
    return () => clearTimeout(timeout);
  });

  if (loading) {
    return (
      <Flex
        width={[1]}
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
        style={{ height: "60vh" }}
      >
        <Loading />
      </Flex>
    );
  }
  return (
    <Box width={[1]} p={[2]}>
      {data && data.length > 0 && (
        <Flex width={[1]} flexDirection='column'>
          <Datagrid
            data={keyBy(data, "id")}
            ids={data.map(({ id }) => id)}
            currentSort={{ field: "id", order: "ASC" }}
            basePath='/priceBooks' // required only if you set use "rowClick"
            // rowClick="edit"
          >
            <TextField
              source='name'
              label={"resources.pricebook.fields.name"}
            />
            <ReferenceField
              source='country_id'
              label={"resources.pricebook.fields.country"}
              reference='countries'
              link={false}
            >
              <TextField source={"name"} />
            </ReferenceField>

            <RaDateField
              {...props}
              source='start_at'
              label='resources.pricebook.fields.start_at'
            />
            <RaDateField
              {...props}
              source='end_at'
              label='resources.pricebook.fields.end_at'
            />
            <ActiveField
              source='active'
              label={"resources.pricebook.fields.active"}
            />
            <ButtonShowEditView
              {...props}
              iconshow={<MenuIcon fontSize={"small"} />}
              isCustomLink={true}
              taburl='priceBooks'
              paramUrl='customer'
            />
          </Datagrid>
          <Pagination
            page={page}
            perPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            setPage={(v) => setPage(v)}
            setPerPage={(v) => setPerPage(v)}
            total={
              total === null
                ? data && data.length > 0
                  ? data.length
                  : 0
                : total
            }
          />
        </Flex>
      )}
    </Box>
  );
};
