import jsonServerRestClient from "ra-data-json-server";
import { fetchUtils } from "react-admin";

export const API_DEBUG = "/api"; //"https://v-gc-dental.herokuapp.com";
// export const API_SERVER = process.env.REACT_APP_SERVER || API_DEBUG;
export const API_SERVER = "/api";
// console.log(API_SERVER)
export const APPKEY_NOTIFICATION =
  process.env.NOTIFICATION_API_KEY || "sg3jexrgdgcq";
export const APPID_NOTIFICATION = process.env.NOTIFICATION_API_ID || "105138";
export const TOKEN = "token_gc_dental";
export const TOKEN_NOTIF = "n_t_gc_gental";
export const PROFILE = "profile_gc_dental";
export const ROLE = "role_gc_dental";
export const LOCALE = "locale_gc_dental";
export const NOAUTHPAGE = "no_auth_page_gc_dental";

export const PromoTypeB = {
  buyxgety: "Buy X Get Y",
  buyabgetcd: "Buy AB Get CD",
  discount: "Discount",
  sp: "Special Price",
};

const httpClient = (url, options = {}) => {
  // console.log(url)
  if (!options.headers) {
    options.headers = new Headers({
      Accept: "application/json; charset=UTF-8",
    });
  }
  const token = localStorage.getItem(TOKEN);
  // console.log(token)
  if (token && token !== null) {
    options.headers.set("Authorization", `Bearer ${token}`);
  }

  //can change url in here

  const resource = url.replace(API_SERVER, "");
  // console.log(resource)
  // console.log(url)
  if (resource.includes("programmes")) {
    url = url.replace("programmes", "loyaltyPrograms");
  } else if (resource.includes("/purchaseOrder")) {
    url = url.replace("purchaseOrder", "purchaseOrders");
    // console.log(url)
  } else if (resource.includes("/orders")) {
    url = url.replace("orders", "purchaseOrders");
    // console.log(url)
  } else if (resource.includes("catalogues")) {
    url = url.replace("catalogues", "catalogs");
  } else if (resource.includes("register")) {
    url = url.replace("register", "herokuProvisions");
    if (url.includes("herokuProvisions?")) {
      url = url + `&status=${encodeURIComponent("Pending review")}`;
    }
  } else if (resource.includes("provisions")) {
    url = url.replace("provisions", "herokuProvisions");
    if (url.includes("herokuProvisions?")) {
      url = url + `&status=${encodeURIComponent("Approved")}`;
    }
  }
  return fetchUtils.fetchJson(url, options);
};

export const dataProvider = jsonServerRestClient(API_SERVER, httpClient);

export const UploadClient = (formData, path) => {
  var myHeaders = new Headers();
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);
  return fetch(API_SERVER + path, {
    method: "POST",
    body: formData,
    headers: myHeaders,
  });
};

export const UploadClientCSV = (formData, path) => {
  //
  let myHeaders = new Headers();
  // console.log(API_SERVER + path, formData);
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);
  return fetch(API_SERVER + path, {
    method: "POST",
    body: formData,
    headers: myHeaders,
  });
};

export const RestApi = (urlParams) => {
  let myHeaders = new Headers({ "Content-Type": "application/json" });
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);
  const url = API_SERVER + urlParams;
  return fetch(url, {
    method: "GET",
    headers: myHeaders,
  });
};
export const RestApiCURD = (urlParams, method = "GET", params) => {
  let options = {};
  if (!options.headers) {
    options.headers = new Headers({ "Content-Type": "application/json" });
  }
  const token = localStorage.getItem(TOKEN);
  if (token) {
    options.headers.set("Authorization", `Bearer ${token}`);
  }

  options.method = method;
  if (params) {
    options.body = JSON.stringify(params);
  }
  const url = `${API_SERVER}/${urlParams}`;
  return fetch(url, options);
};

export const RestApiReward = (urlParams, method = "GET", params) => {
  let options = {};
  if (!options.headers) {
    options.headers = new Headers({ "Content-Type": "application/json" });
  }
  const token = localStorage.getItem(TOKEN);
  if (token) {
    options.headers.set("Authorization", `Bearer ${token}`);
  }

  options.method = method;
  if (params) {
    options.body = JSON.stringify(params);
  }
  const url = `${API_SERVER}/${urlParams}`;
  return fetch(url, options);
};

export const loginClient = (params) => {
  const url = API_SERVER + "/sessions";
  return fetch(url, {
    method: "POST",
    body: params,
    headers: new Headers({ "Content-Type": "application/json" }),
  });
};
export const loginClientPwd = (urlPath, params) => {
  const url = `${API_SERVER}/sessions/${urlPath}`;
  return fetch(url, {
    method: "POST",
    body: params,
    headers: new Headers({ "Content-Type": "application/json" }),
  });
};
export const loginClientJWT = (params) => {
  const url = API_SERVER + "/../mc/sessions/token";
  return fetch(url, {
    method: "POST",
    body: params,
    headers: new Headers({ "Content-Type": "application/json" }),
  });
};

export const searchProduct = (params) => {
  var myHeaders = new Headers({
    "Content-Type": "application/json",
  });
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);

  const url = API_SERVER + "/products?search=" + params;

  return fetch(url, {
    method: "GET",
    headers: myHeaders,
  });
};
export const getPromotion = (id) => {
  const url = `${API_SERVER}/products/${id}/promotions`; //API_SERVER + "" + params
  var myHeaders = new Headers({
    "Content-Type": "application/json",
  });
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);

  return fetch(url, {
    method: "GET",
    headers: myHeaders,
  });
};

export const getProducts = (params) => {
  const url = params
    ? `${API_SERVER}/products?${params}`
    : `${API_SERVER}/products`; //API_SERVER + "" + params
  var myHeaders = new Headers({
    "Content-Type": "application/json",
  });
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);

  return fetch(url, {
    method: "GET",
    headers: myHeaders,
  }).then((response) => response.json());
};
export const getProductsList = (dealerId) => {
  const url = dealerId
    ? `${API_SERVER}/products?dealer_id=${dealerId}`
    : `${API_SERVER}/products`; //API_SERVER + "" + params
  var myHeaders = new Headers({
    "Content-Type": "application/json",
  });
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);

  return fetch(url, {
    method: "GET",
    headers: myHeaders,
  }).then((response) => response.json());
};
export const getCountries = () => {
  const url = `${API_SERVER}/countries`; //API_SERVER + "" + params
  var myHeaders = new Headers({
    "Content-Type": "application/json",
  });
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);

  return fetch(url, {
    method: "GET",
    headers: myHeaders,
  }).then((response) => response.json());
};

export const getAllProducts = (params) => {
  const apiRequest = [];
  params.map((item) => {
    const url = `products?${item}`; //API_SERVER + "" + params
    var myHeaders = new Headers({
      "Content-Type": "application/json",
    });
    const token = localStorage.getItem(TOKEN);
    myHeaders.append("Authorization", `Bearer ${token}`);

    const options = {
      method: "GET",
      headers: myHeaders,
    };
    apiRequest.push(request(url, options));

    return null;
  });
  return Promise.all(apiRequest).then((values) => values);
};
export const getProductsWithId = (params) => {
  const url = `${API_SERVER}/products${params}`; //API_SERVER + "" + params
  var myHeaders = new Headers({
    "Content-Type": "application/json",
  });
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);

  return fetch(url, {
    method: "GET",
    headers: myHeaders,
  }).then((response) => response.json());
};
export const getAllProductsWithId = (params) => {
  const apiRequest = [];
  params.map((item) => {
    const url = `products${item}`; //API_SERVER + "" + params
    var myHeaders = new Headers({
      "Content-Type": "application/json",
    });
    const token = localStorage.getItem(TOKEN);
    myHeaders.append("Authorization", `Bearer ${token}`);

    const options = {
      method: "GET",
      headers: myHeaders,
    };
    apiRequest.push(request(url, options));

    return null;
  });
  return Promise.all(apiRequest).then((values) => values);
};

export const getCodes = (params) => {
  const url = `${API_SERVER}/codes?${params}`; //API_SERVER + "" + params
  var myHeaders = new Headers({
    "Content-Type": "application/json",
  });
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);

  return fetch(url, {
    method: "GET",
    headers: myHeaders,
  });
};

export const updateLogin = (response) => {
  localStorage.setItem(TOKEN, response.token);
  localStorage.setItem(PROFILE, JSON.stringify(response.profile));
  localStorage.setItem(ROLE, response.profile.role);
};

export const postOrderItems = (params) => {
  const apiRequest = [];
  params.map((item) => {
    const options = {
      method: "POST",
      body: item,
      headers: new Headers({ "Content-Type": "application/json" }),
    };
    apiRequest.push(request("orderItems", options));

    return null;
  });
  return Promise.all(apiRequest).then((values) => values);
};
export const updateOrderItems = (params) => {
  const apiRequest = [];
  params.map((item) => {
    const options = {
      method: "PUT",
      body: item,
      headers: new Headers({ "Content-Type": "application/json" }),
    };

    apiRequest.push(request("orderItems", options));

    return null;
  });
  return Promise.all(apiRequest).then((values) => values);
};

function request(url, options) {
  if (!options) {
    options = {};
  }
  if (!options.headers) {
    options.headers = new Headers({ "Content-Type": "application/json" });
  } else {
    options.headers.set("Content-Type", "application/json");
  }
  if (options.body) {
    options.body = JSON.stringify(options.body);
  }

  return fetch(`${API_SERVER}/${url}`, options).then((response) =>
    response.json()
  );
}

export const getOrderList = (dealerId) => {
  const url = dealerId
    ? `${API_SERVER}/purchaseOrders?dealer_id=${dealerId}`
    : `${API_SERVER}/purchaseOrders`; //API_SERVER + "" + params
  var myHeaders = new Headers({
    "Content-Type": "application/json",
  });
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);

  return fetch(url, {
    method: "GET",
    headers: myHeaders,
  }).then((response) => response.json());
};

export const getOrderListParams = (params) => {
  const url = params
    ? `${API_SERVER}/purchaseOrders?${params}`
    : `${API_SERVER}/purchaseOrders`; //API_SERVER + "" + params
  var myHeaders = new Headers({
    "Content-Type": "application/json",
  });
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);

  return fetch(url, {
    method: "GET",
    headers: myHeaders,
  }).then((response) => response.json());
};

export const getProductById = (paramsId) => {
  var myHeaders = new Headers({
    "Content-Type": "application/json",
  });
  const token = localStorage.getItem(TOKEN);
  myHeaders.append("Authorization", `Bearer ${token}`);

  return fetch(`${API_SERVER}/products/${paramsId}`, {
    method: "GET",
    headers: myHeaders,
  }).then((response) => response.json());
};
