import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ListIcon } from "../../icon_svg";
import { useStoreUrl } from "./utils";
import Button from "./Button";

const ListUrlButton = ({
  basepath = "",
  label = "ra.action.list",
  icon,
  ...rest
}) => {
  const { url } = useStoreUrl();
  const myUrl = url !== null ? url : basepath;
  return (
    <Button
      component={Link}
      to={myUrl}
      label={label}
      {...rest}
      sizeIcon={"xsmall"}
      style={{ textTransform: "capitalize" }}
    >
      {icon}
    </Button>
  );
};
ListUrlButton.propTypes = {
  basepath: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.element,
};

ListUrlButton.defaultProps = {
  icon: <ListIcon />,
};

export default ListUrlButton;
