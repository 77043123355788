import React, { Fragment } from "react";
import { get } from "lodash";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useQueryWithStore } from "react-admin";
import { StyledTableCell } from "./styles";
import CountryField from "./CountryField";
const DealerItemField = (props) => {
  const { item } = props;
  const dealer_id = get(item, "dealer_id", "0");
  const { data, loading } = useQueryWithStore({
    type: "getOne",
    resource: "dealers",
    payload: { id: dealer_id },
  });
  if (loading) {
    return (
      <Fragment>
        <StyledTableCell component="td" scope="row">
          <LinearProgress />
        </StyledTableCell>
        <StyledTableCell component="td" scope="row">
          <LinearProgress />
        </StyledTableCell>
      </Fragment>
    );
  }
  //   console.log(data, item);
  return (
    <Fragment>
      <StyledTableCell component="td" scope="row">
        <CountryField child={data} />
      </StyledTableCell>
      <StyledTableCell component="td" scope="row">
        {get(data, "name", "")}
      </StyledTableCell>
    </Fragment>
  );
};
export default DealerItemField;
