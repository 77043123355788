import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
import { useHistory } from "react-router-dom";
import { styles } from "./styles";
import SubMenuItemLink from "./SubMenuItemLink";
import MenuItemLink from "../MenuItemLink";
import { BagIcon, ViewIcon, BadgeIcon } from "../../icon_svg";

const MenuProducts = (props) => {
  const { classes, onMenuClick } = props;
  const history = useHistory();
  const [openMenu, setOpenMenu] = useState(false);
  const translate = useTranslate();
  const handleChange = () => {
    setOpenMenu(!openMenu);
    history.push("/products");
  };
  return (
    <SubMenuItemLink
      handleToggle={() => handleChange()}
      isOpen={openMenu}
      classes={classes}
      sidebarIsOpen={openMenu}
      name='resources.menus.products'
      icon={<BagIcon />}
      location={history.location}
      page='/products'
    >
      {/* <MenuItemLink
        to={`/products`}
        primaryText={translate(`resources.menus.list`, {
          smart_count: 2,
        })}
        onClick={onMenuClick}
        leftIcon={<BagIcon />}
      /> */}
      <MenuItemLink
        to={`/promotions`}
        primaryText={translate(`resources.menus.promotions`, {
          smart_count: 2,
        })}
        leftIcon={<BadgeIcon />}
        onClick={onMenuClick}
        translate={translate}
      />
      <MenuItemLink
        to={`/priceBooks`}
        primaryText={translate(`resources.menus.price_book`, {
          smart_count: 2,
        })}
        leftIcon={<ViewIcon />}
        onClick={onMenuClick}
        translate={translate}
      />

      {/*
      <MenuItemLink
        to={`/categories`}
        primaryText={translate(`resources.menus.categories`, {
          smart_count: 2,
        })}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to={`/collections`}
        primaryText={translate(`resources.menus.collections`, {
          smart_count: 2,
        })}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to={`/attributes`}
        primaryText={translate(`resources.menus.attributes`, {
          smart_count: 2,
        })}
        onClick={onMenuClick}
      /> */}
    </SubMenuItemLink>
  );
};

export default withStyles(styles)(MenuProducts);
