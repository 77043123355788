import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { get, find, toLower } from "lodash"; //findIndex
import { withDataProvider } from "react-admin";
import ActiveAgGridField from "./ActiveAgGridField";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import queryString from "query-string";
import { showAdminSales } from "../../utils";
// import CustomHeader from "./CustomHeader";
import numeral from "numeral";
const CUR_WIDTH = 160;
// const PER_WIDTH = 55

function currencyFormatter(params) {
  console.log(numeral(params.value * 1).format("0,0.00"));
  return numeral(params.value * 1).format("0,0.00"); // "\xA3" + formatNumber(params.value);
}

// const NumberParser = (params) => {
//   return Number(params.newValue);
// };

class AgGrid extends Component {
  constructor(props) {
    super(props);
    const { permissions, location } = props;
    const parsed = queryString.parse(location.search);
    const sortByField =
      get(parsed, "sort", "packaging") === "packaging"
        ? { sort: toLower(get(parsed, "order", "asc")) }
        : {};

    const sortByFieldPrice =
      get(parsed, "sort", "price") === "price"
        ? { sort: toLower(get(parsed, "order", "asc")) }
        : {};
    const sortByFieldCode =
      get(parsed, "sort", "code") === "code"
        ? { sort: toLower(get(parsed, "order", "asc")) }
        : {};
    this.state = {
      columns: [
        {
          headerName: "Product",
          field: "packaging",
          minWidth: 300,
          resizable: true,
          editable: false,
          sortable: true,
          sortingOrder: ["asc", "desc"],
          ...sortByField,
        },
        {
          headerName: "Code",
          field: "code",
          minWidth: 200,
          resizable: true,
          editable: false,
          sortable: true,
          sortingOrder: ["asc", "desc"],
          ...sortByFieldCode,
        },
        {
          headerName: "Default Price",
          field: "default_price",
          editable: false,
          minWidth: CUR_WIDTH,
          resizable: true,
          sortable: false,
          cellStyle: { textAlign: "right" },
          valueFormatter: currencyFormatter,
        },
        {
          headerName: "Price",
          field: "price",
          editable: showAdminSales(permissions),
          valueFormatter: currencyFormatter,
          minWidth: CUR_WIDTH,
          resizable: true,
          sortable: true,
          cellStyle: { textAlign: "right" },
          sortingOrder: ["asc", "desc"],
          ...sortByFieldPrice,
        },
        {
          headerName: "Active",
          field: "active",
          minWidth: 100,
          cellRenderer: "activeAgGridField",
          cellRendererParams: { onActiveChange: this.onActiveChange },
          editable: false,
          resizable: true,
          sortable: true,
          sortingOrder: ["asc", "desc"],
        },
      ],
      frameworkComponents: {
        activeAgGridField: ActiveAgGridField,
        // agColumnHeader: CustomHeader,
      },
      data: [],
      gridApi: null,
      gridColumnApi: null,
    };
    // console.log(props);
    this.onActiveChange = this.onActiveChange.bind(this);
  }

  onPackagingSort = (e) => {
    const gridColumn = get(e, "columnApi.columnController.gridColumns", []);
    const newColoumn = find(gridColumn, function (o) {
      return o.sort;
    });
    if (newColoumn && newColoumn.colId && newColoumn.colId !== "") {
      if (newColoumn.colId !== "default_price") {
        const { location } = this.props;
        const parsed = queryString.parse(location.search);

        // const sorting =
        //   toLower(get(newColoumn, "sort", "")) === "asc" ? "DESC" : "ASC";

        this.props.setSortField(get(newColoumn, "colId", ""));
        // this.props.setOrdered(sorting);

        if (parsed && parsed.order) {
          this.props.setOrdered("ASC");
          this.props.setSort(get(newColoumn, "colId", ""), "ASC");
        } else {
          // console.log("masuk sini", parsed);
          this.props.setOrdered("ASC");
          this.props.setSort(get(newColoumn, "colId", ""), "ASC");
        }
      }
    }
  };

  onActiveChange = (value, defaultData) => {
    // console.log(value, defaultData);
    const { dataProvider } = this.props;
    let myParams = defaultData;
    myParams.active = value === "true" || value === true ? true : false;
    // console.log(myParams);
    dataProvider
      .update("priceBookDetails", {
        id: defaultData.id,
        data: myParams,
        previousData: defaultData,
      })
      .then(({ data }) => {})
      .catch((error) => {});
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      // console.log(nextProps.ids)
      let data = [];
      for (var i = 0; i < nextProps.ids.length; i++) {
        const myData = nextProps.data[nextProps.ids[i]];
        const myPrice = get(myData, "price", 0) * 1;
        // console.log(myPrice);
        const myDefaultPrice = get(myData, "default_price", 0) * 1;
        const newParams = {
          ...myData,
          default_price: numeral(myDefaultPrice).format("0,0.00"),
          price: myPrice, //numeral(myPrice).format("0,0.00"),
        };
        data.push(newParams);
      }
      // const listSort = orderBy(
      //   data,
      //   [nextProps.sortField],
      //   [toLower(nextProps.ordered)]
      // ); // sortBy(data, ["packaging"]);
      // console.log("nextProps.data", nextProps.data);
      // console.log(nextProps.sortField, nextProps.ordered);
      // console.log("listSort", listSort);
      this.setState({ data: data });
    }
  }

  onCellChanged(params, locale) {
    // console.log("on cell changed", params);
    locale.onUpdateItem(params);
  }
  onUpdateItem = (params) => {
    const { dataProvider } = this.props;
    const active = get(params, "data.active", false);
    let myParams = params.data;
    myParams.active = active === "true" || active === true ? true : false;
    myParams.price = numeral(get(params, "data.price", 0)).value();
    dataProvider
      .update("priceBookDetails", {
        id: params.data.id,
        data: myParams,
      })
      .then(({ data }) => {
        // console.log(data);
      })
      .catch((error) => {});
  };
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    var allColumnIds = [];
    // console.log(params.columnApi)

    this.gridColumnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds);
    // params.api.sizeColumnsToFit();
  }
  render() {
    return (
      <div
        style={{
          height: "400px",
          width: "97%",
          padding: "20px",
        }}
        className='ag-theme-balham'
      >
        <AgGridReact
          columnDefs={this.state.columns}
          rowData={this.state.data}
          frameworkComponents={this.state.frameworkComponents}
          onCellValueChanged={(params) => this.onCellChanged(params, this)}
          onGridReady={this.onGridReady}
          sortingOrder={["desc", "asc"]}
          onSortChanged={this.onPackagingSort}
        />
      </div>
    );
  }
}

export default withDataProvider(AgGrid);
