import React from "react";
import { Flex, Box } from "rebass";
import { useTranslate } from "react-admin";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Paper from "@material-ui/core/Paper";
import { TextInput } from "react-admin";
//DateInput,
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "../../../components";
// import { formatDate } from "../../../utils";
const DetailFields = (props) => {
  const translate = useTranslate();
  const [state, setState] = React.useState({
    expanded: "panelDetail",
  });

  const handleChange = (panel) => (event, newExpanded) => {
    setState({
      ...state,
      expanded: newExpanded ? panel : false,
    });
  };

  return (
    <Box width={[1]}>
      <Paper elevation={3} style={{ padding: 0 }}>
        <Box width={[1]} p={[2]}>
          <ExpansionPanel
            expanded={state.expanded === "panelDetail"}
            onChange={handleChange("panelDetail")}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='detail-list-content'
              id='detail-list-header'
            >
              <Typography>
                {translate("resources.promotions.panel.details")}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              style={{ width: "100%%", padding: 0, paddingTop: 16 }}
            >
              <Box width={[1]}>
                <Flex
                  width={[1]}
                  flexDirection={"row"}
                  flexWrap={"wrap"}
                  justifyContent={"flex-start"}
                  sx={{
                    "&> div": {
                      p: [2],
                    },
                  }}
                >
                  {/* <div>
                    <DateInput
                      source='delivery_date'
                      label='resources.po.fields.delivery_date'
                      style={{ width: 200 }}
                      parse={formatDate}
                      options={{ format: "DD-MMM-YYYY" }}
                    />
                  </div> */}
                  <div>
                    <TextInput
                      source='my_reference_number'
                      label='resources.po.fields.ref_number'
                      style={{ width: 300 }}
                    />
                  </div>
                </Flex>
                <Box width={[1]} p={[3]} pt={[0]}>
                  <TextInput
                    fullWidth
                    multiline
                    source='remarks'
                    label='resources.po.fields.remarks'
                  />
                </Box>
              </Box>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Box>
      </Paper>
    </Box>
  );
};

export default DetailFields;
