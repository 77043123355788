import React, { useEffect, Fragment } from "react";
import { get, chain, find } from "lodash";
import { useTranslate } from "react-admin";
import { Flex, Box, Text } from "rebass";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import { grey } from "@material-ui/core/colors";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import moment from "moment";
import {
  // StyledTableAction,
  StyledTableCell,
  StyledTableRow,
  StyledTableSmall,
  PromoType_B,
} from "./edit";
import {
  BuyABGetCDItems,
  BuyXGetYItems,
  SpecialPriceItems,
  DiscountItems,
  NormalItems,
} from "./order_item";
import TotalQuantity from "./TotalQuantity";
import EditTotalTable from "./order_item/EditTotalTable";
import CreateTotalTable from "./order_item/CreateTotalTable";
const EmptyList = () => {
  const translate = useTranslate();

  return (
    <Box width={[1]} p={[2]}>
      <Flex
        width={[1]}
        justifyContent='center'
        alignItems={"center"}
        flexDirection={"column"}
        sx={{
          border: "1px solid #C3c3c3",
          height: 300,
        }}
      >
        <InsertDriveFileOutlinedIcon
          style={{ fontSize: "60pt", color: grey[300] }}
        />

        <Text p={[3]} textAlign={"center"}>
          {translate("resources.po.messages.no_have_items")}
        </Text>
      </Flex>
    </Box>
  );
};

const ListOrders = (props) => {
  const {
    formData,
    onAddItem,
    onSelectItem,
    onSetTotal,
    totalQty,
    totalAmount,
    isOAAsossiate,
    onRemoveItem,
  } = props;
  const products =
    get(formData, "items", []) !== null ? get(formData, "items", []) : [];
  const translate = useTranslate();
  const current = moment();
  useEffect(() => {
    // This gets called after every render, by default
    // (the first one, and every one after that)
    // console.log("render!");
    let totalQuantity = 0;
    let totalAmt = 0;

    let newProduct = [];
    products.map((item) => {
      if (item.promotions !== null) {
        // console.log(item);
        const isEndDate = moment(
          get(item, "promotions.end_date", "")
        ).isSameOrAfter(current, "day");
        if (isEndDate) {
          newProduct.push(item);
        }
      } else {
        newProduct.push(item);
      }
      return "";
    });

    newProduct.map((item) => {
      // console.log("item", item);
      totalQuantity = totalQuantity + item.qty * 1;
      totalAmt = totalAmt + item.subtotal * 1;
      return "";
    });
    // console.log(totalQuantity, totalAmt);
    // console.log(products);
    // console.log(newProduct);
    onCheckExpiredDate(newProduct, products);
    if (newProduct.length > 0) {
      onSetTotalQA(totalQuantity, totalAmt);
    }

    // console.log("masuk sini ga ", products);
    // If you want to implement componentWillUnmount,
    // return a function from here, and React will call
    // it prior to unmounting.
    // return () => console.log("unmounting...");
    // eslint-disable-next-line
  }, [totalQty, totalAmount, formData, products]);

  const onSetTotalQA = (totalQuantity, totalAmt) => {
    onSetTotal(totalQuantity, totalAmt);
  };
  const onCheckExpiredDate = (newProduct, products) => {
    props.onCheckExpiredDate(newProduct, products);
  };

  if (products === null || products.length === 0) {
    return <EmptyList />;
  }
  const listProducts = chain(products)
    .groupBy("promotions.id")
    .map((value, key) => {
      const item = find(value, function (o) {
        return get(o, "promotions.id", 0) + "" === key + "";
      });
      return {
        id: key && key !== "undefined" ? key : "normal",
        promotion_type: get(
          item,
          "promotions.promotion_type",
          key && key !== "undefined" ? key : ""
        ),
        list: value,
        promotions: get(item, "promotions", null),
      };
    })
    .value();
  // console.log("listProducts", formData);
  return (
    <Box width={[1]} p={[3]}>
      <TableContainer component={"div"}>
        <Table aria-label='customized table'>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell style={{ textAlign: "left", paddingLeft: 30 }}>
                {translate("resources.po.table.packaging")}
              </StyledTableCell>
              <StyledTableSmall style={{ textAlign: "center", width: 130 }}>
                {translate("resources.po.table.code")}
              </StyledTableSmall>
              <StyledTableSmall style={{ textAlign: "right", width: 90 }}>
                {translate("resources.po.table.price")}
              </StyledTableSmall>
              <StyledTableSmall style={{ textAlign: "right", width: 150 }}>
                {translate("resources.po.table.discounted_price")}
              </StyledTableSmall>
              <StyledTableSmall style={{ textAlign: "right", width: 80 }}>
                {translate("resources.po.table.qty")}
              </StyledTableSmall>
              <StyledTableSmall style={{ textAlign: "right", width: 100 }}>
                {translate("resources.po.table.subtotal")}
              </StyledTableSmall>
            </StyledTableRow>
          </TableHead>
        </Table>
      </TableContainer>
      {listProducts.map((child, index) => {
        const PromoType = get(child, "promotion_type", "");
        const endDate = get(child, "promotions.end_date");
        const isExpired =
          get(child, "promotions.end_date") &&
          moment(current).isAfter(endDate, "day");
        return (
          <Fragment key={index}>
            {PromoType === "" && (
              <NormalItems
                child={child}
                key={index}
                translate={translate}
                onShow={onAddItem}
              />
            )}
            {PromoType === PromoType_B[0] && (
              <BuyXGetYItems
                child={child}
                key={index}
                translate={translate}
                onShow={() => onSelectItem(child.promotions)}
                isOAAsossiate={isOAAsossiate}
                isExpired={isExpired}
                onRemoveItem={() => onRemoveItem(child, products)}
              />
            )}
            {PromoType === PromoType_B[1] && (
              <BuyABGetCDItems
                child={child}
                key={index}
                translate={translate}
                onShow={() => onSelectItem(child.promotions)}
                isOAAsossiate={isOAAsossiate}
                isExpired={isExpired}
                onRemoveItem={() => onRemoveItem(child, products)}
              />
            )}

            {PromoType === PromoType_B[2] && (
              <DiscountItems
                child={child}
                key={index}
                translate={translate}
                onShow={() => onSelectItem(child.promotions)}
                isExpired={isExpired}
                onRemoveItem={() => onRemoveItem(child, products)}
              />
            )}
            {PromoType === PromoType_B[3] && (
              <SpecialPriceItems
                child={child}
                key={index}
                translate={translate}
                onShow={() => onSelectItem(child.promotions)}
                isExpired={isExpired}
                onRemoveItem={() => onRemoveItem(child, products)}
              />
            )}
          </Fragment>
        );
      })}
      <TotalQuantity products={products} />
      {props.typePostUrl === "CREATE" && (
        <CreateTotalTable
          {...props}
          record={formData}
          translate={translate}
          isEdit={true}
        />
      )}
      {props.typePostUrl === "UPDATE" && (
        <EditTotalTable
          {...props}
          record={formData}
          translate={translate}
          isEdit={true}
        />
      )}
    </Box>
  );
};

export default ListOrders;
