import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    borderRadius: 0,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  header: {
    backgroundColor: theme.palette.primary[300],
    padding: 16,
  },
  content: {
    backgroundColor: theme.palette.primary[50],
  },
  content1: {
    backgroundColor: grey[200],
    width: "100%",
  },
  product: {
    width: 500,
  },
  product1: {
    width: "100%",
  },
}));

export const useStylesTheme = makeStyles((theme) => ({
  thead: {
    backgroundColor: theme.palette.primary[300],
    padding: 16,
  },
  tbody: {
    backgroundColor: theme.palette.primary[50],
  },
}));
