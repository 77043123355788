import { ROLE_USER } from "./";
export const isShowing = (permissions, basepath, type) => {
  let showing = false;
  if (permissions === ROLE_USER.country_manager) {
    if (basepath === "orders" && (type === "list" || type === "show")) {
      return true;
    }
    return false;
  } else if (permissions === ROLE_USER.admin) {
    if (basepath === "codes") {
      return true;
    } else if (basepath === "settings") {
      return true;
    } else if (basepath === "users") {
      return true;
    } else if (basepath === "products") {
      return true;
    } else if (basepath === "priceBooks") {
      return true;
    } else if (basepath === "promotions") {
      return true;
    } else if (basepath === "orders") {
      return true;
    } else if (basepath === "inventories") {
      return true;
    } else if (basepath === "dealersTarget") {
      return true;
    } else if (basepath === "invoices") {
      return true;
    }

    return false;
  } else if (permissions === ROLE_USER.gc_sales_manager) {
    if (basepath === "products") {
      return true;
    } else if (basepath === "priceBooks") {
      return true;
    } else if (basepath === "promotions") {
      return true;
    } else if (basepath === "orders") {
      return true;
    } else if (basepath === "inventories") {
      return true;
    } else if (basepath === "dealersTarget") {
      return true;
    } else if (basepath === "invoices") {
      return true;
    } else if (basepath === "settings") {
      return true;
    } else if (basepath === "codes") {
      return true;
    }

    return false;
  } else if (permissions === ROLE_USER.gc_sales_associate) {
    if (basepath === "products") {
      return true;
    } else if (basepath === "priceBooks") {
      return true;
    } else if (basepath === "promotions") {
      return true;
    } else if (basepath === "orders") {
      return true;
    } else if (basepath === "inventories") {
      return true;
    } else if (basepath === "dealersTarget") {
      return true;
    } else if (basepath === "invoices") {
      return true;
    } else if (basepath === "settings") {
      return true;
    } else if (basepath === "codes") {
      return true;
    }
    return false;
  } else if (permissions === ROLE_USER.gc_oa_associate) {
    if (basepath === "products") {
      return true;
    } else if (basepath === "priceBooks") {
      switch (type) {
        case "list": {
          return true;
        }
        case "create": {
          return false;
        }
        case "edit": {
          return false;
        }
        case "show": {
          return true;
        }
        default:
          return false;
      }
    } else if (basepath === "promotions") {
      return true;
    } else if (basepath === "orders") {
      return true;
    } else if (basepath === "inventories") {
      return true;
    } else if (basepath === "dealersTarget") {
      return true;
    } else if (basepath === "invoices") {
      return true;
    } else if (basepath === "settings") {
      return true;
    } else if (basepath === "codes") {
      return true;
    }
    return false;
  } else if (permissions === ROLE_USER.dealer) {
    switch (basepath) {
      case "orders": {
        return false;
        // if ( type === "create") {
        //   return true;
        // } else {
        //   return false;
        // }
      }
      case "products": {
        if (type === "show" || type === "list") {
          return true;
        } else {
          return false;
        }
      }
      case "invoices": {
        if (type === "list") {
          return true;
        } else {
          return false;
        }
      }

      case "sellOuts": {
        if (type === "list" || type === "create" || type === "edit") {
          return true;
        } else {
          return false;
        }
      }

      case "inventories":
      case "promotions":
        return true;
      case "purchaseOrder":
        return true;
      default:
        return false;
    }
  } else if (permissions === ROLE_USER.customer) {
    return false;
    // switch (basepath) {
    //   case "promotions": {
    //     if (
    //       type === "show" ||
    //       type === "list" ||
    //       type === "edit" ||
    //       type === "create"
    //     ) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   }
    //   case "inventories": {
    //     if (type === "show" || type === "list") {
    //       return false;
    //     } else {
    //       return false;
    //     }
    //   }
    //   case "purchaseOrder": {
    //     if (type === "list" || type === "create" || type === "show") {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   }
    //   case "products": {
    //     if (type === "show" || type === "list") {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   }
    //   default:
    //     return false;
    // }
  }

  return showing;
};
