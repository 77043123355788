import { inputStyles } from "../../../themes"
export const styles = {
  listItem: {
    paddingTop: 10,
    paddingBottom: 10,
    color: "rgba(0, 0, 0, 0.54)",
    "&:hover": {
      backgroundColor: "rgba(0, 156, 125, 0.3)",
    },
  },
  active: {
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 12,
    fontWeight: "bold",
    alignItems: "center",
    backgroundColor: "rgba(0, 156, 125, 0.4)",
    color: "#FFFFFF",
    "&:hover": {
      fontWeight: "bold",
      backgroundColor: "rgba(0, 156, 125, 0.4)",
      color: inputStyles.colorLabel,
    },
  },
  listItemText: {
    textAlign: "left",
    paddingLeft: 0,
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: 12,
    letterSpacing: 3,
  },
  listItemTextLink: {
    textAlign: "left",
    paddingLeft: 0,
    color: "inherit",
    fontSize: 12,
    letterSpacing: 3,
  },
  listItemIcon: {
    padding: 0,
    minWidth: 24,
    color: "rgba(0, 0, 0, 0.54)",
    paddingRight: 16,
  },
  listItemIconLink: {
    padding: 0,
    minWidth: 24,
    color: "inherit",
    paddingRight: 16,
  },
  text: { color: "rgba(0, 0, 0, 0.54)" },
  textlink: { color: "inherit" },
  sidebarIsOpen: {
    paddingLeft: 24,
    transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  },
  sidebarIsClosed: {
    paddingLeft: 0,
    transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  },
}
//    // color: "#21c0fc",
