import React, { useState } from "react";
import { Flex, Box, Text } from "rebass";
// import { get } from "lodash";
import Paper from "@material-ui/core/Paper";
import {
  SelectInput,
  ImageField,
  AutocompleteArrayInput,
  ReferenceArrayInput,
} from "react-admin";
import {
  RaTextInput,
  RaBooleanInput,
  RaTextArea,
  ImageOlaInputNotDelete,
} from "../";
import { get } from "lodash";
// import { dataType } from "./utils"
import {
  dataPromotionSetting,
  dataMOQBuy,
  dataMOQDiscount,
  dataMOQPrice,
} from "./utils";
// import { extendMoment } from "moment-range";
import { DatePicker } from "antd";
import moment from "moment";
import momentTimeZone from "moment-timezone";
const tzNameSg = "Asia/Singapore";
const { RangePicker } = DatePicker;
const PromotionFields = (props) => {
  const { translate, updateField, record, ...rest } = props;
  const [state, setState] = useState({
    start: moment(get(rest, "formData.start_date", "")),
    end: moment(get(rest, "formData.end_date", "")),
  });
  // const country = get(formData, "country", []);

  const handleChange = (e) => {
    const promotype = e.target.value;
    if (promotype === "Buy X Get Y") {
      updateField("criteria.buy_x_get_y.moqs", dataMOQBuy);
    } else if (promotype === "Buy AB Get CD") {
      updateField("criteria.buy_ab_get_cd.moqs", dataMOQBuy);
    } else if (promotype === "Discount") {
      updateField("criteria.discount.moqs", dataMOQDiscount);
    } else if (promotype === "Special Price") {
      updateField("criteria.price.moqs", dataMOQPrice);
    }
  };
  const onChangeDate = (v) => {
    let myRangeDate = {
      start: get(rest.formData, "start_date", null),
      end: get(rest.formData, "end_date", null),
    };
    if (v && v !== null) {
      if (v[0] !== null) {
        myRangeDate.start = v[0];
      }
      if (v[1] !== null) {
        myRangeDate.end = v[1];
      }

      if (
        myRangeDate.start &&
        myRangeDate.end &&
        myRangeDate.start !== null &&
        myRangeDate.end !== null
      ) {
        const myStart = myRangeDate.start;
        const yearStart = myStart.format("YYYY");
        const monthStart = myStart.format("MM");
        const dayStart = myStart.format("DD");
        const start = momentTimeZone.tz(
          `${yearStart}-${monthStart}-${dayStart} 00:00:00`,
          tzNameSg
        );

        const myEnd = myRangeDate.end;
        const yearEnd = myEnd.format("YYYY");
        const monthEnd = myEnd.format("MM");
        const dayEnd = myEnd.format("DD");
        const end = momentTimeZone.tz(
          `${yearEnd}-${monthEnd}-${dayEnd} 23:59:59`,
          tzNameSg
        );

        // console.log(start.utc().format());
        // console.log(end.utc().format());
        setState({
          start: myRangeDate.start,
          end: myRangeDate.end,
        });
        updateField("start_date", start.utc().format());
        updateField("end_date", end.utc().format());
      }
    }
  };
  return (
    <Paper
      elevation={3}
      style={{ minHeight: 250, padding: 16, marginBottom: 16 }}
    >
      {/* <SelectInput
        source="type"
        choices={dataType}
        label={"resources.promotions.fields.type"}
        style={{ width: 180, marginBottom: 8 }}
      /> */}
      <RaBooleanInput
        source={"active"}
        label={"resources.promotions.fields.active"}
      />
      <RaTextInput
        source={"name"}
        label={"resources.promotions.fields.name"}
        style={{ width: "100%" }}
      />
      <Box width={[1]}>
        <ImageOlaInputNotDelete
          source='photos'
          toValue='photos'
          labelImage={translate("resources.promotions.fields.photo")}
          accept='image/*'
          key='photos'
          record={props.formData ? props.formData : record}
          updateField={updateField}
          {...rest}
        >
          <ImageField source='src' title='title' />
        </ImageOlaInputNotDelete>
      </Box>

      {/* <ReferenceArrayInput
        source='country'
        reference='countries'
        resource='countries'
        label={"resources.promotions.fields.country"}
      >
        <AutocompleteArrayInput optionText='name' />
      </ReferenceArrayInput> */}
      <ReferenceArrayInput
        source='dealer'
        reference='dealers'
        resource='dealers'
        label={"resources.promotions.fields.dealers"}
        fullWidth={true}
        filterToQuery={(searchText) => ({ search: searchText })}
        sort={{ field: "name", order: "ASC" }}
      >
        <AutocompleteArrayInput optionText='name' />
      </ReferenceArrayInput>
      {/* <Flex width={[1]} flexDirection={"row"} justifyContent={"flex-start"}>
        <DateInput
          source='start_date'
          label={"resources.promotions.fields.start_date"}
          parse={formatDate}
          style={{ width: 300, marginRight: 20 }}
        />
        <DateInput
          source='end_date'
          label={"resources.promotions.fields.end_date"}
          parse={formatDate}
          style={{ width: 300 }}
        />
      </Flex> */}
      <Flex width={[1]} flexDirection={"column"}>
        <Box pb={[2]}>
          <Flex flexDirection={"row"}>
            <Text
              pl={[2]}
              fontSize={[0]}
              fontWeight={"bold"}
              width={["138px"]}
              color={"#616161"}
            >
              {translate("resources.promotions.fields.start_date")}
            </Text>
            <Text fontSize={[0]} fontWeight={"bold"} color={"#616161"}>
              {translate("resources.promotions.fields.end_date")}
            </Text>
          </Flex>
          <RangePicker
            format={"DD-MMM-YYYY"}
            onChange={onChangeDate}
            value={[state.start, state.end]}
          />
        </Box>
      </Flex>
      <SelectInput
        source='promotion_type'
        label={"resources.promotions.fields.promotion_type"}
        choices={dataPromotionSetting}
        onChange={(e) => handleChange(e)}
        style={{ width: 256, marginRight: 24 }}
      />
      {/* <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        pt={[3]}
      >
        <RaBooleanInput
          source={"apply_other_promotions"}
          label={"resources.promotions.fields.apply_other_promotions"}
        />
      </Flex> */}
      {/* <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        pt={[3]}
      >
        <RaBooleanInput
          source={"need_coupon"}
          label={"resources.promotions.fields.need_coupon"}
        />
      </Flex> */}
      <Box width={[1]} py={[1]}>
        <RaTextArea
          source='remarks'
          label={"resources.promotions.fields.remarks"}
          // rows="5"

          fullWidth
          multiline
          InputLabelProps={{
            shrink: true,
            disableAnimation: true,
          }}
          style={{ transform: "none" }}
        />
      </Box>
    </Paper>
  );
};

export default PromotionFields;
