import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import shouldUpdate from "recompose/shouldUpdate";
import { makeStyles } from "@material-ui/core";
import ImageEye from "@material-ui/icons/RemoveRedEye";
import { Link } from "react-router-dom";
import { linkToRecord } from "react-admin";
import { useHistory } from "react-router-dom";
import ButtonIconOnly from "./ButtonIconOnly";
import { useStoreUrl } from "./utils";
// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e, setUrl, basePath) => {
  setUrl(basePath);
  e.stopPropagation();
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: 14,
    height: 14,
  },
  smallIcon: {
    fontSize: 14,
  },
}));

const ShowUrlButton = ({
  basePath = "",
  label = "ra.action.show",
  classes: classesOverride,
  record = {},
  icon = <ImageEye />,
  ...rest
}) => {
  const history = useHistory();
  const { setUrl } = useStoreUrl();
  const classes = useStyles({ classes: classesOverride });
  const urlPath =
    get(history, "location.pathname", basePath) +
    get(history, "location.search", "");
  return (
    <ButtonIconOnly
      classes={classes}
      component={Link}
      to={`${linkToRecord(basePath, record.id)}/show${get(
        history,
        "location.search",
        ""
      )}`}
      label={label}
      onClick={(e) => stopPropagation(e, setUrl, urlPath)}
      {...rest}
    >
      {React.cloneElement(icon, {
        className: classes[`smallIcon`],
      })}
    </ButtonIconOnly>
  );
};

ShowUrlButton.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  label: PropTypes.string,
  record: PropTypes.object,
  icon: PropTypes.element,
};

const enhance = shouldUpdate(
  (props, nextProps) =>
    props.translate !== nextProps.translate ||
    (props.record &&
      nextProps.record &&
      props.record.id !== nextProps.record.id) ||
    props.basePath !== nextProps.basePath ||
    (props.record == null && nextProps.record != null)
);

export default enhance(ShowUrlButton);
