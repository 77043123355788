import React from "react"
import { Flex } from "rebass"
import { useTranslate } from "react-admin"
import { TabsDetail } from "./components"
import { BetterShow, ListButton, Title } from "../components"

const ProductShowActions = ({ basePath, data, resource }) => {
  const translate = useTranslate()
  return (
    <Flex
      width={[1]}
      pt={[2]}
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Title fontWeight={"bold"} fontSize={[3]}>
        {translate(`resources.products.show_product`)}
      </Title>
      <ListButton basepath={basePath} />
    </Flex>
  )
}

const ProductShow = (props) => {
  const { hasList, hasEdit, hasCreate, hasShow, ...rest } = props
  return (
    <BetterShow {...rest} actions={<ProductShowActions />}>
      <TabsDetail {...props} />
    </BetterShow>
  )
}
export default ProductShow
