import React from "react";
import { Text } from "rebass";
import { get, orderBy } from "lodash";
import {
  StyledTableAction,
  StyledTableCell,
  StyledTableRow,
  StyledTableSmall,
} from "./styles";
import { LabelField } from "./items";

export const FreeField = (props) => {
  const { item, tPromoOrderQty, freeProduct } = props;
  if (tPromoOrderQty >= item.MOQ3) {
    const myTotal = tPromoOrderQty / item.Base3;

    const total = Math.floor(myTotal);
    const myAQTY =
      item.AQty3 > 0 ? (
        <StyledTableRow>
          <StyledTableCell>
            <Text pl={["20px"]} style={{ color: "#009c7d" }}>
              {get(freeProduct, "Packaging", "")}
            </Text>
          </StyledTableCell>
          <StyledTableSmall>
            <Text style={{ color: "#009c7d" }}>
              {get(freeProduct, "ProductCode", "")}
            </Text>
          </StyledTableSmall>
          <StyledTableAction>
            <Text
              style={{ color: "#009c7d", textAlign: "right", paddingRight: 16 }}
            >
              0
            </Text>
          </StyledTableAction>
          <StyledTableAction>
            <Text
              style={{ color: "#009c7d", textAlign: "right", paddingRight: 16 }}
            >
              {total + item.AQty3}
            </Text>
          </StyledTableAction>
          <StyledTableAction style={{ textAlign: "right", paddingRight: 16 }}>
            <Text style={{ color: "#009c7d" }}>0</Text>
          </StyledTableAction>
        </StyledTableRow>
      ) : (
        <StyledTableRow>
          <StyledTableCell>
            <Text pl={["20px"]} style={{ color: "#009c7d" }}>
              {get(freeProduct, "Packaging", "")}
            </Text>
          </StyledTableCell>
          <StyledTableSmall>
            <Text style={{ color: "#009c7d" }}>
              {get(freeProduct, "ProductCode", "")}
            </Text>
          </StyledTableSmall>
          <StyledTableAction>
            <Text
              style={{ color: "#009c7d", textAlign: "right", paddingRight: 16 }}
            >
              0
            </Text>
          </StyledTableAction>
          <StyledTableAction>
            <Text
              style={{ color: "#009c7d", textAlign: "right", paddingRight: 16 }}
            >
              {total}
            </Text>
          </StyledTableAction>
          <StyledTableAction style={{ textAlign: "right", paddingRight: 16 }}>
            <Text style={{ color: "#009c7d" }}>0</Text>
          </StyledTableAction>
        </StyledTableRow>
      );
    return myAQTY;
  } else if (tPromoOrderQty >= item.MOQ2) {
    const myTotal = tPromoOrderQty / item.Base2;
    const total = Math.floor(myTotal);
    const myAQTY =
      item.AQty2 > 0 ? (
        <StyledTableRow>
          <StyledTableCell>
            <Text pl={["20px"]} style={{ color: "#009c7d" }}>
              {get(freeProduct, "Packaging", "")}
            </Text>
          </StyledTableCell>
          <StyledTableSmall>
            <Text style={{ color: "#009c7d" }}>
              {get(freeProduct, "ProductCode", "")}
            </Text>
          </StyledTableSmall>
          <StyledTableAction>
            <Text
              style={{ color: "#009c7d", textAlign: "right", paddingRight: 16 }}
            >
              0
            </Text>
          </StyledTableAction>
          <StyledTableAction>
            <Text
              style={{ color: "#009c7d", textAlign: "right", paddingRight: 16 }}
            >
              {total + item.AQty2}
            </Text>
          </StyledTableAction>
          <StyledTableAction style={{ textAlign: "right", paddingRight: 16 }}>
            <Text style={{ color: "#009c7d" }}>0</Text>
          </StyledTableAction>
        </StyledTableRow>
      ) : (
        <StyledTableRow>
          <StyledTableCell>
            <Text pl={["20px"]} style={{ color: "#009c7d" }}>
              {get(freeProduct, "Packaging", "")}
            </Text>
          </StyledTableCell>
          <StyledTableSmall>
            <Text style={{ color: "#009c7d" }}>
              {get(freeProduct, "ProductCode", "")}
            </Text>
          </StyledTableSmall>
          <StyledTableAction>
            <Text
              style={{ color: "#009c7d", textAlign: "right", paddingRight: 16 }}
            >
              0
            </Text>
          </StyledTableAction>
          <StyledTableAction>
            <Text
              style={{ color: "#009c7d", textAlign: "right", paddingRight: 16 }}
            >
              {total}
            </Text>
          </StyledTableAction>
          <StyledTableAction style={{ textAlign: "right", paddingRight: 16 }}>
            <Text style={{ color: "#009c7d" }}>0</Text>
          </StyledTableAction>
        </StyledTableRow>
      );
    return myAQTY;
  } else if (tPromoOrderQty >= item.MOQ1) {
    const myTotal = tPromoOrderQty / item.Base1;
    const total = Math.floor(myTotal);
    const myAQTY =
      item.AQty1 > 0 ? (
        <StyledTableRow>
          <StyledTableCell>
            <Text pl={["20px"]} style={{ color: "#009c7d" }}>
              {get(freeProduct, "Packaging", "")}
            </Text>
          </StyledTableCell>
          <StyledTableSmall>
            <Text style={{ color: "#009c7d" }}>
              {get(freeProduct, "ProductCode", "")}
            </Text>
          </StyledTableSmall>
          <StyledTableAction>
            <Text
              style={{ color: "#009c7d", textAlign: "right", paddingRight: 16 }}
            >
              0
            </Text>
          </StyledTableAction>
          <StyledTableAction>
            <Text
              style={{ color: "#009c7d", textAlign: "right", paddingRight: 16 }}
            >
              {total + item.AQty1}
            </Text>
          </StyledTableAction>
          <StyledTableAction style={{ textAlign: "right", paddingRight: 16 }}>
            <Text style={{ color: "#009c7d" }}>0</Text>
          </StyledTableAction>
        </StyledTableRow>
      ) : (
        <StyledTableRow>
          <StyledTableCell>
            <Text pl={["20px"]} style={{ color: "#009c7d" }}>
              {get(freeProduct, "Packaging", "")}
            </Text>
          </StyledTableCell>
          <StyledTableSmall>
            <Text style={{ color: "#009c7d" }}>
              {get(freeProduct, "ProductCode", "")}
            </Text>
          </StyledTableSmall>
          <StyledTableAction>
            <Text
              style={{ color: "#009c7d", textAlign: "right", paddingRight: 16 }}
            >
              0
            </Text>
          </StyledTableAction>
          <StyledTableAction>
            <Text
              style={{ color: "#009c7d", textAlign: "right", paddingRight: 16 }}
            >
              {total}
            </Text>
          </StyledTableAction>
          <StyledTableAction style={{ textAlign: "right", paddingRight: 16 }}>
            <Text style={{ color: "#009c7d" }}>0</Text>
          </StyledTableAction>
        </StyledTableRow>
      );
    return myAQTY;
  }

  return <div />;
};

export const FreeFieldProduct = (props) => {
  const { item, tPromoOrderQty } = props;
  let freeTotal = 0;
  if (tPromoOrderQty >= item.MOQ3) {
    const myTotal = tPromoOrderQty / item.Base3;
    const total = Math.floor(myTotal);
    const myTotalResult = item.AQty3 > 0 ? item.AQty1 + total : total;
    return <LabelField color={"red"}>{myTotalResult}</LabelField>;
  } else if (tPromoOrderQty >= item.MOQ2) {
    const myTotal = tPromoOrderQty / item.Base2;
    const total = Math.floor(myTotal);
    const myTotalResult = item.AQty2 > 0 ? item.AQty1 + total : total;
    return <LabelField color={"red"}>{myTotalResult}</LabelField>;
  } else if (tPromoOrderQty >= item.MOQ1) {
    const myTotal = tPromoOrderQty / item.Base1;
    const total = Math.floor(myTotal);
    const myTotalResult = item.AQty1 > 0 ? item.AQty1 + total : total;
    return <LabelField color={"red"}>{myTotalResult}</LabelField>;
  }

  return <LabelField color={"red"}>{freeTotal}</LabelField>;
};

export const getTotalQtyFreeProduct = (item, tPromoOrderQty) => {
  let freeTotal = 0;
  const moqs = orderBy(
    get(item, "criteria.buy_x_get_y.moqs", []),
    ["moq"],
    ["desc"]
  );
  if (moqs.length > 0) {
    if (tPromoOrderQty >= moqs[0].moq) {
      const myTotal = tPromoOrderQty / moqs[0].buy;
      const total = Math.floor(myTotal);
      const myTotalResult =
        moqs[0].additional > 0 ? moqs[0].additional + total : total;
      return myTotalResult;
    } else if (tPromoOrderQty >= moqs[1].moq) {
      const myTotal = tPromoOrderQty / moqs[1].buy;
      const total = Math.floor(myTotal);
      const myTotalResult =
        moqs[1].additional > 0 ? moqs[1].additional + total : total;

      return myTotalResult;
    } else if (tPromoOrderQty >= moqs[2].moq) {
      const myTotal = tPromoOrderQty / moqs[2].buy;
      const total = Math.floor(myTotal);
      const myTotalResult =
        moqs[2].additional > 0 ? moqs[2].additional + total : total;
      return myTotalResult;
    }
  }

  return freeTotal;
};
export const getTotalQtyFreeProductABCD = (item, tPromoOrderQty) => {
  let freeTotal = 0;
  const moqs = orderBy(
    get(item, "criteria.buy_ab_get_cd.moqs", []),
    ["moq"],
    ["desc"]
  );
  if (moqs.length > 0) {
    if (tPromoOrderQty >= moqs[0].moq) {
      const myTotal = tPromoOrderQty / moqs[0].buy;
      const total = Math.floor(myTotal);
      const myTotalResult =
        moqs[0].additional > 0 ? moqs[0].additional + total : total;
      return myTotalResult;
    } else if (tPromoOrderQty >= moqs[1].moq) {
      const myTotal = tPromoOrderQty / moqs[1].buy;
      const total = Math.floor(myTotal);
      const myTotalResult =
        moqs[1].additional > 0 ? moqs[1].additional + total : total;

      return myTotalResult;
    } else if (tPromoOrderQty >= moqs[2].moq) {
      const myTotal = tPromoOrderQty / moqs[2].buy;
      const total = Math.floor(myTotal);
      const myTotalResult =
        moqs[2].additional > 0 ? moqs[2].additional + total : total;
      return myTotalResult;
    }
  }

  return freeTotal;
};

export const getTotalSelectedQtyFreeProduct = (list) => {
  let freeTotal = 0;
  list.map((product) => {
    if (product.Order) {
      freeTotal = freeTotal + product.Order;
    }
    return "";
  });
  return freeTotal;
};
