import React from "react"
import { Text } from "rebass"
import { get } from "lodash"
import { makeStyles } from "@material-ui/core/styles"
import LinearProgress from "@material-ui/core/LinearProgress"
import { useQueryWithStore } from "react-admin"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}))
const UserField = (props) => {
  const classes = useStyles()

  const { record, source, optionText, optionText1, color } = props
  const subColor = color ? { color: color } : {}
  const { loaded, data } = useQueryWithStore({
    type: "getOne",
    resource: "users",
    payload: { id: record[source] },
  })
  if (!loaded) {
    return (
      <div className={classes.root}>
        <LinearProgress variant="query" />
      </div>
    )
  }
  return (
    <Text style={{ ...subColor }}>
      {get(data, optionText, "")} {get(data, optionText1, "")}
    </Text>
  )
}

export default UserField
