import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// import LinearProgress from "@material-ui/core/LinearProgress";
// import { Box } from "rebass";
// import { useQueryWithStore } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 0,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: 0,
    minWidth: 200,
  },
}));

const CountriesFields = (props) => {
  const { handleChange, type, countries } = props;
  const classes = useStyles();
  // const payloadDefault = {
  //   pagination: { page: 1, perPage: 1000 },
  //   sort: { field: "name", order: "ASC" },
  // };
  // const { data, loading } = useQueryWithStore({
  //   type: "getList",
  //   resource: "countries",
  //   payload: payloadDefault,
  // });
  // if (loading) {
  //   return (
  //     <Box>
  //       <LinearProgress />
  //     </Box>
  //   );
  // }

  return (
    <div>
      <Select
        name={"country"}
        value={type}
        onChange={handleChange("country")}
        className={classes.selectEmpty}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {countries &&
          countries.length > 0 &&
          countries.map((i) => (
            <MenuItem key={i.id} value={i.id}>
              {i.name}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
};

export default CountriesFields;
