import React, { Fragment } from "react";
import { Flex, Box, Text } from "rebass";
import { ListButton } from "../buttons";

export const HeaderCustomForm = ({
  translate,
  paramsUrl,
  title,
  finalInitialValues,
  isHideTitle,
}) => {
  if (isHideTitle) {
    return <Fragment />;
  }
  return (
    <Box width={[1]} pt={[2]} pl={[3]} pb={[2]}>
      <Flex width={[1]} flexDirection={"row"} justifyContent={"space-between"}>
        <Box width={[1]}>
          <Text fontWeight={"bold"} fontSize={[3]}>
            {title ? translate(title) : ""}
          </Text>
        </Box>
        <ListButton basepath={paramsUrl} record={finalInitialValues} />
      </Flex>
    </Box>
  );
};

export const sanitizeRestProps = ({
  anyTouched,
  array,
  asyncBlurFields,
  asyncValidate,
  asyncValidating,
  autofill,
  blur,
  change,
  clearAsyncError,
  clearFields,
  clearSubmit,
  clearSubmitErrors,
  destroy,
  dirty,
  dirtyFields,
  dirtySinceLastSubmit,
  dispatch,
  form,
  handleSubmit,
  hasSubmitErrors,
  hasValidationErrors,
  initialize,
  initialized,
  initialValues,
  pristine,
  pure,
  redirect,
  reset,
  resetSection,
  save,
  setRedirect,
  submit,
  submitError,
  submitErrors,
  submitAsSideEffect,
  submitFailed,
  submitSucceeded,
  submitting,
  touch,
  translate,
  triggerSubmit,
  undoable,
  untouch,
  valid,
  validate,
  validating,
  _reduxForm,
  updateField,
  ...props
}) => props;
