import React, { useState } from "react";
import { Box, Flex } from "rebass";
import { downloadCSV } from "react-admin";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { useQuery } from "../../../components";
import { Loading } from "react-admin";
import { getListGL } from "./GrowthLossItem";
import {
  ListGrowthLoss,
  listColumnItemDealer,
  HeaderGrowthLoss,
  listColumnItem,
} from "./growth_loss";
import { getMonthList } from "./utils";
import { get, map, filter, find } from "lodash";
import jsonExport from "jsonexport/dist";
import { ExportCSVFile, NumberParser2Decimals } from "./utils";
import momentTimeZone from "moment-timezone";

const moment = extendMoment(Moment);
const endDateDefault = moment();
const tzNameSg = "Asia/Singapore";
const myFormatDefault = `ddd MMM DD YYYY 23:59:59`;
const myFormatStartDate = `ddd MMM 1 YYYY 00:00:00`;

const MainFields = (props) => {
  const { dataList, translate, dealerId } = props;
  const myListDefault = getMonthList({
    start: new Date(props.startMonth),
    end: new Date(props.endMonth),
  });
  let newColumnList = [];
  myListDefault.map((itemMonth) => {
    newColumnList.push({
      field: `amount-${itemMonth}`,
      headerName: `Amount ${itemMonth}`,
      minWidth: 140,
      cellStyle: { "padding-top": "7px", "text-align": "right" },
      editable: false,
      resizable: true,
      valueFormatter: NumberParser2Decimals,
    });
    newColumnList.push({
      field: `amount-qty-${itemMonth}`,
      headerName: `Qty ${itemMonth}`,
      minWidth: 120,
      cellStyle: { "padding-top": "7px", "text-align": "right" },
      editable: false,
      resizable: true,
      valueFormatter: NumberParser2Decimals,
    });
    newColumnList.push({
      field: `amount_gap-${itemMonth}`,
      headerName: `G/L ${itemMonth}`,
      minWidth: 120,
      cellStyle: { "padding-top": "7px", "text-align": "right" },
      editable: false,
      resizable: true,
      valueFormatter: NumberParser2Decimals,
    });
    newColumnList.push({
      field: `amount_gap-qty-${itemMonth}`,
      headerName: `G/L Qty ${itemMonth}`,
      minWidth: 140,
      cellStyle: { "padding-top": "7px", "text-align": "right" },
      editable: false,
      resizable: true,
      valueFormatter: NumberParser2Decimals,
    });
    return "";
  });
  const [list, setList] = useState(dataList);
  const [valueCountry, setValueCountry] = useState([]);
  const [valueDealer, setValueDealer] = useState([]);
  const [loading, setLoading] = useState(false);
  const myLIst =
    dealerId && dealerId !== null ? listColumnItemDealer : listColumnItem;
  const [myData, setMyData] = useState({
    rangeDate: {
      start: moment(props.startMonth),
      end: moment(props.endMonth),
    },
    listColumn: [...myLIst, ...newColumnList],
    listTemporary: dataList,
  });
  const [myfilter, setMyFilter] = useState({
    country: [],
    dealer: [],
  });
  const onExportCsv = () => {
    if (list && list.length > 0) {
      let newList = [];
      map(list, function (o) {
        const myMonth = o.myMonth;
        map(myMonth, function (i) {
          newList.push({
            country: get(o, "country", ""),
            dealer: get(o, "dealer", ""),
            target_setting: get(o, "target_setting", ""),
            month:
              get(i, "month", "") !== ""
                ? moment(get(i, "month", "")).format("MMM-YY")
                : "",
            amount: get(i, "amount", 0),
            amount_gap: get(i, "amount_gap", 0),
            qty: get(i, "qty", 0),
            qty_gap: get(i, "qty_gap", 0),
          });
          return i;
        });
        /**
         *  amount: NumberAmountValue(get(i, "amount", 0)),
            amount_gap: NumberAmountValue(get(i, "amount_gap", 0)),
            qty: NumberAmountValue(get(i, "qty", 0)),
            qty_gap: NumberAmountValue(get(i, "qty_gap", 0)),
         */
        return {
          country: get(o, "country", ""),
          dealer: get(o, "dealer", ""),
          target_setting: get(o, "target_setting", ""),
          month:
            get(o, "month", "") !== ""
              ? moment(get(o, "month", "")).format("MMM-YY")
              : "",
          amount: get(o, "amount", 0),
          amount_gap: get(o, "amount_gap", 0),
          qty: get(o, "qty", 0),
          qty_gap: get(o, "qty_gap", 0),
        };
      });
      /*
      amount: NumberAmountValue(get(o, "amount", 0)),
          amount_gap: NumberAmountValue(get(o, "amount_gap", 0)),
          qty: NumberAmountValue(get(o, "qty", 0)),
          qty_gap: NumberAmountValue(get(o, "qty_gap", 0)),
      */
      jsonExport(
        newList,
        {
          headers: [
            "country",
            "dealer",
            "target_setting",
            "month",
            "amount",
            "amount_gap",
            "qty",
            "qty_gap",
          ],
        },
        (err, csv) => {
          if (err) return console.error(err);
          downloadCSV(
            csv,
            `growth-loss-${moment().format("DD-MMM-YYYY-HH:mm:ss")}`
          );
        }
      );
    }
  };
  return (
    <Box width={[1]} py={[2]}>
      <HeaderGrowthLoss
        {...props}
        setList={setList}
        valueCountry={valueCountry}
        valueDealer={valueDealer}
        myfilter={myfilter}
        setValueCountry={setValueCountry}
        setValueDealer={setValueDealer}
        setMyFilter={setMyFilter}
        list={list}
        myData={myData}
        setMyData={setMyData}
        setLoading={setLoading}
      />
      <ExportCSVFile
        translate={translate}
        list={list}
        onExportCsv={onExportCsv}
      />

      {loading ? (
        <Flex
          width={[1]}
          py={[3]}
          style={{ height: "20vh" }}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection='row'
        >
          <Loading />
        </Flex>
      ) : (
        <ListGrowthLoss {...props} list={list} myData={myData} />
      )}
    </Box>
  );
};

export default (props) => {
  const startDateDefault = moment(endDateDefault).add(-1, "month");

  const myTextEndDate = endDateDefault.format(myFormatDefault);
  const endOfMonthTest = moment(myTextEndDate)
    .endOf("month")
    .format(myFormatDefault);
  const startDateD = momentTimeZone
    .tz(startDateDefault, myFormatStartDate, `${tzNameSg}`)
    .utc()
    .format(myFormatStartDate);
  const endDateD = momentTimeZone
    .tz(endOfMonthTest, myFormatDefault, `${tzNameSg}`)
    .utc()
    .format(myFormatDefault);
  const { dealerId, country, dealers, countries } = props;
  const paramDealerId = dealerId !== null ? `&dealer_id=${dealerId}` : "";
  const { loaded, data } = useQuery(
    `/reports/growthLoss?start_date=${moment(
      startDateD
    ).toISOString()}&end_date=${moment(endDateD).toISOString()}${paramDealerId}`
  );
  if (!loaded) {
    return (
      <Flex width={[1]} flexDirection={"column"} alignItems={"center"}>
        <Loading />
      </Flex>
    );
  }
  const newlist = getListGL(data, dealerId);
  const dataList =
    newlist && newlist.length > 0
      ? Array.from(
          new Set(
            newlist.map((p, index) => {
              return { ...p, id: index };
            })
          )
        )
      : [];
  let myDataList = dataList;
  let countryNew = country;
  if (dealerId && dealerId !== null) {
    const dealercountryNew = find(dealers, function (dl) {
      return dl.id === dealerId;
    });
    // console.log(dealercountryNew);
    countryNew = find(countries, function (ct) {
      return ct.id === dealercountryNew.country_id;
    });
  }

  if (countryNew && countryNew.id) {
    myDataList = filter(dataList, function (o) {
      return o.country === countryNew.name;
    });
  }
  // console.log(myDataList);
  return (
    <Box width={[1]} py={[1]}>
      <MainFields
        {...props}
        dataList={myDataList}
        startMonth={moment(startDateD).toISOString()}
        endMonth={moment(endDateD).toISOString()}
        country={countryNew}
      />
    </Box>
  );
};
