import React from "react"
import { Text, Box } from "rebass"
import { get } from "lodash"
import { makeStyles } from "@material-ui/core/styles"
import LinearProgress from "@material-ui/core/LinearProgress"
import { useQueryWithStore } from "react-admin"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  progress: {
    width: 100,
  },
}))
const CustomField = (props) => {
  const classes = useStyles()

  const { record, source, label, resource, resource_id } = props
  const { loaded, data } = useQueryWithStore({
    type: "getOne",
    resource: resource,
    payload: { id: get(record, resource_id, "") },
  })
  if (!loaded) {
    return (
      <Box p={[2]}>
        <LinearProgress variant="query" className={classes.progress} />
      </Box>
    )
  }
  return (
    <Box p={[2]}>
      {label && label !== "" && (
        <Text fontSize={[0]} fontWeight={"bold"} pb={[1]}>
          {label}
        </Text>
      )}
      <Text fontSize={[0]}>{get(data, source, "")}</Text>
    </Box>
  )
}

export default CustomField
