import React from "react";
import { Flex, Box, Text } from "rebass";
import { InboxIcon } from "../../components/icon_svg";
// import DialogGoodsReceipts from "./DialogGoodsReceipts";

const EmptyGoodsReceipts = (props) => {
  const { translate } = props;
  // console.log(props)
  return (
    <Flex
      width={[1]}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      style={{ height: "40vh" }}
    >
      <InboxIcon fontSize='large' style={{ color: "#d1d1d1" }} />
      <Box width={[1]} py={[2]}>
        <Text textAlign={"center"} p={[2]}>
          {translate("resources.po.note_text.empty_good_receipts")}
        </Text>
      </Box>
      {/* <DialogGoodsReceipts {...props} /> */}
    </Flex>
  );
};

export default EmptyGoodsReceipts;
