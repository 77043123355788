export default {
  orders: {
    title: "Pesanan",
    new_order: "Pesanan Baru",
    edit_order: "Ubah Pesanan",
    show_order: "Pesanan",
    otd: "OTD",
    upload_otd: "Unggah Harga OTD",
    tabs: {
      order: "Pesanan",
      analytic: "Analisa",
      otd: "OTD",
    },
    fields: {
      name: "Nama",
      status: "Status",
      reference: "Referensi",
      dealer: "Dealer",
      country: "Negara",
      created_at: "Dibuat pada",
      order_reference: "referensi pesanan",
      order_date: "Tanggal Pesanan",
      delivery_date: "Tanggal pengiriman",
      order_items: "Barang Pesanan",
      address: "Alamat",
      tel: "Telepon",
      remarks: "Catatan",
      product: "Produk",
      code: "Kode",
      qty: "Jumlah",
      price: "Harga",
      subtotal: "Subtotal",
      total: "Total",
      promotion: "Promosi",
      rows: "Baris",
      period: "Periode",
      date: "Tanggal",
      items: "Items",
      customer_ref: "Customer Reference",
      discounted_price: "Discounted Price",
    },
    messages: {
      product_qty: "Produk atau Jumlah tidak valid",
      orderItemsEmpty: "Silakan tambahkan produk ke Item Pemesanan",
      create_order: "Buat Pesanan Tidak Sukses",
      create_order_items: "Buat Item Pesanan Tidak Berhasil",
      element_created: "Elemen dibuat",
      check_period: "Silahkan Pilih Periode dahulu sebelum unggah file",
      order_cannot_post: "Order id not detect",
    },
  },
};
