import React from "react";
import { Flex } from "rebass";
import { TextRecord } from "../../../components";
import DealerField from "./DealerField";
export default (props) => {
  const { record } = props;
  return (
    <Flex width={[1]} flexDirection={"column"} py={[3]}>
      <TextRecord
        record={record}
        source={"first_name"}
        label={"resources.users.fields.first_name"}
      />
      <TextRecord
        record={record}
        source={"last_name"}
        label={"resources.users.fields.last_name"}
      />
      <TextRecord
        record={record}
        source={"email"}
        label={"resources.users.fields.email"}
      />
      <TextRecord
        record={record}
        source={"role"}
        label={"resources.users.fields.role"}
      />
      {record && record.role === "Dealer" && (
        <DealerField record={record} source={"role"} />
      )}
    </Flex>
  );
};
