import React from "react";
import { Box, Flex } from "rebass";
//Text
import { get } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
// import { useDataProvider, useNotify } from "react-admin";
import { styles } from "./styles";
import { STATUSPO, ROLE_USER } from "../../utils";
import { ListOrdersView } from "./promotions";

const OrderItemsShow = (props) => {
  // const notify = useNotify();
  // const dataProvider = useDataProvider();
  const { record, translate, basePath, permissions } = props;
  //history,
  // const onSubmitPO = () => {
  //   if (record && record.id) {
  //     // console.log(props)
  //     const oldRecod = record;
  //     record["status"] = "Sent";
  //     dataProvider
  //       .update("purchaseOrder", {
  //         id: record.id,
  //         data: record,
  //         previousData: oldRecod,
  //       })
  //       .then((response) => {
  //         if (response && response.data) {
  //           notify("resources.po.messages.element_updated");
  //           history.push(basePath);
  //         } else {
  //           notify("resources.po.messages.create_order", "warning");
  //         }
  //       });
  //   }
  // };

  return (
    <Box width={[1]} p={[3]} pl={[1]} pr={[1]}>
      <ListOrdersView formData={record} {...props} />
      <Box width={[1]}>
        {get(record, "status", "") === STATUSPO.draft && (
          <Flex
            width={[1]}
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            {permissions !== ROLE_USER.customer && (
              <Button
                component={Link}
                variant='contained'
                color='primary'
                to={`${basePath}/${record.id}`}
                style={{
                  marginRight: 8,
                  borderRadius: 24,
                  width: 180,
                  textTransform: "capitalize",
                }}
              >
                {translate("resources.buttons.edit")}
              </Button>
            )}
            {/* <Button
              variant='contained'
              color='primary'
              onClick={onSubmitPO}
              style={{
                marginLeft: 8,
                borderRadius: 24,
                width: 180,
                textTransform: "capitalize",
              }}
            >
              {translate("resources.buttons.submit_po")}
            </Button> */}
          </Flex>
        )}
      </Box>
    </Box>
  );
};
export default withStyles(styles)(OrderItemsShow);
