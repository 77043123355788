import React from "react";
import { Flex, Box, Text } from "rebass";
import { get } from "lodash";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import { EditIcon } from "../../components/icon_svg";
import { TrashPOIcon } from "../../components/icon_svg";
import { withStyles } from "@material-ui/core/styles";

import {
  styles,
  StyledTableAction,
  StyledTableCell,
  StyledTableRow,
  StyledTableSmall,
  StyledTableRowLast,
} from "./styles";
import { PromotionField } from "../../po";

const OrderItems = (props) => {
  const { formData, translate, classes, onRemoveItem, onEditItem } = props;
  const items = get(formData, "orderItems", []);
  let total = 0;
  return (
    <Box width={[1]} p={[3]}>
      <Text color={"green"} pb={[3]}>
        {translate("resources.orders.fields.order_items")}
      </Text>
      {items.length > 0 && (
        <TableContainer component={"div"}>
          <Table className={classes.table} aria-label='customized table'>
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  {translate("resources.orders.fields.product")}
                </StyledTableCell>
                <StyledTableSmall>
                  {translate("resources.orders.fields.code")}
                </StyledTableSmall>
                <StyledTableSmall>
                  {translate("resources.orders.fields.qty")}
                </StyledTableSmall>
                <StyledTableSmall>
                  {translate("resources.orders.fields.price")}
                </StyledTableSmall>
                <StyledTableSmall>
                  {translate("resources.orders.fields.subtotal")}
                </StyledTableSmall>
                <StyledTableAction />
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => {
                total = total + item.result.subtotal;
                // console.log(item);
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell component='td' scope='row'>
                      <Box>
                        <Text pb={[1]}>{item.product.packaging}</Text>
                        <PromotionField
                          record={item}
                          source='name'
                          resource_id='product_id'
                        />
                        {/* <Text style={{ color: "#FF0000" }} pt={[3]}>
                          {get(
                            item,
                            "result.promotions[0].name",
                            "Buy 12 Get 1 Free"
                          )}
                        </Text> */}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell component='td' scope='row'>
                      <Text>{item.product.code}</Text>
                    </StyledTableCell>
                    <StyledTableCell component='td' scope='row'>
                      <Text>{item.result.qty}</Text>
                    </StyledTableCell>
                    <StyledTableCell component='td' scope='row'>
                      <Text>{item.result.price}</Text>
                    </StyledTableCell>
                    <StyledTableCell component='td' scope='row'>
                      <Text>{item.result.subtotal}</Text>
                    </StyledTableCell>
                    <StyledTableAction component='td' scope='row'>
                      <Flex flexDirection={"row"} alignItems={"center"}>
                        <IconButton
                          className={classes.leftIcon}
                          onClick={() => onRemoveItem(item, items)}
                        >
                          <TrashPOIcon fontSize='small' />
                        </IconButton>

                        <IconButton onClick={() => onEditItem(item)}>
                          <EditIcon fontSize='small' />
                        </IconButton>
                        <Box width={"10px"} />
                      </Flex>
                    </StyledTableAction>
                  </StyledTableRow>
                );
              })}
              <StyledTableRowLast>
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell>
                  <Text fontWeight={"bold"}>
                    {translate("resources.orders.fields.total")}
                  </Text>
                </StyledTableCell>
                <StyledTableCell>
                  <Text fontWeight={"bold"}>{total}</Text>
                </StyledTableCell>
                <StyledTableAction />
              </StyledTableRowLast>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default withStyles(styles)(OrderItems);
