import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  Toolbar,
  SaveButton,
  Show,
  TopToolbar,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import {
  SimpleOlehForm,
  TabbedOlehForm,
  CustomSimpleOlehForm,
  SimpleOrderForm,
  SimplePOForm,
  SimplePOInventoryForm,
  SimpleSelloutForm,
  CustomSimplePromotionForm,
  SimplePOOrderForm,
} from "./form";
import { useStyles, useStylesTable, stylesFormContainer } from "./utils";
import { ListButton } from "./buttons";

const betterRowStyle = (record, index) => ({
  borderBottomWidth: 0,
  borderWidth: 0,
  padding: 0,
});

export const BetterList = (props) => {
  const { classes: classesOverride, rowclick } = props;
  const classes = useStyles({ classes: classesOverride });
  const classesT = useStylesTable({ classes: classesOverride });
  return (
    <List bulkActionButtons={false} {...props} classes={classes}>
      <Datagrid
        rowStyle={betterRowStyle}
        classes={classesT}
        rowClick={rowclick}
      >
        {props.children}
      </Datagrid>
    </List>
  );
};

export const BetterSimpleForm = (props) => {
  const {
    staticContext,
    hasList,
    hasCreate,
    hasEdit,
    hasShow,
    ...checkProps
  } = props;
  return (
    <SimpleOlehForm {...checkProps} redirect='list' submitOnEnter={false} />
  );
};
export const BetterCustomSimpleForm = (props) => {
  const {
    staticContext,
    hasList,
    hasCreate,
    hasEdit,
    hasShow,
    ...checkProps
  } = props;
  return <CustomSimpleOlehForm {...checkProps} submitOnEnter={false} />;
};
export const BetterCustomPromotionForm = (props) => {
  const {
    staticContext,
    hasList,
    hasCreate,
    hasEdit,
    hasShow,
    ...checkProps
  } = props;
  return <CustomSimplePromotionForm {...checkProps} submitOnEnter={false} />;
};

export const BetterCustomForm = (props) => {
  const { hasList, hasCreate, hasEdit, hasShow, ...checkProps } = props;
  return (
    <SimpleOlehForm
      {...checkProps}
      submitOnEnter={false}
      isCustomRender={true}
    />
  );
};
export const BetterSellOutForm = (props) => {
  const { hasList, hasCreate, hasEdit, hasShow, ...checkProps } = props;
  return (
    <SimpleSelloutForm
      {...checkProps}
      submitOnEnter={false}
      isCustomRender={true}
    />
  );
};
export const BetterOrderForm = (props) => {
  const { hasList, hasCreate, hasEdit, hasShow, ...checkProps } = props;
  return (
    <SimpleOrderForm
      {...checkProps}
      submitOnEnter={false}
      isCustomRender={true}
    />
  );
};
export const BetterPoForm = (props) => {
  const { hasList, hasCreate, hasEdit, hasShow, ...checkProps } = props;
  return (
    <SimplePOForm {...checkProps} submitOnEnter={false} isCustomRender={true} />
  );
};

export const BetterOrderPOForm = (props) => {
  const { hasList, hasCreate, hasEdit, hasShow, ...checkProps } = props;
  return (
    <SimplePOOrderForm
      {...checkProps}
      submitOnEnter={false}
      isCustomRender={true}
    />
  );
};

export const BetterPoInventoryForm = (props) => {
  const { hasList, hasCreate, hasEdit, hasShow, ...checkProps } = props;
  return (
    <SimplePOInventoryForm
      {...checkProps}
      submitOnEnter={false}
      isCustomRender={true}
    />
  );
};

export const BetterTabbedForm = (props) => {
  const {
    hasList,
    hasCreate,
    hasEdit,
    hasShow,
    permissions,
    ...checkProps
  } = props;

  return (
    <TabbedOlehForm
      {...checkProps}
      redirect='list'
      submitOnEnter={false}
      permissions={permissions}
    />
  );
};
export const BetterCreate = withStyles(stylesFormContainer)((props) => {
  const { classes, permissions } = props;
  return <Create {...props} permissions={permissions} classes={classes} />;
});
export const BetterShow = withStyles(stylesFormContainer)((props) => {
  const { classes } = props;
  return <Show {...props} classes={classes} />;
});

export const BetterEdit = withStyles(stylesFormContainer)((props) => {
  // console.log(props)
  const {
    classes,
    hasList,
    hasCreate,
    hasEdit,
    hasShow,
    permissions,
    ...checkProps
  } = props;

  return (
    <Edit
      {...checkProps}
      undoable={false}
      classes={classes}
      permissions={permissions}
    />
  );
});
export const EditToolbar = (props) => {
  const { updateField, ...rest } = props;
  return (
    <Toolbar {...rest}>
      <SaveButton redirect={props.redirect} style={{ marginLeft: 16 }} />
    </Toolbar>
  );
};

export const FooterToolbar = (props) => {
  const { updateField, ...rest } = props;
  return (
    <Toolbar {...rest}>
      <SaveButton redirect={props.redirect} style={{ marginLeft: 16 }} />
    </Toolbar>
  );
};
export const FooterOrderCreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        label={"resources.buttons.submit_order"}
        redirect={props.redirect}
        style={{ marginLeft: 16 }}
      />
    </Toolbar>
  );
};
export const FooterPOCreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        label={"resources.buttons.save_as_draft"}
        redirect={props.redirect}
        style={{ marginLeft: 16, borderRadius: 24 }}
      />
      <SaveButton
        label={"resources.buttons.submit_order"}
        redirect={props.redirect}
        style={{ marginLeft: 16, borderRadius: 24 }}
      />
    </Toolbar>
  );
};

export const TopBarAction = ({ basePath }) => {
  return (
    <TopToolbar style={{ paddingTop: 16 }}>
      <ListButton basepath={basePath} />
    </TopToolbar>
  );
};

export const TopBarFixedAction = ({ basePath }) => {
  return (
    <TopToolbar
      style={{
        paddingTop: 16,
        position: "fixed",
        zIndex: 2001,
        width: "98%",
        paddingRight: 0,
        paddingBottom: 16,
      }}
    >
      <ListButton basepath={basePath} />
    </TopToolbar>
  );
};
