export default {
  targets: {
    name: "Targets",
    budget: "Budget",
    tabs: {
      country_phasing: "Country Phasing",
      dealer: "Dealer",
    },
    table: {
      line_number: "Line Number",
      categories: "GCIAG 9 Main Categories",
      target: "Target Setting Purpose",
      product_group: "Product Group",
      sub_product_group: "Sub Product Group",
    },
    fields: {
      fy: "FY",
      country: "Country",
      dealer: "Dealer",
      target: "Target",
      mc: "GCIAG 9 Main Categories",
      target_setting: "Target Setting",
      upload_on: "Uploaded On",
    },
    message: {
      empty_list_country_phasing: "No Country Phasing for FY ",
      empty_list_dealer_target: "No Dealer Target for FY ",
      empty_list1: "Please upload.",
      please_select_FY: "Please select FY first",
      max_size: "Please resize your file less then 1MB",
      message_failed: "Upload File failed",
      success: "Success, File Uploaded",
    },
  },
};
