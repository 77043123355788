import React, { useState, useEffect } from "react";
import { withDataProvider } from "react-admin";
import { Flex, Box } from "rebass";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import EmptyList from "./EmptyList";
import RowCountryPhasing from "./RowCountryPhasing";
import { getYearList, getFY } from "./utils";
import { LoadingRa } from "../../components";

const ListCountryPhasing = (props) => {
  const { translate, dataProvider, fy, setFY } = props;
  const [loading, setLoading] = useState(true);
  const dataYear = getYearList();
  const [data, setData] = useState([]);
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      dataProvider
        .getList("countryTargets", {
          pagination: { page: 1, perPage: 200 },
          sort: { field: "id", order: "ASC" },
          filter: { financial_year: fy },
        })
        .then(({ data }) => {
          // console.log(data);
          if (data) {
            setData(data);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }, 1000);

    return () => clearTimeout(timer);
  }, [fy, dataProvider]);

  const handleChange = (e) => {
    // console.log("dptnya apa", e.target.value);
    setFY(e.target.value);
  };

  if (loading) {
    return <LoadingRa size={"md"} />;
  }

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent="flex-end"
        pb={[3]}
        px={[2]}
      >
        <FormControl>
          <InputLabel id="select-label-date_year" style={{ minWidth: 80 }}>
            {translate("resources.targets.fields.fy")}
          </InputLabel>
          <Select
            labelId="select-label-date_year"
            id="select-date_year"
            value={fy}
            onChange={handleChange}
          >
            {dataYear.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Flex>
      {data && data.length === 0 ? (
        <Box width={[1]}>
          <EmptyList
            {...props}
            title={"resources.targets.message.empty_list_country_phasing"}
            fy={getFY(fy)}
          />
        </Box>
      ) : (
        <RowCountryPhasing data={data} translate={translate} fy={fy} />
      )}
    </Flex>
  );
};

export default withDataProvider(ListCountryPhasing);
