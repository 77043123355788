import React from "react";
import { Flex, Text, Box } from "rebass";
import AvatarUser from "./AvatarUser";
import { PROFILE } from "../../restClient";
export const UserContent = () => {
  const profile = localStorage.getItem(PROFILE);

  // if (
  //   (profile === undefined || profile) === null ||
  //   profile === "[object Object]"
  // ) {
  //   return <span />
  // }

  const newProfile = profile && profile !== null ? JSON.parse(profile) : {};
  return (
    <Flex flexDirection={["row"]} alignItems={"center"}>
      <AvatarUser src={newProfile.avatar} />
      <Box>
        <Text fontSize={12} color={"#FFFFFF"}>
          Hi,{" "}
          {newProfile.first_name
            ? `${newProfile.first_name} ${newProfile.last_name} `
            : "Admin"}
        </Text>
        <Text fontSize={12} color={"#FFFFFF"}>
          {newProfile.email ? newProfile.email : ""}
        </Text>
        <Text fontSize={12} color={"#FFFFFF"}>
          {newProfile.role ? newProfile.role : ""}
        </Text>
      </Box>
    </Flex>
  );
};
