import React from "react";
import { Flex } from "rebass";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Title } from "../../components";
import { getYearList } from "./utils";

const FormFieldDealerTarget = (props) => {
  const { handleChange, translate, state, dealers } = props;
  const dataYear = getYearList();
  return (
    <Flex
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      p={[3]}
    >
      <Title pb={[3]} fontWeight={"bold"} fontSize={[2]}>
        {translate("resources.buttons.upload_dealer_target")}
      </Title>
      <Flex
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <div style={{ paddingLeft: 8 }}>
          <FormControl>
            <InputLabel
              style={{ paddingLeft: 0 }}
              id='select-label-date_year'
              shrink
            >
              {translate("resources.targets.fields.fy")}
            </InputLabel>
            <Select
              labelId='select-label-date_year'
              id='select-date_year'
              value={state.dateYear}
              onChange={(e) => handleChange(e, "dateYear")}
              inputProps={{ "aria-label": "Without label" }}
              style={{ width: 8, paddingLeft: 0 }}
            >
              {dataYear.map((item, index) => (
                <MenuItem value={item.id} key={index}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ paddingLeft: 8 }}>
          <FormControl>
            <InputLabel
              style={{ paddingLeft: 0 }}
              id='select-label-dealers'
              shrink
            >
              {translate("resources.targets.fields.dealer")}
            </InputLabel>
            <Select
              labelId='select-label-dealers'
              id='select-dealers'
              value={state.dealer}
              onChange={(e, index) => handleChange(e, "dealer", index)}
              inputProps={{ "aria-label": "Without label" }}
              style={{ width: 300, paddingLeft: 0 }}
            >
              {dealers &&
                dealers.length > 0 &&
                dealers.map((item, index) => (
                  <MenuItem value={item.id} key={index} item={item}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </Flex>
    </Flex>
  );
};

export default FormFieldDealerTarget;
//
