import React from "react";
import { get } from "lodash";
import { Edit, useTranslate } from "react-admin";
import { Flex, Box } from "rebass";
import { BetterOrderPOForm, Title, useStoreUrl } from "../components";
import { EditFormFields } from "./components_po/promotions";
import { FooterPOToolbar } from "./components_po";
import ListButton from "./components_po/promotions/ListButton";

const ActionForm = (props) => {
  const translate = useTranslate();
  const { pt = 3 } = props;
  return (
    <Flex
      flexDirection={"row"}
      justifyContent={"space-between"}
      width={[1]}
      pt={[pt]}
      pb={[2]}
    >
      <Box width={[1]}>
        <Title fontWeight={"bold"} fontSize={[3]}>
          {translate(props.title)}
          {` ${get(props, "data.po_number", "")}`}
        </Title>
      </Box>
      <Flex width={[1]} flexDirection={"row"} justifyContent={"flex-end"}>
        <ListButton
          basepath={props.basePath}
          record={props.data}
          history={props.history}
        />
      </Flex>
    </Flex>
  );
};

// const OrdersEdit = (props) => {
//   // console.log(props)
//   return (
//     <Edit
//       {...props}
//       redirect={"list"}
//       actions={<ActionForm title={"resources.po.edit_po"} {...props} />}
//     >
//       <BetterOrderPOForm
//         {...props}
//         toolbar={<FooterPOToolbar redirect={"list"} />}
//         typePostUrl={"UPDATE"}
//       >
//         <EditFormFields {...props} />
//       </BetterOrderPOForm>
//     </Edit>
//   );
// };
const withStore = (BaseComponent) => (props) => {
  const { url } = useStoreUrl();
  return <BaseComponent {...props} urlDefault={url} />;
};

class OrdersEdit extends React.Component {
  state = {
    isBackButtonClicked: false,
    open: false,
    url: this.props.location.pathname,
    fullUrl: window.location.href,
  };
  componentDidMount() {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = this._backConfirm;
  }
  componentWillUnmount() {
    window.onpopstate = () => {};
  }
  _backConfirm = async () => {
    if (this.state.fullUrl === window.location.href) {
      const { urlDefault } = this.props;
      // console.log("urlDefault", urlDefault);
      let event = window.confirm("Are you sure want to leave this page?");
      if (!event) {
        window.history.pushState(null, "", window.location.href);
      } else {
        const newUrl =
          urlDefault && urlDefault !== null ? urlDefault : "/orders";
        this.props.history.push(newUrl);
      }
    }
  };

  render() {
    return (
      <Edit
        {...this.props}
        redirect={"list"}
        actions={<ActionForm title={"resources.po.edit_po"} {...this.props} />}
      >
        <BetterOrderPOForm
          {...this.props}
          toolbar={<FooterPOToolbar redirect={"list"} />}
          typePostUrl={"UPDATE"}
        >
          <EditFormFields {...this.props} typePostUrl={"UPDATE"} />
        </BetterOrderPOForm>
      </Edit>
    );
  }
}

export default withStore(OrdersEdit);
