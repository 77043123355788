import React from "react";
import { Box } from "rebass";
import { Skeleton } from "antd";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Paper from "@material-ui/core/Paper";
import { get } from "lodash";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  useQuery,
} from "../../../components";
import { HeaderContents } from "../grid/items";

const TargetSummary = (props) => {
  const { translate, formData } = props;
  //totalQty, totalAmount
  const [state, setState] = React.useState({
    expanded: "panelTargetSummary",
  });

  const handleChange = (panel) => (event, newExpanded) => {
    setState({
      ...state,
      expanded: newExpanded ? panel : false,
    });
  };
  const { data, loading } = useQuery(
    `/purchaseOrders/targetSummary?dealer_id=${get(formData, "dealer_id")}`,
    {
      method: "GET",
    }
  );
  // console.log(data);
  return (
    <Paper elevation={0} style={{ padding: 0 }}>
      <Box width={[1]} p={[2]}>
        <ExpansionPanel
          expanded={state.expanded === "panelTargetSummary"}
          onChange={handleChange("panelTargetSummary")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='target_summary-content'
            id='target_summary-header'
          >
            <Typography>
              {translate("resources.promotions.panel.target_summary")}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{ width: "100%%", padding: 0, paddingTop: 16 }}
          >
            <Box width={[1]} px={[2]}>
              {loading ? <Skeleton /> : <HeaderContents {...props} ts={data} />}
            </Box>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Box>
    </Paper>
  );
};

export default TargetSummary;
