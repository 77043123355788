import React, { useState, Fragment } from "react";
import { Flex, Box, Text } from "rebass";
import { has, get, union, uniqBy } from "lodash";
import {
  useTranslate,
  useDataProvider,
  useNotify,
  FormDataConsumer,
} from "react-admin";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { PaperIcon, AddCircleIcon } from "../../../components/icon_svg";
import EmptyOrderItem from "./EmptyOrderItem";
import ContainerDimensions from "react-container-dimensions";
import ItemsDrawer from "./ItemsDrawer";
import ListOrderItems from "./ListOrderItems";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  fullList: {
    width: 1000,
  },
}));

const FormFieldCustomer = (props) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const onAddItemProduct = (qty, product, data) => {
    if (has(product, "id") && qty && qty > 0) {
      const orderItems = get(data, "items", []);
      const newPromotions = {};
      const newItem = {
        result: {
          product_id: product.id,
          qty: qty * 1,
          price: product.price,
          subtotal: qty * product.price,
          promotions: newPromotions, //isArray(newPromotions) ? newPromotions : [promotions],
        },
        product: product,
        product_id: product.id,
      };
      const result = union([newItem], orderItems);
      // console.log(result)
      props.updateField("items", uniqBy(result, "product_id"));
    } else {
      notify("resources.orders.messages.product_qty", "warning");
    }
  };

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <FormDataConsumer>
        {({ formData }) => (
          <ContainerDimensions>
            {({ width }) => {
              return (
                <Fragment>
                  <Paper elevation={2} style={{ margin: 0 }}>
                    <Box width={[1]} style={{ minHeight: "65vh" }} p={[3]}>
                      <Flex
                        width={[1]}
                        flexDirection={"row"}
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Typography color={"primary"} component={"div"}>
                          <Text style={{ width: 130 }}>
                            {translate("resources.po.tabs.order_items")}
                          </Text>
                        </Typography>
                        <Flex
                          width={[1]}
                          flexDirection={"row"}
                          alignItems="center"
                          justifyContent="flex-start"
                          className={classes.root}
                        >
                          <Button
                            onClick={() => setOpen(true)}
                            color="primary"
                            style={{
                              textTransform: "capitalize",
                              marginRight: 8,
                            }}
                            startIcon={<AddCircleIcon />}
                          >
                            {translate("resources.buttons.add_product")}
                          </Button>
                          <Button
                            color="primary"
                            style={{
                              textTransform: "capitalize",
                              marginRight: 8,
                            }}
                            startIcon={<PaperIcon />}
                          >
                            {translate("resources.buttons.import_from_csv")}
                          </Button>
                        </Flex>
                      </Flex>
                      {formData &&
                      formData.items &&
                      formData.items.length > 0 ? (
                        <ListOrderItems
                          formData={formData}
                          {...props}
                          translate={translate}
                        />
                      ) : (
                        <EmptyOrderItem {...props} translate={translate} />
                      )}
                    </Box>
                  </Paper>
                  <ItemsDrawer
                    {...props}
                    translate={translate}
                    dataProvider={dataProvider}
                    width={width}
                    setOpen={setOpen}
                    open={open}
                    classes={classes}
                    onAddItemProduct={onAddItemProduct}
                    data={formData}
                  />
                </Fragment>
              );
            }}
          </ContainerDimensions>
        )}
      </FormDataConsumer>
    </Flex>
  );
};

export default FormFieldCustomer;
