import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { ListIcon } from "../../icon_svg"

import Button from "./Button"

const ListButton = ({
  basepath = "",
  label = "ra.action.list",
  icon,
  ...rest
}) => {
  return (
    <Button
      component={Link}
      to={basepath}
      label={label}
      {...rest}
      sizeIcon={"xsmall"}
      style={{ textTransform: "capitalize" }}
    >
      {icon}
    </Button>
  )
}
ListButton.propTypes = {
  basepath: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.element,
}

ListButton.defaultProps = {
  icon: <ListIcon />,
}

export default ListButton
