export default {
  pricebook: {
    title: "Buku Harga",
    new_pricebook: "Buat Buku Harga Baru",
    edit_pricebook: "Ubah Buku Harga",
    show_pricebook: "Dari product ",
    tabs: {
      customer: "Konsumen",
      dealer: "Dealer",
    },
    fields: {
      name: "Nama",
      created_at: "Dibuat pada",
      active: "Aktif",
      site_id: "Situs",
      start_at: "Tanggal Awal",
      end_at: "Tanggal Akhir",
      country: "Negara",
      type: "Type",
      product_code: "Product Code",
      qty: "Qty",
      line_number: "Line Number",
      price: "Price",
      product_packaging: "Product Packaging",
    },
    messages: {
      please_select: "Please Select Country and fill Name",
      success: "Upload Success",
      message_failed: "Upload Failed",
    },
  },
};
