import React, { Component, Fragment } from "react";
import { find, filter, has, get, includes, toLower } from "lodash";
import { AgGridReact } from "ag-grid-react";
import { Flex } from "rebass";
import numeral from "numeral";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { getProducts } from "../../../../restClient";
import { getUser } from "../../../../utils";
import { defaultColumnDefs } from "./defaultValue";
import { checkPromotion } from "./utils";
import { NormalFilterFields } from "./NormalFilterFields";
import { ShowMessage } from "./ShowMessage";
// /products?_sort=&_order=&search=&category_id=&group_id=&subgroup_id=

const getFilterSearchProductList = (list, value) => {
  let listProduct = filter(list, function (o) {
    const newValue = toLower(value);
    const myPacking = get(o, "packaging", "");
    const packing = toLower(`${myPacking}`);
    return value !== "" ? includes(packing, newValue) : true;
  });
  return listProduct;
};

const getShowItemProductList = (list) => {
  let listProduct = filter(list, function (o) {
    return o.qty > 0;
  });
  return listProduct;
};

class ArListGridNormal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showItemOnly: false,
      search: "",
      message: "",
      show: false,
      rowData: [],
      oldrowData: [],
      columnDefs: defaultColumnDefs,
      defaultColDef: {
        flex: 1,
        editable: true,
        sortable: true,
        filter: true,
        cellStyle: {
          "line-height": "40px",
        },
      },
      getRowNodeId: function (data) {
        return data.code;
      },
      rowClassRules: {
        "red-row": 'data.Promo == "YES"',
      },
      category: "",
      group: "",
      subgroup: "",
      growth: "",
      recency: "",
      filters: {
        category: "",
        group: "",
        subgroup: "",
        growth: "",
        recency: "",
      },
    };
  }
  componentDidMount() {
    const { tempList, promotions } = this.props;
    const user = getUser();
    getProducts("")
      .then((response) => {
        // console.log(response);
        // console.log(promotions);
        if (response && response.length > 0) {
          const myLatest = response.map((item) => {
            const value = checkPromotion(item, promotions);
            // console.log(item);
            let newItem = item;
            const objUrl = find(tempList, function (o) {
              // console.log(promoType, o.id);
              return o.product_id === item.id;
            });

            if (objUrl && objUrl.product_id) {
              // console.log(promoType, objUrl);
              newItem["qty"] = objUrl.qty;
              newItem["subtotal"] = objUrl.subtotal;
            } else {
              newItem["qty"] = 0;
              newItem["subtotal"] = 0;
            }

            if (toLower(get(item, "moq", 0)) === "indent") {
              // console.log(get(item, "moq", 0));
              newItem["moq"] = 0;
            } else if (get(item, "moq", 0) === "*") {
              newItem["moq"] = 0;
            } else if (get(item, "moq", 0) === "") {
              newItem["moq"] = 0;
            }

            newItem["cr"] = get(item, "cr", false) === true ? "CR" : "";
            newItem["dg"] = get(item, "dg", false) === true ? "DG" : "";
            newItem["promo"] = value;
            newItem["target_id"] = get(item, "target_id", 0);
            newItem["growth"] = get(
              item,
              `class4.${get(user, "country_id", 0)}`,
              ""
            );

            newItem["price"] = get(item, "price", 0) * 1;
            return newItem;
          });
          this.setState({
            rowData: myLatest,
            oldrowData: myLatest,
          });
        }
      })
      .catch((e) => {
        // console.log(e);
      });
  }
  onGridReady = (params) => {
    this.gridApiNormal = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onCellChanged(params, locale) {
    locale.onUpdateItem(params);
  }

  onUpdateItem = (params) => {
    let mymoq = params.data.moq;
    if (mymoq === "Indent") {
      mymoq = 0;
    } else if (mymoq === "*") {
      mymoq = 0;
    }

    if (params.data.qty && params.data.qty > 0 && params.data.qty >= mymoq) {
      this.setOrderAmtDefault(params.data.code, params.data);
    }
  };

  setOrderAmtDefault(ProductCode, data) {
    if (ProductCode) {
      const { tempList } = this.props;
      let newListPromo = tempList;
      if (newListPromo.length === 0) {
        const itemProduct = {
          product_id: data.id,
          qty: data.qty,
          price: data.price,
          subtotal: data.subtotal,
          promotions: null,
          class4: data.class4,
          target_id: get(data, "target_id", 0),
        };

        newListPromo.push(itemProduct);
      } else {
        let newList = filter(newListPromo, function (o) {
          return o.product_id !== data.id;
        });
        const updateItem = find(newListPromo, function (o) {
          return o.product_id === data.id;
        });
        newListPromo = newList;
        let newItemProduct = {
          product_id: data.id,
          qty: data.qty,
          price: data.price,
          subtotal: data.subtotal,
          promotions: null,
          class4: data.class4,
        };
        if (updateItem && updateItem.id) {
          newItemProduct = {
            ...updateItem,
            product_id: data.id,
            qty: data.qty,
            price: data.price,
            subtotal: data.subtotal,
            promotions: null,
            class4: data.class4,
          };
        }
        newItemProduct["target_id"] = get(data, "target_id", 0);
        newListPromo.push(newItemProduct);
      }
      var rowNode = this.gridApiNormal.getRowNode(ProductCode);
      if (rowNode) {
        rowNode.setDataValue(
          "subtotal",
          Number(data.qty * data.price).toFixed(2)
        );
      }

      this.updateTempList(newListPromo);
    }
  }
  getRowStyleDef = (params) => {
    if (params.data.promo === "YES") {
      return {
        "background-color": "#b10505",
        color: "#FFFFFF",
      };
    }
    return null;
  };

  updateTempList = (newListPromo) => {
    this.props.setTempList(newListPromo);
  };
  onCellEditingStopped = (params) => {
    const MOQ = get(params, "data.moq", "");
    if (
      MOQ !== "" &&
      MOQ !== "Indent" &&
      MOQ !== "*" &&
      has(params, "data.code")
    ) {
      if (params.data.qty < MOQ * 1) {
        // const { translate } = this.props;
        this.setState({
          show: true,
          // message: `${translate(
          //   "resources.po.messages.order_qty_min"
          // )} ${MOQ} ${translate("resources.po.messages.order_qty_min1")}`,
          message: `MOQ for this product is ${MOQ}`,
        });

        var rowNode1 = this.gridApiNormal.getRowNode(params.data.code);
        rowNode1.setDataValue("subtotal", 0);
        rowNode1.setDataValue("qty", 0);
        this.setOrderAmtDefault(params.data.code, params.data);
      }
    } else {
      if (has(params, "data.code")) {
        // console.log(params);
        var rowNode = this.gridApiNormal.getRowNode(params.data.code);
        // console.log(rowNode);
        rowNode.setDataValue(
          "subtotal",
          numeral(params.data.qty * params.data.price).format("0,0.00")
        );
        //Number(params.data.qty * params.data.price).toFixed(2)
      }
    }
  };

  handleChange = (k, v) => {
    const params = {
      ...this.state.filters,
      [k]: v,
    };
    if (k === "search") {
      const newRawData = getFilterSearchProductList(this.state.oldrowData, v);
      this.setState({
        [k]: v,
        filters: params,
        rowData: newRawData,
      });
    } else {
      let myparams = "";
      let myparams1 = {};
      for (const [key, value] of Object.entries(params)) {
        if (value && value !== "") {
          if (myparams === "") {
            if (key === "category") {
              myparams = `category_id=${value}`;
              myparams1 = { category_id: value };
            } else if (key === "group") {
              myparams = `group_id=${value}`;
              myparams1 = { group_id: value };
            } else if (key === "subgroup") {
              myparams = `subgroup_id=${value}`;
              myparams1 = { subgroup_id: value };
            } else if (key === "growth") {
              myparams = `growth=${value}`;
            } else if (key === "recency") {
              myparams = `new_existing=${value}`;
            }
          } else {
            if (key === "category") {
              myparams = `${myparams}&category_id=${value}`;
              myparams1 = { ...myparams1, category_id: value };
            } else if (key === "group") {
              myparams = `${myparams}&group_id=${value}`;
              myparams1 = { ...myparams1, group_id: value };
            } else if (key === "subgroup") {
              myparams = `${myparams}&subgroup_id=${value}`;
              myparams1 = { ...myparams1, subgroup_id: value };
            } else if (key === "growth") {
              myparams = `${myparams}&growth=${value}`;
            } else if (key === "recency") {
              myparams = `${myparams}&new_existing=${value}`;
            }
          }
        }
      }
      this.setState({
        [k]: v,
        filters: params,
      });
      if (myparams !== "") {
        //myparams,
        this.handleFilter(myparams1);
      } else {
        // console.log("testing", myparams);
        this.setState({
          rowData: this.state.oldrowData,
        });
      }
    }
  };

  handleFilter = (myparams1) => {
    // console.log(myparams1);
    // const user = getUser();
    const { tempList } = this.props;
    if (this.state.oldrowData && this.state.oldrowData.length > 0) {
      const myLatest = this.state.oldrowData.map((item) => {
        // const value = checkPromotion(item, promotions);
        // console.log(item);
        let newItem = item;
        const objUrl = find(tempList, function (o) {
          // console.log(promoType, o.id);
          return o.product_id === item.id;
        });

        if (objUrl && objUrl.product_id) {
          // console.log(promoType, objUrl);
          newItem["qty"] = objUrl.qty;
          newItem["subtotal"] = objUrl.subtotal;
        }
        //  else {
        //   newItem["qty"] = 0;
        //   newItem["subtotal"] = 0;
        // }
        // newItem["cr"] = get(item, "cr", false) === true ? "CR" : "";
        // newItem["dg"] = get(item, "dg", false) === true ? "DG" : "";
        // newItem["promo"] = value;
        // newItem["target_id"] = get(item, "target_id", 0);
        // newItem["growth"] = get(
        //   item,
        //   `class4.${get(user, "country_id", 0)}`,
        //   ""
        // );

        // newItem["price"] = get(item, "price", 0) * 1;
        return newItem;
      });

      const newFilter = filter(myLatest, myparams1);

      this.setState({
        rowData: newFilter,
      });
    }
  };

  onResetSearch = () => {
    this.setState({
      category: "",
      group: "",
      subgroup: "",
      growth: "",
      recency: "",
      filters: {
        category: "",
        group: "",
        subgroup: "",
        growth: "",
        recency: "",
      },
      rowData: this.state.oldrowData,
    });
  };
  onShowItemmOnly = (checked) => {
    if (checked) {
      const newList = getShowItemProductList(this.state.rowData);
      this.setState({
        showItemOnly: checked,
        rowData: newList,
      });
    } else {
      this.setState({
        showItemOnly: checked,
        rowData: this.state.oldrowData,
      });
    }
  };

  render() {
    return (
      <Fragment>
        <Flex width={[1]} flexDirection={"column"}>
          <NormalFilterFields
            translate={this.props.translate}
            state={this.state}
            handleChange={this.handleChange}
            onResetSearch={this.onResetSearch}
            onShowItemmOnly={(e) => this.onShowItemmOnly(e.target.checked)}
          />
          <div style={{ width: "100%", height: "100%" }}>
            <div className='grid-wrapper'>
              <div
                id='myGridNomrmal'
                style={{
                  height: "43vh",
                  width: "100%",
                }}
                className='ag-theme-balham'
              >
                <AgGridReact
                  rowHeight={40}
                  headerHeight={40}
                  rowData={this.state.rowData}
                  columnDefs={this.state.columnDefs}
                  defaultColDef={this.state.defaultColDef}
                  animateRows={true}
                  getRowStyle={this.getRowStyleDef}
                  getRowNodeId={this.state.getRowNodeId}
                  onGridReady={this.onGridReady}
                  onCellValueChanged={(params) =>
                    this.onCellChanged(params, this)
                  }
                  onCellEditingStopped={this.onCellEditingStopped}
                />
              </div>
            </div>
          </div>
        </Flex>
        {this.state.show && (
          <ShowMessage
            open={this.state.show}
            setOpen={(v) => this.setState({ show: v })}
            message={this.state.message}
          />
        )}
      </Fragment>
    );
  }
}

export default ArListGridNormal;
