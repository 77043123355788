import React from "react"
import { Flex, Box, Text } from "rebass"
import { get, parseInt, has } from "lodash"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import IconButton from "@material-ui/core/IconButton"

import CloseIcon from "@material-ui/icons/Delete"
import { withStyles } from "@material-ui/core/styles"
import { EditIcon } from "../../components/icon_svg"
import { dataProvider } from "../../restClient"
import {
  styles,
  StyledTableAction,
  StyledTableCell,
  StyledTableRow,
  StyledTableSmall,
  StyledTableRowLast,
} from "./styles"
import ProductField from "./ProductField"
import EditProductField from "./EditProductField"

class EditOrderItems extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      orderItems: [],
    }
  }
  onUpdateFieldOrder = (resource, values) => {
    this.props.updateField(resource, values)
  }

  componentDidMount() {
    const { formData } = this.props
    dataProvider
      .getList("orderItems", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: "id", order: "ASC" },
        filter: { order_id: formData.id },
      })
      .then(response => {
        if (response && response.data) {
          // console.log(response)
          const { data } = response
          const newData = data.map(item => {
            const newItem = {
              result: item,
              product_id: item.product_id,
            }
            return newItem
          })
          this.setState({
            orderItems: newData,
          })
          this.props.updateField("orderItems", newData)
        } else {
        }
      })
  }
  render() {
    const {
      formData,
      translate,
      classes,
      onRemoveItem,
      onEditItem,
    } = this.props
    const items = get(formData, "orderItems", this.state.orderItems)
    let total = 0
    return (
      <Box width={[1]} p={[3]}>
        <Text color={"green"} pb={[3]}>
          {translate("resources.orders.fields.order_items")}
        </Text>
        {items.length > 0 && (
          <TableContainer component={"div"}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    {translate("resources.orders.fields.product")}
                  </StyledTableCell>
                  <StyledTableSmall>
                    {translate("resources.orders.fields.code")}
                  </StyledTableSmall>
                  <StyledTableSmall>
                    {translate("resources.orders.fields.qty")}
                  </StyledTableSmall>
                  <StyledTableSmall>
                    {translate("resources.orders.fields.price")}
                  </StyledTableSmall>
                  <StyledTableSmall>
                    {translate("resources.orders.fields.subtotal")}
                  </StyledTableSmall>
                  <StyledTableAction />
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item, index) => {
                  total = total + parseInt(get(item, "result.subtotal", 0))
                  // console.log(item)
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="td" scope="row">
                        <Box>
                          {has(item, "product.packaging") ? (
                            <Text>{item.product.packaging}</Text>
                          ) : (
                            <EditProductField
                              record={item}
                              source="packaging"
                              updateField={this.onUpdateFieldOrder}
                              resource={`orderItems[${index}].product`}
                              isSetProduct={!has(item, "product.packaging")}
                            />
                          )}
                          <Text style={{ color: "#FF0000" }} pt={[3]}>
                            {get(
                              item,
                              "result.promotions[0].name",
                              "Buy 12 Get 1 Free"
                            )}
                          </Text>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row">
                        <ProductField record={item} source="code" />
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row">
                        <Text>{item.result.qty}</Text>
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row">
                        <Text>{item.result.price}</Text>
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row">
                        <Text>{item.result.subtotal}</Text>
                      </StyledTableCell>
                      <StyledTableAction component="td" scope="row">
                        <Flex flexDirection={"row"} alignItems={"center"}>
                          <IconButton
                            className={classes.leftIcon}
                            onClick={() => onRemoveItem(item, items)}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>

                          <IconButton onClick={() => onEditItem(item)}>
                            <EditIcon fontSize="small" />
                          </IconButton>
                          <Box width={"10px"} />
                        </Flex>
                      </StyledTableAction>
                    </StyledTableRow>
                  )
                })}
                <StyledTableRowLast>
                  <StyledTableCell />
                  <StyledTableCell />
                  <StyledTableCell />
                  <StyledTableCell>
                    <Text fontWeight={"bold"}>Total</Text>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Text fontWeight={"bold"}>{total}</Text>
                  </StyledTableCell>
                  <StyledTableAction />
                </StyledTableRowLast>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    )
  }
}

// const EditOrderItems = props => {
//   const { formData, translate, classes, onRemoveItem, onEditItem } = props

//   const { data } = useQueryWithStore({
//     type: "getList",
//     resource: "orderItems",
//     payload: {
//       pagination: { page: 1, perPage: 100 },
//       sort: { field: "order_id", order: "ASC" },
//       filter: { order_id: formData.id },
//     },
//   })
//   console.log(data)
//   const items = get(formData, "orderItems", [])
//   let total = 0
//   return (

//   )
// }

export default withStyles(styles)(EditOrderItems)
