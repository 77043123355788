import React, { Fragment } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Flex, Box } from "rebass";
import { withStyles } from "@material-ui/core/styles";
import { styles, StyledTableCell, StyledTableRow } from "./styles";
import { get, uniqBy } from "lodash";

const RowItem = (props) => {
  const { item } = props;
  const contents = get(item, "contents", []);
  return (
    <StyledTableRow>
      <StyledTableCell>{get(item, "line_number", "")}</StyledTableCell>
      {contents &&
        contents.length > 0 &&
        contents.map((itemrow, indexRow) => {
          if (indexRow < 4) {
            return (
              <StyledTableCell key={indexRow} style={{ minWidth: 200 }}>
                {itemrow !== "" ? itemrow : ""}
              </StyledTableCell>
            );
          } else {
            return <Fragment />;
          }
        })}
    </StyledTableRow>
  );
};

const ListTargetError = (props) => {
  const { list, translate, classes } = props;
  // const itemIndexO =
  //   list && list.length > 0 ? get(list[0], "contents", []) : [];
  // const listHeader =
  //   itemIndexO.length > 5 ? new Array(itemIndexO.length - 5) : [];
  const newList = uniqBy(list, "line_number");
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <Box width={[1]} py={[3]}>
        <TableContainer component={"div"}>
          <Table
            // stickyHeader
            className={classes.table}
            aria-label='customized table'
          >
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ minWidth: 120 }}>
                  {translate("resources.targets.table.line_number")}
                </StyledTableCell>
                <StyledTableCell style={{ minWidth: 200 }}>
                  {translate("resources.targets.table.categories")}
                </StyledTableCell>
                <StyledTableCell style={{ minWidth: 200 }}>
                  {translate("resources.targets.table.target")}
                </StyledTableCell>
                <StyledTableCell style={{ minWidth: 200 }}>
                  {translate("resources.targets.table.product_group")}
                </StyledTableCell>
                <StyledTableCell style={{ minWidth: 200 }}>
                  {translate("resources.targets.table.sub_product_group")}
                </StyledTableCell>
                {/* {listHeader &&
                  listHeader.map((headeritem) => {
                    return (
                      <StyledTableCell
                        key={headeritem}
                        style={{ minWidth: 200 }}
                      />
                    );
                  })} */}
              </TableRow>
            </TableHead>
            <TableBody>
              {newList &&
                newList.length > 0 &&
                newList.map((item, index) => {
                  return <RowItem key={index} item={item} />;
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Flex>
  );
};

export default withStyles(styles)(ListTargetError);
