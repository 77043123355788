import React from "react";
import { Flex, Box, Text } from "rebass";
import moment from "moment";
import { get } from "lodash";
import {
  SelectInput,
  ReferenceInput,
  useTranslate,
  FormDataConsumer,
  required,
} from "react-admin";
import { RaTextInput, RaBooleanInput } from "../../components";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

const FormFields = (props) => {
  // const { MyType } = props;
  const translate = useTranslate();
  const onChange = (value) => {
    if (value[0] && value[0] !== null) {
      props.updateField("start_at", value[0].toISOString());
    }
    if (value[1] && value[1] !== null) {
      props.updateField("end_at", value[1].toISOString());
    }
  };
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <ReferenceInput
        label={"resources.pricebook.fields.country"}
        source='country_id'
        reference='countries'
        style={{ width: 256 }}
        sort={{ field: "name", order: "ASC" }}
        validate={[required()]}
      >
        <SelectInput optionText='name' />
      </ReferenceInput>

      <RaBooleanInput
        source={"active"}
        label={"resources.pricebook.fields.active"}
      />
      <RaTextInput
        source={"name"}
        style={{ width: 400 }}
        label={"resources.pricebook.fields.name"}
        validate={[required()]}
      />
      {/* {MyType === "" && (
        <SelectInput
          source={"type"}
          label={"resources.pricebook.fields.type"}
          style={{ width: 200 }}
          choices={[
            { id: "Dealer", name: "Dealer" },
            { id: "Customer", name: "Customer" },
          ]}
        />
      )} */}
      {/* <DateInput
        source="start_at"
        lable="resources.pricebook.fields.start_at"
        style={{ width: 200 }}
        parse={formatDate}
        options={{ format: "DD-MMM-YYYY" }}
      />
      <DateInput
        source="end_at"
        lable="resources.pricebook.fields.end_at"
        style={{ width: 200 }}
        parse={formatDate}
        options={{ format: "DD-MMM-YYYY" }}
      /> */}
      <FormDataConsumer>
        {({ formData }) => {
          const startDate = get(formData, "start_at", moment());
          const endDate = get(formData, "end_at", moment());

          return (
            <Box width={[1]} py={[2]}>
              <Flex
                width={[1]}
                flexDirection={"row"}
                justifyContent={"flex-start"}
              >
                <Text
                  py={[1]}
                  fontSize={[0]}
                  fontWeight={"bold"}
                  width={"140px"}
                  color={"grey"}
                >
                  {translate("resources.pricebook.fields.start_at")}
                </Text>
                <Text
                  py={[1]}
                  fontSize={[0]}
                  fontWeight={"bold"}
                  color={"grey"}
                >
                  {translate("resources.pricebook.fields.end_at")}
                </Text>
              </Flex>
              <RangePicker
                format='DD-MMM-YYYY'
                onChange={onChange}
                defaultValue={[moment(startDate), moment(endDate)]}
              />
            </Box>
          );
        }}
      </FormDataConsumer>
    </Flex>
  );
};
export default FormFields;
