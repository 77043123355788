import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "../../Expand";
import { filter, get, union, uniqBy } from "lodash";

import { getTotalOrderPromo, getOldListPromo } from "../HeaderOrdersItem";

import { defaultColumnDefs, getNumber } from "../utils";
/**
     * ProductGroup  => group_id =>  611

SubProductGroup  => subgroup_id   621

ProductCode  => code =>  "901126"

ProductShortCode  => short_code   "901126" 
     */

class GridPromoABCD extends Component {
  constructor(props) {
    super(props);

    const { item, formData, products } = props;
    // console.log(item);
    // console.log(product);
    // const { Criteria, Match } = item;
    let listProduct = filter(products, function (o) {
      return (
        o.subgroup_id === item.criteria.buy_ab_get_cd.a.id ||
        o.subgroup_id === item.criteria.buy_ab_get_cd.b.id
      );
    });
    if (
      get(item, "criteria.buy_ab_get_cd.a.type", "") === "ProductCode" ||
      get(item, "criteria.buy_ab_get_cd.b.type", "") === "ProductCode"
    ) {
      listProduct = filter(products, function (o) {
        return (
          o.code === item.criteria.buy_ab_get_cd.a.id ||
          o.code === item.criteria.buy_ab_get_cd.b.id
        );
      });
    } else if (
      get(item, "criteria.buy_ab_get_cd.a.type", "") === "ProductGroup" ||
      get(item, "criteria.buy_ab_get_cd.b.type", "") === "ProductGroup"
    ) {
      listProduct = filter(products, function (o) {
        return (
          o.code === item.criteria.buy_ab_get_cd.a.id ||
          o.code === item.criteria.buy_ab_get_cd.b.id
        );
      });
    } else if (
      get(item, "criteria.buy_ab_get_cd.a.type", "") === "ProductShortCode" ||
      get(item, "criteria.buy_ab_get_cd.b.type", "") === "ProductShortCode"
    ) {
      listProduct = filter(products, function (o) {
        return (
          o.code === item.criteria.buy_ab_get_cd.a.id ||
          o.code === item.criteria.buy_ab_get_cd.b.id
        );
      });
    }
    const listPromo = get(formData, "items", []);
    const allTotal = getTotalOrderPromo(listPromo, item);
    const latestListPromo = getOldListPromo(listPromo, item);

    if (latestListPromo) {
      const data = union(latestListPromo.listPromo, listProduct);
      listProduct = uniqBy(data, "code");
    } else {
      listProduct = filter(products, function (o) {
        if (
          o.subgroup_id === item.criteria.buy_ab_get_cd.a.id ||
          o.subgroup_id === item.criteria.buy_ab_get_cd.b.id
        ) {
          o.Order = 0;
          o.OrderAmt = 0;
          return (
            o.subgroup_id === item.criteria.buy_ab_get_cd.a.id ||
            o.subgroup_id === item.criteria.buy_ab_get_cd.b.id
          );
        } else if (
          o.code === item.criteria.buy_ab_get_cd.a.id ||
          o.code === item.criteria.buy_ab_get_cd.b.id
        ) {
          o.Order = 0;
          o.OrderAmt = 0;
          return (
            o.code === item.criteria.buy_ab_get_cd.a.id ||
            o.code === item.criteria.buy_ab_get_cd.b.id
          );
        } else if (
          o.group_id === item.criteria.buy_ab_get_cd.a.id ||
          o.group_id === item.criteria.buy_ab_get_cd.b.id
        ) {
          o.Order = 0;
          o.OrderAmt = 0;
          return (
            o.group_id === item.criteria.buy_ab_get_cd.a.id ||
            o.group_id === item.criteria.buy_ab_get_cd.b.id
          );
        } else if (
          o.short_code === item.criteria.buy_ab_get_cd.a.id ||
          o.short_code === item.criteria.buy_ab_get_cd.b.id
        ) {
          o.Order = 0;
          o.OrderAmt = 0;
          return (
            o.short_code === item.criteria.buy_ab_get_cd.a.id ||
            o.short_code === item.criteria.buy_ab_get_cd.b.id
          );
        }
        return false;
      });
    }
    this.state = {
      search: "",
      message: "",
      show: false,
      rowData: listProduct,
      columnDefs: defaultColumnDefs,
      defaultColDef: {
        flex: 1,
        editable: true,
        sortable: true,
        filter: true,
        cellStyle: {
          "line-height": "40px",
        },
      },
      getRowNodeId: function (data) {
        return data.code;
      },
      listPromo: get(latestListPromo, "listPromo", []),
      item: props.item,
      tPromoOrderQty: allTotal.totalOrderQty,
      tPromoOrderAmt: allTotal.totalOrderAmt,
      freeTOQ: 0,
      expanded: "panelGridPromoABCD",
    };
  }

  onGridReady = (params) => {
    this.gridApiABCD = params.api;
    this.gridColumnApiABCD = params.columnApi;
  };

  onCellChanged(params, locale) {
    locale.onUpdateItem(params);
    // locale.props.onUpdatePromoProduct(params)
  }
  onUpdateItem = (params) => {
    if (params.data.Order && params.data.Order > 0) {
      const { listPromo } = this.state;
      let newListPromo = listPromo;
      const values = {
        ...params.data,
      };

      if (newListPromo.length === 0) {
        newListPromo.push(values);
      } else {
        let newList = filter(newListPromo, function (o) {
          return o.code !== params.data.code;
        });
        newListPromo = newList;
        newListPromo.push(values);
      }
      this.setOrderAmtDefault(params.data.code, params.data);
      const tpoq = getNumber(newListPromo, "tPromoOrderQty");
      const tpoqa = getNumber(newListPromo, "tPromoOrderAmt");
      this.setState({
        listPromo: newListPromo,
        tPromoOrderQty: tpoq,
        tPromoOrderAmt: tpoqa,
      });
      this.props.onUpdatePromoProduct(newListPromo, tpoq, tpoqa);
    }
  };

  handleChange = (panel) => (event, newExpanded) => {
    this.setState({
      expanded: newExpanded ? panel : false,
    });
  };

  setOrderAmtDefault(ProductCode, item) {
    if (ProductCode) {
      var rowNode = this.gridApiABCD.getRowNode(ProductCode);
      rowNode.setDataValue(
        "OrderAmt",
        Number(item.Order * item.price).toFixed(2)
      );
    }
  }

  render() {
    const { translate } = this.props;

    return (
      <ExpansionPanel
        expanded={this.state.expanded === "panelGridPromoABCD"}
        onChange={this.handleChange("panelGridPromoABCD")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='promotion_items-abcd-content'
          id='promotion_items-abcd-header'
        >
          <Typography>
            {translate("resources.po.listgrid.label.promo_items")} (
            {this.state.rowData.length}{" "}
            {this.state.rowData.length > 1
              ? translate("resources.po.listgrid.label.items")
              : translate("resources.po.listgrid.label.item")}
            )
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          style={{ width: "100%%", padding: 0, paddingTop: 4 }}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <div className='grid-wrapper'>
              <div
                id='myGrid'
                style={{
                  height: "20vh",
                  width: "100%",
                }}
                className='ag-theme-balham'
              >
                <AgGridReact
                  rowHeight={40}
                  headerHeight={40}
                  rowData={this.state.rowData}
                  columnDefs={this.state.columnDefs}
                  defaultColDef={this.state.defaultColDef}
                  animateRows={true}
                  getRowNodeId={this.state.getRowNodeId}
                  onGridReady={this.onGridReady}
                  onCellValueChanged={(params) =>
                    this.onCellChanged(params, this)
                  }
                />
              </div>
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export default GridPromoABCD;
