import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Box } from "rebass";
import { useQueryWithStore } from "react-admin";
import { TextRecord } from "../../../components";

const DealerField = (props) => {
  const { record } = props;
  const { data, loading } = useQueryWithStore({
    type: "getOne",
    resource: "dealers",
    payload: { id: record.dealer_id },
  });
  if (loading) {
    return (
      <Box width={["200px"]}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <TextRecord
      record={data}
      source={"name"}
      label={"resources.users.fields.dealer"}
    />
  );
};
export default DealerField;
