import React from "react"
import { Flex } from "rebass"
import { NumberInput, ArrayInput } from "react-admin"
import { ThemeProvider } from "@material-ui/styles"
import { themeTableInput } from "../../themes"
import { withStyles, createStyles } from "@material-ui/core/styles"
import SimpleFormIterator from "./SimpleFormIterator"

const mystyles = (theme) =>
  createStyles({
    root: {
      width: 400,
    },
  })

const CustomSettings = (props) => {
  const { isShowBuyX } = props
  console.log("helo=>", isShowBuyX)
  if (isShowBuyX === "") {
    return <div />
  }
  return (
    <ThemeProvider theme={themeTableInput}>
      <Flex width={[1]} flexDirection={"column"}>
        {isShowBuyX === "Buy X Get Y" && (
          <ArrayInput source="advanced_price.buy_x_get_y" {...props} label={""}>
            <SimpleFormIterator defaultValue={{ buy: 1, get: 1 }}>
              <NumberInput
                source="buy"
                style={{ width: 90, margin: 0 }}
                label={"resources.promotions.fields.buy"}
              />
              <NumberInput
                source="get"
                style={{ width: 90, margin: 0 }}
                label={"resources.promotions.fields.get"}
              />
            </SimpleFormIterator>
          </ArrayInput>
        )}
        {(isShowBuyX === "Percent" || isShowBuyX === "Amount") && (
          <ArrayInput source="advanced_price.percent" {...props} label={""}>
            <SimpleFormIterator defaultValue={{ qty: 1, discount: 1 }}>
              <NumberInput
                source="qty"
                style={{ width: 90, margin: 0 }}
                label={"resources.promotions.fields.h_qty"}
              />
              <NumberInput
                source="discount"
                style={{ width: 90, margin: 0 }}
                label={"resources.promotions.fields.discount"}
              />
            </SimpleFormIterator>
          </ArrayInput>
        )}
      </Flex>
    </ThemeProvider>
  )
}

export default withStyles(mystyles)(CustomSettings)
