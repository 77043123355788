import create from "zustand";

export const useBarcode = create((set) => ({
  barcode: "",
  setBarcode: (newState) =>
    set((state) => {
      // console.log("state", state);
      // console.log("newState,", newState);
      return { barcode: newState };
    }),
}));
