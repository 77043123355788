import React from "react";
import { Text } from "rebass";
import { get } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
// import { useQueryWithStore } from "react-admin";
import { useQuery } from "react-query";
import { getProductsWithId } from "../../restClient";
const cacheTimeMinute = 1000 * 60 * 30;
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
export default (props) => {
  const classes = useStyles();
  const { record, source } = props;
  const { isLoading, data } = useQuery(
    ["productDetail", record.product_id ? record.product_id : null],
    () => getProductsWithId(`/${record.product_id}`),
    { cacheTime: cacheTimeMinute }
  );
  // const { loaded, data } = useQueryWithStore({
  //   type: "getOne",
  //   resource: "products",
  //   payload: { id: record.product_id ? record.product_id : null },
  // });
  if (isLoading) {
    return (
      <div className={classes.root}>
        <LinearProgress variant='query' />
      </div>
    );
  }

  return <Text>{get(data, source, "")}</Text>;
};
