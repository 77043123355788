import React from "react"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  root: {
    boxShadow: "none",
  },

  content: {
    padding: 0,
  },
})

export const CardPanel = props => {
  const classes = useStyles()
  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>{props.children}</CardContent>
    </Card>
  )
}
