import React, { Fragment } from "react";
import { Flex, Text, Box } from "rebass";
import IconButton from "@material-ui/core/IconButton";
// import { EditIcon } from "../../components/icon_svg";
import { TrashPOIcon } from "../../components/icon_svg";
import moment from "moment";
import {
  StyledTableAction,
  StyledTableCell,
  StyledTableRow,
  StyledTableCellRight,
} from "../../po/components/styles";
import ProductField from "./ProductField";
import DialogEditProductSellOut from "./DialogEditProductSellOut";
/**
 * 
 barcode={barcode}
globalCode={globalCode}
setBarcode={(v) => setBarcode(v)}
setGlobalCode={(v) => setGlobalCode(v)}
 */

const RowItem = (props) => {
  // onEditItem, item,
  const { fields, classes, index } = props;
  const { value } = fields;
  const myRecord = value[index];
  return (
    <Fragment>
      <StyledTableCell>
        {myRecord && myRecord.product_id && (
          <ProductField record={myRecord} source={"code"} />
        )}
      </StyledTableCell>
      <StyledTableCell>
        {myRecord && myRecord.product_id && (
          <ProductField record={myRecord} source={"packaging"} />
        )}
      </StyledTableCell>
      <StyledTableCell>
        {myRecord && myRecord.product_id && (
          <ProductField record={myRecord} source={"global_code"} />
        )}
      </StyledTableCell>
      <StyledTableCell>
        <Text>
          {myRecord.expire_date && myRecord.expire_date !== ""
            ? moment(myRecord.expire_date).format("DD-MMM-YYYY")
            : ""}
        </Text>
      </StyledTableCell>
      <StyledTableCellRight>
        <Text textAlign={"right"}>{value[index].qty}</Text>
      </StyledTableCellRight>
      <StyledTableAction>
        <Flex flexDirection={"row"} alignItems={"center"}>
          <IconButton
            className={classes.leftIcon}
            onClick={() => fields.remove(index)}
          >
            <TrashPOIcon fontSize='small' />
          </IconButton>
          <DialogEditProductSellOut {...props} />

          <Box width={"10px"} />
        </Flex>
      </StyledTableAction>
    </Fragment>
  );
};
export const Item = (props) => {
  //   console.log(props);
  const { fields } = props;
  return (
    <Fragment>
      {fields.map((item, index) => (
        <StyledTableRow key={index}>
          <RowItem {...props} item={item} index={index} fields={fields} />
        </StyledTableRow>
      ))}
    </Fragment>
  );
};
