import React from "react";
import { Admin, Resource } from "react-admin";
import { JssProvider } from "react-jss";
import { themeMui } from "./themes";
import { AppLayout } from "./components/app";
import translations from "./i18n";
import { generateClassName } from "./utils";
import authClient from "./authClient";
import { Dashboard } from "./dashboard";
import { NoAccessPage } from "./components";
import {
  ProductList,
  ProductShow,
  ProductCreate,
  ProductEdit,
} from "./products";
import { TabPOList, OrdersShow, OrdersEdit } from "./orders"; //OrdersEdit,OrdersList
//OrdersCreate,
import {
  PromotionsList,
  CreateDealerPromotion,
  EditDealerPromotion,
} from "./promotions";
import {
  PriceBookList,
  PriceBookCreate,
  PriceBookEdit,
  PriceBookShow,
} from "./pricebook";
import customRoutes from "./customRoutes";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import // InventoryList,
// InventoryListDealer,
// CreatePOInventories,
"./inventory";
import {
  POListDealer,
  POCreateDealer,
  POEditDealer,
  POShowDealer,
  ListPoCustomer,
  CreatePOCustomer,
} from "./po";
import { Settinglist } from "./settings";
import { DealerTarget } from "./targets";
import { LoginPage } from "./auth";
import { ListInvoiceTransactions } from "./invoiced";
import { CreateSellout, ListSellout, EditSellout } from "./sellout";
import { UserList, UserEdit, UserCreate, UserShow } from "./screens/users";
// import { ListNotifications } from "./notifications";
import { ROLE_USER, isShowing } from "./utils";

// import { EditDealer } from "./dealers"
import { dataProvider } from "./restClient";
// import { dataProvider } from "./utils/dummy";

import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <JssProvider generateClassName={generateClassName}>
        <Admin
          layout={AppLayout}
          dataProvider={dataProvider}
          customRoutes={customRoutes}
          title='GC Dentals'
          theme={themeMui}
          authProvider={authClient}
          i18nProvider={translations}
          loginPage={LoginPage}
          dashboard={Dashboard}
        >
          {(permissions) => {
            return [
              permissions === "Admin" && (
                <Resource
                  name='codes'
                  list={
                    isShowing(permissions, "codes", "list")
                      ? Settinglist
                      : NoAccessPage
                  }
                />
              ),
              <Resource
                name='products'
                create={
                  isShowing(permissions, "products", "create")
                    ? ProductCreate
                    : NoAccessPage
                }
                list={
                  isShowing(permissions, "products", "list")
                    ? ProductList
                    : NoAccessPage
                }
                edit={
                  isShowing(permissions, "products", "edit")
                    ? ProductEdit
                    : NoAccessPage
                }
                show={
                  isShowing(permissions, "products", "show")
                    ? ProductShow
                    : NoAccessPage
                }
              />,
              <Resource
                name='orders'
                create={
                  isShowing(permissions, "orders", "create")
                    ? NoAccessPage
                    : NoAccessPage
                  //OrdersCreate
                }
                list={
                  isShowing(permissions, "orders", "list")
                    ? TabPOList
                    : NoAccessPage
                }
                edit={
                  isShowing(permissions, "orders", "edit")
                    ? OrdersEdit
                    : NoAccessPage
                }
                show={
                  isShowing(permissions, "orders", "show")
                    ? OrdersShow
                    : NoAccessPage
                }
              />,
              <Resource
                name='priceBooks'
                create={
                  isShowing(permissions, "priceBooks", "create")
                    ? PriceBookCreate
                    : NoAccessPage
                }
                list={
                  isShowing(permissions, "priceBooks", "list")
                    ? PriceBookList
                    : NoAccessPage
                }
                edit={
                  isShowing(permissions, "priceBooks", "edit")
                    ? PriceBookEdit
                    : NoAccessPage
                }
                show={
                  isShowing(permissions, "priceBooks", "show")
                    ? PriceBookShow
                    : NoAccessPage
                }
              />,
              <Resource
                name='inventories'
                list={
                  isShowing(permissions, "inventories", "list")
                    ? permissions === ROLE_USER.dealer
                      ? NoAccessPage
                      : NoAccessPage
                    : NoAccessPage
                }
                /**InventoryListDealer
                 * create={
                isShowing(permissions, "inventories", "create")
                  ? permissions === ROLE_USER.dealer
                    ? CreatePOInventories
                    : null
                  : null
              } */
              />,
              permissions !== "Admin" && (
                <Resource
                  name='codes'
                  list={
                    isShowing(permissions, "codes", "list")
                      ? Settinglist
                      : NoAccessPage
                  }
                />
              ),
              // <Resource name='notifications' list={ListNotifications} />,
              <Resource
                name='invoices'
                list={
                  isShowing(permissions, "invoices", "list")
                    ? ListInvoiceTransactions
                    : NoAccessPage
                }
              />,
              <Resource
                name='sellOuts'
                list={
                  isShowing(permissions, "sellOuts", "list")
                    ? ListSellout
                    : NoAccessPage
                }
                create={
                  isShowing(permissions, "sellOuts", "create")
                    ? CreateSellout
                    : NoAccessPage
                }
                edit={
                  isShowing(permissions, "sellOuts", "edit")
                    ? EditSellout
                    : NoAccessPage
                }
              />,
              <Resource name='priceBookDetails' />,
              <Resource name='dealers' />,
              <Resource
                name='users'
                list={
                  isShowing(permissions, "users", "list")
                    ? UserList
                    : NoAccessPage
                }
                edit={
                  isShowing(permissions, "users", "edit")
                    ? UserEdit
                    : NoAccessPage
                }
                create={
                  isShowing(permissions, "users", "create")
                    ? UserCreate
                    : NoAccessPage
                }
                show={
                  isShowing(permissions, "users", "show")
                    ? UserShow
                    : NoAccessPage
                }
              />,
              <Resource name='countryTargets' />,
              <Resource
                name='purchaseOrder'
                list={
                  isShowing(permissions, "purchaseOrder", "list")
                    ? permissions === ROLE_USER.dealer
                      ? POListDealer
                      : ListPoCustomer
                    : NoAccessPage
                }
                create={
                  isShowing(permissions, "purchaseOrder", "create")
                    ? permissions === ROLE_USER.dealer
                      ? POCreateDealer
                      : CreatePOCustomer
                    : NoAccessPage
                }
                edit={
                  isShowing(permissions, "purchaseOrder", "edit")
                    ? POEditDealer
                    : NoAccessPage
                }
                show={
                  isShowing(permissions, "purchaseOrder", "show")
                    ? POShowDealer
                    : NoAccessPage
                }
              />,
              <Resource name='warehouses' />,
              <Resource name='classifications' />,
              <Resource name='groups' />,
              <Resource name='categories' />,
              <Resource name='orderItemPromotions' />,
              <Resource name='countries' />,
              <Resource name='settings' />,
              <Resource name='reports' />,
              <Resource
                name='dealersTarget'
                list={
                  isShowing(permissions, "dealersTarget", "list")
                    ? DealerTarget
                    : NoAccessPage
                }
              />,
              <Resource
                name='promotions'
                create={
                  isShowing(permissions, "promotions", "create")
                    ? CreateDealerPromotion
                    : NoAccessPage
                }
                list={
                  isShowing(permissions, "promotions", "list")
                    ? PromotionsList
                    : NoAccessPage
                }
                edit={
                  isShowing(permissions, "promotions", "edit")
                    ? EditDealerPromotion
                    : NoAccessPage
                }
              />,
            ];
          }}
        </Admin>
      </JssProvider>
    </MuiPickersUtilsProvider>
  </QueryClientProvider>
);

export default App;
