import React, { useState } from "react";
import { useNotify, useTranslate, Notification } from "react-admin";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { Flex, Image, Text } from "rebass";
import Card from "@material-ui/core/Card";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Field } from "react-final-form";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { loginClientPwd } from "../restClient";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(2),
  },
  withoutLabel: {
    marginBottom: theme.spacing(3),
  },
  textField: {
    width: "320px",
  },
}));

const ForgotPassword = (props) => {
  const theme = useTheme();
  const history = useHistory();
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const onSubmit = (v) => {
    // console.log(v);
    if (v && v.email) {
      setLoading(true);
      loginClientPwd("resetPassword", JSON.stringify(v))
        .then((response) => response.json())
        .then((response) => {
          // console.log(response);
          if (response && response.status && response.status === "OK") {
            setShow(true);
          } else {
            notify("resources.accounts.message.try_again", "warning");
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          console.error(e);
        });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Flex
        width={[1]}
        bg={theme.palette.primary[500]}
        style={{ height: "100vh" }}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Card
          style={{
            width: 550,
            minHeight: 350,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 16,
            borderRadius: 16,
          }}
        >
          <Image
            src={require("../assets/images/logo.png")}
            width={200}
            mb={[3]}
          />
          <Text
            fontWeight={"bold"}
            fontSize={[3]}
            py={[2]}
            color={"rgba(0,0,0,0.6)"}
          >
            {translate("resources.accounts.forgot_password")}
          </Text>
          {!show ? (
            <Form
              onSubmit={onSubmit}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = "Required";
                }
                return errors;
              }}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Flex
                    width={[1]}
                    flexDirection={"column"}
                    alignItems={"center"}
                  >
                    <Field name='email'>
                      {({ input, meta }) => (
                        <FormControl
                          className={clsx(classes.margin, classes.textField)}
                          variant='outlined'
                        >
                          <InputLabel htmlFor='email'>
                            {translate("resources.accounts.fields.email")}
                          </InputLabel>
                          <OutlinedInput
                            id='email'
                            type={"email"}
                            {...input}
                            labelWidth={86}
                            aria-describedby='component-error-text-email'
                          />
                          <FormHelperText id='component-error-text-email'>
                            {meta.touched && meta.error && (
                              <span style={{ color: "#FF0000" }}>
                                {meta.error}
                              </span>
                            )}
                            {/* translate("resources.login.error_username")} */}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Field>
                    <Button
                      variant='contained'
                      color='primary'
                      size='large'
                      type='submit'
                      style={{
                        minWidth: 180,
                        textTransform: "capitalize",
                        borderRadius: 24,
                      }}
                      startIcon={
                        loading ? (
                          <CircularProgress size={16} color='secondary' />
                        ) : null
                      }
                    >
                      {translate("resources.accounts.reset_password")}
                    </Button>
                    <Flex
                      width={[1]}
                      flexDirection={"column"}
                      alignItems={"center"}
                      py={[3]}
                    >
                      <Button
                        color='primary'
                        size='large'
                        onClick={() => history.push("/login")}
                        style={{
                          width: 180,
                          textTransform: "capitalize",
                          borderRadius: 24,
                        }}
                      >
                        {translate("resources.buttons.login")}
                      </Button>
                    </Flex>
                  </Flex>
                </form>
              )}
            />
          ) : (
            <Flex width={[1]} flexDirection={"column"} alignItems={"center"}>
              <Text py={[3]} color={"rgba(0,0,0,0.6)"}>
                {translate("resources.accounts.message.succes_forgot_password")}
              </Text>
              <Button
                variant='contained'
                color='primary'
                size='large'
                onClick={() => history.push("/login")}
                style={{
                  width: 180,
                  textTransform: "capitalize",
                  borderRadius: 24,
                }}
              >
                {translate("resources.buttons.login")}
              </Button>
            </Flex>
          )}
        </Card>
        <Notification />
      </Flex>
    </ThemeProvider>
  );
};

ForgotPassword.propTypes = {
  authClient: PropTypes.func,
  previousRoute: PropTypes.string,
  theme: PropTypes.object.isRequired,
};

ForgotPassword.defaultProps = {
  theme: {},
};

export default ForgotPassword;
