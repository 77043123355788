import React, { useState } from "react";
import { Box, Flex } from "rebass";
//Text
import { get, filter, sumBy, map, has } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { useDataProvider, useNotify } from "react-admin";
import { styles } from "./styles";
import { STATUSPO, ROLE_USER } from "../../utils";
import { ListOrdersView } from "./promotions";
import moment from "moment";
import { PromoTypeB } from "../../restClient";

const getSubtotal = (record) => {
  const { items } = record;
  const newList = map(items, function (o) {
    const promotions = get(o, "promotions", null);
    const promoType = get(promotions, "promotion_type", "");
    const discountPrice = get(o, "discounted_price", 0) * 1;

    if (promoType === PromoTypeB.sp) {
      return { id: o.id, subtotal: o.qty * discountPrice };
    }
    const price = get(o, "price", 0) * 1;
    const newPrice = has(o, "discounted_price") === 0 ? price : discountPrice;
    return { id: o.id, subtotal: o.qty * newPrice };
  });
  return newList;
};

// const checkDiscountedPrice = (record) => {
//   const items = get(record, "items", []);
//   const newItems = items.map((i) => {
//     if (get(i, "discounted_price")) {
//       if (
//         get(i, "discounted_price") === "0" ||
//         get(i, "discounted_price") === 0
//       ) {
//         return {
//           ...i,
//           discounted_price: get(i, "price", "0"),
//         };
//       } else {
//         return i;
//       }
//     } else {
//       return i;
//     }
//   });
//   // const newParams = {
//   //   ...record,
//   //   items: newItems,
//   // };
//   const listTotal = getSubtotal(record);
//   const totalAmountRecord = sumBy(listTotal, "subtotal");
//   const newParams = {
//     ...record,
//     items: newItems,
//     total: totalAmountRecord,
//   };
//   return newParams;
// };

const checkDiscountedPrice = (record) => {
  const items = get(record, "items", []);
  const newItems = items.map((i) => {
    const discountPrice = get(i, "discounted_price", 0) * 1;
    const price = get(i, "price", 0) * 1;
    const newPrice = has(i, "discounted_price") ? discountPrice : price;
    return {
      ...i,
      discounted_price: newPrice,
    };
  });
  const listTotal = getSubtotal(record);
  const totalAmountRecord = sumBy(listTotal, "subtotal");
  const newParams = {
    ...record,
    items: newItems,
    total: totalAmountRecord,
  };
  return newParams;
};

const checkInactivePromotion = (items, current) => {
  const checkPromotionOnly = filter(items, function (p) {
    return get(p, "promotions.id");
  });
  // console.log("props items", checkPromotionOnly);
  const filterPromotions = filter(checkPromotionOnly, function (oo) {
    const { promotions } = oo;
    const isActive = get(promotions, "active", false);
    return (
      isActive &&
      promotions &&
      promotions.start_date &&
      promotions.start_date !== null &&
      promotions.end_date &&
      promotions.end_date !== null &&
      moment(promotions.start_date).unix() < moment(current).unix() &&
      moment(promotions.end_date).unix() > moment(current).unix()
    );
  });
  return checkPromotionOnly.length === filterPromotions.length;
};

const OrderItemsShow = (props) => {
  const current = moment();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { record, translate, basePath, history, permissions } = props;

  const onSubmitPO = () => {
    if (record && record.id) {
      // console.log(props)
      const items = get(record, "items", []);
      const isActive = checkInactivePromotion(items, current);
      // const filterPromotions = filter(items, function (oo) {
      //   const { promotions } = oo;
      //   return (
      //     promotions.active &&
      //     promotions.start_date &&
      //     promotions.start_date !== null &&
      //     promotions.end_date &&
      //     promotions.end_date !== null &&
      //     moment(promotions.start_date).unix() < moment(current).unix() &&
      //     moment(promotions.end_date).unix() > moment(current).unix()
      //   );
      // });
      if (isActive) {
        setLoading(true);
        const oldRecod = record;
        record["status"] = STATUSPO.sent;
        //change discounted_price 0 => price
        const newParams = checkDiscountedPrice(record);
        dataProvider
          .update("purchaseOrder", {
            id: record.id,
            data: newParams,
            previousData: oldRecod,
          })
          .then((response) => {
            if (response && response.data) {
              notify("resources.po.messages.order_submited");
              history.push(basePath);
            } else {
              notify("resources.po.messages.create_order", "warning");
            }
            setLoading(false);
          });
      } else {
        setLoading(false);
        notify("resources.po.messages.inactive_item_promotions");
      }
    }
  };

  return (
    <Box width={[1]} p={[3]} pl={[1]} pr={[1]}>
      <ListOrdersView formData={record} />
      <Box width={[1]}>
        {get(record, "status", "") === STATUSPO.draft && (
          <Flex
            width={[1]}
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            {permissions !== ROLE_USER.customer && (
              <Button
                component={Link}
                variant='contained'
                color='primary'
                to={`${basePath}/${record.id}`}
                style={{
                  marginRight: 8,
                  borderRadius: 24,
                  width: 180,
                  textTransform: "capitalize",
                }}
              >
                {translate("resources.buttons.edit")}
              </Button>
            )}
            <Button
              variant='contained'
              color='primary'
              onClick={onSubmitPO}
              disabled={loading}
              style={{
                marginLeft: 8,
                borderRadius: 24,
                width: 180,
                textTransform: "capitalize",
              }}
            >
              {translate("resources.buttons.submit_po")}
            </Button>
          </Flex>
        )}
      </Box>
    </Box>
  );
};
export default withStyles(styles)(OrderItemsShow);
