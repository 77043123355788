import React from "react";
// import { DataGrid } from "@material-ui/data-grid";
// import { columns, sortModel, sortModelDealer, columnsDealer } from "./";
import { AgGridReact } from "ag-grid-react";
// import numeral from "numeral";
import { orderBy } from "lodash";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { NumberParser2Decimals } from "../utils";
// import { get } from "lodash";
// import moment from "moment";

// export const NumberParser = (params) => {
//   return `${numeral(params.value * 1).format("0")}`;
// };
// export const NumberParserPercent = (params) => {
//   return `${numeral(params.value * 1).format("0.00")}%`;
// };
// export const DateParser = (params) => {
//   const startDate = get(params, "value", "");
//   return startDate !== "" ? moment(startDate).format("DD/MMM/YYYY") : "";
// };
/* in_transit: 0
open_order: 0*/
const lowerCaseComparator = (t1, t2) => {
  return t1.toLowerCase().localeCompare(t2.toLowerCase());
};

const defaultFreeColumnDefsDealer = [
  {
    field: "category",
    headerName: "Category",
    minWidth: 200,
    editable: false,
    resizable: true,
  },
  {
    field: "target_setting",
    headerName: "Target Setting",
    minWidth: 200,
    editable: false,
    resizable: true,
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },

  {
    field: "packaging",
    headerName: "Packaging",
    minWidth: 350,
    editable: false,
    resizable: true,
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },
  {
    field: "code",
    headerName: "Product Code",
    minWidth: 150,
    editable: false,
    resizable: true,
  },
  {
    field: "in_transit",
    headerName: "Intransit",
    minWidth: 100,
    editable: false,
    resizable: true,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    field: "open_order",
    headerName: "Open Order",
    minWidth: 130,
    editable: false,
    resizable: true,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    field: "available",
    headerName: "Available",
    minWidth: 100,
    editable: false,
    resizable: true,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    field: "back_order",
    headerName: "Back Order",
    minWidth: 130,
    editable: false,
    resizable: true,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
];
const defaultFreeColumnDefs = [
  {
    field: "country",
    headerName: "Country",
    minWidth: 100,
    editable: false,
    resizable: true,
  },
  {
    field: "dealer",
    headerName: "Dealer",
    minWidth: 200,
    editable: false,
    resizable: true,
  },
  {
    field: "category",
    headerName: "Category",
    minWidth: 200,
    editable: false,
    resizable: true,
  },
  {
    field: "target_setting",
    headerName: "Target Setting",
    minWidth: 200,
    editable: false,
    resizable: true,
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },

  {
    field: "packaging",
    headerName: "Packaging",
    minWidth: 350,
    editable: false,
    resizable: true,
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },
  {
    field: "code",
    headerName: "Product Code",
    minWidth: 150,
    editable: false,
    resizable: true,
  },
  {
    field: "in_transit",
    headerName: "Intransit",
    minWidth: 100,
    editable: false,
    resizable: true,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    field: "open_order",
    headerName: "Open Order",
    minWidth: 130,
    editable: false,
    resizable: true,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    field: "available",
    headerName: "Available",
    minWidth: 100,
    editable: false,
    resizable: true,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    field: "back_order",
    headerName: "Back Order",
    minWidth: 130,
    editable: false,
    resizable: true,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
];

export default (props) => {
  // const [pageSize, setPageSize] = useState(20);
  const { list, dealerId } = props;
  // console.log(list.length);

  const state = {
    columnDefs:
      dealerId !== null ? defaultFreeColumnDefsDealer : defaultFreeColumnDefs,
    defaultColDef: {
      flex: 1,
      editable: false,
      sortable: true,
      filter: true,
      // cellStyle: {
      //   "line-height": "40px",
      // },
    },
    getRowNodeId: function (data) {
      return data.category;
    },
  };

  // const onPageSizeChange = (e) => {
  //   // console.log(e);
  //   setPageSize(e.pageSize);
  // };
  // const heightContainer =
  //   list.length > pageSize
  //     ? pageSize * 55 + 110
  //     : list.length > 0
  //     ? pageSize * 55 + 110
  //     : 300;
  // const sortModelDefault = dealerId !== null ? sortModelDealer : sortModel;
  // const columnDefaultDealer = dealerId !== null ? columnsDealer : columns;
  // const muList = sortBy(list, [
  //   function (o) {
  //     return o.packaging;
  //   },
  // ]);
  const muList = orderBy(
    list,
    [(product) => product.packaging.toLowerCase()],
    ["asc"]
  );
  return (
    // <div style={{ height: heightContainer, width: "100%" }}>
    //   <DataGrid
    //     sortModel={sortModelDefault}
    //     rows={list}
    //     columns={columnDefaultDealer}
    //     pageSize={20}
    //     rowsPerPageOptions={[10, 20, 25, 50]}
    //     onPageSizeChange={onPageSizeChange}
    //   />
    // </div>
    <div className='grid-wrapper'>
      <div
        id='myGridPromotion'
        className='ag-theme-balham'
        style={{ height: "50vh", width: "100%" }}
      >
        <AgGridReact
          rowHeight={40}
          headerHeight={40}
          rowData={muList}
          columnDefs={state.columnDefs}
          defaultColDef={state.defaultColDef}
          animateRows={true}
        />
      </div>
    </div>
  );
};
