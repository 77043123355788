import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Flex, Box } from "rebass";
import { withStyles } from "@material-ui/core/styles";
import { styles, StyledTableCell, StyledTableRow } from "./styles";
import { get } from "lodash";

const RowItem = (props) => {
  const { item } = props;
  const contents = get(item, "contents", []);
  return (
    <StyledTableRow>
      <StyledTableCell>{get(item, "line_number", "")}</StyledTableCell>
      {contents &&
        contents.length > 0 &&
        contents.map((itemrow, indexRow) => {
          return (
            <StyledTableCell key={indexRow}>
              {itemrow !== "" ? itemrow : ""}
            </StyledTableCell>
          );
        })}
    </StyledTableRow>
  );
};
/**table: {
      line_number: "Line Number",
      dealer_code: "Dealer Code",
      dealer_name: "Dealer Name",
      product_code: "Product Code",
      product_packaging: "Product Packaging",
      qty: "Qty",
      sales: "Sales",
      discount: "Discount",
      sales_after_discount: "Sales After Discount",
      date: "Date",
      invoice_no: "Invoice No",
      country: "Country",
      item_class_id: "Item Class Id",
    }, */
const ListInvoice = (props) => {
  const { list, translate, classes } = props;
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <Box width={[1]} py={[3]}>
        <TableContainer component={"div"}>
          <Table
            stickyHeader
            className={classes.table}
            aria-label='customized table'
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  {translate("resources.invoiced.table.line_number")}
                </StyledTableCell>
                <StyledTableCell>
                  {translate("resources.invoiced.table.dealer_code")}
                </StyledTableCell>
                {/* <StyledTableCell>
                  {translate("resources.invoiced.table.dealer_name")}
                </StyledTableCell> */}
                <StyledTableCell>
                  {translate("resources.invoiced.table.product_code")}
                </StyledTableCell>
                {/* <StyledTableCell>
                  {translate("resources.invoiced.table.product_packaging")}
                </StyledTableCell> */}
                <StyledTableCell>
                  {translate("resources.invoiced.table.qty")}
                </StyledTableCell>
                <StyledTableCell>
                  {translate("resources.invoiced.table.sales")}
                </StyledTableCell>
                <StyledTableCell>
                  {translate("resources.invoiced.table.discount")}
                </StyledTableCell>
                {/* <StyledTableCell>
                  {translate("resources.invoiced.table.sales_after_discount")}
                </StyledTableCell> */}
                <StyledTableCell>
                  {translate("resources.invoiced.table.date")}
                </StyledTableCell>
                <StyledTableCell>
                  {translate("resources.invoiced.table.invoice_no")}
                </StyledTableCell>
                {/* <StyledTableCell>
                  {translate("resources.invoiced.table.country")}
                </StyledTableCell>
                <StyledTableCell>
                  {translate("resources.invoiced.table.item_class_id")}
                </StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {list &&
                list.length > 0 &&
                list.map((item, index) => {
                  return <RowItem key={index} item={item} />;
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Flex>
  );
};

export default withStyles(styles)(ListInvoice);
