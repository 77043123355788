import React from "react";
// import { DataGrid } from "@material-ui/data-grid";
import { get } from "lodash";
import moment from "moment";
// import { columns, columnsDealer, sortModel, sortModelDealer } from "./";
import { AgGridReact } from "ag-grid-react";
// import numeral from "numeral";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { NumberParser2Decimals } from "../utils";
// /NumberParserPercent

export const DateParser = (params) => {
  const startDate = get(params, "value", "");
  return startDate !== "" ? moment(startDate).format("DD/MMM/YYYY") : "";
};
/** */
const defaultFreeColumnDefsDealer = [
  {
    editable: false,
    resizable: true,
    field: "promotion_type",
    headerName: "Promo Type",
    width: 120,
    minWidth: 120,
  },
  {
    editable: false,
    resizable: true,
    field: "category",
    headerName: "Category",
    width: 200,
    minWidth: 100,
  },
  {
    editable: false,
    resizable: true,
    field: "product_group",
    headerName: "Product Group",
    width: 200,
    minWidth: 130,
  },
  {
    editable: false,
    resizable: true,
    field: "product_sub_group",
    headerName: "Product Sub Group",
    width: 200,
    minWidth: 155,
  },
  {
    editable: false,
    resizable: true,
    field: "base_product_1",
    headerName: "Base Product 1",
    width: 200,
    minWidth: 130,
  },
  {
    editable: false,
    resizable: true,
    field: "base_product_2",
    headerName: "Base Product 2",
    width: 200,
    minWidth: 130,
  },
  {
    editable: false,
    resizable: true,
    field: "start_date",
    headerName: "Start",
    width: 150,
    minWidth: 70,
    valueFormatter: DateParser,
    // valueGetter: (params) => {
    //   const startDate = get(params, "value", "");
    //   return startDate !== "" ? moment(startDate).format("DD/MMM/YYYY") : "";
    // },
  },
  {
    editable: false,
    resizable: true,
    field: "end_date",
    headerName: "End",
    width: 150,
    minWidth: 65,
    valueFormatter: DateParser,
    // valueGetter: (params) => {
    //   const endDate = get(params, "value", "");
    //   return endDate !== "" ? moment(endDate).format("DD/MMM/YYYY") : "";
    // },
  },
  {
    editable: false,
    resizable: true,
    field: "free_item_1",
    headerName: "Free Item 1",
    width: 300,
    minWidth: 110,
  },
  {
    editable: false,
    resizable: true,
    field: "free_item_2",
    headerName: "Free Item 2",
    width: 300,
    minWidth: 110,
  },
  {
    editable: false,
    resizable: true,
    field: "moq_1",
    headerName: "MOQ1",
    width: 100,
    minWidth: 80,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "base_1",
    headerName: "BASE1",
    width: 100,
    minWidth: 80,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "foc_1",
    headerName: "FOC1",
    width: 100,
    minWidth: 80,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "additional_qty_1",
    headerName: "AQTY1",
    width: 100,
    minWidth: 100,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "price_1",
    headerName: "Price1",
    width: 100,
    minWidth: 100,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "discount_1",
    headerName: "Discount1",
    width: 120,
    minWidth: 120,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "moq_2",
    headerName: "MOQ2",
    width: 100,
    minWidth: 80,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "base_2",
    headerName: "BASE2",
    width: 100,
    minWidth: 80,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "foc_2",
    headerName: "FOC2",
    width: 100,
    minWidth: 80,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "additional_qty_2",
    headerName: "AQTY2",
    width: 100,
    minWidth: 100,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "price_2",
    headerName: "Price2",
    width: 100,
    minWidth: 100,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "discount_2",
    headerName: "Discount2",
    width: 120,
    minWidth: 120,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "moq_3",
    headerName: "MOQ3",
    width: 100,
    minWidth: 80,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "base_3",
    headerName: "BASE3",
    width: 100,
    minWidth: 80,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "foc_3",
    headerName: "FOC3",
    width: 100,
    minWidth: 80,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "additional_qty_3",
    headerName: "AQTY3",
    width: 100,
    minWidth: 100,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "price_3",
    headerName: "Price3",
    width: 100,
    minWidth: 100,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "discount_3",
    headerName: "Discount3",
    width: 120,
    minWidth: 120,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "po_order_qty_moq_1",
    headerName: "PO Order Qty MOQ1",
    width: 180,
    minWidth: 160,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "po_order_qty_moq_2",
    headerName: "PO Order Qty MOQ2",
    width: 180,
    minWidth: 160,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "po_order_qty_moq_3",
    headerName: "PO Order Qty MOQ3",
    width: 180,
    minWidth: 160,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "po_order_qty_total",
    headerName: "PO Order Qty Total",
    width: 180,
    minWidth: 160,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
];
const defaultFreeColumnDefs = [
  {
    editable: false,
    resizable: true,
    field: "country",
    headerName: "Country",
    width: 100,
    minWidth: 100,
  },
  {
    editable: false,
    resizable: true,
    field: "dealer",
    headerName: "Dealer",
    width: 350,
    minWidth: 100,
  },
  {
    editable: false,
    resizable: true,
    field: "promotion_type",
    headerName: "Promo Type",
    width: 120,
    minWidth: 120,
  },
  {
    editable: false,
    resizable: true,
    field: "category",
    headerName: "Category",
    width: 200,
    minWidth: 100,
  },
  {
    editable: false,
    resizable: true,
    field: "product_group",
    headerName: "Product Group",
    width: 200,
    minWidth: 130,
  },
  {
    editable: false,
    resizable: true,
    field: "product_sub_group",
    headerName: "Product Sub Group",
    width: 200,
    minWidth: 155,
  },
  {
    editable: false,
    resizable: true,
    field: "base_product_1",
    headerName: "Base Product 1",
    width: 200,
    minWidth: 130,
  },
  {
    editable: false,
    resizable: true,
    field: "base_product_2",
    headerName: "Base Product 2",
    width: 200,
    minWidth: 130,
  },
  {
    editable: false,
    resizable: true,
    field: "start_date",
    headerName: "Start",
    width: 150,
    minWidth: 80,
    valueGetter: (params) => {
      const startDate = get(params, "value", "");
      return startDate !== "" ? moment(startDate).format("DD/MMM/YYYY") : "";
    },
  },
  {
    editable: false,
    resizable: true,
    field: "end_date",
    headerName: "End",
    width: 150,
    minWidth: 80,
    valueGetter: (params) => {
      const endDate = get(params, "value", "");
      return endDate !== "" ? moment(endDate).format("DD/MMM/YYYY") : "";
    },
  },
  {
    editable: false,
    resizable: true,
    field: "free_item_1",
    headerName: "Free Item 1",
    width: 300,
    minWidth: 120,
  },
  {
    editable: false,
    resizable: true,
    field: "free_item_2",
    headerName: "Free Item 2",
    width: 300,
    minWidth: 120,
  },
  {
    editable: false,
    resizable: true,
    field: "moq_1",
    headerName: "MOQ1",
    width: 100,
    minWidth: 80,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "base_1",
    headerName: "BASE1",
    width: 100,
    minWidth: 80,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "foc_1",
    headerName: "FOC1",
    width: 100,
    minWidth: 80,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "additional_qty_1",
    headerName: "AQTY1",
    width: 100,
    minWidth: 100,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "price_1",
    headerName: "Price1",
    width: 100,
    minWidth: 100,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "discount_1",
    headerName: "Discount1",
    width: 120,
    minWidth: 110,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "moq_2",
    headerName: "MOQ2",
    width: 100,
    minWidth: 80,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "base_2",
    headerName: "BASE2",
    width: 100,
    minWidth: 80,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "foc_2",
    headerName: "FOC2",
    width: 100,
    minWidth: 80,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "additional_qty_2",
    headerName: "AQTY2",
    width: 100,
    minWidth: 100,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "price_2",
    headerName: "Price2",
    width: 100,
    minWidth: 100,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "discount_2",
    headerName: "Discount2",
    width: 120,
    minWidth: 110,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "moq_3",
    headerName: "MOQ3",
    width: 100,
    minWidth: 80,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "base_3",
    headerName: "BASE3",
    width: 100,
    minWidth: 80,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "foc_3",
    headerName: "FOC3",
    width: 100,
    minWidth: 80,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "additional_qty_3",
    headerName: "AQTY3",
    width: 100,
    minWidth: 100,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "price_3",
    headerName: "Price3",
    width: 100,
    minWidth: 100,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "discount_3",
    headerName: "Discount3",
    width: 120,
    minWidth: 110,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "po_order_qty_moq_1",
    headerName: "PO Order Qty MOQ1",
    width: 180,
    minWidth: 160,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "po_order_qty_moq_2",
    headerName: "PO Order Qty MOQ2",
    width: 180,
    minWidth: 160,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "po_order_qty_moq_3",
    headerName: "PO Order Qty MOQ3",
    width: 180,
    minWidth: 160,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    editable: false,
    resizable: true,
    field: "po_order_qty_total",
    headerName: "PO Order Qty Total",
    width: 180,
    minWidth: 160,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  // {
  //   editable: false,
  //   resizable: true,field: "country",
  //   headerName: "Country",
  //   width: 100,
  //   editable: false,
  //   resizable: true,
  // },
  // {
  //   field: "dealer",
  //   headerName: "Dealer",
  //   width: 200,
  //   editable: false,
  //   resizable: true,
  // },
  // {
  //   field: "category",
  //   headerName: "Category",
  //   width: 200,
  //   editable: false,
  //   resizable: true,
  // },
  // {
  //   field: "target_setting",
  //   headerName: "Target Setting",
  //   width: 200,
  //   editable: false,
  //   resizable: true,
  // },
  // {
  //   field: "speed_growth",
  //   headerName: "Speed/Growth",
  //   width: 100,
  //   editable: false,
  //   resizable: true,
  // },
  // {
  //   field: "last_year",
  //   headerName: "Last Year",
  //   width: 100,
  //   editable: false,
  //   resizable: true,
  // },
  // {
  //   field: "target",
  //   headerName: "Target",
  //   width: 100,
  //   editable: false,
  //   resizable: true,
  // },
  // {
  //   field: "invoiced",
  //   headerName: "Invoiced",
  //   width: 100,
  //   editable: false,
  //   resizable: true,
  // },
  // {
  //   field: "gap",
  //   headerName: "GAP",
  //   width: 100,
  //   editable: false,
  //   resizable: true,
  // },
  // {
  //   field: "ratio",
  //   headerName: "Ratio %",
  //   width: 100,
  //   editable: false,
  //   resizable: true,
  //   valueFormatter: NumberParserPercent,
  // },
];

export default (props) => {
  // const [pageSize, setPageSize] = useState(20);
  const { list, dealerId } = props;
  // console.log(list.length);
  const state = {
    columnDefs:
      dealerId !== null ? defaultFreeColumnDefsDealer : defaultFreeColumnDefs,
    defaultColDef: {
      flex: 1,
      editable: false,
      sortable: true,
      filter: true,
      // cellStyle: {
      //   "line-height": "40px",
      // },
    },
    getRowNodeId: function (data) {
      return data.category;
    },
  };

  // const onPageSizeChange = (e) => {
  //   // console.log(e);
  //   setPageSize(e.pageSize);
  // };
  // const heightContainer =
  //   list.length > pageSize
  //     ? pageSize * 55 + 110
  //     : list.length > 0
  //     ? pageSize * 55 + 110
  //     : 300;
  // const sortModelDefault = dealerId !== null ? sortModelDealer : sortModel;
  // const columnDefaultDealer = dealerId !== null ? columnsDealer : columns;
  return (
    // <div style={{ height: heightContainer, width: "100%" }}>
    //   <DataGrid
    //     sortModel={sortModelDefault}
    //     rows={list}
    //     columns={columnDefaultDealer}
    //     pageSize={20}
    //     rowsPerPageOptions={[10, 20, 25, 50]}
    //     onPageSizeChange={onPageSizeChange}
    //   />
    // </div>
    <div className='grid-wrapper'>
      <div
        id='myGridPromotion'
        className='ag-theme-balham'
        style={{ height: "50vh", width: "100%" }}
      >
        <AgGridReact
          rowHeight={40}
          headerHeight={40}
          rowData={list}
          columnDefs={state.columnDefs}
          defaultColDef={state.defaultColDef}
          animateRows={true}
        />
      </div>
    </div>
  );
};
