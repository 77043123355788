import React from "react";
import { Flex } from "rebass";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { Button } from "@material-ui/core";
import { useTranslate } from "react-admin";
import { reportTypeValue } from "./utils";
import {
  reportPOSummary,
  reportPODetail,
  reportSOS,
  reportSOD,
  reportInvoiceSummary,
  reportInvoiceDetail,
} from "./downloadCsvSchema";

const ReportExportCsvFile = (props) => {
  const { list, reportType } = props;
  const translate = useTranslate();
  const onExportCsv = () => {
    if (list && list.length > 0) {
      if (reportType === reportTypeValue.pos) {
        reportPOSummary(list, reportType);
      } else if (reportType === reportTypeValue.pod) {
        reportPODetail(list, reportType);
      } else if (reportType === reportTypeValue.sos) {
        reportSOS(list, reportType);
      } else if (reportType === reportTypeValue.sod) {
        reportSOD(list, reportType);
      } else if (reportType === reportTypeValue.is) {
        reportInvoiceSummary(list, reportType);
      } else if (reportType === reportTypeValue.id) {
        reportInvoiceDetail(list, reportType);
      }
    }
  };

  return (
    <Flex
      width={[1]}
      flexDirection={"row"}
      justifyContent={"flex-end"}
      pt={[2]}
      pb={[2]}
    >
      <Button
        variant='contained'
        color='primary'
        disabled={list.length === 0}
        startIcon={<CloudDownloadIcon />}
        style={{ textTransform: "capitalize" }}
        onClick={onExportCsv}
      >
        {translate("resources.buttons.export_csv")}
      </Button>
    </Flex>
  );
};

export default ReportExportCsvFile;
