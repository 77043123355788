import React from "react";
import {
  TextField,
  useTranslate,
  Filter,
  Pagination,
  SearchInput,
} from "react-admin";
import moment from "moment";
import { Flex, Box } from "rebass";
import { get } from "lodash";
import {
  BetterList,
  ButtonEditView,
  CreateButton,
  Title,
  ActiveField,
} from "../../components";
import { ROLE_USER } from "../../utils";

const UserFilter = (props) => {
  const translate = useTranslate();
  const { permissions, basePath } = props;

  return (
    <Box width={[1]} pb={[3]}>
      <Flex
        pt={[2]}
        px={[3]}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Box width={[1]} pt={[2]}>
          <Title fontWeight={"bold"} fontSize={[3]}>
            {translate(`resources.users.name`)}
          </Title>
        </Box>
        <Flex flexDirection={"row"} justifyContent={"flex-end"}>
          {permissions === ROLE_USER.admin && (
            <CreateButton
              basePath={basePath}
              sizeIcon={"xsmall"}
              style={{ textTransform: "capitalize", marginRight: 16 }}
            />
          )}

          {/* <RefreshButton style={{ textTransform: "capitalize" }} /> */}
        </Flex>
      </Flex>

      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={"flex-end"}
        px={[3]}
      >
        <Filter {...props}>
          <Box width={[1]} />
          <SearchInput
            source='search'
            alwaysOn
            style={{
              width: 256,
            }}
          />
        </Filter>
      </Flex>
    </Box>
  );
};
const UserAction = ({ basePath, permissions }) => {
  // console.log(permissions)
  return <Flex flexDirection={"row"}></Flex>;
};
const CreateField = ({ record, source }) => {
  const create_at = get(record, source, "");
  return (
    <span>
      {create_at !== "" ? moment(create_at).format("DD-MMM-YYYY") : ""}
    </span>
  );
};
const FullNameField = ({ record }) => {
  const first_name = get(record, "first_name", "");
  const last_name = get(record, "last_name", "");
  return (
    <span>
      {first_name} {last_name}
    </span>
  );
};
const UserList = (props) => {
  const { permissions, hasList, hasEdit, hasCreate, hasShow, ...rest } = props;

  return (
    <BetterList
      {...props}
      actions={<UserAction permissions={permissions} />}
      rowclick={"show"}
      perPage={15}
      filters={<UserFilter permissions={permissions} {...rest} />}
      pagination={<Pagination rowsPerPageOptions={[15, 20, 25, 30, 50]} />}
    >
      <FullNameField
        source='first_name'
        label={"resources.users.fields.name"}
      />
      <TextField source='email' label={"resources.users.fields.email"} />
      <TextField source='role' label={"resources.users.fields.role"} />
      <ActiveField
        source='active'
        label={"resources.users.fields.active"}
        style={{ width: 100 }}
      />
      <CreateField
        source={"created_at"}
        label={"resources.users.fields.created_at"}
      />
      {permissions === ROLE_USER.admin && <ButtonEditView {...props} />}
    </BetterList>
  );
};
export default UserList;
