import { get, orderBy, find, isString } from "lodash";
import create from "zustand";
export const setItemsList = (list) => {
  if (list && list !== null && list.length > 0) {
    // let objNormal = {
    //   tPromoOrderQty: 600,
    //   tPromoOrderAmt: 27600,
    //   freeProduct: null,
    //   listPromo: [],
    //   item: null,
    // };
    list.map((product) => {
      if (product.promotions === null) {
        // console.log("normal", product);
      } else {
        // console.log("promotions");
      }
      return "";
    });
    // console.log(objNormal);
  }
  return [];
};

export const PromoType_B = [
  "Buy X Get Y",
  "Buy AB Get CD",
  "Discount",
  "Special Price",
];

export const keyPromo = [
  { id: "ProductGroup", value: "group_id" },
  { id: "SubProductGroup", value: "subgroup_id" },
  { id: "ProductCode", value: "code" },
  { id: "ProductShortCode", value: "short_code" },
];
//{ id: "ProductShortCode", value: "_short" },
export const getPromoType = (pt, item) => {
  if (pt === PromoType_B[0]) {
    const promoType = get(item, "criteria.buy_x_get_y.x.type", "");
    const promoShortCode = get(item, "criteria.buy_x_get_y.x.code");
    const promoID = get(item, "criteria.buy_x_get_y.x.id", 0);
    const objUrl = find(keyPromo, function (o) {
      // console.log(promoType, o.id);
      return o.id === promoType;
    });
    // console.log(objUrl, item);
    const objUrlV = objUrl ? objUrl.value : "";
    if (promoShortCode && objUrlV === "short_code") {
      return `?${objUrlV}=${promoShortCode}`;
    }
    return objUrlV !== ""
      ? (objUrlV === "short_code" || objUrlV === "code") && !isString(promoID)
        ? `/${promoID}`
        : `?${objUrlV}=${promoID}`
      : "";
  } else if (pt === PromoType_B[1]) {
    const promoTypeA = get(item, "criteria.buy_ab_get_cd.a.type", "");
    const promoTypeB = get(item, "criteria.buy_ab_get_cd.b.type", "");
    const promoShortCodeA = get(item, "criteria.buy_ab_get_cd.a.code");
    const promoShortCodeB = get(item, "criteria.buy_ab_get_cd.b.code");
    const promoIDA =
      get(item, "criteria.buy_ab_get_cd.a.id", 0) !== ""
        ? get(item, "criteria.buy_ab_get_cd.a.id", 0)
        : 0;
    const promoIDB =
      get(item, "criteria.buy_ab_get_cd.b.id", 0) !== ""
        ? get(item, "criteria.buy_ab_get_cd.b.id", 0)
        : 0;

    const objUrl = find(keyPromo, function (o) {
      return o.id === promoTypeA;
    });
    const objUrlB = find(keyPromo, function (o) {
      return o.id === promoTypeB;
    });

    const objUrlV = objUrl ? objUrl.value : "";
    const objUrlVB = objUrlB ? objUrlB.value : "";
    if (objUrlV === objUrlVB) {
      let newList = [];
      if (objUrlV !== "") {
        if (objUrlV === "short_code" && promoShortCodeA) {
          newList.push(`?${objUrlV}=${promoShortCodeA}`);
        } else {
          const myUrlParams =
            (objUrlV === "short_code" || objUrlV === "code") &&
            !isString(promoIDA)
              ? `/${promoIDA}`
              : `?${objUrlV}=${promoIDA}`;
          //isString(promoIDA)?

          newList.push(`${myUrlParams}`);
        }
      }
      if (objUrlVB !== "") {
        if (objUrlVB === "short_code" && promoShortCodeB) {
          newList.push(`?${objUrlV}=${promoShortCodeB}`);
        } else {
          const myUrlParams =
            (objUrlVB === "short_code" || objUrlVB === "code") &&
            !isString(promoIDB)
              ? `/${promoIDB}`
              : `?${objUrlVB}=${promoIDB}`;
          newList.push(`${myUrlParams}`);
        }
      }
      return newList;
    }

    let newUrlString = "";
    const newListParams = [];
    if (objUrlV !== "") {
      if (promoShortCodeA && objUrlV === "short_code") {
        newUrlString = `?${objUrlV}=${promoShortCodeA}`;
      } else {
        if (
          (objUrlV === "short_code" || objUrlV === "code") &&
          !isString(promoIDA)
        ) {
          newListParams.push(`/${promoIDA}`);
        } else {
          newUrlString = `?${objUrlV}=${promoIDA}`;
        }
      }
    }
    if (objUrlVB !== "") {
      const oldUrl = newUrlString;
      if (promoShortCodeB && objUrlVB === "short_code") {
        newUrlString =
          oldUrl !== ""
            ? `${oldUrl}&${objUrlVB}=${promoShortCodeB}`
            : `?${objUrlVB}=${promoShortCodeB}`;
      } else {
        if (
          (objUrlVB === "short_code" || objUrlVB === "code") &&
          !isString(promoIDB)
        ) {
          newListParams.push(`/${promoIDB}`);
        } else {
          newUrlString =
            oldUrl !== ""
              ? `${oldUrl}&${objUrlVB}=${promoIDB}`
              : `?${objUrlVB}=${promoIDB}`;
        }
      }
    }

    if (newListParams.length === 0) {
      return newUrlString;
    } else {
      return [...newListParams, newUrlString];
    }
    // const objUrlV = objUrl ? objUrl.value : "";
    // const objUrlVB = objUrlB ? objUrlB.value : "";
    // if (objUrlV === objUrlVB) {
    //   if (promoIDA !== 0 && promoIDB !== 0) {
    //     return [`${objUrlV}=${promoIDA}`, `${objUrlVB}=${promoIDB}`];
    //   } else if (promoIDA !== 0 && promoIDB === 0) {
    //     return [`${objUrlV}=${promoIDA}`];
    //   } else if (promoIDA === 0 && promoIDB !== 0) {
    //     return [`${objUrlVB}=${promoIDB}`];
    //   }
    // }
    // if (promoIDA !== 0 && promoIDB === 0) {
    //   return `${objUrlV}=${promoIDA}`;
    // } else if (promoIDA === 0 && promoIDB !== 0) {
    //   return `${objUrlVB}=${promoIDB}`;
    // }
    // return `${objUrlV}=${promoIDA}&${objUrlVB}=${promoIDB}`;
  } else if (pt === PromoType_B[2]) {
    const promoType = get(item, "criteria.discount.buy.type", "");
    const promoShortCode = get(item, "criteria.discount.buy.code");
    const promoID = get(item, "criteria.discount.buy.id", 0);
    const objUrl = find(keyPromo, function (o) {
      return o.id === promoType;
    });
    // return `${objUrl.value}=${promoID}`;
    const objUrlV = objUrl ? objUrl.value : "";
    if (promoShortCode && objUrlV === "short_code") {
      return `?${objUrlV}=${promoShortCode}`;
    }
    return objUrlV !== ""
      ? (objUrlV === "short_code" || objUrlV === "code") && !isString(promoID)
        ? `/${promoID}`
        : `?${objUrlV}=${promoID}`
      : "";
  } else if (pt === PromoType_B[3]) {
    const promoType = get(item, "criteria.price.buy.type", "");
    const promoShortCode = get(item, "criteria.price.buy.code");
    const promoID = get(item, "criteria.price.buy.id", 0);
    const objUrl = find(keyPromo, function (o) {
      return o.id === promoType;
    });
    // return `${objUrl.value}=${promoID}`;
    const objUrlV = objUrl ? objUrl.value : "";
    if (promoShortCode && objUrlV === "short_code") {
      return `?${objUrlV}=${promoShortCode}`;
    }
    return objUrlV !== ""
      ? (objUrlV === "short_code" || objUrlV === "code") && !isString(promoID)
        ? `/${promoID}`
        : `?${objUrlV}=${promoID}`
      : "";
  }
  return "";
};

export const getPromoTypeABCD = (pt, item) => {
  if (pt === PromoType_B[1]) {
    const promoTypeA = get(item, "criteria.buy_ab_get_cd.a.type", "");
    const promoTypeB = get(item, "criteria.buy_ab_get_cd.b.type", "");
    const promoShortCodeA = get(item, "criteria.buy_ab_get_cd.a.code");
    const promoShortCodeB = get(item, "criteria.buy_ab_get_cd.b.code");
    const promoIDA =
      get(item, "criteria.buy_ab_get_cd.a.id", 0) !== ""
        ? get(item, "criteria.buy_ab_get_cd.a.id", 0)
        : 0;
    const promoIDB =
      get(item, "criteria.buy_ab_get_cd.b.id", 0) !== ""
        ? get(item, "criteria.buy_ab_get_cd.b.id", 0)
        : 0;

    const objUrl = find(keyPromo, function (o) {
      return o.id === promoTypeA;
    });
    const objUrlB = find(keyPromo, function (o) {
      return o.id === promoTypeB;
    });

    const objUrlV = objUrl ? objUrl.value : "";
    const objUrlVB = objUrlB ? objUrlB.value : "";
    if (objUrlV === objUrlVB) {
      let newList = [];
      if (objUrlV !== "") {
        if (objUrlV === "short_code" && promoShortCodeA) {
          newList.push(`?${objUrlV}=${promoShortCodeA}`);
        } else {
          const myUrlParams =
            (objUrlV === "short_code" || objUrlV === "code") &&
            !isString(promoIDA)
              ? `/${promoIDA}`
              : `?${objUrlV}=${promoIDA}`;
          //isString(promoIDA)?

          newList.push(`${myUrlParams}`);
        }
      }
      if (objUrlVB !== "") {
        if (objUrlVB === "short_code" && promoShortCodeB) {
          newList.push(`?${objUrlV}=${promoShortCodeB}`);
        } else {
          const myUrlParams =
            (objUrlVB === "short_code" || objUrlVB === "code") &&
            !isString(promoIDB)
              ? `/${promoIDB}`
              : `?${objUrlVB}=${promoIDB}`;
          newList.push(`${myUrlParams}`);
        }
      }
      return newList;
    }

    let newUrlString = "";
    const newListParams = [];
    if (objUrlV !== "") {
      if (promoShortCodeA && objUrlV === "short_code") {
        newUrlString = `?${objUrlV}=${promoShortCodeA}`;
      } else {
        if (
          (objUrlV === "short_code" || objUrlV === "code") &&
          !isString(promoIDA)
        ) {
          newListParams.push(`/${promoIDA}`);
        } else if (objUrlV === "group_id" && !isString(promoIDA)) {
          newListParams.push(`?${objUrlV}=${promoIDA}`);
        } else if (objUrlV === "subgroup_id" && !isString(promoIDA)) {
          newListParams.push(`?${objUrlV}=${promoIDA}`);
        } else {
          newUrlString = `?${objUrlV}=${promoIDA}`;
        }
      }
    }
    if (objUrlVB !== "") {
      const oldUrl = newUrlString;
      if (promoShortCodeB && objUrlVB === "short_code") {
        newUrlString =
          oldUrl !== ""
            ? `${oldUrl}&${objUrlVB}=${promoShortCodeB}`
            : `?${objUrlVB}=${promoShortCodeB}`;
      } else {
        if (
          (objUrlVB === "short_code" || objUrlVB === "code") &&
          !isString(promoIDB)
        ) {
          newListParams.push(`/${promoIDB}`);
        } else if (objUrlVB === "group_id" && !isString(promoIDB)) {
          newListParams.push(`?${objUrlVB}=${promoIDB}`);
        } else if (objUrlVB === "subgroup_id" && !isString(promoIDB)) {
          newListParams.push(`?${objUrlVB}=${promoIDB}`);
        } else {
          newUrlString =
            oldUrl !== ""
              ? `${oldUrl}&${objUrlVB}=${promoIDB}`
              : `?${objUrlVB}=${promoIDB}`;
        }
      }
    }

    if (newListParams.length === 0) {
      return newUrlString;
    } else {
      return [...newListParams, newUrlString];
    }
    // const objUrlV = objUrl ? objUrl.value : "";
    // const objUrlVB = objUrlB ? objUrlB.value : "";
    // if (objUrlV === objUrlVB) {
    //   if (promoIDA !== 0 && promoIDB !== 0) {
    //     return [`${objUrlV}=${promoIDA}`, `${objUrlVB}=${promoIDB}`];
    //   } else if (promoIDA !== 0 && promoIDB === 0) {
    //     return [`${objUrlV}=${promoIDA}`];
    //   } else if (promoIDA === 0 && promoIDB !== 0) {
    //     return [`${objUrlVB}=${promoIDB}`];
    //   }
    // }
    // if (promoIDA !== 0 && promoIDB === 0) {
    //   return `${objUrlV}=${promoIDA}`;
    // } else if (promoIDA === 0 && promoIDB !== 0) {
    //   return `${objUrlVB}=${promoIDB}`;
    // }
    // return `${objUrlV}=${promoIDA}&${objUrlVB}=${promoIDB}`;
  }
  return "";
};

export const getPromoTypeFree = (pt, item) => {
  if (pt === PromoType_B[0]) {
    const promoType = get(item, "criteria.buy_x_get_y.y.type", "");
    const promoID = get(item, "criteria.buy_x_get_y.y.id", 0);
    const promoShortCode = get(item, "criteria.buy_x_get_y.y.code");
    const objUrl = find(keyPromo, function (o) {
      // console.log(promoType, o.id);
      return o.id === promoType;
    });
    // console.log(objUrl);
    const objUrlV = objUrl ? objUrl.value : "";
    if (objUrlV === "short_code" && promoShortCode) {
      return `?${objUrlV}=${promoShortCode}`;
    }
    const myUrlParams =
      (objUrlV === "short_code" || objUrlV === "code") && !isString(promoID)
        ? `/${promoID}`
        : `?${objUrlV}=${promoID}`;

    return myUrlParams;
  } else if (pt === PromoType_B[1]) {
    const promoTypeA = get(item, "criteria.buy_ab_get_cd.c.type", "");
    const promoTypeB = get(item, "criteria.buy_ab_get_cd.d.type", "");
    const promoShortCodeC = get(item, "criteria.buy_ab_get_cd.c.code");
    const promoShortCodeD = get(item, "criteria.buy_ab_get_cd.d.code");
    const promoIDA =
      get(item, "criteria.buy_ab_get_cd.c.id", 0) !== ""
        ? get(item, "criteria.buy_ab_get_cd.c.id", 0)
        : 0;
    const promoIDB =
      get(item, "criteria.buy_ab_get_cd.d.id", 0) !== ""
        ? get(item, "criteria.buy_ab_get_cd.d.id", 0)
        : 0;
    const objUrl = find(keyPromo, function (o) {
      return o.id === promoTypeA;
    });
    const objUrlB = find(keyPromo, function (o) {
      return o.id === promoTypeB;
    });

    const objUrlV = objUrl ? objUrl.value : "";
    const objUrlVB = objUrlB ? objUrlB.value : "";
    if (objUrlV === objUrlVB) {
      let newList = [];
      if (objUrlV !== "") {
        if (promoShortCodeC && objUrlV === "short_code") {
          newList.push(`?${objUrlV}=${promoShortCodeC}`);
        } else {
          const myUrlParams =
            (objUrlV === "short_code" || objUrlV === "code") &&
            !isString(promoIDA)
              ? `/${promoIDA}`
              : `?${objUrlV}=${promoIDA}`;
          //isString(promoIDA)?

          newList.push(`${myUrlParams}`);
        }
      }
      if (objUrlVB !== "") {
        if (promoShortCodeD && objUrlVB === "short_code") {
          newList.push(`?${objUrlVB}=${promoShortCodeD}`);
        } else {
          const myUrlParams =
            (objUrlVB === "short_code" || objUrlVB === "code") &&
            !isString(promoIDB)
              ? `/${promoIDB}`
              : `?${objUrlVB}=${promoIDB}`;
          newList.push(`${myUrlParams}`);
        }
      }
      return newList;
    }

    let newUrlString = "";
    const newListParams = [];
    if (objUrlV !== "") {
      if (objUrlV === "short_code" && promoShortCodeC) {
        newUrlString = `?${objUrlV}=${promoShortCodeC}`;
      } else {
        if (
          (objUrlV === "short_code" || objUrlV === "code") &&
          !isString(promoIDA)
        ) {
          newListParams.push(`/${promoIDA}`);
        } else {
          newUrlString = `?${objUrlV}=${promoIDA}`;
        }
      }
    }
    if (objUrlVB !== "") {
      const oldUrl = newUrlString;
      if (objUrlVB === "short_code" && promoShortCodeD) {
        newUrlString =
          oldUrl !== ""
            ? `${oldUrl}&${objUrlVB}=${promoShortCodeD}`
            : `?${objUrlVB}=${promoShortCodeD}`;
      } else {
        if (
          (objUrlVB === "short_code" || objUrlVB === "code") &&
          !isString(promoIDB)
        ) {
          newListParams.push(`/${promoIDB}`);
        } else {
          newUrlString =
            oldUrl !== ""
              ? `${oldUrl}&${objUrlVB}=${promoIDB}`
              : `?${objUrlVB}=${promoIDB}`;
        }
      }
    }

    if (newListParams.length === 0) {
      return newUrlString;
    } else {
      return [...newListParams, newUrlString];
    }

    // return `${objUrlV}=${promoIDA}&${objUrlVB}=${promoIDB}`;
  }
  return "";
};
export const getPromoTypeFreeABCD = (pt, item) => {
  if (pt === PromoType_B[1]) {
    const promoTypeA = get(item, "criteria.buy_ab_get_cd.c.type", "");
    const promoTypeB = get(item, "criteria.buy_ab_get_cd.d.type", "");
    const promoShortCodeC = get(item, "criteria.buy_ab_get_cd.c.code");
    const promoShortCodeD = get(item, "criteria.buy_ab_get_cd.d.code");
    const promoIDA =
      get(item, "criteria.buy_ab_get_cd.c.id", 0) !== ""
        ? get(item, "criteria.buy_ab_get_cd.c.id", 0)
        : 0;
    const promoIDB =
      get(item, "criteria.buy_ab_get_cd.d.id", 0) !== ""
        ? get(item, "criteria.buy_ab_get_cd.d.id", 0)
        : 0;
    const objUrl = find(keyPromo, function (o) {
      return o.id === promoTypeA;
    });
    const objUrlB = find(keyPromo, function (o) {
      return o.id === promoTypeB;
    });

    const objUrlV = objUrl ? objUrl.value : "";
    const objUrlVB = objUrlB ? objUrlB.value : "";
    if (objUrlV === objUrlVB) {
      let newList = [];
      if (objUrlV !== "") {
        if (promoShortCodeC && objUrlV === "short_code") {
          newList.push(`?${objUrlV}=${promoShortCodeC}`);
        } else {
          const myUrlParams =
            (objUrlV === "short_code" || objUrlV === "code") &&
            !isString(promoIDA)
              ? `/${promoIDA}`
              : `?${objUrlV}=${promoIDA}`;
          //isString(promoIDA)?

          newList.push(`${myUrlParams}`);
        }
      }
      if (objUrlVB !== "") {
        if (promoShortCodeD && objUrlVB === "short_code") {
          newList.push(`?${objUrlVB}=${promoShortCodeD}`);
        } else {
          const myUrlParams =
            (objUrlVB === "short_code" || objUrlVB === "code") &&
            !isString(promoIDB)
              ? `/${promoIDB}`
              : `?${objUrlVB}=${promoIDB}`;
          newList.push(`${myUrlParams}`);
        }
      }
      return newList;
    }

    let newUrlString = "";
    const newListParams = [];
    if (objUrlV !== "") {
      if (objUrlV === "short_code" && promoShortCodeC) {
        newUrlString = `?${objUrlV}=${promoShortCodeC}`;
      } else {
        if (
          (objUrlV === "short_code" || objUrlV === "code") &&
          !isString(promoIDA)
        ) {
          newListParams.push(`/${promoIDA}`);
        } else if (objUrlV === "group_id" && !isString(promoIDA)) {
          newListParams.push(`?${objUrlV}=${promoIDA}`);
        } else if (objUrlV === "subgroup_id" && !isString(promoIDA)) {
          newListParams.push(`?${objUrlV}=${promoIDA}`);
        } else {
          newUrlString = `?${objUrlV}=${promoIDA}`;
        }
      }
    }
    if (objUrlVB !== "") {
      const oldUrl = newUrlString;
      if (objUrlVB === "short_code" && promoShortCodeD) {
        newUrlString =
          oldUrl !== ""
            ? `${oldUrl}&${objUrlVB}=${promoShortCodeD}`
            : `?${objUrlVB}=${promoShortCodeD}`;
      } else {
        if (
          (objUrlVB === "short_code" || objUrlVB === "code") &&
          !isString(promoIDB)
        ) {
          newListParams.push(`/${promoIDB}`);
        } else if (objUrlVB === "group_id" && !isString(promoIDB)) {
          newListParams.push(`?${objUrlVB}=${promoIDB}`);
        } else if (objUrlVB === "subgroup_id" && !isString(promoIDB)) {
          newListParams.push(`?${objUrlVB}=${promoIDB}`);
        } else {
          newUrlString =
            oldUrl !== ""
              ? `${oldUrl}&${objUrlVB}=${promoIDB}`
              : `?${objUrlVB}=${promoIDB}`;
        }
      }
    }

    if (newListParams.length === 0) {
      return newUrlString;
    } else {
      return [...newListParams, newUrlString];
    }

    // return `${objUrlV}=${promoIDA}&${objUrlVB}=${promoIDB}`;
  }
  return "";
};

export const getTotalQtyFreeProduct = (item, totalQty, criteria) => {
  let freeTotal = 0;
  // "criteria.buy_x_get_y.moqs"
  const moqs = orderBy(get(item, criteria, []), ["moq"], ["desc"]);
  if (moqs.length > 0) {
    if (totalQty >= moqs[0].moq) {
      const myTotal = totalQty / moqs[0].buy;
      const total = Math.floor(myTotal);
      const myTotalResult =
        moqs[0].additional > 0 ? moqs[0].additional + total : total;
      return myTotalResult;
    } else if (moqs.length > 1 && totalQty >= moqs[1].moq) {
      const myTotal = totalQty / moqs[1].buy;
      const total = Math.floor(myTotal);
      const myTotalResult =
        moqs[1].additional > 0 ? moqs[1].additional + total : total;

      return myTotalResult;
    } else if (moqs.length > 2 && totalQty >= moqs[2].moq) {
      const myTotal = totalQty / moqs[2].buy;
      const total = Math.floor(myTotal);
      const myTotalResult =
        moqs[2].additional > 0 ? moqs[2].additional + total : total;
      return myTotalResult;
    }
  }

  return freeTotal;
};

export const getMOQIndex = (item, total, source) => {
  const moqs = orderBy(get(item, source, []), ["moq"], ["desc"]);
  if (moqs.length > 0) {
    if (total >= moqs[0].moq) {
      return 3;
    } else if (total >= moqs[1].moq) {
      return 2;
    } else if (total >= moqs[2].moq) {
      return 1;
    } else {
      return 0;
    }
  }
  return 0;
};

export const getTotalSelectedQtyFreeA = (list) => {
  let freeTotal = 0;
  if (list && list.length > 0) {
    list.map((product) => {
      // const { promotions } = product;
      //product.promo === type &&
      if (
        product.subtotal * 1 === 0 &&
        product.qty &&
        product.qty > 0 &&
        product.foc
      ) {
        freeTotal = freeTotal + product.qty * 1;
      }
      return "";
    });
  }

  return freeTotal;
};

export const getTotalSelectedQtyFree = (list) => {
  let freeTotal = 0;
  if (list && list.length > 0) {
    list.map((product) => {
      // const { promotions } = product;
      if (
        product.subtotal * 1 === 0 &&
        product.qty &&
        product.qty > 0 &&
        product.foc
      ) {
        freeTotal = freeTotal + product.qty * 1;
      }
      return "";
    });
  }

  return freeTotal;
};

export const getTotalQtyFreeProductPromoABCD = (item, tPromoOrderQty) => {
  let freeTotal = 0;
  const myMoqs = get(item, "criteria.buy_ab_get_cd.moqs", []);
  const moqs = orderBy(myMoqs, ["moq"], ["desc"]);
  // console.log(moqs);
  if (moqs.length > 0) {
    // console.log(tPromoOrderQty);
    // console.log(moqs[0]);
    if (tPromoOrderQty >= moqs[0].moq) {
      const mybuy = moqs[0].buy !== null ? moqs[0].buy : 0;
      // console.log(mybuy);
      const myAdditional = moqs[0].additional !== null ? moqs[0].additional : 0;
      if (mybuy !== 0) {
        const myTotal = tPromoOrderQty / mybuy;
        const total = Math.floor(myTotal);
        const myTotalResult = myAdditional > 0 ? myAdditional + total : total;
        return myTotalResult;
      }
    } else if (moqs.length > 1 && tPromoOrderQty >= moqs[1].moq) {
      const mybuy = moqs[1].buy !== null ? moqs[1].buy : 0;
      const myAdditional = moqs[1].additional !== null ? moqs[1].additional : 0;
      if (mybuy !== 0) {
        const myTotal = tPromoOrderQty / mybuy;
        const total = Math.floor(myTotal);
        const myTotalResult = myAdditional > 0 ? myAdditional + total : total;
        return myTotalResult;
      }
    } else if (moqs.length > 2 && tPromoOrderQty >= moqs[2].moq) {
      const mybuy = moqs[2].buy !== null ? moqs[2].buy : 0;
      const myAdditional = moqs[2].additional !== null ? moqs[2].additional : 0;
      if (mybuy !== 0) {
        const myTotal = tPromoOrderQty / mybuy;
        const total = Math.floor(myTotal);
        const myTotalResult = myAdditional > 0 ? myAdditional + total : total;
        return myTotalResult;
      }
    }
  }

  return freeTotal;
};
export const getTotalQtyFreeProductPromo = (
  item,
  tPromoOrderQty,
  promoType
) => {
  let freeTotal = 0;
  const myPt =
    promoType === PromoType_B[0]
      ? "criteria.buy_x_get_y.moqs"
      : "criteria.buy_ab_get_cd.moqs";

  const myMoqs = get(item, myPt, []);
  const moqs = orderBy(myMoqs, ["moq"], ["desc"]);
  // console.log(moqs);
  // console.log(myPt, item);
  if (moqs.length > 0) {
    // console.log(tPromoOrderQty);
    // console.log(moqs[0]);
    if (tPromoOrderQty >= moqs[0].moq) {
      // console.log("masuk sini");
      const mybuy = moqs[0].buy !== null ? moqs[0].buy : 0;
      // console.log("mybuy", mybuy, moqs[0]);
      const myAdditional = moqs[0].additional !== null ? moqs[0].additional : 0;
      const myGet = moqs[0].get !== null ? moqs[0].get : 0;
      if (mybuy !== 0) {
        const myTotal = tPromoOrderQty / mybuy;
        const total = Math.floor(myTotal);
        const newTotal = total * myGet;
        // console.log("myTotal", myGet, myTotal, total);
        const myTotalResult =
          myAdditional > 0 ? myAdditional + newTotal : newTotal;
        return myTotalResult;
      }
    } else if (moqs.length > 1 && tPromoOrderQty >= moqs[1].moq) {
      const mybuy = moqs[1].buy !== null ? moqs[1].buy : 0;
      const myAdditional = moqs[1].additional !== null ? moqs[1].additional : 0;
      const myGet = moqs[1].get !== null ? moqs[1].get : 0;
      if (mybuy !== 0) {
        const myTotal = tPromoOrderQty / mybuy;
        const total = Math.floor(myTotal);
        const newTotal = total * myGet;
        const myTotalResult =
          myAdditional > 0 ? myAdditional + newTotal : newTotal;
        return myTotalResult;
      }
    } else if (moqs.length > 2 && tPromoOrderQty >= moqs[2].moq) {
      const mybuy = moqs[2].buy !== null ? moqs[2].buy : 0;
      const myAdditional = moqs[2].additional !== null ? moqs[2].additional : 0;
      const myGet = moqs[2].get !== null ? moqs[2].get : 0;
      if (mybuy !== 0) {
        const myTotal = tPromoOrderQty / mybuy;
        const total = Math.floor(myTotal);
        const newTotal = total * myGet;
        const myTotalResult =
          myAdditional > 0 ? myAdditional + newTotal : newTotal;
        return myTotalResult;
      }
    }
  }

  return freeTotal;
};

const checkShortCode = (product, typeProduct, criteria, isCode) => {
  if (typeProduct === "short_code") {
    if (isCode && isCode !== "" && product[typeProduct] === isCode) {
      return true;
    } else {
      if (isString(criteria) && product[typeProduct] === criteria) {
        return true;
      } else {
        return false;
      }
    }
    // get(criteria, "buy_ab_get_cd.c.code")
  } else {
    if (isString(criteria) === product[typeProduct]) {
      return true;
    } else if (criteria === product.id) {
      return true;
    } else {
      return false;
    }
  }
};

export const checkPromotionGETCD = (product, promotions) => {
  let variable = 0;
  const { criteria } = promotions;
  const buyC = get(criteria, "buy_ab_get_cd.c.type", "");
  const buyD = get(criteria, "buy_ab_get_cd.d.type", "");
  if (
    buyC === "ProductGroup" &&
    get(criteria, "buy_ab_get_cd.c.id", 0) === product.group_id
  ) {
    return 1;
  } else if (
    buyD === "ProductGroup" &&
    get(criteria, "buy_ab_get_cd.d.id", 0) === product.group_id
  ) {
    return 2;
  } else if (
    buyC === "SubProductGroup" &&
    get(criteria, "buy_ab_get_cd.c.id", 0) === product.subgroup_id
  ) {
    return 1;
  } else if (
    buyD === "SubProductGroup" &&
    get(criteria, "buy_ab_get_cd.d.id", 0) === product.subgroup_id
  ) {
    return 2;
  } else if (
    buyC === "ProductCode" &&
    checkShortCode(product, "code", get(criteria, "buy_ab_get_cd.c.id", ""))
  ) {
    return 1;
  } else if (
    buyD === "ProductCode" &&
    checkShortCode(product, "code", get(criteria, "buy_ab_get_cd.d.id", ""))
  ) {
    return 2;
  } else if (
    buyC === "ProductShortCode" &&
    checkShortCode(
      product,
      "short_code",
      get(criteria, "buy_ab_get_cd.c.id", ""),
      get(criteria, "buy_ab_get_cd.c.code")
    )
  ) {
    return 1;
  } else if (
    buyD === "ProductShortCode" &&
    checkShortCode(
      product,
      "short_code",
      get(criteria, "buy_ab_get_cd.d.id", ""),
      get(criteria, "buy_ab_get_cd.d.code")
    )
  ) {
    return 2;
  }

  return variable;
};
export const checkPromotionBuyABGETCD = (product, promotions) => {
  let variable = 0;
  const { criteria } = promotions;
  const buyA = get(criteria, "buy_ab_get_cd.a.type", "");
  const buyB = get(criteria, "buy_ab_get_cd.b.type", "");
  if (
    buyA === "ProductGroup" &&
    get(criteria, "buy_ab_get_cd.a.id", 0) === product.group_id
  ) {
    return 1;
  } else if (
    buyB === "ProductGroup" &&
    get(criteria, "buy_ab_get_cd.b.id", 0) === product.group_id
  ) {
    return 2;
  } else if (
    buyA === "SubProductGroup" &&
    get(criteria, "buy_ab_get_cd.a.id", 0) === product.subgroup_id
  ) {
    return 1;
  } else if (
    buyB === "SubProductGroup" &&
    get(criteria, "buy_ab_get_cd.b.id", 0) === product.subgroup_id
  ) {
    return 2;
  } else if (
    buyA === "ProductCode" &&
    checkShortCode(product, "code", get(criteria, "buy_ab_get_cd.a.id", ""))
  ) {
    return 1;
  } else if (
    buyB === "ProductCode" &&
    checkShortCode(product, "code", get(criteria, "buy_ab_get_cd.b.id", ""))
  ) {
    return 2;
  } else if (
    buyA === "ProductShortCode" &&
    checkShortCode(
      product,
      "short_code",
      get(criteria, "buy_ab_get_cd.a.id", ""),
      get(criteria, "buy_ab_get_cd.a.code")
    )
  ) {
    return 1;
  } else if (
    buyB === "ProductShortCode" &&
    checkShortCode(
      product,
      "short_code",
      get(criteria, "buy_ab_get_cd.b.id", ""),
      get(criteria, "buy_ab_get_cd.b.code")
    )
  ) {
    return 2;
  }

  return variable;
};
export const checkPromotion = (product, promotions) => {
  const myPromotions = find(promotions, function (o) {
    const { criteria, promotion_type } = o;
    // console.log(promotion_type);
    if (promotion_type === "Buy X Get Y") {
      const buyX = get(criteria, "buy_x_get_y.x.type", "");
      if (buyX === "SubProductGroup") {
        return get(criteria, "buy_x_get_y.x.id", 0) === product.subgroup_id;
      } else if (buyX === "ProductGroup") {
        return get(criteria, "buy_x_get_y.x.id", 0) === product.group_id;
      } else if (buyX === "ProductCode") {
        return checkShortCode(
          product,
          "code",
          get(criteria, "buy_x_get_y.x.id", "")
        );
      } else if (buyX === "ProductShortCode") {
        return checkShortCode(
          product,
          "short_code",
          get(criteria, "buy_x_get_y.x.id", "")
        );
      }
    } else if (promotion_type === "Buy AB Get CD") {
      const buyA = get(criteria, "buy_ab_get_cd.a.type", "");
      const buyB = get(criteria, "buy_ab_get_cd.b.type", "");
      if (buyA === "ProductGroup" || buyB === "ProductGroup") {
        return (
          get(criteria, "buy_ab_get_cd.a.id", 0) === product.group_id ||
          get(criteria, "buy_ab_get_cd.b.id", 0) === product.group_id
        );
      } else if (buyA === "SubProductGroup" || buyB === "SubProductGroup") {
        return (
          get(criteria, "buy_ab_get_cd.a.id", 0) === product.subgroup_id ||
          get(criteria, "buy_ab_get_cd.b.id", 0) === product.subgroup_id
        );
      } else if (buyA === "ProductCode" || buyB === "ProductCode") {
        return (
          checkShortCode(
            product,
            "code",
            get(criteria, "buy_ab_get_cd.a.id", "")
          ) ||
          checkShortCode(
            product,
            "code",
            get(criteria, "buy_ab_get_cd.b.id", "")
          )
        );
      } else if (buyA === "ProductShortCode" || buyB === "ProductShortCode") {
        return (
          checkShortCode(
            product,
            "short_code",
            get(criteria, "buy_ab_get_cd.a.id", "")
          ) ||
          checkShortCode(
            product,
            "short_code",
            get(criteria, "buy_ab_get_cd.b.id", "")
          )
        );
      }
    } else if (promotion_type === "Discount") {
      const buyType = get(criteria, "discount.buy.type", "");
      if (buyType === "ProductGroup") {
        return get(criteria, "discount.buy.id", 0) === product.group_id;
      } else if (buyType === "SubProductGroup") {
        return get(criteria, "discount.buy.id", 0) === product.subgroup_id;
      } else if (buyType === "ProductCode") {
        return checkShortCode(
          product,
          "code",
          get(criteria, "discount.buy.id", "")
        );
      } else if (buyType === "ProductShortCode") {
        return checkShortCode(
          product,
          "short_code",
          get(criteria, "discount.buy.id", "")
        );
      }
    } else if (promotion_type === "Special Price") {
      const buyType = get(criteria, "price.buy.type", "");
      if (buyType === "ProductGroup") {
        return get(criteria, "price.buy.id", 0) === product.group_id;
      } else if (buyType === "SubProductGroup") {
        return get(criteria, "price.buy.id", 0) === product.subgroup_id;
      } else if (buyType === "ProductCode") {
        return checkShortCode(
          product,
          "code",
          get(criteria, "price.buy.id", "")
        );
      } else if (buyType === "ProductShortCode") {
        return checkShortCode(
          product,
          "short_code",
          get(criteria, "price.buy.id", "")
        );
      }
    }
    return false;
  });
  return myPromotions && myPromotions.id ? "YES" : "NO";
};

export const getKeyMoq = (promoType, item) => {
  const criteria = get(item, "criteria");
  if (criteria) {
    if (promoType === "Buy AB Get CD") {
      return get(criteria, "buy_ab_get_cd.moqs");
    } else if (promoType === "Buy X Get Y") {
      return get(criteria, "buy_x_get_y.moqs", []);
    } else if (promoType === "Discount") {
      return get(criteria, "discount.moqs", []);
    } else if (promoType === "Special Price") {
      return get(criteria, "price.moqs", []);
    } else {
      return [];
    }
  }
};

export const useStoreTemp = create((set) => ({
  tempList: [],
  setTempList: (newState) =>
    set((state) => {
      // console.log("state", state);
      // console.log("newState,", newState);
      return { tempList: newState };
    }),
}));

export const useStoreTempABCD = create((set) => ({
  tempListBuy: [],
  tempListFree: [],
  tempListBuyXY: [],
  tempListFreeXY: [],
  setTempListBuy: (newState) =>
    set((state) => {
      // console.log("state", state);
      // console.log("newState,", newState);
      return { tempListBuy: newState };
    }),
  setTempListFree: (newState) =>
    set((state) => {
      // console.log("state", state);
      // console.log("newState,", newState);
      return { tempListFree: newState };
    }),
  setTempListBuyXY: (newState) =>
    set((state) => {
      // console.log("state", state);
      // console.log("newState,", newState);
      return { tempListBuyXY: newState };
    }),
  setTempListFreeXY: (newState) =>
    set((state) => {
      // console.log("state", state);
      // console.log("newState,", newState);
      return { tempListFreeXY: newState };
    }),
  setClearTempABCD: () =>
    set((state) => {
      // console.log("state", state);
      // console.log("newState,", newState);
      return {
        ...state,
        tempListBuy: [],
        tempListFree: [],
      };
    }),
  setClearTempXY: () =>
    set((state) => {
      // console.log("state", state);
      // console.log("newState,", newState);
      return {
        ...state,
        tempListBuyXY: [],
        tempListFreeXY: [],
      };
    }),
}));

export const useStoreTempXY = create((set) => ({
  tempListBuyXY: [],
  tempListFreeXY: [],
  setTempListBuy: (newState) =>
    set((state) => {
      // console.log("state", state);
      // console.log("newState,", newState);
      return { tempListBuyXY: newState };
    }),
  setTempListFree: (newState) =>
    set((state) => {
      // console.log("state", state);
      // console.log("newState,", newState);
      return { tempListFree: newState };
    }),
  setClearTemp: () =>
    set((state) => {
      // console.log("state", state);
      // console.log("newState,", newState);
      return {
        tempListBuyXY: [],
        tempListFreeXY: [],
      };
    }),
}));

export const cacheTimeMinute = 1000 * 60 * 30;

export const getRegisterNumber = (item, countryCode) => {
  let rn = "";
  if (item && item.registration_number) {
    if (typeof item.registration_number === "object") {
      return get(item, `registration_number[${countryCode}]`, "");
    } else if (typeof item.registration_number === "string") {
      return get(item, "registration_number", "");
    }
  }
  return rn;
};
