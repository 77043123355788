//ArListGridDiscount
import React, { useState, useEffect, Fragment } from "react";
import {
  find,
  filter,
  flattenDepth,
  uniqBy,
  get,
  orderBy,
  sumBy,
  map,
  has,
  toLower,
} from "lodash";
import numeral from "numeral";
import { AgGridReact } from "ag-grid-react";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "./Expand";
// import { getProducts, getAllProducts } from "../../restClient";
import { getProductsWithId, getAllProductsWithId } from "../../restClient";
import { defaultDiscountColumnDefs } from "./defaultValue";
import {
  getPromoType,
  PromoType_B,
  getKeyMoq,
  getRegisterNumber,
} from "./utils";

const getMOQ = (totalQty, pt, myitem) => {
  // console.log("item", item);
  const moqsList = getKeyMoq(pt, myitem);
  const moqsListSP = filter(moqsList, function (oi) {
    return oi.moq > 0;
  });
  const moqsSP = orderBy(moqsListSP, "moq", "desc");
  const itemMoq = find(moqsSP, function (o) {
    return totalQty >= o.moq;
  });
  return itemMoq;
};

export default (props) => {
  //thisQuantity,
  const {
    translate,
    promoType,
    item,
    tempList,
    setTempList,
    setShow,
    setMessageError,
    isOAAsossiate,
    countryId,
    country,
  } = props;
  const countryCode = toLower(get(country, "code", ""));
  const myURl = getPromoType(promoType, item);
  const [isRequest, setIsRequest] = useState(true);
  const [gridColApi, setGridColApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [state, setState] = useState({
    tempMoq: "",
    totalQty: 0,
    search: "",
    message: "",
    show: false,
    columnDefs: defaultDiscountColumnDefs(false),
    defaultColDef: {
      flex: 1,
      editable: true,
      sortable: true,
      filter: true,
      cellStyle: {
        "line-height": "40px",
      },
    },
    getRowNodeId: function (data) {
      return data.code;
    },
    listPromo: [],
    item: props.item,
    expanded: "panelGridPromo",
  });
  useEffect(() => {
    function fetchProduct() {
      if (isRequest) {
        const totalQty = sumBy(tempList, "qty");
        const myItemMoq = getMOQ(totalQty, promoType, item);
        const discount = get(myItemMoq, "discount", 0);
        if (Array.isArray(myURl)) {
          getAllProductsWithId(myURl)
            .then((res) => {
              // console.log(res);
              const result = flattenDepth(res, 1);
              const dataProduct = filter(result, function (o) {
                return o.active;
              });
              const response = uniqBy(dataProduct, "id");
              if (response && response.length > 0) {
                // console.log("response", response);
                const myLatest = response.map((item) => {
                  let newItem = item;
                  const objUrl = find(tempList, function (o) {
                    // console.log(promoType, o.id);
                    return o.product_id === item.id;
                  });
                  const myPrice = get(item, "price", 0);
                  const labelDiscount =
                    discount < 1 ? discount * 100 : discount;
                  const myDiscount =
                    discount !== null
                      ? discount < 1
                        ? myPrice * (1 - discount)
                        : myPrice * (1 - discount / 100)
                      : myPrice;
                  newItem["discount"] = `${labelDiscount}%`;
                  const roundedDiscount = numeral(myDiscount).format("0,0.00");
                  newItem["discounted_price"] = roundedDiscount;
                  if (objUrl && objUrl.product_id) {
                    // console.log(objUrl.qty, objUrl.subtotal);
                    // console.log(myDiscount);
                    newItem["qty"] = objUrl.qty;
                    newItem["subtotal"] = roundedDiscount * objUrl.qty;
                    if (isOAAsossiate) {
                      newItem["price"] = objUrl.price;
                    }
                  } else {
                    newItem["qty"] = 0;
                    newItem["subtotal"] = 0;
                    // newItem["discount"] = "0%";
                  }
                  newItem["moq_group_index"] = 0;
                  newItem["growth"] = get(item, `class4.${countryId}`, "");
                  newItem["cr"] = get(item, "cr", false);
                  //  === true ? "CR" : "";
                  newItem["dg"] = get(item, "dg", false);
                  // === true ? "DG" : "";
                  newItem["target_id"] = get(item, "target_id", 0);
                  newItem["registration_number"] = getRegisterNumber(
                    item,
                    countryCode
                  );
                  return newItem;
                });
                setRowData(myLatest);
              }
              setIsRequest(false);
            })
            .catch((e) => {
              //   console.log(e);
              setIsRequest(false);
            });
        } else {
          if (myURl !== "") {
            getProductsWithId(myURl)
              .then((response) => {
                if (response && response.length > 0) {
                  const dataProduct = filter(response, function (o) {
                    return o.active;
                  });
                  const responseData = uniqBy(dataProduct, "id");
                  const myLatest = responseData.map((item) => {
                    let newItem = item;
                    const objUrl = find(tempList, function (o) {
                      return o.product_id === item.id;
                    });

                    const myPrice = get(item, "price", 0);
                    const labelDiscount =
                      discount < 1 ? discount * 100 : discount;
                    const myDiscount =
                      discount !== null
                        ? discount < 1
                          ? myPrice * (1 - discount)
                          : myPrice * (1 - discount / 100)
                        : myPrice;

                    const roundedDiscount =
                      numeral(myDiscount).format("0,0.00");
                    newItem["discounted_price"] = roundedDiscount;
                    newItem["discount"] = `${labelDiscount}%`;
                    if (objUrl && objUrl.product_id) {
                      // console.log(objUrl.qty, myDiscount * objUrl.qty);
                      newItem["qty"] = objUrl.qty;
                      newItem["subtotal"] = roundedDiscount * objUrl.qty;
                      if (isOAAsossiate) {
                        newItem["price"] = objUrl.price;
                      }
                    } else {
                      newItem["qty"] = 0;
                      newItem["subtotal"] = 0;
                    }
                    newItem["moq_group_index"] = 0;
                    newItem["growth"] = get(item, `class4.${countryId}`, "");
                    newItem["cr"] = get(item, "cr", false);
                    // === true ? "CR" : "";
                    newItem["dg"] = get(item, "dg", false);
                    //  === true ? "DG" : "";
                    newItem["target_id"] = get(item, "target_id", 0);
                    newItem["registration_number"] = getRegisterNumber(
                      item,
                      countryCode
                    );
                    return newItem;
                  });
                  setRowData(myLatest);
                } else {
                  if (response && response.id) {
                    let newItem = response;
                    const objUrl = find(tempList, function (o) {
                      // console.log(promoType, o.id);
                      return o.product_id === response.id;
                    });

                    const myPrice = get(response, "price", 0);
                    const labelDiscount =
                      discount < 1 ? discount * 100 : discount;
                    const myDiscount =
                      discount !== null
                        ? discount < 1
                          ? myPrice * (1 - discount)
                          : myPrice * (1 - discount / 100)
                        : myPrice;
                    const roundedDiscount =
                      numeral(myDiscount).format("0,0.00");
                    newItem["discounted_price"] = roundedDiscount;
                    newItem["discount"] = `${labelDiscount}%`;
                    if (objUrl && objUrl.product_id) {
                      newItem["qty"] = objUrl.qty;
                      newItem["subtotal"] = numeral(
                        roundedDiscount * objUrl.qty
                      ).format("0,0.00");
                      if (isOAAsossiate) {
                        newItem["price"] = objUrl.price;
                      }
                    } else {
                      newItem["qty"] = 0;
                      newItem["subtotal"] = 0;
                    }
                    newItem["moq_group_index"] = 0;
                    newItem["cr"] = get(response, "cr", false);
                    // === true ? "CR" : "";
                    newItem["dg"] = get(response, "dg", false);
                    // === true ? "DG" : "";
                    newItem["target_id"] = get(response, "target_id", 0);
                    newItem["registration_number"] = getRegisterNumber(
                      response,
                      countryCode
                    );
                    setRowData([newItem]);
                  }
                }
                setIsRequest(false);
              })
              .catch((e) => {
                // console.log(e);
                setIsRequest(false);
              });
          } else {
            setIsRequest(false);
          }
        }
      }
    }
    fetchProduct();
    // console.log(promoType);
  }, [
    myURl,
    promoType,
    tempList,
    isRequest,
    item,
    isOAAsossiate,
    countryId,
    countryCode,
  ]);

  const onGridReady = (params) => {
    setGridColApi(params.api);
    // gridApi = params.api;
    // gridColumnApi = params.columnApi;
  };
  const handleChange = (panel) => (event, newExpanded) => {
    setState({
      ...state,
      expanded: newExpanded ? panel : false,
    });
  };
  const setOrderAmtDefault = (ProductCode, data) => {
    if (ProductCode) {
      let newListPromo = tempList;
      if (newListPromo.length === 0) {
        let itemProduct = {
          product_id: data.id,
          qty: data.qty,
          price: data.price,
          subtotal: data.subtotal,
          promotions: item,
          class4: data.class4,
          new_existing: get(data, "new_existing", ""),
        };
        itemProduct["target_id"] = data.target_id;
        newListPromo.push(itemProduct);
      } else {
        let newList = filter(newListPromo, function (o) {
          return o.product_id !== data.id;
        });
        const updateItem = find(newListPromo, function (o) {
          return o.product_id === data.id;
        });
        newListPromo = newList;
        let newItemProduct = {
          product_id: data.id,
          qty: data.qty,
          price: data.price,
          subtotal: data.subtotal,
          promotions: item,
          class4: data.class4,
          new_existing: get(data, "new_existing", ""),
        };
        if (updateItem && updateItem.id) {
          newItemProduct = {
            ...updateItem,
            product_id: data.id,
            qty: data.qty,
            price: data.price,
            subtotal: data.subtotal,
            promotions: item,
            class4: data.class4,
            new_existing: get(data, "new_existing", ""),
          };
        }

        newItemProduct["target_id"] = data.target_id;
        newListPromo.push(newItemProduct);
      }
      const totalQty = sumBy(newListPromo, "qty");
      setState({
        ...state,
        totalQty: totalQty,
      });
      const myItemMoq = getMOQ(totalQty, promoType, item);
      // let rowNode = gridColApi.getRowNode(ProductCode);
      const discount = get(myItemMoq, "discount", 0);
      if (promoType === "Discount") {
        const labelDiscount = discount < 1 ? discount * 100 : discount;
        //setAll Discount and discounted price
        map(rowData, function (o) {
          let rowNodeAll = gridColApi.getRowNode(o.code);
          if (totalQty < discount * 1) {
            rowNodeAll.setDataValue("discount", 0);
            rowNodeAll.setDataValue(
              "discounted_price",
              Number(o.price).toFixed(2)
            );
            if (o.qty > 0) {
              rowNodeAll.setDataValue(
                "subtotal",
                Number(o.price * o.qty).toFixed(2)
              );
            }
          } else {
            const oDiscount =
              discount !== null
                ? discount < 1
                  ? o.price * (1 - discount)
                  : o.price * (1 - discount / 100)
                : o.price;
            const roundedDiscount = numeral(oDiscount).format("0,0.00");
            rowNodeAll.setDataValue(
              "discounted_price",
              Number(roundedDiscount).toFixed(2)
            );
            rowNodeAll.setDataValue("discount", `${labelDiscount}%`);
            if (o.qty > 0) {
              rowNodeAll.setDataValue(
                "subtotal",
                Number(roundedDiscount * o.qty).toFixed(2)
              );
            }
          }
          return { ...o };
        });
      }

      const newListDiscount = map(newListPromo, function (oo) {
        const oDiscount =
          discount !== null
            ? discount < 1
              ? oo.price * (1 - discount)
              : oo.price * (1 - discount / 100)
            : oo.price;
        const roundedDiscount = numeral(oDiscount).format("0,0.00");
        const newSubtotal = roundedDiscount * oo.qty;
        return { ...oo, subtotal: newSubtotal };
      });
      const newListDiscount1 = sortOrderByName(rowData, newListDiscount);
      if (isOAAsossiate) {
        setTempList(newListDiscount1);
      } else {
        const listCheckQty = filter(newListDiscount1, function (o) {
          return o.qty > 0;
        });
        setTempList(listCheckQty);
      }
    }
  };

  const sortOrderByName = (rowData, list) => {
    const newList = map(list, function (o) {
      const itemProduct = find(rowData, function (i) {
        return i.id === o.product_id;
      });
      return { ...o, product_name: get(itemProduct, "packaging", "") };
    });
    const sortByName = orderBy(newList, "product_name", "asc");
    let mySortOrder = item && item.id ? item.id * 100 : 1;
    const newSortByName = map(sortByName, function (ii) {
      const { product_name, ...rest } = ii;
      rest["sort_order"] = mySortOrder++;
      return { ...rest };
    });

    return newSortByName;
  };

  const onCellEditingStopped = (params) => {
    // console.log(params);
    const MOQ = get(params, "data.moq", "");
    if (has(params, "data.code")) {
      let rowNode1 = gridColApi.getRowNode(params.data.code);
      if (
        MOQ !== "" &&
        MOQ !== "Indent" &&
        MOQ !== "*" &&
        get(params, "data.qty", 0) < MOQ * 1 &&
        !isOAAsossiate
      ) {
        if (get(params, "data.qty", 0) > 0) {
          setShow(true);
          setMessageError(`MOQ for this product is ${MOQ}`);
        }
        // setShow(true);
        // setMessageError(`MOQ for this product is ${MOQ}`);
        rowNode1.setDataValue("subtotal", 0);
        rowNode1.setDataValue("qty", 0);
      } else {
        const totalQty = sumBy(tempList, "qty");
        const myItemMoq = getMOQ(totalQty, promoType, item);
        const discount = get(myItemMoq, "discount", 0);
        const myPrice = get(params, "data.price", 0);
        const myDiscount =
          discount !== null
            ? discount < 1
              ? myPrice * (1 - discount)
              : myPrice * (1 - discount / 100)
            : 0;
        const roundedDiscount = numeral(myDiscount).format("0,0.00");
        rowNode1.setDataValue(
          "subtotal",
          Number(params.data.qty * roundedDiscount).toFixed(2)
        );
      }
      setOrderAmtDefault(params.data.code, params.data);
    }
  };
  const isMoreHeight =
    promoType === PromoType_B[0] || promoType === PromoType_B[1]
      ? "20vh"
      : "52vh";
  return (
    <Fragment>
      <ExpansionPanel
        expanded={state.expanded === "panelGridPromo"}
        onChange={handleChange("panelGridPromo")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='promotion_items-content'
          id='promotion_items-header'
        >
          <Typography>
            {translate("resources.po.listgrid.label.promo_items")} (
            {rowData.length}{" "}
            {rowData.length > 1
              ? translate("resources.po.listgrid.label.items")
              : translate("resources.po.listgrid.label.item")}
            )
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          style={{ width: "100%%", padding: 0, paddingTop: 4 }}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <div className='grid-wrapper'>
              <div
                id='myGrid'
                style={{
                  height: isMoreHeight,
                  width: "100%",
                }}
                className='ag-theme-balham'
              >
                <AgGridReact
                  rowHeight={40}
                  headerHeight={40}
                  rowData={rowData}
                  columnDefs={state.columnDefs}
                  defaultColDef={state.defaultColDef}
                  animateRows={true}
                  getRowNodeId={state.getRowNodeId}
                  onGridReady={onGridReady}
                  onCellEditingStopped={(params) =>
                    onCellEditingStopped(params)
                  }
                />
              </div>
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Fragment>
  );
};
