import React, { useState } from "react";
import { Flex } from "rebass";
import { get } from "lodash";

import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "./ExpandPanel";
import {
  BuyXGetYFields,
  BuyABGetCD,
  SpecialPrice,
  PromotionDiscount,
} from "./promo_settings";

const PromotionProductsettings = (props) => {
  const { translate, formData } = props;
  const [expand, setExpand] = useState(true);
  const isShowBuyX = get(formData, "promotion_type", "");
  return (
    <Flex width={[1]} flexDirection={"column"} pb={[2]}>
      <ExpansionPanel expanded={expand} onChange={() => setExpand(!expand)}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='promotion_settings-content'
          id='promotion_settings-header'
        >
          <Typography>
            {translate("resources.promotions.panel.promotion_settings")}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Flex width={[1]}>
            {isShowBuyX === "Buy X Get Y" && <BuyXGetYFields {...props} />}
            {isShowBuyX === "Buy AB Get CD" && <BuyABGetCD {...props} />}
            {isShowBuyX === "Discount" && <PromotionDiscount {...props} />}
            {isShowBuyX === "Special Price" && <SpecialPrice {...props} />}
          </Flex>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Flex>
  );
};

export default PromotionProductsettings;
