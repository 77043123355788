import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import { Link, useRouteMatch, useLocation } from "react-router-dom";
import { Divider, Tabs, Tab } from "@material-ui/core";
import { TabPanel, a11yProps, Title } from "../components";
import { Flex, Box } from "rebass";
import { useTranslate, useQueryWithStore } from "react-admin";
import {
  ListSettings,
  // ListOthers,
  ListDealers,
  ListCountries,
  CreateSettings,
  CreateCountries,
  CreateDealers,
  OtherFields,
} from "./components";

const tabs = [
  { id: "code", name: "Code", link: "/codes" },
  { id: "countries", name: "Countries", link: "/codes/countries" },
  { id: "dealers", name: "Dealers", link: "/codes/dealers" },
  { id: "others", name: "Others", link: "/codes/others" },
];
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const CreateBoxButton = (props) => {
  const { location } = props;
  if (location.pathname === "/codes/countries") {
    return <CreateCountries {...props} />;
  } else if (location.pathname === "/codes/dealers") {
    return <CreateDealers {...props} />;
  } else if (location.pathname === "/codes/others") {
    return <div />;
  } else {
    return <CreateSettings {...props} />;
  }
};

const payloadDefault = {
  pagination: { page: 1, perPage: 1000 },
  sort: { field: "name", order: "ASC" },
};

const Settinglist = (props) => {
  const { permissions, hasCreate, hasShow, hasEdit, hasList, ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const translate = useTranslate();
  const match = useRouteMatch();
  const location = useLocation();
  const url = location.pathname;
  let indexTab = 0;
  if (url === "/codes/countries") {
    indexTab = 1;
  } else if (url === "/codes/dealers") {
    indexTab = 2;
  } else if (url === "/codes/others") {
    indexTab = 3;
  }

  const { data } = useQueryWithStore({
    type: "getList",
    resource: "countries",
    payload: payloadDefault,
  });

  const [value, setValue] = React.useState(indexTab);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div className={classes.root}>
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        py={[3]}
      >
        <Box>
          <Title fontSize={[3]} fontWeight={"bold"}>
            {translate("resources.settings.name")}
          </Title>
        </Box>
        <CreateBoxButton {...rest} match={match} location={location} />
      </Flex>
      <Tabs value={value} indicatorColor="primary" onChange={handleChange}>
        {tabs.map((choice, index) => (
          <Tab
            key={choice.id}
            label={choice.name}
            component={Link}
            to={choice.link}
            {...a11yProps("codes", index)}
          />
        ))}
      </Tabs>
      <Divider />
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction} name={"codes"}>
          <ListSettings {...props} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction} name={"codes"}>
          <ListCountries {...props} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction} name={"codes"}>
          <ListDealers {...props} countries={data} />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction} name={"codes"}>
          <OtherFields {...props} translate={translate} />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
};

export default Settinglist;
