import React from "react";
import { get, orderBy, has } from "lodash";
import { Box } from "rebass";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";

import { CollapseMui } from "../../../../components";
import { StyledTableRow } from "../edit";
import { ItemViewFields } from "./CurrencyField";

const ViewNormalItems = (props) => {
  const { child } = props;
  const listPromo = get(child, "list", []);
  const newListPromo = orderBy(listPromo, "sort_order", "asc");
  return (
    <Box width={[1]}>
      <CollapseMui
        label={`Normal `}
        isLeft={true}
        defaultExpand={true}
        style={{ backgroundColor: "rgba(231,231,231,0.8)" }}
        isHaveButton={true}
      >
        <TableContainer component={"div"}>
          <Table aria-label='customized table'>
            <TableBody>
              {newListPromo.length > 0 &&
                newListPromo.map((promo, indexPromo) => {
                  // console.log(promo);
                  // const subtotal =
                  //   get(promo, "price", 0) * get(promo, "qty", 0);
                  // const discountedPrice = get(promo, "discounted_price", 0) * 1;
                  // const subtotal =
                  //   discountedPrice > 0
                  //     ? discountedPrice * get(promo, "qty", 0)
                  //     : get(promo, "price", 0) * get(promo, "qty", 0);
                  const discountedPrice = get(promo, "discounted_price", 0) * 1;
                  const subtotal = has(promo, "discounted_price")
                    ? discountedPrice * get(promo, "qty", 0)
                    : get(promo, "price", 0) * get(promo, "qty", 0);
                  const isCRDG =
                    get(promo, "cr", false) === true ||
                    get(promo, "dg", false) === true
                      ? { backgroundColor: "#FF0000", color: "#FFFFFF" }
                      : {};
                  const isCRDGCOLOR =
                    get(promo, "cr", false) === true ||
                    get(promo, "dg", false) === true
                      ? { color: "#FFFFFF" }
                      : { color: "rgba(0, 0, 0, 0.87)" };
                  return (
                    <StyledTableRow key={indexPromo} style={isCRDG}>
                      <ItemViewFields
                        promo={promo}
                        subtotal={subtotal}
                        isCRDGCOLOR={isCRDGCOLOR}
                      />
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </CollapseMui>
    </Box>
  );
};

export default ViewNormalItems;
