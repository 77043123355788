import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import {
  NumberParser2Decimals,
  NumberParserPercent,
  lowerCaseComparator,
  NumberParser2DecimalsWithMinus,
} from "../utils";

const defaultFreeColumnDefsDealer = [
  {
    field: "category",
    headerName: "Category",
    width: 200,
    editable: false,
    resizable: true,
  },
  {
    field: "target_setting",
    headerName: "Target Setting",
    minWidth: 200,
    editable: false,
    resizable: true,
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },
  {
    field: "last_year",
    headerName: "Last Year",
    width: 100,
    editable: false,
    resizable: true,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
    valueFormatter: NumberParser2Decimals,
  },
  {
    field: "target",
    headerName: "Target",
    width: 100,
    editable: false,
    resizable: true,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    field: "po",
    headerName: "PO",
    width: 100,
    editable: false,
    resizable: true,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    field: "gap",
    headerName: "GAP",
    width: 100,
    editable: false,
    resizable: true,
    valueFormatter: NumberParser2DecimalsWithMinus,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
  {
    field: "ratio",
    headerName: "Achievement %",
    width: 100,
    editable: false,
    resizable: true,
    valueFormatter: NumberParserPercent,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
];
const defaultFreeColumnDefs = [
  {
    field: "country",
    headerName: "Country",
    width: 130,
    editable: false,
    resizable: true,
  },
  {
    field: "dealer",
    headerName: "Dealer",
    width: 200,
    editable: false,
    resizable: true,
  },
  {
    field: "category",
    headerName: "Category",
    width: 200,
    editable: false,
    resizable: true,
  },
  {
    field: "target_setting",
    headerName: "Target Setting",
    minWidth: 200,
    editable: false,
    resizable: true,
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },
  {
    field: "last_year",
    headerName: "Last Year",
    width: 100,
    editable: false,
    resizable: true,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
    valueFormatter: NumberParser2Decimals,
  },
  {
    field: "target",
    headerName: "Target",
    width: 100,
    editable: false,
    resizable: true,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
    valueFormatter: NumberParser2Decimals,
  },
  {
    field: "po",
    headerName: "PO",
    width: 100,
    editable: false,
    resizable: true,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
    valueFormatter: NumberParser2Decimals,
  },
  {
    field: "gap",
    headerName: "GAP",
    width: 100,
    editable: false,
    resizable: true,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
    valueFormatter: NumberParser2DecimalsWithMinus,
  },
  {
    field: "ratio",
    headerName: "Achievement %",
    width: 100,
    editable: false,
    resizable: true,
    valueFormatter: NumberParserPercent,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
];

export default (props) => {
  // const [pageSize, setPageSize] = useState(20);
  const { list, dealerId } = props;
  const state = {
    columnDefs:
      dealerId !== null ? defaultFreeColumnDefsDealer : defaultFreeColumnDefs,
    defaultColDef: {
      flex: 1,
      editable: false,
      sortable: true,
      filter: true,
      minWidth: 100,
      // cellStyle: {
      //   "line-height": "40px",
      // },
    },
    getRowNodeId: function (data) {
      return data.category;
    },
  };
  // console.log(list.length);
  // console.log(dealerId);
  // const onPageSizeChange = (e) => {
  //   // console.log(e);
  //   setPageSize(e.pageSize);
  // };
  // const heightContainer =
  //   list.length > pageSize
  //     ? pageSize * 55 + 110
  //     : list.length > 0
  //     ? pageSize * 55 + 110
  //     : 300;
  // const sortModelDefault = dealerId !== null ? sortModelDealer : sortModel;
  // const columnDefaultDealer = dealerId !== null ? columnsDealer : columns;
  return (
    <div className='grid-wrapper'>
      <div
        id='myGridPO'
        className='ag-theme-balham'
        style={{ height: "50vh", width: "100%" }}
      >
        <AgGridReact
          rowHeight={40}
          headerHeight={40}
          rowData={list}
          columnDefs={state.columnDefs}
          defaultColDef={state.defaultColDef}
          animateRows={true}
        />
      </div>
    </div>
  );
};
