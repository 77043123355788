import React from "react"
import { connect } from "react-redux"
import { withStyles, createStyles } from "@material-ui/core/styles"
import classnames from "classnames"
import { userLogout } from "react-admin"
import MenuItem from "@material-ui/core/MenuItem"
import { TurnOffIcon } from "../icon_svg"
import { clearLocalStorage } from "../../authClient"

const styles = theme =>
  createStyles({
    root: {
      paddingTop: 15,
      paddingBottom: 15,
      fontSize: 12,
      alignItems: "center",
      letterSpacing: 3,
      backgroundColor: "#EFEFEF",
      "&:hover": {
        backgroundColor: "rgba(0, 175, 239, 0.2)",
      },
      color: "rgba(0, 0, 0, 0.54)",
      fill: "rgba(0, 0, 0, 0.54)",
    },
  })

const AppLogoutButton = ({ classes, className, userLogout, ...rest }) => {
  const onLogout = () => {
    clearLocalStorage()
    userLogout()
  }
  return (
    <MenuItem
      onClick={onLogout}
      {...rest}
      className={classnames(classes.root, className)}
      style={{
        width: 256,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        textTransform: "capitalize",
      }}
    >
      <TurnOffIcon
        style={{
          marginRight: "0.8rem",
        }}
      />
      Logout
    </MenuItem>
  )
}
export default connect(undefined, { userLogout })(
  withStyles(styles)(AppLogoutButton)
)
