import React from "react"
import moment from "moment"
import { Box, Text } from "rebass"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { withStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import {
  styles,
  StyledTableCell,
  StyledTableRow,
  StyledTableSmall,
  StyledTableMedium,
} from "./styles"
const ListUpload = (props) => {
  const { data, translate, classes } = props
  return (
    <Box width={[1]} py={[3]}>
      {data.length > 0 && (
        <Paper
          elevation={3}
          style={{ minHeight: 120, padding: 16, marginBottom: 16 }}
        >
          <Text color={"green"} pb={[3]}>
            {translate("resources.orders.fields.items")}
          </Text>
          <TableContainer component={"div"}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    {translate("resources.po.fields.product")}
                  </StyledTableCell>
                  <StyledTableMedium>
                    {translate("resources.po.fields.code")}
                  </StyledTableMedium>
                  <StyledTableSmall>
                    {translate("resources.po.fields.qty")}
                  </StyledTableSmall>
                  <StyledTableSmall>
                    {translate("resources.po.fields.price")}
                  </StyledTableSmall>
                  <StyledTableSmall>
                    {translate("resources.orders.fields.date")}
                  </StyledTableSmall>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell>
                        <Text>{item.packaging}</Text>
                      </StyledTableCell>
                      <StyledTableMedium>
                        <Text>{item.code}</Text>
                      </StyledTableMedium>
                      <StyledTableSmall>
                        <Text>{item.qty}</Text>
                      </StyledTableSmall>
                      <StyledTableSmall>
                        <Text>{item.price}</Text>
                      </StyledTableSmall>
                      <StyledTableSmall>
                        <Text>
                          {moment(item.created_at).format("DD-MMM-YYYY")}
                        </Text>
                      </StyledTableSmall>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </Box>
  )
}
export default withStyles(styles)(ListUpload)
