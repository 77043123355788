import { get, map, find, uniqBy } from "lodash";
import { getUser } from "../../../utils";
export const checkListPromotions = (data) => {
  const user = getUser();
  const dealerId = get(user, "dealer_id");
  let list = [];
  if (dealerId && data && data.length > 0) {
    map(data, function (o) {
      const isDealer = get(o, "dealer", []);
      if (isDealer && isDealer.length > 0) {
        const isShow = find(isDealer, function (item) {
          return item === dealerId;
        });
        if (isShow) {
          list.push(o);
        }
      }
      return o;
    });
    const uniqList = list.length > 0 ? uniqBy(list, "id") : list;
    return uniqList;
  }

  return list;
};
