import React, { Component } from "react";
import { filter, get, union, uniqBy } from "lodash";

import { Box, Flex, Text } from "rebass";
import { AgGridReact } from "ag-grid-react";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import {
  getTotalQtyFreeProduct,
  getTotalSelectedQtyFreeProduct,
} from "../FreeFieldProduct";

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "../../Expand";
import { SnackBarMessage } from "../../customer";
import { getOldListPromo } from "../HeaderOrdersItem";
const NumberParser = (params) => {
  return Number(params.newValue);
};

class GridFree extends Component {
  constructor(props) {
    super(props);
    const { products, item } = props;
    let listFreeAll = filter(products, function (o) {
      return o.subgroup_id === item.criteria.buy_x_get_y.y.id;
    });
    if (get(item, "criteria.buy_x_get_y.y.type", "") === "ProductCode") {
      listFreeAll = filter(products, function (o) {
        return o.code === item.criteria.buy_x_get_y.y.id;
      });
    } else if (
      get(item, "criteria.buy_x_get_y.y.type", "") === "ProductGroup"
    ) {
      listFreeAll = filter(products, function (o) {
        return o.group_id === item.criteria.buy_x_get_y.y.id;
      });
    } else if (
      get(item, "criteria.buy_x_get_y.y.type", "") === "ShortProductCode"
    ) {
      listFreeAll = filter(products, function (o) {
        return o.short_code === item.criteria.buy_x_get_y.y.id;
      });
    }

    const listPromo = get(props, "formData.items", []);
    const latestListPromo = getOldListPromo(listPromo, props.item);
    if (
      latestListPromo &&
      latestListPromo.listFreeProduct &&
      latestListPromo.listFreeProduct.length > 0
    ) {
      const data = union(latestListPromo.listFreeProduct, listFreeAll);
      listFreeAll = uniqBy(data, "code");
    } else {
      listFreeAll = filter(products, function (o) {
        if (o.subgroup_id === item.criteria.buy_x_get_y.y.id) {
          o.Order = 0;
          o.OrderAmtFree = 0;
          return o.subgroup_id === item.criteria.buy_x_get_y.y.id;
        } else if (o.code === item.criteria.buy_x_get_y.y.id) {
          o.Order = 0;
          o.OrderAmtFree = 0;
          return o.code === item.criteria.buy_x_get_y.y.id;
        } else if (o.group_id === item.criteria.buy_x_get_y.y.id) {
          o.Order = 0;
          o.OrderAmtFree = 0;
          return o.group_id === item.criteria.buy_x_get_y.y.id;
        } else if (o.short_code === item.criteria.buy_x_get_y.y.id) {
          o.Order = 0;
          o.OrderAmtFree = 0;
          return o.short_code === item.criteria.buy_x_get_y.y.id;
        }
        return false;
      });
    }
    this.state = {
      search: "",
      message: "",
      show: false,
      columnDefs: [
        // {
        //   field: "SubProductGroup",
        //   headerName: "Sub Group",
        //   editable: false,
        //   resizable: true,
        // },
        {
          field: "code",
          headerName: "Product Code",
          editable: false,
        },
        {
          field: "packaging",
          headerName: "Product Packaging",
          editable: false,
          resizable: true,
        },
        { field: "dg", editable: false, width: 80 },
        { field: "cr", editable: false, width: 80 },
        {
          field: "moq",
          headerName: "Promo MOQ",
          editable: false,
          width: 120,
        },
        {
          field: "price",
          headerName: "Unit Price",
          editable: false,
          width: 100,
        },
        {
          field: "Order",
          headerName: "Order Qty",
          width: 100,

          valueParser: NumberParser,
        },
        {
          field: "OrderAmtFree",
          headerName: "Order Amt",
          width: 120,
          editable: false,
          valueParser: NumberParser,
        },
        // { field: "Growth", editable: false },
        // { field: "New", headerName: "Recency", editable: false },
        // { field: "Class1", editable: false },
        // { field: "Class2", editable: false },
        // { field: "Class3", editable: false },
        // { field: "Category", editable: false },
        // { field: "GlobalCode", editable: false },
      ],
      myRowData: listFreeAll,
      defaultColDef: {
        flex: 1,
        editable: true,
        sortable: true,
        filter: true,
        cellStyle: {
          "line-height": "40px",
        },
      },
      getRowNodeId: function (data) {
        return data.code;
      },
      totalFreeQty: 0,
      item: props.item,
      expanded: "panelGridFree",
    };
  }

  onGridReady = (params) => {
    // console.log(params)
    this.gridApiFree = params.api;
    this.gridColumnApiFree = params.columnApi;
  };

  onCellChanged(params, locale) {
    locale.onUpdateItem(params);
  }

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  updateItem = (params) => {
    const { listFreeProduct } = this.props;
    let newListFreeProduct = listFreeProduct;
    const values = {
      ...params.data,
    };

    if (newListFreeProduct.length === 0) {
      newListFreeProduct.push(values);
    } else {
      let newList = filter(newListFreeProduct, function (o) {
        return o.code !== params.data.code;
      });
      newListFreeProduct = newList;
      newListFreeProduct.push(values);
    }
    this.props.onUpdateFreeProduct(newListFreeProduct);
  };

  onUpdateItem = (params) => {
    // console.log(params)
    const { item, tPromoOrderQty, listFreeProduct } = this.props;
    const qtySelected = getTotalSelectedQtyFreeProduct(listFreeProduct);
    const qtyTotalFree = getTotalQtyFreeProduct(item, tPromoOrderQty);
    const totalRemaining = qtyTotalFree - qtySelected;
    if (params.data.Order && params.data.Order > 0) {
      if (listFreeProduct.length === 1) {
        if (params.data.Order <= qtyTotalFree) {
          this.updateItem(params);
        } else {
          let message = `Your free item has been fulfilled`;
          if (totalRemaining > 0) {
            message = `You must add less than or equal ${totalRemaining} `;
          } else {
            if (qtyTotalFree === 0) {
              message = `To get a free item you have to select promotion item first`;
            }
          }
          this.setState({
            show: true,
            message: message,
          });
          this.setOrderDefault(params.data.code, params);
        }
      } else {
        if (params.data.Order <= totalRemaining) {
          this.updateItem(params);
        } else {
          if (totalRemaining > 0) {
            this.setState({
              show: true,
              message: `You must add less than or equal ${totalRemaining} `,
            });
          } else {
            let message = `Please calculate your free items`;
            if (qtyTotalFree === 0) {
              message = `To get a free item you have to select promotion item first`;
            } else if (qtyTotalFree === qtySelected) {
              message = `Your free item has been fulfilled`;
            }
            this.setState({
              show: true,
              message: message,
            });
          }
          this.setOrderDefault(params.data.code, params);
        }
      }
    }
  };

  handleChange = (panel) => (event, newExpanded) => {
    this.setState({
      expanded: newExpanded ? panel : false,
    });
  };

  setOrderDefault(code, itemFree) {
    if (code) {
      // console.log(itemFree);
      var rowNode = this.gridApiFree.getRowNode(code);
      rowNode.setDataValue("Order", 0);
      itemFree["Order"] = 0;
      // console.log(itemFree)
      this.updateItem(itemFree);
    }
  }
  render() {
    const { translate, tPromoOrderQty, item, listFreeProduct } = this.props;
    const qtyTotalFree = getTotalQtyFreeProduct(item, tPromoOrderQty);
    const qtySelected = getTotalSelectedQtyFreeProduct(listFreeProduct);

    return (
      <Box width={[1]} pt={[3]}>
        <ExpansionPanel
          expanded={this.state.expanded === "panelGridFree"}
          onChange={this.handleChange("panelGridFree")}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='free_items-content'
            id='free_items-header'
          >
            <Typography>
              {translate("resources.promotions.panel.free_items")} (
              {this.state.myRowData.length}{" "}
              {this.state.myRowData.length > 1
                ? translate("resources.po.listgrid.label.items")
                : translate("resources.po.listgrid.label.item")}
              )
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{
              width: "100%",
              padding: 0,
              paddingTop: 4,
              overflowY: "hidden",
            }}
          >
            <Box width={[1]}>
              <Flex
                width={[1]}
                flexDirection={"row"}
                sx={{
                  "&>div": {
                    p: [1],
                  },
                }}
              >
                <Box>
                  <Text fontSize={[0]}>
                    {translate("resources.po.listgrid.label.entitled")}
                  </Text>
                  <Text color={"red"}>{qtyTotalFree}</Text>
                </Box>
                <Box>
                  <Text fontSize={[0]}>
                    {translate("resources.po.listgrid.label.selected")}
                  </Text>
                  <Text>{qtySelected}</Text>
                </Box>
                <Box>
                  <Text fontSize={[0]}>
                    {translate("resources.po.listgrid.label.remaining")}
                  </Text>
                  <Text style={{ color: "#009C7D" }}>
                    {qtyTotalFree - qtySelected}
                  </Text>
                </Box>
              </Flex>

              <div style={{ width: "100%", height: "100%" }}>
                <div className='grid-wrapper'>
                  <div
                    id='myGridFree'
                    style={{
                      height: "20vh",
                      width: "100%",
                    }}
                    className='ag-theme-balham'
                  >
                    <AgGridReact
                      rowHeight={40}
                      headerHeight={40}
                      rowData={this.state.myRowData}
                      columnDefs={this.state.columnDefs}
                      defaultColDef={this.state.defaultColDef}
                      animateRows={true}
                      getRowNodeId={this.state.getRowNodeId}
                      onGridReady={this.onGridReady}
                      onCellValueChanged={(params) =>
                        this.onCellChanged(params, this)
                      }
                    />
                  </div>
                </div>
              </div>
            </Box>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <SnackBarMessage
          message={this.state.message}
          open={this.state.show}
          severity={"error"}
          handleClose={() => this.handleClose()}
        />
      </Box>
    );
  }
}

export default GridFree;
