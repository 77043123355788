import React, { useState } from "react";
// import { filter, map } from "lodash";
import { filter, map, flatten } from "lodash";
import { Box, Flex, Text } from "rebass";
import { RestApi } from "../../../../restClient";
import Chip from "@material-ui/core/Chip";
import { listColumnItemDealer, listColumnItem } from "./";
import { getListGL } from "../GrowthLossItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { DatePicker } from "antd";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { applyFilter, getMonthList } from "../utils";
import { ROLE_USER } from "../../../../utils";
import { NumberAmount } from "../utils";
import momentTimeZone from "moment-timezone";
const tzNameSg = "Asia/Singapore";
const moment = extendMoment(Moment);
const { RangePicker } = DatePicker;

export default (props) => {
  const {
    permissions,
    countries,
    dealers,
    setList,
    valueCountry,
    setValueCountry,
    valueDealer,
    setValueDealer,
    myfilter,
    setMyFilter,
    setMyData,
    myData,
    isDealer,
    dealerId,
    country,
    setLoading,
  } = props;
  const myLIst =
    dealerId && dealerId !== null ? listColumnItemDealer : listColumnItem;
  const [state, setState] = useState({
    listDealers: dealers,
  });

  const onChangeCountries = (e, newValue) => {
    // console.log("change", newValue);
    setValueCountry([...newValue]);
    const paramsField = map(newValue, function (o) {
      return o.name;
    });
    // setMyFilter({
    //   ...myfilter,
    //   country: paramsField,
    // });
    let newMyFilterObj = myfilter;
    if (valueDealer.length > 0 && newValue.length > 0) {
      const getDealerSelected = map(newValue, function (oCountry) {
        const newDealers = filter(valueDealer, function (oDealer) {
          return oCountry.id === oDealer.country_id;
        });
        return newDealers;
      });

      const newDealerSelected = filter(getDealerSelected, function (element) {
        return element !== undefined;
      });
      const myFlatDealer = flatten(newDealerSelected);
      setValueDealer(myFlatDealer);
      const paramsFieldDealer = map(myFlatDealer, function (o) {
        return o.name;
      });
      newMyFilterObj = {
        ...myfilter,
        country: paramsField,
        dealer: paramsFieldDealer,
      };
      setMyFilter({
        ...myfilter,
        country: paramsField,
        dealer: paramsFieldDealer,
      });
    } else {
      newMyFilterObj = {
        ...myfilter,
        country: paramsField,
      };
      setMyFilter({
        ...myfilter,
        country: paramsField,
      });
    }
    if (newValue.length > 0) {
      // const newParams = { ...myfilter, country: paramsField };
      const newParams = { ...newMyFilterObj, country: paramsField };
      const newFilter = applyFilter(myData.listTemporary, newParams);
      setList(newFilter);
    } else {
      let filter2 = {};
      // const newMyFilter = { ...myfilter, country: [] };
      const newMyFilter = { ...newMyFilterObj, country: [] };
      filter(newMyFilter, function (v, k) {
        const param = v.length > 0 ? { [k]: v } : {};
        filter2 = { ...filter2, ...param };
        return v.length > 0;
      });
      const newFilter = applyFilter(myData.listTemporary, filter2);
      setList(newFilter);
    }
    if (newValue.length > 0) {
      const newParams = map(newValue, function (o) {
        return o.id;
      });
      const newlistDealers = filter(dealers, function (i) {
        return newParams.some(function (f) {
          return i.country_id === f;
        });
      });
      setState({
        ...state,
        listDealers: newlistDealers,
      });
    } else {
      setState({
        ...state,
        listDealers: dealers,
      });
    }
  };
  const onChangeDealers = (e, newValue) => {
    setValueDealer([...newValue]);
    const paramsField = map(newValue, function (o) {
      return o.name;
    });
    setMyFilter({
      ...myfilter,
      dealer: paramsField,
    });
    if (newValue.length > 0) {
      const newParams = { ...myfilter, dealer: paramsField };
      const newFilter = applyFilter(myData.listTemporary, newParams);
      setList(newFilter);
    } else {
      let filter2 = {};
      const newMyFilter = { ...myfilter, dealer: [] };
      filter(newMyFilter, function (v, k) {
        const param = v.length > 0 ? { [k]: v } : {};
        filter2 = { ...filter2, ...param };
        return v.length > 0;
      });
      const newFilter = applyFilter(myData.listTemporary, filter2);
      // console.log(newFilter);
      setList(newFilter);
    }
  };

  const onChangeDate = (v) => {
    let myRangeDate = myData.rangeDate;
    if (v && v !== null) {
      if (v[0] !== null) {
        myRangeDate.start = v[0];
      }
      if (v[1] !== null) {
        myRangeDate.end = v[1];
      }

      if (
        myRangeDate.start &&
        myRangeDate.end &&
        myRangeDate.start !== null &&
        myRangeDate.end !== null
      ) {
        setLoading(true);
        const start = moment(myRangeDate.start);
        const end = moment(myRangeDate.end);
        const myFormatDefault = `ddd MMM DD YYYY 23:59:59`;
        const myTextEndDate = end.format(myFormatDefault);
        const endOfMonthTest = moment(myTextEndDate)
          .endOf("month")
          .format(myFormatDefault);
        const endDateD = momentTimeZone
          .tz(endOfMonthTest, myFormatDefault, `${tzNameSg}`)
          .utc()
          .format(myFormatDefault);
        const paramDealerId = dealerId !== null ? `&dealer_id=${dealerId}` : "";
        RestApi(
          `/reports/growthLoss?start_date=${start.toISOString()}&end_date=${moment(
            endDateD
          ).toISOString()}${paramDealerId}`
        )
          .then((res) => res.json())
          .then((response) => {
            // console.log(response);
            if (response && response.length > 0) {
              const myListDefault = getMonthList({
                start: new Date(myRangeDate.start),
                end: new Date(myRangeDate.end),
              });

              let newColumnList1 = [];
              // myListDefault.map((itemMonth) => {
              //   newColumnList1.push({
              //     field: `amount-${itemMonth}`,
              //     headerName: `Amount ${itemMonth}`,
              //     width: 160,
              //   });
              //   newColumnList1.push({
              //     field: `amount_gap-${itemMonth}`,
              //     headerName: `G/L ${itemMonth}`,
              //     width: 160,
              //   });
              //   return "";
              // });
              myListDefault.map((itemMonth) => {
                newColumnList1.push({
                  field: `amount-${itemMonth}`,
                  headerName: `Amount ${itemMonth}`,
                  width: 160,
                  cellStyle: { "padding-top": "7px", "text-align": "right" },
                  editable: false,
                  resizable: true,
                  valueFormatter: NumberAmount,
                });
                newColumnList1.push({
                  field: `amount-qty-${itemMonth}`,
                  headerName: `Qty ${itemMonth}`,
                  width: 160,
                  cellStyle: { "padding-top": "7px", "text-align": "right" },
                  editable: false,
                  resizable: true,
                  valueFormatter: NumberAmount,
                });
                newColumnList1.push({
                  field: `amount_gap-${itemMonth}`,
                  headerName: `G/L ${itemMonth}`,
                  width: 160,
                  cellStyle: { "padding-top": "7px", "text-align": "right" },
                  editable: false,
                  resizable: true,
                  valueFormatter: NumberAmount,
                });
                newColumnList1.push({
                  field: `amount_gap-qty-${itemMonth}`,
                  headerName: `G/L Qty ${itemMonth}`,
                  width: 160,
                  cellStyle: { "padding-top": "7px", "text-align": "right" },
                  editable: false,
                  resizable: true,
                  valueFormatter: NumberAmount,
                });
                return "";
              });
              const dataResponse = getListGL(response, dealerId);
              const dataList =
                dataResponse && dataResponse.length > 0
                  ? Array.from(
                      new Set(
                        dataResponse.map((p, index) => {
                          return { ...p, id: index };
                        })
                      )
                    )
                  : [];

              let myDataList = dataList;
              if (country && country.id) {
                myDataList = filter(dataList, function (o) {
                  return o.country === country.name;
                });
              }
              // console.log(countries, country);
              setList(myDataList);
              setMyData({
                ...myData,
                listColumn: [...myLIst, ...newColumnList1],
                listTemporary: myDataList,
              });
            }
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
          });
      } else {
        setMyData({
          ...myData,
          rangeDate: myRangeDate,
        });
      }
    } else {
      setMyData({
        ...myData,
        rangeDate: myRangeDate,
      });
    }
  };

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <Box pb={[2]}>
        <Flex flexDirection={"row"}>
          <Text
            pl={[2]}
            fontSize={[0]}
            fontWeight={"bold"}
            width={["138px"]}
            color={"#616161"}
          >
            Date Range
          </Text>
          <Text fontSize={[0]} fontWeight={"bold"} color={"#616161"}>
            Date To
          </Text>
        </Flex>
        <RangePicker
          picker='month'
          onChange={onChangeDate}
          format={"MMM YYYY"}
          value={[myData.rangeDate.start, myData.rangeDate.end]}
        />
      </Box>
      <Flex width={[1]} flexDirection={"row"} flexWrap={"wrap"} pb={[2]}>
        {permissions !== ROLE_USER.country_manager &&
          permissions !== ROLE_USER.dealer && (
            <Box p={[1]} width={["400px"]}>
              {countries && countries.length > 0 && (
                <Autocomplete
                  id='growthloss-countries-fields-filter'
                  options={countries}
                  multiple
                  value={valueCountry}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) =>
                    onChangeCountries(event, newValue)
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant='outlined'
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      variant='filled'
                      label='Country'
                      placeholder='country'
                    />
                  )}
                />
              )}
            </Box>
          )}
        {!isDealer && (
          <Box p={[1]} width={["400px"]}>
            {state.listDealers && state.listDealers.length > 0 && (
              <Autocomplete
                id='growthloss-dealers-fields-filter'
                options={state.listDealers}
                multiple
                value={valueDealer}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => onChangeDealers(event, newValue)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant='outlined'
                      label={option.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    variant='filled'
                    label='Dealer'
                    placeholder='dealer'
                  />
                )}
              />
            )}
          </Box>
        )}
      </Flex>
    </Flex>
  );
};
