import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
import { styles } from "./styles";
import MenuItemLink from "../MenuItemLink";
import { Chart8, ViewIcon, UploadIcon } from "../../icon_svg";
import MenuReport from "./MenuReport";
//DocumentIcon,
const MenuProductsDealer = (props) => {
  const { onMenuClick, ...rest } = props;
  const translate = useTranslate();
  return (
    <Fragment>
      <MenuItemLink
        leftIcon={<Chart8 />}
        to={`/`}
        primaryText={translate(`resources.menus.dashboard`, {
          smart_count: 2,
        })}
        onClick={props.onMenuClick}
        translate={translate}
      />
      <MenuReport onMenuClick={onMenuClick} {...rest} />
      <MenuItemLink
        leftIcon={<ViewIcon />}
        to={`/purchaseOrder`}
        primaryText={translate(`resources.menus.po`, {
          smart_count: 2,
        })}
        onClick={props.onMenuClick}
        translate={translate}
      />
      {/* <MenuItemLink
        to={`/inventories`}
        primaryText={translate(`resources.menus.inventory`, {
          smart_count: 2,
        })}
        leftIcon={<DocumentIcon />}
        onClick={onMenuClick}
        translate={translate}
      /> */}
      <MenuItemLink
        to={`/sellOuts`}
        primaryText={translate(`resources.menus.sellout`, {
          smart_count: 2,
        })}
        leftIcon={<UploadIcon />}
        onClick={onMenuClick}
        translate={translate}
      />
      {/* <MenuItemLink
        to={`/sellout`}
        primaryText={translate(`resources.menus.sellout`, {
          smart_count: 2,
        })}
        leftIcon={<DocumentIcon />}
        onClick={onMenuClick}
        translate={translate}
      /> */}
    </Fragment>
  );
};

export default withStyles(styles)(MenuProductsDealer);
