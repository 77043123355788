import React from "react";
import { Flex, Text, Box } from "rebass";
import { get } from "lodash";
import moment from "moment";
import ProductField from "./ProductField";
export const ProductView = (props) => {
  const { translate, state } = props;
  if (state && state.product_id) {
    const expiry = get(state, "expire_date", "");
    return (
      <Flex width={[1]} flexDirection={"column"} py={[3]}>
        <Flex width={[1]} flexDirection={"row"} sx={{ "&>div": { pr: [3] } }}>
          <Box>
            <Text fontWeight={"bold"} fontSize={[0]} color={"grey"}>
              {translate("resources.sellout.fields.product_packaging")}
            </Text>
            <ProductField record={state} source={"packaging"} />
          </Box>
          <Box>
            <Text fontWeight={"bold"} color={"grey"} fontSize={[0]}>
              {translate("resources.sellout.fields.product_code")}
            </Text>
            <ProductField record={state} source={"code"} />
          </Box>
          <Box>
            <Text fontWeight={"bold"} color={"grey"} fontSize={[0]}>
              {translate("resources.sellout.fields.expiry")}
            </Text>
            <Text>
              {expiry !== null && expiry !== ""
                ? moment(expiry).format("DD-MMM-YYYY")
                : ""}
            </Text>
          </Box>
        </Flex>
      </Flex>
    );
  }
  return <div />;
};
