import React from "react";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Flex } from "rebass";
import { Title } from "../Text";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
  expand: {
    padding: 0,
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

export const CollapseMui = (props) => {
  const {
    label,
    children,
    isLeft,
    defaultExpand,
    style = {},
    isHaveButton,
    isExpired,
  } = props;
  const translate = useTranslate();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(defaultExpand);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  /**isHaveButton={true}
        labelButton={translate("resources.buttons.edit")}
        onShow={onShow} */
  return (
    <Box width={[1]}>
      {isLeft ? (
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          pb={[2]}
          pt={[2]}
          style={style}
        >
          <Box>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label='Show all'
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>
          <Box pl={[2]}>
            <Title fontWeight={"bold"} fontSize={12}>
              {label}{" "}
              {isHaveButton && (
                <Button
                  color='primary'
                  style={{
                    textTransform: "capitalize",
                    padding: 0,
                  }}
                  onClick={() => props.onShow()}
                >
                  {props.labelButton && props.labelButton}
                </Button>
              )}
            </Title>
            {isExpired && (
              <Title fontWeight={"bold"} fontSize={12}>
                {/* <span style={{ color: "#FF0000" }}> */}
                {translate("resources.buttons.message.expired_promotion")}
                {/* </span> */}
                <Button
                  color='primary'
                  style={{
                    textTransform: "capitalize",
                    padding: 0,
                    color: "#FF0000",
                    marginLeft: 12,
                  }}
                  onClick={() => props.onRemoveItem()}
                >
                  {translate("resources.buttons.clear")}
                </Button>
              </Title>
            )}
          </Box>
        </Flex>
      ) : (
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          pb={[2]}
          pt={[2]}
          style={style}
        >
          <Box pr={[2]}>
            <Title fontWeight={"bold"} fontSize={12}>
              {label}
            </Title>
          </Box>
          <Box>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label='Show all'
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        </Flex>
      )}
      <Collapse in={expanded}>{children}</Collapse>
    </Box>
  );
};
