export default {
  notification: {
    title: "Notifikasi",
    please_try_again: "Silahkan coba lagi",
    updated: "Elemen diperbarui",
    add_reward: "Hadiah ditambahkan",
    required: "Wajib",
    no_found: "Tidak diketemukan",
  },
};
