import React from "react";
import { useHistory } from "react-router-dom";
import AppHeader from "./AppHeader";
import { includes } from "lodash";
import { Box, Flex, Image } from "rebass";
import { Layout, Sidebar } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "./Menu";

const DRAWER_WIDTH = 230;
// theme.palette.background.default
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    minHeight: "100vh",
    backgroundColor: "#FFFFFF",
    position: "relative",
  },
  appFrame: {
    display: "flex",
    flexDirection: "column",
    overflowX: "auto",
  },
  contentWithSidebar: {
    display: "flex",
    flexGrow: 1,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 2,
    padding: theme.spacing(3),
    marginTop: "0em",
    paddingLeft: "1rem",
  },
}));
const useStylesLogin = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    minHeight: "100vh",
    backgroundColor: "#FFFFFF",
    position: "relative",
  },
  appFrame: {
    display: "flex",
    flexDirection: "column",
    overflowX: "auto",
    marginTop: 0,
  },
  contentWithSidebar: {
    display: "flex",
    flexGrow: 1,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 2,
    padding: 0,
    marginTop: "0em",
  },
}));

const MySidebar = (props) => {
  const history = useHistory();
  const { location } = history;
  const isShow =
    includes(location.pathname, "/create_password/") ||
    includes(location.pathname, "/reset_password/") ||
    includes(location.pathname, "/forgot_password");

  if (isShow) {
    return <span />;
  }

  return (
    <Box
      style={{
        backgroundColor: "#EFEFEF",
        height: "100%",
        width: DRAWER_WIDTH,
      }}
    >
      <Flex
        width={DRAWER_WIDTH}
        flexDirection={"column"}
        alignItems={"streach"}
      >
        <Box
          style={{
            position: "fixed",
            backgroundColor: "#EFEFEF",
            top: 0,
            width: DRAWER_WIDTH,
            zIndex: 2000,
            flex: 1,
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <Flex
            width={[1]}
            justifyContent={"flex-start"}
            p={[2]}
            alignItems={"center"}
            flexDirection={"column"}
            style={{ height: 180 }}
          >
            <Image src={require("../../assets/images/logo.png")} width={200} />
          </Flex>
        </Box>
        <Box>
          <Sidebar
            {...props}
            size={DRAWER_WIDTH}
            closedSize={DRAWER_WIDTH}
            style={{
              overflowY: "scroll",
              position: "fixed",
              marginTop: "2.5rem",
              height: "100%",
              backgroundColor: "#EFEFEF",
              zIndex: 2001,
            }}
            className={"menu-side-bar"}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default (props) => {
  const history = useHistory();
  const { location } = history;
  const isShow =
    includes(location.pathname, "/create_password/") ||
    includes(location.pathname, "/reset_password/") ||
    includes(location.pathname, "/forgot_password");
  const classes = isShow ? useStylesLogin() : useStyles();

  return (
    <Layout
      {...props}
      classes={classes}
      appBar={AppHeader}
      sidebar={MySidebar}
      menu={Menu}
      style={{ padding: 0 }}
    />
  );
};
