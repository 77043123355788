import MuiExpansionPanel from "@material-ui/core/Accordion"
import MuiExpansionPanelSummary from "@material-ui/core/AccordionSummary"
import MuiExpansionPanelDetails from "@material-ui/core/AccordionDetails"
import { withStyles } from "@material-ui/core/styles"

const ExpansionPanel = withStyles({
  root: {
    width: "100%",
    border: "0px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(0, 0, 0, 0.01)",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    minHeight: 40,
    "&$expanded": {
      minHeight: 40,
    },
  },
  content: {
    margin: "0px 0px",
    fontSize: "bold",
    "&$expanded": {
      margin: "0px 0px",
    },
  },
  expandIcon: {
    padding: 0,
    margin: 0,
  },
  expanded: {},
}))(MuiExpansionPanelSummary)

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    width: "100%",
    overflowY: "auto",
    padding: 0,
  },
}))(MuiExpansionPanelDetails)

export { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary }
