import React, { Fragment } from "react"
import { withStyles } from "@material-ui/core/styles"
import { useTranslate } from "react-admin"
// import { useHistory } from "react-router-dom"
import { styles } from "./styles"
// import SubMenuItem from "./SubMenuItem"
import MenuItemLink from "../MenuItemLink"
import { StarIcon, Chart8 } from "../../icon_svg"
// import { MenuShow } from "./utils"

const MenuOrders = (props) => {
  // const { classes, onMenuClick, modules } = props
  // const [openMenu, setOpenMenu] = useState(false)
  const translate = useTranslate()

  return (
    <Fragment>
      <MenuItemLink
        leftIcon={<Chart8 />}
        to={`/`}
        primaryText={translate(`resources.menus.dashboard`, {
          smart_count: 2,
        })}
        onClick={props.onMenuClick}
        translate={translate}
      />
      <MenuItemLink
        leftIcon={<StarIcon />}
        to={`/orders`}
        primaryText={translate(`resources.menus.orders`, {
          smart_count: 2,
        })}
        onClick={props.onMenuClick}
        translate={translate}
      />
    </Fragment>
    // <SubMenuItem
    //   handleToggle={() => setOpenMenu(!openMenu)}
    //   isOpen={openMenu}
    //   classes={classes}
    //   sidebarIsOpen={openMenu}
    //   name="resources.menus.orders"
    //   icon={<StarIcon />}
    //   location={history.location}
    //   page="/products"
    // >
    //   <MenuItemLink
    //     to={`/orders`}
    //     primaryText={translate(`resources.menus.list`, {
    //       smart_count: 2,
    //     })}
    //     onClick={onMenuClick}
    //   />
    //   <MenuShow
    //     translate={translate}
    //     to={"returns"}
    //     primaryText="resources.menus.returns"
    //     onMenuClick={onMenuClick}
    //     modules={modules}
    //   />
    //   {/* <MenuItemLink
    //     to={`/returns`}
    //     primaryText={translate(`resources.menus.returns`, {
    //       smart_count: 2,
    //     })}
    //     onClick={onMenuClick}
    //   /> */}
    // </SubMenuItem>
  )
}

export default withStyles(styles)(MenuOrders)
