import React from "react";
import { withDataProvider } from "react-admin";
import { Flex, Box, Text } from "rebass";
import { groupBy, map, sumBy, get } from "lodash";
import Moment from "moment";
import numeral from "numeral";
import { extendMoment } from "moment-range";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { RestApi } from "../../../restClient";
import { colorLine } from "../../utils";
const moment = extendMoment(Moment);

export const renderLegend = (props) => {
  const { payload } = props;

  return (
    <Flex width={[1]} flexDirection={"row"} justifyContent={"center"}>
      {payload.map((entry, index) => (
        <Box
          key={`item-${index}`}
          p={[2]}
          px={[4]}
          mx={[2]}
          style={{
            backgroundColor: entry.color,
            color: "#FFFFFF",
            borderRadius: 16,
          }}
        >
          <Text fontSize={[0]} fontWeight={"bold"}>
            {entry.value}
          </Text>
        </Box>
      ))}
    </Flex>
  );
};

export const getMonthList = (myformat) => {
  // const currentYear = moment().add(-2, "year");
  let currentYear = moment();
  // console.log(currentYear.format("M"));
  //note minus 1 year by default
  // currentYear = moment().add(-1, "year");
  if (currentYear.format("M") < 4) {
    currentYear = moment().add(-1, "year");
  }
  const startMonth = moment(currentYear).month(3);
  const endMonth = moment(startMonth).add(11, "month");
  const range = moment.range(startMonth, endMonth);
  const arrayMonth = Array.from(range.by("month"));

  const myMonth = map(arrayMonth, function (o) {
    return moment(o).format(myformat);
  });
  return myMonth;
};

export const getMonthListLY = (myformat) => {
  // const currentYear = moment().add(-2, "year");
  let currentYear = moment();
  // console.log(currentYear.format("M"));
  //note minus 1 year by default
  currentYear = moment().add(-1, "year");
  // if (currentYear.format("M") < 4) {
  //   currentYear = moment().add(-1, "year");
  // }
  const startMonth = moment(currentYear).month(3);
  const endMonth = moment(startMonth).add(11, "month");
  const range = moment.range(startMonth, endMonth);
  const arrayMonth = Array.from(range.by("month"));

  const myMonth = map(arrayMonth, function (o) {
    return moment(o).format(myformat);
  });
  return myMonth;
};

export const getListBySource = (list, tabTitle) => {
  const myTarget = map(getMonthList("MMM YYYY"), function (o) {
    const myTest = list[o];
    if (myTest && myTest.length > 0) {
      // console.log("myTest", sumBy(myTest, source));
      const myTarget = sumBy(myTest, "target");
      // const myLastYear = sumBy(myTest, "last_year");
      if (tabTitle === "Invoiced") {
        const myInvoiced = sumBy(myTest, "invoiced");
        return {
          name: o.substr(0, 3),
          Target: numeral(myTarget).format("0.00") * 1,
          Invoiced: numeral(myInvoiced).format("0.00") * 1,
          // LY: numeral(myLastYear).format("0.00") * 1,
        };
      } else {
        const myPO = sumBy(myTest, "po");
        return {
          name: o.substr(0, 3),
          Target: numeral(myTarget).format("0.00") * 1,
          PO: numeral(myPO).format("0.00") * 1,
          // LY: numeral(myLastYear).format("0.00") * 1,
        };
      }
    } else {
      return {
        name: o.substr(0, 3),
        Target: 0,
        PO: 0,
        // invoice: 0,
        // last_year: 0,
      };
    }
  });

  return myTarget;
};

export const getListBySourceLY = (list, tabTitle) => {
  const myTarget = map(getMonthListLY("MMM YYYY"), function (o) {
    const myTest = list[o];
    if (myTest && myTest.length > 0) {
      // console.log("myTest", sumBy(myTest, source));
      // const myTarget = sumBy(myTest, "target");
      const myLastYear = sumBy(myTest, "last_year");
      if (tabTitle === "Invoiced") {
        // const myInvoiced = sumBy(myTest, "invoiced");
        return {
          name: o.substr(0, 3),
          // Target: numeral(myTarget).format("0.00") * 1,
          // Invoiced: numeral(myInvoiced).format("0.00") * 1,
          LY: numeral(myLastYear).format("0.00") * 1,
        };
      } else {
        // const myPO = sumBy(myTest, "po");
        return {
          name: o.substr(0, 3),
          // Target: numeral(myTarget).format("0.00") * 1,
          // PO: numeral(myPO).format("0.00") * 1,
          LY: numeral(myLastYear).format("0.00") * 1,
        };
      }
    } else {
      return {
        name: o.substr(0, 3),
        // target: 0,
        // po: 0,
        // invoice: 0,
        LY: 0,
      };
    }
  });

  return myTarget;
};

class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      month: getMonthList("MMM"),
      listChart: [],
    };
  }

  onEvent = (eventName, event) => {
    this.setState((prevState) => prevState.events.push(`${eventName}`));
    // console.log(event);
  };
  componentDidMount() {
    // console.log(this.props);
    RestApi(this.props.baseUrl)
      .then((res) => res.json())
      .then((response) => {
        if (response && response.length > 0) {
          const listResponse = groupBy(response, function (item) {
            return moment(item.month).format("MMM YYYY");
          });

          this.setState({
            listChart: getListBySource(listResponse),
          });
        } else {
        }
      });
  }
  render() {
    return (
      <Box width={[1]} py={[3]}>
        <Flex
          width={[1]}
          pt={[4]}
          flexDirection={"column"}
          justifyContent={"center"}
        >
          <LineChart
            width={get(this.props, "width", 800) - 300}
            height={500}
            data={this.state.listChart}
            margin={{
              top: 15,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='name' />
            <YAxis />
            <Tooltip />
            <Legend content={renderLegend} />
            <Line
              type='monotone'
              dataKey='Target'
              stroke={colorLine[3]}
              activeDot={{ r: 8 }}
            />
            <Line type='monotone' dataKey='PO' stroke={colorLine[1]} />
            <Line type='monotone' dataKey='LY' stroke={colorLine[4]} />
          </LineChart>
        </Flex>
      </Box>
    );
  }
}

export default withDataProvider(Container);
