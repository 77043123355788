import React from "react";
import { Show, useTranslate } from "react-admin";
import { Flex, Box } from "rebass";
import { Title, ListButton } from "../../components";
import { ShowFormFields } from "./components";
const ActionForm = (props) => {
  const translate = useTranslate();
  const { pt = 3 } = props;
  return (
    <Flex
      flexDirection={"row"}
      justifyContent={"space-between"}
      width={[1]}
      pt={[pt]}
      pb={[2]}
    >
      <Box width={[1]}>
        <Title fontWeight={"bold"} fontSize={[3]}>
          {translate(props.title)}
        </Title>
      </Box>
      <Flex width={[1]} flexDirection={"row"} justifyContent={"flex-end"}>
        <ListButton basepath={props.basePath} record={props.data} />
      </Flex>
    </Flex>
  );
};
const UserShow = (props) => {
  const { hasList, hasShow, hasEdit, ...rest } = props;
  return (
    <Show
      {...rest}
      actions={<ActionForm title={"resources.users.name"} />}
      component={"div"}
    >
      <ShowFormFields {...rest} />
    </Show>
  );
};

export default UserShow;
