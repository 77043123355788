import React, { useState } from "react";
import { Button, useNotify, useQueryWithStore } from "react-admin";
//useNotify useRefresh
import { Flex } from "rebass";
import { get } from "lodash";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormFieldDealerTarget from "./FormFieldDealerTarget";
import ButtonUploadCsv from "./ButtonUploadCsv";
const payloadDefault = {
  pagination: { page: 1, perPage: 1000 },
  sort: { field: "name", order: "ASC" },
};

const urlPath = "/dealerTargets/import";

const DialogDealerTarget = (props) => {
  const notify = useNotify();
  const { onSubmitUpload, translate } = props;

  const [state, setState] = useState({
    dateYear: "",
    dealer: "",
    itemSelected: {},
  });
  //   const refresh = useRefresh();
  const [showDialog, setShowDialog] = useState(false);
  //   const notify = useNotify();

  const { data } = useQueryWithStore({
    type: "getList",
    resource: "dealers",
    payload: payloadDefault,
  });
  const respCountries = useQueryWithStore({
    type: "getList",
    resource: "countries",
    payload: payloadDefault,
  });

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };
  const handleChange = (e, key, item) => {
    // console.log(key, e.target.value);
    if (key === "dealer") {
      const myItems = get(item, "props.item");
      // console.log(myItems);
      // console.log(key);
      // console.log(key,e.target.value);
      if (myItems) {
        setState({
          ...state,
          dealer: e.target.value,
          itemSelected: myItems,
        });
      }
    } else {
      setState({
        ...state,
        [key]: e.target.value,
      });
    }
  };

  const handleSubmit = (data) => {
    if (data && data.length > 0) {
      onSubmitUpload("dealer", data);
    }
    // if (
    //   state &&
    //   state.dateYear !== "" &&
    //   state.itemSelected &&
    //   state.itemSelected.id
    // ) {
    //   const dataCountries = get(respCountries, "data", []);
    //   const myCountry = find(dataCountries, function (o) {
    //     return o.id === state.itemSelected.country_id;
    //   });
    //   // console.log(myCountry);
    //   const values = [
    //     {
    //       country: myCountry.name,
    //       dealer: state.itemSelected.name,
    //       mc: "Composite Restorative",
    //       target_setting: "everX Posterior",
    //     },
    //   ];
    // onSubmitUpload("dealer", values);
    // }

    setShowDialog(false);
  };
  return (
    <>
      <Button
        onClick={handleClick}
        label='resources.buttons.upload_dealer_target'
        style={{ textTransform: "capitalize" }}
      />
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        maxWidth={"md"}
      >
        <DialogTitle>
          <Flex flexDirection={"row"} justifyContent={"space-between"}>
            <div />
            <IconButton onClick={handleCloseClick}>
              <CloseIcon />
            </IconButton>
          </Flex>
        </DialogTitle>

        <DialogContent>
          <FormFieldDealerTarget
            {...props}
            translate={translate}
            handleChange={handleChange}
            state={state}
            dealers={data}
            countries={get(respCountries, "data", [])}
          />
        </DialogContent>
        <DialogActions>
          <Flex
            width={[1]}
            flexDirection={"row"}
            justifyContent={"center"}
            pb={[3]}
          >
            <ButtonUploadCsv
              translate={translate}
              period={state.dateYear}
              dealer_id={state.itemSelected.id}
              disableClick={
                state.dateYear !== "" &&
                state.itemSelected &&
                state.itemSelected.id
                  ? false
                  : true
              }
              handleSubmit={handleSubmit}
              label='resources.buttons.upload_csv'
              notify={notify}
              urlPath={urlPath}
            />
          </Flex>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogDealerTarget;
