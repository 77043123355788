import React from "react";
import { Flex, Box } from "rebass";
import { useTranslate, Loading } from "react-admin";
import { get } from "lodash";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link } from "react-router-dom";
import { TabPanel, a11yProps } from "./TabsContent";
import Divider from "@material-ui/core/Divider";
import OrderInfoShow from "./OrderInfoShow";
import OrderItemsShow from "./OrderItemsShow";
import ListGoodsReceipts from "./ListGoodsReceipts";
import { STATUSPO } from "../../utils";
import CountryRemarks from "./promotions/CountryRemarks";
import { getHeaderTotal } from "./utils";
import { useQuery } from "react-query";
import { RestApi } from "../../restClient";
const cacheTimeMinute = 1000 * 60 * 3;
const ShowFormFields = (props) => {
  const { match, location, record } = props;
  const translate = useTranslate();
  const { isLoading, data } = useQuery(
    [`purchaseOrders-${record.id}`, record.id],
    () => RestApi(`/purchaseOrders/${record.id}`).then((res) => res.json()),
    { cacheTime: cacheTimeMinute }
  );
  let myIndex = 0;
  if (location && location.pathname.includes("/goodsreceipts")) {
    myIndex = 1;
  }
  const [value, setValue] = React.useState(myIndex);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  if (isLoading) {
    return <Loading />;
  }
  const myHeaderGoodReceipts = getHeaderTotal(data);
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <CountryRemarks {...props} />
      <OrderInfoShow {...props} translate={translate} />
      <Tabs value={value} aria-label='Tabs PO' onChange={handleChange}>
        <Tab
          component={Link}
          to={`${match.url}/orderItem`}
          label={translate("resources.po.tabs.order_items")}
          {...a11yProps(0)}
        />
        {(get(props, "record.status", "") === STATUSPO.processing ||
          get(props, "record.status", "") === STATUSPO.partial ||
          get(props, "record.status", "") === STATUSPO.received) && (
          <Tab
            component={Link}
            to={`${match.url}/goodsreceipts`}
            label={translate("resources.po.tabs.good_receipts")}
            {...a11yProps(1)}
          />
        )}
      </Tabs>
      <Divider />
      <TabPanel value={value} index={0}>
        <Box width={[1]}>
          <OrderItemsShow {...props} translate={translate} />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box width={[1]}>
          <ListGoodsReceipts
            {...props}
            translate={translate}
            myHeaderGoodReceipts={myHeaderGoodReceipts}
          />
        </Box>
      </TabPanel>
    </Flex>
  );
};

export default ShowFormFields;
