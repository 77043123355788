import React, { useState } from "react";
import { useNotify, useTranslate, Notification } from "react-admin";
import { useTheme } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { Flex, Image, Text } from "rebass";
import Card from "@material-ui/core/Card";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Field } from "react-final-form";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import SuccessFields from "./SuccessFields";
import { loginClientPwd } from "../restClient";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(2),
  },
  withoutLabel: {
    marginBottom: theme.spacing(3),
  },
  textField: {
    width: "320px",
  },
}));

/***/

const ResetPassword = (props) => {
  const theme = useTheme();
  const history = useHistory();
  const matchParams = useParams();
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [visible, setVisible] = useState({
    password: false,
    confirm_password: false,
  });

  const handleClickShowPassword = (k) => {
    setVisible({ ...visible, [k]: !visible[k] });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const onSubmit = (v) => {
    if (
      v &&
      v.password &&
      v.confirm_password &&
      matchParams &&
      matchParams.mytoken &&
      matchParams.mytoken !== ""
    ) {
      const params = { token: matchParams.mytoken, password: v.password };
      setLoading(true);
      loginClientPwd("createPassword", JSON.stringify(params))
        .then((response) => response.json())
        .then((response) => {
          if (response && response.status && response.status === "OK") {
            setShow(true);
          } else {
            notify("resources.accounts.message.try_again", "warning");
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          console.error(e);
        });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Flex
        width={[1]}
        bg={theme.palette.primary[500]}
        style={{ height: "100vh" }}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Card
          style={{
            width: 550,
            minHeight: 350,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 16,
            borderRadius: 16,
          }}
        >
          <Image
            src={require("../assets/images/logo.png")}
            width={200}
            mb={[3]}
          />
          <Text
            fontWeight={"bold"}
            fontSize={[3]}
            py={[2]}
            color={"rgba(0,0,0,0.6)"}
          >
            {translate("resources.accounts.reset_password")}
          </Text>
          {!show ? (
            <Form
              onSubmit={onSubmit}
              validate={(values) => {
                const errors = {};
                if (!values.password) {
                  errors.password = "Required";
                }
                if (!values.confirm_password) {
                  errors.confirm_password = "Required";
                } else if (values.confirm_password !== values.password) {
                  errors.confirm_password = "Must match";
                }
                return errors;
              }}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Flex
                    width={[1]}
                    flexDirection={"column"}
                    alignItems={"center"}
                  >
                    <Field name='password'>
                      {({ input, meta }) => (
                        <FormControl
                          className={clsx(classes.margin, classes.textField)}
                          variant='outlined'
                        >
                          <InputLabel htmlFor='password'>
                            {translate("resources.accounts.fields.password")}
                          </InputLabel>
                          <OutlinedInput
                            id='password'
                            type={visible.password ? "text" : "password"}
                            {...input}
                            labelWidth={86}
                            aria-describedby='component-error-text-password'
                            endAdornment={
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='toggle password visibility'
                                  onClick={() =>
                                    handleClickShowPassword("password")
                                  }
                                  onMouseDown={handleMouseDownPassword}
                                  edge='end'
                                >
                                  {visible.password ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          <FormHelperText id='component-error-text-password'>
                            {meta.touched && meta.error && (
                              <span style={{ color: "#FF0000" }}>
                                {meta.error}
                              </span>
                            )}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Field>
                    <Field name='confirm_password'>
                      {({ input, meta }) => (
                        <FormControl
                          className={clsx(classes.margin, classes.textField)}
                          variant='outlined'
                        >
                          <InputLabel htmlFor='confirm_password'>
                            {translate(
                              "resources.accounts.fields.confirm_password"
                            )}
                          </InputLabel>
                          <OutlinedInput
                            id='confirm_password'
                            type={
                              visible.confirm_password ? "text" : "password"
                            }
                            {...input}
                            labelWidth={170}
                            aria-describedby='component-error-text-confirm_password'
                            endAdornment={
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='toggle confirm password visibility'
                                  onClick={() =>
                                    handleClickShowPassword("confirm_password")
                                  }
                                  onMouseDown={handleMouseDownPassword}
                                  edge='end'
                                >
                                  {visible.confirm_password ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          <FormHelperText id='component-error-text-confirm_password'>
                            {meta.touched && meta.error && (
                              <span style={{ color: "#FF0000" }}>
                                {meta.error}
                              </span>
                            )}
                            {/* translate("resources.login.error_username")} */}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </Field>
                    <Button
                      variant='contained'
                      color='primary'
                      size='large'
                      type='submit'
                      style={{
                        minWidth: 180,
                        textTransform: "capitalize",
                        borderRadius: 24,
                      }}
                      startIcon={
                        loading ? (
                          <CircularProgress size={16} color='secondary' />
                        ) : null
                      }
                    >
                      {translate("resources.buttons.save")}
                    </Button>
                  </Flex>
                </form>
              )}
            />
          ) : (
            <SuccessFields
              history={history}
              translate={translate}
              label={translate(
                "resources.accounts.message.succes_reset_password"
              )}
            />
          )}
        </Card>
        <Notification />
      </Flex>
    </ThemeProvider>
  );
};

ResetPassword.propTypes = {
  authClient: PropTypes.func,
  previousRoute: PropTypes.string,
  theme: PropTypes.object.isRequired,
};

ResetPassword.defaultProps = {
  theme: {},
};

export default ResetPassword;
