import React from "react";
import moment from "moment";
import { get } from "lodash";

import {
  TextField,
  useTranslate,
  Filter,
  SearchInput,
  ReferenceInput,
  SelectInput,
  //   AutocompleteInput,
  // ReferenceField,
} from "react-admin";
import NumberFormat from "react-number-format";
import { Flex, Box } from "rebass";
import {
  BetterList,
  Title,
  // CreatePoButton,
  ButtonShowEditView,
} from "../components";
import { getUser, showDealer, showCountryManager } from "../utils";
const AmountField = ({ record, source }) => {
  const total = get(record, source, 0);
  return (
    <div style={{ textAlign: "right", width: 100 }}>
      <NumberFormat
        value={total}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$ "}
        decimalScale={2}
      />
    </div>
  );
};
// const UserField = ({ record, ...rest }) => {
//   console.log(rest);
//   return <span>{`${record.country_id}  `}</span>;
// };

const POFilter = (props) => {
  const { permissions } = props;
  const user = getUser();
  const countryId = get(user, "country_id");
  const translate = useTranslate();
  const countryManagerFilter = showCountryManager(permissions)
    ? { country_id: countryId }
    : {};

  return (
    <Box width={[1]} pb={[2]}>
      <Box width={[1]} pt={[2]}>
        <Title fontWeight={"bold"} fontSize={[3]}>
          {translate(`resources.po.title`)}
        </Title>
      </Box>
      <Flex width={[1]} flexDirection={"row"} justifyContent={"flex-end"}>
        <Filter {...props}>
          <ReferenceInput
            label='resources.orders.fields.dealer'
            source='dealer'
            reference='dealers'
            alwaysOn
            filter={countryManagerFilter}
            // filterToQuery={(searchText) => ({ name: searchText })}
            sort={{ field: "id", order: "ASC" }}
            style={{ width: 400 }}
            perPage={100}
          >
            <SelectInput optionText='name' />
          </ReferenceInput>
          <SearchInput source='q' alwaysOn style={{ width: 256 }} />
        </Filter>
      </Flex>
    </Box>
  );
};
const POAction = ({ basePath }) => {
  return (
    <Flex
      pt={[2]}
      pl={[2]}
      pr={[2]}
      flexDirection={"row"}
      style={{ position: "fixed", right: 16 }}
    >
      <Flex flexDirection={"row"} justifyContent={"flex-end"}>
        {/* <CreatePoButton
          basePath={"/orders"}
          sizeIcon={"xsmall"}
          label={"resources.buttons.create_po"}
          style={{ textTransform: "capitalize" }}
        /> */}
      </Flex>
    </Flex>
  );
};

const DateCustomField = ({ record, source, format = "DD-MMMM-YYYY" }) => {
  const mydate = get(record, source, "");
  if (mydate === "") {
    return <span />;
  }

  return <span>{moment(mydate).format(format)}</span>;
};

const PurchaseOrderList = (props) => {
  const { permissions } = props;
  const user = getUser();
  const dealerID = get(user, "dealer_id");
  const dealerFilter =
    showDealer(permissions) && dealerID && dealerID !== "0"
      ? { dealer: dealerID }
      : {};

  return (
    <BetterList
      {...props}
      actions={<POAction />}
      // rowclick={"show"}
      filters={<POFilter permissions={permissions} />}
      filter={dealerFilter}
      empty={false}
    >
      <TextField
        source='purchase_order_number'
        label={"resources.po.fields.gc_ref"}
      />
      <TextField source='ref' label={"resources.po.fields.my_ref"} />
      <TextField source='status' label={"resources.po.fields.status"} />
      <AmountField source='total' label={"resources.po.fields.amount"} />
      <DateCustomField
        source='created_at'
        label='resources.po.fields.created_on'
        format={"DD-MMM-YYYY"}
      />
      <ButtonShowEditView {...props} />
    </BetterList>
  );
};
export default PurchaseOrderList;
