import React, { useState } from "react";
import { Box, Flex, Text } from "rebass";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { DatePicker } from "antd";
import { reportTypeValue, statusList } from "./utils";
const { RangePicker } = DatePicker;

const Header = (props) => {
  const { reportType, dateRange, setValue, state, translate } = props;
  const [startTimeV, setStartTimeV] = useState(state.start_time);
  const [endTimeV, setEndTimeV] = useState(state.end_time);

  const onChangeRT = (e) => {
    setValue("valueRT", e.target.value);
  };
  const onChangeStatus = (e) => {
    setValue("valueStatus", e.target.value);
  };
  const onChangeDateRange = (e) => {
    setValue("dateRange", e.target.value);
  };
  const onChangeDateTime = (v) => {
    if (v && v !== null) {
      if (v[0] !== null) {
        setValue("start_time", v[0]);
        setStartTimeV(v[0]);
      }
      if (v[1] !== null) {
        setValue("end_time", v[1]);
        setEndTimeV(v[1]);
      }
    }
  };
  return (
    <Box width={[1]}>
      <Flex width={[1]} flexDirection={"row"} flexWrap={"wrap"} pb={[2]}>
        <Box p={[1]}>
          <FormControl>
            <InputLabel
              id='gc-rt-select-label'
              shrink={true}
              style={{ marginBottom: 20 }}
            >
              Report Type
            </InputLabel>
            <Select
              labelId='gc-rt-select-label'
              id='gc-rt-select'
              value={state.valueRT}
              onChange={onChangeRT}
              style={{ marginTop: 27, width: 280 }}
            >
              {reportType &&
                reportType.map((item, index) => {
                  return (
                    <MenuItem value={item.id} key={index}>
                      {item.text}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Box>
        {state.valueRT !== reportTypeValue.id &&
          state.valueRT !== reportTypeValue.is && (
            <Box p={[1]}>
              <FormControl>
                <InputLabel
                  id='dealer-status-select-label'
                  shrink={true}
                  style={{ marginBottom: 20 }}
                >
                  Status
                </InputLabel>
                <Select
                  labelId='dealer-status-select-label'
                  id='dealer-status-select'
                  value={state.valueStatus}
                  onChange={onChangeStatus}
                  style={{ marginTop: 27 }}
                >
                  {statusList &&
                    statusList.map((item, index) => {
                      return (
                        <MenuItem value={item.id} key={index}>
                          {item.text}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
          )}
        <Box p={[1]}>
          <FormControl>
            <InputLabel
              id='dealer-dateRange-select-label'
              shrink={true}
              style={{ marginBottom: 20 }}
            >
              Date Range
            </InputLabel>
            <Select
              labelId='dealer-dateRange-select-label'
              id='dealer-dateRange-select'
              value={state.dateRange}
              onChange={onChangeDateRange}
              style={{ marginTop: 27, width: 100 }}
            >
              {dateRange &&
                dateRange.map((item, index) => {
                  return (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>

          {state.dateRange === "Custom" && (
            <Box pb={[2]} pt={[2]}>
              <Flex flexDirection={"row"}>
                <Text
                  pl={[2]}
                  fontSize={[0]}
                  fontWeight={"bold"}
                  width={["138px"]}
                  color={"#616161"}
                >
                  {translate("resources.promotions.fields.start_date")}
                </Text>
                <Text fontSize={[0]} fontWeight={"bold"} color={"#616161"}>
                  {translate("resources.promotions.fields.end_date")}
                </Text>
              </Flex>
              <RangePicker
                format={"DD-MMM-YYYY"}
                onChange={(v) => onChangeDateTime(v)}
                value={[startTimeV, endTimeV]}
              />
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default Header;
