import React from "react";
import { Route } from "react-router-dom";
import {
  PromotionsList,
  CreateCustomerPromotion,
  EditCustomerPromotion,
} from "./promotions";
import { OrdersList, UploadOtd } from "./orders";
import { PriceBookList } from "./pricebook";
import { CreatePassword, ResetPassword, ForgotPassword } from "./auth";
import { ListReport } from "./screens/reports";
export default [
  <Route exact path='/orders/otd' render={() => <OrdersList />} />,
  <Route exact path='/orders/analytics' render={() => <OrdersList />} />,
  <Route exact path='/orders/otd/upload' render={() => <UploadOtd />} />,
  <Route exact path='/priceBooks/customer' render={() => <PriceBookList />} />,
  <Route
    exact
    path='/promotions/customers'
    render={() => <PromotionsList />}
  />,
  <Route
    exact
    path='/promotions/customers/create'
    render={() => <CreateCustomerPromotion />}
  />,
  <Route
    exact
    path='/promotions/customers/:id'
    render={() => <EditCustomerPromotion />}
  />,
  <Route exact path='/forgot_password' render={() => <ForgotPassword />} />,
  <Route
    exact
    path='/reset_password/:mytoken'
    render={() => <ResetPassword />}
  />,

  <Route
    exact
    path='/create_password/:token'
    render={() => <CreatePassword />}
  />,
  <Route exact path='/report' render={(props) => <ListReport {...props} />} />,
];
