import React, { Fragment, useState, useEffect } from "react";
import { keyBy } from "lodash";
import { Box, Flex, Text } from "rebass";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {
  useQueryWithStore,
  Loading,
  TextField,
  useTranslate,
} from "react-admin";
import TextFieldName from "@material-ui/core/TextField";
import { Pagination, Datagrid, ActiveField } from "../../components";
import { dataType } from "./utils";
import { EditSettings } from "./settings";

const ButtonField = ({ record, ...rest }) => {
  return (
    <Flex flexDirection={"row"} justifyContent='flex-end'>
      <EditSettings {...rest} record={record} />
    </Flex>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 0,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: 0,
    minWidth: 200,
  },
}));

const ListSettings = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isRequest, setIsRequest] = useState(false);
  let filters =
    type === "" ? { search: searchText } : { search: searchText, type: type };
  const { data, total, loading } = useQueryWithStore({
    type: "getList",
    resource: "codes",
    payload: {
      pagination: { page, perPage },
      sort: { field: "id", order: "ASC" },
      filter: filters,
    },
  });
  const handleChange = (name) => (event) => {
    if (name === "search") {
      setSearch(event.target.value);
    } else if (name === "type") {
      setType(event.target.value);
    }
    setIsRequest(true);
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (isRequest) {
        // console.log("masuk");
        setSearchText(search);
        setIsRequest(false);
      }
    }, 500);
    return () => clearTimeout(timeout);
  });

  if (loading) {
    return (
      <Flex
        width={[1]}
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
        style={{ height: "60vh" }}
      >
        <Loading />
      </Flex>
    );
  }
  return (
    <Box width={[1]} py={[2]}>
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        pb={[3]}
      >
        <Box pr={[2]}>
          <Text fontSize={["10px"]} fontWeight='bold' pt={["12px"]}>
            {translate("resources.settings.fields.type")}
          </Text>
          <Select
            name={"type"}
            value={type}
            onChange={handleChange("type")}
            className={classes.selectEmpty}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {dataType.map((i) => (
              <MenuItem key={i.id} value={i.id}>
                {i.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <TextFieldName
          label='search'
          margin='dense'
          variant='filled'
          value={search}
          onChange={handleChange("search")}
          InputLabelProps={{ shrink: true }}
          style={{ width: 200 }}
        />
      </Flex>
      {data && data.length > 0 && (
        <Fragment>
          <Datagrid
            data={keyBy(data, "id")}
            ids={data.map(({ id }) => id)}
            currentSort={{ field: "id", order: "ASC" }}
            basePath='/codes' // required only if you set use "rowClick"
            // rowClick="edit"
          >
            <TextField label={"resources.settings.fields.type"} source='type' />
            <TextField
              label={"resources.settings.fields.label"}
              source='label'
            />
            <TextField label={"resources.settings.fields.code"} source='code' />
            <ActiveField
              label={"resources.settings.fields.active"}
              source='active'
            />

            <ButtonField {...props} />
          </Datagrid>
          <Pagination
            page={page}
            perPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            setPage={(v) => setPage(v)}
            setPerPage={(v) => setPerPage(v)}
            total={
              total === null
                ? data && data.length > 0
                  ? data.length
                  : 0
                : total
            }
          />
        </Fragment>
      )}
    </Box>
  );
};

export default ListSettings;
