import React from "react";
import { Loading } from "react-admin";
//useQueryWithStore,, get, sumBy
import moment from "moment";
import { chain } from "lodash";
import { Flex, Box, Text } from "rebass";
import RowListGoodsReceipt from "./RowListGoodsReceipt";
import EmptyGoodsReceipts from "./EmptyGoodsReceipts";
import DialogGoodsReceipts from "./DialogGoodsReceipts";
import Paper from "@material-ui/core/Paper";
import NumberFormat from "react-number-format";
import numeral from "numeral";
import { TextRecord } from "../../components";
import { useQuery } from "../../components/hooks";
function currencyFormatter(value) {
  return numeral(value * 1).format("0,0.00"); // "\xA3" + formatNumber(params.value);
}
const occurrenceDay = (occurrence) => {
  return moment(occurrence.created_at).startOf("day").format("DD-MMM-YYYY");
};

const groupToDay = (group, day) => {
  return {
    day: day,
    group: group,
  };
};
const ListGoodsReceipts = (props) => {
  const { translate, record, myHeaderGoodReceipts } = props;
  const { data, loading } = useQuery(
    `/goodsReceipts?_order=${encodeURIComponent(
      "ASC"
    )}&_sort=${encodeURIComponent("id")}&purchase_order=${encodeURIComponent(
      record.id
    )}`
  );

  if (loading) {
    return (
      <Flex
        width={[1]}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        style={{ height: "50vh" }}
      >
        <Loading />
      </Flex>
    );
  }

  const result = chain(data)
    .groupBy(occurrenceDay)
    .map(groupToDay)
    .sortBy("day")
    .value();
  // if (result.length === 0) {
  //   return (
  //     <EmptyGoodsReceipts translate={translate} {...props} result={data} />
  //   );
  // }

  // const myTotal = get(record, "total", 0) * 1;
  // const ttlBackOrder = totalBackorder * 1;
  // const myTotalBackOrder =
  //   totalBackorder * 1 < 0 ? myTotal + ttlBackOrder : totalBackorder;

  return (
    <Box width={[1]}>
      <Box width={[1]} py={[2]}>
        <Paper elevation={3}>
          <Flex width={[1]} p={[3]} flexDirection={"row"}>
            <Box width={[1]}>
              <Flex
                width={[1]}
                py={[2]}
                flexDirection={"row"}
                sx={{
                  "&>div": {
                    pr: [3],
                  },
                }}
              >
                <Box>
                  <Text fontWeight={"bold"} fontSize={[0]} pb={[1]}>
                    {translate("resources.po.fields.total_ordered")}
                  </Text>
                  <Text>{myHeaderGoodReceipts.ordered}</Text>
                </Box>
                <Box>
                  <Text fontWeight={"bold"} fontSize={[0]} pb={[1]}>
                    {translate("resources.po.fields.total_received")}
                  </Text>
                  <Text>{myHeaderGoodReceipts.received}</Text>
                </Box>
                <Box>
                  <Text fontWeight={"bold"} fontSize={[0]} pb={[1]}>
                    {translate("resources.po.fields.total_balance")}
                  </Text>
                  <Text>{myHeaderGoodReceipts.backorder}</Text>
                </Box>
              </Flex>
              <Flex
                width={[1]}
                py={[2]}
                flexDirection={"row"}
                flexWrap={"wrap"}
                sx={{
                  "&>div": {
                    pr: [3],
                  },
                }}
              >
                <TextRecord
                  source={"total"}
                  color={"#000000"}
                  label={"resources.po.fields.total_ordered"}
                  record={record}
                  isCurrency={true}
                />
                <Box>
                  <Text fontWeight={"bold"} fontSize={[0]} pb={[1]}>
                    {translate("resources.po.fields.total_received")}
                  </Text>
                  <Text>
                    <NumberFormat
                      value={currencyFormatter(
                        myHeaderGoodReceipts.totalReceived
                      )}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      prefix={"$"}
                    />
                  </Text>
                </Box>
                <Box>
                  <Text fontWeight={"bold"} fontSize={[0]} pb={[1]}>
                    {translate("resources.po.fields.total_balance")}
                  </Text>
                  <Text>
                    <NumberFormat
                      value={currencyFormatter(
                        myHeaderGoodReceipts.totalBackorder
                      )}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      prefix={"$"}
                    />
                  </Text>
                </Box>
              </Flex>
            </Box>

            <Flex
              width={["300px"]}
              p={[3]}
              flexDirection={"row"}
              justifyContent={"flex-end"}
              alignItems={"flex-end"}
            >
              <DialogGoodsReceipts {...props} result={data} />
            </Flex>
          </Flex>
        </Paper>
      </Box>
      {result && result.length > 0 ? (
        result.map((item, index) => {
          return (
            <Box width={[1]} key={index}>
              <RowListGoodsReceipt data={item} {...props} />
            </Box>
          );
        })
      ) : (
        <EmptyGoodsReceipts translate={translate} {...props} result={data} />
      )}
    </Box>
  );
};

export default ListGoodsReceipts;
