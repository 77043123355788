import moment from "moment";
import { isShowing } from "./settings";
export {
  choicesLanguages,
  languages,
  languagesListSiteId,
  isDealers,
  showDashboard,
  showAdminSales,
  getUser,
  getOnlyToken,
  showApprove,
  showAdmin,
  showDealer,
  showEditProduct,
  showToken,
  showOAAssociate,
  showCountryManager,
} from "./localeConfig";

/**
 * 
 Dealer, GC OA Associate, GC Sales Associate, GC Sales Manager, Admin
 */

/**
  * const dataRole = [
  { name: "Dealers", id: "Dealers" },
  { name: "GC OA Associates", id: "GC OA Associates" },
  { name: "GC Sales Associate", id: "GC Sales Associate" },
  { name: "GC Sales Manager", id: "GC Sales Manager" },
  { name: "Admin", id: "Admin" },
  { name: "GC Country Manager", id: "GC Country Manager" },
];
  */

export const ROLE_USER = {
  super_admin: "Super Admin",
  dealer: "Dealers",
  customer: "Customer",
  admin: "Admin",
  gc_associate: "GC Associates",
  gc_oa_associate: "GC OA Associates",
  gc_sales_associate: "GC Sales Associate",
  gc_sales_manager: "GC Sales Manager",
  country_manager: "GC Country Manager",
};

const escapeRegex = /([[\].#*$><+~=|^:(),"'`\s])/g;
let classCounter = 0;
export const generateClassName = (rule, styleSheet) => {
  classCounter += 1;

  if (process.env.NODE_ENV === "production") {
    return `c${classCounter}`;
  }

  if (styleSheet && styleSheet.options.classNamePrefix) {
    let prefix = styleSheet.options.classNamePrefix;
    // Sanitize the string as will be used to prefix the generated class name.
    prefix = prefix.replace(escapeRegex, "-");

    if (prefix.match(/^Mui/)) {
      return `${prefix}-${rule.key}`;
    }

    return `${prefix}-${rule.key}-${classCounter}`;
  }

  return `${rule.key}-${classCounter}`;
};

export const formatDate = (v) => {
  return moment(v).toISOString();
};

export const STATUSPO = {
  draft: "Draft",
  sent: "Submitted",
  processing: "Processing",
  partial: "Partial Received",
  received: "Completed",
  posted: "Processing",
};
export const STATUSPOLIST = [
  "All",
  "Completed",
  "Draft",
  "Submitted",
  "Partial Received",
  "Processing",
];
export const STATUSPOMESSAGE = {
  Draft: "New PO created by Dealers but not yet submitted to GC.",
  Submitted: "PO submitted to GC. Dealer cannot edit PO anymore. ",
  "Partial Received": "Dealer has received partial of all the items in the PO.",
  Completed:
    "Dealer has received all the items in the PO of the order status in order summary.",
  Processing: "PO is posted to GP (exported as Excel file).",
};
// export const STATUSPO = {
//   draft: "Draft",
//   sent: "Sent",
//   processing: "Processing",
//   partial: "Partial",
//   received: "Received",
//   posted: "Posted",
// };

export { isShowing };
