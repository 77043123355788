export default {
  users: {
    name: "Users",
    new_user: "New User",
    edit_user: "Edit User",
    fields: {
      first_name: "Nama Depan",
      last_name: "Nama Belakang",
      email: "Email",
      name: "Nama",
      mobile: "No Telepon",
      role: "Wewenang",
      status: "Status",
      created_at: "Dibuat pada",
      active: "Active",
      dealer: "Dealer",
      custom: {
        merchant: "Pedagang",
      },
      loyalty_program_name: "Nama Program Loyalitas",
    },
  },
};
