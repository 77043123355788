import React, { useState } from "react";
import { filter, orderBy, map, uniq } from "lodash";
import {
  TextField,
  useTranslate,
  SelectInput,
  Filter,
  Pagination,
  Loading,
  SearchInput,
  useNotify,
  useDataProvider,
} from "react-admin";
import { Flex, Box } from "rebass";
import {
  BetterList,
  ButtonEditView,
  CreateButton,
  Title,
  ActiveField,
  useQuery,
} from "../components";
import { makeStyles } from "@material-ui/core/styles";
import { ROLE_USER, showEditProduct } from "../utils";
import { ButtonUploadCsv } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "&>div:nth-child(3)": {
      display: "flex",
      justifyContent: "flex-end",
      flex: 1,
    },
  },
}));

const ProductFilter = (props) => {
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const notify = useNotify();
  const { permissions, basePath } = props;
  const listProductGroup1 = filter(props.listcode, function (o) {
    return o.type === "ProductGroup";
  });
  const listProductGroup = orderBy(
    listProductGroup1,
    [(product) => product.label.toLowerCase()],
    ["asc"]
  );
  const listCategoryA = filter(props.listcode, function (o) {
    return o.type === "Category";
  });
  const listCategory = orderBy(
    listCategoryA,
    [(product) => product.label.toLowerCase()],
    ["asc"]
  );

  const [state, setState] = useState({
    listproductGroups: listProductGroup,
  });
  const onChangeCategories = (v) => {
    if (v !== "") {
      dataProvider
        .getList("products", {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: "id", order: "ASC" },
          filter: { category_id: v },
        })
        .then(({ data }) => {
          // console.log(data);
          if (data && data.length > 0) {
            const newParams = map(data, function (o) {
              return o.group_id;
            });
            const groupId = uniq(newParams);
            if (groupId && groupId.length > 0) {
              const listProductGroup = filter(props.listcode, function (i) {
                return groupId.some(function (f) {
                  return i.id === f;
                });
              });
              setState({
                listproductGroups: listProductGroup,
              });
            }
          }
        })
        .catch((error) => {});
    } else {
      setState({
        listproductGroups: listProductGroup,
      });
    }
  };
  return (
    <Box width={[1]} pb={[3]}>
      <Flex
        pt={[2]}
        px={[3]}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Box width={[1]} pt={[2]}>
          <Title fontWeight={"bold"} fontSize={[3]}>
            {translate(`resources.products.name`)}
          </Title>
        </Box>
        <Flex flexDirection={"row"} justifyContent={"flex-end"}>
          {permissions !== ROLE_USER.dealer && (
            <CreateButton
              basePath={basePath}
              sizeIcon={"xsmall"}
              style={{ textTransform: "capitalize", marginRight: 16 }}
            />
          )}

          {permissions !== ROLE_USER.dealer && (
            <ButtonUploadCsv
              label={"resources.buttons.upload_product"}
              translate={translate}
              urlPath={"/products/import"}
              notify={notify}
            />
          )}
        </Flex>
      </Flex>

      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={"space-between"}
        px={[3]}
      >
        <Filter {...props} className={classes.root}>
          <SelectInput
            optionText='label'
            label='resources.products.fields.category'
            choices={listCategory}
            source='category_id'
            alwaysOn
            style={{ width: 280 }}
            onChange={(e) => onChangeCategories(e.target.value)}
          />
          <SelectInput
            optionText='label'
            label='resources.products.fields.group'
            choices={state.listproductGroups}
            source='group_id'
            alwaysOn
            style={{ width: 280 }}
          />

          <SearchInput
            source='search'
            alwaysOn
            style={{
              width: 256,
            }}
          />
        </Filter>
      </Flex>
    </Box>
  );
};
const ProductAction = ({ basePath, permissions }) => {
  // console.log(permissions)
  return <Flex flexDirection={"row"}></Flex>;
};

const ProductList = (props) => {
  const { permissions, hasList, hasEdit, hasCreate, hasShow, ...rest } = props;
  const { data, loading } = useQuery(
    `/codes?_order=${encodeURIComponent(
      "ASC"
    )}&active=true&_sort=${encodeURIComponent("label")}`
  );
  if (loading) {
    return <Loading />;
  }
  return (
    <BetterList
      {...props}
      actions={<ProductAction permissions={permissions} />}
      // rowclick={"edit"}
      perPage={15}
      filters={
        <ProductFilter
          permissions={permissions}
          {...rest}
          listcode={data && data.length > 0 ? data : []}
        />
      }
      pagination={<Pagination rowsPerPageOptions={[15, 20, 25, 30, 50]} />}
      sort={{ field: "packaging", order: "ASC" }}
    >
      <TextField
        source='packaging'
        label={"resources.products.fields.product_name"}
      />
      <TextField
        source='code'
        label={"resources.products.fields.product_code"}
      />
      <TextField source='moq' label={"resources.products.fields.moq"} />
      <ActiveField
        source='active'
        label={"resources.products.fields.active"}
        style={{ width: 100 }}
      />
      {showEditProduct(permissions) && <ButtonEditView {...props} />}
    </BetterList>
  );
};
export default ProductList;
