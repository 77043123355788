import React, { useState } from "react";
import {
  SaveButton,
  useCreate,
  useNotify,
  FormWithRedirect,
  useTranslate,
  useRefresh,
} from "react-admin";
import { Flex, Text } from "rebass";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import FormFieldCountries from "./FormFieldCountries";
import { useWindowSize } from "../../../components";
import CloseIcon from "@material-ui/icons/Close";

const initialValues = {
  po_additional_charges: {
    packing_charge: {
      active: false,
      less_than_10k_sgd: 0,
      greater_than_equals_10k_sgd: 0,
      less_than_10k_usd: 0,
      greater_than_equals_10k_usd: 0,
    },
    gst: {
      active: false,
      percent: 0,
    },
    freight_charge: 0,
    insurance: 0,
  },
};

function CreateCountries(props) {
  const refresh = useRefresh();
  const windowSize = useWindowSize();
  const translate = useTranslate();
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate("countries");
  const notify = useNotify();
  // const form = useForm()

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowDialog(open);
  };

  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          // form.change("id", data.id)
          setShowDialog(false);
          notify("ra.notification.created");
          refresh();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  return (
    <>
      <Button
        onClick={toggleDrawer(true)}
        color="primary"
        style={{ textTransform: "capitalize", marginRight: 8 }}
        startIcon={<IconContentAdd />}
      >
        {translate("resources.settings.labels.new_country")}
      </Button>
      <SwipeableDrawer
        anchor={"right"}
        open={showDialog}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Flex width={[`${windowSize.width - 50}px`]} flexDirection={"column"}>
          <Flex
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            p={[2]}
          >
            <Text fontWeight={"bold"} ml={[2]}>
              {translate("resources.settings.labels.new_country")}
            </Text>
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Flex>
          <FormWithRedirect
            resource="countries"
            save={handleSubmit}
            initialValues={initialValues}
            render={({ handleSubmitWithRedirect, pristine, saving }) => (
              <Flex flexDirection={"column"} width={[1]} p={[2]}>
                <FormFieldCountries {...props} translate={translate} />
                <Flex
                  width={[1]}
                  flexDirection={"row"}
                  sx={{
                    position: "fixed",
                    bottom: 16,
                    right: 16,
                  }}
                >
                  <Flex
                    width={[1]}
                    flexDirection={"row"}
                    justifyContent="flex-end"
                  >
                    <Button
                      onClick={toggleDrawer(false)}
                      color="primary"
                      style={{ textTransform: "capitalize", marginRight: 8 }}
                      startIcon={<IconCancel />}
                    >
                      {translate("ra.action.cancel")}
                    </Button>
                    <SaveButton
                      handleSubmitWithRedirect={handleSubmitWithRedirect}
                      pristine={pristine}
                      saving={saving}
                      disabled={loading}
                    />
                  </Flex>
                </Flex>
              </Flex>
            )}
          />
        </Flex>
      </SwipeableDrawer>
    </>
  );
}

export default CreateCountries;
