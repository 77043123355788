import React from "react";
import { Flex, Text } from "rebass";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { useStyles } from "./styles";

export const dataCriteriaType = [
  { id: "ProductCode", name: "ProductCode" },
  { id: "ProductGroup", name: "ProductGroup" },
  { id: "ProductShortCode", name: "ProductShortCode" },
  { id: "SubProductGroup", name: "SubProductGroup" },
];

const CardTable = (props) => {
  const { children, title, translate } = props;

  const classes = useStyles();
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <Card className={classes.root}>
        <CardHeader
          title={
            <Text fontSize={[1]} fontWeight={"bold"}>
              {translate(title)}
            </Text>
          }
          className={classes.header}
        />
        <CardContent className={classes.content}>
          <Flex width={[1]} flexDirection={"column"}>
            {children}
          </Flex>
        </CardContent>
      </Card>
    </Flex>
  );
};

export default CardTable;
