import React from "react";
import moment from "moment";
import {
  TextField,
  useTranslate,
  RefreshButton,
  Filter,
  NumberField,
  TextInput,
  ReferenceField,
} from "react-admin";
import { get } from "lodash";
import { Flex, Box } from "rebass";
import { BetterList, Title, CreatePoButton } from "../components";

const UserField = ({ record }) => {
  return (
    <span>{`${get(record, "first_name", "")} ${get(
      record,
      "last_name",
      ""
    )} `}</span>
  );
};

const POFilter = (props) => {
  const translate = useTranslate();
  return (
    <Box width={[1]} pb={[2]}>
      <Box width={[1]} pt={[2]}>
        <Title fontWeight={"bold"} fontSize={[3]}>
          {translate(`resources.po.title`)}
        </Title>
      </Box>
      <Flex width={[1]} flexDirection={"row"} justifyContent={"flex-end"}>
        <Filter {...props}>
          <TextInput
            label='Search'
            source='q'
            alwaysOn
            style={{ width: 256 }}
          />
        </Filter>
      </Flex>
    </Box>
  );
};
const POAction = ({ basePath }) => {
  return (
    <Flex
      pt={[2]}
      pl={[2]}
      pr={[2]}
      flexDirection={"row"}
      style={{ position: "fixed", right: 16 }}
    >
      <Flex flexDirection={"row"} justifyContent={"flex-end"}>
        <CreatePoButton
          basePath={"/purchaseOrder"}
          sizeIcon={"xsmall"}
          label={"resources.buttons.create_po"}
          style={{ textTransform: "capitalize" }}
        />
        <RefreshButton style={{ textTransform: "capitalize" }} />
      </Flex>
    </Flex>
  );
};

const DateCustomField = ({ record, source }) => {
  const mydate = get(record, source, "");
  if (mydate === "") {
    return <span />;
  }

  return <span>{moment(mydate).format("DD-MMMM-YYYY")}</span>;
};

const ListPoCustomer = (props) => {
  return (
    <BetterList
      {...props}
      actions={<POAction />}
      filters={<POFilter />}
      rowclick={"show"}
    >
      <TextField
        source='purchase_order_number'
        label={"resources.po.fields.po_number"}
      />
      <DateCustomField
        source='created_at'
        label='resources.po.fields.created_on'
      />
      <TextField source='status' label={"resources.po.fields.status"} />
      <NumberField source='total' label={"resources.po.fields.total"} />
      <ReferenceField
        label={"resources.po.fields.created_by"}
        source='created_by'
        reference='users'
        link={false}
      >
        <UserField {...props} />
      </ReferenceField>
    </BetterList>
  );
};
export default ListPoCustomer;
