import React from "react";
import { Create, Loading, FormTab } from "react-admin";
import {
  ActionForm,
  FooterToolbar,
  BetterTabbedForm,
  FormValueTab,
  // useQuery,
} from "../components";
import { map, sortBy, filter } from "lodash";
import { FormFields, InfoFields } from "./components";
import { useQuery } from "react-query";
import { RestApi } from "../restClient";
const cacheTimeMinute = 1000 * 60 * 30;

const MainForm = (props) => {
  const { newClass4, ...rest } = props;
  return (
    <Create
      {...rest}
      actions={<ActionForm title={"resources.products.new_product"} />}
    >
      <BetterTabbedForm
        {...rest}
        toolbar={<FooterToolbar />}
        initialValues={{
          class4: props.newClass4,
        }}
      >
        <FormTab label='resources.products.tabs.info'>
          <InfoFields {...rest} />
        </FormTab>
        <FormValueTab label='resources.products.tabs.country' path={"country"}>
          <FormFields {...props} />
        </FormValueTab>
      </BetterTabbedForm>
    </Create>
  );
};

const ProductCreate = (props) => {
  const { hasList, hasCreate, ...rest } = props;
  // const { data, loading } = useQuery(
  //   `/codes?_order=${encodeURIComponent("ASC")}_sort=${encodeURIComponent(
  //     "label"
  //   )}`
  // );
  const countries = useQuery(
    `/countries`,
    () => RestApi(`/countries`).then((res) => res.json()),
    { cacheTime: cacheTimeMinute }
  );
  const { isLoading, data } = useQuery(
    `/codes?_order=${encodeURIComponent(
      "ASC"
    )}&active=true&_sort=${encodeURIComponent("label")}`,
    () =>
      RestApi(
        `/codes?_order=${encodeURIComponent(
          "ASC"
        )}&active=true&_sort=${encodeURIComponent("label")}`
      ).then((res) => res.json()),
    { cacheTime: cacheTimeMinute }
  );
  // const countries = useQuery(`/countries`);
  if (countries.loading) {
    return <Loading />;
  }
  if (isLoading) {
    return <Loading />;
  }
  // console.log("countries", countries);
  // let newClass4 = {};
  //newClass4 = { ...newClass4, [c.id]: "" };
  const dataGetIDS = map(countries.data, function (c) {
    return [c.id, ""];
  });
  const newClass4 = Object.fromEntries(dataGetIDS);
  const dataCountries = sortBy(countries.data, function (o) {
    return o.name;
  });
  const listCode = filter(data, function (o) {
    return o.active;
  });
  return (
    <MainForm
      {...rest}
      listcode={listCode && listCode.length > 0 ? listCode : []}
      countries={dataCountries}
      newClass4={newClass4}
    />
  );
};

export default ProductCreate;
