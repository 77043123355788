import React, { useState } from "react";
import { Flex, Box, Text } from "rebass";
import { get, toLower } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
//SelectInput
import { useTranslate, TextInput } from "react-admin";

const useStyles = makeStyles({
  loading: {
    height: 100,
    marginTop: 0,
  },
  formControl: {
    margin: 12,
    minWidth: 140,
  },
});

const ClassificationFields = (props) => {
  const { data, classes, formValues, updateField, newClass4 } = props;
  const [state, setState] = useState({ class4: formValues.class4 });
  // console.log("data- class", data);
  // console.log(props);
  const onChangeClass = (e, id) => {
    const class4 =
      state.class4 && state.class4 !== null ? state.class4 : newClass4;
    if (id) {
      class4[id] = e.target.value;
      // console.log("class4", class4);
      updateField(`class4`, class4);
      setState({
        ...state,
        class4: class4,
      });
    }
  };

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <Flex
        width={[1]}
        flexDirection={"row"}
        flexWrap={"wrap"}
        sx={{
          "&>div": {
            px: [3],
            py: [0],
          },
        }}
      >
        {data &&
          data.length > 0 &&
          data.map((country, index) => {
            return (
              <Box key={index} width={[1 / 4]}>
                {/* <SelectInput
                  source={`class4.${country.id}`}
                  label={get(country, "name", "")}
                  choices={dataClassifications}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                /> */}
                <FormControl className={classes.formControl}>
                  <InputLabel id={`country-select-${country.id}`}>
                    {get(country, "name", "")}
                  </InputLabel>
                  <Select
                    labelId={`country-select-${country.id}`}
                    id={`country-id-${country.id}`}
                    value={get(state, `class4.${country.id}`)}
                    onChange={(e) => onChangeClass(e, country.id)}
                  >
                    <MenuItem value={"Growth"}>Growth</MenuItem>
                    <MenuItem value={"Speed"}>Speed</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            );
          })}
      </Flex>
    </Flex>
  );
};
const RegistrationFields = (props) => {
  const { data } = props;
  // console.log("data", props);
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <Flex
        width={[1]}
        flexDirection={"row"}
        flexWrap={"wrap"}
        sx={{
          "&>div": {
            px: [3],
            py: [0],
          },
        }}
      >
        {data &&
          data.length > 0 &&
          data.map((country, index) => {
            const code = toLower(get(country, "code", ""));
            return (
              <Box key={index} width={[1 / 4]}>
                <TextInput
                  source={`registration_number.${code}`}
                  label={get(country, "name", "")}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Box>
            );
          })}
      </Flex>
    </Flex>
  );
};

const FormFields = (props) => {
  const { countries } = props;
  const translate = useTranslate();
  const classes = useStyles();
  // const [state, setState] = useState({ class4: formValues.class4 });

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <Flex py={[3]} px={[2]} width={[1]} flexDirection={"column"}>
        <Paper elevation={3} style={{ minHeight: 280 }}>
          <Box width={[1]} p={[3]}>
            <Text color={"green"}>
              {translate("resources.products.title_registration")}
            </Text>
          </Box>
          <RegistrationFields {...props} data={countries} classes={classes} />
        </Paper>
      </Flex>
      <Flex pb={[3]} px={[2]} width={[1]} flexDirection={"column"}>
        <Paper elevation={3} style={{ minHeight: 280 }}>
          <Box width={[1]} p={[3]}>
            <Text color={"green"}>
              {translate("resources.products.fields.classifications")}
            </Text>
          </Box>
          <ClassificationFields
            {...props}
            data={countries}
            classes={classes}
            // state={state}
            // setState={setState}
          />
        </Paper>
      </Flex>
    </Flex>
  );
};
export default FormFields;
