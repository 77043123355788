import Moment from "moment"
import { extendMoment } from "moment-range"
import { parseInt, filter, mapValues, keyBy } from "lodash"
import { nest, sum, ascending, merge, mean } from "d3"

const moment = extendMoment(Moment)

const getmyData = (fromDate, toDate, dataChart) => {
  return dataChart.filter(item => {
    return (
      moment(item.created_at).format("x") >= moment(fromDate).format("x") &&
      moment(item.created_at).format("x") <= moment(toDate).format("x")
    )
  })
}

export const getDataChart = (fromDate, toDate, data) => {
  const newData = getmyData(fromDate, toDate, data)
  const dataViews = nest()
    .key(function(d) {
      return moment(d.created_at).format("YYYY-MM-DD")
    })
    .sortKeys(ascending)
    .rollup(function(view) {
      const data = {
        order: sum(view, function(d) {
          return d.order
        }),
        avg: mean(view, function(d) {
          return d.order
        }),
        sales: sum(view, function(d) {
          return d.sales
        }),
      }
      return data
    })
    .entries(newData)
    .map(function(group) {
      let merged = {
        id: moment(group.key).format("MM"),
        date: moment(group.key).format("MMM"),
        year: moment(group.key).format("YYYY"),
        sales: group.value.sales,
        order: group.value.order,
        avg: group.value.avg,
        value: group.value.sales + group.value.order,
      }
      merged[moment(group.key).format("YYYY")] = "year"
      return merged
    })
  // console.log(dataViews)
  const totalData = nest()
    .key(function(d) {
      return d.id
    })
    .rollup(function(view) {
      const data = {
        order: sum(view, function(d) {
          return d.order
        }),
        avg: mean(view, function(d) {
          return d.order
        }),
        sales: sum(view, function(d) {
          return d.sales
        }),
      }
      return data
    })
    .entries(dataViews)
    .map(function(group) {
      return {
        order: group.value.order,
        sales: group.value.sales,
        avg: group.value.avg,
      }
    })
  // console.log(totalData)
  let order = 0
  let sales = 0
  let avg = 0
  totalData.forEach(function(element) {
    order = order + element.order
    sales = sales + element.sales
    avg = avg + element.avg
  })

  const resultData = nest()
    .key(function(d) {
      return d.id
    })
    .sortKeys(ascending)
    .key(function(d) {
      return d.year
    })
    .rollup(function(v) {
      return sum(v, function(d) {
        return d.value
      })
    })
    .entries(merge([dataViews, dataDefault]))
    .map(function(group) {
      const newValues = filter(group.values, function(o) {
        return o.key !== "undefined"
      })
      const newValues1 = mapValues(keyBy(newValues, "key"), "value")
      let merged = {
        month: dataMonth[parseInt(group.key) - 1],
        ...newValues1,
      }
      return merged
    })
  return {
    resultData: resultData,
    totalData: { order: order, sales: sales, avg: avg },
  }
}

export const colorLine = [
  "#7BDCB5",
  "#00D084",
  "#8ED1FC",
  "#0693E3",
  "#FF6900",
  "#FCB900",
  "#EB144C",
  "#009688",
  "#F78DA7",
  "#795548",
  "#9900EF",
]

export const dataDefault = [
  {
    date: "Jan",
    id: "01",
    order: 0,
    sales: 0,
    value: 0,
  },
  {
    date: "Feb",
    id: "02",
    order: 0,
    sales: 0,
    value: 0,
  },
  {
    date: "Mar",
    id: "03",
    order: 0,
    sales: 0,
    value: 0,
  },
  {
    date: "Apr",
    id: "04",
    order: 0,
    sales: 0,
    value: 0,
  },
  {
    date: "May",
    id: "05",
    order: 0,
    sales: 0,
    value: 0,
  },
  {
    date: "Jun",
    id: "06",
    order: 0,
    sales: 0,
    value: 0,
  },
  {
    date: "Jul",
    id: "07",
    order: 0,
    sales: 0,
    value: 0,
  },
  {
    date: "Aug",
    id: "08",
    order: 0,
    sales: 0,
    value: 0,
  },
  {
    date: "Sep",
    id: "09",
    order: 0,
    sales: 0,
    value: 0,
  },
  {
    date: "Oct",
    id: "10",
    order: 0,
    sales: 0,
    value: 0,
  },
  {
    date: "Nov",
    id: "11",
    order: 0,
    sales: 0,
    value: 0,
  },
  {
    date: "Dec",
    id: "12",
    order: 0,
    sales: 0,
    value: 0,
  },
]

export const dataMonth = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]

export const dataDealers = [
  { id: 1, name: "Dealer 1" },
  { id: 2, name: "Dealer 2" },
  { id: 3, name: "Dealer 3" },
  { id: 4, name: "Dealer 4" },
  { id: 5, name: "Dealer 5" },
  { id: 6, name: "Dealer 6" },
]

export const dataCategories = [
  { id: 1, name: "Prevention" },
  { id: 2, name: "Composite & Bonding" },
]
