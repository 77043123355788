export default {
  dashboard: {
    title: "Dasbor",
    reports: "Laporan",
    fields: {
      country: "Negara",
      dealer: "Dealer",
      category: "Kategori",
      start_date: "Tanggal Awal",
      end_date: "Tanggal Akhir",
      total_member: "Total Pelanggan",
      total_spend: "Total Poin yang Dibelanjakan",
      total_order: "Total Pesanan",
      total_sales: "Total Penjual",
      total_avarage_order: "Nilai Rata-rata Pesanan",
      total_earn: "Total Poin yang Dihasilkan",
      daily_member: "Anggota baru setiap hari",
      target_setting: "Target Setting",
      speed_growth: "Speed/Growth",
      main_categories: "GCIAG 9 Main Categories",
      date_range: "Date Range",
      date_range_to: "Date Range To",
      top: "Top",
      ratio: "Ratio %",
      gap: "GAP",
      po: "PO",
      last_year: "Last Year",
      target: "Target",
    },
  },
};
