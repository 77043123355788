import React from "react";
import {
  TextInput,
  BooleanInput,
  SelectInput,
  FormDataConsumer,
  ReferenceInput,
} from "react-admin";

import { Flex, Box } from "rebass";
import { RaTextArea } from "../../../components";
import { ROLE_USER } from "../../../utils";
const dataRole = [
  { name: "Dealers", id: "Dealers" },
  { name: "GC OA Associates", id: "GC OA Associates" },
  { name: "GC Sales Associate", id: "GC Sales Associate" },
  { name: "GC Sales Manager", id: "GC Sales Manager" },
  { name: "Admin", id: "Admin" },
  { name: "GC Country Manager", id: "GC Country Manager" },
];
//{ name: "GC Associate", id: "GC Associate" },
/**
 * 
 Dealer, GC OA Associate, GC Sales Associate, GC Sales Manager, Admin
 */

const FormFields = (props) => {
  const { updateField } = props;
  const onChangeRole = (e) => {
    // console.log(e.target.value);
    if (e.target.value !== "Dealer") {
      updateField("dealer_id", null);
    }
  };
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <BooleanInput source={"active"} />
      <TextInput source={"first_name"} style={{ width: 300 }} />
      <TextInput source={"last_name"} style={{ width: 300 }} />
      <TextInput source={"email"} style={{ width: 300 }} />
      <SelectInput
        choices={dataRole}
        source={"role"}
        style={{ width: 300 }}
        onChange={(e) => onChangeRole(e)}
      />
      <FormDataConsumer>
        {({ formData }) => {
          if (formData && formData.role === ROLE_USER.dealer) {
            return (
              <ReferenceInput
                source='dealer_id'
                reference='dealers'
                style={{ width: 500 }}
                perPage={500}
                sort={{ field: "name", order: "ASC" }}
              >
                <SelectInput optionText='name' />
              </ReferenceInput>
            );
          }
          if (formData && formData.role === ROLE_USER.country_manager) {
            return (
              <ReferenceInput
                source='country_id'
                reference='countries'
                style={{ width: 50 }}
                perPage={200}
                sort={{ field: "name", order: "ASC" }}
              >
                <SelectInput optionText='name' />
              </ReferenceInput>
            );
          }
          //    else if (formData && formData.role === "GC Associate") {
          //     return (
          //       <ReferenceInput source='customer_id' reference='customers'>
          //         <SelectInput optionText='name' />
          //       </ReferenceInput>
          //     );
          //   }
          return <div />;
        }}
      </FormDataConsumer>
      <Box width={[1]}>
        <RaTextArea
          source='remarks'
          label={"resources.products.fields.remarks"}
          rows='5'
          fullWidth
          multiline
          InputLabelProps={{
            shrink: true,
            disableAnimation: true,
          }}
          style={{ transform: "none" }}
        />
      </Box>
    </Flex>
  );
};

export default FormFields;
