import React, { useState, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
// import { useHistory } from "react-router-dom"
import { styles } from "./styles";
import SubMenuItem from "./SubMenuItem";
import MenuItemLink from "../MenuItemLink";
import {
  User1Icon,
  ChartIcon,
  DocumentIcon,
  StarIcon,
  Chart8,
} from "../../icon_svg";
// import { MenuShow } from "./utils"

const MenuGCDealers = (props) => {
  const { classes, onMenuClick } = props;
  const [openMenu, setOpenMenu] = useState(false);
  const translate = useTranslate();

  return (
    <Fragment>
      <MenuItemLink
        leftIcon={<Chart8 />}
        to={`/`}
        primaryText={translate(`resources.menus.dashboard`, {
          smart_count: 2,
        })}
        onClick={onMenuClick}
        translate={translate}
      />

      <SubMenuItem
        handleToggle={() => setOpenMenu(!openMenu)}
        isOpen={openMenu}
        classes={classes}
        sidebarIsOpen={openMenu}
        name='resources.menus.dealers'
        icon={<User1Icon />}
      >
        <MenuItemLink
          to={`/dealersTarget`}
          primaryText={translate(`resources.menus.targets`, {
            smart_count: 2,
          })}
          onClick={onMenuClick}
          leftIcon={<ChartIcon />}
          translate={translate}
        />
        <MenuItemLink
          leftIcon={<StarIcon />}
          to={`/orders`}
          primaryText={translate(`resources.menus.orders`, {
            smart_count: 2,
          })}
          onClick={props.onMenuClick}
          translate={translate}
        />
        <MenuItemLink
          to={`/invoices`}
          primaryText={translate(`resources.menus.invoiced`, {
            smart_count: 2,
          })}
          leftIcon={<DocumentIcon />}
          onClick={onMenuClick}
          translate={translate}
        />
      </SubMenuItem>
    </Fragment>
  );
};

export default withStyles(styles)(MenuGCDealers);
