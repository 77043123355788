import React from "react";
import { useTranslate, useQueryWithStore } from "react-admin";
import { Flex, Box } from "rebass";
import { get } from "lodash";
import { ROLE_USER } from "../../utils";
//STATUSPOLIST
import { Title } from "../../components";
import { dateRange, getListOrder, payloadDefault, reportType } from "./utils";
import CMReport from "./CMReport";
import DealerReport from "./DealerReport";
import GCReport from "./GCReport";

const MainReport = (props) => {
  const { countryId, permissions } = props;

  const translate = useTranslate();
  const countryFilter = countryId
    ? { country_id: countryId, active: true }
    : { active: true };
  const dataCountries = useQueryWithStore({
    type: "getList",
    resource: "countries",
    payload: {
      ...payloadDefault,
      sort: { field: "name", order: "ASC" },
      filter: { active: true },
    },
  });

  const dataDealers = useQueryWithStore({
    type: "getList",
    resource: "dealers",
    payload: {
      ...payloadDefault,
      sort: { field: "name", order: "ASC" },
      filter: countryFilter,
    },
  });

  // console.log("dataDealers", user);
  // const role = get(user, "role", "");
  return (
    <Box width={[1]}>
      <Flex width={[1]} py={[3]} flexDirection={"row"}>
        <Title fontSize={[3]} fontWeight={"bold"}>
          {translate("resources.dashboard.reports")}
        </Title>
      </Flex>
      {permissions === ROLE_USER.dealer && (
        <DealerReport
          {...props}
          reportType={reportType}
          dateRange={dateRange}
          translate={translate}
        />
      )}
      {permissions === ROLE_USER.country_manager && dataDealers.loaded && (
        <CMReport
          {...props}
          reportType={reportType}
          dataDealers={get(dataDealers, "data", [])}
          dateRange={dateRange}
          translate={translate}
        />
      )}
      {(permissions === ROLE_USER.gc_oa_associate ||
        permissions === ROLE_USER.gc_sales_associate ||
        permissions === ROLE_USER.gc_sales_manager ||
        permissions === ROLE_USER.admin) &&
        dataDealers.loaded && (
          <GCReport
            {...props}
            dataDealers={getListOrder(get(dataDealers, "data", []))}
            dataCountries={getListOrder(get(dataCountries, "data", []))}
            reportType={reportType}
            dateRange={dateRange}
            translate={translate}
          />
        )}
    </Box>
  );
};

export default MainReport;
