import React, { Fragment, useState, useEffect } from "react";
import { keyBy, find, orderBy } from "lodash";
import { Box, Flex, Text } from "rebass";
import {
  useQueryWithStore,
  Loading,
  TextField,
  useTranslate,
  ReferenceField,
} from "react-admin";
import { useQuery } from "react-query";
import { getCountries } from "../../restClient";
import TextFieldName from "@material-ui/core/TextField";
import {
  Pagination,
  Datagrid,
  useWindowSize,
  ActiveField,
} from "../../components";
import { EditDealers, CountriesFields } from "./dealers";
//
const cacheTimeMinute = 1000 * 60 * 20;
const ButtonField = ({ record, ...rest }) => {
  return (
    <Flex flexDirection={"row"} justifyContent='flex-end'>
      <EditDealers {...rest} record={record} />
    </Flex>
  );
};

const ListDealers = (props) => {
  const translate = useTranslate();
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({ field: "country_id", order: "ASC" });
  const [sortOrder, setSortOrder] = useState("desc");
  const { width } = useWindowSize();
  const [perPage, setPerPage] = useState(25);
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isRequest, setIsRequest] = useState(false);
  const countriesList = useQuery("countriesList", () => getCountries(), {
    cacheTime: cacheTimeMinute,
  });

  let filters =
    type === ""
      ? { search: searchText }
      : { search: searchText, country_id: type };
  const { data, total, loading } = useQueryWithStore({
    type: "getList",
    resource: "dealers",
    payload: {
      pagination: { page, perPage },
      sort: { field: sort.field, order: sort.order },
      filter: filters,
    },
  });
  const handleChange = (name) => (event) => {
    if (name === "search") {
      setSearch(event.target.value);
    } else if (name === "country") {
      setType(event.target.value);
    }
    setIsRequest(true);
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (isRequest) {
        setSearchText(search);
        setIsRequest(false);
      }
    }, 500);
    return () => clearTimeout(timeout);
  });

  const onHandle = (field, order) => {
    let newField = "name";
    if (field) {
      newField = field;
    }
    const defaultSort = sortOrder === "ASC" ? "DESC" : "ASC";
    setSort({
      ...sort,
      field: newField,
      order: defaultSort,
    });
    setSortOrder(defaultSort);
  };

  if (loading) {
    return (
      <Flex
        width={[1]}
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
        style={{ height: "60vh" }}
      >
        <Loading />
      </Flex>
    );
  }
  if (countriesList.isLoading) {
    return (
      <Flex
        width={[1]}
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
        style={{ height: "60vh" }}
      >
        <Loading />
      </Flex>
    );
  }
  let newData = data;
  const countries = countriesList.data;
  // console.log(sort);
  newData =
    data &&
    data.map((item) => {
      const country_name = find(countries, function (o) {
        return o.id === item.country_id;
      });
      return { country_name: country_name.name, ...item };
    });
  if (sort.field === "country_id") {
    const sorter = sort.order === "ASC" ? "asc" : "desc";
    const newData1 = orderBy(newData, ["country_name"], [sorter]);
    newData = newData1;
  }

  return (
    <Box width={[1, width - 280]} py={[2]}>
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        pb={[3]}
      >
        <Box pr={[2]}>
          <Text fontSize={["10px"]} fontWeight='bold' pt={["12px"]}>
            {translate("resources.settings.fields.country")}
          </Text>
          <CountriesFields
            handleChange={handleChange}
            type={type}
            countries={props.countries}
          />
        </Box>
        <TextFieldName
          label='search'
          margin='dense'
          variant='filled'
          value={search}
          onChange={handleChange("search")}
          InputLabelProps={{ shrink: true }}
          style={{ width: 200 }}
        />
      </Flex>
      {newData && newData.length > 0 && (
        <Fragment>
          <Datagrid
            data={keyBy(newData, "id")}
            ids={newData.map(({ id }) => id)}
            currentSort={sort}
            setSort={(p, b) => onHandle(p, b)}
            basePath='/settings' // required only if you set use "rowClick"
            // rowClick="edit"
          >
            <ReferenceField
              label={"resources.settings.fields.country"}
              source='country_id'
              reference='countries'
              link={false}
            >
              <TextField source='name' />
            </ReferenceField>
            <TextField
              label={"resources.settings.fields.dealer"}
              source='name'
            />
            <TextField label={"resources.settings.fields.code"} source='code' />
            <ActiveField
              label={"resources.settings.fields.active"}
              source='active'
              style={{ width: 80 }}
            />

            <ButtonField {...props} />
          </Datagrid>
          <Pagination
            page={page}
            perPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            setPage={(v) => setPage(v)}
            setPerPage={(v) => setPerPage(v)}
            total={
              total === null
                ? data && data.length > 0
                  ? data.length
                  : 0
                : total
            }
          />
        </Fragment>
      )}
    </Box>
  );
};

export default ListDealers;
