import React, { useState } from "react";
import { useQuery, useWindowSize } from "../../../components";
import { Box, Flex } from "rebass";
import { Loading, downloadCSV } from "react-admin";
import { get, map, filter } from "lodash";
import moment from "moment";
import jsonExport from "jsonexport/dist";
import { ListFilters, HeaderFilters } from "./po";
// import Container from "./Container";
import ChartContainer from "./ChartContainer";
import ChartContainerDealer from "./ChartContainerDealer";
import { ExportCSVFile } from "./utils";
const MainFields = (props) => {
  const { width } = useWindowSize();
  const { dataList, translate, dealerId, isDealer, paramDealerId } = props;
  const [list, setList] = useState(dataList);
  const [valueCountry, setValueCountry] = useState([]);
  const [valueDealer, setValueDealer] = useState([]);
  const [myfilter, setMyFilter] = useState({
    country: [],
    dealer: [],
    category: [],
    target_setting: [],
  });
  const [valueCategory, setValueCategory] = useState([]);
  const [valueTargetSetting, setValueTargetSetting] = useState([]);

  const onExportCsv = () => {
    if (list && list.length > 0) {
      const newList = map(list, function (o) {
        return {
          country: get(o, "country", ""),
          dealer: get(o, "dealer", ""),
          category: get(o, "category", ""),
          target_setting: get(o, "target_setting", ""),
          last_year: get(o, "last_year", 0),
          target: get(o, "target", 0),
          po: get(o, "po", 0),
          gap: get(o, "gap", 0),
          ratio: get(o, "ratio", 0),
        };
      });
      /**
       * last_year: getAmount2DecimalValue(get(o, "last_year", 0)),
          target: getAmount2DecimalValue(get(o, "target", 0)),
          po: getAmount2DecimalValue(get(o, "po", 0)),
          gap: getAmount2DecimalValue(get(o, "gap", 0)),
          ratio: getAmount2DecimalValue(get(o, "ratio", 0)),
       */
      // target: NumberAmountValue(get(o, "target", 0)),
      // po: NumberAmountValue(get(o, "po", 0)),
      // gap: NumberAmountValue(get(o, "gap", 0)),
      // console.log(newList);
      jsonExport(
        newList,
        {
          headers: [
            "country",
            "dealer",
            "category",
            "target_setting",
            "last_year",
            "target",
            "po",
            "gap",
            "ratio",
          ],
        },
        (err, csv) => {
          if (err) return console.error(err);
          downloadCSV(csv, `po-${moment().format("DD-MMM-YYYY-HH:mm:ss")}`);
        }
      );
    }
  };

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <HeaderFilters
        {...props}
        setList={setList}
        valueCategory={valueCategory}
        valueCountry={valueCountry}
        valueDealer={valueDealer}
        myfilter={myfilter}
        setValueCategory={setValueCategory}
        setValueCountry={setValueCountry}
        setValueDealer={setValueDealer}
        setMyFilter={setMyFilter}
        valueTargetSetting={valueTargetSetting}
        setValueTargetSetting={setValueTargetSetting}
      />
      <ExportCSVFile
        translate={translate}
        list={list}
        onExportCsv={onExportCsv}
      />

      <Flex width={[1]} flexDirection={"column"}>
        {list && <ListFilters list={list} dealerId={dealerId} />}
      </Flex>
      {!isDealer ? (
        <Box width={[1]} py={[3]}>
          <ChartContainer
            width={width}
            myfilter={myfilter}
            tabTitle={"PO"}
            baseUrl={`/reports/purchaseOrder/chart`}
          />
        </Box>
      ) : (
        <Box width={[1]} py={[3]}>
          <ChartContainerDealer
            width={width}
            myfilter={myfilter}
            baseUrl={`/reports/purchaseOrder/chart${paramDealerId}`}
            tabTitle={"PO"}
          />
        </Box>
      )}
    </Flex>
  );
};

export default (props) => {
  const { country, dealerId } = props;
  const paramDealerId = dealerId !== null ? `?dealer_id=${dealerId}` : "";
  const { loaded, data } = useQuery(`/reports/purchaseOrder${paramDealerId}`);

  if (!loaded) {
    return (
      <Box width={[1]}>
        <Loading />
      </Box>
    );
  }
  const dataList =
    data && data.length > 0
      ? Array.from(
          new Set(
            data.map((p, index) => {
              return { ...p, id: index };
            })
          )
        )
      : [];

  let myDataList = dataList;
  if (country && country.id) {
    myDataList = filter(dataList, function (o) {
      return o.country === country.name;
    });
  }
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <MainFields
        dataList={myDataList}
        {...props}
        paramDealerId={paramDealerId}
      />
      {/* {isDealer && (
        <Box width={[1]} py={[3]}>
          <Container
            baseUrl={`/reports/purchaseOrder/chart${paramDealerId}`}
            width={width}
          />
        </Box>
      )} */}
    </Flex>
  );
};
