export const dataOTD = [
  {
    id: 1,
    period: "2020-03",
    country: "ID",
    dealer: "Dealer1",
    rows: 200,
    created_at: "2020-04-04T06:23:39.387896Z",
  },
  {
    id: 2,
    period: "2020-03",
    country: "MY",
    dealer: "Dealer2",
    rows: 300,
    created_at: "2020-04-03T06:23:39.387896Z",
  },
]

export const dataProduct = [
  {
    id: 1,
    code: "0104B146-0000",
    packaging: "Acro Sep 300g",
    moq: "",
    price: "15.00",
    registration_number: {
      id: "#N/A",
      my: " not MD ",
      sg: " ES0001218 ",
    },
    created_at: "2019-11-17T13:01:09.222592Z",
    updated_at: "2019-11-21T06:13:11.395739Z",
    active: true,
    remarks: "",
    class4: { id: 4, my: 5, ph: 4, sg: 4, th: 5, vn: 5 },
    photos: [
      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/product/photos-AH2.jpeg",
    ],
    summary: "",
    qty: 20,
    category_id: 1,
    class1_id: 1,
    class2_id: 2,
    class3_id: 3,
    group_id: 1,
  },
  {
    id: 2,
    code: "0104B083-0000",
    packaging: "Acron 250g Liquid",
    moq: "5",
    price: "37.00",
    registration_number: {
      id: "AKL 20602816887",
      my: " GMD99829629018A  11-Jun-23 ",
      sg: " ES0001218 ",
    },
    created_at: "2019-11-17T13:01:09.243488Z",
    updated_at: "2019-11-20T15:36:40.41128Z",
    active: true,
    remarks: "",
    class4: { id: 4, my: 4, ph: 4, sg: 4, th: 4, vn: 4 },
    photos: [
      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/product/photos-IW6.jpeg",
    ],
    summary: "",
    qty: 20,
    category_id: 1,
    class1_id: 1,
    class2_id: 2,
    class3_id: 3,
    group_id: 2,
  },
  {
    id: 3,
    code: "0104A076-0030",
    packaging: "Acron 500g Powder Bottle # 8 Live Pink/Veined",
    moq: "7",
    price: "18.00",
    registration_number: {
      id: "AKL 20602816887",
      my: " GMD99829629018A  11-Jun-23 ",
      sg: " ES0001218 ",
    },
    created_at: "2019-12-17T13:01:09.260624Z",
    updated_at: "2019-12-20T15:36:59.700154Z",
    active: true,
    remarks: "",
    class4: { id: 4, my: 4, ph: 4, sg: 4, th: 4, vn: 4 },
    photos: [
      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/product/photos-97I.jpeg",
    ],
    summary: "",
    qty: 20,
    category_id: 1,
    class1_id: 1,
    class2_id: 2,
    class3_id: 3,
    group_id: 2,
  },
  {
    id: 4,
    code: "0130F077-0020",
    packaging: "Aroma Fine Plus 500gx40 Fast ",
    moq: "8",
    price: "17.00",
    registration_number: {
      id: "AKL 20602411708",
      my: " GMD76674390818A  3-Jan-23 ",
      sg: " ES0001218 ",
    },
    created_at: "2019-12-17T13:01:09.284548Z",
    updated_at: "2019-12-19T05:48:22.893218Z",
    active: true,
    remarks: "",
    class4: { id: 4, my: 4, ph: 4, sg: 4, th: 4, vn: 4 },
    photos: [
      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/product/photos-IXJ.jpeg",
    ],
    summary: "",
    qty: 20,
    category_id: 1,
    class1_id: 6,
    class2_id: 7,
    class3_id: 8,
    group_id: 3,
  },
  {
    id: 5,
    code: "0130F077-0010",
    packaging: "Aroma Fine Plus 500gx40 Normal",
    moq: "5",
    price: "27.00",
    registration_number: {
      id: "AKL 20602411708",
      my: " GMD76674390818A  3-Jan-23 ",
      sg: " ES0001218 ",
    },
    created_at: "2019-12-17T13:01:09.301184Z",
    updated_at: "2019-12-19T05:48:37.829595Z",
    active: true,
    remarks: "",
    class4: { id: 4, my: 4, ph: 4, sg: 4, th: 4, vn: 4 },
    photos: [
      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/product/photos-P6Q.jpeg",
    ],
    summary: "",
    qty: 20,
    category_id: 1,
    class1_id: 6,
    class2_id: 7,
    class3_id: 8,
    group_id: 3,
  },
  {
    id: 6,
    code: "0201B111-0020",
    packaging: "Articulating Paper 120 pcs # 120mm Blue",
    moq: "8",
    price: "23.00",
    registration_number: {
      id: "#N/A",
      my: " GMD85441375717A  27-Dec-22 ",
      sg: " ES0001218 ",
    },
    created_at: "2020-01-17T13:01:09.326263Z",
    updated_at: "2020-01-19T05:49:11.66049Z",
    active: true,
    remarks: "",
    class4: { id: 4, my: 4, ph: 4, sg: 4, th: 4, vn: 4 },
    photos: [
      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/product/photos-FLZ.jpeg",
    ],
    summary: "",
    qty: 20,
    category_id: 1,
    class1_id: 9,
    class2_id: 10,
    class3_id: 11,
    group_id: 4,
  },
  {
    id: 7,
    code: "0201B111-0010",
    packaging: "Articulating Paper 120 pcs # 120mm Red",
    moq: "8",
    price: "39.00",
    registration_number: {
      id: "#N/A",
      my: " GMD85441375717A  27-Dec-22 ",
      sg: " ES0001218 ",
    },
    created_at: "2020-01-17T13:01:09.342543Z",
    updated_at: "2020-01-20T15:39:53.291216Z",
    active: true,
    remarks: "",
    class4: { id: 4, my: 4, ph: 4, sg: 4, th: 4, vn: 4 },
    photos: [
      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/product/photos-25Y.jpeg",
    ],
    summary: "",
    qty: 20,
    category_id: 1,
    class1_id: 9,
    class2_id: 10,
    class3_id: 11,
    group_id: 4,
  },
  {
    id: 8,
    code: "0299B242-0000",
    packaging: "Brush for Plastic Syringe",
    moq: "",
    price: "47.00",
    registration_number: { id: "#N/A", my: " not MD ", sg: " NMD " },
    created_at: "2020-02-17T13:01:09.36244Z",
    updated_at: "2020-02-17T13:01:09.36244Z",
    active: true,
    remarks: "",
    class4: { id: 4, my: 4, ph: 4, sg: 4, th: 4, vn: 4 },
    photos: null,
    summary: "",
    qty: 20,
    category_id: 1,
    class1_id: 9,
    class2_id: 10,
    class3_id: 11,
    group_id: 5,
  },
  {
    id: 9,
    code: "0104A233-0020",
    packaging: "Caviton 30g, Pink",
    moq: "8",
    price: "36.00",
    registration_number: {
      id: "AKL 20602412727",
      my: " GB84673825918 24-Apr-23 ",
      sg: " DE0500291 ",
    },
    created_at: "2020-02-17T13:01:09.385929Z",
    updated_at: "2020-02-19T05:50:38.486695Z",
    active: true,
    remarks: "",
    class4: { id: 4, my: 4, ph: 4, sg: 4, th: 4, vn: 4 },
    photos: [
      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/product/photos-6W9.jpeg",
    ],
    summary: "",
    qty: 20,
    category_id: 1,
    class1_id: 6,
    class2_id: 12,
    class3_id: 13,
    group_id: 6,
  },
  {
    id: 10,
    code: "0104A233-0030",
    packaging: "Caviton 30g, White",
    moq: "8",
    price: "36.00",
    registration_number: {
      id: "AKL 20602412727",
      my: " GB84673825918 24-Apr-23 ",
      sg: " DE0500291 ",
    },
    created_at: "2020-03-17T13:01:09.401996Z",
    updated_at: "2020-03-20T15:39:41.309376Z",
    active: true,
    remarks: "",
    class4: { id: 4, my: 4, ph: 4, sg: 4, th: 4, vn: 4 },
    photos: [
      "https://s3-ap-southeast-1.amazonaws.com/oleh/assets/product/photos-836.jpeg",
    ],
    summary: "",
    qty: 12,
    category_id: 1,
    class1_id: 6,
    class2_id: 12,
    class3_id: 13,
    group_id: 6,
  },
  {
    id: 11,
    code: "0101B522-0000",
    packaging: "Cavity Conditioner, 6g",
    moq: "8",
    price: "36.00",
    registration_number: {
      id: "#N/A",
      my: " GB1840486016  9-Nov-21 ",
      sg: " DE0010051  ",
    },
    created_at: "2020-03-17T13:01:09.420013Z",
    updated_at: "2020-03-17T13:01:09.420013Z",
    active: true,
    remarks: "",
    class4: { id: 4, my: 4, ph: 4, sg: 4, th: 4, vn: 4 },
    photos: null,
    summary: "",
    qty: 20,
    category_id: 1,
    class1_id: 6,
    class2_id: 12,
    class3_id: 13,
    group_id: 7,
  },
  {
    id: 12,
    code: "0299B500-0010",
    packaging: "Chappy - Pink",
    moq: "8",
    price: "26.00",
    registration_number: { id: "#N/A", my: "  -   ", sg: " NMD " },
    created_at: "2020-03-17T13:01:09.438837Z",
    updated_at: "2020-03-17T13:01:09.438837Z",
    active: true,
    remarks: "",
    class4: { id: 4, my: 4, ph: 4, sg: 4, th: 4, vn: 4 },
    photos: null,
    summary: "",
    qty: 20,
    category_id: 1,
    class1_id: 9,
    class2_id: 10,
    class3_id: 11,
    group_id: 8,
  },
  {
    id: 13,
    code: "0104F241-1000",
    packaging: "Cocoa Butter, 10g",
    moq: "8",
    price: "26.00",
    registration_number: {
      id: "AKL20602814064",
      my: " GMD98647464618A  23-Mar-23 ",
      sg: " ES0001218 ",
    },
    created_at: "2020-03-17T13:01:09.457645Z",
    updated_at: "2020-03-17T13:01:09.457645Z",
    active: true,
    remarks: "",
    class4: { id: 4, my: 4, ph: 4, sg: 4, th: 4, vn: 4 },
    photos: null,
    summary: "",
    qty: 10,
    category_id: 1,
    class1_id: 6,
    class2_id: 12,
    class3_id: 13,
    group_id: 9,
  },
  {
    id: 14,
    code: "341001",
    packaging: "Coe Comfort Professional Package",
    moq: "8",
    price: "9.00",
    registration_number: { id: "#N/A", my: "  -   ", sg: "#N/A" },
    created_at: "2020-03-17T13:01:09.476319Z",
    updated_at: "2020-03-17T13:01:09.476319Z",
    active: true,
    remarks: "",
    class4: { id: 4, my: 4, ph: 4, sg: 4, th: 4, vn: 4 },
    photos: null,
    summary: "",
    qty: 14,
    category_id: 1,
    class1_id: 1,
    class2_id: 2,
    class3_id: 3,
    group_id: 10,
  },
  {
    id: 15,
    code: "135001",
    packaging: "Coe Pak Periodontal Dressing Standard Pkg",
    moq: "8",
    price: "38.00",
    registration_number: {
      id: "AKL 20602816466",
      my: " GMD72873526118A 25-May-23 ",
      sg: " ES0001218 ",
    },
    created_at: "2020-03-17T13:01:09.501416Z",
    updated_at: "2020-03-17T13:01:09.501416Z",
    active: true,
    remarks: "",
    class4: { id: 4, my: 4, ph: 4, sg: 4, th: 4, vn: 4 },
    photos: null,
    summary: "",
    qty: 20,
    category_id: 1,
    class1_id: 6,
    class2_id: 14,
    class3_id: 15,
    group_id: 11,
  },
  {
    id: 16,
    code: "344001",
    packaging: "Coe Soft Professional Package",
    moq: "8",
    price: "50.00",
    registration_number: { id: "#N/A", my: "  -   ", sg: "#N/A" },
    created_at: "2020-03-20T13:01:09.520567Z",
    updated_at: "2020-03-20T13:01:09.520567Z",
    active: true,
    remarks: "",
    class4: { id: 4, my: 4, ph: 4, sg: 4, th: 4, vn: 4 },
    photos: null,
    summary: "",
    qty: 17,
    category_id: 1,
    class1_id: 1,
    class2_id: 2,
    class3_id: 3,
    group_id: 12,
  },
  {
    id: 17,
    code: "0101E210-9000",
    packaging: "Dentin Conditioner, 25g",
    moq: "8",
    price: "35.00",
    registration_number: {
      id: "AKL 20602514319",
      my: " GB1840486016  9-Nov-21 ",
      sg: " DE0010054 ",
    },
    created_at: "2020-04-17T13:01:09.538729Z",
    updated_at: "2020-04-17T13:01:09.538729Z",
    active: true,
    remarks: "",
    class4: { id: 4, my: 4, ph: 4, sg: 4, th: 4, vn: 4 },
    photos: null,
    summary: "",
    qty: 20,
    category_id: 1,
    class1_id: 6,
    class2_id: 12,
    class3_id: 13,
    group_id: 13,
  },
  {
    id: 18,
    code: "0101B574-1000",
    packaging: "Dentin Conditioner, 6g",
    moq: "8",
    price: "16.00",
    registration_number: {
      id: "AKL 20602514319",
      my: " GB1840486016  9-Nov-21 ",
      sg: "#N/A",
    },
    created_at: "2020-04-17T13:01:09.560143Z",
    updated_at: "2020-04-17T13:01:09.560143Z",
    active: true,
    remarks: "",
    class4: { id: 4, my: 4, ph: 4, sg: 4, th: 4, vn: 4 },
    photos: null,
    summary: "",
    qty: 20,
    category_id: 1,
    class1_id: 6,
    class2_id: 12,
    class3_id: 13,
    group_id: 13,
  },
  {
    id: 19,
    code: "0131F102-0000",
    packaging:
      "Diapolisher Paste                                              ",
    moq: "Indent",
    price: "19.00",
    registration_number: {
      id: "AKL 10602713039",
      my: " GMD53020465318A  20-Mar-23 ",
      sg: " ES0001218 ",
    },
    created_at: "2020-04-17T13:01:09.581777Z",
    updated_at: "2020-04-17T13:01:09.581777Z",
    active: true,
    remarks: "",
    class4: { id: 4, my: 4, ph: 4, sg: 4, th: 4, vn: 4 },
    photos: null,
    summary: "",
    qty: 20,
    category_id: 1,
    class1_id: 6,
    class2_id: 16,
    class3_id: 17,
    group_id: 14,
  },
  {
    id: 20,
    code: "0101B062-0000",
    packaging: "Elite 100 100g Liquid",
    moq: "8",
    price: "16.00",
    registration_number: {
      id: "AKL 20602514292",
      my: "  -   ",
      sg: "#N/A",
    },
    created_at: "2020-04-17T13:01:09.603642Z",
    updated_at: "2020-04-17T13:01:09.603642Z",
    active: true,
    remarks: "",
    class4: { id: 4, my: 4, ph: 4, sg: 4, th: 4, vn: 4 },
    photos: null,
    summary: "",
    qty: 20,
    category_id: 1,
    class1_id: 6,
    class2_id: 18,
    class3_id: 19,
    group_id: 15,
  },
  {
    id: 26,
    code: "0104B514-0010",
    packaging: "Epitex Refill 10m # Coarse",
    moq: "",
    price: "48.00",
    registration_number: {
      id: "#N/A",
      my: " GMD98397418518A  22-Jan-23 ",
      sg: " ES0001218 ",
    },
    created_at: "2019-11-17T13:01:09.222592Z",
    updated_at: "2019-11-21T06:13:11.395739Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 18,
    category_id: 1,
    class1_id: 6,
    class2_id: 16,
    class3_id: 17,
    group_id: 18,
  },
  {
    id: 27,
    code: "0104B514-0040",
    packaging: "Epitex Refill 10m # Extra Fine",
    moq: "",
    price: "13.00",
    registration_number: {
      id: "#N/A",
      my: " GMD98397418518A  22-Jan-23 ",
      sg: " ES0001218 ",
    },
    created_at: "2019-11-17T13:01:09.222592Z",
    updated_at: "2019-11-21T06:13:11.395739Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 18,
    category_id: 1,
    class1_id: 6,
    class2_id: 16,
    class3_id: 17,
    group_id: 18,
  },
  {
    id: 28,
    code: "0104B514-0020",
    packaging: "Epitex Refill 10m # Fine",
    moq: "",
    price: "21.00",
    registration_number: {
      id: "#N/A",
      my: " GMD98397418518A  22-Jan-23 ",
      sg: " ES0001218 ",
    },
    created_at: "2019-11-17T13:01:09.222592Z",
    updated_at: "2019-11-21T06:13:11.395739Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 18,
    category_id: 1,
    class1_id: 6,
    class2_id: 16,
    class3_id: 17,
    group_id: 18,
  },
  {
    id: 29,
    code: "0104B514-0030",
    packaging: "Epitex Refill 10m # Medium",
    moq: "",
    price: "49.00",
    registration_number: {
      id: "#N/A",
      my: " GMD98397418518A  22-Jan-23 ",
      sg: " ES0001218 ",
    },
    created_at: "2019-11-17T13:01:09.222592Z",
    updated_at: "2019-11-21T06:13:11.395739Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 18,
    category_id: 1,
    class1_id: 6,
    class2_id: 16,
    class3_id: 17,
    group_id: 18,
  },
  {
    id: 30,
    code: "0104B513-0000",
    packaging: "Epitex Starter Kit",
    moq: "",
    price: "28.00",
    registration_number: {
      id: "#N/A",
      my: " GMD98397418518A  22-Jan-23 ",
      sg: " ES0001218 ",
    },
    created_at: "2019-11-17T13:01:09.222592Z",
    updated_at: "2019-11-21T06:13:11.395739Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 18,
    category_id: 1,
    class1_id: 6,
    class2_id: 16,
    class3_id: 17,
    group_id: 18,
  },
  {
    id: 31,
    code: "0104F005-0000",
    packaging: "Eugenol Cement 25g Liquid",
    moq: "8",
    price: "49.00",
    registration_number: {
      id: "#N/A",
      my: "  -   ",
      sg: "#N/A",
    },
    created_at: "2019-11-17T13:01:09.222592Z",
    updated_at: "2019-11-21T06:13:11.395739Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 18,
    category_id: 1,
    class1_id: 6,
    class2_id: 18,
    class3_id: 19,
    group_id: 19,
  },
  {
    id: 32,
    code: "0104F004-0000",
    packaging: "Eugenol Cement 50g Powder",
    moq: "8",
    price: "32.00",
    registration_number: {
      id: "#N/A",
      my: "  -   ",
      sg: "#N/A",
    },
    created_at: "2019-11-17T13:01:09.222592Z",
    updated_at: "2019-11-21T06:13:11.395739Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 18,
    category_id: 1,
    class1_id: 6,
    class2_id: 18,
    class3_id: 19,
    group_id: 19,
  },
  {
    id: 33,
    code: "132488",
    packaging: "Exabite II NDS 8-Cartridge Pack",
    moq: "",
    price: "27.00",
    registration_number: {
      id: "#N/A",
      my: " GMD13284384617A  28-Dec-22 ",
      sg: " ES0001218 ",
    },
    created_at: "2019-11-17T13:01:09.222592Z",
    updated_at: "2019-11-21T06:13:11.395739Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 18,
    category_id: 1,
    class1_id: 6,
    class2_id: 7,
    class3_id: 20,
    group_id: 20,
  },
  {
    id: 34,
    code: "138620",
    packaging: "Exaflex Injection Clinic 20-20",
    moq: "8",
    price: "8.00",
    registration_number: {
      id: "AKL 20602514293",
      my: " GMD56593427518A  6-Feb-23 ",
      sg: " ES0001218 ",
    },
    created_at: "2019-11-17T13:01:09.222592Z",
    updated_at: "2019-11-21T06:13:11.395739Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 18,
    category_id: 1,
    class1_id: 6,
    class2_id: 7,
    class3_id: 20,
    group_id: 21,
  },
  {
    id: 35,
    code: "138920",
    packaging: "Exaflex Monophase Clinic 20-20",
    moq: "8",
    price: "43.00",
    registration_number: {
      id: "AKL 20602514297",
      my: " GMD56593427518A  6-Feb-23 ",
      sg: " ES0001218 ",
    },
    created_at: "2019-11-17T13:01:09.222592Z",
    updated_at: "2019-11-21T06:13:11.395739Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 18,
    category_id: 1,
    class1_id: 6,
    class2_id: 7,
    class3_id: 20,
    group_id: 21,
  },
  {
    id: 36,
    code: "138801",
    packaging: "Exaflex Putty Standard Pack1-1",
    moq: "8",
    price: "20.00",
    registration_number: {
      id: "AKL 20602512475",
      my: " GMD56593427518A  6-Feb-23 ",
      sg: " ES0001218 ",
    },
    created_at: "2019-11-17T13:01:09.222592Z",
    updated_at: "2019-11-21T06:13:11.395739Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 18,
    category_id: 1,
    class1_id: 6,
    class2_id: 7,
    class3_id: 20,
    group_id: 21,
  },
  {
    id: 37,
    code: "138520",
    packaging: "Exaflex Regular Clinic (20-20)",
    moq: "8",
    price: "39.00",
    registration_number: {
      id: "AKL 20602512684",
      my: " GMD56593427518A  6-Feb-23 ",
      sg: " ES0001218 ",
    },
    created_at: "2020-04-17T13:01:09.898559Z",
    updated_at: "2020-04-17T13:01:09.898559Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 18,
    category_id: 1,
    class1_id: 6,
    class2_id: 7,
    class3_id: 20,
    group_id: 21,
  },
  {
    id: 38,
    code: "137206",
    packaging: "Examix Heavy Body N.D.S. 2-Cartridge Pkg",
    moq: "8",
    price: "5.00",
    registration_number: {
      id: "#N/A",
      my: " GMD35987384517A  28-Dec-22 ",
      sg: " ES0001218 ",
    },
    created_at: "2020-04-17T13:01:09.9174Z",
    updated_at: "2020-04-17T13:01:09.9174Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 18,
    category_id: 1,
    class1_id: 6,
    class2_id: 7,
    class3_id: 20,
    group_id: 22,
  },
  {
    id: 39,
    code: "137106",
    packaging: "Examix Injection NDS 2cart Pkg",
    moq: "8",
    price: "8.00",
    registration_number: {
      id: "AKL 20602514543",
      my: " GMD35987384517A  28-Dec-22 ",
      sg: " ES0001218 ",
    },
    created_at: "2020-04-17T13:01:09.933687Z",
    updated_at: "2020-04-17T13:01:09.933687Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 18,
    category_id: 1,
    class1_id: 6,
    class2_id: 7,
    class3_id: 20,
    group_id: 22,
  },
  {
    id: 40,
    code: "150100",
    packaging: "Examix Intraoral Tips, Pkg of 100",
    moq: "",
    price: "34.00",
    registration_number: {
      id: "#N/A",
      my: " GMD91273404618A  16-Jan-23 ",
      sg: " ES0001218 ",
    },
    created_at: "2020-04-17T13:01:09.951338Z",
    updated_at: "2020-04-17T13:01:09.951338Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 18,
    category_id: 1,
    class1_id: 6,
    class2_id: 7,
    class3_id: 20,
    group_id: 23,
  },
  {
    id: 41,
    code: "137406",
    packaging: "Examix Monophase NDS 2cart Pkg",
    moq: "8",
    price: "20.00",
    registration_number: {
      id: "AKL 20602512685",
      my: " GMD35987384517A  28-Dec-22 ",
      sg: " ES0001218 ",
    },
    created_at: "2020-04-17T13:01:09.967536Z",
    updated_at: "2020-04-17T13:01:09.967536Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 18,
    category_id: 1,
    class1_id: 6,
    class2_id: 7,
    class3_id: 20,
    group_id: 22,
  },
  {
    id: 42,
    code: "137016",
    packaging: "Examix Regular NDS 2cart Pkg",
    moq: "8",
    price: "43.00",
    registration_number: {
      id: "AKL 20602514934",
      my: " GMD35987384517A  28-Dec-22 ",
      sg: " ES0001218 ",
    },
    created_at: "2020-04-17T13:01:09.984009Z",
    updated_at: "2020-04-17T13:01:09.984009Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 18,
    category_id: 1,
    class1_id: 6,
    class2_id: 7,
    class3_id: 20,
    group_id: 22,
  },
  {
    id: 43,
    code: "0101B520-0000",
    packaging: "Fitchecker 1-1 Pkg",
    moq: "8",
    price: "39.00",
    registration_number: {
      id: "#N/A",
      my: " GMD74278476518A  29-Mar-23 ",
      sg: " ES0001218 ",
    },
    created_at: "2020-04-17T13:01:10.002913Z",
    updated_at: "2020-04-17T13:01:10.002913Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 18,
    category_id: 1,
    class1_id: 6,
    class2_id: 7,
    class3_id: 20,
    group_id: 24,
  },
  {
    id: 44,
    code: "0104B587-0000",
    packaging: "Fitchecker II 1-1 Pkg",
    moq: "8",
    price: "39.00",
    registration_number: {
      id: "#N/A",
      my: " GMD74278476518A  29-Mar-23 ",
      sg: " ES0001218 ",
    },
    created_at: "2020-04-17T13:01:10.02046Z",
    updated_at: "2020-04-17T13:01:10.02046Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 18,
    category_id: 1,
    class1_id: 6,
    class2_id: 7,
    class3_id: 20,
    group_id: 25,
  },
  {
    id: 45,
    code: "0101B267-0000",
    packaging: "Freegenol 1-1 Pkg",
    moq: "8",
    price: "49.00",
    registration_number: {
      id: "AKL 20602514078",
      my: " GB66457245117  14-Jun-22 ",
      sg: " DE0500114 ",
    },
    created_at: "2020-04-17T13:01:10.039429Z",
    updated_at: "2020-04-17T13:01:10.039429Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 18,
    category_id: 1,
    class1_id: 6,
    class2_id: 18,
    class3_id: 19,
    group_id: 26,
  },
  {
    id: 46,
    code: "0101F924-0000",
    packaging: "Fuji 7 1-1 Pkg # Pink",
    moq: "8",
    price: "8.00",
    registration_number: {
      id: "AKL 20602412530",
      my: " GB83813760718  28-Mar-23 ",
      sg: " DE0016626 ",
    },
    created_at: "2020-04-17T13:01:10.061989Z",
    updated_at: "2020-04-17T13:01:10.061989Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 18,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 27,
  },
  {
    id: 47,
    code: "0126F002-0000",
    packaging: "Fuji 7 1-1 Pkg # White",
    moq: "8",
    price: "9.00",
    registration_number: {
      id: "AKL 20602412530",
      my: " GB83813760718  28-Mar-23 ",
      sg: " DE0016626 ",
    },
    created_at: "2020-04-17T13:01:10.078518Z",
    updated_at: "2020-04-17T13:01:10.078518Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 18,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 27,
  },
  {
    id: 48,
    code: "0101F935-0000",
    packaging: "Fuji 7 Capsule, Box of 50 Pink",
    moq: "8",
    price: "16.00",
    registration_number: {
      id: "AKL 20602412530",
      my: " GB83813760718  28-Mar-23 ",
      sg: " DE0016626 ",
    },
    created_at: "2020-04-17T13:01:10.096363Z",
    updated_at: "2020-04-17T13:01:10.096363Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 18,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 28,
  },
  {
    id: 49,
    code: "0126F001-0000",
    packaging: "Fuji 7 Capsule Box of 50 White",
    moq: "8",
    price: "26.00",
    registration_number: {
      id: "AKL 20602412530",
      my: " GB83813760718  28-Mar-23 ",
      sg: " DE0016626 ",
    },
    created_at: "2020-04-17T13:01:10.114623Z",
    updated_at: "2020-04-17T13:01:10.114623Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 18,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 28,
  },
  {
    id: 50,
    code: "0101B848-0000",
    packaging: "Fuji Bond LC 5g Powder",
    moq: "8",
    price: "29.00",
    registration_number: {
      id: "#N/A",
      my: "#N/A",
      sg: " DE0010671 ",
    },
    created_at: "2020-04-17T13:01:10.140088Z",
    updated_at: "2020-04-17T13:01:10.140088Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 18,
    category_id: 3,
    class1_id: 6,
    class2_id: 12,
    class3_id: 22,
    group_id: 29,
  },
  {
    id: 51,
    code: "0101B849-0000",
    packaging: "Fuji Bond LC 8g Liquid",
    moq: "8",
    price: "47.00",
    registration_number: {
      id: "#N/A",
      my: "#N/A",
      sg: " DE0010671 ",
    },
    created_at: "2020-03-22T13:01:10.15642Z",
    updated_at: "2020-03-22T13:01:10.15642Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 32,
    category_id: 3,
    class1_id: 6,
    class2_id: 12,
    class3_id: 22,
    group_id: 29,
  },
  {
    id: 52,
    code: "0101B857-0000",
    packaging: "Fuji Bond LC Standard Package",
    moq: "8",
    price: "11.00",
    registration_number: {
      id: "#N/A",
      my: " GB45924130817  15-Jan-22 ",
      sg: " DE0010671 ",
    },
    created_at: "2020-03-22T13:01:10.172176Z",
    updated_at: "2020-03-22T13:01:10.172176Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 32,
    category_id: 3,
    class1_id: 6,
    class2_id: 12,
    class3_id: 22,
    group_id: 29,
  },
  {
    id: 53,
    code: "0101B842-0000",
    packaging: "Fuji Coat LC, 5g (5.2ml)",
    moq: "8",
    price: "46.00",
    registration_number: {
      id: "#N/A",
      my: " GB68745704718  5-Mar-23 ",
      sg: " DE0010669 ",
    },
    created_at: "2020-03-22T13:01:10.1911Z",
    updated_at: "2020-03-22T13:01:10.1911Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 32,
    category_id: 1,
    class1_id: 6,
    class2_id: 12,
    class3_id: 13,
    group_id: 30,
  },
  {
    id: 54,
    code: "0101B544-0010",
    packaging: "Fuji I Capsule Box of 50",
    moq: "8",
    price: "45.00",
    registration_number: {
      id: "#N/A",
      my: " GB88072180117  16-Mar-22 ",
      sg: " DE0009868  ",
    },
    created_at: "2020-03-24T13:01:10.21232Z",
    updated_at: "2020-03-24T13:01:10.21232Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 32,
    category_id: 4,
    class1_id: 6,
    class2_id: 18,
    class3_id: 23,
    group_id: 31,
  },
  {
    id: 55,
    code: "0101B542-0220",
    packaging: "Fuji II Capsule Box of 50 # 22 Yellow Brown",
    moq: "Indent",
    price: "31.00",
    registration_number: {
      id: "#N/A",
      my: " GB11393826518 9-May-23 ",
      sg: " DE0016626 ",
    },
    created_at: "2020-03-24T13:01:10.230325Z",
    updated_at: "2020-03-24T13:01:10.230325Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 32,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 32,
  },
  {
    id: 56,
    code: "0101B677-0110",
    packaging: "Fuji II LC Caps Box of 50 # A1",
    moq: "8",
    price: "4.00",
    registration_number: {
      id: "AKL 20602412740",
      my: " GB68300291217  31-Jul-22 ",
      sg: " DE0016626 ",
    },
    created_at: "2020-03-24T13:01:10.250846Z",
    updated_at: "2020-03-24T13:01:10.250846Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 32,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 33,
  },
  {
    id: 57,
    code: "0101B677-0120",
    packaging: "Fuji II LC Caps Box of 50 # A2",
    moq: "8",
    price: "10.00",
    registration_number: {
      id: "AKL 20602412740",
      my: " GB68300291217  31-Jul-22 ",
      sg: " DE0016626 ",
    },
    created_at: "2020-03-24T13:01:10.268023Z",
    updated_at: "2020-03-24T13:01:10.268023Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 32,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 33,
  },
  {
    id: 58,
    code: "0101B677-0130",
    packaging: "Fuji II LC Caps Box of 50 # A3",
    moq: "8",
    price: "37.00",
    registration_number: {
      id: "AKL 20602412740",
      my: " GB68300291217  31-Jul-22 ",
      sg: " DE0016626 ",
    },
    created_at: "2020-03-24T13:01:10.284386Z",
    updated_at: "2020-03-24T13:01:10.284386Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 32,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 33,
  },
  {
    id: 59,
    code: "0101B677-0135",
    packaging: "Fuji II LC Caps Box of 50#A3.5",
    moq: "8",
    price: "3.00",
    registration_number: {
      id: "AKL 20602412740",
      my: " GB68300291217  31-Jul-22 ",
      sg: " DE0016626 ",
    },
    created_at: "2020-03-24T13:01:10.300523Z",
    updated_at: "2020-03-24T13:01:10.300523Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 32,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 33,
  },
  {
    id: 60,
    code: "0101B677-0140",
    packaging: "Fuji II LC Caps Box of 50 # A4",
    moq: "8",
    price: "43.00",
    registration_number: {
      id: "AKL 20602412740",
      my: " GB68300291217  31-Jul-22 ",
      sg: " DE0016626 ",
    },
    created_at: "2020-03-25T13:01:10.316246Z",
    updated_at: "2020-03-25T13:01:10.316246Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 32,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 33,
  },
  {
    id: 61,
    code: "0101B677-0220",
    packaging: "Fuji II LC Caps Box of 50 # B2",
    moq: "Indent",
    price: "15.00",
    registration_number: {
      id: "AKL 20602412740",
      my: " GB68300291217  31-Jul-22 ",
      sg: " DE0016626 ",
    },
    created_at: "2020-03-25T13:01:10.333215Z",
    updated_at: "2020-03-25T13:01:10.333215Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 32,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 33,
  },
  {
    id: 62,
    code: "0101B677-0230",
    packaging: "Fuji II LC Caps Box of 50 # B3",
    moq: "Indent",
    price: "11.00",
    registration_number: {
      id: "AKL 20602412740",
      my: " GB68300291217  31-Jul-22 ",
      sg: " DE0016626 ",
    },
    created_at: "2020-03-25T13:01:10.34859Z",
    updated_at: "2020-03-25T13:01:10.34859Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 32,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 33,
  },
  {
    id: 63,
    code: "0101B677-0320",
    packaging: "Fuji II LC Caps Box of 50 # C2",
    moq: "Indent",
    price: "43.00",
    registration_number: {
      id: "AKL 20602412740",
      my: " GB68300291217  31-Jul-22 ",
      sg: " DE0016626 ",
    },
    created_at: "2020-03-25T13:01:10.364078Z",
    updated_at: "2020-03-25T13:01:10.364078Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 32,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 33,
  },
  {
    id: 64,
    code: "0101B677-0420",
    packaging: "Fuji II LC Caps Box of 50 # D2",
    moq: "Indent",
    price: "38.00",
    registration_number: {
      id: "AKL 20602412740",
      my: " GB68300291217  31-Jul-22 ",
      sg: " DE0016626 ",
    },
    created_at: "2020-03-25T13:01:10.384812Z",
    updated_at: "2020-03-25T13:01:10.384812Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 32,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 33,
  },
  {
    id: 65,
    code: "0130F129-0010C",
    packaging: "Fuji IX 1-1 Package New",
    moq: "8",
    price: "48.00",
    registration_number: {
      id: "AKL 20602411703",
      my: " GB11393826518 9-May-23 ",
      sg: " DE0016626 ",
    },
    created_at: "2020-03-27T13:01:10.404795Z",
    updated_at: "2020-03-27T13:01:10.404795Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 32,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 34,
  },
  {
    id: 66,
    code: "0101F870-0120",
    packaging: "Fuji IX GP Capsule - Fast Set, Box of 50 # A2",
    moq: "Indent",
    price: "14.00",
    registration_number: {
      id: "#N/A",
      my: " GB11393826518 9-May-23 ",
      sg: " DE0016626 ",
    },
    created_at: "2020-03-27T13:01:10.423324Z",
    updated_at: "2020-03-27T13:01:10.423324Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 32,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 35,
  },
  {
    id: 67,
    code: "0101F870-0130",
    packaging: "Fuji IX GP Capsule - Fast Set, Box of 50 # A3",
    moq: "Indent",
    price: "40.00",
    registration_number: {
      id: "#N/A",
      my: " GB11393826518 9-May-23 ",
      sg: " DE0016626 ",
    },
    created_at: "2020-03-27T13:01:10.439378Z",
    updated_at: "2020-03-27T13:01:10.439378Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 32,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 35,
  },
  {
    id: 68,
    code: "0101F870-0135",
    packaging: "Fuji IX GP Capsule - Fast Set, Box of 50 # A3.5",
    moq: "Indent",
    price: "49.00",
    registration_number: {
      id: "#N/A",
      my: " GB11393826518 9-May-23 ",
      sg: " DE0016626 ",
    },
    created_at: "2020-03-27T13:01:10.457366Z",
    updated_at: "2020-03-27T13:01:10.457366Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 32,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 35,
  },
  {
    id: 69,
    code: "0101B816-0120",
    packaging: "Fuji IX GP Capsule, Box of 50 # A2",
    moq: "Indent",
    price: "47.00",
    registration_number: {
      id: "#N/A",
      my: " GB11393826518 9-May-23 ",
      sg: " DE0016626 ",
    },
    created_at: "2020-03-27T13:01:10.47596Z",
    updated_at: "2020-03-27T13:01:10.47596Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 32,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 36,
  },
  {
    id: 70,
    code: "0101B816-0130",
    packaging: "Fuji IX GP Capsule, Box of 50 # A3",
    moq: "Indent",
    price: "31.00",
    registration_number: {
      id: "#N/A",
      my: " GB11393826518 9-May-23 ",
      sg: " DE0016626 ",
    },
    created_at: "2020-03-27T13:01:10.493112Z",
    updated_at: "2020-03-27T13:01:10.493112Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 32,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 36,
  },
  {
    id: 71,
    code: "0101B816-0135",
    packaging: "Fuji IX GP Capsule, Box of 50 # A3.5",
    moq: "Indent",
    price: "27.00",
    registration_number: {
      id: "#N/A",
      my: " GB11393826518 9-May-23 ",
      sg: " DE0016626 ",
    },
    created_at: "2020-03-27T13:01:10.511265Z",
    updated_at: "2020-03-27T13:01:10.511265Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 32,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 36,
  },
  {
    id: 72,
    code: "0126F059-0020",
    packaging: "Fuji IX GP Extra Capsule A2",
    moq: "8",
    price: "45.00",
    registration_number: {
      id: "AKL 20602411706",
      my: " GB11393826518 9-May-23 ",
      sg: " DE0016626 ",
    },
    created_at: "2020-03-27T13:01:10.531901Z",
    updated_at: "2020-03-27T13:01:10.531901Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 32,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 37,
  },
  {
    id: 73,
    code: "0126F059-0030",
    packaging: "Fuji IX GP Extra Capsule A3",
    moq: "8",
    price: "33.00",
    registration_number: {
      id: "AKL 20602411706",
      my: " GB11393826518 9-May-23 ",
      sg: " DE0016626 ",
    },
    created_at: "2020-03-27T13:01:10.549675Z",
    updated_at: "2020-03-27T13:01:10.549675Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 32,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 37,
  },
  {
    id: 74,
    code: "0126F059-0040",
    packaging: "Fuji IX GP Extra Capsule A3.5",
    moq: "8",
    price: "38.00",
    registration_number: {
      id: "AKL 20602411706",
      my: " GB11393826518 9-May-23 ",
      sg: " DE0016626 ",
    },
    created_at: "2020-03-27T13:01:10.566815Z",
    updated_at: "2020-03-27T13:01:10.566815Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 32,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 37,
  },
  {
    id: 75,
    code: "0130F130-0010C",
    packaging: "Fuji IX Mini Pack New",
    moq: "8",
    price: "24.00",
    registration_number: {
      id: "AKL 20602411703",
      my: " GB11393826518 9-May-23 ",
      sg: "#N/A",
    },
    created_at: "2020-03-27T13:01:10.585366Z",
    updated_at: "2020-03-27T13:01:10.585366Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 32,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 34,
  },
  {
    id: 76,
    code: "0101A589-0010",
    packaging: "Fuji Lining LC 10g Powder",
    moq: "8",
    price: "50.00",
    registration_number: {
      id: "#N/A",
      my: " GB8833786216  9-Nov-21 ",
      sg: " DE0011535  ",
    },
    created_at: "2020-02-20T13:01:10.605669Z",
    updated_at: "2020-02-20T13:01:10.605669Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 19,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 38,
  },
  {
    id: 77,
    code: "0101A588-0010",
    packaging: "Fuji Lining LC 1-1 Package",
    moq: "8",
    price: "32.00",
    registration_number: {
      id: "#N/A",
      my: " GB8833786216  9-Nov-21 ",
      sg: " DE0011535  ",
    },
    created_at: "2020-02-20T13:01:10.624734Z",
    updated_at: "2020-02-20T13:01:10.624734Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 19,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 38,
  },
  {
    id: 78,
    code: "0101B590-0000",
    packaging: "Fuji Lining LC 8g Liquid",
    moq: "8",
    price: "10.00",
    registration_number: {
      id: "#N/A",
      my: " GB8833786216  9-Nov-21 ",
      sg: " DE0011535  ",
    },
    created_at: "2020-02-20T13:01:10.644006Z",
    updated_at: "2020-02-20T13:01:10.644006Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 19,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 38,
  },
  {
    id: 79,
    code: "0101E592-0000",
    packaging: "Fuji Lining LC Paste Pak, 7g Cartridge",
    moq: "8",
    price: "42.00",
    registration_number: {
      id: "#N/A",
      my: " GB55729199917 12-Apr-22 ",
      sg: " DE0011535  ",
    },
    created_at: "2020-02-20T13:01:10.662929Z",
    updated_at: "2020-02-20T13:01:10.662929Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 19,
    category_id: 2,
    class1_id: 6,
    class2_id: 12,
    class3_id: 21,
    group_id: 38,
  },
  {
    id: 80,
    code: "0122F034-0000",
    packaging: "Fuji Ortho Band Paste Pak, 2 x 13.3g Cartridge",
    moq: "8",
    price: "13.00",
    registration_number: {
      id: "#N/A",
      my: " GB53640180217 23-Mar-22 ",
      sg: " DE0009868 ",
    },
    created_at: "2020-02-23T13:01:10.683459Z",
    updated_at: "2020-02-23T13:01:10.683459Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 19,
    category_id: 4,
    class1_id: 6,
    class2_id: 18,
    class3_id: 23,
    group_id: 39,
  },
  {
    id: 81,
    code: "0101E059-0000",
    packaging: "Fuji Ortho LC 40g Powder",
    moq: "8",
    price: "23.00",
    registration_number: {
      id: "AKL 20602412746",
      my: " GB8639086516  9-Nov-21 ",
      sg: " DE0009868  ",
    },
    created_at: "2020-02-23T13:01:10.703997Z",
    updated_at: "2020-02-23T13:01:10.703997Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 19,
    category_id: 4,
    class1_id: 6,
    class2_id: 18,
    class3_id: 23,
    group_id: 40,
  },
  {
    id: 82,
    code: "0101E060-0000",
    packaging: "Fuji Ortho LC 8g Liquid",
    moq: "8",
    price: "7.00",
    registration_number: {
      id: "AKL 20602412746",
      my: " GB8639086516  9-Nov-21 ",
      sg: " DE0009868  ",
    },
    created_at: "2020-02-23T13:01:10.720538Z",
    updated_at: "2020-02-23T13:01:10.720538Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 19,
    category_id: 4,
    class1_id: 6,
    class2_id: 18,
    class3_id: 23,
    group_id: 40,
  },
  {
    id: 83,
    code: "0101B070-0000",
    packaging: "Fuji Ortho LC Capsules, Box of 50",
    moq: "8",
    price: "34.00",
    registration_number: {
      id: "AKL 20602412746",
      my: " GB8639086516  9-Nov-21 ",
      sg: " DE0009868  ",
    },
    created_at: "2020-02-23T13:01:10.737805Z",
    updated_at: "2020-02-23T13:01:10.737805Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 19,
    category_id: 4,
    class1_id: 6,
    class2_id: 18,
    class3_id: 23,
    group_id: 40,
  },
  {
    id: 84,
    code: "0101E057-0000",
    packaging: "Fuji Ortho LC Intro Pack 1-1",
    moq: "8",
    price: "32.00",
    registration_number: {
      id: "AKL 20602412746",
      my: " GB8639086516  9-Nov-21 ",
      sg: " DE0009868  ",
    },
    created_at: "2020-02-25T13:01:10.755496Z",
    updated_at: "2020-02-25T13:01:10.755496Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 19,
    category_id: 4,
    class1_id: 6,
    class2_id: 18,
    class3_id: 23,
    group_id: 40,
  },
  {
    id: 85,
    code: "0101B859-0000",
    packaging: "Fuji Ortho Self Cure 40g Powder",
    moq: "8",
    price: "22.00",
    registration_number: {
      id: "AKL 20602412746",
      my: " GB76676325917  24-Aug-22 ",
      sg: " DE0009868  ",
    },
    created_at: "2020-02-25T13:01:10.775602Z",
    updated_at: "2020-02-25T13:01:10.775602Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 4,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 19,
    category_id: 4,
    class1_id: 6,
    class2_id: 18,
    class3_id: 23,
    group_id: 41,
  },
  {
    id: 86,
    code: "0101B860-0000",
    packaging: "Fuji Ortho Self Cure 8g Liquid",
    moq: "8",
    price: "9.00",
    registration_number: {
      id: "AKL 20602412746",
      my: " GB76676325917  24-Aug-22 ",
      sg: " DE0009868  ",
    },
    created_at: "2020-02-25T13:01:10.794569Z",
    updated_at: "2020-02-25T13:01:10.794569Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 5,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 19,
    category_id: 4,
    class1_id: 6,
    class2_id: 18,
    class3_id: 23,
    group_id: 41,
  },
  {
    id: 87,
    code: "0101B887-0000",
    packaging: "Fuji Plus 1-1 Pkg with Conditioner",
    moq: "8",
    price: "44.00",
    registration_number: {
      id: "AKL 20602817464",
      my: " GB8153186416  9-Nov-21 ",
      sg: " DE0009868  ",
    },
    created_at: "2020-02-27T13:01:10.817569Z",
    updated_at: "2020-02-27T13:01:10.817569Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 5,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 19,
    category_id: 4,
    class1_id: 6,
    class2_id: 18,
    class3_id: 23,
    group_id: 42,
  },
  {
    id: 88,
    code: "0101B888-0000",
    packaging: "Fuji Plus 15g Powder",
    moq: "8",
    price: "8.00",
    registration_number: {
      id: "AKL 20602817464",
      my: " GB8153186416  9-Nov-21 ",
      sg: " DE0009868  ",
    },
    created_at: "2020-02-27T13:01:10.835955Z",
    updated_at: "2020-02-27T13:01:10.835955Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 5,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 19,
    category_id: 4,
    class1_id: 6,
    class2_id: 18,
    class3_id: 23,
    group_id: 42,
  },
  {
    id: 89,
    code: "0101B889-0000",
    packaging: "Fuji Plus 8g Liquid",
    moq: "8",
    price: "39.00",
    registration_number: {
      id: "AKL 20602817464",
      my: " GB8153186416  9-Nov-21 ",
      sg: " DE0009868  ",
    },
    created_at: "2020-02-27T13:01:10.853349Z",
    updated_at: "2020-02-27T13:01:10.853349Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 5,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 19,
    category_id: 4,
    class1_id: 6,
    class2_id: 18,
    class3_id: 23,
    group_id: 42,
  },
  {
    id: 90,
    code: "0101B891-0100",
    packaging: "Fuji Plus Capsules, Box of 50",
    moq: "8",
    price: "19.00",
    registration_number: {
      id: "AKL 20602817200",
      my: " GB8153186416  9-Nov-21 ",
      sg: " DE0009868  ",
    },
    created_at: "2020-02-28T13:01:10.872144Z",
    updated_at: "2020-02-28T13:01:10.872144Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 5,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 19,
    category_id: 4,
    class1_id: 6,
    class2_id: 18,
    class3_id: 23,
    group_id: 42,
  },
  {
    id: 91,
    code: "0101B890-0100",
    packaging: "Fuji Plus Conditioner 6.5ml",
    moq: "8",
    price: "43.00",
    registration_number: {
      id: "#N/A",
      my: " GB67470217617  30-May-22 ",
      sg: " DE0009829  ",
    },
    created_at: "2020-01-19T13:01:10.890435Z",
    updated_at: "2020-01-19T13:01:10.890435Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 5,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 15,
    category_id: 4,
    class1_id: 6,
    class2_id: 18,
    class3_id: 23,
    group_id: 42,
  },
  {
    id: 92,
    code: "0101E897-0020",
    packaging: "Fuji Plus EWT 15g Powder",
    moq: "Indent",
    price: "7.00",
    registration_number: {
      id: "#N/A",
      my: "  -   ",
      sg: "#N/A",
    },
    created_at: "2020-01-19T13:01:10.907352Z",
    updated_at: "2020-01-19T13:01:10.907352Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 5,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 15,
    category_id: 4,
    class1_id: 6,
    class2_id: 18,
    class3_id: 23,
    group_id: 42,
  },
  {
    id: 93,
    code: "890222",
    packaging: "Fuji Rock EP Golden Brown 5kg",
    moq: "8",
    price: "40.00",
    registration_number: {
      id: "Non Medical Device",
      my: " not MD ",
      sg: " NMD ",
    },
    created_at: "2020-01-19T13:01:10.93052Z",
    updated_at: "2020-01-19T13:01:10.93052Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 5,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 15,
    category_id: 1,
    class1_id: 1,
    class2_id: 24,
    class3_id: 25,
    group_id: 43,
  },
  {
    id: 94,
    code: "0101B048-0000",
    packaging: "Fuji Varnish, 10g",
    moq: "8",
    price: "9.00",
    registration_number: {
      id: "#N/A",
      my: "  -   ",
      sg: "#N/A",
    },
    created_at: "2020-01-19T13:01:10.951016Z",
    updated_at: "2020-01-19T13:01:10.951016Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 5,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 15,
    category_id: 1,
    class1_id: 6,
    class2_id: 12,
    class3_id: 13,
    group_id: 44,
  },
  {
    id: 95,
    code: "0101B044-1000",
    packaging: "Fuji Varnish, 5g",
    moq: "8",
    price: "19.00",
    registration_number: {
      id: "#N/A",
      my: "  -   ",
      sg: "#N/A",
    },
    created_at: "2020-01-19T13:01:10.967553Z",
    updated_at: "2020-01-19T13:01:10.967553Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 5,
      ph: 5,
      sg: 5,
      th: 5,
      vn: 5,
    },
    photos: null,
    summary: "",
    qty: 15,
    category_id: 1,
    class1_id: 6,
    class2_id: 12,
    class3_id: 13,
    group_id: 44,
  },
  {
    id: 96,
    code: "800018",
    packaging: "Fuji Vest II Liquid, 900cc",
    moq: "8",
    price: "36.00",
    registration_number: {
      id: "#N/A",
      my: "  -   ",
      sg: " NMD ",
    },
    created_at: "2020-01-19T13:01:10.988464Z",
    updated_at: "2020-01-19T13:01:10.988464Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 4,
      ph: 4,
      sg: 5,
      th: 4,
      vn: 4,
    },
    photos: null,
    summary: "",
    qty: 15,
    category_id: 1,
    class1_id: 1,
    class2_id: 24,
    class3_id: 26,
    group_id: 45,
  },
  {
    id: 97,
    code: "800016",
    packaging: "Fuji Vest II Powder, 60 x 100 predose",
    moq: "8",
    price: "25.00",
    registration_number: {
      id: "#N/A",
      my: "  -   ",
      sg: " NMD ",
    },
    created_at: "2020-01-19T13:01:11.004844Z",
    updated_at: "2020-01-19T13:01:11.004844Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 5,
      ph: 5,
      sg: 5,
      th: 5,
      vn: 5,
    },
    photos: null,
    summary: "",
    qty: 15,
    category_id: 1,
    class1_id: 1,
    class2_id: 24,
    class3_id: 26,
    group_id: 45,
  },
  {
    id: 98,
    code: "0123F100-0000",
    packaging: "FujiCEM Refill Pack, 13.3g Cartridge",
    moq: "8",
    price: "43.00",
    registration_number: {
      id: "#N/A",
      my: " GB2497986616  9-Nov-21 ",
      sg: " DE0009868  ",
    },
    created_at: "2020-01-19T13:01:11.022159Z",
    updated_at: "2020-01-19T13:01:11.022159Z",
    active: true,
    remarks: "",
    class4: {
      id: 4,
      my: 5,
      ph: 5,
      sg: 5,
      th: 5,
      vn: 5,
    },
    photos: null,
    summary: "",
    qty: 16,
    category_id: 4,
    class1_id: 6,
    class2_id: 18,
    class3_id: 23,
    group_id: 46,
  },
  {
    id: 99,
    code: "0130F178-0000",
    packaging: "FujiTEMP LT, 2x 13.3g Cartridge    ",
    moq: "Indent",
    price: "30.00",
    registration_number: {
      id: "#N/A",
      my: "  -   ",
      sg: "#N/A",
    },
    created_at: "2020-01-19T13:01:11.040407Z",
    updated_at: "2020-01-19T13:01:11.040407Z",
    active: true,
    remarks: "",
    class4: {
      id: 5,
      my: 5,
      ph: 5,
      sg: 5,
      th: 5,
      vn: 5,
    },
    photos: null,
    summary: "",
    qty: 10,
    category_id: 4,
    class1_id: 6,
    class2_id: 18,
    class3_id: 23,
    group_id: 47,
  },
  {
    id: 100,
    code: "0130F151-0020",
    packaging: "G-aenial Universal Flo A2",
    moq: "8",
    price: "28.00",
    registration_number: {
      id: "AKL 20602411701",
      my: " GB11973932318  17-Jun-23 ",
      sg: " DE0011862 ",
    },
    created_at: "2020-01-19T13:01:11.06362Z",
    updated_at: "2020-01-19T13:01:11.06362Z",
    active: true,
    remarks: "",
    class4: {
      id: 5,
      my: 5,
      ph: 5,
      sg: 5,
      th: 5,
      vn: 5,
    },
    photos: null,
    summary: "",
    qty: 20,
    category_id: 5,
    class1_id: 6,
    class2_id: 12,
    class3_id: 27,
    group_id: 48,
  },
  {
    id: 101,
    code: "0130F151-0030",
    packaging: "G-aenial Universal Flo A3",
    moq: "8",
    price: "17.00",
    registration_number: {
      id: "AKL 20602411701",
      my: " GB11973932318  17-Jun-23 ",
      sg: " DE0011862 ",
    },
    created_at: "2019-12-19T13:01:11.081242Z",
    updated_at: "2019-12-19T13:01:11.081242Z",
    active: true,
    remarks: "",
    class4: {
      id: 5,
      my: 5,
      ph: 5,
      sg: 5,
      th: 5,
      vn: 5,
    },
    photos: null,
    summary: "",
    qty: 10,
    category_id: 5,
    class1_id: 6,
    class2_id: 12,
    class3_id: 27,
    group_id: 48,
  },
  {
    id: 102,
    code: "0130F151-0040",
    packaging: "G-aenial Universal Flo A3.5",
    moq: "8",
    price: "3.00",
    registration_number: {
      id: "AKL 20602411701",
      my: " GB11973932318  17-Jun-23 ",
      sg: " DE0011862 ",
    },
    created_at: "2019-12-19T13:01:11.100515Z",
    updated_at: "2019-12-19T13:01:11.100515Z",
    active: true,
    remarks: "",
    class4: {
      id: 5,
      my: 5,
      ph: 5,
      sg: 4,
      th: 5,
      vn: 5,
    },
    photos: null,
    summary: "",
    qty: 11,
    category_id: 5,
    class1_id: 6,
    class2_id: 12,
    class3_id: 27,
    group_id: 48,
  },
  {
    id: 103,
    code: "0130F151-0050",
    packaging: "G-aenial Universal Flo A4",
    moq: "8",
    price: "13.00",
    registration_number: {
      id: "AKL 20602411701",
      my: " GB11973932318  17-Jun-23 ",
      sg: " DE0011862 ",
    },
    created_at: "2019-12-19T13:01:11.123605Z",
    updated_at: "2019-12-19T13:01:11.123605Z",
    active: true,
    remarks: "",
    class4: {
      id: 5,
      my: 5,
      ph: 5,
      sg: 4,
      th: 5,
      vn: 5,
    },
    photos: null,
    summary: "",
    qty: 30,
    category_id: 5,
    class1_id: 6,
    class2_id: 12,
    class3_id: 27,
    group_id: 48,
  },
  {
    id: 104,
    code: "0130F151-0150",
    packaging: "G-aenial Universal Flo AE",
    moq: "8",
    price: "32.00",
    registration_number: {
      id: "AKL 20602411701",
      my: " GB11973932318  17-Jun-23 ",
      sg: " DE0011862 ",
    },
    created_at: "2019-12-19T13:01:11.14149Z",
    updated_at: "2019-12-19T13:01:11.14149Z",
    active: true,
    remarks: "",
    class4: {
      id: 5,
      my: 5,
      ph: 5,
      sg: 4,
      th: 5,
      vn: 5,
    },
    photos: null,
    summary: "",
    qty: 10,
    category_id: 5,
    class1_id: 6,
    class2_id: 12,
    class3_id: 27,
    group_id: 48,
  },
  {
    id: 105,
    code: "0130F151-0100",
    packaging: "G-aenial Universal Flo AO2",
    moq: "8",
    price: "44.00",
    registration_number: {
      id: "AKL 20602411701",
      my: " GB11973932318  17-Jun-23 ",
      sg: " DE0011862 ",
    },
    created_at: "2019-12-19T13:01:11.157962Z",
    updated_at: "2019-12-19T13:01:11.157962Z",
    active: true,
    remarks: "",
    class4: {
      id: 5,
      my: 5,
      ph: 5,
      sg: 4,
      th: 5,
      vn: 5,
    },
    photos: null,
    summary: "",
    qty: 22,
    category_id: 5,
    class1_id: 6,
    class2_id: 12,
    class3_id: 27,
    group_id: 48,
  },
  {
    id: 106,
    code: "0130F151-0110",
    packaging: "G-aenial Universal Flo AO3",
    moq: "8",
    price: "24.00",
    registration_number: {
      id: "AKL 20602411701",
      my: " GB11973932318  17-Jun-23 ",
      sg: " DE0011862 ",
    },
    created_at: "2019-12-19T13:01:11.17329Z",
    updated_at: "2019-12-19T13:01:11.17329Z",
    active: true,
    remarks: "",
    class4: {
      id: 5,
      my: 5,
      ph: 5,
      sg: 4,
      th: 5,
      vn: 5,
    },
    photos: null,
    summary: "",
    qty: 10,
    category_id: 5,
    class1_id: 6,
    class2_id: 12,
    class3_id: 27,
    group_id: 48,
  },
  {
    id: 107,
    code: "0130F151-0060",
    packaging: "G-aenial Universal Flo B1",
    moq: "8",
    price: "22.00",
    registration_number: {
      id: "AKL 20602411701",
      my: " GB11973932318  17-Jun-23 ",
      sg: " DE0011862 ",
    },
    created_at: "2019-12-19T13:01:11.188661Z",
    updated_at: "2019-12-19T13:01:11.188661Z",
    active: true,
    remarks: "",
    class4: {
      id: 5,
      my: 5,
      ph: 5,
      sg: 4,
      th: 5,
      vn: 5,
    },
    photos: null,
    summary: "",
    qty: 5,
    category_id: 5,
    class1_id: 6,
    class2_id: 12,
    class3_id: 27,
    group_id: 48,
  },
]
