import React, { Component } from "react"
import PropTypes from "prop-types"
import { Box } from "rebass"
import { ThemeProvider } from "@material-ui/core/styles"

import { addField, FieldTitle, ResettableTextField } from "react-admin"
import sanitizeRestProps from "./sanitizeRestProps"
import TextMessage from "./utils"
import { themeRaTA } from "./themeTextArea"

export class RaTextArea extends Component {
  handleBlur = eventOrValue => {
    this.props.onBlur(eventOrValue)
    this.props.input.onBlur(eventOrValue)
  }

  handleFocus = event => {
    this.props.onFocus(event)
    this.props.input.onFocus(event)
  }

  handleChange = eventOrValue => {
    this.props.onChange(eventOrValue)
    this.props.input.onChange(eventOrValue)
  }

  render() {
    const {
      className,
      input,
      isRequired,
      label,
      meta,
      options,
      resource,
      source,
      type,
      notetext,
      updateField,
      ...rest
    } = this.props
    if (typeof meta === "undefined") {
      throw new Error(
        "The RaTextArea component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details."
      )
    }
    const { touched, error } = meta

    return (
      <Box>
        <ThemeProvider theme={themeRaTA}>
          <ResettableTextField
            variant="filled"
            type={type}
            label={
              label === false ? (
                label
              ) : (
                <FieldTitle
                  label={label}
                  source={source}
                  resource={resource}
                  isRequired={isRequired}
                />
              )
            }
            error={!!(touched && error)}
            helperText={touched && error}
            className={className}
            {...options}
            {...sanitizeRestProps(rest)}
            {...input}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onChange={this.handleChange}
          />
        </ThemeProvider>
        {notetext && <TextMessage notetext={notetext} />}
      </Box>
    )
  }
}

RaTextArea.propTypes = {
  className: PropTypes.string,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  meta: PropTypes.object,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  type: PropTypes.string,
  notetext: PropTypes.string,
}

RaTextArea.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  options: {},
  type: "text",
}

export default addField(RaTextArea)
