import React, { useState } from "react";
import { useDataProvider, useRefresh } from "react-admin";
import { Flex, Box } from "rebass";
import Paper from "@material-ui/core/Paper";
import { TextRecord } from "../../../components";
import { Button, CircularProgress } from "@material-ui/core";
import { STATUSPO } from "../../../utils";
const DealerView = (props) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  const { record, translate, po, notify } = props;
  const onSendToDealer = () => {
    if (po && po.id) {
      setLoading(true);
      const params = {
        ...po,
        status: STATUSPO.draft,
      };
      dataProvider
        .update("purchaseOrder", {
          id: po.id,
          data: params,
          previousData: po,
        })
        .then(({ data }) => {
          setLoading(false);
          // console.log(data);
          if (data && data.id) {
            notify("resources.notification.updated", "success", {
              smart_count: 1,
            });
            refresh();
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log(e.message);
        });
    }
  };
  return (
    <Flex width={[1]} flexDirection={"column"} p={[1]} pb={[3]}>
      <Paper elevation={3} style={{ minHeight: 200 }}>
        <Box width={[1]} p={[3]}>
          <Flex
            flexDirection={"row"}
            width={[1]}
            py={[2]}
            flexWrap={"wrap"}
            sx={{ "&>div": { pr: [4] } }}
          >
            <Box>
              <TextRecord
                source={"ref"}
                label={"resources.orders.fields.name"}
                record={record}
              />
            </Box>
            <Box>
              <TextRecord
                source={"customer_info.ref"}
                label={"resources.orders.fields.customer_ref"}
                record={record}
              />
            </Box>
            <Box>
              <TextRecord
                source={"dealer_info.name"}
                label={"resources.orders.fields.dealer"}
                record={record}
              />
            </Box>
            <Box>
              <TextRecord
                source={"delivery_date"}
                isDate={true}
                formatDate={"DD-MMM-YYYY"}
                label={"resources.orders.fields.delivery_date"}
                record={record}
              />
            </Box>
            <Box>
              <TextRecord
                source={"status"}
                label={"resources.orders.fields.status"}
                record={po}
              />
            </Box>
          </Flex>
          <Box width={[1]}>
            <TextRecord
              source={"remarks"}
              label={"resources.orders.fields.remarks"}
              record={po}
            />
          </Box>
          {po && po.status === STATUSPO.sent && (
            <Box width={[1]}>
              <Button
                variant={"contained"}
                color={"primary"}
                style={{ textTransform: "capitalize", borderRadius: 16 }}
                onClick={() => onSendToDealer()}
                disabled={loading}
                startIcon={
                  loading ? (
                    <CircularProgress color={"secondary"} size={16} />
                  ) : null
                }
              >
                {translate("resources.buttons.sent_to_dealer")}
              </Button>
            </Box>
          )}
        </Box>
      </Paper>
    </Flex>
  );
};

export default DealerView;
