import React from "react";
import { Create, useTranslate, useQueryWithStore, Loading } from "react-admin";
import { Flex, Box } from "rebass";
import { get } from "lodash";
import { useLocation } from "react-router-dom";
// import Button from "@material-ui/core/Button"
import { BetterPoForm, Title } from "../components";
// import { PaperIcon } from "../components/icon_svg"
import { FooterPOToolbar } from "./components";
// import { FormFields } from "./components/grid";
import { EditFormFields } from "./components/promotions";
import { getUser } from "../utils";
import ListButton from "./components/promotions/ListButton";
const ActionForm = (props) => {
  const translate = useTranslate();
  const location = useLocation();
  const { pt = 3 } = props;
  const basepathUrl =
    location.search && location.search === "?i=inventories"
      ? "/inventories"
      : props.basePath;
  return (
    <Flex
      flexDirection={"row"}
      justifyContent={"space-between"}
      width={[1]}
      pt={[pt]}
      pb={[2]}
    >
      <Box width={[1]}>
        <Title fontWeight={"bold"} fontSize={[3]}>
          {translate(props.title)}
        </Title>
      </Box>
      <Flex width={[1]} flexDirection={"row"} justifyContent={"flex-end"}>
        {/* <Button
          color="primary"
          style={{ textTransform: "capitalize", marginRight: 8 }}
          startIcon={<PaperIcon />}
        >
          {translate("resources.buttons.import_from_csv")}
        </Button> */}
        <ListButton
          basepath={basepathUrl}
          record={props.data}
          history={props.history}
        />
      </Flex>
    </Flex>
  );
};

const initValues = {
  items: [],
};

const MainCreate = (props) => {
  const { hasList, ...rest } = props;
  const user = getUser();
  const dealerId = get(user, "dealer_id");
  const { loaded, data } = useQueryWithStore({
    type: "getOne",
    resource: "dealers",
    payload: { id: dealerId },
  });

  if (!loaded) {
    return (
      <Flex width={[1]} justifyContent={"center"} alignItems={"center"}>
        <Loading />
      </Flex>
    );
  }
  return (
    <Create
      {...rest}
      actions={<ActionForm title={"resources.po.new_po"} {...props} />}
    >
      <BetterPoForm
        {...rest}
        toolbar={<FooterPOToolbar />}
        typePostUrl={"CREATE"}
        initialValues={{ ...initValues, dealer_id: dealerId }}
      >
        <EditFormFields
          {...rest}
          countryId={get(data, "country_id", "")}
          dealerId={dealerId}
          typePostUrl={"CREATE"}
        />
      </BetterPoForm>
    </Create>
  );
};

class POCreateDealer extends React.Component {
  state = {
    isBackButtonClicked: false,
    fullUrl: window.location.href,
  };
  componentDidMount() {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = this._backConfirm;
  }
  componentWillUnmount() {
    window.onpopstate = () => {};
  }
  _backConfirm = async () => {
    if (this.state.fullUrl === window.location.href) {
      let event = window.confirm("Are you sure want to leave this page?");
      if (!event) {
        window.history.pushState(null, "", window.location.href);
      } else {
        this.props.history.push("/purchaseOrder");
      }
    }
  };
  render() {
    return <MainCreate {...this.props} />;
  }
}

export default POCreateDealer;
