import React from "react";
import { Flex } from "rebass";
import {
  BetterEditButton,
  BetterShowButton,
  BetterChartButton,
  BetterShowUrlButton,
  BetterEditUrlButton,
} from "../";
import { ROLE_USER } from "../../utils";
import { MemberButton, ChannelButton } from "./LinkButton";
// import { showAdminSales } from "../../utils";
export const ButtonMemberEditView = (props) => {
  return (
    <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
      <MemberButton {...props} />
      <BetterEditButton {...props} />
    </Flex>
  );
};

export const ButtonChannelEditView = (props) => {
  return (
    <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
      <ChannelButton {...props} />
      <BetterEditButton {...props} />
    </Flex>
  );
};

export const ButtonEditView = (props) => {
  return (
    <Flex
      flexDirection={["row"]}
      justifyContent={"flex-end"}
      style={{ height: 33 }}
    >
      <BetterEditButton {...props} />
    </Flex>
  );
};

export const ButtonShowEditView = (props) => {
  // const { permissions, taburl } = props;
  // if (taburl === "priceBooks") {
  //   return (
  //     <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
  //       <BetterShowButton {...props} icon={props.iconshow} />
  //       {showAdminSales(permissions) && <BetterEditButton {...props} />}
  //     </Flex>
  //   );
  // }
  return (
    <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
      <BetterShowButton {...props} icon={props.iconshow} />
      <BetterEditButton {...props} />
    </Flex>
  );
};

export const ButtonShowUrlEditView = (props) => {
  const { permissions } = props;
  if (permissions === ROLE_USER.country_manager) {
    return (
      <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
        <BetterShowUrlButton {...props} icon={props.iconshow} />
      </Flex>
    );
  }
  // if (taburl === "priceBooks") {
  //   return (
  //     <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
  //       <BetterShowButton {...props} icon={props.iconshow} />
  //       {showAdminSales(permissions) && <BetterEditButton {...props} />}
  //     </Flex>
  //   );
  // }
  return (
    <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
      <BetterShowUrlButton {...props} icon={props.iconshow} />
      <BetterEditUrlButton {...props} />
    </Flex>
  );
};

export const ButtonChartEditView = (props) => (
  <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
    <BetterChartButton {...props} />
    <BetterEditButton {...props} />
  </Flex>
);
