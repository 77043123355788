import React, { useState, useEffect } from "react";
import { Flex, Text, Box } from "rebass";
import { get, filter, sumBy } from "lodash";
import { Button } from "@material-ui/core";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import CancelIcon from "@material-ui/icons/Cancel";
import { DialogHeader, PromoType_B } from "./edit";
import {
  useStoreTemp,
  ArListBuyXGetY,
  ArFreeGridXY,
  useStoreTempABCD,
} from "../../../components/argrid";
import { getMOQIndex, getMinMOQ } from "./edit/utils";
//getTotalQtyFreeProduct
import { ShowMessage } from "./edit/ShowMessage";

import {
  getTotalQtyFreeProductPromo,
  getTotalSelectedQtyFree,
} from "../../../components/argrid/utils";
import { getSubtotal } from "../../../components";
// import { getTotalFree } from "../../../components/argrid/utilsABCD";

const PromoTypeB = {
  buyxgety: "Buy X Get Y",
  buyabgetcd: "Buy AB Get CD",
  discount: "Discount",
  sp: "Special Price",
};

// const getSubtotal = (items) => {
//   const newList = map(items, function (o) {
//     const discountPrice = get(o, "discounted_price", 0) * 1;
//     const price = get(o, "price", 0) * 1;
//     const newPrice = discountPrice === 0 ? price : discountPrice;
//     return { id: o.id, subtotal: o.qty * newPrice };
//   });

//   return newList;
// };

const DialogListGridBuyXGetY = (props) => {
  const {
    open,
    setOpen,
    width,
    itemPromotion,
    translate,
    totalQty,
    totalAmount,
    formData,
    onSaveOrder,
    isOAAsossiate,
    countryId,
    country,
  } = props;

  const products = get(formData, "items", []);
  //   const dealerId = get(formData, "dealer_id");
  // console.log(dealerId, formData);
  // const [tempList, setTempList] = useState([]);
  const { tempList, setTempList } = useStoreTemp();
  const {
    tempListBuy,
    tempListBuyXY,
    tempListFreeXY,
    setTempListBuyXY,
    setTempListFreeXY,
  } = useStoreTempABCD();
  const [show, setShow] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalAmt, setTotalAmt] = useState(0);
  const [thisQuantity, setThisQuantity] = useState(0);
  const [thisAmt, setThisAmt] = useState(0);

  useEffect(() => {
    // This gets called after every render, by default
    // (the first one, and every one after that)
    // console.log("render!");
    let totalQuantityA = 0;
    let totalAmtA = 0;
    // console.log(itemPromotion);
    let myTempList = [];
    products &&
      products.length > 0 &&
      products.map((item) => {
        if (itemPromotion === null && item.promotions === null) {
          totalQuantityA = totalQuantityA + item.qty * 1;
          const subtotal = item.qty * item.price;
          totalAmtA = totalAmtA + subtotal * 1;
          // totalAmtA = totalAmtA + item.subtotal * 1;
          //   console.log(totalAmtA, item);
          myTempList.push(item);
        } else {
          if (
            itemPromotion &&
            itemPromotion.id === get(item, "promotions.id", 0) &&
            itemPromotion.promotion_type ===
              get(item, "promotions.promotion_type", "")
          ) {
            if (isOAAsossiate) {
              //   totalQuantityA = totalQuantityA + item.qty * 1;
              if (item.subtotal * 1 > 0) {
                totalQuantityA = totalQuantityA + item.qty * 1;
              }
            } else {
              if (item.subtotal * 1 > 0) {
                totalQuantityA = totalQuantityA + item.qty * 1;
              }
            }
            const discountPrice = get(item, "discounted_price", 0) * 1;
            const price = get(item, "price", 0) * 1;
            const newPrice = discountPrice === 0 ? price : discountPrice;
            if (itemPromotion.promotion_type === PromoTypeB.buyxgety) {
              const subtotal = item.qty * newPrice;
              // const subtotal = item.qty * item.price;
              totalAmtA = totalAmtA + subtotal * 1;
            } else {
              if (item.subtotal * 1 > 0) {
                totalAmtA = totalAmtA + item.subtotal * 1;
              }
            }
            // if (isOAAsossiate) {
            //   totalQuantityA = totalQuantityA + item.qty * 1;
            //   totalAmtA = totalAmtA + item.subtotal * 1;
            // } else {
            //   if (item.subtotal * 1 > 0) {
            //     totalQuantityA = totalQuantityA + item.qty * 1;
            //     totalAmtA = totalAmtA + item.subtotal * 1;
            //   }
            // }
            if (discountPrice > 0 && get(item, "unit_price", 0) === 0) {
              item["unit_price"] =
                get(item, "unit_price", 0) === 0
                  ? get(item, "price", 0)
                  : get(item, "unit_price", 0);
              item["price"] = newPrice;
            }
            myTempList.push(item);
          }
        }
        return "";
      });
    // updateDefaultList(totalAmtA, totalQuantityA);
    const newTA = getSubtotal(products);
    const totalQtySum = sumBy(products, "qty");
    const newTotalAmount = sumBy(newTA, "subtotal");
    setThisAmt(totalAmtA);
    setTempList(myTempList);
    setTotalQuantity(totalQtySum - totalQuantityA);
    setTotalAmt(newTotalAmount - totalAmtA);
    setThisQuantity(totalQuantityA);

    // If you want to implement componentWillUnmount,
    // return a function from here, and React will call
    // it prior to unmounting.
    // return () => console.log("unmounting...");
  }, [
    totalQty,
    totalAmount,
    itemPromotion,
    products,
    isOAAsossiate,
    setTempList,
  ]);

  const updateTempList = (tempList1) => {
    if (tempList1 && tempList1.length > 0) {
      // let totalQuantityA = 0;
      // let totalAmtA = 0;
      // tempList1.map((item) => {
      //   if (itemPromotion === null && item.promotions === null) {
      //     totalQuantityA = totalQuantityA + item.qty * 1;
      //     totalAmtA = totalAmtA + item.subtotal * 1;
      //   } else {
      //     if (isOAAsossiate) {
      //       if (
      //         itemPromotion &&
      //         itemPromotion.id === get(item, "promotions.id", 0) &&
      //         itemPromotion.promotion_type ===
      //           get(item, "promotions.promotion_type", "") &&
      //         item.subtotal * 1 > 0
      //       ) {
      //         totalQuantityA = totalQuantityA + item.qty * 1;
      //         totalAmtA = totalAmtA + item.subtotal * 1;
      //       }
      //     } else {
      //       if (
      //         itemPromotion &&
      //         itemPromotion.id === get(item, "promotions.id", 0) &&
      //         itemPromotion.promotion_type ===
      //           get(item, "promotions.promotion_type", "") &&
      //         item.subtotal * 1 > 0
      //       ) {
      //         totalQuantityA = totalQuantityA + item.qty * 1;
      //         totalAmtA = totalAmtA + item.subtotal * 1;
      //       }
      //     }
      //   }
      //   return "";
      // });

      // setThisAmt(totalAmtA);
      // setThisQuantity(totalQuantityA);
      // updateTotalQuantity(tempList1, totalQuantityA);
      updateHeaderTotal(tempList1);
      setTempList(tempList1);
    } else {
      updateHeaderTotal(tempList1);
      setTempList([]);
    }
  };
  const updateHeaderTotal = (tempList1) => {
    let totalQuantityA = 0;
    let totalAmtA = 0;
    tempList1.map((item) => {
      if (itemPromotion === null && item.promotions === null) {
        totalQuantityA = totalQuantityA + item.qty * 1;
        totalAmtA = totalAmtA + item.subtotal * 1;
      } else {
        if (isOAAsossiate) {
          if (
            itemPromotion &&
            itemPromotion.id === get(item, "promotions.id", 0) &&
            itemPromotion.promotion_type ===
              get(item, "promotions.promotion_type", "") &&
            item.subtotal * 1 > 0
          ) {
            totalQuantityA = totalQuantityA + item.qty * 1;
            totalAmtA = totalAmtA + item.subtotal * 1;
          }
        } else {
          if (
            itemPromotion &&
            itemPromotion.id === get(item, "promotions.id", 0) &&
            itemPromotion.promotion_type ===
              get(item, "promotions.promotion_type", "") &&
            item.subtotal * 1 > 0
          ) {
            totalQuantityA = totalQuantityA + item.qty * 1;
            totalAmtA = totalAmtA + item.subtotal * 1;
          }
        }
      }
      return "";
    });

    setThisAmt(totalAmtA);
    setThisQuantity(totalQuantityA);
    updateTotalQuantity(tempList1, totalQuantityA);
  };
  const updateTotalQuantity = (tempList1, totalQuantityA) => {
    const notTempList = filter(products, function (oo) {
      return get(oo, "promotions.id", 0) !== get(itemPromotion, "id");
    });
    const listTemp = filter(tempList1, function (oo) {
      return get(oo, "promotions.id", 0) === get(itemPromotion, "id");
    });
    const totalQtySum = sumBy(listTemp, "qty");
    const totalQtyNonTempList = sumBy(notTempList, "qty") + totalQtySum;
    setTotalQuantity(totalQtyNonTempList - totalQuantityA);
  };

  const onSaveMyOrder = (promotionType) => {
    const getMOQ = filter(tempList, function (o) {
      return get(o, "foc", false) === false;
    });
    const totalMOQ = sumBy(getMOQ, function (o) {
      return o.qty;
    });
    const source = "criteria.buy_x_get_y.moqs";
    // if (promotionType === PromoType_B[0]) {
    //   source = "criteria.buy_x_get_y.moqs";
    // } else if (promotionType === PromoType_B[1]) {
    //   source = "criteria.buy_ab_get_cd.moqs";
    // } else if (promotionType === PromoType_B[2]) {
    //   source = "criteria.discount.moqs";
    // } else if (promotionType === PromoType_B[3]) {
    //   source = "criteria.price.moqs";
    // }
    const MOQIndex = getMOQIndex(itemPromotion, totalMOQ, source);
    // console.log(MOQIndex);
    let newList = [];

    tempList.map((item) => {
      let newItem = item;
      if (get(item, "foc", false) === false) {
        newItem["moq_group_index"] = MOQIndex;
      }

      if (newItem.qty > 0) {
        newList.push(newItem);
      }

      return "";
    });

    onSaveOrder(newList, products, itemPromotion);
  };

  const onSaveOrderTemp = () => {
    const promotionType = get(itemPromotion, "promotion_type", null);

    if (promotionType !== null) {
      const minMOQ = getMinMOQ(promotionType, itemPromotion);
      if (thisQuantity * 1 >= minMOQ) {
        if (promotionType === PromoType_B[0]) {
          const totalListQtyA = sumBy(tempListBuyXY, "qty");
          const qtyTotalFree = getTotalQtyFreeProductPromo(
            itemPromotion,
            totalListQtyA,
            PromoType_B[0]
          );
          const qtySelected = getTotalSelectedQtyFree(tempListFreeXY);
          const myTotal = qtyTotalFree - qtySelected;
          if (myTotal === 0) {
            onSaveMyOrder(promotionType);
            setOpen(false);
          } else {
            setShow(true);
            setMessageError(
              `Please select ${qtyTotalFree} from the Free items list`
            );
          }
        } else {
          onSaveMyOrder(promotionType);
          setOpen(false);
        }
      } else {
        const myTotalQty = sumBy(tempList, "qty");
        // console.log(myTotalQty);
        if (myTotalQty > 0) {
          setShow(true);
          setMessageError(`MOQ for this promotion is ${minMOQ}`);
        } else {
          // onSaveOrder(tempList, products, itemPromotion);
          onSaveMyOrder(promotionType);
          setOpen(false);
        }
      }
    } else {
      onSaveOrder(tempList, products, itemPromotion);
      setOpen(false);
    }
  };

  const title =
    itemPromotion === null
      ? "resources.po.listgrid.label.normal_item_price"
      : "resources.po.listgrid.label.promo_items_form";
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  const isShowFree = get(itemPromotion, "promotion_type", null);
  return (
    <SwipeableDrawer
      anchor={"left"}
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <Flex width={width} flexDirection={"column"}>
        <Flex
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={[2]}
        >
          <Text fontWeight={"bold"} ml={[2]}>
            {translate(title)}
          </Text>
          <Button
            onClick={toggleDrawer(false)}
            color='primary'
            style={{ textTransform: "capitalize", marginRight: 8 }}
            startIcon={<CancelIcon />}
          >
            {translate("resources.buttons.close")}
          </Button>
        </Flex>
        <DialogHeader
          translate={translate}
          item={itemPromotion}
          totalQuantity={totalQuantity}
          totalAmt={totalAmt}
          thisAmt={thisAmt}
          thisQuantity={thisQuantity}
          products={products}
          tempListBuy={tempListBuy}
          onSaveOrder={onSaveOrderTemp}
          isOAAsossiate={isOAAsossiate}
        />
        <Box width={[1]} px={[2]}>
          {itemPromotion !== null && isShowFree === PromoType_B[0] && (
            <ArListBuyXGetY
              listSelected={products}
              item={itemPromotion}
              promoType={isShowFree}
              translate={translate}
              tempList={tempList}
              setShow={(v) => setShow(v)}
              setMessageError={(m) => setMessageError(m)}
              setTempList={(temp) => updateTempList(temp)}
              tempListBuy={tempListBuyXY}
              setTempListBuy={(list) => setTempListBuyXY(list)}
              isOAAsossiate={isOAAsossiate}
              countryId={countryId}
              country={country}
            />
          )}

          {isShowFree !== null && isShowFree === PromoType_B[0] && (
            <ArFreeGridXY
              listSelected={products}
              item={itemPromotion}
              promoType={isShowFree}
              translate={translate}
              thisQuantity={thisQuantity}
              tempList={tempList}
              setShow={(v) => setShow(v)}
              setMessageError={(m) => setMessageError(m)}
              setTempList={(temp) => updateTempList(temp)}
              tempListBuy={tempListBuyXY}
              tempListFree={tempListFreeXY}
              setTempListFree={(list) => setTempListFreeXY(list)}
              isOAAsossiate={isOAAsossiate}
              countryId={countryId}
              country={country}
            />
          )}
        </Box>
        <ShowMessage
          open={show}
          setOpen={() => setShow(false)}
          message={messageError}
        />
      </Flex>
    </SwipeableDrawer>
  );
};

export default DialogListGridBuyXGetY;
