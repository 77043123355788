import React from "react";
import { Toolbar } from "react-admin";
import { Flex } from "rebass";
import { get } from "lodash";
import { SavePoButton } from "../../components";
import DialogButtonDelete from "./DialogButtonDelete";
export const FooterPOToolbar = (props) => {
  const { updateField, ...rest1 } = props;

  const canDelete = get(props, "record.status", "") === "Draft";
  // const can_send_back_toDealer =
  //   get(props, "record.status", "") === "Submitted";
  return (
    <Toolbar {...rest1}>
      <Flex flexDirection={"row"} width={[1]} justifyContent={"space-between"}>
        <Flex flexDirection={"row"} justifyContent={"flex-start"}>
          <SavePoButton
            {...props}
            label={"resources.buttons.save"}
            redirect={props.redirect}
            updateField={updateField}
            style={{
              marginLeft: 16,
              borderRadius: 24,
              textTransform: "capitalize",
              paddingLeft: 48,
              paddingRight: 48,
            }}
          />

          <SavePoButton
            {...props}
            label={"resources.buttons.save_as_draft"}
            redirect={props.redirect}
            updateField={updateField}
            style={{
              marginLeft: 16,
              borderRadius: 24,
              textTransform: "capitalize",
              paddingLeft: 48,
              paddingRight: 48,
            }}
          />

          {/* <SavePoButton
            {...props}
            label={"resources.buttons.send_back_to_dealer"}
            redirect={props.redirect}
            updateField={updateField}
            style={{
              marginLeft: 16,
              borderRadius: 24,
              textTransform: "capitalize",
              paddingLeft: 48,
              paddingRight: 48,
            }}
          /> */}
        </Flex>
        <Flex pr={[2]}>{canDelete && <DialogButtonDelete {...props} />}</Flex>
      </Flex>
    </Toolbar>
  );
};
