import React from "react";
import { Flex } from "rebass";
import { TextInput, BooleanInput, required, SelectInput } from "react-admin";
import { dataType } from "./utils";
import { RaTextInput, RaNumberInput } from "../../components";
const FormFieldSettings = (props) => {
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <BooleanInput source="active" label="resources.settings.fields.active" />
      <SelectInput
        source="type"
        choices={dataType}
        label="resources.settings.fields.type"
        validate={required()}
        style={{ width: 300 }}
      />
      <TextInput
        source="label"
        validate={required()}
        style={{ width: 300 }}
        label="resources.settings.fields.label"
        InputLabelProps={{ shrink: true }}
      />
      <RaTextInput
        source="code"
        validate={required()}
        style={{ width: 300 }}
        label="resources.settings.fields.code"
        InputLabelProps={{ shrink: true }}
        notetext="resources.settings.note_text.code"
      />
      <RaNumberInput
        source="sort_order"
        label="resources.settings.fields.sort_order"
        notetext="resources.settings.note_text.sort_order"
      />
    </Flex>
  );
};

export default FormFieldSettings;
