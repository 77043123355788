import React from "react";
import { Flex, Box } from "rebass";
import TextField from "@material-ui/core/TextField";
import {
  FormControl,
  Button,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { filter, orderBy } from "lodash";
// import { useQueryWithStore } from "react-admin";
import { useQuery } from "../../../../components";
import { CategoryFilter, ProductGroupFilter } from "../../grid/filters";
// const getField = (list, key) => {
//   let values = list.map(function (val) {
//     return val[key];
//   });
//   return uniq(values);
// };

export const NormalFilterFields = (props) => {
  const { handleChange, translate, state, onResetSearch, onShowItemmOnly } =
    props;
  const { data, loading } = useQuery(
    `/codes?_order=${encodeURIComponent(
      "ASC"
    )}&active=true&_sort=${encodeURIComponent("label")}`
  );

  if (loading) {
    return <div />;
  }
  // console.log(data);
  const listCategories = filter(data, function (o) {
    return o.type === "Category";
  });
  const listProductGroup = filter(data, function (o) {
    return o.type === "ProductGroup";
  });
  const sortListCategories = orderBy(
    listCategories,
    [(product) => product.label.toLowerCase()],
    ["asc"]
  );

  const sortListProductGroup = orderBy(
    listProductGroup,
    [(product) => product.label.toLowerCase()],
    ["asc"]
  );
  //sortBy(listProductGroup, ["label"])
  return (
    <Box width={[1]}>
      <Box width={[1]} p={[2]}>
        <FormControl>
          <TextField
            value={state.search}
            label={translate("resources.po.listgrid.label.product_name")}
            onChange={(e) => handleChange("search", e.target.value)}
            InputLabelProps={{ shrink: true }}
            style={{ width: 256 }}
          />
        </FormControl>
      </Box>
      <Flex
        width={[1]}
        p={[2]}
        sx={{
          "& > div": {
            p: [3],
            "&:first-of-type": {
              pl: [0],
            },
          },
        }}
      >
        <CategoryFilter
          handleChange={handleChange}
          value={state.category}
          translate={translate}
          listCategories={sortListCategories}
        />
        <ProductGroupFilter
          handleChange={handleChange}
          value={state.group}
          translate={translate}
          category={state.category}
          listProductGroup={sortListProductGroup}
          state={state}
        />
        <Box>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => onResetSearch()}
            style={{ textTransform: "capitalize" }}
          >
            Reset Filter
          </Button>
        </Box>
        <FormControlLabel
          control={
            <Switch
              checked={state.showItemOnly}
              onChange={onShowItemmOnly}
              name='showItemOnly'
              color='primary'
            />
          }
          label='Items with Order Qty'
        />
      </Flex>
    </Box>
  );
};
