import React, { useState, useEffect } from "react";
import {
  find,
  filter,
  flattenDepth,
  uniqBy,
  get,
  orderBy,
  sumBy,
  has,
  map,
  toLower,
} from "lodash";
import { AgGridReact } from "ag-grid-react";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "./Expand";
// import { getProducts, getAllProducts } from "../../restClient";
import { getProductsWithId, getAllProductsWithId } from "../../restClient";
import { defaultBXGYColumnDefs } from "./defaultValue";
import { getPromoType, PromoType_B, getRegisterNumber } from "./utils";
export default (props) => {
  const {
    translate,
    promoType,
    item,
    tempList,
    setTempList,
    setShow,
    setMessageError,
    setTempListBuy,
    tempListBuy,
    isOAAsossiate,
    countryId,
    country,
  } = props;
  const countryCode = toLower(get(country, "code", ""));
  const myURl = getPromoType(promoType, item);
  const isPromoTypeFree = promoType === "Buy X Get Y";
  const [isRequest, setIsRequest] = useState(true);
  const [gridColApi, setGridColApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [state, setState] = useState({
    tempMoq: "",
    totalQty: 0,
    search: "",
    message: "",
    show: false,
    columnDefs: defaultBXGYColumnDefs(false),
    defaultColDef: {
      flex: 1,
      editable: true,
      sortable: true,
      filter: true,
      cellStyle: {
        "line-height": "40px",
      },
    },
    getRowNodeId: function (data) {
      return data.code;
    },
    listPromo: [],
    item: props.item,
    expanded: "panelGridPromoBXGY",
  });
  useEffect(() => {
    function fetchProduct() {
      if (isRequest) {
        if (Array.isArray(myURl)) {
          getAllProductsWithId(myURl)
            .then((res) => {
              const result = flattenDepth(res, 1);
              const dataProduct = filter(result, function (o) {
                return o.active;
              });
              const response = uniqBy(dataProduct, "id");
              if (response && response.length > 0) {
                const myLatest = response.map((item) => {
                  let newItem = item;
                  const objUrl = find(tempList, function (o) {
                    return o.product_id === item.id && !o.foc;
                  });
                  if (objUrl && objUrl.product_id) {
                    newItem["qty"] = objUrl.qty;
                    newItem["subtotal"] = objUrl.subtotal;
                    if (isOAAsossiate) {
                      newItem["price"] = objUrl.price;
                    }
                  } else {
                    newItem["qty"] = 0;
                    newItem["subtotal"] = 0;
                  }
                  newItem["moq_group_index"] = 0;
                  newItem["foc"] = false;
                  newItem["growth"] = get(item, `class4.${countryId}`, "");
                  newItem["cr"] = get(item, "cr", false);
                  // === true ? "CR" : "";
                  newItem["dg"] = get(item, "dg", false);
                  // === true ? "DG" : "";
                  newItem["target_id"] = get(item, "target_id", 0);
                  newItem["registration_number"] = getRegisterNumber(
                    item,
                    countryCode
                  );
                  return newItem;
                });
                const myTempBuy = filter(myLatest, function (oo) {
                  return !oo.foc && oo.qty > 0;
                });
                setRowData(myLatest);
                setTempListBuy(myTempBuy);
              }
              setIsRequest(false);
            })
            .catch((e) => {
              setIsRequest(false);
            });
        } else {
          if (myURl !== "") {
            getProductsWithId(myURl)
              .then((response) => {
                if (response && response.length > 0) {
                  const dataProduct = filter(response, function (o) {
                    return o.active;
                  });
                  const responseData = uniqBy(dataProduct, "id");
                  const myLatest = responseData.map((item) => {
                    let newItem = item;
                    const objUrl = find(tempList, function (o) {
                      return o.product_id === item.id && !o.foc;
                    });

                    if (objUrl && objUrl.product_id) {
                      newItem["qty"] = objUrl.qty;
                      newItem["subtotal"] = objUrl.subtotal;
                      if (isOAAsossiate) {
                        newItem["price"] = objUrl.price;
                      }
                    } else {
                      newItem["qty"] = 0;
                      newItem["subtotal"] = 0;
                    }
                    newItem["moq_group_index"] = 0;
                    newItem["growth"] = get(item, `class4.${countryId}`, "");
                    newItem["foc"] = false;
                    newItem["cr"] = get(item, "cr", false);
                    //  === true ? "CR" : "";
                    newItem["dg"] = get(item, "dg", false);
                    // === true ? "DG" : "";
                    newItem["target_id"] = get(item, "target_id", 0);
                    newItem["registration_number"] = getRegisterNumber(
                      item,
                      countryCode
                    );
                    return newItem;
                  });
                  const myTempBuy = filter(myLatest, function (oo) {
                    return !oo.foc && oo.qty > 0;
                  });
                  setRowData(myLatest);
                  setTempListBuy(myTempBuy);
                } else {
                  if (response && response.id) {
                    let newItem = response;
                    const objUrl = find(tempList, function (o) {
                      return o.product_id === response.id && !o.foc;
                    });

                    if (objUrl && objUrl.product_id) {
                      newItem["qty"] = objUrl.qty;
                      newItem["subtotal"] = objUrl.subtotal;
                      if (isOAAsossiate) {
                        newItem["price"] = objUrl.price;
                      }
                    } else {
                      newItem["qty"] = 0;
                      newItem["subtotal"] = 0;
                    }
                    newItem["moq_group_index"] = 0;
                    newItem["growth"] = get(
                      response,
                      `class4.${countryId}`,
                      ""
                    );
                    newItem["foc"] = false;
                    newItem["cr"] = get(response, "cr", false);
                    // === true ? "CR" : "";
                    newItem["dg"] = get(response, "dg", false);
                    //  === true ? "DG" : "";
                    newItem["target_id"] = get(response, "target_id", 0);
                    newItem["registration_number"] = getRegisterNumber(
                      response,
                      countryCode
                    );
                    setRowData([newItem]);
                    if (!newItem.foc && newItem.qty > 0) {
                      setTempListBuy(newItem);
                    } else {
                      setTempListBuy([]);
                    }
                  }
                }
                setIsRequest(false);
              })
              .catch((e) => {
                setIsRequest(false);
              });
          } else {
            setIsRequest(false);
          }
        }
      }
    }
    fetchProduct();
    return () => setIsRequest(false);
  }, [
    myURl,
    isPromoTypeFree,
    tempList,
    isRequest,
    isOAAsossiate,
    setTempListBuy,
    countryId,
    countryCode,
  ]);

  const onGridReady = (params) => {
    setGridColApi(params.api);
  };
  const handleChange = (panel) => (event, newExpanded) => {
    setState({
      ...state,
      expanded: newExpanded ? panel : false,
    });
  };

  const setOrderAmtDefault = (ProductCode, data) => {
    if (ProductCode) {
      // console.log(
      //   data.price,
      //   get(data, "discounted_price", data.price),
      //   get(data, "unit_price", data.price)
      // );
      let newListPromo = tempList;
      if (newListPromo.length === 0) {
        let itemProduct = {
          product_id: data.id,
          qty: data.qty,
          price: data.price,
          subtotal: data.subtotal,
          promotions: item,
          unit_price: get(data, "unit_price", data.price),
          discounted_price: get(
            data,
            "discounted_price",
            get(data, "unit_price", data.price)
          ),
          class4: data.class4,
          new_existing: get(data, "new_existing", ""),
        };
        itemProduct["foc"] = false;
        itemProduct["target_id"] = data.target_id;
        newListPromo.push(itemProduct);
      } else {
        let newList = filter(newListPromo, function (o) {
          return o.product_id !== data.id && !o.foc;
        });
        const updateItem = find(newListPromo, function (o) {
          return o.product_id === data.id && !o.foc;
        });
        newListPromo = newList;
        let newItemProduct = {
          product_id: data.id,
          qty: data.qty,
          price: data.price,
          subtotal: data.subtotal,
          unit_price: get(data, "unit_price", data.price),
          discounted_price: get(
            data,
            "discounted_price",
            get(data, "unit_price", data.price)
          ),
          promotions: item,
          class4: data.class4,
          new_existing: get(data, "new_existing", ""),
        };
        if (updateItem && updateItem.id) {
          newItemProduct = {
            ...updateItem,
            product_id: data.id,
            qty: data.qty,
            price: data.price,
            subtotal: data.subtotal,
            discounted_price: get(
              data,
              "discounted_price",
              get(data, "unit_price", data.price)
            ),
            promotions: item,
            class4: data.class4,
            new_existing: get(data, "new_existing", ""),
          };
        }
        newItemProduct["foc"] = false;
        newItemProduct["target_id"] = data.target_id;
        newListPromo.push(newItemProduct);
      }
      let rowNode = gridColApi.getRowNode(ProductCode);
      rowNode.setDataValue(
        "subtotal",
        Number(data.qty * data.price).toFixed(2)
      );

      const totalQty = sumBy(newListPromo, "qty");
      const filterListBuy = filter(tempListBuy, function (oi) {
        return oi.id !== data.id && !oi.foc;
      });
      let listBuy = filterListBuy;
      map(rowData, function (o) {
        if (data.id === o.id && !o.foc) {
          listBuy.push(data);
        }
        return o;
      });
      setState({
        ...state,
        totalQty: totalQty,
      });
      const listBuyP = filter(listBuy, function (oo) {
        return oo.qty > 0;
      });
      setTempListBuy(listBuyP);
      const listPromo = filter(newListPromo, function (oo) {
        return oo.qty > 0;
      });
      const listFree = filter(tempList, function (o) {
        return o.foc;
      });
      const newListDiscount1 = sortOrderByName(rowData, listPromo);
      setTempList([...newListDiscount1, ...listFree]);
    }
  };

  const sortOrderByName = (rowData, list) => {
    const newList = map(list, function (o) {
      const itemProduct = find(rowData, function (i) {
        return i.id === o.product_id;
      });
      return { ...o, product_name: get(itemProduct, "packaging", "") };
    });
    const sortByName = orderBy(newList, "product_name", "asc");
    let mySortOrder = item && item.id ? item.id * 100 : 1;
    const newSortByName = map(sortByName, function (ii) {
      const { product_name, ...rest } = ii;
      rest["sort_order"] = mySortOrder++;
      return { ...rest };
    });

    return newSortByName;
  };

  const onCellEditingStopped = (params) => {
    const MOQ = get(params, "data.moq", "");
    if (has(params, "data.code")) {
      let rowNode1 = gridColApi.getRowNode(params.data.code);
      if (
        MOQ !== "" &&
        MOQ !== "Indent" &&
        MOQ !== "*" &&
        get(params, "data.qty", 0) < MOQ * 1 &&
        !isOAAsossiate
      ) {
        if (get(params, "data.qty", 0) > 0) {
          setShow(true);
          setMessageError(`MOQ for this product is ${MOQ}`);
        }

        rowNode1.setDataValue("subtotal", 0);
        rowNode1.setDataValue("qty", 0);
      } else {
        rowNode1.setDataValue(
          "subtotal",
          Number(params.data.qty * params.data.price).toFixed(2)
        );
      }
      const newContent = getDiscountedPrice(params);
      setOrderAmtDefault(params.data.code, newContent);
      // setOrderAmtDefault(params.data.code, params.data);
    }
  };

  const getDiscountedPrice = (params) => {
    const { newValue, oldValue, data } = params;
    const colId = get(params, "column.colId");
    if (colId === "price") {
      if (newValue !== oldValue) {
        // console.log("newValueIn", newValue, oldValue);
        let rowNode1 = gridColApi.getRowNode(params.data.code);
        if (rowNode1) {
          rowNode1.setData({
            ...data,
            discounted_price: newValue,
            unit_price: oldValue,
          });
        }
        return { ...data, discounted_price: newValue, unit_price: oldValue };
      }
    }
    return data;
  };

  const isMoreHeight =
    promoType === PromoType_B[0] || promoType === PromoType_B[1]
      ? "20vh"
      : "52vh";
  return (
    <ExpansionPanel
      expanded={state.expanded === "panelGridPromoBXGY"}
      onChange={handleChange("panelGridPromoBXGY")}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='promotion_items-content'
        id='promotion_items-header'
      >
        <Typography>
          {translate("resources.po.listgrid.label.promo_items")} (
          {rowData.length}{" "}
          {rowData.length > 1
            ? translate("resources.po.listgrid.label.items")
            : translate("resources.po.listgrid.label.item")}
          )
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        style={{ width: "100%%", padding: 0, paddingTop: 4 }}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <div className='grid-wrapper'>
            <div
              id='myGrid'
              style={{
                height: isMoreHeight,
                width: "100%",
              }}
              className='ag-theme-balham'
            >
              <AgGridReact
                rowHeight={40}
                headerHeight={40}
                rowData={rowData}
                columnDefs={state.columnDefs}
                defaultColDef={state.defaultColDef}
                animateRows={true}
                getRowNodeId={state.getRowNodeId}
                onGridReady={onGridReady}
                // onCellValueChanged={(params) => onUpdateItem(params)}
                onCellEditingStopped={(params) => onCellEditingStopped(params)}
              />
            </div>
          </div>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};
