//ArListGridDiscount
import React, { useState, useEffect, Fragment } from "react";
import {
  find,
  flattenDepth,
  uniqBy,
  get,
  orderBy,
  sumBy,
  map,
  has,
  filter,
  toLower,
} from "lodash";
// import numeral from "numeral";

import { AgGridReact } from "ag-grid-react";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "./Expand";
import { getProductsWithId, getAllProductsWithId } from "../../restClient";
import { defaultSPColumnDefs } from "./defaultValue";
import {
  getPromoType,
  PromoType_B,
  getKeyMoq,
  getRegisterNumber,
} from "./utils";

const getMOQ = (totalQty, pt, myitem) => {
  // console.log("item", item);
  const moqsList = getKeyMoq(pt, myitem);
  const moqsListSP = filter(moqsList, function (oi) {
    return oi.moq > 0;
  });
  const moqsSP = orderBy(moqsListSP, "moq", "desc");
  const itemMoq = find(moqsSP, function (o) {
    return totalQty >= o.moq;
  });
  return itemMoq;
};

export default (props) => {
  //thisQuantity,
  const {
    translate,
    promoType,
    item,
    tempList,
    setTempList,
    setShow,
    setMessageError,
    isOAAsossiate,
    countryId,
    country,
  } = props;
  const countryCode = toLower(get(country, "code", ""));
  const myURl = getPromoType(promoType, item);
  const [isRequest, setIsRequest] = useState(true);
  const [gridColApi, setGridColApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [state, setState] = useState({
    tempMoq: "",
    totalQty: 0,
    search: "",
    message: "",
    show: false,
    columnDefs: defaultSPColumnDefs(false),
    defaultColDef: {
      flex: 1,
      editable: true,
      sortable: true,
      filter: true,
      cellStyle: {
        "line-height": "40px",
      },
    },
    getRowNodeId: function (data) {
      return data.code;
    },
    listPromo: [],
    item: props.item,
    expanded: "panelGridPromoSP",
  });
  useEffect(() => {
    function fetchProduct() {
      if (isRequest) {
        const totalQty = sumBy(tempList, "qty");
        const myItemMoq = getMOQ(totalQty, promoType, item);
        const specialPrice = get(myItemMoq, "price", 0);
        if (Array.isArray(myURl)) {
          getAllProductsWithId(myURl)
            .then((res) => {
              // console.log(res);
              const result = flattenDepth(res, 1);
              const dataProduct = filter(result, function (o) {
                return o.active;
              });
              const response = uniqBy(dataProduct, "id");
              if (response && response.length > 0) {
                const myLatest = response.map((item) => {
                  let newItem = item;
                  const objUrl = find(tempList, function (o) {
                    // console.log(promoType, o.id);
                    return o.product_id === item.id;
                  });
                  newItem["special_price"] = specialPrice;
                  if (objUrl && objUrl.product_id) {
                    newItem["qty"] = objUrl.qty;
                    newItem["subtotal"] = objUrl.subtotal;
                    if (isOAAsossiate) {
                      newItem["price"] = objUrl.price;
                    }
                  } else {
                    newItem["qty"] = 0;
                    newItem["subtotal"] = 0;
                  }
                  newItem["moq_group_index"] = 0;
                  newItem["growth"] = get(item, `class4.${countryId}`, "");
                  newItem["cr"] = get(item, "cr", false);
                  // === true ? "CR" : "";
                  newItem["dg"] = get(item, "dg", false);
                  //  === true ? "DG" : "";
                  newItem["target_id"] = get(item, "target_id", 0);
                  newItem["registration_number"] = getRegisterNumber(
                    item,
                    countryCode
                  );
                  return newItem;
                });
                setRowData(myLatest);
              }
              setIsRequest(false);
            })
            .catch((e) => {
              //   console.log(e);
              setIsRequest(false);
            });
        } else {
          if (myURl !== "") {
            getProductsWithId(myURl)
              .then((response) => {
                // console.log("response", response);
                if (response && response.length > 0) {
                  const dataProduct = filter(response, function (o) {
                    return o.active;
                  });
                  const responseData = uniqBy(dataProduct, "id");
                  const myLatest = responseData.map((item) => {
                    let newItem = item;
                    const objUrl = find(tempList, function (o) {
                      // console.log(promoType, o.id);
                      return o.product_id === item.id;
                    });
                    newItem["special_price"] = specialPrice;
                    if (objUrl && objUrl.product_id) {
                      newItem["qty"] = objUrl.qty;
                      newItem["subtotal"] = objUrl.subtotal;
                      if (isOAAsossiate) {
                        newItem["price"] = objUrl.price;
                      }
                    } else {
                      newItem["qty"] = 0;
                      newItem["subtotal"] = 0;
                    }
                    newItem["moq_group_index"] = 0;
                    newItem["growth"] = get(item, `class4.${countryId}`, "");
                    newItem["cr"] = get(item, "cr", false);
                    // === true ? "CR" : "";
                    newItem["dg"] = get(item, "dg", false);
                    //  === true ? "DG" : "";
                    newItem["target_id"] = get(item, "target_id", 0);
                    newItem["registration_number"] = getRegisterNumber(
                      item,
                      countryCode
                    );
                    return newItem;
                  });
                  setRowData(myLatest);
                } else {
                  if (response && response.id) {
                    let newItem = response;
                    const objUrl = find(tempList, function (o) {
                      // console.log(promoType, o.id);
                      return o.product_id === response.id;
                    });
                    newItem["special_price"] = specialPrice;
                    if (objUrl && objUrl.product_id) {
                      newItem["qty"] = objUrl.qty;
                      newItem["subtotal"] = objUrl.subtotal;
                      if (isOAAsossiate) {
                        newItem["price"] = objUrl.price;
                      }
                    } else {
                      newItem["qty"] = 0;
                      newItem["subtotal"] = 0;
                    }
                    newItem["moq_group_index"] = 0;
                    newItem["growth"] = get(
                      response,
                      `class4.${countryId}`,
                      ""
                    );
                    newItem["cr"] = get(response, "cr", false);
                    // === true ? "CR" : "";
                    newItem["dg"] = get(response, "dg", false);
                    // === true ? "DG" : "";
                    newItem["target_id"] = get(response, "target_id", 0);
                    newItem["registration_number"] = getRegisterNumber(
                      response,
                      countryCode
                    );
                    setRowData([newItem]);
                  }
                }
                setIsRequest(false);
              })
              .catch((e) => {
                // console.log(e);
                setIsRequest(false);
              });
          } else {
            setIsRequest(false);
          }
        }
      }
    }
    fetchProduct();
    // console.log(promoType);
  }, [
    myURl,
    promoType,
    tempList,
    isRequest,
    item,
    isOAAsossiate,
    countryId,
    countryCode,
  ]);

  const onGridReady = (params) => {
    setGridColApi(params.api);
    // gridApi = params.api;
    // gridColumnApi = params.columnApi;
  };
  const handleChange = (panel) => (event, newExpanded) => {
    setState({
      ...state,
      expanded: newExpanded ? panel : false,
    });
  };

  const setOrderAmtDefault = (ProductCode, data) => {
    if (ProductCode) {
      const totalQty = sumBy(rowData, "qty");
      const myItemMoq = getMOQ(totalQty, promoType, item);
      if (promoType === "Special Price") {
        let newListPromo = [];
        const specialPrice = get(myItemMoq, "price", 0);
        // console.log("specialPrice", specialPrice);
        //setAll specialPrice and discounted price
        map(rowData, function (o) {
          let rowNodeAll = gridColApi.getRowNode(o.code);

          if (o.qty > 0) {
            const newPrice = specialPrice >= 0 ? specialPrice * o.qty : 0;
            let itemProduct = {
              product_id: o.id,
              qty: o.qty,
              price: o.price,
              subtotal: newPrice,
              promotions: item,
              class4: o.class4,
              new_existing: get(data, "new_existing", ""),
            };
            itemProduct["target_id"] = o.target_id;
            newListPromo.push(itemProduct);
            rowNodeAll.setDataValue("subtotal", Number(newPrice).toFixed(2));
          } else {
            rowNodeAll.setDataValue(
              "subtotal",
              Number(o.qty * o.price).toFixed(2)
            );
          }
          // console.log(o);

          rowNodeAll.setDataValue(
            "special_price",
            Number(specialPrice).toFixed(2)
          );
          return { ...o };
        });
        const totalQty = sumBy(newListPromo, "qty");
        // console.log("list", newListPromo);
        setState({
          ...state,
          totalQty: totalQty,
        });
        const newListDiscount1 = sortOrderByName(rowData, newListPromo);
        setTempList(newListDiscount1);
      }
    }
  };

  const sortOrderByName = (rowData, list) => {
    const newList = map(list, function (o) {
      const itemProduct = find(rowData, function (i) {
        return i.id === o.product_id;
      });
      return { ...o, product_name: get(itemProduct, "packaging", "") };
    });
    const sortByName = orderBy(newList, "product_name", "asc");
    let mySortOrder = item && item.id ? item.id * 100 : 1;
    const newSortByName = map(sortByName, function (ii) {
      const { product_name, ...rest } = ii;
      rest["sort_order"] = mySortOrder++;
      return { ...rest };
    });

    return newSortByName;
  };

  const onCellEditingStopped = (params) => {
    // console.log(params);
    const MOQ = get(params, "data.moq", "");
    if (has(params, "data.code")) {
      let rowNode1 = gridColApi.getRowNode(params.data.code);
      if (
        MOQ !== "" &&
        MOQ !== "Indent" &&
        MOQ !== "*" &&
        get(params, "data.qty", 0) < MOQ * 1 &&
        !isOAAsossiate
      ) {
        if (get(params, "data.qty", 0) > 0) {
          setShow(true);
          setMessageError(`MOQ for this product is ${MOQ}`);
        }
        // setShow(true);
        // setMessageError(`MOQ for this product is ${MOQ}`);
        rowNode1.setDataValue("subtotal", 0);
        rowNode1.setDataValue("qty", 0);
      } else {
        const totalQty = sumBy(tempList, "qty");
        const myItemMoq = getMOQ(totalQty, promoType, item);
        const specialPrice =
          get(myItemMoq, "price", 0) > 0
            ? get(myItemMoq, "price", 0)
            : get(params, "data.price", 0) * 1;
        rowNode1.setDataValue(
          "subtotal",
          Number(params.data.qty * specialPrice).toFixed(2)
        );
      }
      setOrderAmtDefault(params.data.code, params.data);
    }
  };
  const isMoreHeight =
    promoType === PromoType_B[0] || promoType === PromoType_B[1]
      ? "20vh"
      : "52vh";

  return (
    <Fragment>
      <ExpansionPanel
        expanded={state.expanded === "panelGridPromoSP"}
        onChange={handleChange("panelGridPromoSP")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='promotion_items-content'
          id='promotion_items-header'
        >
          <Typography>
            {translate("resources.po.listgrid.label.promo_items")} (
            {rowData.length}{" "}
            {rowData.length > 1
              ? translate("resources.po.listgrid.label.items")
              : translate("resources.po.listgrid.label.item")}
            )
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          style={{ width: "100%%", padding: 0, paddingTop: 4 }}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <div className='grid-wrapper'>
              <div
                id='myGrid'
                style={{
                  height: isMoreHeight,
                  width: "100%",
                }}
                className='ag-theme-balham'
              >
                <AgGridReact
                  rowHeight={40}
                  headerHeight={40}
                  rowData={rowData}
                  columnDefs={state.columnDefs}
                  defaultColDef={state.defaultColDef}
                  animateRows={true}
                  getRowNodeId={state.getRowNodeId}
                  onGridReady={onGridReady}
                  onCellEditingStopped={(params) =>
                    onCellEditingStopped(params)
                  }
                />
              </div>
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Fragment>
  );
};
