import React, { useState } from "react";
import { filter, map, flatten, orderBy } from "lodash";
import { Box, Flex, Text } from "rebass";
import { RestApi } from "../../../../restClient";
import Chip from "@material-ui/core/Chip";
import { listColumnItem, getListOTD, listColumnItemDealer } from "./";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { DatePicker } from "antd";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { applyFilter, getMonthList, NumberAmount } from "../utils";
import { ROLE_USER } from "../../../../utils";
const moment = extendMoment(Moment);
const { RangePicker } = DatePicker;

export default (props) => {
  const {
    // dataList,
    permissions,
    countries,
    dealers,
    targetSettings,
    categories,
    setList,
    valueCountry,
    setValueCountry,
    valueDealer,
    setValueDealer,
    myfilter,
    setMyFilter,
    setMyData,
    myData,
    // valueCategory,
    // setValueCategory,
    valueTargetSetting,
    setValueTargetSetting,
    isDealer,
    dealerId,
    country,
  } = props;
  const myLIst =
    dealerId && dealerId !== null ? listColumnItemDealer : listColumnItem;
  const [state, setState] = useState({
    listCountries: countries,
    listDealers: dealers,
    listTargetSettings: targetSettings,
    listCategories: categories,
  });

  const onChangeCountries = (e, newValue) => {
    // console.log("change", newValue);
    setValueCountry([...newValue]);
    const paramsField = map(newValue, function (o) {
      return o.name;
    });
    let newMyFilterObj = myfilter;
    if (valueDealer.length > 0 && newValue.length > 0) {
      const getDealerSelected = map(newValue, function (oCountry) {
        const newDealers = filter(valueDealer, function (oDealer) {
          return oCountry.id === oDealer.country_id;
        });
        return newDealers;
      });

      const newDealerSelected = filter(getDealerSelected, function (element) {
        return element !== undefined;
      });
      const myFlatDealer = flatten(newDealerSelected);
      setValueDealer(myFlatDealer);
      const paramsFieldDealer = map(myFlatDealer, function (o) {
        return o.name;
      });
      newMyFilterObj = {
        ...myfilter,
        country: paramsField,
        dealer: paramsFieldDealer,
      };
      setMyFilter({
        ...myfilter,
        country: paramsField,
        dealer: paramsFieldDealer,
      });
    } else {
      newMyFilterObj = {
        ...myfilter,
        country: paramsField,
      };
      setMyFilter({
        ...myfilter,
        country: paramsField,
      });
    }
    if (newValue.length > 0) {
      const newParams = { ...newMyFilterObj, country: paramsField };
      const newFilter = applyFilter(myData.listTemporary, newParams);
      setList(newFilter);
    } else {
      let filter2 = {};
      const newMyFilter = { ...newMyFilterObj, country: [] };
      filter(newMyFilter, function (v, k) {
        const param = v.length > 0 ? { [k]: v } : {};
        filter2 = { ...filter2, ...param };
        return v.length > 0;
      });
      const newFilter = applyFilter(myData.listTemporary, filter2);
      setList(newFilter);
    }
    if (newValue.length > 0) {
      const newParams = map(newValue, function (o) {
        return o.id;
      });
      const newlistDealers = filter(dealers, function (i) {
        return newParams.some(function (f) {
          return i.country_id === f;
        });
      });
      setState({
        ...state,
        listDealers: newlistDealers,
      });
    } else {
      setState({
        ...state,
        listDealers: dealers,
      });
    }
  };

  const onChangeDealers = (e, newValue) => {
    setValueDealer([...newValue]);
    const paramsField = map(newValue, function (o) {
      return o.name;
    });
    setMyFilter({
      ...myfilter,
      dealer: paramsField,
    });
    if (newValue.length > 0) {
      const newParams = { ...myfilter, dealer: paramsField };
      const newFilter = applyFilter(myData.listTemporary, newParams);
      setList(newFilter);
    } else {
      let filter2 = {};
      const newMyFilter = { ...myfilter, dealer: [] };
      filter(newMyFilter, function (v, k) {
        const param = v.length > 0 ? { [k]: v } : {};
        filter2 = { ...filter2, ...param };
        return v.length > 0;
      });
      const newFilter = applyFilter(myData.listTemporary, filter2);
      // console.log(newFilter);
      setList(newFilter);
    }
  };

  // const onChangeCategories = (e, newValue) => {
  //   // console.log("change", newValue);
  //   setValueCategory([...newValue]);
  //   const paramsField = map(newValue, function (o) {
  //     return o.label;
  //   });
  //   setMyFilter({
  //     ...myfilter,
  //     category: paramsField,
  //   });
  //   if (newValue.length > 0) {
  //     const newParams = { ...myfilter, category: paramsField };
  //     const newFilter = applyFilter(myData.listTemporary, newParams);
  //     setList(newFilter);
  //   } else {
  //     let filter2 = {};
  //     const newMyFilter = { ...myfilter, category: [] };
  //     filter(newMyFilter, function (v, k) {
  //       const param = v.length > 0 ? { [k]: v } : {};
  //       filter2 = { ...filter2, ...param };
  //       return v.length > 0;
  //     });
  //     const newFilter = applyFilter(myData.listTemporary, filter2);
  //     setList(newFilter);
  //   }
  //   if (newValue.length > 0) {
  //     const newParams = map(newValue, function (o) {
  //       return o.label;
  //     });
  //     const newListTarget = filter(dataList, function (i) {
  //       return newParams.some(function (f) {
  //         return i.category === f;
  //       });
  //     });

  //     const lt = uniqBy(newListTarget, "target_setting");
  //     const newListTargetSetting = map(lt, function (oi) {
  //       return { label: oi.target_setting };
  //     });
  //     // console.log(newListTargetSetting);
  //     setState({
  //       ...state,
  //       listTargetSettings: newListTargetSetting,
  //     });
  //   } else {
  //     setState({
  //       ...state,
  //       listTargetSettings: targetSettings,
  //     });
  //   }
  // };
  const onChangeTargetSetting = (e, newValue) => {
    // console.log("change", newValue);
    setValueTargetSetting([...newValue]);
    const paramsField = map(newValue, function (o) {
      return o.label;
    });
    setMyFilter({
      ...myfilter,
      target_setting: paramsField,
    });
    if (newValue.length > 0) {
      const newParams = { ...myfilter, target_setting: paramsField };
      const newFilter = applyFilter(myData.listTemporary, newParams);
      setList(newFilter);
    } else {
      let filter2 = {};
      const newMyFilter = { ...myfilter, target_setting: [] };
      filter(newMyFilter, function (v, k) {
        const param = v.length > 0 ? { [k]: v } : {};
        filter2 = { ...filter2, ...param };
        return v.length > 0;
      });
      const newFilter = applyFilter(myData.listTemporary, filter2);
      setList(newFilter);
    }
  };

  const onChangeDate = (v) => {
    let myRangeDate = myData.rangeDate;
    if (v && v !== null) {
      if (v[0] !== null) {
        myRangeDate.start = v[0];
      }
      if (v[1] !== null) {
        myRangeDate.end = v[1];
      }

      if (
        myRangeDate.start &&
        myRangeDate.end &&
        myRangeDate.start !== null &&
        myRangeDate.end !== null
      ) {
        const start = moment(myRangeDate.start);
        const end = moment(myRangeDate.end);
        RestApi(
          `/reports/otd?start_date=${start.toISOString()}&end_date=${end.toISOString()}`
        )
          .then((res) => res.json())
          .then((response) => {
            // console.log(response);
            if (response && response.length > 0) {
              const myListDefault = getMonthList({
                start: new Date(myRangeDate.start),
                end: new Date(myRangeDate.end),
              });

              let newColumnList1 = [];
              myListDefault.map((itemMonth) => {
                newColumnList1.push({
                  field: `amount-${itemMonth}`,
                  headerName: `Amount ${itemMonth}`,
                  width: 160,
                  cellStyle: { "padding-top": "7px", "text-align": "right" },
                  editable: false,
                  resizable: true,
                  valueFormatter: NumberAmount,
                });
                newColumnList1.push({
                  field: `amount-qty-${itemMonth}`,
                  headerName: `Qty ${itemMonth}`,
                  width: 160,
                  cellStyle: { "padding-top": "7px", "text-align": "right" },
                  editable: false,
                  resizable: true,
                  valueFormatter: NumberAmount,
                });
                // newColumnList1.push({
                //   field: `amount_gap-${itemMonth}`,
                //   headerName: `G/L ${itemMonth}`,
                //   width: 160,
                //   cellStyle: { "padding-top": "7px", "text-align": "right" },
                //   editable: false,
                //   resizable: true,
                //   valueFormatter: NumberAmount,
                // });
                // newColumnList1.push({
                //   field: `amount_gap-qty-${itemMonth}`,
                //   headerName: `G/L Qty ${itemMonth}`,
                //   width: 160,
                //   cellStyle: { "padding-top": "7px", "text-align": "right" },
                //   editable: false,
                //   resizable: true,
                //   valueFormatter: NumberAmount,
                // });
                return "";
              });

              const dataResponse = getListOTD(response);
              const dataList =
                dataResponse && dataResponse.length > 0
                  ? Array.from(
                      new Set(
                        dataResponse.map((p, index) => {
                          return { ...p, id: index };
                        })
                      )
                    )
                  : [];
              let myDataList = dataList;
              if (country && country.id) {
                myDataList = filter(dataList, function (o) {
                  return o.country === country.name;
                });
              }
              setList(myDataList);
              setMyData({
                ...myData,
                listColumn: [...myLIst, ...newColumnList1],
                listTemporary: myDataList,
              });
            }
          });
      }
    }
    setMyData({
      ...myData,
      rangeDate: myRangeDate,
    });
  };
  // console.log(dataList);
  const sortListTargetSettings = orderBy(
    state.listTargetSettings,
    [(product) => product.label.toLowerCase()],
    ["asc"]
  );
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <Box pb={[2]}>
        <Flex flexDirection={"row"}>
          <Text
            pl={[2]}
            fontSize={[0]}
            fontWeight={"bold"}
            width={["138px"]}
            color={"#616161"}
          >
            Date Range
          </Text>
          <Text fontSize={[0]} fontWeight={"bold"} color={"#616161"}>
            Date To
          </Text>
        </Flex>
        <RangePicker
          format={"DD-MMM-YYYY"}
          onChange={onChangeDate}
          value={[myData.rangeDate.start, myData.rangeDate.end]}
        />
      </Box>
      <Flex width={[1]} flexDirection={"row"} flexWrap={"wrap"} pb={[2]}>
        {permissions !== ROLE_USER.country_manager &&
          permissions !== ROLE_USER.dealer && (
            <Box p={[1]} width={["400px"]}>
              {countries && countries.length > 0 && (
                <Autocomplete
                  id='otd-countries-fields-filter'
                  options={countries}
                  multiple
                  value={valueCountry}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) =>
                    onChangeCountries(event, newValue)
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant='outlined'
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      variant='filled'
                      label='Country'
                      placeholder='country'
                    />
                  )}
                />
              )}
            </Box>
          )}
        {!isDealer && (
          <Box p={[1]} width={["400px"]}>
            {state.listDealers && state.listDealers.length > 0 && (
              <Autocomplete
                id='otd-dealers-fields-filter'
                options={state.listDealers}
                multiple
                value={valueDealer}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => onChangeDealers(event, newValue)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant='outlined'
                      label={option.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    variant='filled'
                    label='Dealer'
                    placeholder='dealer'
                  />
                )}
              />
            )}
          </Box>
        )}
        {/* <Box p={[1]} width={["400px"]}>
          {categories && categories.length > 0 && (
            <Autocomplete
              id='categories-fields-filter'
              options={categories}
              multiple
              value={valueCategory}
              getOptionLabel={(option) => option.label}
              onChange={(event, newValue) =>
                onChangeCategories(event, newValue)
              }
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant='outlined'
                    label={option.label}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  variant='filled'
                  label='Category'
                  placeholder='category'
                />
              )}
            />
          )}
        </Box> */}
        <Box p={[1]} width={["400px"]}>
          {sortListTargetSettings && sortListTargetSettings.length > 0 && (
            <Autocomplete
              id='otd-targetSettings-fields-filter'
              options={sortListTargetSettings}
              multiple
              value={valueTargetSetting}
              getOptionLabel={(option) => option.label}
              onChange={(event, newValue) =>
                onChangeTargetSetting(event, newValue)
              }
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant='outlined'
                    label={option.label}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  variant='filled'
                  label='Target Setting'
                  placeholder=''
                />
              )}
            />
          )}
        </Box>
      </Flex>
    </Flex>
  );
};
