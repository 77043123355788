import React, { Fragment } from "react";
import { get } from "lodash";
import { Box, Text } from "rebass";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import NumberFormat from "react-number-format";
import {
  StyledTableAction,
  StyledTableRow,
  StyledTableHeader,
  StyledTableRowHeader,
} from "./styles";

const HeaderOrderIntake = (props) => {
  const { translate, ts } = props;
  const po_total = get(ts, "order_intake.po_total", 0);
  const po_percentage = get(ts, "order_intake.po_percentage", 0);
  const back_order_total = get(ts, "order_intake.back_order_total", 0);
  const back_order_percentage = get(
    ts,
    "order_intake.back_order_percentage",
    0
  );
  return (
    <Box width={["400px"]} pt={[1]} pb={[3]}>
      <TableContainer component={"div"}>
        <Table aria-label='header summary table'>
          <TableHead>
            <StyledTableRowHeader>
              <StyledTableHeader colSpan={3}>
                <Text p={[2]}>
                  {translate("resources.po.table.order_intake")}
                </Text>
              </StyledTableHeader>
            </StyledTableRowHeader>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableAction>
                <Text p={[2]}>{translate("resources.po.table.po_amt")}</Text>
              </StyledTableAction>
              <StyledTableAction>
                <Text>
                  <NumberFormat
                    value={po_total}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={"2"}
                    fixedDecimalScale={true}
                    prefix={""}
                    suffix={"/"}
                  />
                  {translate("resources.po.table.ytd")}
                </Text>
              </StyledTableAction>
              <StyledTableAction>
                <Text pr={[3]} textAlign={"right"}>
                  {po_percentage}%
                </Text>
              </StyledTableAction>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableAction>
                <Text p={[2]}>{translate("resources.po.table.backorder")}</Text>
              </StyledTableAction>
              <StyledTableAction>
                <Text>
                  <NumberFormat
                    value={back_order_total}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={"2"}
                    fixedDecimalScale={true}
                    prefix={""}
                    suffix={"/"}
                  />
                  {translate("resources.po.table.po_amt")}
                </Text>
              </StyledTableAction>
              <StyledTableAction>
                <Text pr={[3]} textAlign={"right"}>
                  {back_order_percentage}%
                </Text>
              </StyledTableAction>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const getSuffixInvoiceSalse = (target, translate) => {
  if (target === "MTD") {
    return translate("resources.po.table.mth");
  } else if (target === "QTD") {
    return translate("resources.po.table.qt");
  } else if (target === "YTD") {
    return translate("resources.po.table.yt");
  }
  return "";
};
const HeaderContents = (props) => {
  const { translate, ts } = props;
  const invoiced_sales = get(ts, "invoiced_sales", []);

  return (
    <Fragment>
      <HeaderOrderIntake {...props} />
      <Box width={[1]} px={[0]}>
        <TableContainer component={"div"}>
          <Table aria-label='header summary table'>
            <TableHead>
              <StyledTableRowHeader>
                <StyledTableHeader colSpan={7}>
                  <Text p={[2]}>
                    {translate("resources.po.table.invoice_sales")}
                  </Text>
                </StyledTableHeader>
              </StyledTableRowHeader>
              <StyledTableRowHeader>
                <StyledTableHeader>
                  <Text p={[2]}>{translate("resources.po.table.target")}</Text>
                </StyledTableHeader>
                <StyledTableHeader>
                  {translate("resources.po.table.total")}
                </StyledTableHeader>
                <StyledTableHeader>%</StyledTableHeader>
                <StyledTableHeader>
                  {translate("resources.po.table.growth")}
                </StyledTableHeader>
                <StyledTableHeader>%</StyledTableHeader>
                <StyledTableHeader>
                  {translate("resources.po.table.speed")}
                </StyledTableHeader>
                <StyledTableHeader>%</StyledTableHeader>
              </StyledTableRowHeader>
            </TableHead>
            <TableBody>
              {invoiced_sales &&
                invoiced_sales.length > 0 &&
                invoiced_sales.map((item, index) => {
                  const target = get(item, "target", "");
                  const suffixTarget = getSuffixInvoiceSalse(target, translate);
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableAction>
                        <Text p={[2]}>{get(item, "target", "")}</Text>
                      </StyledTableAction>
                      <StyledTableAction>
                        <Text>
                          <NumberFormat
                            value={get(item, "total", 0)}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={"2"}
                            fixedDecimalScale={true}
                            prefix={""}
                            suffix={""}
                          />
                          {suffixTarget}
                        </Text>
                      </StyledTableAction>
                      <StyledTableAction>
                        <Text>{get(item, "total_percentage", 0)}%</Text>
                      </StyledTableAction>
                      <StyledTableAction>
                        <Text>
                          <NumberFormat
                            value={get(item, "growth", 0)}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={"2"}
                            fixedDecimalScale={true}
                            prefix={""}
                            suffix={""}
                          />
                          {suffixTarget}
                        </Text>
                      </StyledTableAction>
                      <StyledTableAction>
                        <Text>{get(item, "growth_percentage", 0)}%</Text>
                      </StyledTableAction>
                      <StyledTableAction>
                        <Text>
                          <NumberFormat
                            value={get(item, "speed", 0)}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={"2"}
                            fixedDecimalScale={true}
                            prefix={""}
                            suffix={""}
                          />
                          {suffixTarget}
                        </Text>
                      </StyledTableAction>
                      <StyledTableAction>
                        <Text>{get(item, "speed_percentage", 0)}%</Text>
                      </StyledTableAction>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Fragment>
  );
};

export default HeaderContents;
