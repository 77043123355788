export default {
  inventory: {
    title: "Inventaris",
    fields: {
      category: "Kategori",
      name: "Nama",
      available: "Tersedia",
      ordered: "Dipesan",
      min: "Min",
      max: "Max",
      dealer: "Dealer",
      overstock: "Overstock",
      low: "Terendah",
      to_order: "Memesan",
      search_product: "Mencari produk",
      search_product_packaging: "Mencari paket produk",
      new_existing: "New/Existing",
      growth_speed: "Growth/Speed",
      group: "Group",
      sub_group: "Sub Group",
      backorder: "Backorder",
      code: "Code",
    },
  },
};
