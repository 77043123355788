export default {
  inventory: {
    title: "Inventory",
    fields: {
      category: "Category",
      name: "Name",
      available: "Available",
      ordered: "Ordered",
      min: "Min",
      max: "Max",
      dealer: "Dealer",
      overstock: "Overstock",
      low: "Low",
      to_order: "To Order",
      search_product: "Search Product",
      search_product_packaging: "Search Product Packaging",
      new_existing: "New/Existing",
      growth_speed: "Growth/Speed",
      group: "Group",
      sub_group: "Sub Group",
      backorder: "Backorder",
      code: "Code",
    },
  },
};
