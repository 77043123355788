import React from "react";
import { get, filter, orderBy } from "lodash";
import { Box, Text } from "rebass";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import { CollapseMui } from "../../../../components";
import {
  // StyledTableAction,
  StyledTableCell,
  StyledTableRow,
  StyledTableSmall,
} from "../edit";
import { PromoType_B } from "../edit";
import {
  CurrencyField,
  ItemField,
  ProductField,
  ItemViewFields,
} from "./CurrencyField";

const BuyABGetCDItems = (props) => {
  const { child, translate, onShow, onRemoveItem, isExpired } = props;
  const PromoType = get(child, "promotion_type", "");
  const labelBuyABgetCD = PromoType === PromoType_B[1] ? PromoType_B[1] : "";
  // const listPromo = filter(get(child, "list", []), function (o) {
  //   return o.price * 1 > 0 && o.subtotal * 1 > 0;
  // });
  // const listFreeProduct = filter(get(child, "list", []), function (o) {
  //   return o.subtotal * 1 === 0;
  // });
  const listPromo = filter(get(child, "list", []), function (o) {
    return o.price * 1 > 0 && o.subtotal * 1 > 0 && !o.foc;
  });
  const listFreeProduct = filter(get(child, "list", []), function (o) {
    return o.foc;
  });
  const newListPromo = orderBy(listPromo, "sort_order", "asc");
  const newListFreeProduct = orderBy(listFreeProduct, "sort_order", "asc");
  return (
    <Box width={[1]}>
      <CollapseMui
        label={`Promotion ${get(
          child,
          "promotions.name",
          ""
        )} ${labelBuyABgetCD}`}
        isLeft={true}
        style={{ backgroundColor: "rgba(231,231,231,0.8)" }}
        defaultExpand={true}
        isHaveButton={true}
        labelButton={translate("resources.buttons.edit")}
        onShow={onShow}
        isExpired={isExpired}
        onRemoveItem={() => onRemoveItem()}
      >
        <TableContainer component={"div"}>
          <Table aria-label='customized table'>
            <TableBody>
              {newListPromo.length > 0 &&
                newListPromo.map((promo, indexPromo) => {
                  const subtotal =
                    get(promo, "price", 0) * get(promo, "qty", 0);
                  const isCRDG =
                    get(promo, "cr", false) === true ||
                    get(promo, "dg", false) === true
                      ? { backgroundColor: "#FF0000", color: "#FFFFFF" }
                      : {};
                  const isCRDGCOLOR =
                    get(promo, "cr", false) === true ||
                    get(promo, "dg", false) === true
                      ? { color: "#FFFFFF" }
                      : { color: "rgba(0, 0, 0, 0.87)" };
                  return (
                    <StyledTableRow key={indexPromo} style={isCRDG}>
                      <ItemViewFields
                        promo={promo}
                        subtotal={subtotal}
                        isCRDGCOLOR={isCRDGCOLOR}
                      />
                    </StyledTableRow>
                  );
                })}
              {newListFreeProduct.length > 0 &&
                newListFreeProduct.map((promo, indexPromo) => {
                  return (
                    <StyledTableRow key={indexPromo}>
                      <StyledTableCell
                        style={{
                          color: "#009c7d",
                        }}
                      >
                        <Text pl={["20px"]}>
                          <ProductField
                            record={{ product_id: get(promo, "product_id", 0) }}
                            source={"packaging"}
                          />
                        </Text>
                      </StyledTableCell>
                      <StyledTableSmall
                        style={{
                          color: "#009c7d",
                          width: 130,
                          padding: 8,
                        }}
                      >
                        <ProductField
                          record={{ product_id: get(promo, "product_id", 0) }}
                          source={"code"}
                        />
                      </StyledTableSmall>
                      <StyledTableSmall
                        style={{
                          textAlign: "right",
                          color: "#009c7d",
                          padding: 8,
                          width: 90,
                        }}
                      >
                        <CurrencyField value={0} />
                      </StyledTableSmall>
                      <StyledTableSmall
                        style={{
                          textAlign: "right",
                          color: "#009c7d",
                          padding: 8,
                          width: 150,
                        }}
                      >
                        <CurrencyField value={0} />
                      </StyledTableSmall>
                      <StyledTableSmall
                        style={{
                          textAlign: "right",
                          color: "#009c7d",
                          padding: 8,
                          width: 80,
                        }}
                      >
                        <ItemField value={get(promo, "qty", 0)} />
                      </StyledTableSmall>
                      <StyledTableSmall
                        style={{
                          textAlign: "right",
                          color: "#009c7d",
                          padding: 8,
                          width: 100,
                        }}
                      >
                        <CurrencyField value={0} />
                      </StyledTableSmall>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </CollapseMui>
    </Box>
  );
};

export default BuyABGetCDItems;
