export default {
  buttons: {
    chart: "Chart",
    create_websites: "Create New Website",
    add_new_schedule: "Add New Schedule",
    add_schedule: "Add Schedule",
    edit_schedule: "Edit Schedule",
    add_option: "Add option",
    add_variant: "Add Variant",
    add_product: "Add Product",
    create: "Create",
    cancel: "Cancel",
    cancel_promotion: "Cancel Promotion",
    delete: "Delete",
    save: "Save",
    edit: "Edit",
    generate: "Generate",
    view: "View",
    download_cvs: "Download CSV",
    submit_order: "Submit Order",
    submit_po: "Submit PO",
    add_to_order: "Add to Order",
    add_to_po: "Add to PO",
    print_invoice: "Print Invoice",
    print__pro_form_invoice: "Print Pro Forma Invoice",
    login: "Login",
    create_po: "Create PO",
    save_as_draft: "Save as Draft",
    receive_goods: "Receive Goods",
    update_inventory: "Update Inventory",
    import_from_csv: "Import from CSV",
    close: "Close",
    upload_csv: "Upload CSV",
    upload: "Upload",
    dashboard: "Dashboard",
    list: "List",
    upload_file_csv: "Upload CSV File …",
    or: "OR",
    and: "AND",
    add_normal_price: "Add Non-Promo Item",
    showremarks: "Show Remarks",
    upload_country_phasing: "Upload Country Phasing",
    upload_dealer_target: "Upload Dealer Target",
    upload_promotions: "Upload Promotions",
    broadcast_promotion: "Broadcast Promotion",
    new: "New",
    upload_invoiced_transactions: "Upload Invoiced Transactions",
    upload_inventory: "Upload Inventory",
    import_via_csv: "Import via CSV",
    add_to_list: "Add to List",
    reset: "Reset",
    submit: "Submit",
    view_all: "View All",
    post_to_gp: "Post to GP",
    export_csv: "Export CSV",
    sent_to_dealer: "Sent to Dealer",
    submit_for_approval: "Submit For Approval",
    approve: "Approve",
    reject: "Reject",
    rejected: "Rejected",
    upload_product: "Upload Product",
    confirm: "Confirm",
    clear: "Clear",
    send_back_to_dealer: "Send back to dealer",
    message: {
      cancel_promotion_title: "Cancel Promotion",
      cancel_promotion: "Are You sure want to cancel?",
      expired_promotion: "Promotion has expired",
      remove_promotion_title: "Remove Promotion",
      remove_promotion: "Are You sure want to remove?",
      can_not_remove: "Can not remove this promotion",
    },
  },
};
