import React, { useState } from "react";
import { Flex } from "rebass";
import { useTranslate, FormDataConsumer } from "react-admin";
import {
  PromotionFields,
  // PromotionSetting,
  // PromotionProducts,
  PromotionProductsettings,
  CouponSettings,
} from "../components";
import { SnackBarMessage } from "../../components";
const FormFields = (props) => {
  const translate = useTranslate();
  const {
    hasList,
    hasEdit,
    hasCreate,
    hasShow,
    isCreate,
    updateField,
    ...rest
  } = props;
  const [show, setShow] = useState({ message: "", open: false });
  const checkEmptyValue = (k, v) => {
    // console.log(k, t.target.name);
    if (v === "") {
      setShow({
        ...show,
        message: "Field can not empty, set 0 for default",
        open: true,
      });
      updateField(k, 0);
    }
  };
  return (
    <Flex>
      <FormDataConsumer>
        {({ formData }) => (
          <Flex width={[1]} flexDirection={"column"}>
            <PromotionFields
              formData={formData}
              translate={translate}
              {...props}
            />
            <Flex width={1} flexDirection={"column"} mt={[2]}>
              <PromotionProductsettings
                {...rest}
                translate={translate}
                formData={formData}
                checkEmptyValue={(k, v, e) => checkEmptyValue(k, v, e)}
              />
              {/* <PromotionProducts
                {...props}
                translate={translate}
                formData={formData}
              /> */}
              {formData && formData.need_coupon && (
                <CouponSettings
                  {...props}
                  translate={translate}
                  formData={formData}
                />
              )}
            </Flex>
          </Flex>
        )}
      </FormDataConsumer>
      <SnackBarMessage
        message={show.message}
        open={show.open}
        handleClose={() => setShow({ ...show, open: false })}
      />
    </Flex>
  );
};

export default FormFields;
