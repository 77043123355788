//Promotion Report API : GET/reports/promotion?dealer_id=
import React, { useState } from "react";
import { useQuery } from "../../../components";
//useWindowSize
import { Box, Flex } from "rebass";
import { Loading, downloadCSV } from "react-admin";
import { get, map, filter } from "lodash";
import moment from "moment";
import jsonExport from "jsonexport/dist";
import { HeaderFilters, ListFilters, columnCsv } from "./promotion";
import { ExportCSVFile } from "./utils";
const MainFields = (props) => {
  const { dataList, translate, dealerId } = props;
  const [list, setList] = useState(dataList);
  const [valueCountry, setValueCountry] = useState([]);
  const [valueDealer, setValueDealer] = useState([]);
  const [myfilter, setMyFilter] = useState({
    country: [],
    dealer: [],
    category: [],
    target_setting: [],
  });
  const [valueCategory, setValueCategory] = useState([]);
  const [valueTargetSetting, setValueTargetSetting] = useState([]);

  const onExportCsv = () => {
    if (list && list.length > 0) {
      const newList = map(list, function (o) {
        return {
          country: get(o, "country", ""),
          dealer: get(o, "dealer", ""),
          promotion_type: get(o, "promotion_type", 0),
          category: get(o, "category", ""),
          product_group: get(o, "product_group", ""),
          product_sub_group: get(o, "product_sub_group", ""),
          base_product_1: get(o, "base_product_1", ""),
          base_product_2: get(o, "base_product_2", ""),
          start_date:
            get(o, "start_date", "") !== ""
              ? moment(get(o, "start_date", "")).format("DD/MMM/YYYY")
              : "",
          end_date:
            get(o, "end_date", "") !== ""
              ? moment(get(o, "end_date", "")).format("DD/MMM/YYYY")
              : "",
          free_item_1: get(o, "free_item_1", ""),
          free_item_2: get(o, "free_item_2", ""),
          moq_1: get(o, "moq_1", 0),
          base_1: get(o, "base_1", 0),
          foc_1: get(o, "foc_1", 0),
          additional_qty_1: get(o, "additional_qty_1", 0),
          price_1: get(o, "price_1", 0),
          discount_1: get(o, "discount_1", 0),
          moq_2: get(o, "moq_2", 0),
          base_2: get(o, "base_2", 0),
          foc_2: get(o, "foc_2", 0),
          additional_qty_2: get(o, "additional_qty_2", 0),
          price_2: get(o, "price_2", 0),
          discount_2: get(o, "discount_2", 0),
          moq_3: get(o, "moq_3", 0),
          base_3: get(o, "base_3", 0),
          foc_3: get(o, "foc_3", 0),
          additional_qty_3: get(o, "additional_qty_3", 0),
          price_3: get(o, "price_3", 0),
          discount_3: get(o, "discount_3", 0),
          po_order_qty_moq_1: get(o, "po_order_qty_moq_1", 0),
          po_order_qty_moq_2: get(o, "po_order_qty_moq_2", 0),
          po_order_qty_moq_3: get(o, "po_order_qty_moq_3", 0),
          po_order_qty_total: get(o, "po_order_qty_total", 0),
        };
      });
      jsonExport(
        newList,
        {
          headers: columnCsv,
        },
        (err, csv) => {
          if (err) return console.error(err);
          downloadCSV(
            csv,
            `promotions-${moment().format("DD-MMM-YYYY-HH:mm:ss")}`
          );
        }
      );
    }
  };

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <HeaderFilters
        {...props}
        setList={setList}
        valueCategory={valueCategory}
        valueCountry={valueCountry}
        valueDealer={valueDealer}
        myfilter={myfilter}
        setValueCategory={setValueCategory}
        setValueCountry={setValueCountry}
        setValueDealer={setValueDealer}
        setMyFilter={setMyFilter}
        valueTargetSetting={valueTargetSetting}
        setValueTargetSetting={setValueTargetSetting}
      />
      <ExportCSVFile
        translate={translate}
        list={list}
        onExportCsv={onExportCsv}
      />

      <Flex width={[1]} flexDirection={"column"}>
        {list && <ListFilters list={list} dealerId={dealerId} />}
      </Flex>
    </Flex>
  );
};

export default (props) => {
  // const { width } = useWindowSize();
  const { dealerId, country } = props;
  const paramDealerId = dealerId !== null ? `?dealer_id=${dealerId}` : "";
  const { loaded, data } = useQuery(`/reports/promotion${paramDealerId}`);

  if (!loaded) {
    return (
      <Box width={[1]}>
        <Loading />
      </Box>
    );
  }
  const dataList =
    data && data.length > 0
      ? Array.from(
          new Set(
            data.map((p, index) => {
              return { ...p, id: index };
            })
          )
        )
      : [];
  let myDataList = dataList;
  if (country && country.id) {
    myDataList = filter(dataList, function (o) {
      return o.country === country.name;
    });
  }

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <MainFields dataList={myDataList} {...props} />
    </Flex>
  );
};
