import React, { Fragment, useState } from "react";
import { Flex, Box } from "rebass";
import { get } from "lodash";
import { useTranslate, FormDataConsumer, ImageField } from "react-admin";
import {
  PromotionFields,
  // PromotionSetting,
  // PromotionProducts,
  PromotionProductsettings,
  CouponSettings,
  ViewPromotionProductsettings,
  ViewCouponSettings,
} from "../components";
import {
  RaTextArea,
  TextRecord,
  RaBooleanInput,
  ImageOlaInputNotDelete,
} from "../../components";
import { statusList } from "./Edit";
import { DealerFields } from "./ViewFormFields";
//CountryFields,
//PhotoFields,
import Paper from "@material-ui/core/Paper";
import { showApprove } from "../../utils";
import { SnackBarMessage } from "../../components";
// import Typography from "@material-ui/core/Typography"
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
// import ActionList from "./ActionList"

const FormFields = (props) => {
  const translate = useTranslate();
  const {
    hasList,
    hasEdit,
    hasCreate,
    hasShow,
    isCreate,
    updateField,
    ...rest
  } = props;
  const [show, setShow] = useState({ message: "", open: false });

  const status = get(rest, "record.status", "");
  const { record } = rest;
  const checkEmptyValue = (k, v, e, formData) => {
    // console.log(k, t.target.name);
    const moqK = k.substr(0, k.lastIndexOf("."));
    const mySource = k.split(".").pop();
    const moq = get(formData, `${moqK}.moq`, 0);
    if (moq * 1 <= 0 && mySource !== "moq" && mySource !== "additional") {
      setShow({
        ...show,
        message: "MOQ can not empty or 0",
        open: true,
      });
      updateField(k, 0);
    } else {
      if (mySource === "additional") {
        if (v === "") {
          updateField(k, 0);
        }
      } else {
        if (v === "") {
          setShow({
            ...show,
            message: "Field can not empty, set 0 for default",
            open: true,
          });
          updateField(k, 0);
        }
      }
    }
  };
  if (
    status !== "" &&
    (status === statusList.approved ||
      status === statusList.rejected ||
      status === statusList.cancelled)
  ) {
    return (
      <Fragment>
        <Paper
          elevation={3}
          style={{ minHeight: 250, padding: 16, marginBottom: 16 }}
        >
          <Flex width={[1]} flexDirection={"column"}>
            <RaBooleanInput
              source={"active"}
              label={"resources.promotions.fields.active"}
            />

            <TextRecord
              record={record}
              source={"name"}
              label={"resources.promotions.fields.name"}
            />
            {/* <PhotoFields
              {...props}
              source={"photos"}
              label={translate("resources.promotions.fields.photo")}
            /> */}
            <FormDataConsumer>
              {({ formData }) => (
                <Box width={[1]}>
                  <ImageOlaInputNotDelete
                    source='photos'
                    toValue='photos'
                    labelImage={translate("resources.promotions.fields.photo")}
                    accept='image/*'
                    key='photos'
                    record={formData ? formData : props.record}
                    updateField={updateField}
                    {...rest}
                  >
                    <ImageField source='src' title='title' />
                  </ImageOlaInputNotDelete>
                </Box>
              )}
            </FormDataConsumer>
            {/* <CountryFields
              {...props}
              source={"country"}
              label={translate("resources.promotions.fields.country")}
            /> */}
            <DealerFields
              {...props}
              source={"dealer"}
              label={translate("resources.promotions.fields.dealers")}
            />
            <Flex
              width={[1]}
              flexDirection={"row"}
              py={[2]}
              sx={{ "&>div": { pr: [2] } }}
            >
              <TextRecord
                record={record}
                source='start_date'
                isDate={true}
                formatDate={"DD/MMM/YYYY"}
                label={"resources.promotions.fields.start_date"}
              />
              <TextRecord
                record={record}
                source='end_date'
                isDate={true}
                formatDate={"DD/MMM/YYYY"}
                label={"resources.promotions.fields.end_date"}
              />
            </Flex>
            <TextRecord
              record={record}
              source='promotion_type'
              label={"resources.promotions.fields.promotion_type"}
            />

            <Box width={[1]} py={[1]}>
              <RaTextArea
                source='remarks'
                label={"resources.promotions.fields.remarks"}
                // rows="5"

                fullWidth
                multiline
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                style={{ transform: "none" }}
              />
            </Box>
          </Flex>
        </Paper>
        <ViewPromotionProductsettings {...rest} translate={translate} />
        {record && record.need_coupon && (
          <ViewCouponSettings {...rest} translate={translate} />
        )}
        <TextRecord
          record={record}
          source={"approval_comment"}
          label={"resources.promotions.fields.approval_comment"}
        />
      </Fragment>
    );
  }
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <FormDataConsumer>
        {({ formData }) => (
          <Flex width={[1]} flexDirection={"column"}>
            <PromotionFields
              isProduct={true}
              formData={formData}
              translate={translate}
              updateField={updateField}
              {...rest}
            />
            <Flex width={1} flexDirection={"column"} mt={[2]}>
              <PromotionProductsettings
                {...rest}
                translate={translate}
                formData={formData}
                checkEmptyValue={(k, v, e) =>
                  checkEmptyValue(k, v, e, formData)
                }
                updateField={updateField}
              />
              {/* <PromotionProducts
                {...props}
                translate={translate}
                formData={formData}
              /> */}
              {formData && formData.need_coupon && (
                <CouponSettings
                  {...props}
                  translate={translate}
                  formData={formData}
                />
              )}
            </Flex>
          </Flex>
        )}
      </FormDataConsumer>
      {showApprove(props.permissions) && (
        <Box width={[1]} py={[1]}>
          <RaTextArea
            source='approval_comment'
            label={"resources.promotions.fields.approval_comment"}
            // rows="5"

            fullWidth
            multiline
            InputLabelProps={{
              shrink: true,
              disableAnimation: true,
            }}
            style={{ transform: "none" }}
          />
        </Box>
      )}
      <SnackBarMessage
        message={show.message}
        open={show.open}
        handleClose={() => setShow({ ...show, open: false })}
      />
    </Flex>
  );
};

export default FormFields;
