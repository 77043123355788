import React, { useRef } from "react";
import { Form } from "react-final-form";
import { get, filter, sumBy, map, has } from "lodash";
import arrayMutators from "final-form-arrays";
import {
  useTranslate,
  sanitizeEmptyValues,
  getFormInitialValues,
  useNotify,
  useDataProvider,
} from "react-admin";
import { message } from "antd";
// import moment from "moment";
import SimpleFormView from "./SimpleFormView";
import CustomFormView from "./CustomFormView";
import { PROFILE, PromoTypeB } from "../../restClient";

const error = (text) => {
  message.error(text);
};

const getSubtotal = (record) => {
  const { items } = record;
  const newList = map(items, function (o) {
    // const promotions = get(o, "promotions", null);

    const promotions = get(o, "promotions", null);
    const promoType = get(promotions, "promotion_type", "");
    const discountPrice = get(o, "discounted_price", 0) * 1;

    if (promoType === PromoTypeB.sp) {
      return { id: o.id, subtotal: o.qty * discountPrice };
    }
    const price = get(o, "price", 0) * 1;
    const newPrice = has(o, "discounted_price") ? discountPrice : price;
    return { id: o.id, subtotal: o.qty * newPrice };
  });
  return newList;
};

const checkDiscountedPrice = (record) => {
  const items = get(record, "items", []);
  const newItems = items.map((i) => {
    const discountPrice = get(i, "discounted_price", 0) * 1;
    const price = get(i, "price", 0) * 1;
    const newPrice = has(i, "discounted_price") ? discountPrice : price;
    return {
      ...i,
      discounted_price: newPrice,
    };
  });
  const listTotal = getSubtotal(record);
  const totalAmountRecord = sumBy(listTotal, "subtotal");
  const newParams = {
    ...record,
    items: newItems,
    total: totalAmountRecord,
  };
  return newParams;
};

const SimplePOOrderForm = ({
  initialValues,
  defaultValue,
  saving,
  typePostUrl,
  ...props
}) => {
  // const current = moment();
  const notify = useNotify();
  let redirect = useRef(props.redirect);
  // We don't use state here for two reasons:
  // 1. There no way to execute code only after the state has been updated
  // 2. We don't want the form to rerender when redirect is changed
  const setRedirect = (newRedirect) => {
    redirect.current = newRedirect;
  };

  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const finalInitialValues = getFormInitialValues(
    initialValues,
    defaultValue,
    props.record
  );

  const checkInactivePromotion = (items) => {
    const checkPromotionOnly = filter(items, function (p) {
      return get(p, "promotions.id");
    });
    const filterPromotions = filter(checkPromotionOnly, function (oo) {
      const { promotions } = oo;
      const isActive = get(promotions, "active", false);
      return (
        isActive &&
        promotions &&
        promotions.start_date &&
        promotions.start_date !== null &&
        promotions.end_date &&
        promotions.end_date !== null
        //  &&
        // moment(promotions.start_date).isSameOrBefore(current, "day") &&
        // moment(promotions.end_date).isSameOrAfter(current, "day")
      );
    });
    return checkPromotionOnly.length === filterPromotions.length;
  };

  const submit = (values) => {
    // const finalRedirect =
    //   typeof redirect.current === undefined ? props.redirect : redirect.current
    const finalValues = sanitizeEmptyValues(finalInitialValues, values);
    const items = get(values, "items", []);
    const isActive = checkInactivePromotion(items);
    if (items && items.length > 0) {
      if (isActive) {
        if (typePostUrl && typePostUrl === "CREATE") {
          let newValues = values;
          const myItems = get(newValues, "items", []);
          let total = 0;
          myItems.map((item) => {
            total = total + item.subtotal * 1;
            return "";
          });
          newValues["total"] = total;
          if (finalValues.dealer_id) {
            newValues["dealer_id"] = finalValues.dealer_id;
          } else {
            const profile = localStorage.getItem(PROFILE);
            const profile_id = JSON.parse(profile);
            newValues["dealer_id"] = profile_id.id;
          }
          const newParams = checkDiscountedPrice(newValues);
          dataProvider
            .create("purchaseOrder", {
              data: newParams,
            })
            .then((response) => {
              if (response && response.data) {
                // console.log(response.data);
                notify("resources.po.messages.element_created");
                props.history.push(`/orders`);
              } else {
                notify("resources.po.messages.create_order", "warning");
              }
            })
            .catch((e) => {
              notify(e.message, "warning");
            });
        } else if (typePostUrl && typePostUrl === "UPDATE") {
          let newValues = values;
          const myItems = get(newValues, "items", []);
          // console.log("values", values);
          // console.log("finalValues", finalValues);
          // console.log(myItems);
          let total = 0;
          myItems.map((item) => {
            total = total + item.subtotal * 1;
            return "";
          });
          newValues["total"] = total;
          const newParams = checkDiscountedPrice(newValues);
          dataProvider
            .update("purchaseOrder", {
              id: finalValues.id,
              data: newParams,
              previousData: finalValues,
            })
            .then((response) => {
              if (response && response.data) {
                notify("resources.po.messages.element_updated");
                props.history.push(`/orders`);
              } else {
                notify("resources.po.messages.create_order", "warning");
              }
            })
            .catch((e) => {
              notify(e.message, "warning");
            });
        }
      } else {
        error(translate("resources.po.messages.inactive_item_promotions"));
        // notify("resources.po.messages.inactive_item_promotions", "warning");
      }
    } else {
      error(translate("resources.po.messages.orderItemsEmpty"));
      // notify("resources.po.messages.orderItemsEmpty", "warning");
    }
  };
  const { isCustomRender, ...newProps } = props;
  return (
    <Form
      key={props.version}
      initialValues={finalInitialValues}
      onSubmit={submit}
      mutators={{
        ...arrayMutators,
        updateField: ([field, value], state, utils) => {
          utils.changeValue(state, field, () => value);
        },
      }}
      keepDirtyOnReinitialize
      destroyOnUnregister
      subscription={defaultSubscription}
      {...newProps}
      render={({
        form: {
          mutators: { updateField },
        },
        ...formProps
      }) =>
        isCustomRender ? (
          <CustomFormView
            saving={formProps.submitting || saving}
            translate={translate}
            setRedirect={setRedirect}
            {...newProps}
            {...formProps}
            updateField={(key, value) => updateField(key, value)}
          />
        ) : (
          <SimpleFormView
            saving={formProps.submitting || saving}
            translate={translate}
            setRedirect={setRedirect}
            {...newProps}
            {...formProps}
          />
        )
      }
    />
  );
};

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
};

export default SimplePOOrderForm;
