import React, { Fragment } from "react"
import { withStyles } from "@material-ui/core/styles"
import { useTranslate } from "react-admin"
// import { useHistory } from "react-router-dom"
import { styles } from "./styles"
// import SubMenuItem from "./SubMenuItem"
import MenuItemLink from "../MenuItemLink"
import { ViewIcon, BagIcon, BadgeIcon } from "../../icon_svg"
// import { MenuShow } from "./utils"

const MenuCustomers = (props) => {
  const translate = useTranslate()
  return (
    <Fragment>
      <MenuItemLink
        leftIcon={<BagIcon />}
        to={`/products`}
        primaryText={translate(`resources.menus.products`, {
          smart_count: 2,
        })}
        onClick={props.onMenuClick}
        translate={translate}
      />
      <MenuItemLink
        to={`/promotions`}
        primaryText={translate(`resources.menus.promotions`, {
          smart_count: 2,
        })}
        leftIcon={<BadgeIcon />}
        onClick={props.onMenuClick}
        translate={translate}
      />
      {/* <MenuItemLink
        to={`/inventories`}
        primaryText={translate(`resources.menus.inventory`, {
          smart_count: 2,
        })}
        leftIcon={<DocumentIcon />}
        onClick={props.onMenuClick}
        translate={translate}
      /> */}
      <MenuItemLink
        to={`/purchaseOrder`}
        primaryText={translate(`resources.menus.po`, {
          smart_count: 2,
        })}
        leftIcon={<ViewIcon />}
        onClick={props.onMenuClick}
        translate={translate}
      />
    </Fragment>
  )
}

export default withStyles(styles)(MenuCustomers)
