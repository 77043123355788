import React, { Fragment, cloneElement, Children, useState } from "react"
import PropTypes from "prop-types"
import { useLocation } from "react-router-dom"
import {
  useSortState,
  usePaginationState,
  useReferenceManyFieldController,
} from "react-admin"

import { lightBlue } from "@material-ui/core/colors"
import TextField from "@material-ui/core/TextField"

import { Box, Flex } from "rebass"
import { CreateButton } from "../buttons"
import { AddIcon } from "../icon_svg"

export const RaReferenceManyField = props => {
  const {
    children,
    sort: initialSort,
    perPage: initialPerPage,
    resource,
    reference,
    record,
    target,
    source,
    basePath,
    labelCreate,
    defaultbasePath,
    notShowSearch,
  } = props
  if (React.Children.count(children) !== 1) {
    throw new Error(
      "<ReferenceManyField> only accepts a single child (like <Datagrid>)"
    )
  }
  const [searchText, setSearchText] = useState("")
  const { sort, setSortField } = useSortState(initialSort)
  const { page, perPage, setPage, setPerPage } = usePaginationState({
    perPage: initialPerPage,
  })

  let location = useLocation()
  const { filter, ...rest } = props

  const handleChange = event => {
    setSearchText(event.target.value)
  }

  const controllerProps = useReferenceManyFieldController({
    resource,
    reference,
    record,
    target,
    filter: { search: searchText },
    source,
    basePath: basePath ? basePath : defaultbasePath,
    page,
    perPage,
    sort,
  })
  return (
    <Box width={[1]}>
      <Flex
        width={[1]}
        p={[2]}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box width={[1]}>
          {!notShowSearch && (
            <TextField
              placeholder={"Search"}
              value={searchText}
              onChange={handleChange}
              margin={"dense"}
            />
          )}
        </Box>
        <Box>
          <CreateButton
            basePath={location.pathname}
            icon={<AddIcon />}
            sizeIcon={"xsmall"}
            style={{
              color: lightBlue[700],
              justifyContent: "center",
              textTransform: "none",
            }}
            label={labelCreate}
          />
        </Box>
      </Flex>
      <ReferenceManyFieldView
        {...rest}
        {...{
          currentSort: sort,
          page,
          perPage,
          setPage,
          setPerPage,
          setSort: setSortField,
          ...controllerProps,
        }}
      />
    </Box>
  )
}

RaReferenceManyField.propTypes = {
  addLabel: PropTypes.bool,
  basePath: PropTypes.string,
  children: PropTypes.element.isRequired,
  classes: PropTypes.object,
  className: PropTypes.string,
  filter: PropTypes.object,
  label: PropTypes.string,
  perPage: PropTypes.number,
  record: PropTypes.object,
  reference: PropTypes.string.isRequired,
  resource: PropTypes.string,
  sortBy: PropTypes.string,
  source: PropTypes.string.isRequired,
  labelCreate: PropTypes.string,
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  target: PropTypes.string.isRequired,
}

RaReferenceManyField.defaultProps = {
  filter: {},
  perPage: 25,
  sort: { field: "id", order: "DESC" },
  source: "id",
  addLabel: true,
  labelCreate: "ra.action.create",
}

export const ReferenceManyFieldView = ({
  children,
  className,
  currentSort,
  data,
  ids,
  loaded,
  page,
  pagination,
  perPage,
  reference,
  referenceBasePath,
  setPage,
  setPerPage,
  setSort,
  total,
}) => (
  <Fragment>
    {cloneElement(Children.only(children), {
      className,
      resource: reference,
      ids,
      loaded,
      data,
      basePath: referenceBasePath,
      currentSort,
      setSort,
      total,
    })}
    {pagination &&
      total !== undefined &&
      cloneElement(pagination, {
        page,
        perPage,
        setPage,
        setPerPage,
        total,
      })}
  </Fragment>
)

ReferenceManyFieldView.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  currentSort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  data: PropTypes.object,
  ids: PropTypes.array,
  loaded: PropTypes.bool,
  pagination: PropTypes.element,
  reference: PropTypes.string,
  referenceBasePath: PropTypes.string,
  setSort: PropTypes.func,
}

export default RaReferenceManyField
