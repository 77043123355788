export default {
  accounts: {
    name: "Akun",
    create_password: "Create Password",
    reset_password: "Reset Password",
    forgot_password: "Forgot Password",
    fields: {
      name: "Nama",
      address: "Alamat kantor",
      contact_person: "Orang yang dapat dihubungi",
      contact_email: "Kontak email",
      contact_phone: "Kontak telepon",
      remarks: "Catatan",
      created_at: "Dibuat pada",
      confirm_password: "Confirm Password",
      email: "Email",
    },
    message: {
      name: "Nama akun, biasanya, cantumkan nama perusahaan",
      succes_create_password: "Your account is setup successfully.",
      succes_forgot_password:
        "We have sent the instructions to reset password to your email.",
      succes_reset_password: "Your password is reset successfully",
      try_again: "Silahkan dicoba kembali",
    },
  },
};
