import React from "react";
import { get } from "lodash";
import { Box, Text } from "rebass";
import { useQueryWithStore, useTranslate } from "react-admin";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import { getUser } from "../../../utils";

const ShowRemark = (props) => {
  const translate = useTranslate();
  const { countryId } = props;
  const { data, loading } = useQueryWithStore({
    type: "getOne",
    resource: "countries",
    payload: { id: countryId },
  });
  if (loading) {
    return (
      <Box width={[1]} my={[2]}>
        <Paper elevation={3}>
          <Box width={[1]} p={[2]}>
            <LinearProgress />
          </Box>
        </Paper>
      </Box>
    );
  }
  return (
    <Box width={[1]} my={[2]}>
      <Paper elevation={3}>
        <Box width={[1]} p={[2]}>
          <Text fontWeight={"bold"} p={[2]} pl={[0]}>
            {translate("resources.po.fields.remarks")}
          </Text>
          <Text p={[2]} pt={[0]} pl={[0]}>
            {get(data, "order_form_remarks", "")}
          </Text>
        </Box>
      </Paper>
    </Box>
  );
};

export default (props) => {
  const { record } = props;
  const user = getUser();
  const dealerId = get(record, "dealer_id", get(user, "dealer_id"));

  const { data, loading } = useQueryWithStore({
    type: "getOne",
    resource: "dealers",
    payload: { id: dealerId },
  });

  if (loading) {
    return (
      <Box width={[1]} my={[2]}>
        <Paper elevation={3}>
          <Box width={[1]} p={[2]}>
            <LinearProgress />
          </Box>
        </Paper>
      </Box>
    );
  }

  return get(data, "country_id") ? (
    <ShowRemark countryId={get(data, "country_id")} />
  ) : (
    <div />
  );
};
