import React from "react"
import { Box } from "rebass"
import ContainerDimensions from "react-container-dimensions"
import { useTranslate } from "react-admin"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import SwipeableViews from "react-swipeable-views"
import { Divider, Tabs, Tab } from "@material-ui/core"
import { ContentShow, Dashboard } from "./"
import { TabPanel, a11yProps } from "../../components"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}))

const TabsDetail = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  const theme = useTheme()

  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }
  return (
    <div className={classes.root}>
      <Tabs value={value} indicatorColor="primary" onChange={handleChange}>
        <Tab
          label={translate("resources.products.tabs.details")}
          {...a11yProps("products", 0)}
        />
        <Tab
          label={translate("resources.products.tabs.analytics")}
          {...a11yProps("products", 1)}
        />
      </Tabs>
      <Divider />
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel
          value={value}
          index={0}
          dir={theme.direction}
          name={"products"}
        >
          <ContentShow {...props} />
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          dir={theme.direction}
          name={"products"}
        >
          <Box width={[1]}>
            <ContainerDimensions>
              {({ width, height }) => {
                return (
                  <Box width={[1]}>
                    <Dashboard {...props} width={width} height={height} />
                  </Box>
                )
              }}
            </ContainerDimensions>
          </Box>
        </TabPanel>
      </SwipeableViews>
    </div>
  )
}

export default TabsDetail
