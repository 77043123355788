import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";
import { translate } from "react-admin";
// import { ROLE_USER } from "../../utils"

import { SuperAdminMenu } from "./RoleMenu";
class Menu extends Component {
  state = {
    menuSettings: false,
  };

  static propTypes = {
    onMenuClick: PropTypes.func,
    logout: PropTypes.object,
  };

  handleToggle = (menu) => {
    this.setState((state) => ({ [menu]: !state[menu] }));
  };

  render() {
    const { onMenuClick, open, translate, logout } = this.props;
    return (
      <div>
        <SuperAdminMenu
          onMenuClick={onMenuClick}
          handleToggle={() => this.handleToggle("menuSettings")}
          menuSettings={this.state.menuSettings}
          open={open}
          translate={translate}
        />
        {logout}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  open: state.admin.ui.sidebarOpen,
  theme: state.theme,
  // locale: state.i18n.locale,
});

const enhance = compose(withRouter, connect(mapStateToProps, {}), translate);

export default enhance(Menu);
