import React from "react";
import { useTranslate } from "react-admin";
import { Flex, Box } from "rebass";
// import { lightBlue } from "@material-ui/core/colors"
import { ListButton } from "../buttons";
import { Title } from "../Text";

export const ActionForm = (props) => {
  const translate = useTranslate();
  const { pt = 3, search } = props;
  const urlBasePath = search ? props.basePath + search : props.basePath;
  return (
    <Flex
      flexDirection={"row"}
      justifyContent={"space-between"}
      width={[1]}
      pt={[pt]}
      pb={[2]}
    >
      <Box width={[1]}>
        <Title fontWeight={"bold"} fontSize={[3]}>
          {props.title && translate(props.title)}
        </Title>
      </Box>
      <Flex width={[1]} flexDirection={"row"} justifyContent={"flex-end"}>
        <ListButton basepath={urlBasePath} record={props.data} />
      </Flex>
    </Flex>
  );
};
