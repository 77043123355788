import React, { useState } from "react"
import { get } from "lodash"
import { Flex, Box, Text, Image } from "rebass"
import NumberFormat from "react-number-format"
import { TextField, Button } from "@material-ui/core"
import { ThemeProvider } from "@material-ui/core/styles"
import SnackBarMessage from "./SnackBarMessage"
import { themeQuantityInput } from "../../../themes"
import PromotionField from "./PromotionField"

const ProductItem = (props) => {
  const { product, translate, width, onAddItemProduct, data } = props
  // console.log(product)
  const [severity, setSeverity] = useState("error")
  const [qty, setQty] = useState(0)
  const [open, setOpen] = useState(0)
  const [message, setMessage] = useState("")
  const photo = get(product, "photos[0]", "")
  const handleClose = () => {
    setOpen(false)
  }
  const onChangeQuantity = (e) => {
    setQty(e.target.value)
  }

  const onCheckAddItemProduct = () => {
    if (qty > 0) {
      setOpen(true)
      onAddItemProduct(qty, product, data)
      setSeverity("success")
      setMessage(translate("resources.po.messages.product_added"))
    } else {
      setOpen(true)
      setSeverity("error")
      setMessage(translate("resources.po.messages.product_qty"))
    }
  }

  const urlPhoto =
    photo && photo !== "" && photo !== null
      ? photo
      : require("../../../assets/images/placeholder.jpg")
  const sizeImage = width > 832 ? width / 5 : width / 3
  return (
    <Box
      width={[1, 1 / 3, 1 / 5]}
      sx={{
        display: "grid",
        gridGap: 4,
      }}
      p={[2]}
      pb={[1]}
    >
      <Flex
        width={[1]}
        style={{ border: "1px solid #d1d1d1", flex: 1 }}
        flexDirection={"column"}
        justifyContent={"space-between"}
      >
        <Flex
          width={[1]}
          flexDirection={"column"}
          justifyContent={"flex-start"}
        >
          <Flex
            width={[1]}
            flexDirection={"column"}
            justifyContent={"center"}
            style={{ height: sizeImage }}
            mt={-12}
          >
            <Image src={urlPhoto} />
          </Flex>
        </Flex>
        <Box width={[1]} style={{ minHeight: 40 }}>
          <Text px={[2]} pb={[2]} fontSize={[0]} fontWeight={"bold"}>
            {get(product, "packaging", "")}
          </Text>
        </Box>
        {get(product, "summary", "") !== "" && (
          <Text px={[2]} pb={[2]} fontSize={[0]} color={"grey"}>
            {get(product, "summary", "")}
          </Text>
        )}
        <PromotionField record={product} source="name" resource_id="id" />
        <div style={{ position: "relative" }}>
          <Flex
            width={[1]}
            flexDirection={"column"}
            justifyContent={"flex-end"}
            style={{ position: "inherit", bottom: "0px" }}
            pt={[2]}
          >
            <Text px={[2]} pb={[1]} fontSize={[0]} color={"grey"}>
              <NumberFormat
                value={get(product, "price", 0)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </Text>
            <Flex
              width={[1]}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              pr={[2]}
              py={[2]}
              pl={[0]}
            >
              <Box pl={[1]} px={[2]}>
                <Text pb={[1]} fontSize={[0]}>
                  {translate("resources.po.fields.moq")}
                </Text>
                <Text fontSize={[0]}>{get(product, "moq", 0)}</Text>
              </Box>
              <ThemeProvider theme={themeQuantityInput}>
                <TextField
                  id="qty-field"
                  name={"qty-field"}
                  label={translate("resources.po.fields.quantity")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={qty}
                  onChange={onChangeQuantity}
                  style={{ minWidth: 60, width: 60 }}
                />
              </ThemeProvider>
            </Flex>
            <Flex
              width={[1]}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              py={[3]}
            >
              <Button
                onClick={onCheckAddItemProduct}
                color="primary"
                variant={"contained"}
                style={{ textTransform: "capitalize", borderRadius: 24 }}
              >
                {translate("resources.buttons.add_to_po")}
              </Button>
            </Flex>
          </Flex>
        </div>
      </Flex>
      <SnackBarMessage
        message={message}
        open={open}
        severity={severity}
        handleClose={handleClose}
      />
    </Box>
  )
}
export default ProductItem
