import React from "react"
import { withRouter } from "react-router-dom"
import { Box } from "rebass"
import { connect } from "react-redux"
import compose from "recompose/compose"
import { showNotification } from "react-admin"
import { BetterCustomSimpleForm, FooterToolbar, CardPanel } from "./"
import FormFields from "./FormFields"
const initValues = {
  type: "Customer",
  need_coupon: false,
  action: {
    promotion_type: "Buy X Get Y",
  },
  buxgety: {
    type: "Simple",
    simple: {
      product: "",
      list: [],
    },
    advanced: {
      buy: [{ product_id: "", qty: 1, condition: "AND" }],
      get: [{ product_id: "", qty: 1, condition: "AND" }],
    },
  },
  buy_more_discount: {
    qty: 1,
    type: "Percent",
    product: "",
    discount: 0,
  },
  coupon: {
    auto_generate: true,
  },
}

const CreateCustomerPromotion = (props) => {
  return (
    <CardPanel>
      <Box width={[1]} pt={[3]}>
        <BetterCustomSimpleForm
          {...props}
          toolbar={<FooterToolbar />}
          initialValues={initValues}
          sourceApi="promotions"
          title={"resources.promotions.title.new_promotion"}
          typePostUrl={"CREATE"}
          pathUrl="promotions"
          customBackUrl="/promotions/customers"
        >
          <FormFields {...props} isCreate={true} />
        </BetterCustomSimpleForm>
      </Box>
    </CardPanel>
  )
}

const enhance = compose(withRouter, connect(undefined, { showNotification }))

export default enhance(CreateCustomerPromotion)
