import React, { Fragment } from "react";
import { get } from "lodash";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useQueryWithStore } from "react-admin";

const CountryField = (props) => {
  const { child } = props;
  const country_id = get(child, "country_id", "0");
  const { data, loading } = useQueryWithStore({
    type: "getOne",
    resource: "countries",
    payload: { id: country_id },
  });
  if (loading) {
    return (
      <Fragment>
        <LinearProgress />
      </Fragment>
    );
  }
  return <div>{data.name} </div>;
};
export default CountryField;
