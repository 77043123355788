import React from "react";
import { Box } from "rebass";
import { useTranslate } from "react-admin";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Paper from "@material-ui/core/Paper";
import { TextInput } from "react-admin";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "../../../components";
const DetailFields = (props) => {
  const translate = useTranslate();
  const [state, setState] = React.useState({
    expanded: "panelDetail",
  });

  const handleChange = (panel) => (event, newExpanded) => {
    setState({
      ...state,
      expanded: newExpanded ? panel : false,
    });
  };
  const onkeyPress = (e) => {
    //handle keypress enter
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <Box width={[1]}>
      <Paper elevation={3} style={{ padding: 0 }}>
        <Box width={[1]} p={[2]}>
          <ExpansionPanel
            expanded={state.expanded === "panelDetail"}
            onChange={handleChange("panelDetail")}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='detail-list-content'
              id='detail-list-header'
            >
              <Typography>
                {translate("resources.promotions.panel.details")}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              style={{ width: "100%%", padding: 0, paddingTop: 16 }}
            >
              <Box width={[1]} p={[3]} pt={[0]}>
                <TextInput
                  source='my_reference_number'
                  label='resources.po.fields.ref_number'
                  style={{ width: 300 }}
                  onKeyPress={(e) => onkeyPress(e)}
                />
                <TextInput
                  fullWidth
                  multiline
                  source='remarks'
                  label='resources.po.fields.remarks'
                />
              </Box>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Box>
      </Paper>
    </Box>
  );
};

export default DetailFields;
