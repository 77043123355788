import React, { useState, useEffect } from "react";
import { Box } from "rebass";
import { downloadCSV } from "react-admin";
import moment from "moment";
import { get, map } from "lodash";
import jsonExport from "jsonexport/dist";
import { ExportCSVFile } from "./utils";
import { HeaderTop20, ListTop20 } from "./top_20";
import { RestApiCURD } from "../../../restClient";

export default (props) => {
  const { translate, country, dealerId } = props;
  const myCountry = country && country.id ? [country.id] : [];
  const [list, setList] = useState([]);
  const [isRequest, setIsRequest] = useState(true);
  const [topParams, setTopParams] = useState("");
  const [valueCountry, setValueCountry] = useState([]);
  const [valueDealer, setValueDealer] = useState([]);
  const [valueCategory, setValueCategory] = useState([]);
  const [valueTargetSetting, setValueTargetSetting] = useState([]);
  const [myfilter, setMyFilter] = useState({
    country_ids: myCountry,
    dealer_ids: [],
    category_ids: [],
    target_ids: [],
    top: 20,
  });
  const paramDealerId = dealerId !== null ? `?dealer_id=${dealerId}` : "";
  // const getList = () => {
  //   console.log(paramDealerId);
  // };
  useEffect(() => {
    function getReport() {
      if (isRequest) {
        RestApiCURD(`reports/top${paramDealerId}`, "POST", myfilter)
          .then((resp) => resp.json())
          .then((response) => {
            // console.log(response);
            if (response && response.length > 0) {
              setList(response);
            } else {
              setList([]);
            }
            setIsRequest(false);
          })
          .catch((e) => {
            setIsRequest(false);
            // console.log(e.message);
          });
      }
    }

    getReport();
  }, [isRequest, myfilter, paramDealerId]);
  /**category: "GIC Restorative"
country: "Vietnam"
dealer: "LIEN NHA CO LTD"
invoiced: 1191125
packaging: "GC Gold Label 9 1-1 Extra A3"
target_setting: "GC Gold Label 9 Extra" */
  const onExportCsv = () => {
    if (list && list.length > 0) {
      const newList = map(list, function (o) {
        return {
          country: get(o, "country", ""),
          dealer: get(o, "dealer", ""),
          category: get(o, "category", ""),
          target_setting: get(o, "target_setting", ""),
          packaging: get(o, "packaging", ""),
          invoiced: get(o, "invoiced", 0),
        };
      });
      //invoiced: NumberAmountValue(get(o, "invoiced", 0)),
      jsonExport(
        newList,
        {
          headers: [
            "country",
            "dealer",
            "category",
            "target_setting",
            "packaging",
            "invoiced",
          ],
        },
        (err, csv) => {
          if (err) return console.error(err);
          downloadCSV(csv, `top-20-${moment().format("DD-MMM-YYYY-HH:mm:ss")}`);
        }
      );
    }
  };

  return (
    <Box width={[1]} py={[3]}>
      <HeaderTop20
        {...props}
        setList={setList}
        valueCategory={valueCategory}
        valueCountry={valueCountry}
        valueDealer={valueDealer}
        myfilter={myfilter}
        setValueCategory={setValueCategory}
        setValueCountry={setValueCountry}
        setValueDealer={setValueDealer}
        setMyFilter={setMyFilter}
        topParams={topParams}
        setTopParams={setTopParams}
        valueTargetSetting={valueTargetSetting}
        setValueTargetSetting={setValueTargetSetting}
      />
      <ExportCSVFile
        translate={translate}
        list={list}
        onExportCsv={onExportCsv}
      />
      <ListTop20 {...props} list={list} />
    </Box>
  );
};
