import React from "react"
import { Box, Text } from "rebass"
import { get, has } from "lodash"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { withStyles } from "@material-ui/core/styles"

import {
  styles,
  StyledTableAction,
  StyledTableCell,
  StyledTableRow,
  StyledTableSmall,
  StyledTableRowLast,
} from "./styles"
import EditProductItem from "./EditProductItem"

const EditOrderItems = (props) => {
  const {
    formData,
    translate,
    classes,
    onRemoveItem,
    onEditItem,
    updateField,
  } = props
  //
  var items = get(formData, "items", [])
  if (items.length>0 && !has(items[0],"result")) {
    const details = get(formData, "items", [])
    const myItems = details.map((item) => {
      return {
        result: item,
        product_id: item.product_id,
      }
    })
    updateField("items", myItems);
    items=myItems;
  }

  let total = 0
  
  return (
    <Box width={[1]} p={[3]}>
      <Text color={"green"} pb={[3]}>
        {translate("resources.po.fields.order_items")}
      </Text>
      {items.length > 0 && (
        <TableContainer component={"div"}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  {translate("resources.po.fields.product")}
                </StyledTableCell>
                <StyledTableSmall>
                  {translate("resources.po.fields.code")}
                </StyledTableSmall>
                <StyledTableSmall>
                  {translate("resources.po.fields.qty")}
                </StyledTableSmall>
                <StyledTableSmall>
                  {translate("resources.po.fields.price")}
                </StyledTableSmall>
                <StyledTableSmall>
                  {translate("resources.po.fields.subtotal")}
                </StyledTableSmall>
                <StyledTableAction />
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => {
                total = total + item.result.subtotal * 1
                return (
                  <StyledTableRow key={index}>
                    <EditProductItem
                      {...props}
                      item={item}
                      onRemoveItem={() => onRemoveItem(item, items)}
                      onEditItem={(myItem) => onEditItem(myItem)}
                      items={items}
                    />
                  </StyledTableRow>
                )
              })}
              <StyledTableRowLast>
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell>
                  <Text fontWeight={"bold"}>
                    {translate("resources.po.fields.total")}
                  </Text>
                </StyledTableCell>
                <StyledTableCell>
                  <Text fontWeight={"bold"}>{total}</Text>
                </StyledTableCell>
                <StyledTableAction />
              </StyledTableRowLast>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  )
}

export default withStyles(styles)(EditOrderItems)
