import React from "react"
import { Flex, Box, Text } from "rebass"
import { get } from "lodash"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import IconButton from "@material-ui/core/IconButton"
import { EditIcon } from "../../../components/icon_svg"
import { withStyles } from "@material-ui/core/styles"

import {
  styles,
  StyledTableAction,
  StyledTableCell,
  StyledTableRow,
  StyledTableSmall,
  StyledTableRowLast,
} from "../styles"
const ListOrderItems = (props) => {
  const { formData, translate, classes } = props
  const items = get(formData, "items", [])
  let total = 0
  return (
    <Box width={[1]} p={[3]}>
      {items.length > 0 && (
        <TableContainer component={"div"}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  {translate("resources.po.fields.product")}
                </StyledTableCell>
                <StyledTableSmall>
                  {translate("resources.po.fields.code")}
                </StyledTableSmall>
                <StyledTableSmall>
                  {translate("resources.po.fields.qty")}
                </StyledTableSmall>
                <StyledTableSmall>
                  {translate("resources.po.fields.price")}
                </StyledTableSmall>
                <StyledTableSmall>
                  {translate("resources.po.fields.subtotal")}
                </StyledTableSmall>
                <StyledTableAction />
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item, index) => {
                total = total + item.result.subtotal
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="td" scope="row">
                      <Box>
                        <Text>{item.product.packaging}</Text>
                        <Text style={{ color: "#FF0000" }} pt={[3]}>
                          {get(
                            item,
                            "result.promotions[0].name",
                            "Buy 12 Get 1 Free"
                          )}
                        </Text>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell component="td" scope="row">
                      <Text>{item.product.code}</Text>
                    </StyledTableCell>
                    <StyledTableCell component="td" scope="row">
                      <Text>{item.result.qty}</Text>
                    </StyledTableCell>
                    <StyledTableCell component="td" scope="row">
                      <Text>{item.result.price}</Text>
                    </StyledTableCell>
                    <StyledTableCell component="td" scope="row">
                      <Text>{item.result.subtotal}</Text>
                    </StyledTableCell>
                    <StyledTableAction component="td" scope="row">
                      <Flex flexDirection={"row"} alignItems={"center"}>
                        <IconButton>
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <Box width={"10px"} />
                      </Flex>
                    </StyledTableAction>
                  </StyledTableRow>
                )
              })}
              <StyledTableRowLast>
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell>
                  <Text fontWeight={"bold"}>
                    {translate("resources.po.fields.total")}
                  </Text>
                </StyledTableCell>
                <StyledTableCell>
                  <Text fontWeight={"bold"}>{total}</Text>
                </StyledTableCell>
                <StyledTableAction />
              </StyledTableRowLast>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  )
}

export default withStyles(styles)(ListOrderItems)
