/**
 * amount: 134778.8
created_at: "2021-07-02T06:49:32.510432Z"
created_by: "SG: NK Luck (S) Pte Ltd - Jiawong"
my_reference_number: "E129/PO-001826 NKL"
purchase_order_number: "20210702-QU00"
remarks: ""
status: "Partial Received"
NumberParserPercent,
  lowerCaseComparator,
  NumberParser2DecimalsWithMinus,
  NumberParser2Decimals,
 */
import {
  DateString,
  NumberParser2DecimalsWithDollar,
} from "../../dashboard/components/reports/utils";

export const poSummarySchema = [
  {
    field: "created_at",
    headerName: "Create Date",
    minWidth: 120,
    editable: false,
    resizable: true,
    valueFormatter: DateString,
  },
  {
    field: "purchase_order_number",
    headerName: "GC Reference",
    width: 300,
    minWidth: 180,
    editable: false,
    resizable: true,
  },
  {
    field: "my_reference_number",
    headerName: "My Reference",
    width: 300,
    minWidth: 180,
    editable: false,
    resizable: true,
  },
  {
    field: "status",
    headerName: "Status",
    minWidth: 120,
    editable: false,
    resizable: true,
  },
  {
    field: "amount",
    headerName: "Amount",
    width: 100,
    editable: false,
    resizable: true,
    cellStyle: { "text-align": "right" },

    valueFormatter: NumberParser2DecimalsWithDollar,
  },
  {
    field: "created_by",
    headerName: "Created By",
    width: 300,
    minWidth: 240,
    editable: false,
    resizable: true,
  },
  {
    field: "remarks",
    headerName: "Remarks",
    width: 300,
    minWidth: 240,
    editable: false,
    resizable: true,
  },
];

/**amount: 134778.8
created_at: "2021-07-02T06:49:32.510432Z"
created_by: "SG: NK Luck (S) Pte Ltd - Jiawong"
discounted_price: 0
my_reference_number: "E129/PO-001826 NKL"
price: 0
product_code: "0135F225-0020"
product_name: "Solare X 2ml Syringe X-A2     "
purchase_order_number: "20210702-QU00"
qty: 10
remarks: ""
status: "Partial Received"
subtotal: 0 */

export const poDetailSchema = [
  {
    field: "created_at",
    headerName: "Create Date",
    minWidth: 120,
    editable: false,
    resizable: true,
    valueFormatter: DateString,
  },
  {
    field: "purchase_order_number",
    headerName: "GC Reference",
    width: 300,
    minWidth: 180,
    editable: false,
    resizable: true,
  },
  {
    field: "my_reference_number",
    headerName: "My Reference",
    width: 300,
    minWidth: 180,
    editable: false,
    resizable: true,
  },
  {
    field: "status",
    headerName: "Status",
    minWidth: 120,
    editable: false,
    resizable: true,
  },
  {
    field: "amount",
    headerName: "Amount",
    width: 100,
    editable: false,
    resizable: true,
    cellStyle: { "text-align": "right" },

    valueFormatter: NumberParser2DecimalsWithDollar,
  },
  {
    field: "created_by",
    headerName: "Created By",
    width: 300,
    minWidth: 240,
    editable: false,
    resizable: true,
  },
  {
    field: "remarks",
    headerName: "Remarks",
    width: 300,
    minWidth: 240,
    editable: false,
    resizable: true,
  },
  {
    field: "product_name",
    headerName: "Product Name",
    minWidth: 300,
    editable: false,
    resizable: true,
  },
  {
    field: "product_code",
    headerName: "Product Code",
    width: 180,
    minWidth: 130,
    editable: false,
    resizable: true,
  },
  {
    field: "price",
    headerName: "Price",
    width: 100,
    editable: false,
    resizable: true,
    cellStyle: { "text-align": "right" },
    valueFormatter: NumberParser2DecimalsWithDollar,
  },
  {
    field: "discounted_price",
    headerName: "Discounted Price",
    width: 180,
    minWidth: 150,
    editable: false,
    resizable: true,
    cellStyle: { "text-align": "right" },
    valueFormatter: NumberParser2DecimalsWithDollar,
  },
  {
    field: "qty",
    headerName: "Qty",
    width: 60,
    editable: false,
    resizable: true,
    cellStyle: { "text-align": "right" },
  },
  {
    field: "subtotal",
    headerName: "Subtotal",
    width: 100,
    editable: false,
    resizable: true,
    cellStyle: { "text-align": "right" },
    valueFormatter: NumberParser2DecimalsWithDollar,
  },
];

export const sosSchema = [
  {
    field: "created_at",
    headerName: "Create Date",
    minWidth: 120,
    editable: false,
    resizable: true,
    valueFormatter: DateString,
  },
  {
    field: "customer_reference",
    headerName: "Customer/Reference",
    width: 300,
    minWidth: 180,
    editable: false,
    resizable: true,
  },
  {
    field: "qty",
    headerName: "Qty",
    width: 60,
    editable: false,
    resizable: true,
    cellStyle: { "text-align": "right" },
  },
  {
    field: "status",
    headerName: "Status",
    minWidth: 120,
    editable: false,
    resizable: true,
  },
  {
    field: "created_by",
    headerName: "Created By",
    width: 300,
    minWidth: 240,
    editable: false,
    resizable: true,
  },
];
export const sodSchema = [
  {
    field: "created_at",
    headerName: "Create Date",
    minWidth: 120,
    editable: false,
    resizable: true,
    valueFormatter: DateString,
  },
  {
    field: "customer_reference",
    headerName: "Customer/Reference",
    width: 300,
    minWidth: 180,
    editable: false,
    resizable: true,
  },
  {
    field: "product_name",
    headerName: "Product Name",
    minWidth: 300,
    editable: false,
    resizable: true,
  },
  {
    field: "product_code",
    headerName: "Product Code",
    width: 180,
    minWidth: 130,
    editable: false,
    resizable: true,
  },
  {
    field: "status",
    headerName: "Status",
    minWidth: 120,
    editable: false,
    resizable: true,
  },
  {
    field: "qty",
    headerName: "Qty",
    width: 60,
    editable: false,
    resizable: true,
    cellStyle: { "text-align": "right" },
  },
  {
    field: "created_by",
    headerName: "Created By",
    width: 300,
    minWidth: 240,
    editable: false,
    resizable: true,
  },
];
export const isSchema = [
  {
    field: "id",
    headerName: "Batch ID",
    width: 50,
    editable: false,
    resizable: true,
    cellStyle: { "text-align": "center" },
  },
  {
    field: "created_at",
    headerName: "Upload Date",
    width: 60,
    editable: false,
    resizable: true,
    valueFormatter: DateString,
  },
  {
    field: "total_items",
    headerName: "Total Items",
    width: 60,
    editable: false,
    resizable: true,
    cellStyle: { "text-align": "center" },
  },
  {
    field: "dealer",
    headerName: "Dealer",
    minWidth: 600,
    editable: false,
    resizable: true,
  },
];
export const idSchema = [
  {
    field: "id",
    headerName: "Batch ID",
    width: 40,
    minWidth: 25,
    editable: false,
    resizable: true,
    cellStyle: { "text-align": "center" },
  },
  {
    field: "created_at",
    headerName: "Upload Date",
    minWidth: 60,
    editable: false,
    resizable: true,
    valueFormatter: DateString,
  },
  {
    field: "product_code",
    headerName: "Product Code",
    width: 180,
    minWidth: 130,
    editable: false,
    resizable: true,
  },
  {
    field: "product_name",
    headerName: "Product Name",
    minWidth: 300,
    editable: false,
    resizable: true,
  },

  {
    field: "total_items",
    headerName: "Total Items",
    width: 40,
    editable: false,
    resizable: true,
    cellStyle: { "text-align": "center" },
  },
  {
    field: "dealer",
    headerName: "Dealer",
    minWidth: 300,
    editable: false,
    resizable: true,
  },
];
/**
 * created_at: "2021-06-25T09:42:32.723571Z"
dealer: "EDI DENTAL SUPPLIES SDN BHD"
id: 25
total_items: 124
 */
/**created_at: "2021-07-21T08:05:00Z"
created_by: "SG: NK Luck (S) Pte Ltd - Jiawong"
customer_reference: "23.06.2021-21.07.2021"
product_code: "0104A233-0030"
product_name: "Caviton 30g, White"
qty: 15
status: "Sent" */
/**{
 * 
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
    field: "po",
    headerName: "PO",
    width: 100,
    editable: false,
    resizable: true,
    cellStyle: {  "text-align": "right" },

    valueFormatter: NumberParser2Decimals,
  },
  {
    field: "gap",
    headerName: "GAP",
    width: 100,
    editable: false,
    resizable: true,
    cellStyle: {  "text-align": "right" },

    valueFormatter: NumberParser2DecimalsWithMinus,
  },
  {
    field: "ratio",
    headerName: "Achievement %",
    width: 100,
    editable: false,
    resizable: true,
    valueFormatter: NumberParserPercent,
    cellStyle: { "padding-top": "7px", "text-align": "right" },

  }, */
