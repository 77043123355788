import React, { useState, Fragment } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { Flex } from "rebass";
import { useRefresh, useNotify, withDataProvider } from "react-admin";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Delete";
import ButtonInvoiceDetail from "./ButtonInvoiceDetail";
const ButtonDelete = (props) => {
  const { record, dataProvider } = props;
  console.log(props);
  const notify = useNotify();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);
  const handleDelete = () => {
    if (record && record.id) {
      dataProvider
        .delete("invoices", {
          id: record.id,
          previousData: record,
        })
        .then(({ data }) => {
          if (data) {
            notify("resources.invoiced.messages.invoice_delete", "success");
            setOpen(false);
            refresh();
          } else {
            notify("resources.invoiced.messages.try_again", "warning");
            setOpen(false);
          }
        })
        .catch((e) => {
          notify("resources.invoiced.messages.try_again", "warning");
          setOpen(false);
        });
    } else {
      notify("resources.invoiced.messages.try_again", "warning");
    }
  };

  return (
    <Fragment>
      <Flex flexDirection={"row"} justifyContent={"flex-end"}>
        <ButtonInvoiceDetail {...props} />
        <IconButton
          onClick={() => setOpen(true)}
          style={{ textAlign: "right", fontSize: 14, padding: 7 }}
        >
          <CloseIcon style={{ color: "#FF0000" }} />
        </IconButton>
      </Flex>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{"Delete Items"}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure want to delete #{record.id}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color='primary'>
            No
          </Button>
          <Button onClick={() => handleDelete()} color='primary' autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
export default withDataProvider(ButtonDelete);
