import { withStyles, createStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { NumberInput } from "react-admin";
const styles = (theme) =>
  createStyles({
    root: {
      padding: 0,
      marginBottom: 0,
      "& > li:last-child": {
        borderBottom: "none",
      },
    },
    header: {
      backgroundColor: "#efefef",
    },
    line: {
      display: "flex",
      listStyleType: "none",
      borderBottom: `solid 1px ${theme.palette.divider}`,
      [theme.breakpoints.down("xs")]: { display: "block" },
      "&.fade-enter": {
        opacity: 0.01,
        transform: "translateX(100vw)",
      },
      "&.fade-enter-active": {
        opacity: 1,
        transform: "translateX(0)",
        transition: "all 500ms ease-in",
      },
      "&.fade-exit": {
        opacity: 1,
        transform: "translateX(0)",
      },
      "&.fade-exit-active": {
        opacity: 0.01,
        transform: "translateX(100vw)",
        transition: "all 500ms ease-in",
      },
    },
    index: {
      width: "3em",
      paddingTop: "1em",
      [theme.breakpoints.down("sm")]: { display: "none" },
    },
    form: { flex: 2 },
    action: {
      paddingTop: "0.5em",
    },
    leftIcon: {
      marginRight: theme.spacing(1),
      color: "#FF0000",
    },
    leftIcon1: {
      color: "#FF0000",
    },
    table: {
      width: "100%",
    },
    container: {
      paddingBottom: 20,
      maxWidth: "100%",
    },
  });

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "transparent",
    color: theme.palette.common.black,
  },
  body: {
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 14,
  },
}))(TableCell);
const StyledTableCellRight = withStyles((theme) => ({
  head: {
    backgroundColor: "transparent",
    color: theme.palette.common.black,
    textAlign: "right",
    width: 100,
  },
  body: {
    paddingTop: 16,
    paddingBottom: 16,
    fontSize: 14,
  },
}))(TableCell);
const StyledTableSmall = withStyles((theme) => ({
  head: {
    backgroundColor: "transparent",
    color: theme.palette.common.black,
    width: 100,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableAction = withStyles((theme) => ({
  head: {
    backgroundColor: "#FFFFFF",
    color: theme.palette.common.black,
    width: 40,
    padding: 0,
  },
  body: {
    fontSize: 14,
    width: 40,
    padding: 0,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);
const StyledTableRowLast = withStyles((theme) => ({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    borderTop: "1px solid #CECECE",
    borderBottom: "1px solid #CECECE",
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);
const StyledNumberInput = withStyles((theme) => ({
  override: {
    MuiFilledInput: {
      inputMarginDense: {
        paddingTop: 0,
      },
    },
  },
}))(NumberInput);

export {
  styles,
  StyledTableAction,
  StyledTableCell,
  StyledTableRow,
  StyledTableSmall,
  StyledTableRowLast,
  StyledTableCellRight,
  StyledNumberInput,
};
