import React, { useState } from "react"
import Button from "@material-ui/core/Button"
import { Link } from "react-router-dom"
import { Box, Flex } from "rebass"
import { useTranslate, useNotify } from "react-admin"
import { Title } from "../../components"
import { ListIcon } from "../../components/icon_svg"
import ButtonUploadCsv from "./ButtonUploadCsv"

import { makeStyles } from "@material-ui/core/styles"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import ListUpload from "./ListUpload"

const dataPeriod = [
  { id: "2019-11", name: "2019-11" },
  { id: "2019-12", name: "2019-12" },
  { id: "2020-01", name: "2020-01" },
  { id: "2020-02", name: "2020-02" },
  { id: "2020-03", name: "2020-03" },
  { id: "2020-04", name: "2020-04" },
]

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const UploadOtd = (props) => {
  const translate = useTranslate()
  const notify = useNotify()
  const classes = useStyles()
  const [period, setPeriod] = useState("")
  const [data, setData] = useState([])

  const handleChange = (event) => {
    setPeriod(event.target.value)
  }

  return (
    <Box width={[1]}>
      <Flex
        width={[1]}
        justifyContent={"space-between"}
        flexDirection={"row"}
        alignItems={"center"}
        py={[3]}
      >
        <Title fontWeight={"bold"} fontSize={[3]}>
          {translate(`resources.orders.upload_otd`)}
        </Title>
        <Button
          color="primary"
          style={{ textTransform: "capitalize" }}
          to={"/orders/otd"}
          component={Link}
        >
          <ListIcon fontSize={"inherit"} style={{ paddingRight: 8 }} />
          {translate("resources.buttons.list")}
        </Button>
      </Flex>

      <Box width={[1]} pb={[3]}>
        <FormControl className={classes.formControl}>
          <InputLabel id="period-select-label">
            {translate("resources.orders.fields.period")}
          </InputLabel>
          <Select
            labelId="period-select-label"
            id="period-select"
            value={period}
            onChange={handleChange}
          >
            {dataPeriod.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <ButtonUploadCsv
        {...props}
        translate={translate}
        setData={setData}
        period={period}
        notify={notify}
      />
      <ListUpload {...props} data={data} translate={translate} />
      {/* {data.map((item, index) => {
        return (
          <Box width={[1]} key={index}>
            {item.created_at}
          </Box>
        )
      })} */}
    </Box>
  )
}

export default UploadOtd
