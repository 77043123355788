import React from "react";
import { Edit } from "react-admin";
import { ActionForm, FooterToolbar, BetterSimpleForm } from "../../components";
import { FormFields } from "./components";

const UserEdit = (props) => {
  const { hasList, hasEdit, ...rest } = props;
  return (
    <Edit
      {...rest}
      actions={<ActionForm title={"resources.users.edit_user"} />}
    >
      <BetterSimpleForm toolbar={<FooterToolbar />} isCustomRender={true}>
        <FormFields {...rest} />
      </BetterSimpleForm>
    </Edit>
  );
};

export default UserEdit;
