import React from "react";
import { get } from "lodash";
import { Flex, Box, Image, Text } from "rebass";
import { useGetMany } from "react-admin";
import LinearProgress from "@material-ui/core/LinearProgress";
import Chip from "@material-ui/core/Chip";

export const PhotoFields = ({ record, source, label }) => {
  const photos = get(record, source, []);
  return (
    <Box width={[1]}>
      <Text fontSize={[0]} fontWeight={"bold"} color={"grey"} pb={[1]}>
        {label}
      </Text>
      <Flex width={[1]} flexDirection={"row"} flexWrap={"wrap"}>
        {photos &&
          photos !== null &&
          photos.length > 0 &&
          photos.map((img, indexImg) => {
            return (
              <Box pr={[2]} key={indexImg}>
                <Image src={img} width={"100px"} height={"auto"} />
              </Box>
            );
          })}
      </Flex>
    </Box>
  );
};

export const CountryFields = ({ record, source, label }) => {
  const countries =
    get(record, source, []) !== null ? get(record, source, []) : [];
  const { data, loading, error } = useGetMany("countries", countries);
  if (loading) {
    return (
      <Flex width={[1]}>
        <LinearProgress />
      </Flex>
    );
  }
  if (error) {
    return <Flex />;
  }
  return (
    <Box width={[1]} py={[2]}>
      <Text fontSize={[0]} fontWeight={"bold"} color={"grey"} pb={[1]}>
        {label}
      </Text>
      <Flex width={[1]} flexDirection={"row"} flexWrap={"wrap"}>
        {data.map((country) => (
          <Box pr={[2]} key={country.id}>
            <Chip label={country.name} color='primary' />
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export const DealerFields = ({ record, source, label }) => {
  const dealers =
    get(record, source, []) !== null ? get(record, source, []) : [];
  const { data, loading, error } = useGetMany("dealers", dealers);
  if (loading) {
    return (
      <Flex width={[1]}>
        <LinearProgress />
      </Flex>
    );
  }
  if (error) {
    return <Flex />;
  }
  return (
    <Box width={[1]} py={[2]}>
      <Text fontSize={[0]} fontWeight={"bold"} color={"grey"} pb={[1]}>
        {label}
      </Text>
      <Flex width={[1]} flexDirection={"row"} flexWrap={"wrap"}>
        {data.map((dealer) => (
          <Box pr={[2]} key={dealer.id}>
            <Chip label={dealer.name} color='primary' />
          </Box>
        ))}
      </Flex>
    </Box>
  );
};
