import React, { Fragment, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import { Flex } from "rebass";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { get } from "lodash";
import TextField from "@material-ui/core/TextField";
import { EditIcon } from "../../components/icon_svg";
import { useQuery } from "react-query";
import { TextRecord } from "../../components";
import { getProductById } from "../../restClient";
import { useNotify } from "react-admin";
const cacheTimeMinute = 1000 * 60 * 5;
const DialogEditProductSellOut = (props) => {
  //   console.log(props);onEditItem,isEdit
  const { item, fields, index, updateField, translate } = props;
  const notify = useNotify();
  const { value } = fields;
  const myRecord = value[index];
  const [open, setOpen] = useState(false);
  const [qty, setQty] = useState(get(myRecord, "qty", 0));

  //   console.log("myRecord", myRecord);
  const productId = get(myRecord, "product_id", 0);
  const { data } = useQuery(
    `products=${productId}`,
    () => getProductById(productId),
    {
      cacheTime: cacheTimeMinute,
    }
  );
  const onChangeQty = (e) => {
    setQty(e.target.value);
  };
  const onSave = () => {
    if (qty > 0) {
      updateField(`${item}.qty`, qty * 1);
      setOpen(false);
    } else {
      notify("resources.sellout.message.more_than_zero", "warning");
    }
    // if (isEdit) {
    //   if (qty > 0) {
    //     updateField(`${item}.qty`, qty * 1);
    //     setOpen(false);
    //   } else {
    //     notify("resources.sellout.message.more_than_zero", "warning");
    //   }
    // } else {
    //   updateField(`${item}.qty`, qty * 1);
    //   setOpen(false);
    // }
  };
  return (
    <Fragment>
      <IconButton onClick={() => setOpen(true)}>
        <EditIcon fontSize='small' />
      </IconButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth={"md"}
      >
        <DialogTitle id='alert-dialog-title'>Edit Item</DialogTitle>
        <DialogContent>
          <Flex width={["500px"]} flexDirection={"column"}>
            <TextRecord
              source={"packaging"}
              label={"resources.sellout.fields.product_packaging"}
              record={data}
            />
            <Flex
              width={[1]}
              flexDirection={"row"}
              flexWrap={"wrap"}
              sx={{ "&>div": { pr: [4], pb: [2] } }}
            >
              <TextRecord
                source={"code"}
                label={"resources.sellout.fields.product_code"}
                record={data}
              />
              <TextRecord
                source={"global_code"}
                label={"resources.sellout.fields.global_code"}
                record={data}
              />
              <TextRecord
                source={"expire_date"}
                label={"resources.sellout.fields.expiry"}
                record={myRecord}
                isDate={true}
              />
            </Flex>

            <TextField
              id={"qty-edit"}
              value={qty}
              label={translate("resources.sellout.fields.qty")}
              onChange={(e) => onChangeQty(e)}
              type={"number"}
              style={{ width: 100 }}
              InputLabelProps={{ shrink: true }}
            />
          </Flex>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color='primary'>
            {translate("resources.buttons.cancel")}
          </Button>
          <Button
            onClick={() => onSave()}
            color='primary'
            variant={"contained"}
          >
            {translate("resources.buttons.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default DialogEditProductSellOut;
