import React, { Fragment } from "react";
import moment from "moment";
import { Flex, Text } from "rebass";
import DialogShowRemarks from "../../grid/DialogShowRemarks";
const Labelmoq = "MOQ";

const ContainerMod = ({ list }) => {
  return list.map((item, indexPromo) => {
    if (item.moq === 0) {
      return <Fragment />;
    }
    return (
      <Flex
        flexDirection={"row"}
        py={[1]}
        sx={{ "& > *": { pr: [2] } }}
        key={indexPromo}
      >
        <Text fontSize={[0]} style={{ color: "#00CBB4", minWidth: 70 }}>
          {Labelmoq} {item.moq}
        </Text>
        <Text
          fontSize={[0]}
          style={{ width: 50 }}
        >{`${item.buy} + ${item.get}`}</Text>
        {item.additional !== 0 && (
          <Text fontSize={[0]} fontWeight={"bold"} color={"red"}>
            +{item.additional}
          </Text>
        )}
      </Flex>
    );
  });
};
const ContainerModDisc = ({ list }) => {
  return list.map((item, indexDisc) => {
    const { moq, discount } = item;

    const myDiscount =
      discount !== "" ? `${discount < 2 ? discount * 100 : discount}%` : "";
    return (
      <Flex
        flexDirection={"row"}
        py={[1]}
        sx={{ "& > *": { pr: [2] } }}
        key={indexDisc}
      >
        <Text fontSize={[0]} style={{ color: "#00CBB4", minWidth: 70 }}>
          {Labelmoq} {moq}
        </Text>
        <Text fontSize={[0]} style={{ width: 50 }}>
          {myDiscount}
        </Text>
      </Flex>
    );
  });
};
const ContainerModPrice = ({ list }) => {
  return list.map((item, indexPrice) => {
    const { moq, price } = item;
    return (
      <Flex
        flexDirection={"row"}
        py={[1]}
        sx={{ "& > *": { pr: [2] } }}
        key={indexPrice}
      >
        <Text fontSize={[0]} style={{ color: "#00CBB4", minWidth: 70 }}>
          {Labelmoq} {moq}
        </Text>
        <Text fontSize={[0]} style={{ width: 50 }}>{`${price}`}</Text>
      </Flex>
    );
  });
};

const BoxStartEndDate = ({ item, translate }) => {
  const start = moment(item.start_date).format("DD-MMM-YYYY");
  const end = moment(item.end_date).format("DD-MMM-YYYY");

  return (
    <Flex
      flexDirection={"column"}
      alignItems={"flex-start"}
      width={[1]}
      p={[2]}
      sx={{
        "&>div": {
          pt: [2],
        },
      }}
    >
      <Text fontSize={[0]}>
        {translate("resources.po.listgrid.label.valid_from")} {start}{" "}
        {translate("resources.po.listgrid.label.to")} {end}
      </Text>
      <DialogShowRemarks item={item} />
    </Flex>
  );
};

export { ContainerMod, ContainerModDisc, ContainerModPrice, BoxStartEndDate };
