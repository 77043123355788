import { withStyles, createStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import { grey } from "@material-ui/core/colors"
const styles = (theme) =>
  createStyles({
    root: {
      padding: 0,
      marginBottom: 0,
      "& > li:last-child": {
        borderBottom: "none",
      },
    },
    line: {
      display: "flex",
      listStyleType: "none",
      borderBottom: `solid 1px ${theme.palette.divider}`,
      [theme.breakpoints.down("xs")]: { display: "block" },
      "&.fade-enter": {
        opacity: 0.01,
        transform: "translateX(100vw)",
      },
      "&.fade-enter-active": {
        opacity: 1,
        transform: "translateX(0)",
        transition: "all 500ms ease-in",
      },
      "&.fade-exit": {
        opacity: 1,
        transform: "translateX(0)",
      },
      "&.fade-exit-active": {
        opacity: 0.01,
        transform: "translateX(100vw)",
        transition: "all 500ms ease-in",
      },
    },
    index: {
      width: "3em",
      paddingTop: "1em",
      [theme.breakpoints.down("sm")]: { display: "none" },
    },
    form: { flex: 2 },
    action: {
      paddingTop: "0.5em",
    },
    leftIcon: {
      marginRight: theme.spacing(1),
      color: "#FF0000",
    },
  })

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: grey[300],
    color: grey[700],
    textAlign: "center",
    width: "50%",
  },
  body: {
    fontSize: 14,
    width: "50%",
  },
}))(TableCell)
const StyledTableSmall = withStyles((theme) => ({
  head: {
    backgroundColor: grey[300],
    color: grey[700],
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)
const StyledTableAction = withStyles((theme) => ({
  head: {
    backgroundColor: grey[300],
    color: grey[700],
    width: 70,
    padding: 0,
  },
  body: {
    fontSize: 14,
    width: 70,
    padding: 0,
  },
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(231,231,231,0.6)",
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(231,231,231,0.2)",
    },
  },
}))(TableRow)

const StyledTableRowLast = withStyles((theme) => ({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    borderTop: "1px solid #CECECE",
    borderBottom: "1px solid #CECECE",
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow)
export {
  styles,
  StyledTableAction,
  StyledTableCell,
  StyledTableRow,
  StyledTableSmall,
  StyledTableRowLast,
}
