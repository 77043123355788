import Moment from "moment";
import { groupBy } from "lodash";
import { extendMoment } from "moment-range";
import { lowerCaseComparator } from "../utils";
const moment = extendMoment(Moment);

export const listColumnItem = [
  {
    field: "country",
    headerName: "Country",
    minWidth: 130,
    editable: false,
    resizable: true,
  },
  {
    field: "dealer",
    headerName: "Dealer",
    minWidth: 330,
    editable: false,
    resizable: true,
  },
  {
    field: "target_setting",
    headerName: "Target Setting",
    minWidth: 200,
    editable: false,
    resizable: true,
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },
];
export const listColumnItemDealer = [
  {
    field: "target_setting",
    headerName: "Target Setting",
    minWidth: 200,
    editable: false,
    resizable: true,
  },
];
export const columns = [
  {
    field: "country",
    headerName: "Country",
    minWidth: 130,
    editable: false,
    resizable: true,
  },
  {
    field: "dealer",
    headerName: "Dealer",
    minWidth: 350,
    editable: false,
    resizable: true,
  },
  {
    field: "target_setting",
    headerName: "Target Setting",
    minWidth: 200,
    editable: false,
    resizable: true,
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },
];
export const columnsDealers = [
  {
    field: "target_setting",
    headerName: "Target Setting",
    minWidth: 200,
    editable: false,
    resizable: true,
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },
];
export const sortModelDealer = [
  {
    field: "target_setting",
    comparator: lowerCaseComparator,
    sort: "asc",
  },
];
export const sortModel = [
  {
    field: "country",
    sort: "asc",
  },
  {
    field: "dealer",
    sort: "asc",
  },
  {
    field: "target_setting",
    comparator: lowerCaseComparator,
    sort: "asc",
  },
];

export const getListGL = (data, dealerId) => {
  const list = groupBy(data, function (o) {
    return [o.target_setting, o.dealer, o.country];
  });
  // console.log(list);
  const toArray = Object.values(list);
  const newlist = toArray.map((item) => {
    let newItems = {};
    const monthDate = item.map((io) => {
      const test = moment(io.month);
      newItems[`amount-${test.format("MMM-YY")}`] = `${io.amount} - ${io.qty}`;
      newItems[
        `amount_gap-${test.format("MMM-YY")}`
      ] = `${io.amount_gap} - ${io.qty_gap}`;
      // newItems[`qty-${test.format("MMM-YY")}`] = io.qty;
      // newItems[`qty_gap-${test.format("MMM-YY")}`] = io.qty_gap;
      if (dealerId && dealerId !== null) {
      } else {
        newItems[`country`] = io.country;
        newItems[`dealer`] = io.dealer;
      }

      newItems[`target_setting`] = io.target_setting;
      return {
        month: io.month,
        amount: io.amount,
        qty: io.qty,
        amount_gap: io.amount_gap,
        qty_gap: io.qty_gap,
      };
    });
    return { myMonth: monthDate, ...newItems };
  });
  return newlist;
};

export { default as ListGrowthLoss } from "./ListGrowthLoss";
export { default as HeaderGrowthLoss } from "./HeaderGrowthLoss";
