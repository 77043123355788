import React, { useState } from "react";
import { find } from "lodash";
import { Flex, Box } from "rebass";
import TextField from "@material-ui/core/TextField";
import { Skeleton } from "antd";
import { withDataProvider } from "react-admin";
import { PanelCardView, useQuery } from "../../../components";
import { isDealers } from "../../../utils";
//showAdmin,
const MainField = (props) => {
  const { translate, list, dataProvider } = props;
  const reminderDefault1 = find(list, function (o1) {
    return o1.key === "promotion_expiry_reminder_1";
  });
  const reminderDefault2 = find(list, function (o1) {
    return o1.key === "promotion_expiry_reminder_2";
  });
  const reminderDefault3 = find(list, function (o1) {
    return o1.key === "sop_prefix";
  });
  const reminderDefault4 = find(list, function (o1) {
    return o1.key === "sop_running_number";
  });
  const [reminder1, setReminder1] = useState(
    reminderDefault1
      ? reminderDefault1
      : { key: "promotion_expiry_reminder_1", value: "" }
  );
  const [reminder2, setReminder2] = useState(
    reminderDefault2
      ? reminderDefault2
      : { key: "promotion_expiry_reminder_2", value: "" }
  );
  const [reminder3, setReminder3] = useState(
    reminderDefault3 ? reminderDefault3 : { key: "sop_prefix", value: "" }
  );
  const [reminder4, setReminder4] = useState(
    reminderDefault4
      ? reminderDefault4
      : { key: "sop_running_number", value: "" }
  );

  const onChangeReminder1 = (e) => {
    setReminder1({
      ...reminder1,
      value: e.target.value,
    });
  };
  const onChangeReminder2 = (e) => {
    setReminder2({
      ...reminder2,
      value: e.target.value,
    });
  };
  const onBlurReminder1 = (e) => {
    if (
      reminderDefault1 &&
      reminderDefault1.id &&
      reminderDefault1.key &&
      reminderDefault1.key === "promotion_expiry_reminder_1"
    ) {
      onUpdate(reminderDefault1.id, reminder1, reminderDefault1);
    } else {
      //create
      if (e.target.value !== "") {
        onCreate({ key: "promotion_expiry_reminder_1", value: e.target.value });
      }
    }
  };

  const onBlurReminder2 = (e) => {
    if (
      reminderDefault2 &&
      reminderDefault2.id &&
      reminderDefault2.key &&
      reminderDefault2.key === "promotion_expiry_reminder_2"
    ) {
      onUpdate(reminderDefault2.id, reminder2, reminderDefault2);
    } else {
      //create
      if (e.target.value !== "") {
        onCreate({ key: "promotion_expiry_reminder_2", value: e.target.value });
      }
    }
  };
  const onChangeReminder3 = (e) => {
    setReminder3({
      ...reminder3,
      value: e.target.value,
    });
  };
  const onChangeReminder4 = (e) => {
    setReminder4({
      ...reminder4,
      value: e.target.value,
    });
  };
  const onBlurReminder3 = (e) => {
    if (
      reminderDefault3 &&
      reminderDefault3.id &&
      reminderDefault3.key &&
      reminderDefault3.key === "sop_prefix"
    ) {
      onUpdate(reminderDefault3.id, reminder3, reminderDefault3);
    } else {
      //create
      if (e.target.value !== "") {
        onCreate({ key: "sop_prefix", value: e.target.value });
      }
    }
  };

  const onBlurReminder4 = (e) => {
    if (
      reminderDefault4 &&
      reminderDefault4.id &&
      reminderDefault4.key &&
      reminderDefault4.key === "sop_running_number"
    ) {
      onUpdate(reminderDefault4.id, reminder4, reminderDefault4);
    } else {
      //create
      if (e.target.value !== "") {
        onCreate({ key: "sop_running_number", value: e.target.value });
      }
    }
  };

  const onCreate = (values) => {
    dataProvider.create("settings", { data: values });
  };

  const onUpdate = (id, values, previousData) => {
    dataProvider.update("settings", {
      id: id,
      data: values,
      previousData: previousData,
    });
  };

  return (
    <Flex width={[1]} flexDirection={"column"} px={[2]}>
      <PanelCardView
        title={translate("resources.settings.labels.reminder")}
        elevation={1}
      >
        <Flex width={[1]} flexDirection={"column"} style={{ minHeight: 200 }}>
          <Flex width={[1]} flexDirection={"row"} p={[3]}>
            <Box pr={[3]}>
              <TextField
                id='promotion_expiry_reminder_1'
                style={{ width: 400 }}
                InputLabelProps={{ shrink: true }}
                value={reminder1.value}
                label={`${translate(
                  "resources.settings.fields.others.promotion_expiry_reminder"
                )} 1 (${translate("resources.settings.fields.others.day")})`}
                onChange={(e) => onChangeReminder1(e)}
                onBlur={(e) => onBlurReminder1(e)}
              />
            </Box>
            <Box>
              <TextField
                id='promotion_expiry_reminder_2'
                style={{ width: 400 }}
                InputLabelProps={{ shrink: true }}
                value={reminder2.value}
                label={`${translate(
                  "resources.settings.fields.others.promotion_expiry_reminder"
                )} 2 (${translate("resources.settings.fields.others.days")})`}
                onChange={(e) => onChangeReminder2(e)}
                onBlur={(e) => onBlurReminder2(e)}
              />
            </Box>
          </Flex>
          <Flex width={[1]} flexDirection={"row"} p={[3]}>
            <Box pr={[3]}>
              <TextField
                id='sop_prefix'
                style={{ width: 400 }}
                InputLabelProps={{ shrink: true }}
                value={reminder3.value}
                label={`${translate(
                  "resources.settings.fields.others.sop_prefix"
                )}`}
                onChange={(e) => onChangeReminder3(e)}
                onBlur={(e) => onBlurReminder3(e)}
              />
            </Box>
            <Box>
              <TextField
                id='sop_running_number'
                style={{ width: 400 }}
                InputLabelProps={{ shrink: true }}
                value={reminder4.value}
                label={`${translate(
                  "resources.settings.fields.others.sop_running_number"
                )}`}
                onChange={(e) => onChangeReminder4(e)}
                onBlur={(e) => onBlurReminder4(e)}
              />
            </Box>
          </Flex>
        </Flex>
      </PanelCardView>
    </Flex>
  );
};

const OtherFields = (props) => {
  const { translate, permissions } = props;
  const { data, loading } = useQuery(`/settings`);

  if (loading) {
    return (
      <Flex width={[1]} flexDirection={"column"} px={[2]}>
        <PanelCardView
          title={translate("resources.settings.labels.reminder")}
          elevation={1}
        >
          <Flex width={[1]} flexDirection={"column"} style={{ minHeight: 200 }}>
            <Flex width={[1]} flexDirection={"row"} p={[3]}>
              <Box width={1 / 2} pr={[3]}>
                <Skeleton />
              </Box>
              <Box width={1 / 2}>
                <Skeleton />
              </Box>
            </Flex>
          </Flex>
        </PanelCardView>
      </Flex>
    );
  }
  return !isDealers(permissions) ? (
    <MainField {...props} list={data} />
  ) : (
    <Flex width={[1]} flexDirection={"column"} px={[2]}>
      <PanelCardView
        title={translate("resources.settings.labels.reminder")}
        elevation={1}
      >
        <Flex width={[1]} flexDirection={"column"} style={{ minHeight: 200 }} />
      </PanelCardView>
    </Flex>
  );
};

export default withDataProvider(OtherFields);
