import React from "react";
import { filter, orderBy } from "lodash";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const ProductGroupFilter = (props) => {
  const { handleChange, value, translate, listProductGroup, category, state } =
    props;
  const myFilter =
    category !== ""
      ? filter(state.oldrowData, function (o) {
          return o.category_id === category;
        })
      : [];
  let listGroup = listProductGroup;
  if (myFilter.length > 0) {
    const newlistDealers = filter(listProductGroup, function (i) {
      return myFilter.some(function (f) {
        return i.id === f.group_id;
      });
    });
    listGroup = newlistDealers;
  }
  const sortListProductGroup = orderBy(
    listGroup,
    [(product) => product.label.toLowerCase()],
    ["asc"]
  );
  return (
    <FormControl>
      <InputLabel style={{ paddingLeft: 20 }} id='select-label-group'>
        {translate("resources.po.listgrid.label.group")}
      </InputLabel>
      <Select
        labelId='select-label-group'
        id='select-group'
        value={value}
        onChange={(e) => handleChange("group", e.target.value)}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        style={{ minWidth: 200 }}
      >
        <MenuItem value=''>
          <em style={{ height: 20 }} />
        </MenuItem>
        {sortListProductGroup &&
          sortListProductGroup.length > 0 &&
          sortListProductGroup.map((item, index) => (
            <MenuItem value={item.id} key={index}>
              {item.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default ProductGroupFilter;
