import React, { useState } from "react";

import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box, Flex, Text } from "rebass";
import { UploadClientCSV } from "../../restClient";
const ButtonUploadCsv = (props) => {
  const {
    accept,
    disableClick,
    maxSize,
    minSize,
    multiple,
    translate,
    period,
    notify,
    handleSubmit,
    urlPath,
    dealer_id,
  } = props;

  const [loading, setLoading] = useState(false);
  const onDrop = (accepted, rejected) => {
    if (accepted.length > 0 && rejected.length === 0) {
      if (period !== "" && dealer_id !== "") {
        console.log(period, accepted);
        let formData = new FormData();
        accepted.forEach((file) => {
          console.log(file);
          formData.append("file", file);
        });
        formData.append("financial_year", period);
        formData.append("dealer_id", dealer_id);
        // console.log(urlPath, formData);
        setLoading(true);
        UploadClientCSV(formData, urlPath)
          .then((response) => response.json())
          .then((data) => {
            // console.log(data);
            if (data && data.length > 0) {
              // this.onAdd(data.url);
              handleSubmit(data);
            } else {
              notify("resources.targets.message.success", "success");
              handleSubmit([]);
            }
            /*notify(
                translate("resources.targets.message.max_size"),
                "warning"
              );*/
            setLoading(false);
          })
          .catch((e) => {
            notify("resources.targets.message.message_failed", "warning");
            setLoading(false);
          });
      } else {
        notify("resources.targets.message.please_select_FY", "warning");
      }
    }
  };
  return (
    <Box p={[2]}>
      <Dropzone
        onDrop={onDrop}
        accept={accept}
        disableClick={disableClick}
        maxSize={maxSize}
        minSize={minSize}
        multiple={multiple}
      >
        {({ getRootProps, getInputProps }) => (
          <div>
            <div {...getRootProps()}>
              {!disableClick && <input {...getInputProps()} />}

              <Button
                variant={"contained"}
                color='primary'
                disabled={disableClick}
                style={{
                  textTransform: "capitalize",
                  width: 200,
                  borderRadius: 24,
                }}
              >
                {translate("resources.buttons.upload_csv")}
              </Button>
              {loading && (
                <Flex flexDirection={"row"} alignItems='center' p={[2]}>
                  <CircularProgress /> <Text px={[2]}>Loading ...</Text>
                </Flex>
              )}
            </div>
          </div>
        )}
      </Dropzone>
    </Box>
  );
};

ButtonUploadCsv.propTypes = {
  accept: PropTypes.string,
  children: PropTypes.element,
  disableClick: PropTypes.bool,
  elStyle: PropTypes.object,
  input: PropTypes.object,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  multiple: PropTypes.bool,
  style: PropTypes.object,
  placeholder: PropTypes.node,
  updateField: PropTypes.func,
};

ButtonUploadCsv.defaultProps = {
  addLabel: false,
  addField: true,
  multiple: false,
  maxSize: 1000000,
  accept: ".csv, application/vnd.ms-excel, text/csv",
  onUpload: () => {},
};

export default ButtonUploadCsv;
