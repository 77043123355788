import React from "react";
import { Create } from "react-admin";
import { ActionForm, FooterToolbar, BetterSimpleForm } from "../../components";

import { FormFields } from "./components";
const UserCreate = (props) => {
  const { hasList, hasCreate, ...rest } = props;
  return (
    <Create
      {...rest}
      actions={<ActionForm title={"resources.users.new_user"} />}
    >
      <BetterSimpleForm
        {...rest}
        toolbar={<FooterToolbar />}
        isCustomRender={true}
        initialValues={{ active: true }}
      >
        <FormFields {...rest} />
      </BetterSimpleForm>
    </Create>
  );
};

export default UserCreate;
