import * as React from "react";
import { Fragment, useCallback, useEffect, useState } from "react";
import moment from "moment";
import { get, filter, map, orderBy, toLower, keyBy } from "lodash";
import { useQuery } from "react-query";
import { Flex, Box } from "rebass";
import {
  Datagrid,
  Filter,
  ListContextProvider,
  ReferenceInput,
  SearchInput,
  TextField,
  SelectInput,
  useGetList,
  useListContext,
  useTranslate,
  List,
  Pagination as NewPagination,
  usePermissions,
  ReferenceField,
} from "react-admin";
import { getUser, showCountryManager } from "../utils";
import { Title } from "../components";
import { ButtonShowUrlEditView } from "./components/ButtonShowUrlEditView";
import { Divider, Tabs, Tab } from "@material-ui/core";
import { getOrderListParams } from "../restClient";
import { Pagination } from "../components";
import { useLocation } from "react-router-dom";
import NumberFormat from "react-number-format";
import numeral from "numeral";
import { STATUSPO } from "../utils";
const AmountField = ({ record, source }) => {
  const total = get(record, source, 0);
  const roundUpTotal = numeral(total).format("0,0.00");
  return (
    <div style={{ textAlign: "right", width: 100 }}>
      <NumberFormat
        value={roundUpTotal}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$ "}
        decimalScale={2}
      />
    </div>
  );
};

const DealerName = (props) => {
  const { record } = props;
  if (
    get(record, "dealer_name", "") !== null &&
    get(record, "dealer_name", "") !== ""
  ) {
    return <span>{get(record, "dealer_name", "")}</span>;
  }

  return (
    <ReferenceField
      label='resources.orders.fields.dealer'
      source='dealer_id'
      reference='dealers'
      {...props}
      link={false}
    >
      <TextField source='name' />
    </ReferenceField>
  );
};

const showingOthers = (value, status) => {
  if (status) {
    // return status !== "Sent" && status !== "Draft";
    return status !== STATUSPO.sent && status !== STATUSPO.draft;
  } else {
    return value !== "";
  }
};

function objectToQueryString(obj) {
  let str = [];
  for (let p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}
const cacheTimeMinute = 1000 * 60 * 2;
const POFilter = (props) => {
  const { permissions, countryId, currentSort, ...rest } = props;
  const translate = useTranslate();
  const countryManagerFilter = showCountryManager(permissions)
    ? { country_id: countryId }
    : {};

  return (
    <Box width={[1]} pb={[2]}>
      <Box width={[1]} pt={[2]}>
        <Title fontWeight={"bold"} fontSize={[3]}>
          {translate(`resources.po.title`)}
        </Title>
      </Box>
      <Flex width={[1]} flexDirection={"row"} justifyContent={"flex-end"}>
        <Filter {...rest}>
          <ReferenceInput
            label='resources.orders.fields.dealer'
            source='dealer'
            reference='dealers'
            alwaysOn
            filter={countryManagerFilter}
            // filterToQuery={(searchText) => ({ name: searchText })}
            sort={{ field: "name", order: "ASC" }}
            style={{ width: 400 }}
            perPage={100}
          >
            <SelectInput optionText='name' />
          </ReferenceInput>
          <SearchInput source='search' alwaysOn style={{ width: 256 }} />
        </Filter>
      </Flex>
    </Box>
  );
};

const tabs = [
  { id: STATUSPO.sent, name: STATUSPO.sent },
  { id: "Others", name: "Others" },
];

const useGetTotals = (filterValues) => {
  const { total: totalSent } = useGetList(
    "orders",
    { perPage: 1, page: 1 },
    { field: "created_at", order: "DESC" },
    { ...filterValues, status: STATUSPO.sent }
  );
  const myFilter = objectToQueryString(filterValues);
  // console.log(totalSent);
  const { data: dataPosted1 } = useQuery(
    `purchaseOrders=${myFilter}`,
    () => getOrderListParams(myFilter),
    {
      cacheTime: cacheTimeMinute,
    }
  );

  const myOthers = filter(dataPosted1, function (o) {
    return o.status !== STATUSPO.sent && o.status !== STATUSPO.draft;
  });

  return {
    Sent: totalSent,
    Others: myOthers.length,
  };
};
const TabbedDatagrid = (props) => {
  const { hasBulkActions, ...rest } = props;
  // const {
  //   currentSort,
  //   exporter,
  //   hasBulkActions,
  //   totalPages,
  //   showFilter,
  //   setSort,
  //   setPerPage,
  //   setPage,
  //   defaultTitle,
  //   selectedIds,
  //   perPage,
  //   onUnselectItems,
  //   onToggleItem,
  //   loaded,
  //   ...rest
  // } = props;
  const { permissions } = usePermissions();
  const listContext = useListContext();
  const { ids, filterValues, setFilters, page, perPage } = listContext;
  const [sent, setSent] = useState([]);
  const matchParams = useLocation();
  const [posted, setPosted] = useState([]);
  const totals = useGetTotals(filterValues);

  const myFilter = objectToQueryString(filterValues);
  // console.log(totalSent);
  const { data: dataPosted } = useQuery(
    `purchaseOrders=${myFilter}`,
    () => getOrderListParams(myFilter),
    {
      cacheTime: cacheTimeMinute,
    }
  );
  useEffect(() => {
    if (dataPosted && ids && ids !== filterValues.status) {
      switch (filterValues.status) {
        case STATUSPO.sent:
          setSent(ids);
          break;
        // case "Others":
        //   setPosted(ids);
        //   break;
        default:
          if (showingOthers(matchParams.search, filterValues.status)) {
            const myOthers = filter(dataPosted, function (o) {
              return o.status !== STATUSPO.sent && o.status !== STATUSPO.draft;
            });
            const myPosted = map(myOthers, function (oo) {
              return oo.id;
            });
            setPosted(myPosted);
          } else {
            // setSent(ids);
          }

          break;
      }
    }
  }, [ids, dataPosted, filterValues.status, matchParams]);

  const handleChange = useCallback(
    (event, value) => {
      //"Sent"
      const myValues =
        value === STATUSPO.sent ? { status: value } : { status: undefined };
      setFilters && setFilters({ ...filterValues, ...myValues });
    },
    [filterValues, setFilters]
  );

  const myListPosted = filter(dataPosted, function (ooo) {
    return ooo.status !== STATUSPO.sent && ooo.status !== STATUSPO.draft;
  });

  const myOrder = orderBy(
    myListPosted,
    "created_at",
    toLower(get(listContext, "currentSort.order", "asc"))
  );

  let newMyOrder = myOrder;
  if (showingOthers(matchParams.search, filterValues.status)) {
    // console.log(myListPosted);
    const indexSlice = page - 1;
    newMyOrder = myOrder.slice(
      indexSlice <= 0 ? 0 : indexSlice * perPage,
      page * perPage
    );
  }
  return (
    <Fragment>
      <Tabs
        variant='fullWidth'
        centered
        // value={filterValues.status === "Sent" ? filterValues.status : "Others"}
        value={
          showingOthers(matchParams.search, filterValues.status)
            ? "Others"
            : STATUSPO.sent
        }
        indicatorColor='primary'
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab
            key={choice.id}
            label={
              totals[choice.name]
                ? `${choice.name} (${totals[choice.name]})`
                : choice.name
            }
            value={choice.id}
          />
        ))}
      </Tabs>
      <Divider />
      <div>
        {showingOthers(matchParams.search, filterValues.status) === false && (
          <ListContextProvider value={{ ...listContext, ids: sent }}>
            <Datagrid {...props}>
              <DateCustomField
                source='created_at'
                label='resources.po.fields.created_on'
                format={"DD-MMM-YYYY"}
              />
              <DealerName source='dealer_id' />
              <TextField
                source='purchase_order_number'
                label={"resources.po.fields.gc_ref"}
              />
              <TextField
                source='my_reference_number'
                label={"resources.po.fields.my_ref"}
              />
              <TextField source='status' label={"resources.po.fields.status"} />
              <AmountField
                source='total'
                label={"resources.po.fields.amount"}
              />

              <ButtonShowUrlEditView
                basePath={props.basePath}
                record={props.record}
                permissions={permissions}
              />
            </Datagrid>
            <NewPagination {...rest} />
          </ListContextProvider>
        )}
        {showingOthers(matchParams.search, filterValues.status) &&
          myListPosted && (
            <ListContextProvider
              value={{
                ...listContext,
                data: keyBy(newMyOrder, "id"),
                ids: newMyOrder.map(({ id }) => id),
                total: myOrder.length,
              }}
            >
              <Datagrid {...props}>
                <DateCustomField
                  source='created_at'
                  label='resources.po.fields.created_on'
                  format={"DD-MMM-YYYY"}
                />
                <DealerName source='dealer_id' />
                <TextField
                  source='purchase_order_number'
                  label={"resources.po.fields.gc_ref"}
                />
                <TextField
                  source='my_reference_number'
                  label={"resources.po.fields.my_ref"}
                />
                <TextField
                  source='status'
                  label={"resources.po.fields.status"}
                />
                <AmountField
                  source='total'
                  label={"resources.po.fields.amount"}
                />

                <ButtonShowUrlEditView
                  basePath={props.basePath}
                  record={props.record}
                  permissions={permissions}
                />
              </Datagrid>
              <Pagination {...rest} total={posted.length} />
            </ListContextProvider>
          )}
      </div>
    </Fragment>
  );
};
const POAction = ({ basePath }) => {
  return (
    <Flex
      pt={[2]}
      pl={[2]}
      pr={[2]}
      flexDirection={"row"}
      style={{ position: "fixed", right: 16 }}
    >
      <Flex flexDirection={"row"} justifyContent={"flex-end"}>
        {/* <CreatePoButton
            basePath={"/orders"}
            sizeIcon={"xsmall"}
            label={"resources.buttons.create_po"}
            style={{ textTransform: "capitalize" }}
          /> */}
      </Flex>
    </Flex>
  );
};

const DateCustomField = ({ record, source, format = "DD-MMMM-YYYY" }) => {
  const mydate = get(record, source, "");
  if (mydate === "") {
    return <span />;
  }
  return <span>{moment(mydate).format(format)}</span>;
};

const TabPOList = (props) => {
  const { permissions } = props;
  const user = getUser();
  const countryId = get(user, "country_id");
  // const dealerID = get(user, "dealer_id");
  // const dealerFilter =
  //   showDealer(permissions) && dealerID && dealerID !== "0"
  //     ? { dealer: dealerID }
  //     : {};
  const countryManagerFilter = showCountryManager(permissions)
    ? { country: countryId }
    : {};

  return (
    <List
      {...props}
      bulkActionButtons={false}
      component={"div"}
      actions={<POAction />}
      filterDefaultValues={{
        ...countryManagerFilter,
        status: STATUSPO.sent,
      }}
      sort={{ field: "created_at", order: "DESC" }}
      perPage={25}
      empty={false}
      filters={<POFilter permissions={permissions} countryId={countryId} />}
      pagination={false}
    >
      <TabbedDatagrid />
    </List>
  );
};

export default TabPOList;

// interface TabbedDatagridProps extends DatagridProps {}
