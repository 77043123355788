import { sumBy, get, has } from "lodash";

export const getHeaderTotal = (record) => {
  const items = get(record, "items", []);
  const ordered = sumBy(items, function (o) {
    return o.qty * 1;
  });
  const received = sumBy(items, function (o) {
    return o.received * 1;
  });
  // const backorder = sumBy(items, function (o) {
  //   return o.back_order * 1;
  // });
  // const mytotal = sumBy(items, function (o) {
  //   return o.subtotal * 1;
  // });
  // const totalBackorder = parseInt(get(record, "total_back_order", 0));
  const totalReceived = sumBy(record.items, function (o) {
    //o.subtotal*1
    const myPrice = o.price * 1;
    const myDiscountedPrice = o.discounted_price * 1;
    const myReceived = o.received * 1;
    return has(o, "discounted_price")
      ? myDiscountedPrice * myReceived
      : myPrice * myReceived;
  });
  const myTotal = get(record, "total", 0);
  const totalBalance = ordered - received;
  const totalBalanaceAmount = myTotal - totalReceived;
  return {
    ordered: ordered,
    received: received,
    backorder: totalBalance,
    totalBackorder: totalBalanaceAmount,
    totalReceived: totalReceived,
  };
};
