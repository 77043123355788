import React, { Fragment, useState, useEffect } from "react";
//useRef,
import {
  find,
  filter,
  flattenDepth,
  uniqBy,
  get,
  sumBy,
  sortBy,
  orderBy,
  map,
  toLower,
} from "lodash";
import { Box } from "rebass";
import { AgGridReact } from "ag-grid-react";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "./Expand";
import { getProductsWithId, getAllProductsWithId } from "../../restClient";
import { defaultFreeColumnDefs } from "./defaultValue";
import {
  getPromoTypeFreeABCD,
  getTotalSelectedQtyFreeA,
  checkPromotionGETCD,
  getRegisterNumber,
} from "./utils";
import { getTotalSelected, getTotalFree } from "./utilsABCD";
import HeaderFreeABCD from "./HeaderFreeABCD";

export default (props) => {
  const {
    translate,
    promoType,
    item,
    tempList,
    setTempList,
    setShow,
    setMessageError,
    tempListBuy,
    tempListFree,
    setTempListFree,
    countryId,
    // isOAAsossiate,
    country,
  } = props;
  // console.log("tempListFree", tempListFree);
  // console.log("tempListBuy", tempListBuy);
  const countryCode = toLower(get(country, "code", ""));
  const [isRequest, setIsRequest] = useState(true);
  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [state, setState] = useState({
    search: "",
    message: "",
    show: false,
    columnDefs: defaultFreeColumnDefs(false),
    defaultColDef: {
      flex: 1,
      editable: true,
      sortable: true,
      filter: true,
      cellStyle: {
        "line-height": "40px",
      },
    },
    getRowNodeId: function (data) {
      return data.code;
    },
    listPromo: [],
    item: item,
    expanded: "panelGridFreePromoABCD",
  });
  const myURl = getPromoTypeFreeABCD(promoType, item);
  // console.log(item);
  // console.log(myURl);
  useEffect(() => {
    if (isRequest) {
      if (Array.isArray(myURl)) {
        getAllProductsWithId(myURl)
          .then((res) => {
            const result = flattenDepth(res, 1);
            const dataProduct = filter(result, function (o) {
              return o.active;
            });
            const response = uniqBy(dataProduct, "id");

            if (response && response.length > 0) {
              const myLatest = response.map((itemRes) => {
                const value = checkPromotionGETCD(itemRes, item);
                let newItemRes = itemRes;
                const objUrl = find(tempList, function (o) {
                  return o.product_id === itemRes.id && o.foc;
                });
                if (objUrl && objUrl.product_id) {
                  newItemRes["qty"] = objUrl.qty;
                  newItemRes["subtotal"] = 0;
                } else {
                  newItemRes["qty"] = 0;
                  newItemRes["subtotal"] = 0;
                }
                newItemRes["cr"] = get(itemRes, "cr", false);
                // get(itemRes, "cr", false) === true ? "CR" : "";
                newItemRes["dg"] = get(itemRes, "dg", false);
                // get(itemRes, "dg", false) === true ? "DG" : "";
                newItemRes["foc"] = true;
                newItemRes["growth"] = get(itemRes, `class4.${countryId}`, "");
                newItemRes["promo"] = value;
                newItemRes["target_id"] = get(itemRes, "target_id", 0);
                newItemRes["registration_number"] = getRegisterNumber(
                  itemRes,
                  countryCode
                );
                // get(
                //   itemRes,
                //   `registration_number[${countryCode}]`,
                //   ""
                // );
                return newItemRes;
              });
              // setRowData(myLatest);

              const newFilter = filter(myLatest, function (oo) {
                return oo.promo !== 0;
              });

              const sortNewFilter = sortBy(newFilter, function (oio) {
                return oio.promo;
              });

              setRowData(sortNewFilter);
              const myTempBuyFree = filter(myLatest, function (oo) {
                return oo.foc && oo.qty > 0;
              });
              setTempListFree(myTempBuyFree);
            }
            setIsRequest(false);
          })
          .catch((e) => {
            setIsRequest(false);
          });
      } else {
        if (myURl !== "") {
          getProductsWithId(myURl)
            .then((response) => {
              if (response && response.length > 0) {
                const dataProduct = filter(response, function (o) {
                  return o.active;
                });
                const responseData = uniqBy(dataProduct, "id");
                const myLatest = responseData.map((itemRes) => {
                  const value = checkPromotionGETCD(itemRes, item);
                  let newItemRes = itemRes;
                  const objUrl = find(tempList, function (o) {
                    return o.product_id === itemRes.id && o.foc;
                  });
                  if (objUrl && objUrl.qty) {
                    newItemRes["qty"] = objUrl.qty;
                  } else {
                    newItemRes["qty"] = 0;
                  }
                  newItemRes["subtotal"] = 0;
                  newItemRes["cr"] = get(itemRes, "cr", false);
                  // get(itemRes, "cr", false) === true ? "CR" : "";
                  newItemRes["dg"] = get(itemRes, "dg", false);
                  // get(itemRes, "dg", false) === true ? "DG" : "";
                  newItemRes["foc"] = true;
                  newItemRes["promo"] = value;
                  newItemRes["growth"] = get(
                    itemRes,
                    `class4.${countryId}`,
                    ""
                  );
                  newItemRes["target_id"] = get(itemRes, "target_id", 0);
                  newItemRes["registration_number"] = getRegisterNumber(
                    itemRes,
                    countryCode
                  );
                  return newItemRes;
                });
                // onUpdateState(myLatest);
                // setRowData(myLatest);
                const newFilter = filter(myLatest, function (oo) {
                  return oo.promo !== 0;
                });
                const sortNewFilter = sortBy(newFilter, function (oio) {
                  return oio.promo;
                });

                setRowData(sortNewFilter);
                const myTempBuyFree = filter(myLatest, function (oo) {
                  return oo.foc && oo.qty > 0;
                });
                setTempListFree(myTempBuyFree);
              } else {
                if (response && response.id) {
                  const value = checkPromotionGETCD(response, item);
                  let newItemRes = response;
                  const objUrl = find(tempList, function (o) {
                    return o.product_id === response.id && o.foc;
                  });
                  if (objUrl && objUrl.qty) {
                    newItemRes["qty"] = objUrl.qty;
                  } else {
                    newItemRes["qty"] = 0;
                  }
                  newItemRes["subtotal"] = 0;
                  newItemRes["cr"] = get(response, "cr", false);
                  // get(response, "cr", false) === true ? "CR" : "";
                  newItemRes["dg"] = get(response, "dg", false);
                  // get(response, "dg", false) === true ? "DG" : "";
                  newItemRes["foc"] = true;
                  newItemRes["promo"] = value;
                  newItemRes["growth"] = get(
                    response,
                    `class4.${countryId}`,
                    ""
                  );
                  newItemRes["target_id"] = get(response, "target_id", 0);
                  newItemRes["registration_number"] = getRegisterNumber(
                    response,
                    countryCode
                  );
                  if (newItemRes.promo !== 0) {
                    setRowData([newItemRes]);
                    const myTempBuyFree = filter([newItemRes], function (oo) {
                      return oo.foc && oo.qty > 0;
                    });
                    setTempListFree(myTempBuyFree);
                  } else {
                    setRowData([]);
                    setTempListFree([]);
                  }
                }
              }
              setIsRequest(false);
            })
            .catch((e) => {
              setIsRequest(false);
            });
        } else {
          setIsRequest(false);
        }
      }
    }
    return () => setIsRequest(false);
  }, [
    myURl,
    tempList,
    isRequest,
    item,
    countryId,
    setTempListFree,
    countryCode,
  ]);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const getRowStyleDef = (params) => {
    if (params.data.promo === 1) {
      return {
        "background-color": "#33bfff",
        color: "#000000",
      };
    } else if (params.data.promo === 2) {
      return {
        "background-color": "#33c9dc",
        color: "#000000",
      };
    }
    return null;
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setState({
      ...state,
      expanded: newExpanded ? panel : false,
    });
  };

  const setOrderAmtDefault = (ProductCode, data) => {
    if (ProductCode) {
      let newListPromo = tempList;
      let newList = filter(newListPromo, function (o) {
        return o.product_id === data.id && o.foc;
      });
      const newNonPromoList = filter(newListPromo, function (o) {
        return !o.foc && o.qty > 0;
      });
      if (newList.length === 0) {
        const itemProduct = {
          product_id: data.id,
          qty: data.qty,
          price: 0,
          subtotal: 0,
          promotions: item,
          class4: data.class4,
          foc: true,
          target_id: data.target_id,
          new_existing: get(data, "new_existing", ""),
        };

        newListPromo.push(itemProduct);
        // setTempList(newListPromo);
        const newListFree = filter(newListPromo, function (o) {
          return o.foc;
        });
        const myListFree = sortOrderByName(rowData, newListFree);
        setTempList([...newNonPromoList, ...myListFree]);
      } else {
        let newListNonFree = filter(newListPromo, function (o) {
          return o.product_id !== data.id;
        });

        const itemProduct = {
          product_id: data.id,
          qty: data.qty,
          price: 0,
          subtotal: 0,
          promotions: item,
          class4: data.class4,
          foc: true,
          target_id: data.target_id,
          new_existing: get(data, "new_existing", ""),
        };
        newListNonFree.push(itemProduct);
        const newListFree = filter(newListNonFree, function (o) {
          return o.foc;
        });
        const myListFree = sortOrderByName(rowData, newListFree);
        setTempList([...newNonPromoList, ...myListFree]);
      }

      let newList1 = filter(tempListFree, function (o) {
        return o.id !== data.id && o.foc;
      });
      newList1.push(data);
      // const myListFree = sortOrderByName(rowData, newList1);
      setTempListFree(newList1);
    }
  };

  const sortOrderByName = (rowData, list) => {
    const newList = map(list, function (o) {
      const itemProduct = find(rowData, function (i) {
        return i.id === o.product_id;
      });
      return { ...o, product_name: get(itemProduct, "packaging", "") };
    });
    const sortByName = orderBy(newList, "product_name", "asc");
    let mySortOrder = item && item.id ? item.id * 200 : 1;
    const newSortByName = map(sortByName, function (ii) {
      const { product_name, ...rest } = ii;
      rest["sort_order"] = mySortOrder++;
      return { ...rest };
    });

    return newSortByName;
  };

  const onCellEditingStopped = (params) => {
    if (params.data.qty > 0) {
      let rowNode1 = gridApi.getRowNode(params.data.code);
      const qtyTotalFree = getTotalFree(item, tempListBuy);
      let listFree = filter(tempListFree, function (oo) {
        return oo.id !== params.data.id;
      });
      listFree.push(params.data);
      const newListRaw = getTotalSelected(listFree);
      const totalSelected = sumBy(newListRaw, "qty");
      rowNode1.setDataValue("subtotal", 0);
      if (totalSelected > qtyTotalFree || qtyTotalFree === 0) {
        setShow(true);
        setMessageError(`Free Item is ${qtyTotalFree}`);
        rowNode1.setDataValue("qty", 0);
      }
      setOrderAmtDefault(params.data.code, params.data);
      // if (params.data.promo === 1) {
      //   let listFree = filter(tempListFree, function (oo) {
      //     return oo.id !== params.data.id;
      //   });
      //   listFree.push(params.data);
      //   const newListRaw = getTotalSelected(listFree, 1);
      //   const totalSelected = sumBy(newListRaw, "qty");
      //   rowNode1.setDataValue("subtotal", 0);
      //   if (totalSelected > qtyTotalFree || qtyTotalFree === 0) {
      //     setShow(true);
      //     setMessageError(`Free Item is ${qtyTotalFree}`);
      //     rowNode1.setDataValue("qty", 0);
      //   }
      //   setOrderAmtDefault(params.data.code, params.data);
      // } else if (params.data.promo === 2) {
      //   let listFree = filter(tempListFree, function (oo) {
      //     return oo.id !== params.data.id;
      //   });
      //   listFree.push(params.data);
      //   const newListRaw = getTotalSelected(listFree, 2);
      //   const totalSelected = sumBy(newListRaw, "qty");
      //   rowNode1.setDataValue("subtotal", 0);
      //   if (totalSelected > qtyTotalFree || qtyTotalFree === 0) {
      //     setShow(true);
      //     setMessageError(`Free Item is ${qtyTotalFree}`);
      //     rowNode1.setDataValue("qty", 0);
      //   }
      //   setOrderAmtDefault(params.data.code, params.data);
      // } else {
      //   setShow(true);
      //   setMessageError(`Free Item is 0`);
      //   rowNode1.setDataValue("qty", 0);
      //   rowNode1.setDataValue("subtotal", 0);
      // }
    } else {
      setOrderAmtDefault(params.data.code, params.data);
    }
  };
  const qtyTotalFree = getTotalFree(item, tempListBuy);
  const qtySelectedC = getTotalSelectedQtyFreeA(tempListFree);
  // const qtySelectedD = getTotalSelectedQtyFreeA(tempListFree, 2);
  return (
    <Fragment>
      <ExpansionPanel
        expanded={state.expanded === "panelGridFreePromoABCD"}
        onChange={handleChange("panelGridFreePromoABCD")}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='free_items-content'
          id='free_items-header'
        >
          <Typography>
            {translate("resources.promotions.panel.free_items")} (
            {rowData.length}{" "}
            {rowData.length > 1
              ? translate("resources.po.listgrid.label.items")
              : translate("resources.po.listgrid.label.item")}
            )
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          style={{ width: "100%%", padding: 0, paddingTop: 4 }}
        >
          <Box width={[1]}>
            <HeaderFreeABCD
              qtyTotalFreeC={qtyTotalFree}
              qtySelectedC={qtySelectedC}
            />

            <div style={{ width: "100%" }}>
              <div className='grid-wrapper'>
                <div
                  id='myGridFreeABCD'
                  style={{
                    height: "20vh",
                    width: "100%",
                  }}
                  className='ag-theme-balham'
                >
                  <AgGridReact
                    rowHeight={40}
                    headerHeight={40}
                    rowData={rowData}
                    columnDefs={state.columnDefs}
                    defaultColDef={state.defaultColDef}
                    animateRows={true}
                    getRowNodeId={state.getRowNodeId}
                    onGridReady={onGridReady}
                    getRowStyle={getRowStyleDef}
                    onCellEditingStopped={(params) =>
                      onCellEditingStopped(params)
                    }
                  />
                </div>
              </div>
            </div>
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Fragment>
  );
};
