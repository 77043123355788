import React from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import {
  // NumberInput,
  ReferenceInput,
  // ArrayInput,
  AutocompleteInput,
  SelectInput,
} from "react-admin";
import { useStyles } from "./styles";
// import TableFormIterator from "./TableFormIterator";
import { withStyles, createStyles } from "@material-ui/core/styles";
import CardTable, { dataCriteriaType } from "./CardTable";
import { ThemeProvider } from "@material-ui/styles";
import { themeTableInput } from "../../../themes";
import ProductShortCodeInput from "./ProductShortCodeInput";
import { BuyABGetCDMOQ } from "./TableStyles";
// const defaultBuyABGetCD = [
//   { buy: 0, get: 0, moq: 0, additional: 0 },
//   { buy: 0, get: 0, moq: 0, additional: 0 },
//   { buy: 0, get: 0, moq: 0, additional: 0 },
// ];

const mystyles = (theme) =>
  createStyles({
    root: {
      width: "100%",
    },
  });

const BuyABGetCD = (props) => {
  const {
    formData,
    checkEmptyValue,
    dataProducts,
    updateField,
    ...rest
  } = props;
  const classes = useStyles();
  const type_a = get(formData, "criteria.buy_ab_get_cd.a.type", "");
  const type_b = get(formData, "criteria.buy_ab_get_cd.b.type", "");
  const type_c = get(formData, "criteria.buy_ab_get_cd.c.type", "");
  const type_d = get(formData, "criteria.buy_ab_get_cd.d.type", "");
  return (
    <Flex
      width={[1]}
      flexDirection={"column"}
      sx={{
        "&>div": {
          pb: [3],
        },
      }}
    >
      <CardTable {...props} title={"resources.promotions.labels.buy_a_or_b"}>
        <Flex flexDirection='row' width={[1]} justifyContent='flex-start'>
          <SelectInput
            source='criteria.buy_ab_get_cd.a.type'
            choices={dataCriteriaType}
            label={"resources.promotions.labels.criteria_type_a"}
            style={{ paddingRight: 16 }}
          />
          {(type_a === "ProductGroup" || type_a === "SubProductGroup") && (
            <ReferenceInput
              label={"resources.promotions.labels.criteria_a"}
              source='criteria.buy_ab_get_cd.a.id'
              reference='codes'
              className={classes.product}
              filter={{ type: type_a, active: true }}
              sort={{ field: "label", order: "ASC" }}
              perPage={1000}
            >
              <AutocompleteInput optionText='label' />
            </ReferenceInput>
          )}
          {type_a === "ProductCode" && (
            <ProductShortCodeInput
              record={formData}
              source='criteria.buy_ab_get_cd.a.id'
              optionValue={"code"}
              updateField={updateField}
              label={"resources.promotions.labels.criteria_a"}
              list={dataProducts || []}
            />
          )}
          {type_a === "ProductShortCode" && (
            <ProductShortCodeInput
              record={formData}
              source='criteria.buy_ab_get_cd.a.id'
              optionValue={"short_code"}
              updateField={updateField}
              label={"resources.promotions.labels.criteria_a"}
              list={dataProducts || []}
            />
          )}
        </Flex>
        <Flex flexDirection='row' width={[1]} justifyContent='flex-start'>
          <SelectInput
            source='criteria.buy_ab_get_cd.b.type'
            choices={dataCriteriaType}
            label={"resources.promotions.labels.criteria_type_b"}
            style={{ paddingRight: 16 }}
          />
          {(type_b === "ProductGroup" || type_b === "SubProductGroup") && (
            <ReferenceInput
              label={"resources.promotions.labels.criteria_b"}
              source='criteria.buy_ab_get_cd.b.id'
              reference='codes'
              className={classes.product}
              filter={{ type: type_b, active: true }}
              sort={{ field: "label", order: "ASC" }}
              perPage={1000}
            >
              <AutocompleteInput optionText='label' />
            </ReferenceInput>
          )}
          {type_b === "ProductCode" && (
            <ProductShortCodeInput
              record={formData}
              source='criteria.buy_ab_get_cd.b.id'
              optionValue={"code"}
              updateField={updateField}
              label={"resources.promotions.labels.criteria_b"}
              list={dataProducts || []}
            />
          )}
          {type_b === "ProductShortCode" && (
            <ProductShortCodeInput
              record={formData}
              source='criteria.buy_ab_get_cd.b.id'
              optionValue={"short_code"}
              updateField={updateField}
              label={"resources.promotions.labels.criteria_b"}
              list={dataProducts || []}
            />
            // <ReferenceInput
            //   label={"resources.promotions.labels.criteria_b"}
            //   source='criteria.buy_ab_get_cd.b.id'
            //   reference='products'
            //   className={classes.product}
            //   perPage={1000}
            // >
            //   <AutocompleteInput
            //     optionText='packaging'
            //     optionValue={"short_code"}
            //   />
            // </ReferenceInput>
          )}
        </Flex>
      </CardTable>

      <CardTable {...props} title={"resources.promotions.labels.get_c_or_d"}>
        <Flex flexDirection='row' width={[1]} justifyContent='flex-start'>
          <SelectInput
            source='criteria.buy_ab_get_cd.c.type'
            choices={dataCriteriaType}
            label={"resources.promotions.labels.criteria_type_c"}
            style={{ paddingRight: 16 }}
          />
          {(type_c === "ProductGroup" || type_c === "SubProductGroup") && (
            <ReferenceInput
              label={"resources.promotions.labels.criteria_c"}
              source='criteria.buy_ab_get_cd.c.id'
              reference='codes'
              className={classes.product}
              filter={{ type: type_c, active: true }}
              sort={{ field: "label", order: "ASC" }}
              perPage={1000}
            >
              <AutocompleteInput optionText='label' />
            </ReferenceInput>
          )}
          {type_c === "ProductCode" && (
            <ProductShortCodeInput
              record={formData}
              source='criteria.buy_ab_get_cd.c.id'
              optionValue={"code"}
              updateField={updateField}
              label={"resources.promotions.labels.criteria_c"}
              list={dataProducts || []}
            />
            // <ReferenceInput
            //   label={"resources.promotions.labels.criteria_c"}
            //   source='criteria.buy_ab_get_cd.c.id'
            //   reference='products'
            //   filter={{ type: type_c }}
            //   className={classes.product}
            //   perPage={1000}
            // >
            //   <AutocompleteInput optionText='packaging' optionValue={"id"} />
            // </ReferenceInput>
          )}
          {type_c === "ProductShortCode" && (
            <ProductShortCodeInput
              record={formData}
              source='criteria.buy_ab_get_cd.c.id'
              optionValue={"short_code"}
              updateField={updateField}
              label={"resources.promotions.labels.criteria_c"}
              list={dataProducts || []}
            />
            // <ReferenceInput
            //   label={"resources.promotions.labels.criteria_c"}
            //   source='criteria.buy_ab_get_cd.c.id'
            //   reference='products'
            //   filter={{ type: type_c }}
            //   className={classes.product}
            //   perPage={1000}
            // >
            //   <AutocompleteInput
            //     optionText='packaging'
            //     optionValue={"short_code"}
            //   />
            // </ReferenceInput>
          )}
        </Flex>
        <Flex flexDirection='row' width={[1]} justifyContent='flex-start'>
          <SelectInput
            source='criteria.buy_ab_get_cd.d.type'
            choices={dataCriteriaType}
            label={"resources.promotions.labels.criteria_type_d"}
            style={{ paddingRight: 16 }}
          />
          {(type_d === "ProductGroup" || type_d === "SubProductGroup") && (
            <ReferenceInput
              label={"resources.promotions.labels.criteria_d"}
              source='criteria.buy_ab_get_cd.d.id'
              reference='codes'
              className={classes.product}
              filter={{ type: type_d, active: true }}
              sort={{ field: "label", order: "ASC" }}
              perPage={1000}
            >
              <AutocompleteInput optionText='label' />
            </ReferenceInput>
          )}
          {type_d === "ProductCode" && (
            <ProductShortCodeInput
              record={formData}
              source='criteria.buy_ab_get_cd.d.id'
              optionValue={"code"}
              updateField={updateField}
              label={"resources.promotions.labels.criteria_d"}
              list={dataProducts || []}
            />
            // <ReferenceInput
            //   label={"resources.promotions.labels.criteria_d"}
            //   source='criteria.buy_ab_get_cd.d.id'
            //   reference='products'
            //   className={classes.product}
            //   filter={{ type: type_d }}
            //   perPage={1000}
            // >
            //   <AutocompleteInput optionText='packaging' optionValue={"id"} />
            // </ReferenceInput>
          )}
          {type_d === "ProductShortCode" && (
            <ProductShortCodeInput
              record={formData}
              source='criteria.buy_ab_get_cd.d.id'
              optionValue={"short_code"}
              updateField={updateField}
              label={"resources.promotions.labels.criteria_d"}
              list={dataProducts || []}
            />
            // <ReferenceInput
            //   label={"resources.promotions.labels.criteria_d"}
            //   source='criteria.buy_ab_get_cd.d.id'
            //   reference='products'
            //   className={classes.product}
            //   filter={{ type: type_d }}
            //   perPage={1000}
            // >
            //   <AutocompleteInput
            //     optionText='packaging'
            //     optionValue={"short_code"}
            //   />
            // </ReferenceInput>
          )}
        </Flex>
      </CardTable>
      <ThemeProvider theme={themeTableInput}>
        <Flex width={[1]} flexDirection={"column"}>
          <BuyABGetCDMOQ {...rest} checkEmptyValue={checkEmptyValue} />
          {/* <ArrayInput
            source='criteria.buy_ab_get_cd.moqs'
            {...rest}
            label={""}
            defaultValue={defaultBuyABGetCD}
          >
            <TableFormIterator {...rest} disableAdd={true} disableRemove={true}>
              <NumberInput
                source='moq'
                style={{ width: 100, margin: 0 }}
                label={"resources.promotions.fields.moq"}
                onBlur={(e) =>
                  checkEmptyValue(
                    "criteria.buy_ab_get_cd.moqs.moq",
                    e.target.value
                  )
                }
              />
              <NumberInput
                source='buy'
                style={{ width: 100, margin: 0 }}
                label={"resources.promotions.fields.buy"}
                onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
              />
              <NumberInput
                source='get'
                style={{ width: 100, margin: 0 }}
                label={"resources.promotions.fields.get"}
                onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
              />
              <NumberInput
                source='additional'
                style={{ width: 100, margin: 0 }}
                label={"resources.promotions.fields.additional"}
                onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
              />
            </TableFormIterator>
          </ArrayInput> */}
        </Flex>
      </ThemeProvider>
    </Flex>
  );
};
export default withStyles(mystyles)(BuyABGetCD);
