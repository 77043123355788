import React from "react";
import { Flex, Text } from "rebass";
import { get, map, sumBy, has } from "lodash";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
// import NumberFormat from "react-number-format";
import { StyledTableAction, StyledTableCell, StyledTableRow } from "../edit";
import { CurrencyField } from "./CurrencyField";
import { useQueryWithStore } from "react-admin";

const PromoTypeB = {
  buyxgety: "Buy X Get Y",
  buyabgetcd: "Buy AB Get CD",
  discount: "Discount",
  sp: "Special Price",
};

const getValuePackageCharge = (item, currency, totalAmount) => {
  let value = 0;
  if (currency === "USD") {
    value =
      totalAmount > 10000
        ? get(item, "greater_than_equals_10k_usd", 0) * 1
        : get(item, "less_than_10k_usd", 0) * 1;
  } else if (currency === "SGD") {
    value =
      totalAmount > 10000
        ? get(item, "greater_than_equals_10k_sgd", 0) * 1
        : get(item, "less_than_10k_sgd", 0) * 1;
  }
  return value;
};

const getSubtotal = (record) => {
  const { items } = record;
  const newList = map(items, function (o) {
    const promotions = get(o, "promotions", null);
    if (promotions !== null) {
      const promoType = get(promotions, "promotion_type", "");
      if (promoType === PromoTypeB.buyxgety) {
        return { id: o.id, subtotal: o.qty * o.price };
      } else if (promoType === PromoTypeB.buyabgetcd) {
        return { id: o.id, subtotal: o.qty * o.price };
      } else if (promoType === PromoTypeB.sp) {
        const discountPrice = get(o, "discounted_price", 0) * 1;
        // const price = get(o, "price", 0) * 1;
        // const newPrice = discountPrice === 0 ? price : discountPrice;
        return { id: o.id, subtotal: o.qty * discountPrice };
      } else if (promoType === PromoTypeB.discount) {
        const discountPrice = get(o, "discounted_price", 0) * 1;
        const price = get(o, "price", 0) * 1;
        const newPrice = discountPrice === 0 ? price : discountPrice;
        return { id: o.id, subtotal: o.qty * newPrice };
      } else {
        return { id: o.id, subtotal: o.qty * o.price };
      }
    } else {
      const discountPrice = get(o, "discounted_price", 0) * 1;
      const price = get(o, "price", 0) * 1;
      const newPrice = has(o, "discounted_price") ? discountPrice : price;
      return { id: o.id, subtotal: o.qty * newPrice };
    }
  });

  return newList;
};

const MainFields = (props) => {
  const { translate, countryId, record } = props;
  //totalAmount,
  const { data, loading } = useQueryWithStore({
    type: "getOne",
    resource: "countries",
    payload: { id: countryId },
  });
  if (loading) {
    return (
      <TableContainer component={"div"} style={{ width: 380 }}>
        <Table aria-label='customized table'>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell />
              <StyledTableCell>Loading...</StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  const isActive = get(data, "po_additional_charges.gst.active", false);
  // const gst = get(data, "po_additional_charges.gst.percent", 0) * 1;
  const gst = get(record, "tax_rate", 0) * 1;
  const isPCActive = get(
    data,
    "po_additional_charges.packing_charge.active",
    false
  );
  const itemPackingCharge = get(
    data,
    "po_additional_charges.packing_charge",
    {}
  );
  const currencyDefault = get(data, "currency", "");
  const shipping = get(record, "shipping", 0) * 1;
  const listTotal = getSubtotal(record);
  const totalAmountRecord = sumBy(listTotal, "subtotal") * 1;
  const valuePackageCharge = getValuePackageCharge(
    itemPackingCharge,
    currencyDefault,
    totalAmountRecord
  );
  const currentGST = gst > 1 ? gst : gst * 100;
  const joinTA_PC = isPCActive
    ? totalAmountRecord + valuePackageCharge
    : totalAmountRecord;
  const valueCurrentGST = (joinTA_PC * currentGST) / 100;

  const grandTotal = isActive
    ? valueCurrentGST + joinTA_PC + shipping
    : joinTA_PC + shipping;
  // console.log(totalAmountRecord, record);
  return (
    <TableContainer component={"div"} style={{ width: 380 }}>
      <Table aria-label='customized table'>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell />
            <StyledTableAction />
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell>
              <Text textAlign={"right"}>
                {translate("resources.po.listgrid.label.subtotal")}
              </Text>
            </StyledTableCell>
            <StyledTableAction style={{ textAlign: "right", paddingRight: 16 }}>
              <CurrencyField value={totalAmountRecord} />
            </StyledTableAction>
          </StyledTableRow>
          {isPCActive ? (
            <StyledTableRow>
              <StyledTableCell>
                <Text textAlign={"right"} fontSize={[0]}>
                  {translate("resources.po.listgrid.label.package_charge")}
                </Text>
              </StyledTableCell>
              <StyledTableAction
                style={{ textAlign: "right", paddingRight: 16 }}
              >
                <Text textAlign={"right"} fontSize={[0]}>
                  <CurrencyField value={valuePackageCharge} />
                  {/* <NumberFormat
                    value={valuePackageCharge}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    decimalScale={2}
                  /> */}
                </Text>
              </StyledTableAction>
            </StyledTableRow>
          ) : (
            <StyledTableRow>
              <StyledTableCell>
                <Text textAlign={"right"}>
                  {translate("resources.po.listgrid.label.package_charge")}
                </Text>
              </StyledTableCell>
              <StyledTableAction
                style={{ textAlign: "right", paddingRight: 16 }}
              >
                <CurrencyField value={0} />
                {/* <NumberFormat
                  value={0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                  decimalScale={2}
                /> */}
              </StyledTableAction>
            </StyledTableRow>
          )}
          <StyledTableRow>
            <StyledTableCell>
              <Text textAlign={"right"}>
                {translate("resources.po.listgrid.label.shipping")}
              </Text>
            </StyledTableCell>
            <StyledTableAction style={{ textAlign: "right", paddingRight: 16 }}>
              <CurrencyField value={shipping} />
              {/* <NumberFormat
                value={shipping}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={2}
              /> */}
            </StyledTableAction>
          </StyledTableRow>
          {isActive ? (
            <StyledTableRow>
              <StyledTableCell>
                <Text textAlign={"right"} fontSize={[0]}>
                  {translate("resources.po.listgrid.label.tax")} {currentGST}%
                </Text>
              </StyledTableCell>
              <StyledTableAction
                style={{ textAlign: "right", paddingRight: 16 }}
              >
                <Text textAlign={"right"} fontSize={[0]}>
                  <CurrencyField value={valueCurrentGST} />
                  {/* <NumberFormat
                    value={valueCurrentGST}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    decimalScale={2}
                  /> */}
                </Text>
              </StyledTableAction>
            </StyledTableRow>
          ) : (
            <StyledTableRow>
              <StyledTableCell>
                <Text textAlign={"right"}>Tax 0%</Text>
              </StyledTableCell>
              <StyledTableAction
                style={{ textAlign: "right", paddingRight: 16 }}
              >
                <CurrencyField value={0} />
                {/* <NumberFormat
                  value={0}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                  decimalScale={2}
                /> */}
              </StyledTableAction>
            </StyledTableRow>
          )}
          <StyledTableRow>
            <StyledTableCell>
              <Text textAlign={"right"}>
                {translate("resources.po.listgrid.label.total")}
              </Text>
            </StyledTableCell>
            <StyledTableAction style={{ textAlign: "right", paddingRight: 16 }}>
              <CurrencyField value={grandTotal} />
            </StyledTableAction>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const TotalTable = (props) => {
  const { record, countryId } = props;
  const dealerId = get(record, "dealer_id");
  const { data, loading } = useQueryWithStore({
    type: "getOne",
    resource: "dealers",
    payload: { id: dealerId },
  });
  if (loading) {
    return (
      <Flex
        width={[1]}
        flexDirection={"column"}
        alignItems={"flex-end"}
        pt={[4]}
      >
        <TableContainer component={"div"} style={{ width: 380 }}>
          <Table aria-label='customized table'>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell />
                <StyledTableCell>Loading...</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Flex>
    );
  }
  const countryId1 = get(data, "country_id", countryId);
  return (
    <Flex width={[1]} flexDirection={"column"} alignItems={"flex-end"} pt={[1]}>
      <MainFields {...props} countryId={countryId1} />
    </Flex>
  );
};
export default TotalTable;
