import React from "react"
import { Edit } from "react-admin"
import { BetterCustomSimpleForm, EditToolbar } from "./"
import FormFields from "./FormFields"

const EditCustomerPromotion = (props) => {
  return (
    <Edit {...props}>
      <BetterCustomSimpleForm
        {...props}
        toolbar={<EditToolbar />}
        sourceApi="promotions"
        title={"resources.promotions.title.new_promotion"}
        typePostUrl={"UPDATE"}
        pathUrl="promotions"
        customBackUrl="/promotions/customers"
      >
        <FormFields {...props} isCreate={false} />
      </BetterCustomSimpleForm>
    </Edit>
  )
}

export default EditCustomerPromotion
