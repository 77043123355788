import React, { useState } from "react";
import { useDataProvider, useRefresh, useRedirect } from "react-admin";
import { Flex, Box } from "rebass";
import { Button, CircularProgress } from "@material-ui/core";
import { get } from "lodash";
import { STATUSPO } from "../../../utils";

const checkDiscountedPrice = (record) => {
  const items = get(record, "items", []);
  const newItems = items.map((i) => {
    if (get(i, "discounted_price")) {
      if (
        get(i, "discounted_price") === "0" ||
        get(i, "discounted_price") === 0
      ) {
        return {
          ...i,
          discounted_price: get(i, "price", "0"),
        };
      } else {
        return i;
      }
    } else {
      return i;
    }
  });
  const newParams = {
    ...record,
    items: newItems,
  };
  return newParams;
};

const DealerView = (props) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const { translate, po, notify } = props;
  const onSendToDealer = () => {
    if (po && po.id) {
      setLoading(true);
      const params = {
        ...po,
        status: STATUSPO.draft,
      };
      const newParams = checkDiscountedPrice(params);
      dataProvider
        .update("purchaseOrder", {
          id: po.id,
          data: newParams,
          previousData: po,
        })
        .then(({ data }) => {
          setLoading(false);
          // console.log(data);
          if (data && data.id) {
            notify("resources.notification.updated", "success", {
              smart_count: 1,
            });
            redirect("/orders");
            refresh();
          }
        })
        .catch((e) => {
          setLoading(false);
          // console.log(e.message);
        });
    }
  };
  return (
    <Flex width={[1]} flexDirection={"column"} px={[3]} pb={[3]}>
      {po && po.status === STATUSPO.sent && (
        <Box width={[1]}>
          <Button
            variant={"contained"}
            color={"primary"}
            style={{ textTransform: "capitalize", borderRadius: 16 }}
            onClick={() => onSendToDealer()}
            disabled={loading}
            startIcon={
              loading ? (
                <CircularProgress color={"secondary"} size={16} />
              ) : null
            }
          >
            {translate("resources.buttons.sent_to_dealer")}
          </Button>
        </Box>
      )}
    </Flex>
  );
};

export default DealerView;
