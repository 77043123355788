import { downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";
import { get, map, orderBy } from "lodash";
import moment from "moment";

export const reportPOSummary = (list, reportType) => {
  const newList = map(list, function (o) {
    const createdAt = get(o, "created_at", "");
    const formatCreateAt =
      createdAt !== "" ? moment(createdAt).format("YYYY-MM-DD") : "";
    return {
      "Create Date": formatCreateAt,
      "GC Reference": get(o, "purchase_order_number", ""),
      "My Reference": get(o, "my_reference_number", ""),
      Status: get(o, "status", ""),
      Amount: get(o, "amount", 0),
      "Created By": get(o, "created_by", ""),
      Remarks: get(o, "remarks", ""),
    };
  });
  const newSortList = orderBy(
    newList,
    [
      (item) => {
        const createBy = get(item, "Create Date", "");
        return createBy.toLowerCase();
      },
    ],
    ["asc"]
  );

  jsonExport(
    newSortList,
    {
      headers: [
        "Create Date",
        "GC Reference",
        "My Reference",
        "Status",
        "Amount",
        "Created By",
        "Remarks",
      ],
    },
    (err, csv) => {
      if (err) return console.error(err);
      downloadCSV(
        csv,
        `${reportType}-${moment().format("DD-MMM-YYYY-HH:mm:ss")}`
      );
    }
  );
};

export const reportPODetail = (list, reportType) => {
  const newList = map(list, function (o) {
    const createdAt = get(o, "created_at", "");
    const formatCreateAt =
      createdAt !== "" ? moment(createdAt).format("YYYY-MM-DD") : "";
    return {
      "Create Date": formatCreateAt,
      "GC Reference": get(o, "purchase_order_number", ""),
      "My Reference": get(o, "my_reference_number", ""),
      Status: get(o, "status", ""),
      Amount: get(o, "amount", 0),
      "Created By": get(o, "created_by", ""),
      Remarks: get(o, "remarks", ""),
      "Product Name": get(o, "product_name", ""),
      "Product Code": get(o, "product_code", ""),
      Price: get(o, "price", 0),
      "Discounted Price": get(o, "discounted_price", 0),
      Qty: get(o, "qty", 0),
      Subtotal: get(o, "subtotal", 0),
    };
  });
  const newSortList = orderBy(
    newList,
    [
      (item) => {
        const createBy = get(item, "Create Date", "");
        return createBy.toLowerCase();
      },
    ],
    ["asc"]
  );

  jsonExport(
    newSortList,
    {
      headers: [
        "Create Date",
        "GC Reference",
        "My Reference",
        "Status",
        "Amount",
        "Created By",
        "Remarks",
        "Product Name",
        "Product Code",
        "Price",
        "Discounted Price",
        "Qty",
        "Subtotal",
      ],
    },
    (err, csv) => {
      if (err) return console.error(err);
      downloadCSV(
        csv,
        `${reportType}-${moment().format("DD-MMM-YYYY-HH:mm:ss")}`
      );
    }
  );
};

export const reportSOS = (list, reportType) => {
  const newList = map(list, function (o) {
    const createdAt = get(o, "created_at", "");
    const formatCreateAt =
      createdAt !== "" ? moment(createdAt).format("YYYY-MM-DD") : "";
    return {
      "Create Date": formatCreateAt,
      "Customer/Reference": get(o, "customer_reference", ""),
      Qty: get(o, "qty", 0),
      Status: get(o, "status", ""),
      "Created By": get(o, "created_by", ""),
    };
  });
  const newSortList = orderBy(
    newList,
    [
      (item) => {
        const createBy = get(item, "Create Date", "");
        return createBy.toLowerCase();
      },
    ],
    ["asc"]
  );

  jsonExport(
    newSortList,
    {
      headers: [
        "Create Date",
        "Customer/Reference",
        "Qty",
        "Status",
        "Created By",
      ],
    },
    (err, csv) => {
      if (err) return console.error(err);
      downloadCSV(
        csv,
        `${reportType}-${moment().format("DD-MMM-YYYY-HH:mm:ss")}`
      );
    }
  );
};

export const reportSOD = (list, reportType) => {
  const newList = map(list, function (o) {
    const createdAt = get(o, "created_at", "");
    const formatCreateAt =
      createdAt !== "" ? moment(createdAt).format("YYYY-MM-DD") : "";
    return {
      "Create Date": formatCreateAt,
      "Customer/Reference": get(o, "customer_reference", ""),
      "Product Code": get(o, "product_code", ""),
      "Product Name": get(o, "product_name", ""),
      Qty: get(o, "qty", 0),
      Status: get(o, "status", ""),
      "Created By": get(o, "created_by", ""),
    };
  });
  const newSortList = orderBy(
    newList,
    [
      (item) => {
        const createBy = get(item, "Create Date", "");
        return createBy.toLowerCase();
      },
    ],
    ["asc"]
  );

  jsonExport(
    newSortList,
    {
      headers: [
        "Create Date",
        "Customer/Reference",
        "Product Code",
        "Product Name",
        "Qty",
        "Status",
        "Created By",
      ],
    },
    (err, csv) => {
      if (err) return console.error(err);
      downloadCSV(
        csv,
        `${reportType}-${moment().format("DD-MMM-YYYY-HH:mm:ss")}`
      );
    }
  );
};

export const reportInvoiceSummary = (list, reportType) => {
  const newList = map(list, function (o) {
    const createdAt = get(o, "created_at", "");
    const formatCreateAt =
      createdAt !== "" ? moment(createdAt).format("YYYY-MM-DD") : "";
    return {
      "Batch ID": get(o, "id", ""),
      "Upload Date": formatCreateAt,
      "Total Items": get(o, "total_items", 0),
      Dealer: get(o, "dealer", ""),
    };
  });
  const newSortList = orderBy(
    newList,
    [
      (item) => {
        const createBy = get(item, "Dealer", "");
        return createBy.toLowerCase();
      },
    ],
    ["asc"]
  );

  jsonExport(
    newSortList,
    {
      headers: ["Batch ID", "Upload Date", "Total Items", "Dealer"],
    },
    (err, csv) => {
      if (err) return console.error(err);
      downloadCSV(
        csv,
        `${reportType}-${moment().format("DD-MMM-YYYY-HH:mm:ss")}`
      );
    }
  );
};
export const reportInvoiceDetail = (list, reportType) => {
  const newList = map(list, function (o) {
    const createdAt = get(o, "created_at", "");
    const formatCreateAt =
      createdAt !== "" ? moment(createdAt).format("YYYY-MM-DD") : "";
    return {
      "Batch ID": get(o, "id", ""),
      "Upload Date": formatCreateAt,
      "Product Code": get(o, "product_code", ""),
      "Product Name": get(o, "product_name", ""),
      "Total Items": get(o, "total_items", 0),
      Dealer: get(o, "dealer", ""),
    };
  });
  const newSortList = orderBy(
    newList,
    [
      (item) => {
        const createBy = get(item, "Product Name", "");
        return createBy.toLowerCase();
      },
    ],
    ["asc"]
  );

  jsonExport(
    newSortList,
    {
      headers: [
        "Batch ID",
        "Upload Date",
        "Product Code",
        "Product Name",
        "Total Items",
        "Dealer",
      ],
    },
    (err, csv) => {
      if (err) return console.error(err);
      downloadCSV(
        csv,
        `${reportType}-${moment().format("DD-MMM-YYYY-HH:mm:ss")}`
      );
    }
  );
};
