import React, { useRef } from "react";
import { Create, useNotify } from "react-admin";
import { get } from "lodash";
import moment from "moment";
import { BetterSellOutForm, ActionForm } from "../components";
import { FormFields, FooterSelloutToolbar } from "./components";
import { getUser } from "../utils/localeConfig";

// const payloadDefault = {
//   pagination: { page: 1, perPage: 1000 },
//   sort: { field: "packaging", order: "ASC" },
// };

const CreateSellout = (props) => {
  const childRef = useRef(null);
  const user = getUser();
  // console.log(user);
  const notify = useNotify();

  const dealerId = get(user, "dealer_id", -1);
  // const { loading, data } = useQueryWithStore({
  //   type: "getList",
  //   resource: "products",
  //   payload: payloadDefault,
  // });

  // if (loading) {
  //   return (
  //     <Flex flexDirection={"column"} width={[1]} alignItems={"center"}>
  //       <Loading />
  //     </Flex>
  //   );
  // }
  const onFailure = (e) => {
    notify(e.message, "warning");
    console.log("testing", e.message);
    childRef.current.resetFieldItems();
  };
  return (
    <Create
      {...props}
      actions={<ActionForm title={"resources.sellout.title.new_sellout"} />}
      redirect={"list"}
      onFailure={onFailure}
    >
      <BetterSellOutForm
        {...props}
        toolbar={<FooterSelloutToolbar />}
        initialValues={{
          dealer_id: dealerId,
          created_at: moment().toISOString(),
          items: [],
          total: 0,
        }}
      >
        {/* ListProduct={data} */}
        <FormFields {...props} ref={childRef} />
      </BetterSellOutForm>
    </Create>
  );
};

export default CreateSellout;
