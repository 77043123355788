import React from "react";
import { Flex, Box } from "rebass";
import { Title } from "../../components";
import { InboxIcon } from "../../components/icon_svg";

const EmptyList = (props) => {
  const { translate, title, fy } = props;
  // console.log(props)
  return (
    <Flex
      width={[1]}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      style={{ height: "40vh", border: "1px solid #eeeeee" }}
    >
      <InboxIcon fontSize="large" style={{ color: "#d1d1d1" }} />
      <Box width={[1]} py={[2]}>
        <Title fontSize={[1]} textAlign={"center"} p={[2]}>
          {translate(title)}
          {fy}.
        </Title>
        <Title fontSize={[1]} textAlign={"center"} p={[0]}>
          {translate("resources.targets.message.empty_list1")}
        </Title>
      </Box>
    </Flex>
  );
};

export default EmptyList;
