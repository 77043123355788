import { get, orderBy, find, filter } from "lodash";
import create from "zustand";
export const setItemsList = (list) => {
  if (list && list !== null && list.length > 0) {
    // let objNormal = {
    //   tPromoOrderQty: 600,
    //   tPromoOrderAmt: 27600,
    //   freeProduct: null,
    //   listPromo: [],
    //   item: null,
    // };
    list.map((product) => {
      if (product.promotions === null) {
        // console.log("normal", product);
      } else {
        // console.log("promotions");
      }
      return "";
    });
    // console.log(objNormal);
  }
  return [];
};

export const PromoType_B = [
  "Buy X Get Y",
  "Buy AB Get CD",
  "Discount",
  "Special Price",
];

export const keyPromo = [
  { id: "ProductGroup", value: "group_id" },
  { id: "SubProductGroup", value: "subgroup_id" },
  { id: "ProductCode", value: "code" },
  { id: "ProductShortCode", value: "_short" },
];

export const getPromoType = (pt, item) => {
  if (pt === PromoType_B[0]) {
    const promoType = get(item, "criteria.buy_x_get_y.x.type", "");
    const promoID = get(item, "criteria.buy_x_get_y.x.id", 0);
    const objUrl = find(keyPromo, function (o) {
      // console.log(promoType, o.id);
      return o.id === promoType;
    });
    // console.log(objUrl, item);
    return `${objUrl.value}=${promoID}`;
  } else if (pt === PromoType_B[1]) {
    const promoTypeA = get(item, "criteria.buy_ab_get_cd.a.type", "");
    const promoTypeB = get(item, "criteria.buy_ab_get_cd.b.type", "");
    const promoIDA = get(item, "criteria.buy_ab_get_cd.a.id", 0);
    const promoIDB = get(item, "criteria.buy_ab_get_cd.b.id", 0);
    const objUrl = find(keyPromo, function (o) {
      return o.id === promoTypeA;
    });
    const objUrlB = find(keyPromo, function (o) {
      return o.id === promoTypeB;
    });
    if (objUrl.value === objUrlB.value) {
      return [`${objUrl.value}=${promoIDA}`, `${objUrlB.value}=${promoIDB}`];
    }
    return `${objUrl.value}=${promoIDA}&${objUrlB.value}=${promoIDB}`;
  } else if (pt === PromoType_B[2]) {
    const promoType = get(item, "criteria.discount.buy.type", "");
    const promoID = get(item, "criteria.discount.buy.id", 0);
    const objUrl = find(keyPromo, function (o) {
      return o.id === promoType;
    });
    return `${objUrl.value}=${promoID}`;
  } else if (pt === PromoType_B[3]) {
    const promoType = get(item, "criteria.price.buy.type", "");
    const promoID = get(item, "criteria.price.buy.id", 0);
    const objUrl = find(keyPromo, function (o) {
      return o.id === promoType;
    });
    return `${objUrl.value}=${promoID}`;
  }
  return "";
};

export const getPromoTypeFree = (pt, item) => {
  if (pt === PromoType_B[0]) {
    const promoType = get(item, "criteria.buy_x_get_y.y.type", "");
    const promoID = get(item, "criteria.buy_x_get_y.y.id", 0);
    const objUrl = find(keyPromo, function (o) {
      // console.log(promoType, o.id);
      return o.id === promoType;
    });
    // console.log(objUrl);
    return `${objUrl.value}=${promoID}`;
  } else if (pt === PromoType_B[1]) {
    const promoTypeA = get(item, "criteria.buy_ab_get_cd.c.type", "");
    const promoTypeB = get(item, "criteria.buy_ab_get_cd.d.type", "");
    const promoIDA = get(item, "criteria.buy_ab_get_cd.c.id", 0);
    const promoIDB = get(item, "criteria.buy_ab_get_cd.d.id", 0);
    const objUrl = find(keyPromo, function (o) {
      return o.id === promoTypeA;
    });
    const objUrlB = find(keyPromo, function (o) {
      return o.id === promoTypeB;
    });
    if (objUrl.value === objUrlB.value) {
      return [`${objUrl.value}=${promoIDA}`, `${objUrlB.value}=${promoIDB}`];
    }
    return `${objUrl.value}=${promoIDA}&${objUrlB.value}=${promoIDB}`;
  }
  return "";
};

export const getTotalQtyFreeProduct = (item, totalQty, criteria) => {
  let freeTotal = 0;
  // "criteria.buy_x_get_y.moqs"
  const moqs = orderBy(get(item, criteria, []), ["moq"], ["desc"]);
  if (moqs.length > 0) {
    if (totalQty >= moqs[0].moq) {
      const myTotal = totalQty / moqs[0].buy;
      const total = Math.floor(myTotal);
      const myGet = moqs[0].get !== null ? moqs[0].get : 0;
      const newTotal = total * myGet;
      const myTotalResult =
        moqs[0].additional > 0 ? moqs[0].additional + newTotal : newTotal;
      return myTotalResult;
    } else if (moqs.length > 1 && totalQty >= moqs[1].moq) {
      const myTotal = totalQty / moqs[1].buy;
      const total = Math.floor(myTotal);
      const myGet = moqs[1].get !== null ? moqs[1].get : 0;
      const newTotal = total * myGet;
      const myTotalResult =
        moqs[1].additional > 0 ? moqs[1].additional + newTotal : newTotal;

      return myTotalResult;
    } else if (moqs.length > 2 && totalQty >= moqs[2].moq) {
      const myTotal = totalQty / moqs[2].buy;
      const total = Math.floor(myTotal);
      const myGet = moqs[2].get !== null ? moqs[2].get : 0;
      const newTotal = total * myGet;
      const myTotalResult =
        moqs[2].additional > 0 ? moqs[2].additional + newTotal : newTotal;
      return myTotalResult;
    }
  }

  return freeTotal;
};

export const getMOQIndex = (item, total, source) => {
  const moqs = orderBy(get(item, source, []), ["moq"], ["desc"]);
  if (moqs.length > 0) {
    if (moqs.length === 1) {
      if (total >= moqs[0].moq) {
        return 1;
      } else {
        return 0;
      }
    } else if (moqs.length === 2) {
      if (total >= moqs[0].moq) {
        return 2;
      } else if (total >= moqs[1].moq) {
        return 1;
      } else {
        return 0;
      }
    } else {
      if (total >= moqs[0].moq) {
        return 3;
      } else if (total >= moqs[1].moq) {
        return 2;
      } else if (total >= moqs[2].moq) {
        return 1;
      } else {
        return 0;
      }
    }
  }
  // if (moqs.length > 0) {
  //   if (total >= moqs[0].moq) {
  //     if (moqs.length === 1) {
  //       return 1;
  //     } else if (moqs.length === 2) {
  //       return 2;
  //     } else {
  //       return 3;
  //     }
  //   } else {
  //     if (moqs.length > 1) {
  //       if (total >= moqs[1].moq) {
  //         return 1;
  //       } else {
  //         if (moqs.length > 2) {
  //           if (total >= moqs[1].moq) {
  //             return 1;
  //           } else if (total >= moqs[2].moq) {
  //             return 2;
  //           } else {
  //             return 0;
  //           }
  //         } else {
  //           return 0;
  //         }
  //       }
  //     } else {
  //       return 0;
  //     }
  //   }
  //   /**
  //      *  if (moqs.length > 1 && total >= moqs[1].moq) {
  //     return 1;
  //   } else if (moqs.length > 2 && total >= moqs[2].moq) {
  //     return 2;
  //   } else {
  //     return 0;
  //   }
  //      */
  // }
  return 0;
};

export const getTotalQtyFreeProductPromo = (
  item,
  tPromoOrderQty,
  promoType
) => {
  let freeTotal = 0;
  const myPt =
    promoType === PromoType_B[0]
      ? "criteria.buy_x_get_y.moqs"
      : "criteria.buy_ab_get_cd.moqs";

  const myMoqs = get(item, myPt, []);
  const moqs = orderBy(myMoqs, ["moq"], ["desc"]);
  if (moqs.length > 0) {
    if (tPromoOrderQty >= moqs[0].moq) {
      const myTotal = tPromoOrderQty / moqs[0].buy;
      const total = Math.floor(myTotal);
      const myTotalResult =
        moqs[0].additional > 0 ? moqs[0].additional + total : total;
      return myTotalResult;
    } else if (moqs.length > 1 && tPromoOrderQty >= moqs[1].moq) {
      const myTotal = tPromoOrderQty / moqs[1].buy;
      const total = Math.floor(myTotal);
      const myTotalResult =
        moqs[1].additional > 0 ? moqs[1].additional + total : total;

      return myTotalResult;
    } else if (moqs.length > 2 && tPromoOrderQty >= moqs[2].moq) {
      const myTotal = tPromoOrderQty / moqs[2].buy;
      const total = Math.floor(myTotal);
      const myTotalResult =
        moqs[2].additional > 0 ? moqs[2].additional + total : total;
      return myTotalResult;
    }
  }

  return freeTotal;
};

export const checkPromotion = (product, promotions) => {
  const myPromotions = find(promotions, function (o) {
    const { criteria, promotion_type } = o;
    if (promotion_type === "Buy X Get Y") {
      const buyX = get(criteria, "buy_x_get_y.x.type", "");
      if (buyX === "SubProductGroup") {
        return get(criteria, "buy_x_get_y.x.id", 0) === product.subgroup_id;
      } else if (buyX === "ProductGroup") {
        return get(criteria, "buy_x_get_y.x.id", 0) === product.group_id;
      } else if (buyX === "ProductCode") {
        return get(criteria, "buy_x_get_y.x.id", "") === product.id;
      } else if (buyX === "ProductShortCode") {
        return get(criteria, "buy_x_get_y.x.id", "") === product.id;
      }
    } else if (promotion_type === "Buy AB Get CD") {
      const buyA = get(criteria, "buy_ab_get_cd.a.type", "");
      const buyB = get(criteria, "buy_ab_get_cd.b.type", "");
      if (buyA === "ProductGroup" || buyB === "ProductGroup") {
        return (
          get(criteria, "buy_ab_get_cd.a.id", 0) === product.group_id ||
          get(criteria, "buy_ab_get_cd.b.id", 0) === product.group_id
        );
      } else if (buyA === "SubProductGroup" || buyB === "SubProductGroup") {
        return (
          get(criteria, "buy_ab_get_cd.a.id", 0) === product.subgroup_id ||
          get(criteria, "buy_ab_get_cd.b.id", 0) === product.subgroup_id
        );
      } else if (buyA === "ProductCode" || buyB === "ProductCode") {
        return (
          get(criteria, "buy_ab_get_cd.a.id", "") === product.id ||
          get(criteria, "buy_ab_get_cd.b.id", "") === product.id
        );
      } else if (buyA === "ProductShortCode" || buyB === "ProductShortCode") {
        return (
          get(criteria, "buy_ab_get_cd.a.id", "") === product.id ||
          get(criteria, "buy_ab_get_cd.b.id", "") === product.id
        );
      } else if (promotion_type === "Discount") {
        const buyType = get(criteria, "discount.buy.type", "");
        if (buyType === "ProductGroup") {
          return get(criteria, "discount.buy.id", 0) === product.group_id;
        } else if (buyType === "SubProductGroup") {
          return get(criteria, "discount.buy.id", 0) === product.subgroup_id;
        } else if (buyType === "ProductCode") {
          return get(criteria, "discount.buy.id", "") === product.id;
        } else if (buyType === "ProductShortCode") {
          return get(criteria, "discount.buy.id", "") === product.id;
        }
      } else if (promotion_type === "Special Price") {
        const buyType = get(criteria, "price.buy.type", "");
        if (buyType === "ProductGroup") {
          return get(criteria, "price.buy.id", 0) === product.group_id;
        } else if (buyType === "SubProductGroup") {
          return get(criteria, "price.buy.id", 0) === product.subgroup_id;
        } else if (buyType === "ProductCode") {
          return get(criteria, "price.buy.id", "") === product.id;
        } else if (buyType === "ProductShortCode") {
          return get(criteria, "price.buy.id", "") === product.id;
        }
      }
    }
    return false;
  });
  return myPromotions && myPromotions.id ? "YES" : "NO";
};

export const useStoreTemp = create((set) => ({
  tempList: [],
  setTempList: (newState) =>
    set((state) => {
      // console.log("state", state);
      // console.log("newState,", newState);
      return { tempList: newState };
    }),
}));

export const getMinMOQ = (pt, myitem) => {
  const moqsList = getKeyMoq(pt, myitem);
  const moqsListSP = filter(moqsList, function (oi) {
    return oi.moq > 0;
  });
  const moqsSP = orderBy(moqsListSP, "moq", "asc");
  if (moqsSP.length > 0) {
    return get(moqsSP[0], "moq", 0);
  } else {
    return 0;
  }
};

export const getKeyMoq = (promoType, item) => {
  const criteria = get(item, "criteria");
  if (criteria) {
    if (promoType === "Buy AB Get CD") {
      return get(criteria, "buy_ab_get_cd.moqs");
    } else if (promoType === "Buy X Get Y") {
      return get(criteria, "buy_x_get_y.moqs", []);
    } else if (promoType === "Discount") {
      return get(criteria, "discount.moqs", []);
    } else if (promoType === "Special Price") {
      return get(criteria, "price.moqs", []);
    } else {
      return [];
    }
  }
};
