import React from "react";
import { Flex, Box, Text } from "rebass";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ProductField,
} from "../../components";
import {
  styles,
  StyledTableCell,
  StyledTableSmall,
  StyledTableRow,
  StyledTableRowLast,
  StyledTableCellRight,
} from "./styles";
//batch_on_goods_receipts
const RowListGoodsReceipt = (props) => {
  const { data, translate, classes } = props;
  let total = 0;
  return (
    <Box width={[1]}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`contenent-expand-${data.day}`}
          id={`header-expand-${data.day}`}
        >
          <Typography color={"primary"} component={"div"}>
            <Text fontSize={[0]} fontWeight={"bold"}>
              {translate("resources.po.fields.batch_on_goods_receipts")}{" "}
              {data.day}
            </Text>
          </Typography>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails>
          <Flex width={[1]} pr={[4]}>
            <TableContainer component={"div"}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow className={classes.header}>
                    <StyledTableSmall>
                      {translate("resources.po.fields.hours")}
                    </StyledTableSmall>
                    <StyledTableCell>
                      {translate("resources.po.fields.product")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {translate("resources.po.fields.code")}
                    </StyledTableCell>
                    <StyledTableCellRight>
                      {translate("resources.po.fields.ordered")}
                    </StyledTableCellRight>
                    <StyledTableCellRight>
                      {translate("resources.po.fields.received")}
                    </StyledTableCellRight>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.group &&
                    data.group.length > 0 &&
                    data.group.map((item, index) => {
                      // const { result } = item
                      total = total + item.qty;
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableSmall component="td" scope="row">
                            {moment(item.created_at).format("HH:mm")}
                          </StyledTableSmall>
                          <StyledTableCell component="td" scope="row">
                            <ProductField record={item} source="packaging" />
                          </StyledTableCell>
                          <StyledTableCell component="td" scope="row">
                            <ProductField record={item} source="code" />
                          </StyledTableCell>
                          <StyledTableCellRight component="td" scope="row">
                            <Text textAlign={"right"} pr={[2]}>
                              {item.ordered}
                            </Text>
                          </StyledTableCellRight>
                          <StyledTableCellRight component="td" scope="row">
                            <Text textAlign={"right"} pr={[2]}>
                              {item.qty}
                            </Text>
                          </StyledTableCellRight>
                        </StyledTableRow>
                      );
                    })}
                  <StyledTableRowLast>
                    <StyledTableCell colSpan={3} />
                    <StyledTableCell component="td" scope="row">
                      <Text fontWeight={"bold"} textAlign={"right"} pr={[2]}>
                        {translate("resources.po.fields.total")}
                      </Text>
                    </StyledTableCell>
                    <StyledTableCell component="td" scope="row">
                      <Text fontWeight={"bold"} textAlign={"right"} pr={[2]}>
                        {total}
                      </Text>
                    </StyledTableCell>
                  </StyledTableRowLast>
                </TableBody>
              </Table>
            </TableContainer>
          </Flex>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Box>
  );
};

export default withStyles(styles)(RowListGoodsReceipt);
