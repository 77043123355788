import React, { Fragment } from "react"
import Button from "@material-ui/core/Button"
import { Flex } from "rebass"
import { get } from "lodash"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { makeStyles } from "@material-ui/core/styles"
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "./ExpandPanel"

import { RaNumberInput, RaBooleanInput, RaTextInput } from "../"

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
    textTransform: "capitalize",
  },
}))

const AutoGenerateFields = (props) => {
  const { translate } = props
  const classes = useStyles()
  const handleClick = () => {}
  return (
    <Fragment>
      <RaNumberInput
        source={"coupon_settings.qty"}
        label={"resources.promotions.fields.qty"}
      />
      <RaTextInput
        source={"coupon_settings.coupon_prefix"}
        label={"resources.promotions.fields.coupon_prefix"}
        style={{ width: 256 }}
      />
      <RaNumberInput
        source={"coupon_settings.coupon_suffix"}
        label={"resources.promotions.fields.coupon_suffix"}
      />
      <RaNumberInput
        source={"coupon_settings.coupon_code_length"}
        label={"resources.promotions.fields.coupon_code_length"}
      />
      <Flex
        flexDirection={"row"}
        p={[2]}
        pt={[3]}
        justifyContent={"flex-start"}
      >
        <Button
          color={"primary"}
          onClick={() => handleClick()}
          className={classes.margin}
        >
          {translate("resources.buttons.generate")}
        </Button>
        <Button
          color={"primary"}
          onClick={() => handleClick()}
          className={classes.margin}
        >
          {translate("resources.buttons.view")}
        </Button>
        <Button
          color={"primary"}
          onClick={() => handleClick()}
          className={classes.margin}
        >
          {translate("resources.buttons.download_cvs")}
        </Button>
      </Flex>
    </Fragment>
  )
}
const NotAutoGenerateFields = (props) => {
  return (
    <Fragment>
      <RaTextInput
        source={"coupon_settings.coupon_code"}
        label={"resources.promotions.fields.coupon_code"}
        style={{ width: 256 }}
      />
      <RaNumberInput
        source={"coupon_settings.limit_number_of_use"}
        label={"resources.promotions.fields.limit_number_use"}
        notetext={"resources.promotions.note_text.limit_number_use"}
      />
      <RaNumberInput
        source={"coupon_settings.limit_per_customer"}
        label={"resources.promotions.fields.limit_per_user"}
        notetext={"resources.promotions.note_text.limit_per_user"}
      />
    </Fragment>
  )
}

const CouponFields = (props) => {
  const { formData } = props
  const isAuto = get(formData, "coupon.auto_generate", false)
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <RaBooleanInput
        source={"coupon.auto_generate"}
        label={"resources.promotions.fields.auto_generate"}
      />
      {isAuto ? (
        <AutoGenerateFields {...props} />
      ) : (
        <NotAutoGenerateFields {...props} />
      )}
    </Flex>
  )
}

const CouponSettings = (props) => {
  const { translate } = props

  return (
    <Flex width={[1]} flexDirection={"column"} pb={[2]}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="coupon_settings-content"
          id="coupon_settings-header"
        >
          <Typography>
            {translate("resources.promotions.panel.coupon_settings")}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <CouponFields {...props} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Flex>
  )
}

export default CouponSettings
