import React from "react"
import { Flex, Image } from "rebass"
const NoAccessPage = props => {
  return (
    <Flex
      width={[1]}
      justifyContent={"center"}
      alignItems={"center"}
      style={{ height: "70vh" }}
    >
      <Image src={require("../assets/images/logo.png")} />
    </Flex>
  )
}
export default NoAccessPage
