// import { numberdesimal } from "../utils";
import moment from "moment";
import { get } from "lodash";
export const columns = [
  { field: "country", headerName: "Country", width: 130 },
  { field: "dealer", headerName: "Dealer", width: 350 },
  { field: "promotion_type", headerName: "Promo Type", width: 200 },
  { field: "category", headerName: "Category", width: 200 },
  { field: "product_group", headerName: "Product Group", width: 200 },
  { field: "product_sub_group", headerName: "Product Sub Group", width: 200 },
  {
    field: "base_product_1",
    headerName: "Base Product 1",
    width: 200,
  },
  {
    field: "base_product_2",
    headerName: "Base Product 2",
    width: 200,
  },
  {
    field: "start_date",
    headerName: "Start",
    width: 150,
    valueGetter: (params) => {
      const startDate = get(params, "value", "");
      return startDate !== "" ? moment(startDate).format("DD/MMM/YYYY") : "";
    },
  },
  {
    field: "end_date",
    headerName: "End",
    width: 150,
    valueGetter: (params) => {
      const endDate = get(params, "value", "");
      return endDate !== "" ? moment(endDate).format("DD/MMM/YYYY") : "";
    },
  },
  { field: "free_item_1", headerName: "Free Item 1", width: 300 },
  { field: "free_item_2", headerName: "Free Item 2", width: 300 },
  { field: "moq_1", headerName: "MOQ1", width: 180, type: "number" },
  { field: "base_1", headerName: "BASE1", width: 180, type: "number" },
  { field: "foc_1", headerName: "FOC1", width: 180, type: "number" },
  {
    field: "additional_qty_1",
    headerName: "AQTY1",
    width: 180,
    type: "number",
  },
  { field: "price_1", headerName: "Price1", width: 180, type: "number" },
  {
    field: "discount_1",
    headerName: "Discount1",
    width: 180,
    type: "number",
  },
  { field: "moq_2", headerName: "MOQ2", width: 180, type: "number" },
  { field: "base_2", headerName: "BASE2", width: 180, type: "number" },
  { field: "foc_2", headerName: "FOC2", width: 180, type: "number" },
  {
    field: "additional_qty_2",
    headerName: "AQTY2",
    width: 180,
    type: "number",
  },
  { field: "price_2", headerName: "Price2", width: 180, type: "number" },
  {
    field: "discount_2",
    headerName: "Discount2",
    width: 180,
    type: "number",
  },
  { field: "moq_3", headerName: "MOQ3", width: 180, type: "number" },
  { field: "base_3", headerName: "BASE3", width: 180, type: "number" },
  { field: "foc_3", headerName: "FOC3", width: 180, type: "number" },
  {
    field: "additional_qty_3",
    headerName: "AQTY3",
    width: 180,
    type: "number",
  },
  { field: "price_3", headerName: "Price3", width: 180, type: "number" },
  {
    field: "discount_3",
    headerName: "Discount3",
    width: 180,
    type: "number",
  },
  {
    field: "po_order_qty_moq_1",
    headerName: "PO Order Qty MOQ1",
    width: 250,
    type: "number",
  },
  {
    field: "po_order_qty_moq_2",
    headerName: "PO Order Qty MOQ2",
    width: 250,
    type: "number",
  },
  {
    field: "po_order_qty_moq_3",
    headerName: "PO Order Qty MOQ3",
    width: 250,
    type: "number",
  },
  {
    field: "po_order_qty_total",
    headerName: "PO Order Qty Total",
    width: 250,
    type: "number",
  },
];
export const columnsDealer = [
  { field: "promotion_type", headerName: "Promo Type", width: 200 },
  { field: "category", headerName: "Category", width: 200 },
  { field: "product_group", headerName: "Product Group", width: 200 },
  { field: "product_sub_group", headerName: "Product Sub Group", width: 200 },
  {
    field: "base_product_1",
    headerName: "Base Product 1",
    width: 200,
  },
  {
    field: "base_product_2",
    headerName: "Base Product 2",
    width: 200,
  },
  {
    field: "start_date",
    headerName: "Start",
    width: 150,
    valueGetter: (params) => {
      const startDate = get(params, "value", "");
      return startDate !== "" ? moment(startDate).format("DD/MMM/YYYY") : "";
    },
  },
  {
    field: "end_date",
    headerName: "End",
    width: 150,
    valueGetter: (params) => {
      const endDate = get(params, "value", "");
      return endDate !== "" ? moment(endDate).format("DD/MMM/YYYY") : "";
    },
  },
  { field: "free_item_1", headerName: "Free Item 1", width: 300 },
  { field: "free_item_2", headerName: "Free Item 2", width: 300 },
  { field: "moq_1", headerName: "MOQ1", width: 180, type: "number" },
  { field: "base_1", headerName: "BASE1", width: 180, type: "number" },
  { field: "foc_1", headerName: "FOC1", width: 180, type: "number" },
  {
    field: "additional_qty_1",
    headerName: "AQTY1",
    width: 180,
    type: "number",
  },
  { field: "price_1", headerName: "Price1", width: 180, type: "number" },
  {
    field: "discount_1",
    headerName: "Discount1",
    width: 180,
    type: "number",
  },
  { field: "moq_2", headerName: "MOQ2", width: 180, type: "number" },
  { field: "base_2", headerName: "BASE2", width: 180, type: "number" },
  { field: "foc_2", headerName: "FOC2", width: 180, type: "number" },
  {
    field: "additional_qty_2",
    headerName: "AQTY2",
    width: 180,
    type: "number",
  },
  { field: "price_2", headerName: "Price2", width: 180, type: "number" },
  {
    field: "discount_2",
    headerName: "Discount2",
    width: 180,
    type: "number",
  },
  { field: "moq_3", headerName: "MOQ3", width: 180, type: "number" },
  { field: "base_3", headerName: "BASE3", width: 180, type: "number" },
  { field: "foc_3", headerName: "FOC3", width: 180, type: "number" },
  {
    field: "additional_qty_3",
    headerName: "AQTY3",
    width: 180,
    type: "number",
  },
  { field: "price_3", headerName: "Price3", width: 180, type: "number" },
  {
    field: "discount_3",
    headerName: "Discount3",
    width: 180,
    type: "number",
  },
  {
    field: "po_order_qty_moq_1",
    headerName: "PO Order Qty MOQ1",
    width: 250,
    type: "number",
  },
  {
    field: "po_order_qty_moq_2",
    headerName: "PO Order Qty MOQ2",
    width: 250,
    type: "number",
  },
  {
    field: "po_order_qty_moq_3",
    headerName: "PO Order Qty MOQ3",
    width: 250,
    type: "number",
  },
  {
    field: "po_order_qty_total",
    headerName: "PO Order Qty Total",
    width: 250,
    type: "number",
  },
];

export const columnCsv = [
  "country",
  "dealer",
  "promotion_type",
  "category",
  "product_group",
  "product_sub_group",
  "base_product_1",
  "base_product_2",
  "start_date",
  "end_date",
  "free_item_1",
  "free_item_2",
  "moq_1",
  "base_1",
  "foc_1",
  "additional_qty_1",
  "price_1",
  "discount_1",
  "moq_2",
  "base_2",
  "foc_2",
  "additional_qty_2",
  "price_2",
  "discount_2",
  "moq_3",
  "base_3",
  "foc_3",
  "additional_qty_3",
  "price_3",
  "discount_3",
  "po_order_qty_moq_1",
  "po_order_qty_moq_2",
  "po_order_qty_moq_3",
  "po_order_qty_total",
];

export const sortModelDealer = [
  {
    field: "promotion_type",
    sort: "asc",
  },
  {
    field: "category",
    sort: "asc",
  },

  {
    field: "product_group",
    sort: "asc",
  },
  {
    field: "product_sub_group",
    sort: "asc",
  },
  {
    field: "base_product_1",
    sort: "asc",
  },
  {
    field: "base_product_2",
    sort: "asc",
  },
  {
    field: "start_date",
    sort: "asc",
  },
  {
    field: "end_date",
    sort: "asc",
  },
  {
    field: "free_item_1",
    sort: "asc",
  },
  {
    field: "free_item_2",
    sort: "asc",
  },
  {
    field: "moq_1",
    sort: "asc",
  },
  {
    field: "base_1",
    sort: "asc",
  },
  {
    field: "foc_1",
    sort: "asc",
  },
  {
    field: "additional_qty_1",
    sort: "asc",
  },
  {
    field: "price_1",
    sort: "asc",
  },
  {
    field: "discount_1",
    sort: "asc",
  },
  {
    field: "moq_2",
    sort: "asc",
  },
  {
    field: "base_2",
    sort: "asc",
  },
  {
    field: "foc_2",
    sort: "asc",
  },
  {
    field: "additional_qty_2",
    sort: "asc",
  },
  {
    field: "price_2",
    sort: "asc",
  },
  {
    field: "discount_2",
    sort: "asc",
  },
  {
    field: "moq_3",
    sort: "asc",
  },
  {
    field: "base_3",
    sort: "asc",
  },
  {
    field: "foc_3",
    sort: "asc",
  },
  {
    field: "additional_qty_3",
    sort: "asc",
  },
  {
    field: "price_3",
    sort: "asc",
  },
  {
    field: "discount_3",
    sort: "asc",
  },

  {
    field: "po_order_qty_moq_1",
    sort: "asc",
  },
  {
    field: "po_order_qty_moq_2",
    sort: "asc",
  },
  {
    field: "po_order_qty_moq_3",
    sort: "asc",
  },
  {
    field: "po_order_qty_total",
    sort: "asc",
  },
];

export const sortModel = [
  {
    field: "country",
    sort: "asc",
  },
  {
    field: "dealer",
    sort: "asc",
  },
  {
    field: "promotion_type",
    sort: "asc",
  },
  {
    field: "category",
    sort: "asc",
  },

  {
    field: "product_group",
    sort: "asc",
  },
  {
    field: "product_sub_group",
    sort: "asc",
  },
  {
    field: "base_product_1",
    sort: "asc",
  },
  {
    field: "base_product_2",
    sort: "asc",
  },
  {
    field: "start_date",
    sort: "asc",
  },
  {
    field: "end_date",
    sort: "asc",
  },
  {
    field: "free_item_1",
    sort: "asc",
  },
  {
    field: "free_item_2",
    sort: "asc",
  },
  {
    field: "moq_1",
    sort: "asc",
  },
  {
    field: "base_1",
    sort: "asc",
  },
  {
    field: "foc_1",
    sort: "asc",
  },
  {
    field: "additional_qty_1",
    sort: "asc",
  },
  {
    field: "price_1",
    sort: "asc",
  },
  {
    field: "discount_1",
    sort: "asc",
  },
  {
    field: "moq_2",
    sort: "asc",
  },
  {
    field: "base_2",
    sort: "asc",
  },
  {
    field: "foc_2",
    sort: "asc",
  },
  {
    field: "additional_qty_2",
    sort: "asc",
  },
  {
    field: "price_2",
    sort: "asc",
  },
  {
    field: "discount_2",
    sort: "asc",
  },
  {
    field: "moq_3",
    sort: "asc",
  },
  {
    field: "base_3",
    sort: "asc",
  },
  {
    field: "foc_3",
    sort: "asc",
  },
  {
    field: "additional_qty_3",
    sort: "asc",
  },
  {
    field: "price_3",
    sort: "asc",
  },
  {
    field: "discount_3",
    sort: "asc",
  },

  {
    field: "po_order_qty_moq_1",
    sort: "asc",
  },
  {
    field: "po_order_qty_moq_2",
    sort: "asc",
  },
  {
    field: "po_order_qty_moq_3",
    sort: "asc",
  },
  {
    field: "po_order_qty_total",
    sort: "asc",
  },
];

export { default as HeaderFilters } from "./HeaderFilters";
export { default as ListFilters } from "./ListFilters";
