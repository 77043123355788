export default {
  sellout: {
    title: {
      sellout: "Sellout",
      new_sellout: "New Sellout",
      edit_sellout: "Edit Sellout",
    },
    fields: {
      name: "Name",
      type: "Type",
      start: "Start",
      end: "End",
      active: "Active",
      ref_customer: "Customer/Reference",
      date: "Date",
      barcode: "Barcode",
      global_code: "Global Code",
      qty: "Qty",
      product_packaging: "Product Packaging",
      product_code: "Product Code",
      expiry: "Expiry",
      items: "Items",
      total: "Total",
      status: "Status",
    },
    message: {
      items_zero: "Please check your List",
      list_item_zero: "Please add Quantity",
      more_than_zero: "Item must be > 0",
      reject_product:
        "Product pricing is not setup. Please contact your local country manager or sales.asia@gc.dental for assistance.",
      product_inactive: "Product Inactive",
    },
  },
};
