import React from "react";
import { Box, Flex, Text } from "rebass";
import { get } from "lodash";
import { useQueryWithStore } from "react-admin";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import NumberFormat from "react-number-format";
import { EditIcon } from "../../components/icon_svg";

import {
  styles,
  StyledTableAction,
  StyledTableCell,
  StyledTableRow,
  StyledTableSmall,
  StyledTableRowLast,
} from "./styles";
import { PromotionField } from "../../po";
import ProductField from "./ProductField";

const OrderItemsShow = (props) => {
  const { record, translate, classes } = props;
  const { data } = useQueryWithStore({
    type: "getList",
    resource: "orderItems",
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "order_id", order: "ASC" },
      filter: { order_id: record.id },
    },
  });
  let total = 0;
  return (
    <Box width={[1]} p={[3]} pl={[1]} pr={[1]}>
      <Paper elevation={3} style={{ minHeight: 250 }}>
        <Box width={[1]} p={[3]}>
          <Text color={"green"} pb={[3]}>
            {translate("resources.orders.fields.order_items")}
          </Text>

          <TableContainer component={"div"}>
            <Table className={classes.table} aria-label='customized table'>
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    {translate("resources.orders.fields.product")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {translate("resources.orders.fields.code")}
                  </StyledTableCell>
                  <StyledTableSmall>
                    {translate("resources.orders.fields.qty")}
                  </StyledTableSmall>
                  <StyledTableSmall>
                    {translate("resources.orders.fields.price")}
                  </StyledTableSmall>
                  <StyledTableSmall>
                    {translate("resources.orders.fields.subtotal")}
                  </StyledTableSmall>
                  <StyledTableAction />
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.length > 0 &&
                  data.map((item, index) => {
                    total = total + item.subtotal * 1;
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell component='td' scope='row'>
                          <Box>
                            <ProductField record={item} source='packaging' />
                            <PromotionField
                              record={item}
                              source='name'
                              resource_id='product_id'
                            />
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell component='td' scope='row'>
                          <ProductField record={item} source='code' />
                        </StyledTableCell>
                        <StyledTableSmall component='td' scope='row'>
                          <Text>{item.qty}</Text>
                        </StyledTableSmall>
                        <StyledTableSmall component='td' scope='row'>
                          <Text textAlign={"right"}>
                            <NumberFormat
                              value={get(item, "price", 0)}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={2}
                            />
                          </Text>
                        </StyledTableSmall>
                        <StyledTableSmall component='td' scope='row'>
                          <Text textAlign={"right"}>
                            <NumberFormat
                              value={get(item, "subtotal", 0)}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={2}
                            />
                          </Text>
                        </StyledTableSmall>
                        <StyledTableAction component='td' scope='row'>
                          <IconButton>
                            <EditIcon fontSize='small' />
                          </IconButton>
                        </StyledTableAction>
                      </StyledTableRow>
                    );
                  })}
                <StyledTableRowLast>
                  <StyledTableCell colSpan={5}>
                    <Flex flexDirection={"row"} justifyContent={"flex-end"}>
                      <Text fontWeight={"bold"} pr={[2]}>
                        {translate("resources.orders.fields.total")}
                      </Text>
                      <Text
                        fontWeight={"bold"}
                        width={"100px"}
                        textAlign={"right"}
                      >
                        <NumberFormat
                          value={total}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={2}
                        />
                      </Text>
                    </Flex>
                  </StyledTableCell>
                  <StyledTableAction />
                </StyledTableRowLast>
              </TableBody>
            </Table>
          </TableContainer>
          <Box width={[1]} pt={[4]} pb={[2]}>
            <Text fontWeight={"bold"} fontSize={[0]} pb={[2]}>
              {translate("resources.products.fields.remarks")}
            </Text>
            <Flex
              width={[1]}
              p={[2]}
              style={{
                minHeight: 100,
              }}
              flexWrap={"wrap"}
            >
              <ProductField
                record={record}
                source='remarks'
                defaultValue={
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                }
              />
            </Flex>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
export default withStyles(styles)(OrderItemsShow);
