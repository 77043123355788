import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
// import { useHistory } from "react-router-dom"
import { styles } from "./styles";
import SubMenuItem from "./SubMenuItem";
import MenuItemLink from "../MenuItemLink";
import { User1Icon, ChartIcon, DocumentIcon, UploadIcon } from "../../icon_svg";
// import { MenuShow } from "./utils"

const MenuDealers = (props) => {
  const { classes, onMenuClick } = props;
  const [openMenu, setOpenMenu] = useState(false);
  const translate = useTranslate();

  return (
    <SubMenuItem
      handleToggle={() => setOpenMenu(!openMenu)}
      isOpen={openMenu}
      classes={classes}
      sidebarIsOpen={openMenu}
      name='resources.menus.dealers'
      icon={<User1Icon />}
    >
      <MenuItemLink
        to={`/dealersTarget`}
        primaryText={translate(`resources.menus.targets`, {
          smart_count: 2,
        })}
        onClick={onMenuClick}
        leftIcon={<ChartIcon />}
        translate={translate}
      />
      <MenuItemLink
        to={`/inventories`}
        primaryText={translate(`resources.menus.inventory`, {
          smart_count: 2,
        })}
        leftIcon={<DocumentIcon />}
        onClick={onMenuClick}
        translate={translate}
      />
      <MenuItemLink
        to={`/sellout`}
        primaryText={translate(`resources.menus.sellout`, {
          smart_count: 2,
        })}
        leftIcon={<UploadIcon />}
        onClick={onMenuClick}
        translate={translate}
      />
    </SubMenuItem>
  );
};

export default withStyles(styles)(MenuDealers);
