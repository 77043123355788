import React from "react";
import { get, orderBy, filter } from "lodash";
import { Flex, Box, Text, Image } from "rebass";

import {
  ContainerMod,
  ContainerModDisc,
  ContainerModPrice,
  BoxStartEndDate,
} from "./ListItemPromotion";
import { PromoType_B } from "./utils";

export const ContainerItemField = (props) => {
  const { item } = props;

  const moqsListBXGY = filter(
    get(item, "criteria.buy_x_get_y.moqs", []),
    function (oi) {
      return oi.moq > 0;
    }
  );
  const moqsBXGY = orderBy(moqsListBXGY, ["moq"], ["desc"]);

  const moqsListBABGCD = filter(
    get(item, "criteria.buy_ab_get_cd.moqs", []),
    function (oi) {
      return oi.moq > 0;
    }
  );
  const moqsBABGCD = orderBy(moqsListBABGCD, "moq", "desc");
  const moqsListDiscount = filter(
    get(item, "criteria.discount.moqs", []),
    function (oi) {
      return oi.moq > 0;
    }
  );
  const moqsDiscount = orderBy(moqsListDiscount, "moq", "desc");

  const moqsListSP = filter(
    get(item, "criteria.price.moqs", []),
    function (oi) {
      return oi.moq > 0;
    }
  );
  const moqsSP = orderBy(moqsListSP, "moq", "desc");
  const photos =
    get(item, "photos", []) !== null ? get(item, "photos", []) : [];
  return (
    <Box width={[1]}>
      <Flex width={[1]} flexDirection={"row"}>
        <Box p={[1]}>
          <Image
            p={[1]}
            style={{
              border: "1px solid #EFEFEF",
              width: 200,
              height: "auto",
            }}
            src={photos.length > 0 ? photos[0] : ""}
          />
        </Box>

        <Box width={[1]} p={[1]}>
          <Text>{item.name}</Text>
          {/* <Text fontSize={[0]} py={[2]}>
            {item.promotion_type}
          </Text> */}
          {item.promotion_type === PromoType_B[0] && (
            <Box>
              <ContainerMod list={moqsBXGY} />
            </Box>
          )}
          {item.promotion_type === PromoType_B[1] && (
            <Box>
              <ContainerMod list={moqsBABGCD} />
            </Box>
          )}
          {item.promotion_type === PromoType_B[2] && (
            <Box>
              <ContainerModDisc list={moqsDiscount} />
            </Box>
          )}
          {item.promotion_type === PromoType_B[3] && (
            <Box>
              <ContainerModPrice list={moqsSP} />
            </Box>
          )}
        </Box>
      </Flex>
      <BoxStartEndDate {...props} />
    </Box>
  );
};

export const ContainerItem = (props) => {
  const { item, onShow } = props;

  const moqsListBXGY = filter(
    get(item, "criteria.buy_x_get_y.moqs", []),
    function (oi) {
      return oi.moq > 0;
    }
  );
  const moqsBXGY = orderBy(moqsListBXGY, ["moq"], ["desc"]);

  const moqsListBABGCD = filter(
    get(item, "criteria.buy_ab_get_cd.moqs", []),
    function (oi) {
      return oi.moq > 0;
    }
  );
  const moqsBABGCD = orderBy(moqsListBABGCD, "moq", "desc");
  const moqsListDiscount = filter(
    get(item, "criteria.discount.moqs", []),
    function (oi) {
      return oi.moq > 0;
    }
  );
  const moqsDiscount = orderBy(moqsListDiscount, "moq", "desc");

  const moqsListSP = filter(
    get(item, "criteria.price.moqs", []),
    function (oi) {
      return oi.moq > 0;
    }
  );
  const moqsSP = orderBy(moqsListSP, "moq", "desc");
  const photos = get(item, "photos", []);
  return (
    <Box width={[1, 1 / 2]} p={[1]}>
      <Box
        width={[1]}
        p={[2]}
        style={{ border: "1px solid #EFEFEF" }}
        sx={{
          "&:hover": {
            backgroundColor: "rgba(158 ,158, 158,0.2)",
            cursor: "pointer",
          },
        }}
        onClick={() => onShow()}
      >
        <Flex width={[1]} flexDirection={"row"}>
          <Box p={[1]}>
            <Image
              p={[1]}
              style={{
                border: "1px solid #EFEFEF",
                width: 150,
                height: "auto",
              }}
              src={photos.length > 0 ? photos[0] : ""}
            />
          </Box>

          <Box width={[1]} p={[1]}>
            <Text>{item.name}</Text>
            {/* <Text fontSize={[0]} py={[2]}>
              {item.promotion_type}
            </Text> */}
            {item.promotion_type === PromoType_B[0] && (
              <Box>
                <ContainerMod list={moqsBXGY} />
              </Box>
            )}
            {item.promotion_type === PromoType_B[1] && (
              <Box>
                <ContainerMod list={moqsBABGCD} />
              </Box>
            )}
            {item.promotion_type === PromoType_B[2] && (
              <Box>
                <ContainerModDisc list={moqsDiscount} />
              </Box>
            )}
            {item.promotion_type === PromoType_B[3] && (
              <Box>
                <ContainerModPrice list={moqsSP} />
              </Box>
            )}
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
