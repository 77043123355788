export default {
  promotions: {
    title: {
      new_product: "Buat Promosi Dealer",
      edit_product: "Ubah Promosi Dealer",
      new_cart: "Buat Promosi Pelanggan",
      edit_cart: "Ubah Promosi Pelanggan",
      new_promotion: "Buat Promosi",
      edit_promotion: "Ubah Promosi",
      promotions: "Promotion",
    },
    tabs: {
      product_rules: "Peraturan Dealer",
      cart_rules: "Aturan Pelanggan",
    },
    panel: {
      criteria: "Kriteria",
      action: "Tindakan",
      coupon_settings: "Pengaturan Kupon",
      promotion_settings: "Pengaturan Promosi",
      promotion_product: "Produk Promosi",
      details: "Detail",
      order_items: "Item Order",
      summary: "Ringkasan",
      promotion_items: "Item Promosi",
      free_items: "Gratis item",
      target_summary: "Target Summary",
    },
    labels: {
      criteria_type_x: "Criteria X Type",
      criteria_type_y: "Criteria Y Type",
      criteria_x: "Criteria X",
      criteria_y: "Criteria Y",
      buy_a_or_b: "Buy A or B",
      get_c_or_d: "Get C or D",
      criteria_type_a: "Criteria A Type",
      criteria_type_b: "Criteria B Type",
      criteria_a: "Criteria A",
      criteria_b: "Criteria B",
      criteria_type_c: "Criteria C Type",
      criteria_type_d: "Criteria D Type",
      criteria_c: "Criteria C",
      criteria_d: "Criteria D",
      criteria: "Criteria",
      criteria_type: "CriteriaType",
    },
    fields: {
      type: "Type",
      country: "Negara",
      name: "Nama",
      site_id: "Situs",
      active: "Aktif",
      start: "Awal",
      end: "Akhir",
      start_date: "Tanggal Awal",
      end_date: "Tanggal Akhir",
      criteria: "Kriteria",
      criteria_type: "Tipe Kriteria",
      coupon: "Kupon",
      warehouse: "Gudang",
      collection: "Koleksi",
      collections: "Koleksi",
      apply_lower_priority:
        "Terapkan Promosi Lain Dengan Prioritas Lebih Rendah",
      promotion_amount: "Total Promosi",
      promotion_type: "Tipe Promosi",
      need_coupon: "Butuh Kode Kupon Untuk Menggunakan Promosi",
      priority: "Prioritas",
      buy_x: "Beli X",
      get_y: "Dapat Y",
      max_qty_y: "Kuantitas Maksimum Gratis Y",
      auto_generate: "Auto Generate",
      qty: "Kuantitas",
      coupon_prefix: "Awalan Kupon",
      coupon_suffix: "Sufiks Kupon",
      coupon_code_length: "Panjang Kode Kupon",
      coupon_code: "Kode Kupon",
      limit_number_use: "Batasi Jumlah Penggunaan",
      limit_per_user: "Batasi Per Pengguna",
      subtotal: "Subtotal",
      weight: "Berat",
      payment_method: "Cara Pembayaran",
      postal_code: "Kode Pos",
      shipping_region: "Wilayah Pengiriman",
      shipping_state: "Negara Pengiriman",
      products: "Produk",
      cart: "Keranjang",
      categories: "Kategori",
      attribute: "Atribut",
      operator: "Operator",
      discount_type: "Jenis Diskon",
      percent: "Persen",
      amount: "Total",
      custom_settings: "Pengaturan Khusus",
      buy: "Beli",
      get: "Dapat",
      discount: "Diskon",
      h_qty: "Qty",
      next_purchase: "Pembelian berikutnya",
      product: "Produk",
      apply_other_promotions: "Terapan pada promo lainnya",
      max_qty: "Jumlah Maksimal",
      groups: "Groups",
      moq: "MOQ",
      additional: "Additional",
      price: "Price",
      remarks: "Remarks",
      photo: "Photo",
      approval_comment: "Approval Comment",
      status: "Status",
      dealers: "Dealers",
    },
    note_text: {
      priority: "Prioritas Promosi yang cocok untuk diterapkan terlebih dahulu",
      percent:
        "Masukkan 20 untuk 20% untuk Jenis Persen atau 20 untuk $ 20 Jenis Total",
      amount:
        "Masukkan 20 untuk 20% untuk Jenis Persen atau 20 untuk $ 20 Jenis Total",
      get_y:
        "Masukkan 20 untuk 20% untuk Jenis Persen atau 20 untuk $ 20 Jenis Total, 1 gratis 1 untuk Beli X Dapatkan Y",
      buy_x:
        "Langkah kuantitas diskon. Misalnya, masukkan 3 untuk Beli 3 Dapatkan Y juga berarti Beli 6 Dapatkan 2x Y",
      max_qty_y: "Misalnya, masukkan 5 berarti maksimum 5xY dapat diberikan",
      limit_number_use:
        "Jumlah maksimum penukaran Masukkan 0 jika tidak ada batasan",
      limit_per_user: "Masukkan 0 jika tidak ada batasan",
    },
  },
};
