export const dataType = [
  {
    id: "Dealer",
    name: "Dealer",
  },
  {
    id: "Customer",
    name: "Customer",
  },
];
export const dataCriteriaType = [
  {
    id: "Product",
    name: "Product",
  },
  {
    id: "Collection",
    name: "Collection",
  },
  {
    id: "Category",
    name: "Category",
  },
  {
    id: "Cart",
    name: "Cart",
  },
];
export const dataDiscountType = [
  {
    id: "Percent",
    name: "Percent",
  },
  {
    id: "Amount",
    name: "Amount",
  },
];

export const dataOperator = [
  {
    id: "contains",
    name: "Contains",
  },
  {
    id: "ncontains",
    name: "Doesn’t contain",
  },
];

export const dataPromotionTypeCart = [
  {
    id: "Discount on Product",
    name: "Discount on Product",
  },
  {
    id: "Discount on Cart",
    name: "Discount on Cart",
  },
  {
    id: "Buy X Get Y",
    name: "Buy X Get Y",
  },
  {
    id: "Shipping Cost",
    name: "Shipping Cost",
  },
  {
    id: "Next Item Of Same Category",
    name: "Next Item Of Same Category",
  },
];

export const dataAttributes = [
  {
    id: "Subtotal",
    name: "Subtotal",
  },
  {
    id: "Total Item Quantity",
    name: "Total Item Quantity",
  },
  {
    id: "Payment Method",
    name: "Payment Method",
  },
  {
    id: "Shipping Weight",
    name: "Shipping Weight",
  },
  {
    id: "Shipping Postal Code",
    name: "Shipping Postal Code",
  },
  {
    id: "Shipping Region",
    name: "Shipping Region",
  },
  {
    id: "Shipping State",
    name: "Shipping State",
  },
];

export const dataPromotionProduct = [
  {
    id: "Product",
    name: "Product",
  },
  {
    id: "Category",
    name: "Category",
  },
  {
    id: "Group",
    name: "Group",
  },
];
export const dataPromotionSetting = [
  {
    id: "Buy X Get Y",
    name: "Buy X Get Y",
  },
  {
    id: "Buy AB Get CD",
    name: "Buy AB Get CD",
  },
  {
    id: "Discount",
    name: "Discount",
  },
  {
    id: "Special Price",
    name: "Special Price",
  },
];

export const dataTypeBuyModeDiscount = [
  {
    id: "Percent",
    name: "Percent",
  },
  {
    id: "Amount",
    name: "Amount",
  },
];

export const dataMOQDiscount = [
  {
    moq: 0,
    discount: 0,
  },
  {
    moq: 0,
    discount: 0,
  },
  {
    moq: 0,
    discount: 0,
  },
];
export const dataMOQPrice = [
  {
    moq: 0,
    price: 0,
  },
  {
    moq: 0,
    price: 0,
  },
  {
    moq: 0,
    price: 0,
  },
];

export const dataMOQBuy = [
  {
    moq: 0,
    buy: 0,
    get: 0,
    additional: 0,
  },
  {
    moq: 0,
    buy: 0,
    get: 0,
    additional: 0,
  },
  {
    moq: 0,
    buy: 0,
    get: 0,
    additional: 0,
  },
];

/**
 * ,
 */
