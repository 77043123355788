//
import React from "react";
import { Flex, Box } from "rebass";
import { get } from "lodash";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DealerItemField from "./DealerItemField";
// import IconButton from "@material-ui/core/IconButton";
// import { TrashPOIcon } from "../../components/icon_svg";
import { styles, StyledTableCell, StyledTableRow } from "./styles";
//StyledTableAction,
const RowGCDealerTarget = (props) => {
  const { data, translate, classes } = props;
  //dataProvider, onRefresh
  //   const onDelete = (item) => {
  //     console.log(item);
  //     if (item && item.id) {
  //       dataProvider
  //         .delete("dealerTargets", {
  //           id: item.id,
  //           previousData: item,
  //         })
  //         .then(({ data }) => {
  //           console.log(data);
  //           onRefresh();
  //         })
  //         .catch((error) => {});
  //     }
  //   };
  return (
    <Box width={[1]}>
      <Flex width={[1]}>
        <TableContainer component={"div"}>
          <Table className={classes.table} aria-label='customized table'>
            <TableHead>
              <TableRow className={classes.header}>
                <StyledTableCell>
                  {translate("resources.targets.fields.country")}
                </StyledTableCell>
                <StyledTableCell>
                  {translate("resources.targets.fields.dealer")}
                </StyledTableCell>
                <StyledTableCell>
                  {translate("resources.targets.fields.upload_on")}
                </StyledTableCell>
                {/* <StyledTableCell> </StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 &&
                data.map((item, index) => {
                  const upload_date = get(item, "created_at", "");
                  return (
                    <StyledTableRow key={index}>
                      <DealerItemField item={item} />
                      <StyledTableCell>
                        {upload_date !== ""
                          ? moment(upload_date).format("DD-MMM-YYYY")
                          : ""}
                      </StyledTableCell>
                      {/* <StyledTableAction>
                        <IconButton onClick={() => onDelete(item)} size='small'>
                          <TrashPOIcon fontSize='inherit' />
                        </IconButton>
                      </StyledTableAction> */}
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Flex>
    </Box>
  );
};

export default withStyles(styles)(RowGCDealerTarget);
