export default {
  pricebook: {
    title: "Price Books",
    new_pricebook: "New Price Book",
    edit_pricebook: "Edit Price Book",
    show_pricebook: "Products of ",
    tabs: {
      customer: "Customer",
      dealer: "Dealer",
    },
    fields: {
      name: "Name",
      created_at: "Created On",
      active: "Active",
      site_id: "Site",
      start_at: "Start Date",
      end_at: "End Date",
      country: "Country",
      type: "Type",
      product_code: "Product Code",
      qty: "Qty",
      line_number: "Line Number",
      price: "Price",
      product_packaging: "Product Packaging",
    },
    messages: {
      please_select: "Please Select Country and fill Name",
      success: "Upload Success",
      message_failed: "Upload Failed",
    },
  },
};
