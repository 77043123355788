import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { sodSchema } from "./schemaList";
const SODList = (props) => {
  const { list } = props;
  const state = {
    columnDefs: sodSchema,
    defaultColDef: {
      flex: 1,
      editable: false,
      sortable: true,
      filter: true,
      minWidth: 100,
      // cellStyle: {
      //   "line-height": "40px",
      // },
    },
    getRowNodeId: function (data) {
      return data.customer_reference;
    },
  };
  return (
    <div className='grid-wrapper'>
      <div
        id='myGridSODList'
        className='ag-theme-balham'
        style={{ height: "50vh", width: "100%" }}
      >
        <AgGridReact
          rowHeight={40}
          headerHeight={40}
          rowData={list}
          columnDefs={state.columnDefs}
          defaultColDef={state.defaultColDef}
          animateRows={true}
        />
      </div>
    </div>
  );
};

export default SODList;
