import React from "react";
import PropTypes from "prop-types";
import { ListIcon } from "../../../components/icon_svg";
import { useLocation } from "react-router-dom";
import Button from "../../../components/buttons/rabutton/Button";
import { get } from "lodash";
const ListButton = ({
  basepath = "",
  label = "ra.action.list",
  icon,
  ...rest
}) => {
  const myLocation = useLocation();

  const onClickBack = () => {
    let event = window.confirm("Are you sure want to leave this page?");
    if (event) {
      rest.history.push(`/orders${get(myLocation, "search", "")}`);
    }
  };
  return (
    <Button
      onClick={onClickBack}
      to={basepath}
      label={label}
      {...rest}
      sizeIcon={"xsmall"}
      style={{ textTransform: "capitalize" }}
    >
      {icon}
    </Button>
  );
};

ListButton.propTypes = {
  basepath: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.element,
};

ListButton.defaultProps = {
  icon: <ListIcon />,
};

export default ListButton;
