export default {
  invoiced: {
    title: {
      invocied: "Invoiced",
      transactions: "Invoiced Transactions",
    },
    table: {
      line_number: "Line Number",
      dealer_code: "Dealer Code",
      dealer_name: "Dealer Name",
      product_code: "Product Code",
      product_packaging: "Product Packaging",
      qty: "Qty",
      sales: "Sales",
      discount: "Discount",
      sales_after_discount: "Sales After Discount",
      date: "Date",
      invoice_no: "Invoice No",
      country: "Country",
      item_class_id: "Item Class Id",
    },
    fields: {
      name: "Name",
      type: "Type",
      start: "Start",
      end: "End",
      active: "Active",
      batchId: "Batch ID",
      upload_date: "Tanggal Upload",
      totalItems: "Total Item",
    },
    messages: {
      invoice_delete: "Item sukses dihapus",
      try_again: "Please try again",
    },
  },
};
