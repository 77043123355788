import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
import { styles } from "./styles";
import MenuItemLink from "../MenuItemLink";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";

const MenuReport = (props) => {
  const { onMenuClick } = props;

  const translate = useTranslate();
  return (
    <Fragment>
      <MenuItemLink
        to={`/report`}
        primaryText={translate(`resources.menus.reports`, {
          smart_count: 2,
        })}
        leftIcon={<DescriptionOutlinedIcon />}
        onClick={onMenuClick}
        translate={translate}
      />
    </Fragment>
  );
};

export default withStyles(styles)(MenuReport);
