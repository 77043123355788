import React, { Fragment } from "react";
import { Box } from "rebass";
import { get, sum } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import NumberFormat from "react-number-format";
import {
  styles,
  StyledTableCell,
  StyledTableRow,
  StyledTableSmall,
} from "./styles";
import { useWindowSize } from "../../components";
import DealerItemField from "./DealerItemField";
import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);

const CellHeaderMonth = (props) => {
  const { fy } = props;
  const currentYear = moment().year(fy);
  const startMonth = moment(currentYear).month(3);
  const endMonth = moment(startMonth).add(11, "month");
  const range = moment.range(startMonth, endMonth);
  const arrayMonth = Array.from(range.by("month"));
  return (
    <Fragment>
      {arrayMonth.map((m, index) => {
        return (
          <StyledTableSmall key={index} style={{ minWidth: 100 }}>
            {m.format("MMM")} {m.format("YY")}
          </StyledTableSmall>
        );
      })}
    </Fragment>
  );
};
const CellBodyMonth = (props) => {
  const { child } = props;
  if (child && child.length > 0) {
    return child.map((m, indexBodyMonth) => {
      return (
        <StyledTableSmall key={indexBodyMonth} style={{ minWidth: 100 }}>
          <NumberFormat
            value={m}
            displayType={"text"}
            thousandSeparator={true}
            prefix={""}
          />
        </StyledTableSmall>
      );
    });
  }
  return <StyledTableSmall />;
};

const RowCountryPhasing = (props) => {
  const windowSize = useWindowSize();
  const { data, translate, classes } = props;
  // const listTarget = get(data, "targets", []);
  // console.log(data);
  // console.log(data);
  return (
    <Box width={[1, `${windowSize.width - 270}px`]}>
      <TableContainer component={"div"} className={classes.container}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow className={classes.header}>
              <StyledTableCell style={{ minWidth: 200 }}>
                {translate("resources.targets.fields.country")}
              </StyledTableCell>
              <StyledTableCell style={{ minWidth: 200 }}>
                {translate("resources.targets.fields.dealer")}
              </StyledTableCell>
              <StyledTableCell style={{ minWidth: 200 }}>
                {translate("resources.targets.fields.target")}
              </StyledTableCell>
              <CellHeaderMonth {...props} />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 &&
              data.map((dealerTarget, index) => {
                const listTarget = get(dealerTarget, "targets", []);
                if (
                  listTarget &&
                  listTarget !== null &&
                  listTarget.length > 0
                ) {
                  return (
                    <Fragment key={index}>
                      {listTarget.map((itemChild, indexTarget) => {
                        const monthyTarget = get(
                          itemChild,
                          "monthly_targets",
                          []
                        );
                        const totalTarget = sum(monthyTarget);

                        return (
                          <StyledTableRow key={indexTarget}>
                            <DealerItemField item={itemChild} />
                            <StyledTableCell component="td" scope="row">
                              <NumberFormat
                                value={totalTarget}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={""}
                              />
                            </StyledTableCell>
                            <CellBodyMonth child={monthyTarget} />
                          </StyledTableRow>
                        );
                      })}
                    </Fragment>
                  );
                } else {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell />
                    </StyledTableRow>
                  );
                }
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default withStyles(styles)(RowCountryPhasing);
