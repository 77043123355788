import React, { useState } from "react";
import { Flex, Box } from "rebass";
import { connect } from "react-redux";
import { get } from "lodash";
import {
  List,
  useTranslate,
  TextInput,
  Filter,
  useQueryWithStore,
  Loading,
} from "react-admin";
import { useStyles } from "../components/utils";
import { Title, ListButton, Pagination } from "../components";
import { AgGrid } from "./components";
import queryString from "query-string";

const PriceBookFilter = (props) => {
  const translate = useTranslate();
  const { name_price_book } = props;
  return (
    <Box width={[1]} pb={[2]}>
      <Box width={[1]} pt={[3]}>
        <Title fontWeight={"bold"} fontSize={[3]}>
          {translate(`resources.pricebook.show_pricebook`)}{" "}
          {name_price_book ? name_price_book : ""}
        </Title>
      </Box>
      <Flex width={[1]} flexDirection={"row"} justifyContent={"flex-end"}>
        <Filter {...props}>
          <TextInput
            label='Search'
            source='search'
            alwaysOn
            style={{ width: 256 }}
          />
        </Filter>
      </Flex>
    </Box>
  );
};

const PriceBookAction = (props) => {
  return (
    <Flex
      pt={[3]}
      pl={[2]}
      pr={[2]}
      flexDirection={"row"}
      style={{ position: "fixed", right: 16 }}
    >
      <Flex flexDirection={"row"} justifyContent={"flex-end"}>
        <ListButton
          style={{ textTransform: "capitalize" }}
          basepath={"/priceBooks"}
        />
      </Flex>
    </Flex>
  );
};

const PriceBookShow = (props) => {
  const { location } = props;
  const parsed = queryString.parse(location.search);
  const classes = useStyles();

  const translate = useTranslate();
  const [sortField, setSortField] = useState(get(parsed, "sort", "packaging"));
  const [ordered, setOrdered] = useState(get(parsed, "order", "ASC"));
  const { data, loaded } = useQueryWithStore({
    type: "getOne",
    resource: "priceBooks",
    payload: { id: props.id ? props.id : 0 },
  });
  if (!loaded) {
    return <Loading />;
  }
  return (
    <List
      filter={{ price_book_id: props.id }}
      classes={classes}
      bulkActionButtons={false}
      filters={<PriceBookFilter name_price_book={get(data, "name", "")} />}
      actions={<PriceBookAction />}
      {...props}
      title={translate("resources.pricebook.show_pricebook")}
      perPage={25}
      pagination={<Pagination />}
      sort={{ field: sortField, order: ordered }}
    >
      <AgGrid
        {...props}
        sortField={sortField}
        setSortField={setSortField}
        ordered={ordered}
        setOrdered={setOrdered}
      />
    </List>
  );
};

function mapStateToProps(state) {
  return {
    resource: "priceBookDetails",
    options: { label: "" },
    hasList: true,
    hasEdit: true,
    hasShow: false,
    hasCreate: false,
  };
}

export default connect(mapStateToProps)(PriceBookShow);
