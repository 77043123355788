import React, { useState } from "react";
import {
  Button,
  SaveButton,
  useUpdate,
  useNotify,
  FormWithRedirect,
  useTranslate,
  useRefresh,
  useRedirect,
} from "react-admin";
import IconButton from "@material-ui/core/IconButton";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormFieldSettings from "../FormFieldSettings";
import { EditIcon } from "../../../components/icon_svg";

function EditSettings(props) {
  const { record } = props;
  const redirect = useRedirect();
  const refresh = useRefresh();
  const translate = useTranslate();
  const [showDialog, setShowDialog] = useState(false);
  const [update, { loading }] = useUpdate("codes");

  const notify = useNotify();
  // const form = useForm()

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    update(
      { payload: { id: record.id, data: values } },
      {
        onSuccess: ({ data }) => {
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          // form.change("id", data.id)
          setShowDialog(false);
          redirect("/codes");
          notify("resources.notification.updated");
          refresh();
        },
        onFailure: ({ error }) => {
          notify(error.message, "error");
        },
      }
    );
  };

  return (
    <>
      <IconButton onClick={handleClick} size="small" color={"primary"}>
        <EditIcon size="small" style={{ width: "1rem", height: "1rem" }} />
      </IconButton>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate("resources.settings.labels.edit_code")}
      >
        <DialogTitle>
          {translate("resources.settings.labels.edit_code")}
        </DialogTitle>

        <FormWithRedirect
          resource="codes"
          initialValues={record}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <FormFieldSettings {...props} />
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default EditSettings;
