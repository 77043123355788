import React from "react";
import { Flex, Box, Text } from "rebass";
import Paper from "@material-ui/core/Paper";
import { SelectInput } from "react-admin";
import { filter, orderBy } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
const dataExisting = [
  { id: "New", name: "New" },
  { id: "Existing", name: "Existing" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
    },
  },
}));

const Classifications = (props) => {
  const { translate } = props;
  const classes = useStyles();
  const listClass1A = filter(props.listcode, function (o) {
    return o.type === "Class1";
  });
  const listClass2A = filter(props.listcode, function (o) {
    return o.type === "Class2";
  });
  const listClass3A = filter(props.listcode, function (o) {
    return o.type === "Class3";
  });
  const listCategoryA = filter(props.listcode, function (o) {
    return o.type === "Category";
  });
  const listTargetA = filter(props.listcode, function (o) {
    return o.type === "TargetSetting";
  });
  const listClass1 = orderBy(listClass1A, ["label"], ["asc"]);
  const listClass2 = orderBy(listClass2A, ["label"], ["asc"]);
  const listClass3 = orderBy(listClass3A, ["label"], ["asc"]);
  const listCategory = orderBy(listCategoryA, ["label"], ["asc"]);
  const listTarget = orderBy(listTargetA, ["label"], ["asc"]);

  return (
    <Box width={[1]} pt={[3]}>
      <Paper elevation={3}>
        <Text pl={[3]} pt={[3]} fontWeight={"bold"} fontSize={[0]}>
          {translate("resources.products.fields.classifications")}
        </Text>
        <Flex
          width={[1]}
          flexDirection={"row"}
          flexWrap={"wrap"}
          justifyContent={"flex-start"}
          p={[3]}
          pl={[0]}
          pr={[0]}
          pt={[1]}
          className={classes.root}
        >
          <SelectInput
            label='resources.products.fields.class_1'
            source='class1_id'
            choices={listClass1}
            optionText={"label"}
            style={{ minWidth: 280 }}
          />
          <SelectInput
            label='resources.products.fields.class_2'
            source='class2_id'
            choices={listClass2}
            optionText={"label"}
            style={{ minWidth: 280 }}
          />
          <SelectInput
            label='resources.products.fields.class_3'
            source='class3_id'
            choices={listClass3}
            optionText={"label"}
            style={{ minWidth: 280 }}
          />
          <SelectInput
            label='resources.products.fields.category'
            source='category_id'
            choices={listCategory}
            optionText={"label"}
            style={{ minWidth: 280 }}
          />
          {/* <SelectInput optionText='code' />
          </ReferenceInput> */}
          {/* <ReferenceInput
            label='resources.products.fields.class_2'
            source='class2_id'
            reference='codes'
            filter={{ type: "Class2" }}
            style={{ minWidth: 280 }}
          >
            <SelectInput optionText='code' />
          </ReferenceInput> */}
          {/* <ReferenceInput
            label='resources.products.fields.class_3'
            source='class3_id'
            reference='codes'
            filter={{ type: "Class3" }}
            style={{ minWidth: 280 }}
          >
            <SelectInput optionText='code' />
          </ReferenceInput>
          <ReferenceInput
            label='resources.products.fields.category'
            source='category_id'
            reference='codes'
            filter={{ type: "Category" }}
            style={{ minWidth: 280 }}
          >
            <SelectInput optionText='code' />
          </ReferenceInput> */}
          <SelectInput
            source={"new_existing"}
            label={"resources.products.fields.new_existing"}
            choices={dataExisting}
          />
          <SelectInput
            label='resources.products.fields.target_setting'
            source='target_id'
            choices={listTarget}
            optionText={"label"}
            style={{ minWidth: 280 }}
          />
          {/* <ReferenceInput
            label="resources.products.fields.group"
            source="group_id"
            reference="groups"
            style={{ minWidth: 280 }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput> */}
          {/* <ReferenceInput
            label='resources.products.fields.target_setting'
            source='class3_id'
            reference='codes'
            filter={{ type: "TargetSetting" }}
            style={{ minWidth: 280 }}
          >
            <SelectInput optionText='code' />
          </ReferenceInput> */}
        </Flex>
      </Paper>
    </Box>
  );
};
//
export default Classifications;
