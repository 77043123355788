/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Iframe from "react-iframe";
import { Flex } from "rebass";

const Dashboard = (props) => {
  const { width, height } = props;
  const [myWidth, setMyWidth] = useState(width);
  // console.log(width, height)
  return (
    <Flex
      width={[1]}
      flexDirection={"column"}
      style={{ height: "85vh", overflowY: "hidden" }}
      pt={[2]}
    >
      <Iframe
        width={myWidth}
        height={height}
        src="https://datastudio.google.com/embed/reporting/1tbmLZqVM0-hO9njpQkRGCHGFenfDmrPX/page/KBSOB"
        frameborder="0"
        style={{ borderWidth: 0 }}
        allowfullscreen
      />
    </Flex>
  );
};

export default Dashboard;
