import React from "react";
import Box from "@material-ui/core/Box";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { NumberInput } from "react-admin";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: 0,
      marginBottom: 0,
      "& > li:last-child": {
        borderBottom: "none",
      },
    },
    line: {
      display: "flex",
      listStyleType: "none",
      borderBottom: `solid 1px ${theme.palette.divider}`,
      [theme.breakpoints.down("xs")]: { display: "block" },
      "&.fade-enter": {
        opacity: 0.01,
        transform: "translateX(100vw)",
      },
      "&.fade-enter-active": {
        opacity: 1,
        transform: "translateX(0)",
        transition: "all 500ms ease-in",
      },
      "&.fade-exit": {
        opacity: 1,
        transform: "translateX(0)",
      },
      "&.fade-exit-active": {
        opacity: 0.01,
        transform: "translateX(100vw)",
        transition: "all 500ms ease-in",
      },
    },
    index: {
      width: "3em",
      paddingTop: "0em",
      [theme.breakpoints.down("sm")]: { display: "none" },
    },
    form: { flex: 2 },
    action: {
      paddingTop: "0.5em",
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: "TableFormIterator" }
);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary["300"],
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
    backgroundColor: theme.palette.primary["50"],
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

export const BuyXGetYMOQ = (props) => {
  const classes = useStyles(props);
  const { checkEmptyValue, translate } = props;
  return (
    <Box width={[1]}>
      <TableContainer component={"div"}>
        <Table className={classes.table} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                {translate(`resources.promotions.fields.moq`)}
              </StyledTableCell>
              <StyledTableCell>
                {translate(`resources.promotions.fields.buy`)}
              </StyledTableCell>
              <StyledTableCell>
                {translate(`resources.promotions.fields.get`)}
              </StyledTableCell>
              <StyledTableCell>
                {translate(`resources.promotions.fields.additional`)}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.buy_x_get_y.moqs[0].moq`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.buy_x_get_y.moqs[0].buy`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.buy_x_get_y.moqs[0].get`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.buy_x_get_y.moqs[0].additional`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.buy_x_get_y.moqs[1].moq`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.buy_x_get_y.moqs[1].buy`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.buy_x_get_y.moqs[1].get`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.buy_x_get_y.moqs[1].additional`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.buy_x_get_y.moqs[2].moq`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.buy_x_get_y.moqs[2].buy`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.buy_x_get_y.moqs[2].get`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.buy_x_get_y.moqs[2].additional`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export const BuyABGetCDMOQ = (props) => {
  const classes = useStyles(props);
  const { checkEmptyValue, translate } = props;
  return (
    <Box width={[1]}>
      <TableContainer component={"div"}>
        <Table className={classes.table} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                {translate(`resources.promotions.fields.moq`)}
              </StyledTableCell>
              <StyledTableCell>
                {translate(`resources.promotions.fields.buy`)}
              </StyledTableCell>
              <StyledTableCell>
                {translate(`resources.promotions.fields.get`)}
              </StyledTableCell>
              <StyledTableCell>
                {translate(`resources.promotions.fields.additional`)}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.buy_ab_get_cd.moqs[0].moq`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.buy_ab_get_cd.moqs[0].buy`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.buy_ab_get_cd.moqs[0].get`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.buy_ab_get_cd.moqs[0].additional`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.buy_ab_get_cd.moqs[1].moq`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.buy_ab_get_cd.moqs[1].buy`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.buy_ab_get_cd.moqs[1].get`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.buy_ab_get_cd.moqs[1].additional`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.buy_ab_get_cd.moqs[2].moq`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.buy_ab_get_cd.moqs[2].buy`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.buy_ab_get_cd.moqs[2].get`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.buy_ab_get_cd.moqs[2].additional`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export const DiscountMOQ = (props) => {
  const classes = useStyles(props);
  const { checkEmptyValue, translate } = props;
  return (
    <Box width={[1 / 2]}>
      <TableContainer component={"div"}>
        <Table className={classes.table} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                {translate(`resources.promotions.fields.moq`)}
              </StyledTableCell>
              <StyledTableCell>
                {translate(`resources.promotions.fields.discount`)}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.discount.moqs[0].moq`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.discount.moqs[0].discount`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.discount.moqs[1].moq`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.discount.moqs[1].discount`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.discount.moqs[2].moq`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.discount.moqs[2].discount`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export const SPMOQ = (props) => {
  const classes = useStyles(props);
  const { checkEmptyValue, translate } = props;
  return (
    <Box width={[1 / 2]}>
      <TableContainer component={"div"}>
        <Table className={classes.table} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                {translate(`resources.promotions.fields.moq`)}
              </StyledTableCell>
              <StyledTableCell>
                {translate(`resources.promotions.fields.price`)}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.price.moqs[0].moq`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.price.moqs[0].price`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.price.moqs[1].moq`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.price.moqs[1].price`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.price.moqs[2].moq`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
              <StyledTableCell>
                <NumberInput
                  label={""}
                  source={`criteria.price.moqs[2].price`}
                  style={{ width: 100, margin: 0 }}
                  onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
                  defaultValue={0}
                />
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
