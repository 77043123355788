import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { Flex } from "rebass";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ListInvoice from "./ListInvoice";
export default (props) => {
  const { open, setOpen } = props;
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
      maxWidth={"lg"}
    >
      <DialogTitle>
        <Flex flexDirection={"row"} justifyContent={"space-between"}>
          <div />
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Flex>
      </DialogTitle>
      <DialogContent>
        <ListInvoice {...props} />
      </DialogContent>
    </Dialog>
  );
};
