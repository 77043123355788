import React from "react";
import { Text } from "rebass";
import { get } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useQueryWithStore } from "react-admin";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const ProductField = (props) => {
  const classes = useStyles();

  const { record, source } = props;
  const { loaded, data } = useQueryWithStore({
    type: "getOne",
    resource: "products",
    payload: { id: record.product_id ? record.product_id : 0 },
  });
  if (!loaded) {
    return (
      <div className={classes.root}>
        <LinearProgress variant='query' />
      </div>
    );
  }

  return <Text>{get(data, source, "")}</Text>;
};

export default ProductField;
