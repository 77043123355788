/* eslint-disable react-hooks/rules-of-hooks */
import { useGetOne } from "react-admin";
import { PROFILE, TOKEN, TOKEN_NOTIF } from "../restClient";
import { ROLE_USER } from "./";
export const choicesLanguages = [
  { id: "en", name: "English" },
  { id: "th", name: "Thai" },
  { id: "id", name: "Bahasa" },
  { id: "zh_CN", name: "简体" },
];

export const languages = [{ id: "en", name: "English" }];

export const languagesListSiteId = ({ id }) => {
  const { data, loading, error } = useGetOne("sites", id);
  if (loading) {
    return [];
  }
  if (error) {
    return [];
  }
  if (data) {
    let newLanguage = data.locales.map(function (e) {
      let f = choicesLanguages.find((a) => a.id === e);
      return f ? f : undefined;
    });
    // console.log(newLanguage);
    return { default_locale: data.default_locale, locale: newLanguage };
  }
  return { default_locale: "en", locale: languages };
};

export const getUser = () => {
  const myProfile = localStorage.getItem(PROFILE);
  return myProfile !== null ? JSON.parse(myProfile) : {};
};

export const getToken = () => {
  const token = localStorage.getItem(TOKEN);
  return token !== null ? `Bearer ${token}` : "";
};

export const showToken = () => {
  const token = localStorage.getItem(TOKEN);
  return token !== null;
};

export const getOnlyToken = () => {
  const token = localStorage.getItem(TOKEN_NOTIF);
  return token !== null ? token : "";
};

export const isDealers = (permissions) => {
  return permissions === ROLE_USER.dealer;
};

export const showDashboard = () => {
  const user = getUser();
  return user && user.role === "Dealer";
};

export const showAdminSales = (permissions) => {
  return (
    permissions === ROLE_USER.admin ||
    permissions === ROLE_USER.gc_sales_manager ||
    permissions === ROLE_USER.gc_sales_associate
  );
};

export const showEditProduct = (permissions) => {
  return (
    permissions === ROLE_USER.admin ||
    permissions === ROLE_USER.gc_sales_manager ||
    permissions === ROLE_USER.gc_sales_associate ||
    permissions === ROLE_USER.gc_oa_associate
  );
};
export const showApprove = (permissions) => {
  return (
    permissions === ROLE_USER.admin ||
    permissions === ROLE_USER.gc_sales_manager
  );
};

export const showOAAssociate = (permissions) => {
  return permissions === ROLE_USER.gc_oa_associate;
};

export const showAdmin = (permissions) => {
  return permissions === ROLE_USER.admin;
};

export const showDealer = (permissions) => {
  return permissions === ROLE_USER.dealer;
};

export const showCountryManager = (permissions) => {
  return permissions === ROLE_USER.country_manager;
};
