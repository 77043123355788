import React from "react";
import { useHistory } from "react-router-dom";
import { includes } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import { showToken } from "../../utils";
// import { Image, Box, Flex } from "rebass"
import AppBar from "./AppBar";

const DRAWER_WIDTH = 230;

const styles = {
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    width: DRAWER_WIDTH,
  },
};

const AppHeader = withStyles(styles)(({ classes, ...props }) => {
  const history = useHistory();
  const { location } = history;
  const isShow =
    includes(location.pathname, "/create_password/") ||
    includes(location.pathname, "/reset_password/") ||
    includes(location.pathname, "/forgot_password");
  if (isShow) {
    return <span />;
  }
  if (!showToken()) {
    return <div className={classes.spacer} />;
  }
  return (
    <AppBar
      {...props}
      style={{ boxShadow: "none", backgroundColor: "transparent" }}
    >
      <span className={classes.spacer} />
    </AppBar>
  );
});

export default AppHeader;
