import { orderBy } from "lodash";
import moment from "moment";
import momentTimeZone from "moment-timezone";
const tzNameSg = "Asia/Singapore";
export const getListOrder = (data) => {
  return orderBy(data, ["name", "ASC"]);
};

export const payloadDefault = {
  pagination: { page: 1, perPage: 100 },
};

export const statusList = [
  { id: "All", text: "All" },
  { id: "Submitted", text: "Submitted" },
  { id: "Processing", text: "Processing" },
  { id: "Draft", text: "Draft" },
  { id: "Partial Received", text: "Partial Received" },
  { id: "Completed", text: "Completed" },
];

export const StatusListSell = [
  { id: "All", text: "All" },
  { id: "Draft", text: "Draft" },
  { id: "Partial", text: "Partial" },
  { id: "Posted", text: "Posted" },
  { id: "Processing", text: "Processing" },
  { id: "Received", text: "Received" },
  { id: "Sent", text: "Sent" },
];

export const reportType = [
  { id: "poSummary", text: "PO summary" },
  { id: "poDetail", text: "PO Detailed by Product" },
  { id: "sellOutSummary", text: "Sell out summary" },
  { id: "sellOutDetail", text: "Sell out summary by Product" },
  { id: "invoiceSummary", text: "Invoiced Transactions summary" },
  { id: "invoiceDetail", text: "Invoiced Transactions by Product" },
];
export const reportTypeValue = {
  pos: "poSummary",
  pod: "poDetail",
  sos: "sellOutSummary",
  sod: "sellOutDetail",
  is: "invoiceSummary",
  id: "invoiceDetail",
};
export const dateRange = [
  "All",
  "Custom",
  "This Year",
  "This Month",
  "This Quarter",
  "This Week",
  "Last Year",
  "Last Month",
  "Last Quarter",
  "Last Week",
  "Today",
  "Yesterday",
];

function getQuarterRange(quarter, currentDate) {
  let startQuarter = moment().quarter(quarter).startOf("quarter");
  let endQuarter = moment().quarter(quarter).endOf("quarter");

  if (currentDate) {
    startQuarter = moment(currentDate).quarter(quarter).startOf("quarter");
    endQuarter = moment(currentDate).quarter(quarter).endOf("quarter");
  }
  const yearStart = startQuarter.format("YYYY");
  const monthStart = startQuarter.format("MM");
  const dayStart = startQuarter.format("DD");
  const yearEnd = endQuarter.format("YYYY");
  const monthEnd = endQuarter.format("MM");
  const dayEnd = endQuarter.format("DD");
  const start = `${yearStart}-${monthStart}-${dayStart} 00:00:00`;
  const end = `${yearEnd}-${monthEnd}-${dayEnd} 23:59:59`;
  return { start, end };
}

function getDateByWeek(currentWeek) {
  let startQuarter = moment().startOf("week");
  let endQuarter = moment().endOf("week");

  if (currentWeek) {
    startQuarter = moment(currentWeek).startOf("week");
    endQuarter = moment(currentWeek).endOf("week");
  }
  const yearStart = startQuarter.format("YYYY");
  const monthStart = startQuarter.format("MM");
  const dayStart = startQuarter.format("DD");
  const yearEnd = endQuarter.format("YYYY");
  const monthEnd = endQuarter.format("MM");
  const dayEnd = endQuarter.format("DD");
  const start = `${yearStart}-${monthStart}-${dayStart} 00:00:00`;
  const end = `${yearEnd}-${monthEnd}-${dayEnd} 23:59:59`;
  return { start, end };
}

export const getStartEndTime = (typeDate, start, end) => {
  const thisYear = moment();
  const lastYear = moment().subtract(1, "year");
  const myStart = moment(start);
  const yearStart = myStart.format("YYYY");
  const monthStart = myStart.format("MM");
  const dayStart = myStart.format("DD");
  const myEnd = moment(end);
  const yearEnd = myEnd.format("YYYY");
  const monthEnd = myEnd.format("MM");
  const dayEnd = myEnd.format("DD");
  switch (typeDate) {
    case "Custom":
      const startTimeCustom = momentTimeZone.tz(
        `${yearStart}-${monthStart}-${dayStart} 00:00:00`,
        tzNameSg
      );

      const endTimeCustom = momentTimeZone.tz(
        `${yearEnd}-${monthEnd}-${dayEnd} 23:59:59`,
        tzNameSg
      );
      return {
        start_time: startTimeCustom.toISOString(),
        end_time: endTimeCustom.toISOString(),
      };
    case "This Year":
      const yearStartThisYear = thisYear.format("YYYY");
      const startTimeThisYear = momentTimeZone.tz(
        `${yearStartThisYear}-01-01 00:00:00`,
        tzNameSg
      );
      const endTimeThisYear = momentTimeZone.tz(
        `${yearStartThisYear}-12-31 23:59:59`,
        tzNameSg
      );
      return {
        start_time: startTimeThisYear.toISOString(),
        end_time: endTimeThisYear.toISOString(),
      };
    case "This Month":
      const yearStartThisMonth = thisYear.format("YYYY");
      const yearStartMonthThisMonth = thisYear.format("MM");
      const startTimeThisMonth = momentTimeZone.tz(
        `${yearStartThisMonth}-${yearStartMonthThisMonth}-01 00:00:00`,
        tzNameSg
      );
      const endTimeThisMonth = momentTimeZone.tz(
        `${yearStartThisMonth}-${yearStartMonthThisMonth}-31 23:59:59`,
        tzNameSg
      );
      return {
        start_time: startTimeThisMonth.toISOString(),
        end_time: endTimeThisMonth.toISOString(),
      };
    case "This Quarter":
      const currentQuarter = moment().quarter();
      const params = getQuarterRange(currentQuarter);
      // console.log("params", params);
      const startTimeThisQuarter = momentTimeZone.tz(params.start, tzNameSg);
      const endTimeThisQuarter = momentTimeZone.tz(params.end, tzNameSg);
      return {
        start_time: startTimeThisQuarter.toISOString(),
        end_time: endTimeThisQuarter.toISOString(),
      };
    case "This Week":
      const paramsThisW = getDateByWeek();
      const startTimeThisWeek = momentTimeZone.tz(paramsThisW.start, tzNameSg);
      const endTimeThisWeek = momentTimeZone.tz(paramsThisW.end, tzNameSg);
      return {
        start_time: startTimeThisWeek.toISOString(),
        end_time: endTimeThisWeek.toISOString(),
      };
    case "Last Year":
      const yearStartLastYear = lastYear.format("YYYY");
      const startTimeLastYear = momentTimeZone.tz(
        `${yearStart}-01-01 00:00:00`,
        tzNameSg
      );
      const endTimeLastYear = momentTimeZone.tz(
        `${yearStartLastYear}-12-31 23:59:59`,
        tzNameSg
      );
      return {
        start_time: startTimeLastYear.toISOString(),
        end_time: endTimeLastYear.toISOString(),
      };
    case "Last Month":
      const yearStartLastMonth = lastYear.format("YYYY");
      const yearStartMLastMonth = lastYear.format("MM");
      const startTimeLastMonth = momentTimeZone.tz(
        `${yearStart}-${yearStartMLastMonth}-01 00:00:00`,
        tzNameSg
      );
      const endTimeLastMonth = momentTimeZone.tz(
        `${yearStartLastMonth}-${yearStartMLastMonth}-31 23:59:59`,
        tzNameSg
      );
      return {
        start_time: startTimeLastMonth.toISOString(),
        end_time: endTimeLastMonth.toISOString(),
      };
    case "Last Quarter":
      const lastQ = moment().add(-1, "quarter");
      const lastQuarter = moment(lastQ).quarter();
      const paramsLast = getQuarterRange(lastQuarter, lastQ);
      const startTimeLastQuarter = momentTimeZone.tz(
        paramsLast.start,
        tzNameSg
      );
      const endTimeLastQuarter = momentTimeZone.tz(paramsLast.end, tzNameSg);
      return {
        start_time: startTimeLastQuarter.toISOString(),
        end_time: endTimeLastQuarter.toISOString(),
      };
    case "Last Week":
      const lastW = moment().add(-1, "week");
      const paramsLastW = getDateByWeek(lastW);
      const startTimeLastWeek = momentTimeZone.tz(paramsLastW.start, tzNameSg);
      const endTimeLastWeek = momentTimeZone.tz(paramsLastW.end, tzNameSg);
      return {
        start_time: startTimeLastWeek.toISOString(),
        end_time: endTimeLastWeek.toISOString(),
      };
    case "Today":
      const yearToday = thisYear.format("YYYY");
      const monthToday = thisYear.format("MM");
      const dayToday = thisYear.format("DD");
      const startTimeToday = momentTimeZone.tz(
        `${yearToday}-${monthToday}-${dayToday} 00:00:00`,
        tzNameSg
      );
      const endTimeToday = momentTimeZone.tz(
        `${yearToday}-${monthToday}-${dayToday} 23:59:59`,
        tzNameSg
      );
      return {
        start_time: startTimeToday.toISOString(),
        end_time: endTimeToday.toISOString(),
      };
    case "Yesterday":
      const yesterday = moment().add(-1, "day");
      const yearYesterday = yesterday.format("YYYY");
      const monthYesterday = yesterday.format("MM");
      const dayYesterday = yesterday.format("DD");
      const startTimeYesterday = momentTimeZone.tz(
        `${yearYesterday}-${monthYesterday}-${dayYesterday} 00:00:00`,
        tzNameSg
      );
      const endTimeYesterday = momentTimeZone.tz(
        `${yearYesterday}-${monthYesterday}-${dayYesterday} 23:59:59`,
        tzNameSg
      );
      return {
        start_time: startTimeYesterday.toISOString(),
        end_time: endTimeYesterday.toISOString(),
      };

    default:
      return {};
  }
};

export const getStatus = (reportType, status) => {
  if (reportType !== reportTypeValue.is && reportType !== reportTypeValue.id) {
    return {
      status: status,
    };
  }

  return {};
};
