import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
const CategoryFilter = (props) => {
  const { handleChange, value, translate, listCategories } = props;

  return (
    <FormControl>
      <InputLabel style={{ paddingLeft: 0 }} id='select-label-category'>
        {translate("resources.po.listgrid.label.category")}
      </InputLabel>
      <Select
        labelId='select-label-category'
        id='select-category'
        value={value}
        onChange={(e) => handleChange("category", e.target.value)}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        style={{ minWidth: 200, paddingLeft: 0 }}
      >
        <MenuItem value=''>
          <em style={{ height: 20 }} />
        </MenuItem>
        {listCategories &&
          listCategories.length > 0 &&
          listCategories.map((item, index) => (
            <MenuItem value={item.id} key={index}>
              {item.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default CategoryFilter;
