import React from "react"
import { get } from "lodash"
import { Flex, Box, Text, Image } from "rebass"
import { useTranslate } from "react-admin"
import { makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import CustomField from "./CustomField"
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}))

const TextRecord = ({ source, record = {}, label }) => {
  const translate = useTranslate()
  return (
    <Box pb={[3]}>
      <Text fontWeight={"bold"} fontSize={[0]} pb={[1]}>
        {translate(label)}
      </Text>
      {source === "active" && (
        <Text style={{ textTransform: "capitalize" }}>
          {get(record, source, "false")}
        </Text>
      )}
      {source !== "active" && <Text>{get(record, source, "")}</Text>}
    </Box>
  )
}

const ContentShow = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  // console.log(props)
  const { record } = props
  const photos =
    get(record, "photos", []) !== null ? get(record, "photos", []) : []
  return (
    <Box width={[1]}>
      <Flex width={[1]} flexDirection={"row"} justifyContent={"space-between"}>
        <Box width={[3 / 4]} p={[3]}>
          <Paper elevation={3} style={{ minHeight: 250 }}>
            <Box width={[1]} p={[3]}>
              <TextRecord
                source={"code"}
                label={"resources.products.fields.code"}
                record={record}
              />
              <TextRecord
                source={"packaging"}
                label={"resources.products.fields.packaging"}
                record={record}
              />
              <TextRecord
                source={"active"}
                label={"resources.products.fields.active"}
                record={record}
              />
              <TextRecord
                source={"price"}
                label={"resources.products.fields.default_price"}
                record={record}
              />
            </Box>
          </Paper>
        </Box>
        <Box width={[1 / 3]} pt={[3]} pr={[3]}>
          <Paper elevation={3} style={{ minHeight: 250 }}>
            <Box width={[1]} p={[3]}>
              <Text color={"green"} pb={[3]}>
                {translate("resources.products.title_registration")}
              </Text>
              <TextRecord
                source={"registration_number.sg"}
                label={"resources.products.fields.sg"}
                record={record}
              />
              <TextRecord
                source={"registration_number.id"}
                label={"resources.products.fields.id"}
                record={record}
              />
              <TextRecord
                source={"registration_number.my"}
                label={"resources.products.fields.my"}
                record={record}
              />
            </Box>
          </Paper>
        </Box>
      </Flex>
      <Box width={[1]} p={[2]}>
        <Paper elevation={3}>
          <Box width={[1]} p={[3]} pb={[1]}>
            <Text fontWeight={"bold"} fontSize={[0]} pb={[2]}>
              {translate("resources.products.fields.classifications")}
            </Text>
          </Box>
          <Flex
            width={[1]}
            flexDirection={"row"}
            flexWrap={"wrap"}
            className={classes.root}
            px={[2]}
            pb={[2]}
          >
            <CustomField
              label={translate("resources.products.fields.category")}
              source="name"
              record={record}
              resource={"categories"}
              resource_id="category_id"
            />
            <CustomField
              label={translate("resources.products.fields.class_1")}
              source="name"
              record={record}
              resource={"classifications"}
              resource_id="class1_id"
            />
            <CustomField
              label={translate("resources.products.fields.class_2")}
              source="name"
              record={record}
              resource={"classifications"}
              resource_id="class2_id"
            />
            <CustomField
              label={translate("resources.products.fields.class_3")}
              source="name"
              record={record}
              resource={"classifications"}
              resource_id="class3_id"
            />
            <CustomField
              label={translate("resources.products.fields.group")}
              source="name"
              record={record}
              resource={"groups"}
              resource_id="group_id"
            />
          </Flex>
        </Paper>
      </Box>
      <Box width={[1]} p={[2]}>
        <Paper elevation={3}>
          <Box width={[1]} p={[3]}>
            <Text fontWeight={"bold"} fontSize={[0]} pb={[2]}>
              {translate("resources.products.fields.photos")}
            </Text>
          </Box>
          <Flex
            width={[1]}
            flexDirection={"row"}
            flexWrap={"wrap"}
            className={classes.root}
            px={[2]}
            pb={[2]}
          >
            {photos.length > 0 &&
              photos.map((item, index) => {
                return (
                  <Image
                    key={index}
                    src={item}
                    style={{ width: 180, height: 180 }}
                  />
                )
              })}
          </Flex>
        </Paper>
      </Box>
      <Box width={[1]} p={[2]}>
        <Paper elevation={3}>
          <Box width={[1]} p={[3]}>
            <Text fontWeight={"bold"} fontSize={[0]} pb={[2]}>
              {translate("resources.products.fields.remarks")}
            </Text>
            <Flex
              width={[1]}
              p={[2]}
              style={{
                backgroundColor: "#EFECEC",
                borderRadius: 8,
                minHeight: 100,
              }}
              flexWrap={"wrap"}
            >
              <Text>{get(record, "remarks", "")}</Text>
            </Flex>
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}

export default ContentShow
