import { get, map } from "lodash";

const PromoTypeB = {
  buyxgety: "Buy X Get Y",
  buyabgetcd: "Buy AB Get CD",
  discount: "Discount",
  sp: "Special Price",
};

export const getSubtotal = (items) => {
  const newList = map(items, function (o) {
    const price = get(o, "price", 0) * 1;

    const promotions = get(o, "promotions", null);
    const promoType = get(promotions, "promotion_type", "");
    const discountPrice = get(o, "discounted_price", 0) * 1;

    if (promoType === PromoTypeB.sp) {
      return { id: o.id, subtotal: o.qty * discountPrice };
    }

    const newPrice = discountPrice > 0 ? discountPrice : price;
    return { id: o.id, subtotal: o.qty * newPrice };
  });
  //   console.log(newList);
  return newList;
};
