import React from "react";
import { Flex, Text } from "rebass";
import Button from "@material-ui/core/Button";

export default (props) => {
  const { history, label, translate } = props;
  return (
    <Flex width={[1]} flexDirection={"column"} alignItems={"center"}>
      <Text py={[3]} color={"rgba(0,0,0,0.6)"}>
        {label}
      </Text>
      <Button
        variant='contained'
        color='primary'
        size='large'
        onClick={() => history.push("/login")}
        style={{
          width: 180,
          textTransform: "capitalize",
          borderRadius: 24,
        }}
      >
        {translate("resources.buttons.login")}
      </Button>
    </Flex>
  );
};
