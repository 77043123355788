import React, { Fragment } from "react";
import numeral from "numeral";
import NumberFormat from "react-number-format";
import { get, has } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useQueryWithStore } from "react-admin";
import { Text } from "rebass";
import { StyledTableCell, StyledTableSmall } from "../edit";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
function currencyFormatter(value) {
  return numeral(value * 1).format("0,0.00"); // "\xA3" + formatNumber(params.value);
}

const CurrencyField = (props) => {
  const { value, fontSize = "9pt" } = props;
  return (
    <NumberFormat
      style={{ fontSize: fontSize }}
      value={currencyFormatter(value)}
      displayType={"text"}
      thousandSeparator={true}
      prefix={"$"}
      decimalScale={2}
    />
  );
};
const ItemField = ({ value }) => {
  return <span style={{ fontSize: "9pt" }}>{value}</span>;
};

const ProductField = (props) => {
  const classes = useStyles();

  const { record, source } = props;
  const { loaded, data } = useQueryWithStore({
    type: "getOne",
    resource: "products",
    payload: { id: record.product_id ? record.product_id : 0 },
  });
  if (!loaded) {
    return (
      <div className={classes.root}>
        <LinearProgress variant='query' />
      </div>
    );
  }

  return <span style={{ fontSize: "9pt" }}>{get(data, source, "")}</span>;
};

const ItemViewFields = ({ isCRDGCOLOR, promo, subtotal }) => {
  const discountedPrice = has(promo, "discounted_price")
    ? get(promo, "discounted_price", 0)
    : get(promo, "price", 0);
  // console.log("promo.discounted_price", promo.discounted_price);
  // const discountedPrice =
  //   get(promo, "discounted_price", 0) === 0
  //     ? get(promo, "price", 0)
  //     : get(promo, "discounted_price", 0);
  // const myPrice =
  //   get(promo, "unit_price", 0) === 0
  //     ? get(promo, "price", 0)
  //     : get(promo, "unit_price", 0);
  return (
    <Fragment>
      <StyledTableCell>
        <Text pl={["20px"]} style={{ ...isCRDGCOLOR }}>
          <ProductField
            record={{ product_id: get(promo, "product_id", 0) }}
            source={"packaging"}
          />
        </Text>
      </StyledTableCell>
      <StyledTableSmall style={{ ...isCRDGCOLOR, width: 130, padding: 8 }}>
        <ProductField
          record={{ product_id: get(promo, "product_id", 0) }}
          source={"code"}
        />
      </StyledTableSmall>
      <StyledTableSmall
        style={{
          textAlign: "right",
          padding: 8,
          ...isCRDGCOLOR,
          width: 90,
        }}
      >
        <CurrencyField value={get(promo, "price", 0)} />
      </StyledTableSmall>
      <StyledTableSmall
        style={{
          textAlign: "right",
          padding: 8,
          width: 150,
          ...isCRDGCOLOR,
        }}
      >
        <CurrencyField value={discountedPrice} />
      </StyledTableSmall>
      <StyledTableSmall
        style={{
          textAlign: "right",
          padding: 8,
          ...isCRDGCOLOR,
          width: 80,
        }}
      >
        <ItemField value={get(promo, "qty", 0)} />
      </StyledTableSmall>
      <StyledTableSmall
        style={{
          textAlign: "right",
          padding: 8,
          ...isCRDGCOLOR,
          width: 100,
        }}
      >
        <CurrencyField value={subtotal} />
      </StyledTableSmall>
    </Fragment>
  );
};

export { CurrencyField, ItemField, ProductField, ItemViewFields };
