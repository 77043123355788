import React, { Fragment, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { Flex } from "rebass";
import { Button } from "react-admin";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ButtonUploadCsvInvoice from "./ButtonUploadCsvInvoice";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const monthList = [
  { id: 4, name: "April" },
  { id: 5, name: "May" },
  { id: 6, name: "June" },
  { id: 7, name: "July" },
  { id: 8, name: "August" },
  { id: 9, name: "September" },
  { id: 10, name: "October" },
  { id: 11, name: "November" },
  { id: 12, name: "December" },
  { id: 1, name: "January" },
  { id: 2, name: "February" },
  { id: 3, name: "March" },
];

const DialogUploadInvoice = (props) => {
  const { onSubmitUpload, translate, notify } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [state, setState] = useState({
    month: 4,
  });
  const handleClick = () => {
    setShowDialog(true);
  };

  const onClose = (v) => {
    onSubmitUpload(v);
    setShowDialog(false);
  };
  const handleChange = (e) => {
    setState({
      ...state,
      month: e.target.value * 1,
    });
  };

  return (
    <Fragment>
      <Button
        onClick={handleClick}
        label={"resources.buttons.upload_invoiced_transactions"}
        style={{ textTransform: "capitalize" }}
      />
      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        maxWidth={"lg"}
      >
        <DialogTitle>
          <Flex flexDirection={"row"} justifyContent={"space-between"}>
            <div />
            <IconButton onClick={() => setShowDialog(false)}>
              <CloseIcon />
            </IconButton>
          </Flex>
        </DialogTitle>
        <DialogContent>
          <Flex
            width={["400px"]}
            flexDirection={"row"}
            justifyContent={"center"}
          >
            <FormControl>
              <InputLabel htmlFor='age-native-simple'>Month</InputLabel>
              <Select
                native
                value={state.month}
                onChange={handleChange}
                inputProps={{
                  name: "age",
                  id: "age-native-simple",
                }}
              >
                {monthList.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>
                      {item.name}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </Flex>
        </DialogContent>

        <DialogActions>
          <Flex
            width={[1]}
            flexDirection={"row"}
            justifyContent={"center"}
            pb={[3]}
          >
            <ButtonUploadCsvInvoice
              label={"resources.buttons.upload_invoiced_transactions"}
              translate={translate}
              disableClick={state.month === 0}
              onClose={(v) => onClose(v)}
              month={state.month}
              urlPath={"/invoices/import"}
              notify={notify}
            />
          </Flex>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default DialogUploadInvoice;
