export default {
  users: {
    name: "Users",
    new_user: "New User",
    edit_user: "Edit User",
    fields: {
      first_name: "First Name",
      last_name: "Last Name",
      name: "Name",
      email: "Email",
      mobile: "Phone number",
      role: "Role",
      status: "Status",
      created_at: "Create On",
      active: "Active",
      dealer: "Dealer",
      custom: {
        merchant: "Merchant",
      },
      loyalty_program_name: "Loyalty Program Name",
    },
  },
};
