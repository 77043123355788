import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Box } from "rebass";
import { useQueryWithStore } from "react-admin";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 0,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: 0,
    minWidth: 200,
  },
}));
const payloadDefault = {
  pagination: { page: 1, perPage: 100 },
  sort: { field: "name", order: "ASC" },
};
const CountryFields = (props) => {
  const { handleChange, value, translate } = props;
  const classes = useStyles();

  const { data, loading } = useQueryWithStore({
    type: "getList",
    resource: "countries",
    payload: payloadDefault,
  });
  if (loading) {
    return (
      <Box width={["200px"]}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <FormControl>
      <InputLabel id='country-date_year' style={{ minWidth: 80 }} shrink={true}>
        {translate("resources.targets.fields.country")}
      </InputLabel>
      <Select
        name={"country"}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        className={classes.selectEmpty}
        style={{ width: 200 }}
      >
        <MenuItem value={""} style={{ height: 36 }} />
        {data &&
          data.length > 0 &&
          data.map((i) => (
            <MenuItem key={i.id} value={i.id}>
              {i.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default CountryFields;
