import React from "react"
import { Text } from "rebass"
import { inputStyles } from "../themes"

export const Title = props => (
  <Text
    {...props}
    style={{ letterSpacing: inputStyles.letterSpacing }}
    color={inputStyles.colorLabel}
  />
)
