import accounts from "./accounts";
import buttons from "./buttons";
import contents from "./contents";
import dashboard from "./dashboard";
import login from "./login";
import menus from "./menus";
import notification from "./notification";
import orders from "./orders";
import pricebook from "./pricebook";
import products from "./products";
import promotions from "./promotions";
import users from "./users";
import inventory from "./inventory";
import po from "./po";
import settings from "./settings";
import targets from "./targets";
import dealers from "./dealers";
import invoiced from "./invoiced";
import sellout from "./sellout";
export default {
  ...accounts,
  ...buttons,
  ...contents,
  ...dashboard,
  ...dealers,
  ...inventory,
  ...invoiced,
  ...login,
  ...menus,
  ...notification,
  ...orders,
  ...po,
  ...pricebook,
  ...products,
  ...promotions,
  ...targets,
  ...users,
  ...settings,
  ...sellout,
};
