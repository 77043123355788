// import { find, filter, includes, get } from "lodash";
// import { round } from "lodash";
import numeral from "numeral";

function currencyFormatter(params) {
  return numeral(params.value * 1).format("0,0.00"); // "\xA3" + formatNumber(params.value);
}

export const NumberParser = (params) => {
  return Number(params.newValue);
};

export const DGParser = (params) => {
  return params.newValue === "true" ? "DG" : "";
};

export const NumberAmount = (params) => {
  // console.log(params);
  //Number(params.data.Order * params.data.Price).toFixed(2)

  //round(params.newValue * 1, 2).toFixed(2); //
  return numeral(params.newValue * 1).format("0,0.00");
  // Number(params.newValue * 1).toFixed(2);
};

export const defaultColumnDefsNormal = [
  //  {
  //    field: "SubProductGroup",
  //    headerName: "Sub Group",
  //    editable: false,
  //    resizable: true,
  //  },
  { field: "code", headerName: "Product Code", editable: false },
  {
    field: "packaging",
    headerName: "Product Name",
    editable: false,
    resizable: true,
  },

  { field: "dg", editable: false, width: 80 },
  { field: "cr", editable: false, width: 80 },
  { field: "growth", headerName: "Speed/Growth", editable: false },
  { field: "new_existing", headerName: "New & Existing", editable: false },
  { field: "moq", headerName: "MOQ", editable: false, width: 80 },
  {
    field: "price",
    headerName: "Unit Price",
    editable: false,
    width: 100,
    valueFormatter: currencyFormatter,
  },
  {
    field: "qty",
    headerName: "Order Qty",
    width: 100,
    valueParser: NumberParser,
    cellStyle: {
      "background-color": "rgba(0, 156, 125, 0.15)",
      "padding-top": "7px",
    },
  },
  {
    field: "subtotal",
    headerName: "Order Amt",
    width: 120,
    valueFormatter: currencyFormatter,
  },
  //  { field: "Promo", editable: false, width: 80 },
  //  { field: "Growth", editable: false },
  //  { field: "New", headerName: "Recency", editable: false },
  // { field: "Class1", editable: false },
  // { field: "Class2", editable: false },
  // { field: "Class3", editable: false },
  // { field: "Category", editable: false },
  // { field: "GlobalCode", editable: false },
];

export const defaultColumnDefs = [
  //  {
  //    field: "SubProductGroup",
  //    headerName: "Sub Group",
  //    editable: false,
  //    resizable: true,
  //  },
  { field: "code", headerName: "Product Code", editable: false },
  {
    field: "packaging",
    headerName: "Product Name",
    editable: false,
    resizable: true,
  },
  { field: "dg", editable: false, width: 80 },
  { field: "cr", editable: false, width: 80 },
  //new_existing
  {
    field: "growth",
    headerName: "Speed/Growth",
    editable: false,
    width: 130,
    resizable: true,
  },
  {
    field: "new_existing",
    headerName: "New & Existing",
    editable: false,
    width: 130,
    resizable: true,
  },
  { field: "moq", headerName: "MOQ", editable: false, width: 80 },
  {
    field: "price",
    headerName: "Unit Price1",
    editable: false,
    width: 100,
    resizable: true,
    valueFormatter: currencyFormatter,
  },
  {
    field: "qty",
    headerName: "Order Qty",
    width: 100,
    valueParser: NumberParser,
    cellStyle: {
      "background-color": "rgba(0, 156, 125, 0.15)",
      "padding-top": "7px",
    },
  },
  {
    field: "subtotal",
    headerName: "Order Amt",
    width: 120,
    editable: false,
    valueFormatter: currencyFormatter,
    // valueParser: NumberAmount,
  },
  // { field: "Growth", editable: false },
  // { field: "New", headerName: "Recency", editable: false },
  //  { field: "Class1", editable: false },
  //  { field: "Class2", editable: false },
  //  { field: "Class3", editable: false },
  //  { field: "Category", editable: false },
  //  { field: "GlobalCode", editable: false },
];

export const defaultSPDiscountColumnDefs = [
  //  {
  //    field: "SubProductGroup",
  //    headerName: "Sub Group",
  //    editable: false,
  //    resizable: true,
  //  },
  { field: "code", headerName: "Product Code", editable: false },
  {
    field: "packaging",
    headerName: "Product Name",
    editable: false,
    resizable: true,
  },
  { field: "dg", editable: false, width: 80 },
  { field: "cr", editable: false, width: 80 },
  //new_existing
  {
    field: "growth",
    headerName: "Speed/Growth",
    editable: false,
    width: 130,
    resizable: true,
  },
  {
    field: "new_existing",
    headerName: "New & Existing",
    editable: false,
    width: 130,
    resizable: true,
  },
  {
    field: "moq",
    headerName: "Promo Price",
    editable: false,
    width: 120,
    resizable: true,
  },
  {
    field: "price",
    headerName: "Unit Price",
    editable: false,
    width: 100,
    resizable: true,
    valueFormatter: currencyFormatter,
  },
  {
    field: "qty",
    headerName: "Order Qty",
    width: 100,
    valueParser: NumberParser,
    cellStyle: {
      "background-color": "rgba(0, 156, 125, 0.15)",
      "padding-top": "7px",
    },
  },
  {
    field: "subtotal",
    headerName: "Order Amt",
    width: 120,
    editable: false,
    valueFormatter: currencyFormatter,
    // valueParser: NumberAmount,
  },
  // { field: "Growth", editable: false },
  // { field: "New", headerName: "Recency", editable: false },
  //  { field: "Class1", editable: false },
  //  { field: "Class2", editable: false },
  //  { field: "Class3", editable: false },
  //  { field: "Category", editable: false },
  //  { field: "GlobalCode", editable: false },
];

export const defaultFreeColumnDefs = [
  //  {
  //    field: "SubProductGroup",
  //    headerName: "Sub Group",
  //    editable: false,
  //    resizable: true,
  //  },
  { field: "code", headerName: "Product Code", editable: false },
  {
    field: "packaging",
    headerName: "Product Name",
    editable: false,
    resizable: true,
  },
  { field: "dg", editable: false, width: 80 },
  { field: "cr", editable: false, width: 80 },
  //new_existing
  {
    field: "growth",
    headerName: "Speed/Growth",
    editable: false,
    width: 130,
    resizable: true,
  },
  {
    field: "new_existing",
    headerName: "New & Existing",
    editable: false,
    width: 130,
    resizable: true,
  },
  {
    field: "price",
    headerName: "Unit Price",
    editable: false,
    width: 100,
    resizable: true,
    valueFormatter: currencyFormatter,
  },
  {
    field: "qty",
    headerName: "Order Qty",
    width: 100,
    valueParser: NumberParser,
    cellStyle: {
      "background-color": "rgba(0, 156, 125, 0.15)",
      "padding-top": "7px",
    },
  },
  {
    field: "subtotal",
    headerName: "Order Amt",
    width: 120,
    editable: false,
    valueFormatter: currencyFormatter,
    // valueParser: NumberAmount,
  },
  // { field: "Growth", editable: false },
  // { field: "New", headerName: "Recency", editable: false },
  //  { field: "Class1", editable: false },
  //  { field: "Class2", editable: false },
  //  { field: "Class3", editable: false },
  //  { field: "Category", editable: false },
  //  { field: "GlobalCode", editable: false },
];
