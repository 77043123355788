import React from "react";
import { Create, useTranslate } from "react-admin";
import { Flex, Box } from "rebass";
import { useLocation } from "react-router-dom";
import { BetterPoForm, Title, ListButton } from "../components";
import { FormFieldCustomer, FooterPOToolbar } from "./components";
//
const ActionForm = (props) => {
  const translate = useTranslate();
  const location = useLocation();
  const { pt = 3 } = props;
  const basepathUrl =
    location.search && location.search === "?i=inventories"
      ? "/inventories"
      : props.basePath;
  return (
    <Flex
      flexDirection={"row"}
      justifyContent={"space-between"}
      width={[1]}
      pt={[pt]}
      pb={[2]}
    >
      <Box width={[1]}>
        <Title fontWeight={"bold"} fontSize={[3]}>
          {translate(props.title)}
        </Title>
      </Box>
      <Flex width={[1]} flexDirection={"row"} justifyContent={"flex-end"}>
        {/*  */}
        <ListButton basepath={basepathUrl} record={props.data} />
      </Flex>
    </Flex>
  );
};

const initValues = {
  delivery_date: new Date(),
};

const CreatePOCustomer = (props) => {
  // console.log(props)
  return (
    <Create {...props} actions={<ActionForm title={"resources.po.new_po"} />}>
      <BetterPoForm
        {...props}
        toolbar={<FooterPOToolbar />}
        typePostUrl={"CREATE"}
        initialValues={initValues}
      >
        <FormFieldCustomer {...props} />
      </BetterPoForm>
    </Create>
  );
};
export default CreatePOCustomer;
