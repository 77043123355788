import React from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import {
  // NumberInput,
  ReferenceInput,
  // ArrayInput,
  AutocompleteInput,
  SelectInput,
} from "react-admin";
import { useStyles } from "./styles";
// import TableFormIterator from "./TableFormIterator";
import { withStyles, createStyles } from "@material-ui/core/styles";
import CardTable, { dataCriteriaType } from "./CardTable";
import { ThemeProvider } from "@material-ui/styles";
import { themeTableInput } from "../../../themes";
import ProductShortCodeInput from "./ProductShortCodeInput";
import { BuyXGetYMOQ } from "./TableStyles";
// const defaultBuyX_GetY = [
//   { buy: 0, get: 0, moq: 0, additional: 0 },
//   { buy: 0, get: 0, moq: 0, additional: 0 },
//   { buy: 0, get: 0, moq: 0, additional: 0 },
// ];

const mystyles = (theme) =>
  createStyles({
    root: {
      width: "100%",
    },
  });

const BuyXGetYFields = (props) => {
  const {
    formData,
    checkEmptyValue,
    dataProducts,
    updateField,
    ...rest
  } = props;

  const classes = useStyles();
  const type_x = get(formData, "criteria.buy_x_get_y.x.type", "");
  const type_y = get(formData, "criteria.buy_x_get_y.y.type", "");

  return (
    <Flex
      width={[1]}
      flexDirection={"column"}
      sx={{
        "&>div": {
          pb: [3],
        },
      }}
    >
      <CardTable {...props} title={"resources.promotions.fields.buy_x"}>
        <Flex flexDirection='row' width={[1]} justifyContent='flex-start'>
          <SelectInput
            source='criteria.buy_x_get_y.x.type'
            choices={dataCriteriaType}
            label={"resources.promotions.labels.criteria_type_x"}
            style={{ paddingRight: 16 }}
          />
          {(type_x === "ProductGroup" || type_x === "SubProductGroup") && (
            <ReferenceInput
              label={"resources.promotions.labels.criteria_x"}
              source='criteria.buy_x_get_y.x.id'
              reference='codes'
              className={classes.product}
              filterToQuery={(searchText) => ({ search: searchText })}
              filter={{ type: type_x, active: true }}
              sort={{ field: "label", order: "ASC" }}
              perPage={1000}
            >
              <AutocompleteInput optionText='label' />
            </ReferenceInput>
          )}
          {type_x === "ProductCode" && (
            <ProductShortCodeInput
              record={formData}
              source={"criteria.buy_x_get_y.x.id"}
              optionValue={"code"}
              updateField={updateField}
              label={"resources.promotions.labels.criteria_x"}
              list={dataProducts || []}
            />
          )}
          {type_x === "ProductShortCode" && (
            <ProductShortCodeInput
              record={formData}
              source={"criteria.buy_x_get_y.x.id"}
              optionValue={"short_code"}
              updateField={updateField}
              label={"resources.promotions.labels.criteria_x"}
              list={dataProducts || []}
            />
          )}
        </Flex>
      </CardTable>

      <CardTable {...props} title={"resources.promotions.fields.get_y"}>
        <Flex flexDirection='row' width={[1]} justifyContent='flex-start'>
          <SelectInput
            source='criteria.buy_x_get_y.y.type'
            choices={dataCriteriaType}
            label={"resources.promotions.labels.criteria_type_y"}
            style={{ paddingRight: 16 }}
          />
          {(type_y === "ProductGroup" || type_y === "SubProductGroup") && (
            <ReferenceInput
              label={"resources.promotions.labels.criteria_y"}
              source='criteria.buy_x_get_y.y.id'
              reference='codes'
              className={classes.product}
              filter={{ type: type_y, active: true }}
              sort={{ field: "label", order: "ASC" }}
              filterToQuery={(searchText) => ({ search: searchText })}
              perPage={1000}
            >
              <AutocompleteInput optionText='label' />
            </ReferenceInput>
          )}

          {type_y === "ProductCode" && (
            // <ReferenceInput
            //   label={"resources.promotions.labels.criteria_y"}
            //   source='criteria.buy_x_get_y.y.id'
            //   reference='products'
            //   className={classes.product}
            //   filterToQuery={(searchText) => ({ search: searchText })}
            //   perPage={1000}
            // >
            //   <AutocompleteInput optionText='packaging' optionValue={"id"} />
            // </ReferenceInput>
            <ProductShortCodeInput
              record={formData}
              source={"criteria.buy_x_get_y.y.id"}
              optionValue={"code"}
              updateField={updateField}
              label={"resources.promotions.labels.criteria_y"}
              list={dataProducts || []}
            />
          )}
          {type_y === "ProductShortCode" && (
            // <ReferenceInput
            //   label={"resources.promotions.labels.criteria_y"}
            //   source='criteria.buy_x_get_y.y.id'
            //   reference='products'
            //   className={classes.product}
            //   filterToQuery={(searchText) => ({ search: searchText })}
            //   perPage={1000}
            // >
            //   <AutocompleteInput
            //     optionText='packaging'
            //     optionValue={"short_code"}
            //   />
            // </ReferenceInput>
            <ProductShortCodeInput
              record={formData}
              source={"criteria.buy_x_get_y.y.id"}
              optionValue={"short_code"}
              updateField={updateField}
              label={"resources.promotions.labels.criteria_y"}
              list={dataProducts || []}
            />
          )}
        </Flex>
      </CardTable>
      <ThemeProvider theme={themeTableInput}>
        <Flex width={[1]} flexDirection={"column"}>
          <BuyXGetYMOQ {...rest} checkEmptyValue={checkEmptyValue} />
          {/* <ArrayInput
            source='criteria.buy_x_get_y.moqs'
            {...rest}
            label={""}
            defaultValue={defaultBuyX_GetY}
          >
            <TableFormIterator {...rest} disableAdd={true} disableRemove={true}>
              <NumberInput
                source='moq'
                style={{ width: 100, margin: 0 }}
                label={"resources.promotions.fields.moq"}
                onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
              />
              <NumberInput
                source='buy'
                style={{ width: 100, margin: 0 }}
                label={"resources.promotions.fields.buy"}
                onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
              />
              <NumberInput
                source='get'
                style={{ width: 100, margin: 0 }}
                label={"resources.promotions.fields.get"}
                onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
              />
              <NumberInput
                source='additional'
                style={{ width: 100, margin: 0 }}
                label={"resources.promotions.fields.additional"}
                onBlur={(e) => checkEmptyValue(e.target.name, e.target.value)}
              />
            </TableFormIterator>
          </ArrayInput> */}
        </Flex>
      </ThemeProvider>
    </Flex>
  );
};

export default withStyles(mystyles)(BuyXGetYFields);
