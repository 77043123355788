import React from "react";
import { Flex } from "rebass";
import { Loading } from "react-admin";
export const ContainerLoading = (props) => {
  return (
    <Flex
      width={[1]}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ height: "20vh" }}
    >
      <Loading />
    </Flex>
  );
};
