import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { idSchema } from "./schemaList";
const InvoiceDetailList = (props) => {
  const { list, height = "50vh" } = props;
  const state = {
    columnDefs: idSchema,
    defaultColDef: {
      flex: 1,
      editable: false,
      sortable: true,
      filter: true,
      minWidth: 60,
      // cellStyle: {
      //   "line-height": "40px",
      // },
    },
    getRowNodeId: function (data) {
      return data.id;
    },
  };
  return (
    <div className='grid-wrapper'>
      <div
        id='myGridInvoiceDetailList'
        className='ag-theme-balham'
        style={{ height: height, width: "100%" }}
      >
        <AgGridReact
          rowHeight={40}
          headerHeight={40}
          rowData={list}
          columnDefs={state.columnDefs}
          defaultColDef={state.defaultColDef}
          animateRows={true}
        />
      </div>
    </div>
  );
};

export default InvoiceDetailList;
