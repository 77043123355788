import React from "react";
import { get, chain, find } from "lodash";
import { useTranslate } from "react-admin";
import { Flex, Box, Text } from "rebass";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import { grey } from "@material-ui/core/colors";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import {
  // StyledTableAction,
  StyledTableCell,
  StyledTableRow,
  StyledTableSmall,
  PromoType_B,
} from "./edit";
import {
  ViewBuyABGetCDItems,
  ViewBuyXGetYItems,
  ViewSpecialPriceItems,
  ViewDiscountItems,
  ViewNormalItems,
  TotalTable,
} from "./order_item";
import TotalQuantity from "./TotalQuantity";

const EmptyList = () => {
  const translate = useTranslate();

  return (
    <Box width={[1]} p={[2]}>
      <Flex
        width={[1]}
        justifyContent='center'
        alignItems={"center"}
        flexDirection={"column"}
        sx={{
          border: "1px solid #C3c3c3",
          height: 300,
        }}
      >
        <InsertDriveFileOutlinedIcon
          style={{ fontSize: "60pt", color: grey[300] }}
        />

        <Text p={[3]} textAlign={"center"}>
          {translate("resources.po.messages.no_have_items")}
        </Text>
      </Flex>
    </Box>
  );
};

const ListOrdersView = (props) => {
  const { formData } = props;
  const products = get(formData, "items", []);
  const translate = useTranslate();

  if (products === null || products.length === 0) {
    return <EmptyList />;
  }
  const listProducts = chain(products)
    .groupBy("promotions.id")
    .map((value, key) => {
      const item = find(value, function (o) {
        return get(o, "promotions.id", 0) + "" === key + "";
      });
      return {
        id: key && key !== "undefined" ? key : "normal",
        promotion_type: get(
          item,
          "promotions.promotion_type",
          key && key !== "undefined" ? key : ""
        ),
        list: value,
        promotions: get(item, "promotions", null),
      };
    })
    .value();
  let total = 0;
  return (
    <Box width={[1]}>
      <TableContainer component={"div"}>
        <Table aria-label='customized table'>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell style={{ textAlign: "left", paddingLeft: 30 }}>
                {translate("resources.po.table.packaging")}
              </StyledTableCell>
              <StyledTableSmall style={{ textAlign: "center", width: 130 }}>
                {translate("resources.po.table.code")}
              </StyledTableSmall>
              <StyledTableSmall style={{ textAlign: "right", width: 90 }}>
                {translate("resources.po.table.price")}
              </StyledTableSmall>
              <StyledTableSmall style={{ textAlign: "right", width: 150 }}>
                {translate("resources.po.table.discounted_price")}
              </StyledTableSmall>
              <StyledTableSmall style={{ textAlign: "right", width: 80 }}>
                {translate("resources.po.table.qty")}
              </StyledTableSmall>
              <StyledTableSmall style={{ textAlign: "right", width: 100 }}>
                {translate("resources.po.table.subtotal")}
              </StyledTableSmall>
            </StyledTableRow>
          </TableHead>
        </Table>
      </TableContainer>
      {listProducts.map((child, index) => {
        const PromoType = get(child, "promotion_type", "");
        const list = get(child, "list", []);
        list.map((item) => {
          total = total + item.subtotal * 1;
          return "";
        });
        return (
          <Box width={[1]} key={index}>
            {PromoType === "" && <ViewNormalItems child={child} key={index} />}
            {PromoType === PromoType_B[0] && (
              <ViewBuyXGetYItems child={child} key={index} />
            )}
            {PromoType === PromoType_B[1] && (
              <ViewBuyABGetCDItems child={child} key={index} />
            )}

            {PromoType === PromoType_B[2] && (
              <ViewDiscountItems child={child} key={index} />
            )}
            {PromoType === PromoType_B[3] && (
              <ViewSpecialPriceItems child={child} key={index} />
            )}
          </Box>
        );
      })}
      <TotalQuantity products={products} />
      <TotalTable translate={translate} totalAmount={total} record={formData} />
    </Box>
  );
};

export default ListOrdersView;
