export default {
  accounts: {
    name: "Accounts",
    create_password: "Create Password",
    reset_password: "Reset Password",
    forgot_password: "Forgot Password",
    fields: {
      name: "Name",
      address: "Business Address",
      contact_person: "Contact Person",
      contact_email: "Contact Email",
      contact_phone: "Contact Phone",
      remarks: "Remarks",
      created_at: "Create On",
      password: "Password",
      confirm_password: "Confirm Password",
      email: "Email",
    },
    message: {
      name: "Account name, typically, put company name",
      succes_create_password: "Your account is setup successfully.",
      succes_forgot_password:
        "We have sent the instructions to reset password to your email.",
      succes_reset_password: "Your password is reset successfully",
      try_again: "Please try again",
    },
  },
};
