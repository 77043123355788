import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useRouteMatch, useLocation } from "react-router-dom";
import { Title } from "../components";
import { Flex, Box } from "rebass";
import { useTranslate } from "react-admin";
import {
  ListGCDealers,
  DialogDealerTarget,
  DialogShowListError,
} from "./components";
import { getCurrentYear } from "./components/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const CreateBoxButton = (props) => {
  return <DialogDealerTarget {...props} />;
};
const DealerTarget = (props) => {
  const { hasCreate, hasShow, hasEdit, hasList, permissions, ...rest } = props;
  const classes = useStyles();
  // const [state, setState] = useState({ lcp: [], ld: [] });
  const [listError, setListError] = useState({ open: false, list: [] });
  // const [fy, setFY] = useState(getCurrentYear());
  const [filter, setFilter] = useState({});
  const [stateDealer, setStateDealer] = useState({
    fy: getCurrentYear(),
    country: "",
    dealer: "",
  });
  const translate = useTranslate();
  const match = useRouteMatch();
  const location = useLocation();

  const onSubmitUpload = (key, values) => {
    if (values && values.length > 0) {
      setListError({
        open: true,
        list: values,
      });
    }
    // setState({
    //   ...state,
    //   ld: values,
    // });
  };

  const onCloseDialog = (value) => {
    setListError({
      open: false,
      list: [],
    });
  };

  const onSetStateDealer = (key, v) => {
    setStateDealer({
      ...stateDealer,
      [key]: v,
    });
    if (v !== "") {
      let params = {
        dealer_id: v,
      };
      setFilter({ ...filter, ...params });
    }
  };

  return (
    <div className={classes.root}>
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        p={[3]}
      >
        <Box>
          <Title fontSize={[3]} fontWeight={"bold"}>
            {translate("resources.targets.name")}
          </Title>
        </Box>
        <CreateBoxButton
          {...rest}
          permissions={permissions}
          match={match}
          location={location}
          onSubmitUpload={onSubmitUpload}
          translate={translate}
        />
      </Flex>
      <ListGCDealers
        {...rest}
        translate={translate}
        fy={stateDealer.fy}
        stateDealer={stateDealer}
        filter={filter}
        onSetStateDealer={onSetStateDealer}
      />
      <DialogShowListError
        setOpen={(v) => onCloseDialog(v)}
        open={listError.open}
        list={listError.list}
        translate={translate}
      />
    </div>
  );
};

export default DealerTarget;
