import React, { useState } from "react";
import {
  find,
  filter,
  has,
  get,
  includes,
  toLower,
  uniqBy,
  map,
  orderBy,
} from "lodash";
import numeral from "numeral";
import { AgGridReact } from "ag-grid-react";
import { Flex } from "rebass";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { useQuery } from "react-query";
import { getProductsList } from "../../restClient";
import { checkPromotion, cacheTimeMinute, getRegisterNumber } from "./utils";

// import { getUser } from "../../utils";
import { defaultColumnDefsNormal } from "./defaultValue";
import { NormalFilterFields } from "./NormalFilterFields";
import "./defaultStyle.css";
import { ContainerLoading } from "./ContainerLoading";
// import { func } from "prop-types";

const getFilterSearchProductList = (list, value) => {
  let listProduct = filter(list, function (o) {
    const newValue = toLower(value);
    const myPacking = get(o, "packaging", "");
    const packing = toLower(`${myPacking}`);
    return value !== "" ? includes(packing, newValue) : true;
  });

  return listProduct;
};

const sortOrderByName = (rowData, list) => {
  const newList = map(list, function (o) {
    const itemProduct = find(rowData, function (i) {
      return i.id === o.product_id;
    });
    return { ...o, product_name: toLower(get(itemProduct, "packaging", "")) };
  });
  const sortByName = orderBy(newList, "product_name", "asc");
  let mySortOrder = 10000000;
  const newSortByName = map(sortByName, function (ii) {
    const { product_name, ...rest } = ii;
    rest["sort_order"] = mySortOrder++;
    return { ...rest };
  });

  return newSortByName;
};

const getShowItemProductList = (list) => {
  let listProduct = filter(list, function (o) {
    return o.qty > 0;
  });
  return listProduct;
};

const MainList = (props) => {
  const {
    translate,
    tempList,
    setTempList,
    setShow,
    setMessageError,
    isOAAsossiate,
    productList,
  } = props;
  const [gridColApi, setGridColApi] = useState(null);
  const [state, setState] = useState({
    showItemOnly: false,
    search: "",
    message: "",
    show: false,
    rowData: get(productList, "rowData", []),
    oldrowData: get(productList, "oldrowData", []),
    columnDefs: defaultColumnDefsNormal(isOAAsossiate),
    defaultColDef: {
      flex: 1,
      editable: true,
      sortable: true,
      filter: true,
      cellStyle: {
        "line-height": "40px",
      },
    },
    getRowNodeId: function (data) {
      return data.code;
    },
    rowClassRules: {
      "red-row": 'data.Promo == "YES"',
    },
    category: "",
    group: "",
    subgroup: "",
    growth: "",
    recency: "",
    filters: {
      category: "",
      group: "",
      subgroup: "",
      growth: "",
      recency: "",
    },
  });

  const onGridReady = (params) => {
    setGridColApi(params.api);
  };

  const getRowStyleDef = (params) => {
    if (params.data.promo === "YES") {
      return {
        "background-color": "#b10505",
        color: "#FFFFFF",
      };
    }
    return null;
  };
  const handleChange = (k, v) => {
    const params = {
      ...state.filters,
      [k]: v,
    };
    if (k === "search") {
      const newRawData = getFilterSearchProductList(state.oldrowData, v);
      setState({
        ...state,
        [k]: v,
        filters: params,
        rowData: newRawData,
      });
    } else {
      let myparams = "";
      let myparams1 = {};
      for (const [key, value] of Object.entries(params)) {
        if (value && value !== "") {
          if (myparams === "") {
            if (key === "category") {
              myparams = `category_id=${value}`;
              myparams1 = { category_id: value };
            } else if (key === "group") {
              myparams = `group_id=${value}`;
              myparams1 = { group_id: value };
            } else if (key === "subgroup") {
              myparams = `subgroup_id=${value}`;
              myparams1 = { subgroup_id: value };
            } else if (key === "growth") {
              myparams = `growth=${value}`;
            } else if (key === "recency") {
              myparams = `new_existing=${value}`;
            }
          } else {
            if (key === "category") {
              myparams = `${myparams}&category_id=${value}`;
              myparams1 = { ...myparams1, category_id: value };
            } else if (key === "group") {
              if (k === "category") {
                //when category change reset group_id
                myparams = `${myparams}&group_id=""`;
                myparams1 = { ...myparams1 };
              } else {
                myparams = `${myparams}&group_id=${value}`;
                myparams1 = { ...myparams1, group_id: value };
              }
            } else if (key === "subgroup") {
              myparams = `${myparams}&subgroup_id=${value}`;
              myparams1 = { ...myparams1, subgroup_id: value };
            } else if (key === "growth") {
              myparams = `${myparams}&growth=${value}`;
            } else if (key === "recency") {
              myparams = `${myparams}&new_existing=${value}`;
            }
          }
        }
      }
      setState({
        ...state,
        filters: params,
        [k]: v,
      });
      if (myparams !== "") {
        //myparams,
        handleFilter(myparams1, k, v, params);
      } else {
        setState({
          ...state,
          rowData: state.oldrowData,
          [k]: v,
        });
      }
    }
  };

  const handleFilter = (myparams1, k, v, params) => {
    if (state.oldrowData && state.oldrowData.length > 0) {
      const myLatest = state.oldrowData.map((item) => {
        let newItem = item;
        const objUrl = find(tempList, function (o) {
          return o.product_id === item.id;
        });

        if (objUrl && objUrl.product_id) {
          newItem["qty"] = objUrl.qty;
          newItem["subtotal"] = objUrl.subtotal;
        }
        return newItem;
      });

      const newFilter = filter(myLatest, myparams1);

      setState({
        ...state,
        rowData: newFilter,
        filters: params,
        [k]: v,
      });
    }
  };

  const onResetSearch = () => {
    setState({
      ...state,
      category: "",
      group: "",
      subgroup: "",
      growth: "",
      recency: "",
      filters: {
        category: "",
        group: "",
        subgroup: "",
        growth: "",
        recency: "",
      },
      showItemOnly: false,
      rowData: state.oldrowData,
    });
  };
  const onShowItemmOnly = (checked) => {
    if (checked) {
      const newList = getShowItemProductList(state.rowData);
      setState({
        ...state,
        showItemOnly: checked,
        rowData: newList,
      });
    } else {
      setState({
        ...state,
        showItemOnly: checked,
        rowData: state.oldrowData,
      });
    }
  };

  const updateTempList = (newListPromo) => {
    const newListPromo1 = sortOrderByName(
      get(productList, "rowData", []),
      newListPromo
    );
    setTempList(newListPromo1);
  };

  const onCellEditingStopped = (params) => {
    const MOQ = get(params, "data.moq", "");
    let rowNode1 = gridColApi.getRowNode(params.data.code);
    if (
      MOQ !== "" &&
      MOQ !== "Indent" &&
      MOQ !== "*" &&
      has(params, "data.code")
    ) {
      if (params.data.qty < MOQ * 1 && !isOAAsossiate) {
        if (get(params, "data.qty", 0) > 0) {
          setShow(true);
          setMessageError(`MOQ for this product is ${MOQ}`);
        }
        // setShow(true);
        // setMessageError(`MOQ for this product is ${MOQ}`);
        rowNode1.setDataValue("subtotal", 0);
        rowNode1.setDataValue("qty", 0);
      } else {
        rowNode1.setDataValue(
          "subtotal",
          Number(params.data.qty * params.data.price).toFixed(2)
        );
      }
      const newContent = getDiscountedPrice(params);
      // console.log("newContent1", newContent);
      setOrderAmtDefault(params.data.code, newContent);
      // setOrderAmtDefault(params.data.code, params.data);
    } else {
      if (has(params, "data.code")) {
        if (params.data.qty < 1) {
          // setShow(true);
          // setMessageError(
          //   `MOQ for this product is ${get(params, "data.moq", "")}`
          // );
          rowNode1.setDataValue("subtotal", 0);
          rowNode1.setDataValue("qty", 0);
        } else {
          rowNode1.setDataValue(
            "subtotal",
            Number(params.data.qty * params.data.price).toFixed(2)
          );
        }
        const newContent = getDiscountedPrice(params);
        // console.log("newContent", newContent);
        setOrderAmtDefault(params.data.code, newContent);
      }
    }
  };

  const getDiscountedPrice = (params) => {
    const { newValue, oldValue, data } = params;
    const colId = get(params, "column.colId");
    if (colId === "price") {
      if (newValue !== oldValue) {
        // console.log("newValueIn", data.qty, newValue, oldValue);
        const varValue = newValue * 1;
        const checkNewValue = varValue < 0 ? 0 : varValue;
        const myRounded = numeral(checkNewValue).format("0,0.00");
        let rowNode1 = gridColApi.getRowNode(params.data.code);
        // console.log("total", myRounded * data.qty);
        if (rowNode1) {
          rowNode1.setData({
            ...data,
            discounted_price: myRounded,
            unit_price: oldValue,
            subtotal: data.qty * myRounded,
          });
        }
        return {
          ...data,
          discounted_price: myRounded,
          unit_price: oldValue,
          subtotal: data.qty * myRounded,
        };
      }
    }
    return data;
  };

  const setOrderAmtDefault = (ProductCode, data) => {
    // console.log(
    //   "data",
    //   get(data, "discounted_price", get(data, "unit_price", data.price))
    // );
    // console.log("discounted_price", data.discounted_price);
    // console.log("unit_price", data.unit_price);
    // console.log("price", data.price);
    if (ProductCode) {
      let newListPromo = tempList;
      if (newListPromo.length === 0) {
        const itemProduct = {
          product_id: data.id,
          qty: data.qty,
          price: data.default_price,
          subtotal: data.subtotal,
          unit_price: get(data, "unit_price", data.price),
          discounted_price: data.default_price,
          promotions: null,
          class4: data.class4,
          new_existing: get(data, "new_existing", ""),
          target_id: get(data, "target_id", 0),
        };
        newListPromo.push(itemProduct);
      } else {
        const newList = filter(newListPromo, function (o) {
          return o.product_id !== data.id;
        });
        const updateItem = find(newListPromo, function (o) {
          return o.product_id === data.id;
        });
        newListPromo = newList;
        let newItemProduct = {
          product_id: data.id,
          qty: data.qty,
          price: data.default_price,
          subtotal: data.subtotal,
          unit_price: get(data, "unit_price", data.price),
          discounted_price: data.default_price,
          promotions: null,
          class4: data.class4,
          new_existing: get(data, "new_existing", ""),
        };
        if (updateItem && updateItem.product_id) {
          console.log("masuk sini");
          newItemProduct = {
            ...updateItem,
            product_id: data.id,
            qty: data.qty,
            price: data.default_price,
            subtotal: data.subtotal,
            promotions: null,
            class4: data.class4,
            new_existing: get(data, "new_existing", ""),
          };
        }
        newItemProduct["target_id"] = get(data, "target_id", 0);
        newListPromo.push(newItemProduct);
      }
      // let rowNode = gridColApi.getRowNode(ProductCode);
      // if (rowNode) {
      //   rowNode.setDataValue(
      //     "subtotal",
      //     Number(data.qty * data.price).toFixed(2)
      //   );
      // }
      updateTempList(newListPromo);
    }
  };

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <NormalFilterFields
        translate={translate}
        state={state}
        handleChange={(k, v) => handleChange(k, v)}
        onResetSearch={() => onResetSearch()}
        onShowItemmOnly={(e) => onShowItemmOnly(e.target.checked)}
      />
      <div style={{ width: "100%", height: "100%" }}>
        <div className='grid-wrapper'>
          <div
            id='myGridNomrmal'
            style={{
              height: "43vh",
              width: "100%",
            }}
            className='ag-theme-balham'
          >
            <AgGridReact
              rowHeight={40}
              headerHeight={40}
              rowData={state.rowData}
              columnDefs={state.columnDefs}
              defaultColDef={state.defaultColDef}
              animateRows={true}
              getRowStyle={getRowStyleDef}
              getRowNodeId={state.getRowNodeId}
              onGridReady={onGridReady}
              onCellEditingStopped={onCellEditingStopped}
            />
          </div>
        </div>
      </div>
    </Flex>
  );
};

const convertRowData = (
  response,
  promotions,
  tempList,
  isOAAsossiate,
  countryId,
  countryCode
) => {
  // const user = getUser();

  if (response && response !== null && response.length > 0) {
    const myLatest = response.map((item) => {
      const value = checkPromotion(item, promotions);
      // console.log(value);
      let newItem = item;
      const objUrl = find(tempList, function (o) {
        return o.product_id === item.id;
      });

      if (objUrl && objUrl.product_id) {
        newItem["qty"] = objUrl.qty;
        newItem["subtotal"] = objUrl.subtotal;
        // if (isOAAsossiate) {
        //   newItem["price"] = objUrl.price;
        // }
        const mySubtotal = get(objUrl, "subtotal", 0) * 1;
        if (mySubtotal === 0) {
          newItem["price"] = get(objUrl, "discounted_price", 0);
        } else {
          newItem["price"] = objUrl.price;
        }
        newItem["discounted_price"] = get(objUrl, "discounted_price", 0);
        newItem["default_price"] = objUrl.price;
        // console.log("newItem", newItem);
      } else {
        newItem["discounted_price"] = 0;
        newItem["qty"] = 0;
        newItem["subtotal"] = 0;
        newItem["price"] = get(item, "price", 0) * 1;
        newItem["default_price"] = get(item, "price", 0) * 1;
      }

      newItem["cr"] = get(item, "cr", false);
      newItem["dg"] = get(item, "dg", false);
      // (item && item.cr && item.cr === "CR") || toString(item.cr) === "true"
      //   ? "CR"
      //   : "";

      // (item && item.dg && item.dg === "DG") || toString(item.dg) === "true"
      //   ? "DG"
      //   : "";
      // get(item, "cr", false) === true && get(item, "cr", false) === true
      //   ? "CR"
      //   : "";

      // get(item, "dg", false) === true || get(item, "dg", false) === "DG"
      // ? "DG"
      // : "";
      newItem["registration_number"] = getRegisterNumber(item, countryCode);
      // get(
      //   item,
      //   `registration_number[${countryCode}]`,
      //   ""
      // );

      newItem["promo"] = value;
      newItem["target_id"] = get(item, "target_id", 0);
      newItem["growth"] = get(item, `class4.${countryId}`, "");
      return newItem;
    });

    return { rowData: myLatest, oldrowData: myLatest };
  }
  return { rowData: [], oldrowData: [] };
};
const ArListGridNormalDealer = (props) => {
  const { isOAAsossiate, promotions, tempList, countryId, dealerId, country } =
    props;
  const { isLoading, data } = useQuery(
    "productsListNormal1",
    () => getProductsList(dealerId),
    { cacheTime: cacheTimeMinute }
  );

  if (isLoading) {
    return <ContainerLoading />;
  }

  // if (error)
  //   return (
  //     <Flex width={[1]} justifyContent={"center"} alignItems={"center"}>
  //       {"An error has occurred: " + error.message}
  //     </Flex>
  //   );
  const dataProduct = filter(data, function (o) {
    return o.active;
  });
  const myData = uniqBy(dataProduct, "id");
  // console.log(myData);
  const countryCode = toLower(get(country, "code", ""));
  const myDataProducts = convertRowData(
    myData,
    promotions,
    tempList,
    isOAAsossiate,
    countryId,
    countryCode
  );

  return <MainList {...props} productList={myDataProducts} />;
};
export default ArListGridNormalDealer;
