import React from "react";
import Paper from "@material-ui/core/Paper";
import { Flex, Box, Text } from "rebass";
import { Popover } from "antd";
import { get } from "lodash";
import InfoTwoToneIcon from "@material-ui/icons/InfoTwoTone";
import { DateInput, required, useNotify } from "react-admin";
import { formatDate, ROLE_USER, STATUSPOMESSAGE } from "../../utils";
import { TextRecord, UserField } from "../../components";
import { DealerView } from "./po";

const content = (status) => {
  const myStatus = get(STATUSPOMESSAGE, status, "");
  return <div>{myStatus !== "" && <p>{myStatus}</p>}</div>;
};
const OrderInfoShow = (props) => {
  const { record, translate, isEdit, permissions } = props;
  const notify = useNotify();
  return (
    <Box width={[1]} pb={[3]} pt={[2]}>
      <Paper elevation={1}>
        <Box width={[1]} p={[3]}>
          <Flex
            width={[1]}
            flexDirection={"row"}
            alignItems={"center"}
            flexWrap={"wrap"}
          >
            <Flex pr={[4]} flexDirection={"row"}>
              <TextRecord
                pb={2}
                record={record}
                source={"status"}
                label={"resources.po.fields.status"}
                color={"rgba(0,0,0,0.7)"}
              />
              <Box>
                {get(record, "status", "") !== "" && (
                  <Popover
                    placement='top'
                    title={
                      <div style={{ textAlign: "center", fontWeight: "bold" }}>
                        {get(record, "status", "")}
                      </div>
                    }
                    content={() => content(get(record, "status", ""))}
                    trigger='click'
                  >
                    <InfoTwoToneIcon
                      style={{ color: "#FF0000", fontSize: "24px" }}
                    />
                  </Popover>
                )}
              </Box>
            </Flex>
            <Box pr={[4]}>
              <TextRecord
                pb={2}
                record={record}
                source={"created_at"}
                isDate={true}
                formatDate={"DD-MMMM-YYYY"}
                label={"resources.po.fields.created_on"}
                color={"rgba(0,0,0,0.7)"}
              />
            </Box>
            <Box pr={[4]} mb={[1]} pb={[1]}>
              <Text fontWeight={"bold"} fontSize={[0]} pb={[1]}>
                {translate("resources.po.fields.created_by")}
              </Text>
              <UserField
                record={record}
                source={"created_by"}
                optionText={"first_name"}
                optionText1={"last_name"}
                color={"rgba(0,0,0,0.7)"}
              />
            </Box>
            <Box pr={[4]}>
              {isEdit ? (
                <DateInput
                  source={"delivery_date"}
                  label={"resources.po.fields.delivery_date"}
                  parse={formatDate}
                  validate={[required()]}
                />
              ) : (
                <TextRecord
                  pb={2}
                  record={record}
                  source={"delivery_date"}
                  label={"resources.po.fields.delivery_date"}
                  isDate={true}
                  formatDate={"DD-MMMM-YYYY"}
                  color={"rgba(0,0,0,0.7)"}
                />
              )}
            </Box>
            {/* <Box>
              <TextRecord
                source={"status"}
                label={"resources.orders.fields.status"}
                record={record}
              />
            </Box> */}
          </Flex>
          <Box width={[1]}>
            <TextRecord
              source={"remarks"}
              label={"resources.orders.fields.remarks"}
              record={record}
            />
          </Box>
        </Box>
        {permissions !== ROLE_USER.country_manager && (
          <DealerView {...props} po={record} notify={notify} />
        )}
      </Paper>
    </Box>
  );
};

export default OrderInfoShow;
