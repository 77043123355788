import { numberdesimal } from "../utils";
export { default as HeaderTop20 } from "./HeaderTop20";
export { default as ListTop20 } from "./ListTop20";

export const columns = [
  { field: "country", headerName: "Country", width: 130 },
  { field: "dealer", headerName: "Dealer", width: 350 },
  { field: "category", headerName: "Category", width: 256 },
  { field: "target_setting", headerName: "Target Setting", width: 200 },
  { field: "packaging", headerName: "Packaging", width: 330 },
  { field: "invoiced", headerName: "Invoiced", width: 140, ...numberdesimal },
];

export const sortModel = [
  {
    field: "country",
    sort: "asc",
  },
  {
    field: "dealer",
    sort: "asc",
  },
  {
    field: "category",
    sort: "asc",
  },
  {
    field: "target_setting",
    sort: "asc",
  },
  {
    field: "packaging",
    sort: "asc",
  },
  {
    field: "invoiced",
    sort: "asc",
  },
];

export const columnsDealer = [
  { field: "category", headerName: "Category", width: 256 },
  { field: "target_setting", headerName: "Target Setting", width: 200 },
  { field: "packaging", headerName: "Packaging", width: 330 },
  { field: "invoiced", headerName: "Invoiced", width: 140, ...numberdesimal },
];

export const sortModelDealer = [
  {
    field: "category",
    sort: "asc",
  },
  {
    field: "target_setting",
    sort: "asc",
  },
  {
    field: "packaging",
    sort: "asc",
  },
  {
    field: "invoiced",
    sort: "asc",
  },
];
