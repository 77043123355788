import React, { Fragment } from "react";
import { Flex, Box } from "rebass";
import { ContainerItemField } from "./ContainerItemField";
import {
  HeaderPromotion,
  HeaderNormal,
  HeaderButton,
  HeaderPromo,
} from "./Header";
import { useStoreTemp } from "../../../../components/argrid";

const DialogHeader = (props) => {
  const {
    item,
    translate,
    totalQuantity,
    totalAmt,
    thisAmt,
    thisQuantity,
    onSaveOrder,
    tempListBuy,
  } = props;

  const tempList = useStoreTemp((state) => state.tempList);
  return (
    <Flex
      width={[1]}
      flexDirection={"row"}
      pb={[2]}
      justifyContent={"space-between"}
    >
      <Box width={[1, 2 / 5]}>
        {item !== null ? (
          <ContainerItemField item={item} translate={translate} />
        ) : (
          <div />
        )}
      </Box>
      <Flex width={[1, 3 / 5]} flexDirection='column'>
        <Flex
          width={[1]}
          flexDirection='column'
          justifyContent={"stretch"}
          style={{
            backgroundColor: "rgba(0,156,125,0.15)",
            flex: 1,
          }}
        >
          {item !== null ? (
            <Fragment>
              <HeaderPromotion
                translate={translate}
                totalQuantity={totalQuantity}
                thisAmt={thisAmt}
                thisQuantity={thisQuantity}
                totalAmt={totalAmt}
              />
              <HeaderPromo
                translate={translate}
                onSaveOrder={onSaveOrder}
                thisQuantity={thisQuantity}
                item={item}
                tempList={tempList}
                tempListBuy={tempListBuy}
              />
            </Fragment>
          ) : (
            <HeaderNormal
              style={{ backgroundColor: "rgba(0,156,125,0.15)" }}
              translate={translate}
              totalQuantity={totalQuantity}
              totalAmt={totalAmt}
              thisAmt={thisAmt}
              thisQuantity={thisQuantity}
            />
          )}
        </Flex>
        {item === null && (
          <Flex width={[1]} flexDirection='column' p={[3]}>
            <HeaderButton translate={translate} onSaveOrder={onSaveOrder} />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default DialogHeader;
