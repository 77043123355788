import {
  getTotalQtyFreeProductPromoABCD,
  getTotalQtyFreeProductPromo,
} from "./utils";
import { sumBy, map, filter } from "lodash";

export const getTotalFreeXY = (item, tempListBuy, promoType) => {
  const listBXGY = filter(tempListBuy, function (o) {
    return !o.foc;
  });

  // console.log(promoType, "listBXGY", listBXGY);
  const totalListQty = sumBy(listBXGY, "qty");
  const qtyTotalFree = getTotalQtyFreeProductPromo(
    item,
    totalListQty,
    promoType
  );
  return qtyTotalFree;
};

export const getTotalFree = (item, tempListBuy) => {
  const listTempQty = filter(tempListBuy, function (o) {
    return !o.foc;
  });
  // const listTempQtyA = filter(tempListBuy, function (o) {
  //   return !o.foc && o.promo === 1;
  // });
  // const listTempQtyB = filter(tempListBuy, function (o) {
  //   return !o.foc && o.promo === 2;
  // });
  const totalListQtyA = sumBy(listTempQty, "qty");
  // const totalListQtyB = sumBy(listTempQtyB, "qty");
  // const total =
  //   totalListQtyA > totalListQtyB
  //     ? totalListQtyB === 0
  //       ? totalListQtyA
  //       : totalListQtyB
  //     : totalListQtyA;
  // const total = totalListQtyA > totalListQtyB ? totalListQtyA : totalListQtyB;
  // const total = totalListQtyA + totalListQtyB;
  // console.log("total", total);
  const qtyTotalFreeC = getTotalQtyFreeProductPromoABCD(item, totalListQtyA);
  // console.log("qtyTotalFreeC", qtyTotalFreeC);
  return qtyTotalFreeC;
};

export const getTotalSelected = (list) => {
  //type
  let newListRaw = [];
  map(list, function (o) {
    // if (o.foc && o.qty > 0 && o.promo === type) {
    if (o.foc && o.qty > 0) {
      newListRaw.push(o);
    }
    return o;
  });
  return newListRaw;
};

export const getTotalSelectedFree = (list) => {
  let newListRaw = [];
  map(list, function (o) {
    if (o.foc && o.qty > 0) {
      newListRaw.push(o);
    }
    return o;
  });
  const totalSelectedQty = sumBy(newListRaw, "qty");
  return totalSelectedQty;
};

export const getTotalSelectedB = (list) => {
  let newListRaw = [];
  map(list, function (o) {
    if (o.foc && o.qty > 0 && o.promo === 2) {
      newListRaw.push(o);
    }
    return o;
  });
  const totalSelectedQty = sumBy(newListRaw, "qty");
  return totalSelectedQty;
};

export const getTotalFreeC = (list) => {};

export const getTotalFreeD = (list) => {};
