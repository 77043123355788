import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
import { styles } from "./styles";
import MenuItemLink from "../MenuItemLink";
import { StarIcon, Chart8 } from "../../icon_svg";
import { MenuReport } from ".";
const MenuCounrtyManager = (props) => {
  const { onMenuClick, ...rest } = props;
  const translate = useTranslate();
  return (
    <Fragment>
      <MenuItemLink
        leftIcon={<Chart8 />}
        to={`/`}
        primaryText={translate(`resources.menus.dashboard`, {
          smart_count: 2,
        })}
        onClick={onMenuClick}
        translate={translate}
      />
      <MenuReport onMenuClick={onMenuClick} {...rest} />
      <MenuItemLink
        leftIcon={<StarIcon />}
        to={`/orders`}
        primaryText={translate(`resources.menus.orders`, {
          smart_count: 2,
        })}
        onClick={onMenuClick}
        translate={translate}
      />
    </Fragment>
  );
};

export default withStyles(styles)(MenuCounrtyManager);
