import React from "react";
import { Loading, useAuthState, usePermissions } from "react-admin";
import { get } from "lodash";
import { getUser, ROLE_USER } from "../../utils";
import { NoAccessPage } from "../../components";
import MainReport from "./MainReport";

const List = (props) => {
  const { permissions } = usePermissions();
  const { loading, authenticated } = useAuthState();
  const user = getUser();

  if (loading) {
    return <Loading />;
  }
  if (authenticated && permissions === ROLE_USER.country_manager) {
    const countryId = get(user, "country_id");
    return (
      <MainReport
        {...props}
        countryId={countryId}
        user={user}
        permissions={permissions}
      />
    );
  }

  return authenticated ? (
    <MainReport {...props} user={user} permissions={permissions} />
  ) : (
    <NoAccessPage />
  );
};

export default List;
