import React from "react";
import { Box, Text } from "rebass";
import { get } from "lodash";
const RemarksView = (props) => {
  const { item, translate } = props;
  // if (get(item, "remarks", "") === null || get(item, "remarks", "") === "") {
  //   return <div />;
  // }
  return (
    <div>
      <Text fontSize={[1]}>{translate("resources.po.fields.remarks")}</Text>
      <Box width={[1]} p={[2]}>
        <Text fontSize={[1]}>
          {get(item, "remarks", "") !== null ? get(item, "remarks", "") : ""}
        </Text>
      </Box>
    </div>
  );
};

export default RemarksView;
