import React from "react";
import { Create } from "react-admin";
import { get, includes, toLower } from "lodash";
import {
  ActionForm,
  FooterToolbar,
  BetterCustomSimpleForm,
} from "../components";
import { FormFields } from "./components";
const PriceBookCreate = (props) => {
  const { location } = props;

  const searchType = get(location, "search", "");
  const myType = includes(searchType, "dealer")
    ? "Dealer"
    : includes(searchType, "customer")
    ? "Customer"
    : "";

  return (
    <Create
      {...props}
      actions={
        <ActionForm
          title={"resources.pricebook.new_pricebook"}
          search={`${myType === "Dealer" ? "" : "/" + toLower(myType)}`}
        />
      }
      redirect={"show"}
    >
      <BetterCustomSimpleForm
        {...props}
        toolbar={<FooterToolbar />}
        initialValues={{ active: true, type: myType }}
        sourceApi='priceBooks'
        title={""}
        typePostUrl={"CREATE"}
        pathUrl='priceBooks'
        customBackUrl='/priceBooks'
        isHideTitle={true}
      >
        <FormFields {...props} myType={myType} />
      </BetterCustomSimpleForm>
    </Create>
  );
};

export default PriceBookCreate;
