export default {
  po: {
    title: "Order Pembelian",
    edit_po: "Ubah Order Pembelian",
    new_po: "Order Pembelian Baru",
    add_product_to_order: "Menambah Produk ke dalam Pesanan",
    tabs: {
      order_items: "Barang Pesanan",
      good_receipts: "Tanda Terima Barang",
    },
    fields: {
      po_number: "No PO",
      date: "Tanggal",
      status: "Status",
      total: "Total",
      created_by: "Dibuat oleh",
      created_on: "Dibuat pada",
      delivery_date: "Tanggal pengiriman",
      product: "Produk",
      promotion: "Promosi",
      code: "Kode",
      unit_price: "Harga satuan",
      moq: "MOQ",
      qty: "Qty",
      quantity: "Quantity",
      price: "Harga",
      subtotal: "Subtotal",
      remarks: "Catatan",
      order_items: "Barang Pesanan",
      order_cart: "Keranjang Pesanan",
      batch_on_goods_receipts: "Batch Diterima pada",
      ordered: "Dipesan",
      received: "Diterima",
      category: "Kategori",
      free_items: "Gratis Produk",
      ref_number: "Nomor Referensi",
      amount: "Amount",
      my_ref: "My Reference",
      gc_ref: "GC Reference",
      receive: "Receive",
      hours: "Hours",
      total_backorder: "Total Backorder",
      total_ordered: "Total Ordered",
      total_received: "Total Received",
      country_remarks: "Country Remarks",
      invoiced: "Invoiced",
      total_balance: "Total Balance",
    },
    table: {
      packaging: "Product Name",
      code: "Code",
      price: "Harga",
      qty: "Qty",
      subtotal: "Subtotal",
      target: "Target",
      growth: "Growth",
      speed: "Speed",
      order_intake: "Order Intake",
      po_amt: "PO Amt",
      ytd: "YTD",
      mtd: "MTD",
      qtd: "QTD",
      mth: "/Mth T",
      qt: "/Q T",
      yt: "/Y T",
      backorder: "Backorder",
      invoice_sales: "Invoice Sales",
      total: "Total",
      discounted_price: "Discounted Price",
    },
    listgrid: {
      total_order_qty: "Total Order Qty",
      total_order_amt: "Total Order Amt",
      this_promo_order_qty: "This Promo Order Qty",
      this_promo_order_amt: "This Promo Order Amt",
      this_order_qty: "This Order Qty",
      this_order_amt: "This Order Amt",
      free: "FREE",
      this_promo_free_qty: "Promo ini gratis",
      total_saving: "Total diperoleh",
      add_to_order: "Add to Order",
      label: {
        subgroup: "Sub Group",
        group: "Product Group",
        recency: "New/Existing Product",
        category: "Product Category",
        product_packaging: "Product Packaging",
        product_name: "Product Name",
        growth: "Growth/Speed Product",
        valid_from: "Valid dari",
        to: "sampai",
        promo_items_form: "Form item promosi",
        promo_items: "Item Promosi",
        normal_item_price: "Form Pesanan Non-Promo",
        item: "Item",
        items: "Item",
        entitled: "Entitled",
        selected: "Selected",
        remaining: "Remaining",
        subtotal: "Subtotal",
        total: "Total",
        shipping: "shipping",
        tax_10: "Tax 10%",
        tax: "Tax ",
        package_charge: "Packing Charge",
      },
    },
    note_text: {
      empty_good_receipts: "Anda belum menerima pengiriman dari PO ini",
      empty_order_item: "Belum Produk dalam list pemesanan.",
    },
    messages: {
      no_have_items: "You don’t have any item in the list yet",
      product_qty: "Produk atau Qty tidak valid",
      orderItemsEmpty: "Silakan tambahkan produk ke Item Pemesanan",
      create_order: "Buat Pembelian Gagal",
      create_order_items: "Buat Item Pesanan Gagal",
      product_added: "Produk telah ditambahkan",
      element_created: "Element telah dibuat",
      element_updated: "Element telah Diperbaharui",
      error_create_goods_receipts: "Buat Penerimaan Barang Gagal",
      error_empty_item_goods_receipts: "Did you receive the goods?",
      order_qty_min: "This product has MOQ of",
      order_qty_min1: ". Please increase your order qty.",
      expired_promotions:
        "Some of the Promotions have expired. Items will be removed from Order Form",
      order_submited: "Order Submited",
      inactive_item_promotions: "Some of the Promotions have expired.",
    },
  },
};
