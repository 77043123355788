export default {
  settings: {
    name: "Settings",
    tabs: {
      code: "Code",
      countries: "Countries",
      dealers: "Dealers",
      others: "Others",
    },
    labels: {
      new_code: "New Code",
      edit_code: "Edit Code",
      new_country: "New Country",
      edit_country: "Edit Country",
      new_dealer: "New Dealer",
      edit_dealer: "Edit Dealer",
      new_orther: "New Other",
      edit_orther: "Edit Other",
      po_additional_charges: "PO Additional Charges",
      reminder: "Reminder",
    },
    fields: {
      active: "Active",
      type: "Type",
      label: "Label",
      sort_order: "Sort Order",
      code: "Code",
      remarks: "Order Form Remarks",
      country: "Country",
      dealer: "Dealer",
      dealer_name: "Dealer Name",
      email: "Email",
      currency: "Currency",
      phone: "No. Telp",
      address: "Alamat",
      auto_receipt: "Auto Receipt",
      gc_admin_order_email: "GC Admin Order Notification Email",
      packaging_charges: {
        name: "Packaging charge",
        less_sgd_10k: "<SGD10K",
        more_sgd_10k: ">SGD10K",
        less_usd_10k: "<USD10K",
        more_usd_10k: ">USD10K",
      },
      gst: {
        name: "GST",
        percent: "GST %",
        freight_charge: "Freight Charge",
        insurance: "Insurance",
      },
      others: {
        promotion_expiry_reminder: "Pengingat batas akhir promosi",
        day: "Hari",
        days: "Hari",
        sop_running_number: "SOP Running Number",
        sop_prefix: "SOP Prefix",
      },
    },
    note_text: {
      code: "Don’t edit code once it is being used",
      sort_order: "Higher # will be displayed first",
    },
  },
};
