import React, { useState, useEffect } from "react"
import { Flex } from "rebass"
import {
  ReferenceInput,
  SelectInput,
  useQueryWithStore,
  Loading,
} from "react-admin"
import { TextField } from "@material-ui/core"
import ProductItem from "./ProductItem"

const ListProducts = (props) => {
  const { translate, classes } = props
  const [category, setCategory] = useState(0)
  const [search, setSearch] = useState("")
  const [searchText, setSearchText] = useState("")
  const [isRequest, setIsRequest] = useState(false)
  const paramCategory = category === 0 ? {} : { category: category }
  const { data, loaded, loading } = useQueryWithStore({
    type: "getList",
    resource: "products",
    payload: {
      pagination: { page: 1, perPage: 30 },
      sort: { field: "id", order: "ASC" },
      filter: { search: searchText, ...paramCategory },
    },
  })
  const handleChange = (name) => (event) => {
    if (name === "search") {
      setSearch(event.target.value)
    }
    setIsRequest(true)
  }
  const onCategory = (e) => {
    // console.log(e.target.value)
    setCategory(e.target.value)
  }

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (isRequest) {
        setSearchText(search)
        setIsRequest(false)
      }
    }, 500)
    return () => clearTimeout(timeout)
  })
  // console.log(data)
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <Flex
        flexDirection={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        p={[2]}
        className={classes.root}
      >
        <ReferenceInput
          label="resources.po.fields.category"
          source="category_id"
          reference="categories"
          onChange={onCategory}
          style={{ width: 256 }}
          allowEmpty
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextField
          id="search"
          name={"search"}
          label={translate("resources.po.fields.product")}
          InputLabelProps={{
            shrink: true,
          }}
          value={search}
          onChange={handleChange("search")}
          style={{ width: 256 }}
        />
      </Flex>
      <Flex width={1} flexDirection={"column"}>
        {loading && <Loading />}
        {loaded && (
          <Flex
            width={[1]}
            flexDirection={"row"}
            flexWrap={"wrap"}
            className={classes.root}
            p={[3]}
          >
            {data &&
              data.length > 0 &&
              data.map((item, index) => {
                return (
                  <ProductItem
                    key={index}
                    product={item}
                    translate={translate}
                    {...props}
                  />
                )
              })}
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
export default ListProducts
