import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-admin";
import { styles } from "./styles";
import MenuItemLink from "../MenuItemLink";
import { SettingsIcon, User1Icon } from "../../icon_svg";

const MenuAdmin = (props) => {
  const { onMenuClick } = props;

  const translate = useTranslate();
  return (
    <Fragment>
      <MenuItemLink
        to={`/codes`}
        primaryText={translate(`resources.menus.settings`, {
          smart_count: 2,
        })}
        onClick={onMenuClick}
        translate={translate}
        leftIcon={<SettingsIcon />}
      />
      <MenuItemLink
        to={`/users`}
        primaryText={translate(`resources.menus.users`, {
          smart_count: 2,
        })}
        leftIcon={<User1Icon />}
        onClick={onMenuClick}
        translate={translate}
      />
    </Fragment>
  );
};

export default withStyles(styles)(MenuAdmin);
