import React from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import { TextField, ArrayField } from "react-admin";
import { useStylesTheme } from "./styles";
import { withStyles, createStyles } from "@material-ui/core/styles";
import CardTable from "./CardTable";
import { ThemeProvider } from "@material-ui/styles";
import { themeTableInput } from "../../../themes";
import { TextRecord, ReferenceTextRecord, Datagrid } from "../../../components";

const defaultBuyX_GetY = [
  { buy: 0, get: 0, moq: 0, additional: 0 },
  { buy: 0, get: 0, moq: 0, additional: 0 },
  { buy: 0, get: 0, moq: 0, additional: 0 },
];

const mystyles = (theme) =>
  createStyles({
    root: {
      width: "100%",
    },
  });

const ViewBuyXGetYFields = (props) => {
  const { formData, translate, classes, dataProducts, ...rest } = props;
  // console.log(props.dataProducts);
  const { record } = rest;
  const myClass = useStylesTheme();
  const type_x = get(record, "criteria.buy_x_get_y.x.type", "");
  const type_y = get(record, "criteria.buy_x_get_y.y.type", "");
  return (
    <Flex
      width={[1]}
      flexDirection={"column"}
      sx={{
        "&>div": {
          pb: [3],
        },
      }}
    >
      <CardTable {...props} title={"resources.promotions.fields.buy_x"}>
        <Flex flexDirection='row' width={[1]} justifyContent='flex-start'>
          <TextRecord
            {...rest}
            source='criteria.buy_x_get_y.x.type'
            label={"resources.promotions.labels.criteria_type_x"}
          />
          {(type_x === "ProductGroup" || type_x === "SubProductGroup") && (
            <ReferenceTextRecord
              {...rest}
              label={"resources.promotions.labels.criteria_x"}
              source='criteria.buy_x_get_y.x.id'
              reference='codes'
              filter={{ type: type_x, active: true }}
              optionText='label'
            />
          )}
          {type_x === "ProductCode" && (
            <ReferenceTextRecord
              {...rest}
              label={"resources.promotions.labels.criteria_x"}
              source='criteria.buy_x_get_y.x.id'
              reference='products'
              optionText='packaging'
              optionValue={"code"}
            />
          )}
          {type_x === "ProductShortCode" && (
            <ReferenceTextRecord
              {...rest}
              label={"resources.promotions.labels.criteria_x"}
              source='criteria.buy_x_get_y.x.id'
              reference='products'
              optionText='packaging'
              optionValue={"short_code"}
            />
          )}
        </Flex>
      </CardTable>

      <CardTable {...props} title={"resources.promotions.fields.get_y"}>
        <Flex flexDirection='row' width={[1]} justifyContent='flex-start'>
          <TextRecord
            {...rest}
            source='criteria.buy_x_get_y.y.type'
            label={"resources.promotions.labels.criteria_type_y"}
          />

          {(type_y === "ProductGroup" || type_y === "SubProductGroup") && (
            <ReferenceTextRecord
              {...rest}
              label={"resources.promotions.labels.criteria_y"}
              source='criteria.buy_x_get_y.y.id'
              reference='codes'
              filter={{ type: type_y, active: true }}
              optionText='label'
            />
          )}
          {type_y === "ProductCode" && (
            <ReferenceTextRecord
              {...rest}
              label={"resources.promotions.labels.criteria_y"}
              source='criteria.buy_x_get_y.y.id'
              reference='products'
              optionText='packaging'
              optionValue={"code"}
            />
          )}
          {type_y === "ProductShortCode" && (
            <ReferenceTextRecord
              {...rest}
              label={"resources.promotions.labels.criteria_y"}
              source='criteria.buy_x_get_y.y.id'
              reference='products'
              optionText='packaging'
              optionValue={"short_code"}
            />
          )}
        </Flex>
      </CardTable>
      <ThemeProvider theme={themeTableInput}>
        <Flex width={[1]} flexDirection={"column"}>
          <ArrayField
            source='criteria.buy_x_get_y.moqs'
            {...rest}
            label={""}
            defaultValue={defaultBuyX_GetY}
          >
            <Datagrid style={{ width: "100%" }} classes={myClass}>
              <TextField
                source='moq'
                style={{ width: 100, margin: 0 }}
                label={"resources.promotions.fields.moq"}
              />
              <TextField
                source='buy'
                style={{ width: 100, margin: 0 }}
                label={"resources.promotions.fields.buy"}
              />
              <TextField
                source='get'
                style={{ width: 100, margin: 0 }}
                label={"resources.promotions.fields.get"}
              />
              <TextField
                source='additional'
                style={{ width: 100, margin: 0 }}
                label={"resources.promotions.fields.additional"}
              />
            </Datagrid>
          </ArrayField>
        </Flex>
      </ThemeProvider>
    </Flex>
  );
};

export default withStyles(mystyles)(ViewBuyXGetYFields);
