import React, { Fragment, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { Flex } from "rebass";
import { Loading } from "react-admin";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import { RestApiCURD } from "../../restClient";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { SODList, ReportExportCsvFile } from "../../screens/reports";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const ButtonSODetail = (props) => {
  const { record, classes } = props;
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isRequest, setIsRequest] = useState(false);
  const [list, setList] = useState([]);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);

    loadList();
  };
  const loadList = () => {
    if (record && record.id) {
      setIsRequest(true);
      const params = {
        sell_out_id: record.id,
      };
      RestApiCURD(`reports2/sellOutDetail`, "POST", params)
        .then((resp) => resp.json())
        .then((response) => {
          if (response) {
            if (response.message) {
              setList([]);
              setMessage(response.message);
            } else {
              setList(response);
            }
          }
          setIsRequest(false);
          console.log("response", response);
        })
        .catch((e) => {
          setList([]);
          setIsRequest(false);
          setMessage(e);
          console.log("error", e);
        });
    } else {
      setList([]);
      setMessage("Id not found");
    }
  };
  return (
    <Fragment>
      <Flex flexDirection={"row"} justifyContent={"flex-end"}>
        <IconButton
          onClick={() => handleOpen()}
          style={{
            textAlign: "right",
            padding: 7,
            marginRight: 8,
          }}
        >
          <CloudDownloadIcon color='primary' style={{ fontSize: 14 }} />
        </IconButton>
      </Flex>
      <Dialog
        fullScreen
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='report-title'
        aria-describedby='report-description'
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={handleClose}
              aria-label='close'
              style={{ marginLeft: 4, marginRight: 4 }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant='h6' className={classes.title}>
              Export Sell out Summary Detail
            </Typography>
            {/* */}
          </Toolbar>
        </AppBar>
        <Flex
          width={[1]}
          flexDirection={"column"}
          style={{ flex: 1, overflowY: "auto" }}
          pt={["60px"]}
        >
          {isRequest && (
            <Flex
              width={[1]}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Loading />
            </Flex>
          )}
          {!isRequest && (
            <DialogContent>
              <ReportExportCsvFile list={list} reportType={"sellOutDetail"} />
              <SODList list={list} height={"70vh"} />
            </DialogContent>
          )}
          {list.length === 0 && message !== "" && (
            <Flex
              width={[1]}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              pt={["60px"]}
            >
              <Typography variant='h6' className={classes.title}>
                {message}
              </Typography>
              <Button
                variant='contained'
                onClick={() => loadList()}
                color='primary'
                style={{ marginTop: 10 }}
              >
                Refresh
              </Button>
            </Flex>
          )}
        </Flex>
      </Dialog>
    </Fragment>
  );
};

export default withStyles(styles)(ButtonSODetail);
