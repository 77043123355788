import React from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import { TextField, ArrayField } from "react-admin";
import { withStyles, createStyles } from "@material-ui/core/styles";
import CardTable from "./CardTable";
import { ThemeProvider } from "@material-ui/styles";
import { themeTableInput } from "../../../themes";
import { useStylesTheme } from "./styles";
import { TextRecord, ReferenceTextRecord, Datagrid } from "../../../components";

const defaultViewBuyABGetCD = [
  { buy: 0, get: 0, moq: 0, additional: 0 },
  { buy: 0, get: 0, moq: 0, additional: 0 },
  { buy: 0, get: 0, moq: 0, additional: 0 },
];

const mystyles = (theme) =>
  createStyles({
    root: {
      width: "100%",
    },
  });

const ViewBuyABGetCD = (props) => {
  const { formData, translate, classes, dataProducts, ...rest } = props;
  const myClass = useStylesTheme();
  const { record } = rest;
  const type_a = get(record, "criteria.buy_ab_get_cd.a.type", "");
  const type_b = get(record, "criteria.buy_ab_get_cd.b.type", "");
  const type_c = get(record, "criteria.buy_ab_get_cd.c.type", "");
  const type_d = get(record, "criteria.buy_ab_get_cd.d.type", "");
  return (
    <Flex
      width={[1]}
      flexDirection={"column"}
      sx={{
        "&>div": {
          pb: [3],
        },
      }}
    >
      <CardTable {...props} title={"resources.promotions.labels.buy_a_or_b"}>
        <Flex flexDirection='row' width={[1]} justifyContent='flex-start'>
          <TextRecord
            {...rest}
            source='criteria.buy_ab_get_cd.a.type'
            label={"resources.promotions.labels.criteria_type_a"}
          />
          {(type_a === "ProductGroup" || type_a === "SubProductGroup") && (
            <ReferenceTextRecord
              {...rest}
              label={"resources.promotions.labels.criteria_a"}
              source='criteria.buy_ab_get_cd.a.id'
              reference='codes'
              filter={{ type: type_a, active: true }}
              optionText='label'
            />
          )}
          {type_a === "ProductCode" && (
            <ReferenceTextRecord
              {...rest}
              label={"resources.promotions.labels.criteria_a"}
              source='criteria.buy_ab_get_cd.a.id'
              reference='products'
              optionText='packaging'
              optionValue={"code"}
            />
          )}
          {type_a === "ProductShortCode" && (
            <ReferenceTextRecord
              {...rest}
              label={"resources.promotions.labels.criteria_a"}
              source='criteria.buy_ab_get_cd.a.id'
              reference='products'
              optionText='packaging'
              optionValue={"short_code"}
            />
          )}
        </Flex>
        <Flex flexDirection='row' width={[1]} justifyContent='flex-start'>
          <TextRecord
            {...rest}
            source='criteria.buy_ab_get_cd.b.type'
            label={"resources.promotions.labels.criteria_type_b"}
          />
          {(type_b === "ProductGroup" || type_b === "SubProductGroup") && (
            <ReferenceTextRecord
              {...rest}
              label={"resources.promotions.labels.criteria_b"}
              source='criteria.buy_ab_get_cd.b.id'
              reference='codes'
              filter={{ type: type_b, active: true }}
              optionText='label'
            />
          )}
          {type_b === "ProductCode" && (
            <ReferenceTextRecord
              {...rest}
              label={"resources.promotions.labels.criteria_b"}
              source='criteria.buy_ab_get_cd.b.id'
              reference='products'
              optionText='packaging'
              optionValue={"code"}
            />
          )}
          {type_b === "ProductShortCode" && (
            <ReferenceTextRecord
              {...rest}
              label={"resources.promotions.labels.criteria_b"}
              source='criteria.buy_ab_get_cd.b.id'
              reference='products'
              optionText='packaging'
              optionValue={"short_code"}
            />
          )}
        </Flex>
      </CardTable>

      <CardTable {...props} title={"resources.promotions.labels.get_c_or_d"}>
        <Flex flexDirection='row' width={[1]} justifyContent='flex-start'>
          <TextRecord
            {...rest}
            source='criteria.buy_ab_get_cd.c.type'
            label={"resources.promotions.labels.criteria_type_c"}
          />
          {(type_c === "ProductGroup" || type_c === "SubProductGroup") && (
            <ReferenceTextRecord
              {...rest}
              label={"resources.promotions.labels.criteria_c"}
              source='criteria.buy_ab_get_cd.c.id'
              reference='codes'
              filter={{ type: type_c, active: true }}
              optionText='label'
            />
          )}
          {type_c === "ProductCode" && (
            <ReferenceTextRecord
              {...rest}
              label={"resources.promotions.labels.criteria_c"}
              source='criteria.buy_ab_get_cd.c.id'
              reference='products'
              optionText='packaging'
              optionValue={"code"}
            />
          )}
          {type_c === "ProductShortCode" && (
            <ReferenceTextRecord
              {...rest}
              label={"resources.promotions.labels.criteria_c"}
              source='criteria.buy_ab_get_cd.c.id'
              reference='products'
              optionText='packaging'
              optionValue={"short_code"}
            />
          )}
        </Flex>
        <Flex flexDirection='row' width={[1]} justifyContent='flex-start'>
          <TextRecord
            {...rest}
            source='criteria.buy_ab_get_cd.d.type'
            label={"resources.promotions.labels.criteria_type_d"}
          />
          {(type_d === "ProductGroup" || type_d === "SubProductGroup") && (
            <ReferenceTextRecord
              {...rest}
              label={"resources.promotions.labels.criteria_d"}
              source='criteria.buy_ab_get_cd.d.id'
              reference='codes'
              filter={{ type: type_d, active: true }}
              optionText='label'
            />
          )}
          {type_d === "ProductCode" && (
            <ReferenceTextRecord
              {...rest}
              label={"resources.promotions.labels.criteria_d"}
              source='criteria.buy_ab_get_cd.d.id'
              reference='products'
              optionText='packaging'
              optionValue={"code"}
            />
          )}
          {type_d === "ProductShortCode" && (
            <ReferenceTextRecord
              {...rest}
              label={"resources.promotions.labels.criteria_d"}
              source='criteria.buy_ab_get_cd.d.id'
              reference='products'
              optionText='packaging'
              optionValue={"short_code"}
            />
          )}
        </Flex>
      </CardTable>
      <ThemeProvider theme={themeTableInput}>
        <Flex width={[1]} flexDirection={"column"}>
          <ArrayField
            source='criteria.buy_ab_get_cd.moqs'
            {...rest}
            label={""}
            defaultValue={defaultViewBuyABGetCD}
          >
            <Datagrid style={{ width: "100%" }} classes={myClass}>
              <TextField
                source='moq'
                style={{ width: 100, margin: 0 }}
                label={"resources.promotions.fields.moq"}
              />
              <TextField
                source='buy'
                style={{ width: 100, margin: 0 }}
                label={"resources.promotions.fields.buy"}
              />
              <TextField
                source='get'
                style={{ width: 100, margin: 0 }}
                label={"resources.promotions.fields.get"}
              />
              <TextField
                source='additional'
                style={{ width: 100, margin: 0 }}
                label={"resources.promotions.fields.additional"}
              />
            </Datagrid>
          </ArrayField>
        </Flex>
      </ThemeProvider>
    </Flex>
  );
};
export default withStyles(mystyles)(ViewBuyABGetCD);
