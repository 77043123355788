export default {
  products: {
    name: "Produk",
    new_product: "Produk Baru",
    edit_product: "Ubah Produk",
    show_product: "Produk",
    title_expand: "Batasan",
    title_registration: "Nomor pendaftaran",
    tabs: {
      info: "Info",
      variants: "Varian",
      images: "Gambar",
      attributes: "Atribut",
      custom: "Kustomisasi",
      bundles: "bundel",
      details: "Detail",
      analytics: "Analisa",
      country: "Country",
    },
    fields: {
      packaging: "Packing",
      moq: "MOQ",
      active: "Aktif",
      name: "Nama",
      default_price: "Harga Default",
      id: "ID",
      sg: "SG",
      my: "MY",
      th: "TH",
      photos: "Foto",
      type: "Tipe",
      remarks: "Catatan",
      terms: "Ketentuan",
      categories: "kategori",
      expiry_days: "Hari Kedaluwarsa",
      points: "Poin",
      value: "Value",
      point_need: "Poin yang Dibutuhkan",
      reward_value: "Nilai Hadiah",
      sku: "SKU",
      min_order_value: "Nilai Pesanan Minimum",
      reward_validity: "Validitas Hadiah (Hari)",
      not_in_branches: "Tidak di Cabang",
      cost: "Biaya",
      price: "Harga",
      margin: "Marjin",
      slug: "Slug",
      brand: "Merek",
      sort_order: "Sortir Pesanan",
      shipping_weight: "Berat Pengiriman (kg)",
      discount: "Diskon",
      unit_price: "Patokan harga",
      description: "Deskripsi",
      unit: "Satuan",
      value_number: "Nilai (dalam Angka)",
      option_title: "Judul Opsi",
      option_page: "Halaman Konten Opsi",
      option_type: "Jenis Opsi",
      option_required: "Wajib",
      title: "Judul",
      addvalue: "Tambahkan nilai",
      product: "Produk",
      code: "Kode",
      threshold: "Ambang",
      name_sku: "Nama & SKU",
      variant: "Varian",
      options: "Opsi",
      attribute: "Atribut",
      filters: "Filter",
      product_image: "Gambar Produk",
      category: "Kategori",
      group: "Group",
      classifications: "Klasifikasi",
      class_1: "Klasifikasi 1",
      class_2: "Klasifikasi 2",
      class_3: "Klasifikasi 3",
      target_setting: "Target Setting",
      product_link: "Product Link",
      dg: "DG",
      cr: "CR",
      new_existing: "New/Existing",
      product_packaging: "Product Packaging",
      product_name: "Product Name",
      product_code: "Product Code",
      short_code: "Short Code",
      product_group: "Product Group",
      sub_product_group: "Sub Product Group",
      global_code: "Global Code",
      effective_date: "Effective Date",
    },
    note_text: {
      image_variant:
        "Penting: Harap lengkapi informasi Varian terlebih dahulu sebelum mengunggah gambar produk.",
      slug: "Url produk",
      point_need: "Poin loyalitas diperlukan untuk menebus hadiah ini",
      reward_value: "Jumlah yang akan dikurangkan dari subtotal",
      sku: "Terbatas hanya untuk SKU di atas",
      min_order_value:
        "Jumlah pesanan minimum dalam keranjang belanja selama checkout",
      reward_validity:
        "Jumlah Batas hari berlaku setelah penebusan. Masukkan 0 jika tidak kedaluwarsa",
      not_in_branches:
        "Daftar cabang yang tidak berpartisipasi untuk produk ini",
      check_name_product:
        "Silakan isi nama pada tab info dan lengkapi Informasi Varian untuk mengunggah gambar",
    },
  },
};
