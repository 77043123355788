import React, { Fragment, useState } from "react";
import { useRefresh } from "react-admin";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { Flex, Text } from "rebass";
import { UploadClientCSV } from "../../restClient";
const ButtonUploadCsvInvoice = (props) => {
  const {
    accept,
    disableClick,
    maxSize,
    minSize,
    multiple,
    translate,
    notify,
    urlPath,
    label,
    onClose,
    month,
  } = props;
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const onDrop = (accepted, rejected) => {
    setLoading(true);
    if (accepted.length > 0 && rejected.length === 0) {
      // console.log(accepted);
      let formData = new FormData();
      accepted.forEach((file) => {
        // console.log(file);
        formData.append("file", file);
      });

      // console.log(urlPath, formData);
      formData.append("month", month);

      UploadClientCSV(formData, urlPath)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          //error
          if (data && data.length > 0) {
            // this.onAdd(data.url);
            onClose(data);
          } else {
            notify("resources.targets.message.success", "success");
            refresh();
            // notify(translate("resources.targets.message.max_size"), "warning");
          }
          setLoading(false);
        })
        .catch((e) => {
          notify("resources.targets.message.message_failed", "warning");
          setLoading(false);
        });
    } else {
      notify("resources.targets.message.message_failed", "warning");
      setLoading(false);
    }
  };
  return (
    <Fragment>
      <Dropzone
        onDrop={onDrop}
        accept={accept}
        disableClick={disableClick}
        maxSize={maxSize}
        minSize={minSize}
        multiple={multiple}
      >
        {({ getRootProps, getInputProps }) => (
          <Fragment>
            <div {...getRootProps()}>
              <Button
                disabled={disableClick}
                color='primary'
                style={{
                  textTransform: "capitalize",
                  minWidth: 160,
                }}
                startIcon={
                  loading ? (
                    <CircularProgress style={{ width: 16, height: 16 }} />
                  ) : null
                }
              >
                {translate(label)}
              </Button>
              <input {...getInputProps()} />

              {/* {loading && (
                <Flex flexDirection={"row"} alignItems="center" p={[2]}>
                  <CircularProgress /> <Text px={[2]}>Loading ...</Text>
                </Flex>
              )} */}
            </div>
          </Fragment>
        )}
      </Dropzone>
    </Fragment>
  );
};

ButtonUploadCsvInvoice.propTypes = {
  accept: PropTypes.string,
  children: PropTypes.element,
  disableClick: PropTypes.bool,
  elStyle: PropTypes.object,
  input: PropTypes.object,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  multiple: PropTypes.bool,
  style: PropTypes.object,
  placeholder: PropTypes.node,
  updateField: PropTypes.func,
};

ButtonUploadCsvInvoice.defaultProps = {
  addLabel: false,
  addField: true,
  multiple: false,
  maxSize: 10000000,
  accept: ".csv, application/vnd.ms-excel, text/csv",
  onUpload: () => {},
};

export default ButtonUploadCsvInvoice;
