import React, { Fragment } from "react";
//useState,
// import { useRefresh } from "react-admin";
import { get, isNumber } from "lodash";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import Button from "@material-ui/core/Button";
import csv from "csvtojson";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Flex, Text } from "rebass";
// import { UploadClientCSV } from "../../restClient";
const ButtonUploadCsv = (props) => {
  const {
    accept,
    disableClick,
    maxSize,
    minSize,
    multiple,
    translate,
    getJson,
    loading,
    // notify,
    // urlPath,
    label,
  } = props;
  // const [loading, setLoading] = useState(false);
  // const refresh = useRefresh();
  const onDrop = (accepted, rejected) => {
    if (accepted.length > 0 && rejected.length === 0) {
      // console.log(accepted);
      accepted.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          const fileAsBinaryString = reader.result;
          csv({
            noheader: true,
            output: "json",
          })
            .fromString(fileAsBinaryString)
            .then((csvRows) => {
              const toJson = [];
              // console.log("csvRows", csvRows);
              csvRows.forEach((aCsvRow, i) => {
                const builtObject = {};
                if (i !== 0) {
                  Object.keys(aCsvRow).forEach((aKey) => {
                    const valueToAddInBuiltObject = aCsvRow[aKey];
                    // const keyToAddInBuiltObject = csvRows[0][aKey];
                    if (aKey === "field1") {
                      //keyToAddInBuiltObject
                      builtObject["Product Code"] = valueToAddInBuiltObject;
                    } else if (aKey === "field2") {
                      //keyToAddInBuiltObject
                      const newQty = isNumber(valueToAddInBuiltObject * 1)
                        ? valueToAddInBuiltObject * 1
                        : 0;
                      builtObject["Qty"] = newQty;
                    }
                  });
                  if (get(builtObject, "Qty", 0) > 0) {
                    toJson.push(builtObject);
                  }
                }
              });
              // console.log("jsonJOSN", toJson);
              getJson(toJson);
            });
        };

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");

        reader.readAsBinaryString(file);
      });
    }
  };
  return (
    <Fragment>
      <Dropzone
        onDrop={onDrop}
        accept={accept}
        disableClick={disableClick}
        maxSize={maxSize}
        minSize={minSize}
        multiple={multiple}
      >
        {({ getRootProps, getInputProps }) => (
          <Fragment>
            <div {...getRootProps()}>
              <Button
                color='primary'
                variant={"contained"}
                style={{
                  textTransform: "capitalize",
                  width: 160,
                }}
                disabled={loading}
              >
                {translate(label)}
              </Button>
              <input {...getInputProps()} />

              {loading && (
                <Flex flexDirection={"row"} alignItems='center' p={[2]}>
                  <CircularProgress size={20} />{" "}
                  <Text px={[2]}>Loading ...</Text>
                </Flex>
              )}
            </div>
          </Fragment>
        )}
      </Dropzone>
    </Fragment>
  );
};

ButtonUploadCsv.propTypes = {
  accept: PropTypes.string,
  children: PropTypes.element,
  disableClick: PropTypes.bool,
  elStyle: PropTypes.object,
  input: PropTypes.object,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  multiple: PropTypes.bool,
  style: PropTypes.object,
  placeholder: PropTypes.node,
  updateField: PropTypes.func,
};

ButtonUploadCsv.defaultProps = {
  addLabel: false,
  addField: true,
  multiple: false,
  maxSize: 1000000,
  accept: ".csv, application/vnd.ms-excel, text/csv",
  onUpload: () => {},
};

export default ButtonUploadCsv;
