import React from "react";
import { Flex, Text, Box } from "rebass";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import { get, sumBy } from "lodash";

import {
  styles,
  StyledTableAction,
  StyledTableCell,
  StyledTableCellRight,
} from "../../po/components/styles";
import AddSellout from "./AddSellout";
import { ProductView } from "./ProductView";
import { Item } from "./RowItem";
/**
 * 
 barcode={barcode}
globalCode={globalCode}
setBarcode={(v) => setBarcode(v)}
setGlobalCode={(v) => setGlobalCode(v)}
 */

const ItemFields = (props) => {
  const { translate, classes, formData, setItemSelected, setGlobalCode } =
    props;

  const onEditItem = (item, value) => {
    // console.log(item, value);
    const myData = get(formData, item);
    // console.log(myData, formData);
    if (myData && myData.product_id) {
      // console.log(myData);
      setGlobalCode(myData.global_code);
      setItemSelected(myData);
    }
  };

  const itemValues = get(formData, "items", []);
  // console.log(itemValues);
  const total = sumBy(itemValues, function (o) {
    return o.qty * 1;
  });
  return (
    <Flex width={[1]} flexDirection={"column"} py={[3]}>
      <AddSellout {...props} />
      <ProductView {...props} />
      <Box py={[3]}>
        <Text color={"#009c7d"}>
          {translate("resources.sellout.fields.items")}
        </Text>
      </Box>
      <TableContainer component={"div"}>
        <Table className={classes.table} aria-label='sellout items table'>
          <TableHead>
            <TableRow style={{ borderBottom: "1px solid #efefef" }}>
              <StyledTableCell>
                {translate("resources.sellout.fields.product_code")}
              </StyledTableCell>
              <StyledTableCell>
                {translate("resources.sellout.fields.product_packaging")}
              </StyledTableCell>
              <StyledTableCell>
                {translate("resources.sellout.fields.global_code")}
              </StyledTableCell>
              <StyledTableCell>
                {translate("resources.sellout.fields.expiry")}
              </StyledTableCell>
              <StyledTableCellRight>
                {translate("resources.sellout.fields.qty")}
              </StyledTableCellRight>
              <StyledTableAction />
            </TableRow>
          </TableHead>
          <TableBody>
            <Item {...props} onEditItem={onEditItem} />
            <TableRow style={{ borderBottom: "1px solid #efefef" }}>
              <StyledTableCell colSpan={4}>
                <Text textAlign={"right"}>
                  {translate("resources.sellout.fields.total")}
                </Text>
              </StyledTableCell>
              <StyledTableCellRight>
                <Text textAlign={"right"}>{total}</Text>
              </StyledTableCellRight>
              <StyledTableAction />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Flex>
  );
};

export default withStyles(styles)(ItemFields);
