import React, { useState, Fragment } from "react";
import { Flex } from "rebass";
import { useTranslate, FormDataConsumer } from "react-admin";
import {
  PromotionFields,
  // PromotionSetting,
  // PromotionProducts,
  PromotionProductsettings,
  CouponSettings,
} from "../components";
import { get } from "lodash";
import { SnackBarMessage } from "../../components";
// import Typography from "@material-ui/core/Typography"
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
// import ActionList from "./ActionList"

const FormFields = (props) => {
  const translate = useTranslate();
  const [show, setShow] = useState({ message: "", open: false });

  const {
    hasList,
    hasEdit,
    hasCreate,
    hasShow,
    isCreate,
    updateField,
    ...rest
  } = props;
  const checkEmptyValue = (k, v, e, formData) => {
    console.log(formData.criteria);
    // console.log(k, t.target.name);
    const moqK = k.substr(0, k.lastIndexOf("."));
    const mySource = k.split(".").pop();
    const moq = get(formData, `${moqK}.moq`, 0);
    if (moq * 1 <= 0 && mySource !== "moq" && mySource !== "additional") {
      setShow({
        ...show,
        message: "MOQ can not empty or 0",
        open: true,
      });
      updateField(k, 0);
    } else {
      if (mySource === "additional") {
        if (v === "") {
          updateField(k, 0);
        }
      } else {
        if (v === "") {
          setShow({
            ...show,
            message: "Field can not empty, set 0 for default",
            open: true,
          });
          updateField(k, 0);
        }
      }
    }
  };

  return (
    <Fragment>
      <FormDataConsumer>
        {({ formData }) => (
          <Flex width={[1]} flexDirection={"column"}>
            <PromotionFields
              isProduct={true}
              formData={formData}
              translate={translate}
              updateField={updateField}
              {...rest}
            />
            <Flex width={1} flexDirection={"column"} mt={[2]}>
              <PromotionProductsettings
                {...rest}
                translate={translate}
                formData={formData}
                updateField={updateField}
                checkEmptyValue={(k, v, e) =>
                  checkEmptyValue(k, v, e, formData)
                }
              />
              {/* <PromotionProducts
                {...props}
                translate={translate}
                formData={formData}
              /> */}
              {formData && formData.need_coupon && (
                <CouponSettings
                  {...props}
                  translate={translate}
                  formData={formData}
                />
              )}
            </Flex>
          </Flex>
        )}
      </FormDataConsumer>
      <SnackBarMessage
        message={show.message}
        open={show.open}
        handleClose={() => setShow({ ...show, open: false })}
      />
    </Fragment>
  );
};

export default FormFields;
