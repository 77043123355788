import React, { Fragment, useState } from "react";
import { keyBy } from "lodash";
import { Box, Flex } from "rebass";
import { useQueryWithStore, Loading, TextField } from "react-admin";
import { Pagination, Datagrid } from "../../components";
import { EditCountries } from "./country";

const ButtonField = ({ record, ...rest }) => {
  return (
    <Flex flexDirection={"row"} justifyContent='flex-end'>
      <EditCountries {...rest} record={record} />
    </Flex>
  );
};

const ListCountries = (props) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const { data, total, loading } = useQueryWithStore({
    type: "getList",
    resource: "countries",
    payload: {
      pagination: { page, perPage },
      sort: { field: "name", order: "ASC" },
      filter: {},
    },
  });

  if (loading) {
    return (
      <Flex
        width={[1]}
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
        style={{ height: "60vh" }}
      >
        <Loading />
      </Flex>
    );
  }

  return (
    <Box width={[1]} py={[2]}>
      {data && data.length > 0 && (
        <Fragment>
          <Datagrid
            data={keyBy(data, "id")}
            ids={data.map(({ id }) => id)}
            currentSort={{ field: "id", order: "ASC" }}
            basePath='/countries' // required only if you set use "rowClick"
            // rowClick="edit"
          >
            <TextField
              label={"resources.settings.fields.country"}
              source='name'
            />
            <ButtonField {...props} />
          </Datagrid>
          <Pagination
            page={page}
            perPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            setPage={(v) => setPage(v)}
            setPerPage={(v) => setPerPage(v)}
            total={
              total === null
                ? data && data.length > 0
                  ? data.length
                  : 0
                : total
            }
          />
        </Fragment>
      )}
    </Box>
  );
};

export default ListCountries;
