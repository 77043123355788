import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import create from "zustand";
export const sanitizeRestPropsDelete = ({
  basePath,
  classes,
  filterValues,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  label,
  pristine,
  resource,
  saving,
  selectedIds,
  submitOnEnter,
  redirect,
  ...rest
}) => rest;

export const useStylesDelete = makeStyles((theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      // Reset on mouse devices
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
  smallIcon: {
    fontSize: 12,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const useStoreUrl = create((set) => ({
  url: null,
  setUrl: (newState) =>
    set((state) => {
      // console.log("state", state);
      // console.log("newState,", newState);
      return { url: newState };
    }),
}));
