import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
  TextInput,
  useTranslate,
  FormDataConsumer,
  required,
} from "react-admin";
import { find, flattenDepth, uniqBy } from "lodash";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Flex, Box } from "rebass";
import Paper from "@material-ui/core/Paper";
import ItemFields from "./ItemFields";
import { FieldArray } from "react-final-form-arrays";
import ButtonUploadCsv from "./ButtonUploadCsv";
import { getAllProducts } from "../../restClient";

import { useNotify } from "react-admin";

const FormFields = forwardRef((props, ref) => {
  const translate = useTranslate();
  const notify = useNotify();
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const [globalCode, setGlobalCode] = useState("");

  const handleDateChange = (date, key) => {
    // console.log(date);
    props.updateField(key, date);
  };

  useImperativeHandle(ref, () => ({
    resetFieldItems: () => {
      props.updateField("items", []);
    },
  }));
  const setItemSelected = (item) => {
    setState(item);
  };

  const getJson = (v, formData) => {
    let listGlobalList = uniqBy(v, "Product Code");
    if (listGlobalList && listGlobalList.length > 0) {
      const globalCodeList = listGlobalList.map((item) => {
        return `code=${item["Product Code"]}`;
      });
      setLoading(true);
      getAllProducts(globalCodeList)
        .then((response) => {
          // console.log(JSON.stringify(response));
          const result = flattenDepth(response, 1);
          // console.log("result", result);
          // const myFilter = filter(result, function (oo) {
          //   return oo.active === true;
          // });
          setLoading(false);
          let res = uniqBy(result, "id");
          console.log("res", res);
          if (res && res.length > 0) {
            const myLatest = res.map((item) => {
              let newItem = { product_id: item.id };
              const objUrl = find(v, function (o) {
                // console.log(promoType, o.id);
                return o["Product Code"] === item.code;
              });
              newItem["price"] = item.price * 1;

              if (objUrl && objUrl["Product Code"]) {
                newItem["code"] = objUrl["Product Code"];
                newItem["global_code"] = item.global_code;
                newItem["qty"] = objUrl.Qty * 1;
                newItem["subtotal"] = item.price * objUrl.Qty;
              } else {
                newItem["global_code"] = item.global_code;
                newItem["code"] = item.code;
                newItem["qty"] = 0;
                newItem["subtotal"] = 0;
              }
              return newItem;
            });

            const newItem = formData.items;
            // console.log(newItem);
            if (newItem && newItem.length > 0) {
              const newItems = [...newItem, ...myLatest];
              // newItem.push(...myLatest)
              const uniqItem = uniqBy(newItems, "product_id");
              props.updateField("items", uniqItem);
            } else {
              props.updateField("items", myLatest);
            }
          } else {
            notify("No active pricebook for Dealer", "warning");
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log(e.message);
          notify(e.message + "", "warning");
        });
    } else {
      notify("No item on CSV", "warning");
    }
  };

  return (
    <Flex width={[1]} flexDirection={"column"} py={[3]} px={[0]}>
      <FormDataConsumer>
        {({ formData }) => {
          return (
            <Flex width={[1]} flexDirection={"column"}>
              <Flex
                width={[1]}
                flexDirection={"row"}
                justifyContent={"flex-end"}
                pb={[2]}
              >
                <ButtonUploadCsv
                  {...props}
                  translate={translate}
                  label={"resources.buttons.import_via_csv"}
                  getJson={(v) => getJson(v, formData)}
                  loading={loading}
                />
              </Flex>
              <Box width={[1]}>
                <Paper elevation={3}>
                  <Flex width={[1]} flexDirection={"row"} px={[3]} pb={[3]}>
                    <Box>
                      <KeyboardDatePicker
                        disableToolbar
                        variant='inline'
                        format='DD-MMM-YYYY'
                        margin='normal'
                        style={{ marginRight: 16 }}
                        inputProps={{
                          style: { width: 100, minWidth: 100 },
                        }}
                        id='from-date-inline'
                        label={translate("resources.sellout.fields.date")}
                        value={formData.created_at}
                        onChange={(e) =>
                          handleDateChange(e.toISOString(), "created_at")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </Box>
                    <TextInput
                      source='customer_name'
                      label={"resources.sellout.fields.ref_customer"}
                      style={{ width: 256 }}
                      InputLabelProps={{ shrink: true }}
                      validate={[required()]}
                    />
                  </Flex>
                </Paper>
              </Box>
              <FieldArray
                name='items'
                validateFields={["product_id"]}
                component={(rest) => (
                  <ItemFields
                    {...props}
                    {...rest}
                    formData={formData}
                    translate={translate}
                    state={state}
                    setItemSelected={(item) => setItemSelected(item)}
                    // barcode={barcode}
                    globalCode={globalCode}
                    // setBarcode={(v) => setBarcode(v)}
                    setGlobalCode={(v) => setGlobalCode(v)}
                  />
                )}
              />
            </Flex>
          );
        }}
      </FormDataConsumer>
    </Flex>
  );
});

export default FormFields;
