import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import { connect } from "react-redux";

import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const UploadButton = (props) => {
  const { label, icon, isHideIcon, ...rest } = props;
  const handleClick = (event) => {
    const { onClick } = props;
    event.preventDefault();
    // refreshView();

    if (typeof onClick === "function") {
      onClick();
    }
  };
  if (isHideIcon) {
    return (
      <Button
        label={label}
        onClick={handleClick}
        {...rest}
        style={{ textTransform: "capitalize", minWidth: 160 }}
      />
    );
  }
  return (
    <Button
      label={label}
      onClick={handleClick}
      {...rest}
      style={{ textTransform: "capitalize" }}
    >
      {icon}
    </Button>
  );
};

UploadButton.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.element,
  isHideIcon: PropTypes.bool,
};

UploadButton.defaultProps = {
  label: "resources.buttons.upload",
  icon: <CloudUploadIcon />,
  isHideIcon: false,
};

const enhance = connect(null, {});

export default enhance(UploadButton);
