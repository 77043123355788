import React from "react";
import { Flex } from "rebass";
import { BetterShowUrlButton, BetterEditUrlButton } from "../../components";
import { ROLE_USER } from "../../utils";
import { ButtonPODetail } from "../../screens/reports";
export const ButtonShowUrlEditView = (props) => {
  const { permissions } = props;
  if (permissions === ROLE_USER.country_manager) {
    return (
      <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
        <ButtonPODetail {...props} />
        <BetterShowUrlButton {...props} icon={props.iconshow} />
      </Flex>
    );
  }
  return (
    <Flex flexDirection={["row"]} justifyContent={"flex-end"}>
      <ButtonPODetail {...props} />
      <BetterShowUrlButton {...props} icon={props.iconshow} />
      <BetterEditUrlButton {...props} />
    </Flex>
  );
};
