import React from "react";
import { Edit } from "react-admin";
import { get, includes, toLower } from "lodash";
import { ActionForm, EditToolbar, BetterCustomSimpleForm } from "../components";
import { FormFields } from "./components";

const PriceBookEdit = (props) => {
  const { location } = props;

  const searchType = get(location, "search", "");
  const myType = includes(searchType, "dealer")
    ? "Dealer"
    : includes(searchType, "customer")
    ? "Customer"
    : "";
  return (
    <Edit
      {...props}
      actions={
        <ActionForm
          title={"resources.pricebook.edit_pricebook"}
          search={`${myType === "Dealer" ? "" : "/" + toLower(myType)}`}
        />
      }
      redirect={"list"}
    >
      <BetterCustomSimpleForm
        {...props}
        toolbar={<EditToolbar />}
        sourceApi='priceBooks'
        title={""}
        typePostUrl={"UPDATE"}
        pathUrl='priceBooks'
        customBackUrl='/priceBooks'
        isHideTitle={true}
      >
        <FormFields {...props} />
      </BetterCustomSimpleForm>
    </Edit>
  );
};

export default PriceBookEdit;
