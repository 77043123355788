import React from "react";
// import moment from "moment";
import { useTranslate } from "react-admin";
import { Text, Box } from "rebass";
import { get } from "lodash";
import NumberFormat from "react-number-format";
import numeral from "numeral";
import moment from "moment-timezone";
const tzNameSg = "Asia/Singapore";

moment.tz.setDefault(tzNameSg);
function currencyFormatter(value) {
  return numeral(value * 1).format("0,0.00"); // "\xA3" + formatNumber(params.value);
}

const TextRecord = ({
  source,
  record = {},
  label,
  color,
  defaultValue = "",
  pb = 3,
  isDate = false,
  formatDate = "DD-MMM-YYYY",
  isCurrency = false,
  notetext,
}) => {
  const translate = useTranslate();
  const subColor = color ? { color: color } : {};
  const myColor = color ? color : "grey";
  if (source === "active") {
    return (
      <Box pb={[pb]}>
        <Text fontWeight={"bold"} color={myColor} fontSize={[0]} pb={[1]}>
          {translate(label)}
        </Text>
        <Text style={{ ...subColor }}>
          {record.active ? "Active" : "Inactive"}
        </Text>
      </Box>
    );
  }
  if (isDate) {
    return (
      <Box pb={[pb]}>
        <Text fontWeight={"bold"} color={myColor} fontSize={[0]} pb={[1]}>
          {translate(label)}
        </Text>
        <Text style={{ ...subColor }}>
          {get(record, source, defaultValue) !== "" &&
          get(record, source, defaultValue) !== null
            ? moment(get(record, source, defaultValue)).format(formatDate)
            : "-"}
        </Text>
      </Box>
    );
  }
  if (isCurrency) {
    return (
      <Box pb={[pb]}>
        <Text fontWeight={"bold"} fontSize={[0]} pb={[1]} color={myColor}>
          {translate(label)}
        </Text>
        <Text style={{ ...subColor }}>
          <NumberFormat
            value={currencyFormatter(get(record, source, 0))}
            displayType={"text"}
            thousandSeparator={true}
            decimalScale={2}
            prefix={"$"}
          />
        </Text>
      </Box>
    );
  }

  return (
    <Box pb={[pb]}>
      <Text fontWeight={"bold"} fontSize={[0]} pb={[1]} color={myColor}>
        {translate(label)}
      </Text>
      {source === "active" && (
        <Text style={{ textTransform: "capitalize", ...subColor }}>
          {get(record, source, "false")}
        </Text>
      )}
      {source !== "active" && (
        <Text style={{ ...subColor }}>{get(record, source, defaultValue)}</Text>
      )}
      {notetext && (
        <Text py={[1]} style={{ ...subColor }}>
          {notetext}
        </Text>
      )}
    </Box>
  );
};
export default TextRecord;
