import React from "react";
import { get } from "lodash";
import { Flex } from "rebass";
import { Toolbar } from "react-admin";
import { SavePoButton } from "../../components";
import { STATUSPO } from "../../utils";
/**
 * 
 get(rest, "record.status", "") === "Sent" ||
    get(rest, "record.status", "") === "Received" ||
    get(rest, "record.status", "") === "Partial" ||
    get(rest, "record.status", "") === "Posted"
 */
export const FooterPOToolbar = (props) => {
  const { updateField, ...rest } = props;
  if (
    get(rest, "record.status", "") === STATUSPO.sent ||
    get(rest, "record.status", "") === STATUSPO.received ||
    get(rest, "record.status", "") === STATUSPO.partial ||
    get(rest, "record.status", "") === STATUSPO.posted
  ) {
    return <Flex width={[1]} pt={[3]} />;
  }
  return (
    <Toolbar {...rest}>
      <SavePoButton
        label={"resources.buttons.save_as_draft"}
        redirect={props.redirect}
        updateField={updateField}
        style={{
          marginLeft: 16,
          borderRadius: 24,
          textTransform: "capitalize",
          paddingLeft: 48,
          paddingRight: 48,
        }}
      />
      <SavePoButton
        label={"resources.buttons.submit_order"}
        redirect={props.redirect}
        updateField={updateField}
        style={{
          marginLeft: 16,
          borderRadius: 24,
          textTransform: "capitalize",
          paddingLeft: 48,
          paddingRight: 48,
        }}
      />
    </Toolbar>
  );
};
