import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
// import { TextField } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { Box, Flex, Text } from "rebass";
import { get, filter, sumBy, map, uniq, find, uniqBy } from "lodash";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import { withStyles } from "@material-ui/core/styles";
import { useNotify } from "react-admin";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  styles,
  StyledTableCell,
  StyledTableRow,
  StyledTableSmall,
} from "./styles";
import { STATUSPO } from "../../utils";
// import { ProductField } from "../../components";
import { RestApiCURD } from "../../restClient";
import DialogGoodReceiptsContent from "./DialogGoodReceiptsContent";
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    paddingLeft: 24,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  table: {
    width: "92%",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const getNotReceivedCompleted = (list, received) => {
  let newList = [];
  if (list && list !== null) {
    list.map((i) => {
      const newReceived = filter(received, function (o) {
        return o.purchase_order_detail_id === i.id;
      });
      let newQty = 0;
      if (newReceived && newReceived.length > 0) {
        newReceived.map((a) => {
          newQty = newQty + a.qty;
          return "";
        });
      }
      // if (newQty < i.qty) {
      //   i.received = newQty;
      //   i.receive = i.qty - newQty;
      //   newList.push(i);
      // }
      if (newQty <= i.qty) {
        i.received = newQty;
        i.receive = i.qty - newQty;
        //change to get from i.invoiced to show on receive
        // i.receive = i.invoiced;
        newList.push(i);
      }
      return "";
    });
  }
  return newList;
};
const DialogGoodsReceipts = (props) => {
  const { translate, record, result } = props;
  const classes = useStyles();
  const notify = useNotify();
  const details = get(record, "items", []);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultList = getNotReceivedCompleted(details, result);
  const totalReceive = sumBy(defaultList, function (o) {
    return o.receive;
  });
  const [total, setTotal] = useState(totalReceive);
  const [goodsReceipts, setGoodsReceipts] = useState(defaultList);
  const status = get(record, "status", "");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onChangeReceived = (e, index, item) => {
    console.log(index);
    const result = item.received + e.target.value * 1;
    const myqty = item.qty;
    if (result <= myqty) {
      const editItem = find(goodsReceipts, function (ii) {
        return ii.id === item.id;
      });
      const newList1 = filter(goodsReceipts, function (io) {
        return io.id !== item.id;
      });
      if (editItem) {
        const params = {
          ...editItem,
          receive: e.target.value * 1,
        };
        newList1.push(params);
        setGoodsReceipts(newList1);
        const totalReceive1 = sumBy(newList1, function (o) {
          return o.receive;
        });
        setTotal(totalReceive1);
      }
    }
  };

  const onBlurReceived = (v, index) => {
    // let newGoodsReceipts = goodsReceipts;
    // const result = newGoodsReceipts[index].received + e.target.value * 1;
    // const result = e.target.value * 1;
    // console.log("goodsReceipts", goodsReceipts);
    // console.log(v, index);
  };

  const onSubmitGP = () => {
    if (record && record.id) {
      setLoading(true);
      const good_receipts = goodsReceipts.map((item) => {
        if (item && item.receive && item.receive > 0) {
          return {
            product_id: item.product_id,
            qty: item.receive,
            purchase_order_detail_id: item.id,
          };
        }
        return undefined;
      });
      const listGoodReceipts = filter(good_receipts, function (li) {
        return li !== undefined;
      });
      const listGoodReceiptsUniq = uniqBy(
        listGoodReceipts,
        "purchase_order_detail_id"
      );
      const params = {
        purchase_order_id: record.id,
        goods_receipts: listGoodReceiptsUniq,
      };
      console.log("Params", params);
      if (listGoodReceipts.length === 0) {
        notify(
          "resources.po.messages.error_empty_item_goods_receipts",
          "warning"
        );
      } else {
        RestApiCURD("goodsReceipts", "POST", params)
          .then((res) => res.json())
          .then((response) => {
            console.log("response", response);
            setLoading(false);
            if (response && response.purchase_order_id) {
              notify("resources.po.messages.element_created");
              setOpen(false);
              props.history.go();
            } else {
              if (response.message && response.message !== "") {
                notify(response.message, "warning");
              } else {
                notify(
                  "resources.po.messages.error_create_goods_receipts",
                  "warning"
                );
              }
            }
          })
          .catch((e) => {
            notify(e.message, "warning");
          });
      }
    }
  };
  // console.log("goodsReceipts", goodsReceipts);
  const myProducts = map(goodsReceipts, function (o) {
    return o.product_id;
  });
  return (
    <Box width={[1]}>
      {(status === STATUSPO.processing || status === STATUSPO.partial) && (
        <Flex
          width={[1]}
          py={[3]}
          flexDirection={"row"}
          justifyContent={"center"}
        >
          <Button
            variant='contained'
            color='primary'
            style={{
              borderRadius: 24,
              textTransform: "capitalize",
              paddingLeft: 32,
              paddingRight: 32,
            }}
            onClick={handleClickOpen}
          >
            {translate("resources.buttons.receive_goods")}
          </Button>
        </Flex>
      )}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={handleClose}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
            <Typography variant='h6' className={classes.title}>
              {translate("resources.po.tabs.good_receipts")}
            </Typography>
            {/* */}
          </Toolbar>
        </AppBar>
        <Flex
          width={[1]}
          flexDirection={"column"}
          style={{ flex: 1, overflowY: "auto" }}
          p={[2]}
        >
          <DialogGoodReceiptsContent
            ids={uniq(myProducts)}
            goodsReceipts={goodsReceipts}
            onChangeReceived={onChangeReceived}
            onBlurReceived={onBlurReceived}
            translate={translate}
            classes={classes}
          />
          {/* <TableContainer component={"div"}>
            <Table className={classes.table} aria-label='customized table'>
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    {translate("resources.po.fields.product")}
                  </StyledTableCell>
                  <StyledTableCell>
                    {translate("resources.po.fields.code")}
                  </StyledTableCell>
                  <StyledTableSmall>
                    <Text textAlign={"center"}>
                      {translate("resources.po.fields.ordered")}
                    </Text>
                  </StyledTableSmall>
                  <StyledTableSmall>
                    {translate("resources.po.fields.invoiced")}
                  </StyledTableSmall>
                  <StyledTableSmall>
                    {translate("resources.po.fields.received")}
                  </StyledTableSmall>
                  <StyledTableSmall>
                    {translate("resources.po.fields.receive")}
                  </StyledTableSmall>
                </TableRow>
              </TableHead>
              <TableBody>
                {goodsReceipts.length > 0 &&
                  goodsReceipts.map((item, index) => {
                    // console.log(item.received);
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell component='td' scope='row'>
                          <ProductField record={item} source='packaging' />
                        </StyledTableCell>
                        <StyledTableCell component='td' scope='row'>
                          <ProductField record={item} source='code' />
                        </StyledTableCell>
                        <StyledTableSmall component='td' scope='row'>
                          <Text textAlign={"center"}>{item.qty}</Text>
                        </StyledTableSmall>
                        <StyledTableSmall component='td' scope='row'>
                          <Text textAlign={"center"}>{item.invoiced}</Text>
                        </StyledTableSmall>
                        <StyledTableSmall component='td' scope='row'>
                          <Text textAlign={"center"}>{item.received}</Text>
                        </StyledTableSmall>
                        <StyledTableSmall component='td' scope='row'>
                          <TextInput
                            item={item}
                            index={index}
                            onChangeItem={onChangeReceived}
                          />
                        </StyledTableSmall>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer> */}
        </Flex>
        <Divider style={{ marginTop: 2 }} />
        <Flex width={[1]} flexDirection={"column"} p={[2]}>
          <TableContainer component={"div"}>
            <Table className={classes.table} aria-label='customized table'>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell component='td' scope='row' />
                  <StyledTableCell component='td' scope='row' />
                  <StyledTableCell component='td' scope='row' />
                  <StyledTableCell component='td' scope='row' />
                  <StyledTableSmall component='td' scope='row'>
                    <Flex flexDirection={"row"} justifyContent={"flex-end"}>
                      <Text
                        style={{ minWidth: 80 }}
                        pr={[2]}
                        fontWeight={"bold"}
                      >
                        {translate("resources.po.fields.total")}
                      </Text>
                      <Text style={{ minWidth: 80 }}>{total}</Text>
                    </Flex>
                  </StyledTableSmall>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Flex>
        <Divider style={{ marginTop: 2 }} />
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          pr={[3]}
          py={[2]}
        >
          <Button
            autoFocus
            variant={"contained"}
            color='primary'
            onClick={onSubmitGP}
            style={{ textTransform: "capitalize" }}
            disabled={goodsReceipts.length === 0 || loading}
          >
            {loading && (
              <CircularProgress
                size={18}
                thickness={2}
                className={classes.leftIcon}
              />
            )}
            {translate("resources.buttons.save")}
          </Button>
        </Flex>
      </Dialog>
    </Box>
  );
};

export default withStyles(styles)(DialogGoodsReceipts);

// dataProvider
//   .create("goodsReceipts", {
//     data: params,
//   })
//   .then((response) => {
//     setLoading(false);
//     console.log(response);
//     if (response && response.data) {
//       console.log(response.data);
//       notify("resources.po.messages.element_created");
//       // setOpen(false);
//       // props.history.go();
//     } else {
//       notify(
//         "resources.po.messages.error_create_goods_receipts",
//         "warning"
//       );
//     }
//   })
//   .catch((e) => {
//     console.log(e);
//   });
