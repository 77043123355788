import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

export default (props) => {
  const { list, myData } = props;
  const state = {
    columnDefs: myData.listColumn,
    defaultColDef: {
      flex: 1,
      editable: false,
      sortable: true,
      filter: true,
      cellStyle: {
        "line-height": "40px",
      },
    },
    getRowNodeId: function (data) {
      return data.category;
    },
  };
  // const [pageSize, setPageSize] = useState(20);
  // const onPageSizeChange = (e) => {
  //   // console.log(e);
  //   setPageSize(e.pageSize);
  // };

  // const heightContainer =
  //   list.length > pageSize
  //     ? pageSize * 55 + 110
  //     : list.length > 0
  //     ? pageSize * 55 + 110
  //     : 300;
  // //   console.log(myData.listColumn);
  // const sortModelDefault = dealerId !== null ? sortModelDealer : sortModel;
  return (
    // <div style={{ height: heightContainer, width: "100%" }}>
    //   <DataGrid
    //     sortModel={sortModelDefault}
    //     rows={list}
    //     columns={myData.listColumn}
    //     pageSize={20}
    //     rowsPerPageOptions={[10, 20, 25, 50]}
    //     onPageSizeChange={onPageSizeChange}
    //   />
    // </div>
    <div className='grid-wrapper'>
      <div
        id='myGridPromotion'
        className='ag-theme-balham'
        style={{ height: "50vh", width: "100%" }}
      >
        <AgGridReact
          rowHeight={40}
          headerHeight={40}
          rowData={list}
          columnDefs={state.columnDefs}
          defaultColDef={state.defaultColDef}
          animateRows={true}
        />
      </div>
    </div>
  );
};
