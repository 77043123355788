import React from "react";
import { get, orderBy, sumBy } from "lodash";
import { Box } from "rebass";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import { CollapseMui } from "../../../../components";
import { ItemViewFields } from "./CurrencyField";
import { StyledTableRow } from "../edit";
import { PromoType_B } from "../edit";
import numeral from "numeral";
const DiscountItems = (props) => {
  const { child, translate, onShow, isExpired, onRemoveItem } = props;
  const PromoType = get(child, "promotion_type", "");
  const labelDiscount = PromoType === PromoType_B[2] ? PromoType_B[2] : "";
  const listPromo = get(child, "list", []);
  const totalQty = sumBy(listPromo, "qty");
  const listPromoSortOrder = orderBy(listPromo, "sort_order", "asc");
  return (
    <Box width={[1]}>
      <CollapseMui
        label={`Promotion ${get(
          child,
          "promotions.name",
          ""
        )} ${labelDiscount}`}
        isLeft={true}
        style={{ backgroundColor: "rgba(231,231,231,0.8)" }}
        defaultExpand={true}
        isHaveButton={true}
        labelButton={translate("resources.buttons.edit")}
        onShow={onShow}
        isExpired={isExpired}
        onRemoveItem={() => onRemoveItem()}
      >
        <TableContainer component={"div"}>
          <Table aria-label='customized table'>
            <TableBody>
              {listPromoSortOrder.length > 0 &&
                listPromoSortOrder.map((promo, indexPromo) => {
                  const myMoqList =
                    get(promo, "promotions.criteria.discount.moqs", []) !== null
                      ? get(promo, "promotions.criteria.discount.moqs", [])
                      : [];
                  const myPrice = get(promo, "price", 0);

                  const moqsDiscount = orderBy(myMoqList, "moq", "desc");
                  let discount = 0;
                  let myDiscount = myPrice;
                  //get(moqsDiscount[MOQIndex], "discount", 0)
                  if (moqsDiscount.length > 0) {
                    if (totalQty >= moqsDiscount[0].moq) {
                      discount = moqsDiscount[0].discount;
                    } else {
                      if (moqsDiscount.length > 1) {
                        if (totalQty >= moqsDiscount[1].moq) {
                          discount = moqsDiscount[1].discount;
                        } else {
                          if (moqsDiscount.length > 2) {
                            if (totalQty >= moqsDiscount[1].moq) {
                              discount = moqsDiscount[1].discount;
                            } else if (totalQty >= moqsDiscount[2].moq) {
                              discount = moqsDiscount[2].discount;
                            }
                          }
                        }
                      }
                    }
                    myDiscount =
                      discount !== null
                        ? discount < 1
                          ? myPrice * (1 - discount)
                          : myPrice * (1 - discount / 100)
                        : myPrice;
                    const roundedDiscount =
                      numeral(myDiscount).format("0,0.00");
                    promo["discounted_price"] = roundedDiscount;
                  }
                  const roundedDiscount = numeral(myDiscount).format("0,0.00");
                  const subtotal = roundedDiscount * get(promo, "qty", 0);
                  const isCRDG =
                    get(promo, "cr", false) === true ||
                    get(promo, "dg", false) === true
                      ? { backgroundColor: "#FF0000", color: "#FFFFFF" }
                      : {};
                  const isCRDGCOLOR =
                    get(promo, "cr", false) === true ||
                    get(promo, "dg", false) === true
                      ? { color: "#FFFFFF" }
                      : { color: "rgba(0, 0, 0, 0.87)" };
                  return (
                    <StyledTableRow key={indexPromo} style={isCRDG}>
                      <ItemViewFields
                        promo={promo}
                        subtotal={subtotal}
                        isCRDGCOLOR={isCRDGCOLOR}
                      />
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </CollapseMui>
    </Box>
  );
};
export default DiscountItems;
