import React, { Fragment } from "react"
import { Box, Text, Flex } from "rebass"
import { ThemeProvider } from "@material-ui/core/styles"

import { NumberInput } from "react-admin"
import IconButton from "@material-ui/core/IconButton"
// import { EditIcon } from "../../components/icon_svg"
import { TrashPOIcon } from "../../components/icon_svg"
import { themeTableInput } from "../../themes"
import {
  StyledTableAction,
  StyledTableCell,
  StyledTableCellRight,
  StyledTableSmall,
} from "../../po/components/styles"

const RowPoItem = (props) => {
  const { item, index, fields, classes } = props
  const { value } = fields
  return (
    <Fragment>
      <StyledTableCell component="td" scope="row">
        <Box>
          <Text>{value[index].result.packaging}</Text>
        </Box>
      </StyledTableCell>
      <StyledTableCellRight component="td" scope="row">
        <Text textAlign={"right"}>{value[index].result.qty}</Text>
      </StyledTableCellRight>
      <StyledTableCellRight component="td" scope="row">
        <Text textAlign={"right"}>{value[index].result.ordered}</Text>
      </StyledTableCellRight>
      <StyledTableCellRight component="td" scope="row">
        <Text textAlign={"right"}>{value[index].result.minimum}</Text>
      </StyledTableCellRight>
      <StyledTableCellRight component="td" scope="row">
        <Text textAlign={"right"}>{value[index].result.maximum}</Text>
      </StyledTableCellRight>
      <StyledTableSmall component="td" scope="row">
        <ThemeProvider theme={themeTableInput}>
          <NumberInput
            source={`${item}.qty`}
            label=""
            defaultValue={value[index].result.maximum}
            style={{ width: 95, marginTop: 0, paddingTop: 0 }}
          />
        </ThemeProvider>
      </StyledTableSmall>
      <StyledTableAction component="td" scope="row">
        <Flex flexDirection={"row"} alignItems={"center"}>
          <IconButton
            className={classes.leftIcon1}
            onClick={() => fields.remove(index)}
            size="small"
          >
            <TrashPOIcon fontSize="inherit" />
          </IconButton>
        </Flex>
      </StyledTableAction>
    </Fragment>
  )
}

export default RowPoItem
