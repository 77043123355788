import React, { useState } from "react";
import { get, chain, find } from "lodash";
import { useTranslate, useNotify, useRedirect } from "react-admin";
import { Flex, Box, Text } from "rebass";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import { grey } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import {
  // StyledTableAction,
  StyledTableCell,
  StyledTableRow,
  StyledTableSmall,
  PromoType_B,
} from "./edit";
import {
  ViewBuyABGetCDItems,
  ViewBuyXGetYItems,
  ViewSpecialPriceItems,
  ViewDiscountItems,
  ViewNormalItems,
  TotalTable,
} from "./order_item";
import { RestApi } from "../../../restClient";
import moment from "moment";
import { message } from "antd";
import { ROLE_USER, STATUSPO } from "../../../utils";
import TotalQuantity from "./TotalQuantity";
const errorMessage = (text) => {
  message.error(text);
};

const generateFile = (content, fileName) => {
  // console.log("content", content); // here at console if i copy the code and use online tool(https://base64.guru/converter/decode/pdf) it shows the correct pdf
  const blob = new Blob([content], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  // console.log(blob);
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
};

const EmptyList = () => {
  const translate = useTranslate();

  return (
    <Box width={[1]} p={[2]}>
      <Flex
        width={[1]}
        justifyContent='center'
        alignItems={"center"}
        flexDirection={"column"}
        sx={{
          border: "1px solid #C3c3c3",
          height: 300,
        }}
      >
        <InsertDriveFileOutlinedIcon
          style={{ fontSize: "60pt", color: grey[300] }}
        />

        <Text p={[3]} textAlign={"center"}>
          {translate("resources.po.messages.no_have_items")}
        </Text>
      </Flex>
    </Box>
  );
};

const ListOrdersView = (props) => {
  const { formData, record, permissions } = props;
  const [loading, setLoading] = useState(false);
  const products = get(formData, "items", []);
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  if (products === null || products.length === 0) {
    return <EmptyList />;
  }
  const listProducts = chain(products)
    .groupBy("promotions.id")
    .map((value, key) => {
      const item = find(value, function (o) {
        return get(o, "promotions.id", 0) + "" === key + "";
      });
      return {
        id: key && key !== "undefined" ? key : "normal",
        promotion_type: get(
          item,
          "promotions.promotion_type",
          key && key !== "undefined" ? key : ""
        ),
        list: value,
        promotions: get(item, "promotions", null),
      };
    })
    .value();
  // console.log(totalQty, totalAmount);
  let total = 0;

  const status = get(record, "status", "");
  const handlePostToGC = () => {
    setLoading(true);
    if (record && record.id) {
      // RestApi(`/orders/${record.id}/postToGP`)
      RestApi(`/purchaseOrders/${record.id}/postToGP`)
        .then((res) => {
          return res.blob();
        })
        .then((data) => {
          setLoading(false);
          if (data) {
            const myfilename = `purchaseOrders-${moment().format(
              "DD-MMM-YYYYHH:mm"
            )}.xlsx`;
            generateFile(data, myfilename);
            redirect("/orders");
          } else {
            notify("resources.orders.messages.order_cannot_post", "warning");
          }

          // notify("resources.notification.updated", "success", {
          //   smart_count: 1,
          // });
          // setLoading(false);
          // history.push(`/orders/${record.id}`);
        })
        .catch((e) => {
          errorMessage(e.message);
          setLoading(false);
        });
    } else {
      notify("resources.orders.messages.order_cannot_post", "warning");
      setLoading(false);
    }
  };

  return (
    <Box width={[1]}>
      <TableContainer component={"div"}>
        <Table aria-label='customized table'>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell style={{ textAlign: "left", paddingLeft: 30 }}>
                {translate("resources.po.table.packaging")}
              </StyledTableCell>
              <StyledTableSmall style={{ textAlign: "center", width: 130 }}>
                {translate("resources.po.table.code")}
              </StyledTableSmall>
              <StyledTableSmall style={{ textAlign: "right", width: 90 }}>
                {translate("resources.po.table.price")}
              </StyledTableSmall>
              <StyledTableSmall style={{ textAlign: "right", width: 150 }}>
                {translate("resources.po.table.discounted_price")}
              </StyledTableSmall>
              <StyledTableSmall style={{ textAlign: "right", width: 80 }}>
                {translate("resources.po.table.qty")}
              </StyledTableSmall>
              <StyledTableSmall style={{ textAlign: "right", width: 100 }}>
                {translate("resources.po.table.subtotal")}
              </StyledTableSmall>
            </StyledTableRow>
          </TableHead>
        </Table>
      </TableContainer>
      {listProducts.map((child, index) => {
        const PromoType = get(child, "promotion_type", "");
        const list = get(child, "list", []);
        list.map((item) => {
          total = total + item.subtotal * 1;
          return "";
        });
        return (
          <Box width={[1]} key={index}>
            {PromoType === "" && <ViewNormalItems child={child} key={index} />}
            {PromoType === PromoType_B[0] && (
              <ViewBuyXGetYItems child={child} key={index} />
            )}
            {PromoType === PromoType_B[1] && (
              <ViewBuyABGetCDItems child={child} key={index} />
            )}

            {PromoType === PromoType_B[2] && (
              <ViewDiscountItems child={child} key={index} />
            )}
            {PromoType === PromoType_B[3] && (
              <ViewSpecialPriceItems child={child} key={index} />
            )}
          </Box>
        );
      })}
      <TotalQuantity products={products} />
      <TotalTable translate={translate} totalAmount={total} record={formData} />
      <Flex width={[1]} flexDirection={"row"} justifyContent={"flex-end"}>
        {permissions !== ROLE_USER.country_manager && status === STATUSPO.sent && (
          <Flex
            width={[1]}
            flexDirection={"row"}
            justifyContent={"flex-end"}
            p={[3]}
          >
            <Button
              color={"primary"}
              onClick={handlePostToGC}
              variant={"contained"}
              style={{ borderRadius: 24, textTransform: "capitalize" }}
              startIcon={
                loading ? (
                  <CircularProgress
                    color='secondary'
                    style={{ width: 16, height: 16 }}
                  />
                ) : null
              }
            >
              {translate("resources.buttons.post_to_gp")}
            </Button>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default ListOrdersView;
