import React, { Fragment } from "react";
//useState,
// import { useRefresh } from "react-admin";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import Button from "@material-ui/core/Button";
// import CircularProgress from "@material-ui/core/CircularProgress";
// import { Flex, Text } from "rebass";
// import { UploadClientCSV } from "../../restClient";
const ButtonUploadCsv = (props) => {
  const {
    accept,
    disableClick,
    maxSize,
    minSize,
    multiple,
    translate,
    // notify,
    // urlPath,
    label,
  } = props;
  // const [loading, setLoading] = useState(false);
  // const refresh = useRefresh();
  const onDrop = (accepted, rejected) => {
    if (accepted.length > 0 && rejected.length === 0) {
      // console.log( accepted);
      let formData = new FormData();
      accepted.forEach((file) => {
        console.log(file);
        formData.append("file", file);
      });

      // console.log(urlPath, formData);
      // setLoading(true);
      // UploadClientCSV(formData, urlPath)
      //   .then((response) => response.json())
      //   .then((data) => {
      //     // console.log(data);
      //     if (data) {
      //       // this.onAdd(data.url);
      //       notify("resources.targets.message.success", "success");
      //       refresh();
      //     } else {
      //       notify(translate("resources.targets.message.max_size"), "warning");
      //     }
      //     setLoading(false);
      //   })
      //   .catch((e) => {
      //     notify("resources.targets.message.message_failed", "warning");
      //     setLoading(false);
      //   });
    }
  };
  return (
    <Fragment>
      <Dropzone
        onDrop={onDrop}
        accept={accept}
        disableClick={disableClick}
        maxSize={maxSize}
        minSize={minSize}
        multiple={multiple}
      >
        {({ getRootProps, getInputProps }) => (
          <Fragment>
            <div {...getRootProps()}>
              <Button
                color="primary"
                style={{
                  textTransform: "capitalize",
                  width: 160,
                }}
              >
                {translate(label)}
              </Button>
              <input {...getInputProps()} />

              {/* {loading && (
                <Flex flexDirection={"row"} alignItems="center" p={[2]}>
                  <CircularProgress /> <Text px={[2]}>Loading ...</Text>
                </Flex>
              )} */}
            </div>
          </Fragment>
        )}
      </Dropzone>
    </Fragment>
  );
};

ButtonUploadCsv.propTypes = {
  accept: PropTypes.string,
  children: PropTypes.element,
  disableClick: PropTypes.bool,
  elStyle: PropTypes.object,
  input: PropTypes.object,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  multiple: PropTypes.bool,
  style: PropTypes.object,
  placeholder: PropTypes.node,
  updateField: PropTypes.func,
};

ButtonUploadCsv.defaultProps = {
  addLabel: false,
  addField: true,
  multiple: false,
  maxSize: 1000000,
  accept: ".csv, application/vnd.ms-excel, text/csv",
  onUpload: () => {},
};

export default ButtonUploadCsv;
