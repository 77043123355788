import React from "react";
import { Flex, Text } from "rebass";
import { get, isString } from "lodash";
import { useQueryWithStore, useTranslate } from "react-admin";
import LinearProgress from "@material-ui/core/LinearProgress";
export default (props) => {
  const {
    record,
    source,
    label,
    reference,
    optionText,
    optionValue,
    filter = {},
  } = props;
  // console.log("optionValue", props);
  const translate = useTranslate();
  const keyFilter =
    optionValue &&
    (optionValue === "short_code" || optionValue === "code") &&
    isString(get(record, source))
      ? optionValue
      : "id";
  const getData = optionValue && keyFilter !== "id" ? "getList" : "getOne";

  // console.log(keyFilter);
  const palyoadType =
    optionValue && keyFilter !== "id"
      ? {
          pagination: { page: 1, perPage: 10 },
          sort: { field: "id", order: "ASC" },
          filter: { [keyFilter]: get(record, source) },
        }
      : { id: get(record, source), filter: filter };
  // console.log(getData);
  // console.log(palyoadType);
  const { loaded, data } = useQueryWithStore({
    type: getData,
    resource: reference,
    payload: palyoadType,
  });
  if (!loaded) {
    return (
      <Flex flexDirection={"column"}>
        <LinearProgress variant='query' />
      </Flex>
    );
  }
  // console.log(data);
  if (Array.isArray(data)) {
    if (data && data.length > 0) {
      return (
        <Flex flexDirection={"column"} px={[2]}>
          <Text color={"gray"} fontSize={[0]} fontWeight={"bold"} pb={[1]}>
            {translate(label)}
          </Text>
          <Text>{get(data[0], optionText, "")}</Text>
        </Flex>
      );
    } else {
      return (
        <Flex flexDirection={"column"} px={[2]}>
          <Text color={"gray"} fontSize={[0]} fontWeight={"bold"} pb={[1]}>
            {translate(label)}
          </Text>
          <Text />
        </Flex>
      );
    }
  }

  return (
    <Flex flexDirection={"column"} px={[2]}>
      <Text color={"gray"} fontSize={[0]} fontWeight={"bold"} pb={[1]}>
        {translate(label)}
      </Text>
      <Text>{get(data, optionText, "")}</Text>
    </Flex>
  );
};
