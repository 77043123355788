export default {
  menus: {
    accounts: "Akun",
    catalogues: "Katalog",
    clients: "Klien",
    members: "Member",
    merchants: "Pedagang",
    admin: "Admin",
    registrations: "Pendaftaran",
    provisions: "Ketentuan",
    users: "Pengguna",
    dashboard: "Dasbor",
    programmes: "Program",
    orders: "Pesanan",
    list: "Daftar",
    returns: "Pengembalian",
    products: "Produk",
    price_book: "Buku Harga",
    categories: "Kategori",
    collections: "Koleksi",
    attributes: "Atribut",
    marketing: "Pemasaran",
    promotions: "Promosi",
    banners: "Banners",
    content: "Content",
    pages: "Halaman",
    customers: "Pelanggan",
    sites: "Situs",
    settings: "Settings",
    inventory: "Inventaris",
    po: "Order Pembelian",
    dealers: "Dealers",
    targets: "Targets",
    sellout: "Sellout",
    invoiced: "Invoiced",
    reports: "Download Report",
  },
};
