import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Avatar from "@material-ui/core/Avatar"

// import PeopleIcon from "@material-ui/icons/People"
import { lightBlue } from "@material-ui/core/colors"

const styles = {
  orangeAvatar: {
    margin: 8,
    width: 32,
    height: 32,
    color: "#fff",
    backgroundColor: lightBlue[500],
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "#FFFFFF",
  },
}

function AvatarUser(props) {
  const { classes, src } = props
  if (src) {
    return <Avatar className={classes.orangeAvatar} src={src} />
  }
  return (
    <Avatar
      className={classes.orangeAvatar}
      src={"../../assets/images/avatar.png"}
    />
  )
}

AvatarUser.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AvatarUser)
