import React from "react";
import { Flex } from "rebass";
import { Loading as Load } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles({
  loading_sm: {
    height: 100,
    marginTop: 0,
  },
  loading_md: {
    height: 200,
    marginTop: 0,
  },
});

export const LoadingRa = (props) => {
  const { size } = props;
  const classes = useStyles();
  const layoutClasses = size === "md" ? classes.loading_md : classes.loading_sm;
  return (
    <Flex
      width={[1]}
      flexDirection={"row"}
      justifyContent={"center"}
      alignItems={"center"}
      p={[3]}
    >
      <Load className={layoutClasses} />
    </Flex>
  );
};

export const Loading = () => (
  <Flex width={[1]} p={[1]} justifyContent={["center"]} alignItems={["center"]}>
    <CircularProgress />
  </Flex>
);

export const FullLoadingScreen = ({ color = "secondary" }) => (
  <Flex
    width={[1]}
    p={[1]}
    justifyContent={["center"]}
    alignItems={["center"]}
    style={{ height: "100vh" }}
  >
    <CircularProgress color={color} />
  </Flex>
);
