import React from "react";
import moment from "moment";
import { get } from "lodash";
import { TextField, useTranslate, Filter, SearchInput } from "react-admin";
import { Flex, Box } from "rebass";
import {
  BetterList,
  Title,
  CreatePoButton,
  BetterDeleteButton,
  BetterShowButton,
} from "../components";
import { getUser, showDealer } from "../utils";
import NumberFormat from "react-number-format";
import numeral from "numeral";
import { ButtonPODetail } from "../screens/reports";
// const UserField = ({ record, ...rest }) => {
//   console.log(rest);
//   return <span>{`${record.country_id}  `}</span>;
// };

const AmountField = ({ record, source }) => {
  const total = get(record, source, 0);
  const roundUpTotal = numeral(total).format("0,0.00");
  return (
    <div style={{ textAlign: "right", width: 100 }}>
      <NumberFormat
        value={roundUpTotal}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$ "}
        decimalScale={2}
      />
    </div>
  );
};

const POFilter = (props) => {
  const translate = useTranslate();
  return (
    <Box width={[1]} pb={[2]}>
      <Box width={[1]} pt={[2]}>
        <Title fontWeight={"bold"} fontSize={[3]}>
          {translate(`resources.po.title`)}
        </Title>
      </Box>
      <Flex width={[1]} flexDirection={"row"} justifyContent={"flex-end"}>
        <Filter {...props}>
          <SearchInput source='search' alwaysOn style={{ width: 256 }} />
        </Filter>
      </Flex>
    </Box>
  );
};
const POAction = ({ basePath }) => {
  return (
    <Flex
      pt={[2]}
      pl={[2]}
      pr={[2]}
      flexDirection={"row"}
      style={{ position: "fixed", right: 16 }}
    >
      <Flex flexDirection={"row"} justifyContent={"flex-end"}>
        <CreatePoButton
          basePath={"/purchaseOrder"}
          sizeIcon={"xsmall"}
          label={"resources.buttons.create_po"}
          style={{ textTransform: "capitalize" }}
        />
      </Flex>
    </Flex>
  );
};

const DateCustomField = ({ record, source, format = "DD-MMMM-YYYY" }) => {
  const mydate = get(record, source, "");
  if (mydate === "") {
    return <span />;
  }

  return <span>{moment(mydate).format(format)}</span>;
};
const DeleteButton = (props) => {
  const { record } = props;
  const isShow = get(record, "status") && get(record, "status") === "Draft";
  return isShow ? (
    <Flex flexDirection={"row"} justifyContent={"flex-end"}>
      <ButtonPODetail {...props} />
      <BetterShowButton {...props} />
      <BetterDeleteButton {...props} />
    </Flex>
  ) : (
    <Flex flexDirection={"row"} justifyContent={"flex-end"}>
      <ButtonPODetail {...props} />
      <BetterShowButton {...props} />
    </Flex>
  );
};

const POListDealer = (props) => {
  const { permissions } = props;
  const user = getUser();
  const dealerID = get(user, "dealer_id");
  const dealerFilter =
    showDealer(permissions) && dealerID ? { dealer: dealerID } : {};

  return (
    <BetterList
      {...props}
      actions={<POAction />}
      // rowclick={"show"}
      filters={<POFilter />}
      filter={dealerFilter}
      empty={false}
    >
      <TextField
        source='purchase_order_number'
        label={"resources.po.fields.gc_ref"}
      />
      <TextField
        source='my_reference_number'
        label={"resources.po.fields.my_ref"}
      />
      <TextField source='status' label={"resources.po.fields.status"} />
      <AmountField source='total' label={"resources.po.fields.amount"} />
      <DateCustomField
        source='created_at'
        label='resources.po.fields.created_on'
        format={"DD-MMM-YYYY"}
      />

      <DeleteButton />
    </BetterList>
  );
};
export default POListDealer;
