import React from "react";
import { Flex, Box } from "rebass";
import { makeStyles } from "@material-ui/core/styles";
import { TextInput, required, SelectInput } from "react-admin";
import { dataCurrency } from "../utils";
import { RaBooleanInput, RaNumberInput, Title } from "../../../components";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
  },
  header: {
    backgroundColor: "#eeeeee",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const FormFieldCountries = (props) => {
  const { translate } = props;
  const classes = useStyles();
  return (
    <Flex width={[1]} flexDirection={"column"} p={[3]}>
      <Flex
        width={[1]}
        flexDirection={"row"}
        sx={{
          "&>div": {
            p: [2],
          },
          "div:first-of-type": {
            pl: [0],
          },
        }}
      >
        <Box>
          <TextInput
            source='name'
            validate={required()}
            style={{ width: 300 }}
            label='resources.settings.fields.country'
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box>
          <TextInput
            source='code'
            validate={required()}
            style={{ width: 80 }}
            label='resources.settings.fields.code'
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box>
          <SelectInput
            source='currency'
            choices={dataCurrency}
            label='resources.settings.fields.currency'
            InputLabelProps={{ shrink: true }}
            style={{ width: 100 }}
          />
        </Box>
      </Flex>
      {/* <BooleanInput source="active" label="resources.settings.fields.active" /> */}

      <Box width={[1]} pb={[3]}>
        <TextInput
          source='gc_admin_order_email'
          // validate={required()}
          style={{ width: 400 }}
          label='resources.settings.fields.gc_admin_order_email'
          InputLabelProps={{ shrink: true }}
        />
        <TextInput
          source='order_form_remarks'
          label='resources.settings.fields.remarks'
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Card className={classes.root}>
        <CardHeader
          className={classes.header}
          title={
            <Title fontSize={[2]} fontWeight={"bold"}>
              {translate("resources.settings.labels.po_additional_charges")}
            </Title>
          }
        />

        <CardContent>
          <RaBooleanInput
            source='po_additional_charges.packing_charge.active'
            label='resources.settings.fields.packaging_charges.name'
          />
          <Flex
            flexDirection={"row"}
            sx={{
              "&>div": {
                px: [2],
              },
              "div:first-of-type": {
                pl: [0],
              },
            }}
          >
            <RaNumberInput
              source='po_additional_charges.packing_charge.less_than_10k_sgd'
              label='resources.settings.fields.packaging_charges.less_sgd_10k'
            />
            <RaNumberInput
              source='po_additional_charges.packing_charge.greater_than_equals_10k_sgd'
              label='resources.settings.fields.packaging_charges.more_sgd_10k'
            />
            <RaNumberInput
              source='po_additional_charges.packing_charge.less_than_10k_usd'
              label='resources.settings.fields.packaging_charges.less_usd_10k'
            />
            <RaNumberInput
              source='po_additional_charges.packing_charge.greater_than_equals_10k_usd'
              label='resources.settings.fields.packaging_charges.more_usd_10k'
            />
          </Flex>
          <RaBooleanInput
            source='po_additional_charges.gst.active'
            label='resources.settings.fields.gst.name'
            style={{ paddingTop: 16 }}
          />
          <Flex
            flexDirection={"row"}
            sx={{
              "&>div": {
                px: [2],
              },
              "div:first-of-type": {
                pl: [0],
              },
            }}
          >
            <RaNumberInput
              source='po_additional_charges.gst.percent'
              label='resources.settings.fields.gst.percent'
            />
            <RaNumberInput
              source='po_additional_charges.freight_charge'
              label='resources.settings.fields.gst.freight_charge'
            />
            <RaNumberInput
              source='po_additional_charges.insurance'
              label='resources.settings.fields.gst.insurance'
            />
          </Flex>
        </CardContent>
      </Card>
    </Flex>
  );
};

export default FormFieldCountries;
