import React from "react";
import { Flex, Text } from "rebass";
import { get, map, sumBy, has } from "lodash";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
// import NumberFormat from "react-number-format";
import { StyledTableAction, StyledTableCell, StyledTableRow } from "../edit";
import { CurrencyField } from "./CurrencyField";
import { NumberInput } from "react-admin";
import { getValuePackageCharge } from "../../../../components";
import { PromoTypeB } from "../../../../restClient";
// const PromoTypeB = {
//   buyxgety: "Buy X Get Y",
//   buyabgetcd: "Buy AB Get CD",
//   discount: "Discount",
//   sp: "Special Price",
// };

const getSubtotal = (record) => {
  const { items } = record;
  const newList = map(items, function (o) {
    const promotions = get(o, "promotions", null);
    const promoType = get(promotions, "promotion_type", "");
    const discountPrice = get(o, "discounted_price", 0) * 1;

    if (promoType === PromoTypeB.sp) {
      return { id: o.id, subtotal: o.qty * discountPrice };
    }
    const price = get(o, "price", 0) * 1;
    const newPrice = has(o, "discounted_price") ? discountPrice : price;
    // const newPrice = discountPrice > 0 ? discountPrice : price;
    return { id: o.id, subtotal: o.qty * newPrice };
    // if (promotions !== null) {
    //   const promoType = get(promotions, "promotion_type", "");
    //   if (promoType === PromoTypeB.buyxgety) {
    //     return { id: o.id, subtotal: o.qty * newPrice };
    //   } else if (promoType === PromoTypeB.buyabgetcd) {
    //     return { id: o.id, subtotal: o.qty * newPrice };
    //   } else if (promoType === PromoTypeB.sp) {
    //     // const discountPrice = get(o, "discounted_price", 0) * 1;
    //     // const price = get(o, "price", 0) * 1;
    //     // const newPrice = discountPrice === 0 ? price : discountPrice;
    //     return { id: o.id, subtotal: o.qty * newPrice };
    //   } else if (promoType === PromoTypeB.discount) {
    //     // const discountPrice = get(o, "discounted_price", 0) * 1;
    //     // const price = get(o, "price", 0) * 1;
    //     // const newPrice = discountPrice === 0 ? price : discountPrice;
    //     return { id: o.id, subtotal: o.qty * newPrice };
    //   } else {
    //     return { id: o.id, subtotal: o.qty * newPrice };
    //   }
    // } else {
    //   return { id: o.id, subtotal: o.qty * newPrice };
    // }
  });
  return newList;
};

const EditTotalTable = (props) => {
  const { translate, record, isEdit, country } = props;
  const isActive = get(country, "po_additional_charges.gst.active", false);
  const gst = get(record, "tax_rate", 0) * 1;
  //   const gst = get(country, "po_additional_charges.gst.percent", 0) * 1;
  // const currentGST = gst > 1 ? gst : gst * 100;
  // const valueCurrentGST = (totalAmount * currentGST) / 100;
  // //package_charge

  const isPCActive = get(
    country,
    "po_additional_charges.packing_charge.active",
    false
  );
  const itemPackingCharge = get(
    country,
    "po_additional_charges.packing_charge",
    {}
  );
  const currencyDefault = get(country, "currency", "");
  const listTotal = getSubtotal(record);
  const totalAmountRecord = sumBy(listTotal, "subtotal") * 1;
  const valuePackageCharge = getValuePackageCharge(
    itemPackingCharge,
    currencyDefault,
    totalAmountRecord
  );
  const shipping = get(record, "shipping", 0) * 1;
  const currentGST = gst > 1 ? gst : gst * 100;
  const joinTA_PC = isPCActive
    ? totalAmountRecord + valuePackageCharge
    : totalAmountRecord;
  const valueCurrentGST = (joinTA_PC * currentGST) / 100;
  const grandTotal = isActive
    ? valueCurrentGST + joinTA_PC + shipping
    : joinTA_PC + shipping;

  return (
    <Flex width={[1]} flexDirection={"column"} alignItems={"flex-end"} pt={[1]}>
      <TableContainer component={"div"} style={{ width: 380 }}>
        <Table aria-label='customized table'>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell />
              <StyledTableAction />
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell>
                <Text textAlign={"right"}>
                  {translate("resources.po.listgrid.label.subtotal")}
                </Text>
              </StyledTableCell>
              <StyledTableAction
                style={{ textAlign: "right", paddingRight: 16 }}
              >
                <CurrencyField value={totalAmountRecord} />
              </StyledTableAction>
            </StyledTableRow>
            {isPCActive ? (
              <StyledTableRow>
                <StyledTableCell>
                  <Text textAlign={"right"} fontSize={[0]}>
                    {translate("resources.po.listgrid.label.package_charge")}
                  </Text>
                </StyledTableCell>
                <StyledTableAction
                  style={{ textAlign: "right", paddingRight: 16 }}
                >
                  <Text textAlign={"right"} fontSize={[0]}>
                    <CurrencyField value={valuePackageCharge} />
                  </Text>
                </StyledTableAction>
              </StyledTableRow>
            ) : (
              <StyledTableRow>
                <StyledTableCell>
                  <Text textAlign={"right"}>
                    {translate("resources.po.listgrid.label.package_charge")}
                  </Text>
                </StyledTableCell>
                <StyledTableAction
                  style={{ textAlign: "right", paddingRight: 16 }}
                >
                  <CurrencyField value={0} />
                </StyledTableAction>
              </StyledTableRow>
            )}
            <StyledTableRow>
              <StyledTableCell>
                <Text textAlign={"right"}>
                  {translate("resources.po.listgrid.label.shipping")}
                </Text>
              </StyledTableCell>
              <StyledTableAction
                style={{ textAlign: "right", paddingRight: 16 }}
              >
                {isEdit ? (
                  <NumberInput
                    source={"shipping"}
                    label={""}
                    defaultValue={0}
                    // addLabel={false}
                    style={{
                      width: 80,
                      height: 40,
                      marginTop: 0,
                      marginBottom: 8,
                      textAlign: "right",
                    }}
                    size='small'
                  />
                ) : (
                  <CurrencyField value={get(record, "shipping", 0)} />
                )}
              </StyledTableAction>
            </StyledTableRow>
            {isActive ? (
              <StyledTableRow>
                <StyledTableCell>
                  <Text textAlign={"right"} fontSize={[0]}>
                    {translate("resources.po.listgrid.label.tax")} {currentGST}%
                  </Text>
                </StyledTableCell>
                <StyledTableAction
                  style={{ textAlign: "right", paddingRight: 16 }}
                >
                  <Text textAlign={"right"} fontSize={[0]}>
                    <CurrencyField value={valueCurrentGST} />
                  </Text>
                </StyledTableAction>
              </StyledTableRow>
            ) : (
              <StyledTableRow>
                <StyledTableCell>
                  <Text textAlign={"right"}>Tax 0%</Text>
                </StyledTableCell>
                <StyledTableAction
                  style={{ textAlign: "right", paddingRight: 16 }}
                >
                  <CurrencyField value={0} />
                </StyledTableAction>
              </StyledTableRow>
            )}
            <StyledTableRow>
              <StyledTableCell>
                <Text textAlign={"right"}>
                  {translate("resources.po.listgrid.label.total")}
                </Text>
              </StyledTableCell>
              <StyledTableAction
                style={{ textAlign: "right", paddingRight: 16 }}
              >
                <CurrencyField value={grandTotal} />
              </StyledTableAction>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Flex>
  );
};

export default EditTotalTable;
