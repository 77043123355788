export default {
  orders: {
    title: "Orders",
    new_order: "New Order",
    edit_order: "Edit Order",
    show_order: "Order",
    otd: "OTD",
    upload_otd: "Upload OTD Pricing",
    tabs: {
      order: "Order",
      analytic: "Analytics",
      otd: "OTD",
    },
    fields: {
      name: "Name",
      status: "Status",
      reference: "Reference",
      dealer: "Dealer",
      country: "Country",
      created_at: "Create On",
      order_reference: "Order Reference",
      order_date: "Order Date",
      delivery_date: "Delivery Date",
      order_items: "Order Items",
      address: "Address",
      tel: "Tel",
      remarks: "Remarks",
      product: "Product",
      code: "Code",
      qty: "Qty",
      price: "Price",
      subtotal: "Subtotal",
      total: "Total",
      promotion: "Promotion",
      rows: "Rows",
      period: "Period",
      date: "Date",
      items: "Items",
      customer_ref: "Customer Reference",
      discounted_price: "Discounted Price",
    },
    messages: {
      product_qty: "Invalid product or Qty",
      orderItemsEmpty: "Please add product to Order Items",
      create_order: "Create Order Not Success",
      create_order_items: "Create Order Items Not Success",
      element_created: "Element Created",
      check_period: "Please select Period first to upload csv file.",
      order_cannot_post: "Order id not detect",
    },
  },
};
