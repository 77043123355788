import React from "react";
import { Divider, Tabs, Tab } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { TabPanel, a11yProps, Title, NoAccessPage } from "../components";
import { Flex, Box } from "rebass";
import {
  useTranslate,
  useQueryWithStore,
  Loading,
  useAuthState,
} from "react-admin";
import SwipeableViews from "react-swipeable-views";
import { Link } from "react-router-dom";
import { get, filter, orderBy, find } from "lodash";
import { getUser, ROLE_USER } from "../utils";
// import { CardPanel } from "../components"
// import ContainerDimensions from "react-container-dimensions";

import {
  OTD,
  Inventory,
  Invoiced,
  GrowthLoss,
  Promotions,
  Top20,
} from "./components/reports";
import PurchaseOrders from "./components/reports/PurchaseOrders";
import { useQuery } from "../components";
import { isDealers } from "../utils/localeConfig";

const payloadDefault = {
  pagination: { page: 1, perPage: 100 },
};

const tabs = [
  { id: "po", name: "PO", link: "/" },
  { id: "invoiced", name: "Invoiced", link: "/" },
  { id: "growth_loss", name: "Growth/Loss", link: "/" },
  { id: "top_20", name: "Top 20", link: "/" },
  { id: "otd", name: "OTD", link: "/" },
  { id: "promotion", name: "Promotion", link: "/" },
  { id: "inventory", name: "Inventory", link: "/" },
];

const getListCodes = (data, source) => {
  const newList = filter(data, function (o) {
    return o.type === source && o.active;
  });

  const newOrderBy = orderBy(newList, ["label", "ASC"]);
  return newOrderBy;
};

const getListOrder = (data) => {
  return orderBy(data, ["name", "ASC"]);
};

const Dashboard = (props) => {
  const { permissions } = props;
  const { loading, authenticated } = useAuthState();
  const user = getUser();

  if (loading) {
    return <Loading />;
  }
  if (authenticated && permissions === ROLE_USER.country_manager) {
    const countryId = get(user, "country_id");
    return <MainDashboard {...props} countryId={countryId} user={user} />;
  }

  return authenticated ? (
    <MainDashboard {...props} user={user} />
  ) : (
    <NoAccessPage />
  );
};

const MainDashboard = (props) => {
  const { countryId, permissions, user } = props;
  let indexTab = 0;
  const theme = useTheme();
  const translate = useTranslate();
  const [value, setValue] = React.useState(indexTab);
  const countryFilter = countryId
    ? { country_id: countryId, active: true }
    : { active: true };
  const dataCountries = useQueryWithStore({
    type: "getList",
    resource: "countries",
    payload: {
      ...payloadDefault,
      sort: { field: "name", order: "ASC" },
      filter: { active: true },
    },
  });

  const dataDealers = useQueryWithStore({
    type: "getList",
    resource: "dealers",
    payload: {
      ...payloadDefault,
      sort: { field: "name", order: "ASC" },
      filter: countryFilter,
    },
  });
  const dataCodes = useQuery(
    `/codes?_order=${encodeURIComponent(
      "ASC"
    )}&active=true&_sort=${encodeURIComponent("label")}`
  );

  // const dataTargetSetting =  dataCodes&& dataCodes.data?dataCodes.data:[]
  // //  useQuery(`/codes?type=TargetSetting`);
  // const dataCategories = useQuery(`/codes?type=Category`);
  // // console.log(dataTargetSetting.data);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const country = countryId
    ? find(dataCountries.data, function (o) {
        return o.id === countryId;
      })
    : {};
  return (
    <Box width={[1]}>
      <Flex width={[1]} py={[3]} flexDirection={"row"}>
        <Title fontSize={[3]} fontWeight={"bold"}>
          {translate("resources.dashboard.reports")}
        </Title>
      </Flex>
      <Tabs value={value} indicatorColor='primary' onChange={handleChange}>
        {tabs.map((choice, index) => (
          <Tab
            key={choice.id}
            label={choice.name}
            component={Link}
            to={choice.link}
            style={{ minWidth: 100 }}
            {...a11yProps("reports", index)}
          />
        ))}
      </Tabs>
      <Divider />
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel
          value={value}
          index={0}
          dir={theme.direction}
          name={"reports"}
        >
          {dataCodes.loaded ? (
            <Flex width={[1]} flexDirection={"column"}>
              <PurchaseOrders
                {...props}
                countries={
                  dataCountries ? getListOrder(dataCountries.data) : []
                }
                dealers={dataDealers ? getListOrder(dataDealers.data) : []}
                targetSettings={
                  dataCodes ? getListCodes(dataCodes.data, "TargetSetting") : []
                }
                categories={
                  dataCodes ? getListCodes(dataCodes.data, "Category") : []
                }
                country={country}
                translate={translate}
                isDealer={isDealers(permissions)}
                dealerId={get(user, "dealer_id", null)}
              />
            </Flex>
          ) : (
            <Flex width={[1]} flexDirection={"column"} alignItems={"center"}>
              <Loading />
            </Flex>
          )}
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          dir={theme.direction}
          name={"reports"}
        >
          {dataCodes.loaded ? (
            <Invoiced
              {...props}
              title={"Invoiced"}
              countries={dataCountries ? getListOrder(dataCountries.data) : []}
              dealers={dataDealers ? getListOrder(dataDealers.data) : []}
              targetSettings={
                dataCodes ? getListCodes(dataCodes.data, "TargetSetting") : []
              }
              categories={
                dataCodes ? getListCodes(dataCodes.data, "Category") : []
              }
              translate={translate}
              isDealer={isDealers(permissions)}
              country={country}
              dealerId={get(user, "dealer_id", null)}
            />
          ) : (
            <Flex width={[1]} flexDirection={"column"} alignItems={"center"}>
              <Loading />
            </Flex>
          )}
        </TabPanel>
        <TabPanel
          value={value}
          index={2}
          dir={theme.direction}
          name={"reports"}
        >
          <GrowthLoss
            title={"Growth/Loss"}
            {...props}
            countries={dataCountries ? getListOrder(dataCountries.data) : []}
            dealers={dataDealers ? getListOrder(dataDealers.data) : []}
            translate={translate}
            isDealer={isDealers(permissions)}
            dealerId={get(user, "dealer_id", null)}
            country={country}
          />
        </TabPanel>
        <TabPanel
          value={value}
          index={3}
          dir={theme.direction}
          name={"reports"}
        >
          <Top20
            title={"Top 20"}
            {...props}
            countries={dataCountries ? getListOrder(dataCountries.data) : []}
            dealers={dataDealers ? getListOrder(dataDealers.data) : []}
            targetSettings={
              dataCodes ? getListCodes(dataCodes.data, "TargetSetting") : []
            }
            categories={
              dataCodes ? getListCodes(dataCodes.data, "Category") : []
            }
            translate={translate}
            isDealer={isDealers(permissions)}
            dealerId={get(user, "dealer_id", null)}
            country={country}
          />
        </TabPanel>
        <TabPanel
          value={value}
          index={4}
          dir={theme.direction}
          name={"reports"}
        >
          <OTD
            title={"OTD"}
            {...props}
            countries={dataCountries ? getListOrder(dataCountries.data) : []}
            dealers={dataDealers ? getListOrder(dataDealers.data) : []}
            targetSettings={
              dataCodes ? getListCodes(dataCodes.data, "TargetSetting") : []
            }
            categories={
              dataCodes ? getListCodes(dataCodes.data, "Category") : []
            }
            translate={translate}
            isDealer={isDealers(permissions)}
            dealerId={get(user, "dealer_id", null)}
            country={country}
          />
        </TabPanel>
        <TabPanel
          value={value}
          index={5}
          dir={theme.direction}
          name={"reports"}
        >
          <Promotions
            title={"Promotions"}
            {...props}
            countries={dataCountries ? getListOrder(dataCountries.data) : []}
            dealers={dataDealers ? getListOrder(dataDealers.data) : []}
            targetSettings={
              dataCodes ? getListCodes(dataCodes.data, "TargetSetting") : []
            }
            categories={
              dataCodes ? getListCodes(dataCodes.data, "Category") : []
            }
            translate={translate}
            isDealer={isDealers(permissions)}
            dealerId={get(user, "dealer_id", null)}
            country={country}
          />
        </TabPanel>
        <TabPanel
          value={value}
          index={6}
          dir={theme.direction}
          name={"reports"}
        >
          <Inventory
            title={"Inventory"}
            {...props}
            countries={dataCountries ? getListOrder(dataCountries.data) : []}
            dealers={dataDealers ? getListOrder(dataDealers.data) : []}
            targetSettings={
              dataCodes ? getListCodes(dataCodes.data, "TargetSetting") : []
            }
            categories={
              dataCodes ? getListCodes(dataCodes.data, "Category") : []
            }
            translate={translate}
            isDealer={isDealers(permissions)}
            dealerId={get(user, "dealer_id", null)}
            country={country}
          />
        </TabPanel>
      </SwipeableViews>
      {/* <ContentDashboard {...props} /> */}
    </Box>
  );
};
export default Dashboard;
