import React from "react";
import { Flex, Box } from "rebass";
import Paper from "@material-ui/core/Paper";
import { useTranslate, ImageField } from "react-admin";
import ProductFields from "./ProductFields";
import Classifications from "./Classifications";
import { RaTextArea, ImageOlaInput } from "../../components";
const InfoFields = (props) => {
  const { record, ...rest } = props;
  const translate = useTranslate();

  return (
    <Flex width={[1]} flexDirection={"column"} px={[2]}>
      <ProductFields {...props} translate={translate} />
      <Classifications {...props} translate={translate} />
      <Box width={[1]} pt={[3]}>
        <Paper elevation={3}>
          <Box width={[1]} p={[3]} pt={[1]}>
            <ImageOlaInput
              source='photos'
              toValue='photos'
              labelImage={translate("resources.products.fields.product_image")}
              accept='image/*'
              key='photos'
              record={props.formValues ? props.formValues : record}
              {...rest}
            >
              <ImageField source='src' title='title' />
            </ImageOlaInput>
            {/* <ImageOlaInput source="photos" /> */}
          </Box>
        </Paper>
      </Box>
      <Box width={[1]} pt={[3]}>
        <Paper elevation={3}>
          <Box width={[1]} p={[3]}>
            <RaTextArea
              source='remarks'
              label={"resources.products.fields.remarks"}
              rows='5'
              fullWidth
              multiline
              InputLabelProps={{
                shrink: true,
                disableAnimation: true,
              }}
              style={{ transform: "none" }}
            />
          </Box>
        </Paper>
      </Box>
    </Flex>
  );
};

export default InfoFields;
