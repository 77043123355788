import React from "react";
import { Edit, Loading } from "react-admin";
import {
  BetterTabbedForm,
  FormValueTab,
  ActionForm,
  EditToolbar,
  // useQuery,
} from "../components";
import { sortBy, filter, map } from "lodash";
import { useQuery } from "react-query";
import { FormFields, InfoFields } from "./components";
import { RestApi } from "../restClient";
const cacheTimeMinute = 1000 * 60 * 30;
const ProductEdit = (props) => {
  const { hasList, hasCreate, ...rest } = props;
  const { isLoading, data } = useQuery(
    `/codes?_order=${encodeURIComponent(
      "ASC"
    )}&active=true&_sort=${encodeURIComponent("label")}`,
    () =>
      RestApi(
        `/codes?_order=${encodeURIComponent(
          "ASC"
        )}&active=true&_sort=${encodeURIComponent("label")}`
      ).then((res) => res.json()),
    { cacheTime: cacheTimeMinute }
  );
  // const { data, loading } = useQuery(
  //   `/codes?_order=${encodeURIComponent("ASC")}_sort=${encodeURIComponent(
  //     "label"
  //   )}`
  // );
  const countries = useQuery(
    `/countries`,
    () => RestApi(`/countries`).then((res) => res.json()),
    { cacheTime: cacheTimeMinute }
  );
  if (countries.loading) {
    return <Loading />;
  }
  if (isLoading) {
    return <Loading />;
  }
  const dataCountries = sortBy(countries.data, function (o) {
    return o.name;
  });
  const listCode = filter(data, function (o) {
    return o.active;
  });
  // console.log("dataCountries", countries.data);
  const dataGetIDS = map(countries.data, function (c) {
    return [c.id, ""];
  });
  const newClass4 = Object.fromEntries(dataGetIDS);
  return (
    <Edit
      {...rest}
      actions={<ActionForm title={"resources.products.edit_product"} />}
    >
      <BetterTabbedForm {...rest} toolbar={<EditToolbar />}>
        <FormValueTab label='resources.products.tabs.info'>
          <InfoFields
            {...rest}
            listcode={listCode && listCode.length > 0 ? listCode : []}
            countries={dataCountries}
          />
        </FormValueTab>
        <FormValueTab label='resources.products.tabs.country' path={"country"}>
          <FormFields
            {...rest}
            listcode={listCode && listCode.length > 0 ? listCode : []}
            countries={dataCountries}
            newClass4={newClass4}
          />
        </FormValueTab>
      </BetterTabbedForm>
    </Edit>
  );
};

export default ProductEdit;
