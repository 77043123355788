import React from "react";
import { EditIcon, ShowIcon, Chart8, TrashIcon, User1Icon } from "./icon_svg";
import {
  EditButton,
  ShowButton,
  ChartButton,
  DeleteButton,
  MemberButton,
  ShowUrlButton,
  EditUrlButton,
} from "./buttons/rabutton";

export const BetterEditButton = (props) => {
  const { bashPath, hasList, hasCreate, hasEdit, hasShow, ...checkProps } =
    props;
  return (
    <EditButton
      {...checkProps}
      label=''
      style={{ textAlign: "right", fontSize: 14, padding: 7 }}
      icon={<EditIcon fontSize={"small"} />}
    />
  );
};
export const BetterUserButton = (props) => {
  const { bashPath, hasList, hasCreate, hasEdit, hasShow, ...checkProps } =
    props;
  return (
    <MemberButton
      {...checkProps}
      label=''
      style={{ textAlign: "right", fontSize: 14, padding: 7 }}
      icon={<User1Icon fontSize={"small"} />}
    />
  );
};

export const BetterChartButton = (props) => {
  const { bashPath, hasList, hasCreate, hasEdit, hasShow, ...checkProps } =
    props;
  return (
    <ChartButton
      {...checkProps}
      label=''
      style={{ textAlign: "right", fontSize: 14, padding: 7 }}
      icon={<Chart8 fontSize={"small"} />}
    />
  );
};
export const BetterDeleteButton = (props) => {
  const { bashPath, hasList, hasCreate, hasEdit, hasShow, ...checkProps } =
    props;
  return (
    <DeleteButton
      {...checkProps}
      label=''
      style={{ textAlign: "right", fontSize: 14, padding: 7 }}
      icon={<TrashIcon fontSize={"small"} />}
    />
  );
};
export const BetterShowButton = (props) => {
  const { hasList, hasCreate, hasEdit, hasShow, icon, ...checkProps } = props;
  return (
    <ShowButton
      {...checkProps}
      label=''
      style={{ textAlign: "right", fontSize: 14, padding: 7, marginRight: 8 }}
      icon={icon ? icon : <ShowIcon fontSize={"small"} />}
    />
  );
};

export const BetterShowUrlButton = (props) => {
  const { hasList, hasCreate, hasEdit, hasShow, icon, ...checkProps } = props;
  return (
    <ShowUrlButton
      {...checkProps}
      label=''
      style={{ textAlign: "right", fontSize: 14, padding: 7, marginRight: 8 }}
      icon={icon ? icon : <ShowIcon fontSize={"small"} />}
    />
  );
};

export const BetterEditUrlButton = (props) => {
  const { bashPath, hasList, hasCreate, hasEdit, hasShow, ...checkProps } =
    props;
  return (
    <EditUrlButton
      {...checkProps}
      label=''
      style={{ textAlign: "right", fontSize: 14, padding: 7 }}
      icon={<EditIcon fontSize={"small"} />}
    />
  );
};
