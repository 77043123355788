import React, { useState, useEffect } from "react";
import { Flex, Text, Box } from "rebass";
import { get, filter, sumBy, map, has } from "lodash";
import { Button } from "@material-ui/core";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import CancelIcon from "@material-ui/icons/Cancel";
import { DialogHeader } from "./edit";
import {
  useStoreTemp,
  ArListGridNormalC,
  useStoreTempABCD,
} from "../../../components/argrid";
import { ShowMessage } from "./edit/ShowMessage";
import { PromoTypeB } from "../../../restClient";
// const PromoTypeB = {
//   buyxgety: "Buy X Get Y",
//   buyabgetcd: "Buy AB Get CD",
//   discount: "Discount",
//   sp: "Special Price",
// };

// const getSubtotal = (items) => {
//   const newList = map(items, function (o) {
//     const discountPrice = get(o, "discounted_price", 0) * 1;
//     const price = get(o, "price", 0) * 1;
//     const newPrice = discountPrice > 0 ? discountPrice : price;
//     return { id: o.id, subtotal: o.qty * newPrice };
//   });
//   //   console.log(newList);
//   return newList;
// };

const getSubtotal = (items) => {
  const newList = map(items, function (o) {
    const price = get(o, "price", 0) * 1;
    const promotions = get(o, "promotions", null);
    const promoType = get(promotions, "promotion_type", "");
    const discountPrice = get(o, "discounted_price", 0) * 1;

    if (promoType === PromoTypeB.sp) {
      return { id: o.id, subtotal: o.qty * discountPrice };
    }

    const newPrice = has(o, "discounted_price") ? discountPrice : price;
    return { id: o.id, subtotal: o.qty * newPrice };
  });

  return newList;
};

const DialogListGridNormal = (props) => {
  const {
    open,
    setOpen,
    width,
    itemPromotion,
    translate,
    totalQty,
    totalAmount,
    formData,
    onSaveOrder,
    isOAAsossiate,
    countryId,
    country,
  } = props;

  const products = get(formData, "items", []);
  const dealerId = get(formData, "dealer_id");
  // console.log(dealerId, formData);
  // const [tempList, setTempList] = useState([]);
  const { tempList, setTempList } = useStoreTemp();
  const { tempListBuy } = useStoreTempABCD();
  const [show, setShow] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalAmt, setTotalAmt] = useState(0);
  const [thisQuantity, setThisQuantity] = useState(0);
  const [thisAmt, setThisAmt] = useState(0);

  useEffect(() => {
    // This gets called after every render, by default
    // (the first one, and every one after that)
    // console.log("render!");
    let totalQuantityA = 0;
    let totalAmtA = 0;
    // console.log(itemPromotion);
    let myTempList = [];
    products &&
      products.length > 0 &&
      products.map((item) => {
        if (itemPromotion === null && item.promotions === null) {
          totalQuantityA = totalQuantityA + item.qty * 1;
          const discountPrice = get(item, "discounted_price", 0) * 1;
          const price = get(item, "price", 0) * 1;
          const newPrice = discountPrice === 0 ? price : discountPrice;
          const subtotal = item.qty * newPrice;
          totalAmtA = totalAmtA + subtotal * 1;
          // totalAmtA = totalAmtA + item.subtotal * 1;
          //   console.log(totalAmtA, item);

          if (discountPrice > 0 && get(item, "unit_price", 0) === 0) {
            item["unit_price"] =
              get(item, "unit_price", 0) === 0
                ? get(item, "price", 0)
                : get(item, "unit_price", 0);
            item["price"] = newPrice;
          }
          myTempList.push(item);
        }
        return "";
      });
    // updateDefaultList(totalAmtA, totalQuantityA);
    const newTA = getSubtotal(products);
    const totalQtySum = sumBy(products, "qty");
    const newTotalAmount = sumBy(newTA, "subtotal");
    const resultTotal =
      itemPromotion === null ? totalQtySum : totalQtySum - totalQuantityA;
    setThisAmt(totalAmtA);
    setTempList(myTempList);
    setTotalQuantity(resultTotal);
    setTotalAmt(newTotalAmount);
    setThisQuantity(totalQuantityA);
    // If you want to implement componentWillUnmount,
    // return a function from here, and React will call
    // it prior to unmounting.
    // return () => console.log("unmounting...");
  }, [
    totalQty,
    totalAmount,
    itemPromotion,
    products,
    isOAAsossiate,
    setTempList,
  ]);

  const updateTempList = (tempList1) => {
    if (tempList1 && tempList1.length > 0) {
      let totalQuantityA = 0;
      let totalAmtA = 0;
      tempList1.map((item) => {
        if (itemPromotion === null && item.promotions === null) {
          totalQuantityA = totalQuantityA + item.qty * 1;
          totalAmtA = totalAmtA + item.subtotal * 1;
        } else {
          if (isOAAsossiate) {
            if (
              itemPromotion &&
              itemPromotion.id === get(item, "promotions.id", 0) &&
              itemPromotion.promotion_type ===
                get(item, "promotions.promotion_type", "")
            ) {
              totalQuantityA = totalQuantityA + item.qty * 1;
              totalAmtA = totalAmtA + item.subtotal * 1;
            }
          } else {
            if (
              itemPromotion &&
              itemPromotion.id === get(item, "promotions.id", 0) &&
              itemPromotion.promotion_type ===
                get(item, "promotions.promotion_type", "") &&
              item.subtotal * 1 > 0
            ) {
              totalQuantityA = totalQuantityA + item.qty * 1;
              totalAmtA = totalAmtA + item.subtotal * 1;
            }
          }
        }
        return "";
      });
      setThisAmt(totalAmtA);
      setThisQuantity(totalQuantityA);
      updateTotalQuantity(tempList1, totalQuantityA);
      setTempList(tempList1);
    } else {
      setTempList([]);
    }
  };

  const updateTotalQuantity = (tempList1, totalQuantityA) => {
    // this is for non promotions
    const notTempList = filter(products, function (oo) {
      return get(oo, "promotions") !== null;
    });
    const listTemp = filter(tempList1, function (oo) {
      return get(oo, "promotions") === null;
    });
    const totalQtySum = sumBy(listTemp, "qty");
    const totalQtyNonTempList = sumBy(notTempList, "qty") + totalQtySum;
    const resultTotal =
      itemPromotion === null
        ? totalQtyNonTempList
        : totalQtyNonTempList - totalQuantityA;
    setTotalQuantity(resultTotal);

    const notTempL = getSubtotal(notTempList);
    const notThisTempL = getSubtotal(listTemp);
    const newTotalAmount = sumBy(notTempL, "subtotal");
    const newThisAmount = sumBy(notThisTempL, "subtotal");
    const resultTotalAmount = newTotalAmount + newThisAmount * 1;
    setTotalAmt(resultTotalAmount);
  };

  const onSaveOrderTemp = () => {
    onSaveOrder(tempList, products, itemPromotion);
    setOpen(false);
  };

  const title = "resources.po.listgrid.label.normal_item_price";

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  const isShowFree = get(itemPromotion, "promotion_type", null);

  return (
    <SwipeableDrawer
      anchor={"left"}
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <Flex width={width} flexDirection={"column"}>
        <Flex
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={[2]}
        >
          <Text fontWeight={"bold"} ml={[2]}>
            {translate(title)}
          </Text>
          <Button
            onClick={toggleDrawer(false)}
            color='primary'
            style={{ textTransform: "capitalize", marginRight: 8 }}
            startIcon={<CancelIcon />}
          >
            {translate("resources.buttons.close")}
          </Button>
        </Flex>
        <DialogHeader
          translate={translate}
          item={itemPromotion}
          totalQuantity={totalQuantity}
          totalAmt={totalAmt}
          thisAmt={thisAmt}
          thisQuantity={thisQuantity}
          products={products}
          tempListBuy={tempListBuy}
          onSaveOrder={onSaveOrderTemp}
          isOAAsossiate={isOAAsossiate}
        />
        <Box width={[1]} px={[2]}>
          {itemPromotion === null && (
            <ArListGridNormalC
              listSelected={products}
              item={itemPromotion}
              promoType={isShowFree}
              translate={translate}
              tempList={tempList}
              promotions={props.promotions}
              setShow={(v) => setShow(v)}
              setMessageError={(m) => setMessageError(m)}
              setTempList={(temp) => updateTempList(temp)}
              isOAAsossiate={isOAAsossiate}
              countryId={countryId}
              dealerId={dealerId}
              country={country}
            />
          )}
        </Box>
        <ShowMessage
          open={show}
          setOpen={() => setShow(false)}
          message={messageError}
        />
      </Flex>
    </SwipeableDrawer>
  );
};

export default DialogListGridNormal;
