import Moment from "moment";
import { groupBy } from "lodash";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);

export const listColumnItem = [
  {
    field: "target_setting",
    title: "Target Setting",
    show: true,
    width: "200px",
  },
];

export const getListGL = (data, dealerId) => {
  const list = groupBy(data, function (o) {
    return [o.target_setting, o.dealer, o.country];
  });
  // console.log(list);
  const toArray = Object.values(list);
  const newlist = toArray.map((item) => {
    let newItems = {};
    const monthDate = item.map((io) => {
      const test = moment(io.month);
      newItems[`amount-${test.format("MMM-YY")}`] = `${io.amount}`;
      newItems[`amount-qty-${test.format("MMM-YY")}`] = `${io.qty}`;
      newItems[`amount_gap-${test.format("MMM-YY")}`] = `${io.amount_gap}`;
      newItems[`amount_gap-qty-${test.format("MMM-YY")}`] = `${io.qty_gap}`;
      // newItems[`qty-${test.format("MMM-YY")}`] = io.qty;
      // newItems[`qty_gap-${test.format("MMM-YY")}`] = io.qty_gap;
      if (dealerId && dealerId !== null) {
        newItems[`country`] = io.country;
        newItems[`dealer`] = io.dealer;
      } else {
        newItems[`country`] = io.country;
        newItems[`dealer`] = io.dealer;
      }

      newItems[`target_setting`] = io.target_setting;
      return {
        month: io.month,
        amount: io.amount,
        qty: io.qty,
        amount_gap: io.amount_gap,
        qty_gap: io.qty_gap,
      };
    });
    return { myMonth: monthDate, ...newItems };
  });
  return newlist;
};
