import React from "react"
import { Flex, Text } from "rebass"

import { Button } from "@material-ui/core"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import CancelIcon from "@material-ui/icons/Cancel"
import ListProducts from "./ListProducts"

const ItemsDrawer = (props) => {
  const { width, translate, open, setOpen } = props

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setOpen(open)
  }

  return (
    <SwipeableDrawer
      anchor={"right"}
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
    >
      <Flex width={width} flexDirection={"column"}>
        <Flex
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={[2]}
        >
          <Text fontWeight={"bold"} ml={[2]}>
            {translate("resources.po.add_product_to_order")}
          </Text>
          <Button
            onClick={toggleDrawer(false)}
            color="primary"
            style={{ textTransform: "capitalize", marginRight: 8 }}
            startIcon={<CancelIcon />}
          >
            {translate("resources.buttons.close")}
          </Button>
        </Flex>
        <ListProducts {...props} />
      </Flex>
    </SwipeableDrawer>
  )
}

export default ItemsDrawer
