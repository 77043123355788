import React from "react";
import { Edit } from "react-admin";
// useQueryWithStore, Loading
// import { get } from "lodash";
// import moment from "moment";
// import { Flex } from "rebass";
import { BetterSellOutForm, ActionForm } from "../components";
import { FormFields, FooterSelloutToolbar } from "./components";
// import { getUser } from "../utils/localeConfig";

// const payloadDefault = {
//   pagination: { page: 1, perPage: 1000 },
//   sort: { field: "packaging", order: "ASC" },
// };

//FooterSelloutToolbar
const EditSellout = (props) => {
  //   const user = getUser();
  // console.log(user);
  //   const dealerId = get(user, "dealer_id", -1);
  // const { loading, data } = useQueryWithStore({
  //   type: "getList",
  //   resource: "products",
  //   payload: payloadDefault,
  // });

  // if (loading) {
  //   return (
  //     <Flex flexDirection={"column"} width={[1]} alignItems={"center"}>
  //       <Loading />
  //     </Flex>
  //   );
  // }

  return (
    <Edit
      {...props}
      actions={<ActionForm title={"resources.sellout.title.edit_sellout"} />}
      redirect={"list"}
    >
      <BetterSellOutForm
        {...props}
        toolbar={<FooterSelloutToolbar isEdit={true} />}
      >
        {/* ListProduct={data} */}
        <FormFields {...props} isEdit={true} />
      </BetterSellOutForm>
    </Edit>
  );
};

export default EditSellout;
