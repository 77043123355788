export default {
  login: {
    titleGoogleButtinon: "Sign in with Google",
    userid: "User Id",
    username: "Username",
    password: "Kata sandi",
    error_password: "Kata sandi tidak boleh kosong",
    error_username: "Username tidak boleh kosong",
    error_login: "Silahkan coba lagi",
  },
}
