import React, { useState } from "react";
import { Flex } from "rebass";
import { withDataProvider } from "react-admin";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { get, find, findIndex } from "lodash";
import moment from "moment";
import { useBarcode } from "./utils";
const payloadDefault = {
  pagination: { page: 1, perPage: 25 },
  sort: { field: "id", order: "ASC" },
};
const ENTERKYCODE = 13;
const AddSellout = (props) => {
  const {
    translate,
    state,
    setGlobalCode,
    globalCode,
    setItemSelected,
    fields,
    dataProvider,
  } = props;
  const { barcode, setBarcode } = useBarcode();
  const [qty, setQty] = useState(get(state, "qty", 0));

  const resetBarcodeField = () => {
    setBarcode("");
  };
  const resetGlobalCodeField = () => {
    setGlobalCode("");
  };
  const onChangeBarcode = (e) => {
    setBarcode(e.target.value);
    // if (e.target.value !== "") {
    //   // console.log(e.target.value);
    //   const mystring = e.target.value;
    //   const gc = mystring.substr(0, mystring.indexOf("1/"));
    //   const resultGC = gc.substr(5, gc.length);
    //   const getExpiry = mystring.substr(mystring.indexOf("$$3"), 9);
    //   const resultExpiry = getExpiry.substr(3, getExpiry.length);
    //   // console.log(resultGC);
    //   const expiryDate = moment(resultExpiry, "YYMMDD");
    //   // console.log(moment(expiryDate).toISOString());
    //   setGlobalCode(resultGC);

    //   dataProvider
    //     .getList("products", {
    //       ...payloadDefault,
    //       filter: { global_code: resultGC },
    //     })
    //     .then((response) => {
    //       // console.log(response.data);
    //       // { id: 123, title: "hello, world" }
    //       if (response && response.data && response.data.length > 0) {
    //         const product = find(response.data, function (o) {
    //           return o.global_code === resultGC;
    //         });
    //         if (product && product.id) {
    //           setItemSelected({
    //             ...state,
    //             expire_date: moment(expiryDate).toISOString(),
    //             product_id: product.id,
    //             qty: 0,
    //             price: product.price * 1,
    //             subtotal: product.price * 1,
    //             global_code: resultGC,
    //           });
    //         }
    //       }
    //     })
    //     .catch((e) => {
    //       console.log(e.message);
    //     });
    //   // console.log(product);
    //   setBarcode(e.target.value);
    // }
  };

  const onChangeGlobalCode = (e) => {
    // console.log("globalCode ", e.target.value);
    setGlobalCode(e.target.value);
    if (e.target.value) {
      // console.log("globalCode ", e.target.value);

      dataProvider
        .getList("products", {
          ...payloadDefault,
          filter: { global_code: e.target.value },
        })
        .then((response) => {
          // console.log(response.data); // { id: 123, title: "hello, world" }
          if (response && response.data && response.data.length > 0) {
            const product = find(response.data, function (o) {
              return o.global_code === e.target.value;
            });
            if (product && product.id) {
              const mystring = barcode;
              const getExpiry = mystring.substr(mystring.indexOf("$$3"), 9);
              const resultExpiry = getExpiry.substr(3, getExpiry.length);
              // console.log(resultGC);
              const expiryDate = moment(resultExpiry, "YYMMDD");
              setItemSelected({
                ...state,
                expire_date: moment(expiryDate).toISOString(),
                product_id: product.id,
                price: product.price * 1,
              });
            }
          }
        })
        .catch((e) => {
          console.log(e.message);
        });

      // console.log(product);
    }
  };
  const onChangeQty = (e) => {
    setQty(e.target.value);
    setItemSelected({
      ...state,
      qty: e.target.value * 1,
    });
  };
  const onAddItemField = () => {
    const { value } = fields;
    // console.log("0", value);
    if (qty > 0 && state && state.product_id) {
      const productIndex = findIndex(value, function (o) {
        return o.product_id === state.product_id;
      });
      if (productIndex > -1) {
        fields.update(productIndex, {
          expire_date: state.expire_date,
          qty: qty,
          price: state.price,
          product_id: state.product_id,
          subtotal: qty * 1 * (state.price * 1),
        });
      } else {
        fields.push({
          expire_date: state.expire_date,
          qty: qty,
          price: state.price,
          product_id: state.product_id,
          subtotal: qty * 1 * (state.price * 1),
        });
      }

      setItemSelected({});
      setQty(0);
      setBarcode("");
      setGlobalCode("");
    }
  };
  const onKeypressBarcode = (e) => {
    let code = e.keyCode ? e.keyCode : e.which;
    // if (e.key === "Enter" ) {
    if (code === ENTERKYCODE && e.target.value !== "") {
      // console.log(e.target.value);
      const mystring = e.target.value;
      const gc = mystring.substr(0, mystring.indexOf("1/"));
      const resultGC = gc.substr(5, gc.length);
      const getExpiry = mystring.substr(mystring.indexOf("$$3"), 9);
      const resultExpiry = getExpiry.substr(3, getExpiry.length);
      // console.log(resultGC);
      const expiryDate = moment(resultExpiry, "YYMMDD");
      // console.log(moment(expiryDate).toISOString());
      setGlobalCode(resultGC);

      dataProvider
        .getList("products", {
          ...payloadDefault,
          filter: { global_code: resultGC },
        })
        .then((response) => {
          // console.log(response.data);
          // { id: 123, title: "hello, world" }
          if (response && response.data && response.data.length > 0) {
            const product = find(response.data, function (o) {
              return o.global_code === resultGC;
            });
            if (product && product.id) {
              setItemSelected({
                ...state,
                expire_date: moment(expiryDate).toISOString(),
                product_id: product.id,
                qty: 0,
                price: product.price * 1,
                subtotal: product.price * 1,
                global_code: resultGC,
              });
            }
          }
        })
        .catch((e) => {
          console.log(e.message);
        });
      // console.log(product);
    }
  };
  return (
    <Flex width={[1]} flexDirection={"column"} py={[3]}>
      <Flex
        width={[1]}
        flexDirection={"row"}
        flexWrap={"wrap"}
        sx={{ "&>div": { pr: [3], pb: [3] } }}
      >
        <Flex flexDirection={"row"}>
          <TextField
            id={"barcode"}
            value={barcode}
            label={translate("resources.sellout.fields.barcode")}
            style={{ minWidth: 400, marginRight: 16 }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => onChangeBarcode(e)}
            onKeyPress={(e) => onKeypressBarcode(e)}
            // onBlur={(v) => onBlurBarcode(v)}
          />
          <Button
            variant='contained'
            color='primary'
            size='small'
            value={globalCode}
            style={{
              borderRadius: 24,
              width: 100,
              paddingTop: 0,
              paddingBottom: 0,
              textTransform: "capitalize",
              height: 36,
            }}
            onClick={() => resetBarcodeField()}
          >
            {translate("resources.buttons.reset")}
          </Button>
        </Flex>
        <Flex flexDirection={"row"}>
          <TextField
            id={"global_code"}
            value={globalCode}
            label={translate("resources.sellout.fields.global_code")}
            style={{ minWidth: 256, marginRight: 16 }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => onChangeGlobalCode(e)}
          />
          <Button
            variant='contained'
            color='primary'
            size='small'
            style={{
              borderRadius: 24,
              width: 100,
              paddingTop: 0,
              paddingBottom: 0,
              textTransform: "capitalize",
              height: 36,
            }}
            onClick={() => resetGlobalCodeField()}
          >
            {translate("resources.buttons.reset")}
          </Button>
        </Flex>
        <Flex flexDirection={"row"}>
          <TextField
            id={"qty"}
            value={qty}
            label={translate("resources.sellout.fields.qty")}
            onChange={(e) => onChangeQty(e)}
            type={"number"}
            style={{ minWidth: 100, marginRight: 16 }}
            InputLabelProps={{ shrink: true }}
          />
          <Button
            variant='contained'
            color='primary'
            size='small'
            onClick={() => onAddItemField()}
            style={{
              borderRadius: 24,
              minWidth: 120,
              paddingTop: 0,
              paddingBottom: 0,
              textTransform: "capitalize",
              height: 36,
            }}
          >
            {translate("resources.buttons.add_to_list")}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default withDataProvider(AddSellout);
