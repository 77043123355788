import React from "react";
import { Text } from "rebass";
import { filter, get, isArray } from "lodash";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useQueryWithStore } from "react-admin";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const MainField = (props) => {
  const classes = useStyles();
  const { country, optionsText } = props;
  const { loaded, data } = useQueryWithStore({
    type: "getMany",
    resource: "countries",
    payload: { ids: country },
  });
  if (!loaded) {
    return (
      <div className={classes.root}>
        <LinearProgress variant='query' />
      </div>
    );
  }
  //   console.log(country, data);
  if (data && data.length > 0) {
    const list = filter(data, function (o) {
      return country.indexOf(o.id) >= 0;
    });
    return list && list.length > 0 ? (
      list.map((item, index) => {
        return <Chip label={item[optionsText]} key={index} />;
      })
    ) : (
      <span />
    );
  }

  return <span />;
};

const SingleField = (props) => {
  const classes = useStyles();

  const { record, source, optionsText } = props;
  const { loaded, data } = useQueryWithStore({
    type: "getOne",
    resource: "countries",
    payload: { id: record[source] ? record[source] : 0 },
  });
  if (!loaded) {
    return (
      <div className={classes.root}>
        <LinearProgress variant='query' />
      </div>
    );
  }

  return <Text>{get(data, optionsText, "")}</Text>;
};

export const SingleCountryField = ({ record, source, optionsText }) => {
  const { loaded, data } = useQueryWithStore({
    type: "getMany",
    resource: "dealers",
    payload: {
      ids: record.dealer && record.dealer !== null ? record.dealer : [],
    },
  });
  if (!loaded) {
    return (
      <div>
        <LinearProgress variant='query' />
      </div>
    );
  }
  if (data && data.length > 0) {
    const list = filter(data, function (o) {
      return record[source].indexOf(o.id) >= 0;
    });
    return list && list.length > 0 ? (
      list.map((item, index) => {
        return <Chip label={item[optionsText]} key={index} />;
      })
    ) : (
      <span />
    );
  }

  return <span />;
};

const CountryField = (props) => {
  const { record, source } = props;
  const country = get(record, source);
  if (isArray(country)) {
    return <MainField {...props} country={country} />;
  }

  return <SingleField {...props} />;
};

export default CountryField;
