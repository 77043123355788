import React from "react";
import { useQuery } from "../../../components/hooks";
import { getListBySource, getListBySourceLY, renderLegend } from "./Container";
import { groupBy, get, map, find } from "lodash";
import { Flex, Box } from "rebass";
import Moment from "moment";
import { extendMoment } from "moment-range";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { colorLine } from "../../utils";
import { applyFilter } from "./utils";
const moment = extendMoment(Moment);
// const baseUrl = "/reports/purchaseOrder/chart";
const ChartContainer = (props) => {
  const { myfilter, tabTitle, baseUrl } = props;
  // const [state, setState] = useState({
  //   month: getMonthList("MMM"),
  //   listChart: [],
  // });
  const { data, loading } = useQuery(baseUrl);
  // useEffect(() => {
  //   function setData() {
  //     if (data && data.length > 0) {
  //       const newFilter = applyFilter(data, myfilter);
  //       const listResponse = groupBy(newFilter, function (item) {
  //         return moment(item.month).format("MMM YYYY");
  //       });
  //       setState({
  //         ...state,
  //         listChart: getListBySource(listResponse),
  //       });
  //     }
  //   }
  //   setData();
  // }, [data]);

  if (loading) {
    return <div />;
  }
  const newFilter = applyFilter(data, myfilter);
  const listResponse = groupBy(newFilter, function (item) {
    return moment(item.month).format("MMM YYYY");
  });
  const getPoTarget = getListBySource(listResponse, tabTitle);
  const getLY = getListBySourceLY(listResponse, tabTitle);

  const newList = map(getPoTarget, function (o) {
    const item = find(getLY, function (i) {
      return i.name === o.name;
    });
    return {
      ...o,
      LY: get(item, "LY", 0),
    };
  });

  return (
    <Box width={[1]} py={[3]}>
      <Flex
        width={[1]}
        pt={[4]}
        flexDirection={"column"}
        justifyContent={"center"}
      >
        <LineChart
          width={get(props, "width", 800) - 300}
          height={500}
          data={newList}
          margin={{
            top: 15,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' />
          <YAxis />
          <Tooltip />
          <Legend content={renderLegend} />
          <Line
            type='monotone'
            dataKey='Target'
            stroke={colorLine[3]}
            activeDot={{ r: 8 }}
          />
          <Line
            type='monotone'
            dataKey={tabTitle && tabTitle !== "" ? tabTitle : "PO"}
            stroke={colorLine[1]}
          />
          <Line type='monotone' dataKey='LY' stroke={colorLine[4]} />
        </LineChart>
      </Flex>
    </Box>
  );
};

export default ChartContainer;
