import React from "react";
import { useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
// import Button from "@material-ui/core/Button";
// import Dialog from "@material-ui/core/Dialog";
// import Divider from "@material-ui/core/Divider";
// import AppBar from "@material-ui/core/AppBar";
// import Toolbar from "@material-ui/core/Toolbar";
// import IconButton from "@material-ui/core/IconButton";
// import Typography from "@material-ui/core/Typography";
// import CloseIcon from "@material-ui/icons/Close";
// import Slide from "@material-ui/core/Slide";
// import { Box, Text } from "rebass";
// import { get } from "lodash";
import RemarksView from "./RemarksView";
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    paddingLeft: 24,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction='up' ref={ref} {...props} />;
// });

const DialogShowRemarks = (props) => {
  // const { item } = props;
  const translate = useTranslate();
  const classes = useStyles();
  // const [open, setOpen] = useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // console.log("item", item);

  return (
    <div>
      <RemarksView {...props} translate={translate} classes={classes} />
      {/* <Box>
        <Button
          onClick={handleClickOpen}
          color='primary'
          style={{ textTransform: "capitalize" }}
        >
          {translate("resources.buttons.showremarks")}
        </Button>
      </Box>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={handleClose}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
            <Typography variant='h6' className={classes.title}>
              {translate("resources.po.fields.remarks")}
            </Typography>
            
          </Toolbar>
        </AppBar>
        <Divider style={{ marginTop: 2 }} />
        <Box width={[1]} p={[2]}>
          <Text fontSize={[1]}>
            {get(item, "remarks", "") !== null ? get(item, "remarks", "") : ""}
          </Text>
        </Box>
      </Dialog> */}
    </div>
  );
};

export default DialogShowRemarks;
