import React, { useState, Fragment } from "react";
import { useQuery } from "../../../components";
import { Box, Flex } from "rebass";
import { Loading, downloadCSV, useNotify } from "react-admin";
import { get, map, filter, orderBy } from "lodash";
import moment from "moment";
import jsonExport from "jsonexport/dist";
import { HeaderFilters } from "./po";
import { ListFilters } from "./inventory_fields";

import { Button } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { DialogUploadInventory } from "../../../inventory";
import DialogListInventory from "./DialogListInventory";
// import { ExportCSVFile } from "./utils";
const MainFields = (props) => {
  const { dataList, translate, dealerId, ...rest } = props;
  const [list, setList] = useState(dataList);
  const [valueCountry, setValueCountry] = useState([]);
  const [valueDealer, setValueDealer] = useState([]);
  const [myfilter, setMyFilter] = useState({
    country: [],
    dealer: [],
    category: [],
    target_setting: [],
  });
  const [valueCategory, setValueCategory] = useState([]);
  const [valueTargetSetting, setValueTargetSetting] = useState([]);
  const [open, setOpen] = useState(false);
  const [listUpload, setListUpload] = useState([]);
  const notify = useNotify();
  const onSubmitUpload = (v) => {
    setListUpload(v);
    setOpen(true);
  };

  const onExportCsv = () => {
    if (list && list.length > 0) {
      const newList = map(list, function (o) {
        return {
          country: get(o, "country", ""),
          dealer: get(o, "dealer", ""),
          category: get(o, "category", ""),
          target_setting: get(o, "target_setting", ""),
          packaging: get(o, "packaging", ""),
          code: get(o, "code", ""),
          in_transit: get(o, "in_transit", 0),
          open_order: get(o, "open_order", 0),
          available: get(o, "available", 0),
          back_order: get(o, "back_order", 0),
        };
      });
      const newSortList = orderBy(
        newList,
        [(product) => product.packaging.toLowerCase()],
        ["asc"]
      );

      jsonExport(
        newSortList,
        {
          headers: [
            "country",
            "dealer",
            "category",
            "target_setting",
            "packaging",
            "code",
            "in_transit",
            "open_order",
            "available",
            "back_order",
          ],
        },
        (err, csv) => {
          if (err) return console.error(err);
          downloadCSV(
            csv,
            `inventory-${moment().format("DD-MMM-YYYY-HH:mm:ss")}`
          );
        }
      );
    }
  };
  // console.log("default", list);
  const sortList = orderBy(
    list,
    [(product) => product.packaging.toLowerCase()],
    ["asc"]
  );
  // console.log("sortby", sortList);

  return (
    <Fragment>
      <Flex width={[1]} flexDirection={"column"}>
        <HeaderFilters
          {...props}
          setList={setList}
          valueCategory={valueCategory}
          valueCountry={valueCountry}
          valueDealer={valueDealer}
          myfilter={myfilter}
          setValueCategory={setValueCategory}
          setValueCountry={setValueCountry}
          setValueDealer={setValueDealer}
          setMyFilter={setMyFilter}
          valueTargetSetting={valueTargetSetting}
          setValueTargetSetting={setValueTargetSetting}
          idHeaderDealer='inventory-fields-filter'
        />
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"flex-end"}
          pb={[2]}
        >
          <DialogUploadInventory
            translate={translate}
            onSubmitUpload={(v) => onSubmitUpload(v)}
            notify={notify}
            {...rest}
          />
          {/* <ExportCSVFile
          translate={translate}
          list={sortList}
          onExportCsv={onExportCsv}
        /> */}
          <Button
            variant='contained'
            color='primary'
            disabled={sortList.length === 0}
            startIcon={<CloudDownloadIcon />}
            style={{ textTransform: "capitalize" }}
            onClick={onExportCsv}
          >
            {translate("resources.buttons.export_csv")}
          </Button>
        </Flex>

        <Flex width={[1]} flexDirection={"column"}>
          {sortList && <ListFilters list={sortList} dealerId={dealerId} />}
        </Flex>
      </Flex>
      {open && (
        <DialogListInventory
          open={open}
          setOpen={setOpen}
          list={listUpload}
          translate={translate}
        />
      )}
    </Fragment>
  );
};

export default (props) => {
  // const { width } = useWindowSize();
  const { dealerId, country } = props;
  const paramDealerId = dealerId !== null ? `?dealer_id=${dealerId}` : "";
  const { loaded, data } = useQuery(`/reports/inventory${paramDealerId}`);

  if (!loaded) {
    return (
      <Box width={[1]}>
        <Loading />
      </Box>
    );
  }
  const dataList =
    data && data.length > 0
      ? Array.from(
          new Set(
            data.map((p, index) => {
              return { ...p, id: index };
            })
          )
        )
      : [];
  let myDataList = dataList;
  if (country && country.id) {
    myDataList = filter(dataList, function (o) {
      return o.country === country.name;
    });
  }

  return (
    <Flex width={[1]} flexDirection={"column"}>
      <MainFields dataList={myDataList} {...props} />
      {/* <Box width={[1]} py={[3]}>
        <Container baseUrl={`/reports/inventory/chart`} width={width} />
      </Box> */}
    </Flex>
  );
};
