import React, { useState, Fragment } from "react";
// useEffect
import { Flex, Box, Text } from "rebass";
import { get, union, find, filter, map } from "lodash";
import moment from "moment";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import {
  FormDataConsumer,
  useTranslate,
  useQueryWithStore,
  useNotify,
  Confirm,
} from "react-admin";
import { useWindowSize, Loading } from "../../../components";
//useWindowSize,
// import ListPromotions from "../grid/ListPromotions";
import TargetSummary from "./TargetSummary";
import ListOrders from "./ListOrders";
import DetailFields from "./DetailFields";
import { PromoType_B } from "./edit";
import DialogListGrid from "./DialogListGrid";
import DialogListGridBuyXGetY from "./DialogListGridBuyXGetY";
import DialogListGridNormal from "./DialogListGridNormal";
import DialogListGridBuyABGETCD from "./DialogListGridBuyABGETCD";
import CountryRemarks from "./CountryRemarks";
import { showOAAssociate } from "../../../utils";

const startDate = moment().utcOffset(0);
// const endDate = moment().utcOffset(0);

startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
// endDate.set({ hour: 23, minute: 59, second: 0, millisecond: 0 });

const MainFields = (props) => {
  const { permissions, countryId } = props;
  const isOAAsossiate = showOAAssociate(permissions);
  const windowSize = useWindowSize();
  const translate = useTranslate();
  const notify = useNotify();
  const [visible, setVisible] = useState({
    open: false,
    item: null,
    products: [],
  });
  const [state, setState] = useState({
    promotions: null,
    itempromo: null,
    itempromoDiscount: null,
    itemSp: null,
    itemABCD: null,
    open: false,
    openNormal: false,
    openDiscount: false,
    openSp: false,
    openABCD: false,
    openbuyXgetY: false,
    totalQty: 0,
    totalAmount: 0,
  });
  const responseCountry = useQueryWithStore({
    type: "getOne",
    resource: "countries",
    payload: { id: countryId },
  });

  const { loading, data } = useQueryWithStore({
    type: "getList",
    resource: "promotions",
    payload: {
      pagination: { page: 1, perPage: 200 },
      sort: { field: "id", order: "ASC" },
      filter: {
        active: true,
        status: "Approved",
        date: startDate.toISOString(),
        // end_date: endDate.toISOString(),
      },
    },
  });

  const onSelectItem = (item) => {
    console.log("on select item 2");
    // const current = moment();
    //gcassosiacte can edit no need check expired date
    if (item && item.id) {
      setState({
        ...state,
        open: item.promotion_type !== PromoType_B[0],
        openbuyXgetY:
          item.promotion_type !== null &&
          item.promotion_type === PromoType_B[0],
        promotions: item,
        openNormal: item.promotion_type === null,
        openABCD:
          item.promotion_type !== null &&
          item.promotion_type === PromoType_B[1],
      });
      // const isEndDate = moment(get(item, "end_date", "")).isSameOrAfter(
      //   current,
      //   "day"
      // );
      // if (isEndDate) {
      //reset for free list
      // setTempListBuy([]);
      // setTempListFree([]);

      // } else {
      //   notify("resources.po.messages.expired_promotions", "warning");
      //   setState({
      //     ...state,
      //     open: false,
      //     openbuyXgetY: false,
      //     promotions: null,
      //   });
      // }
    }
  };
  const onCloseDialog = () => {
    setState({
      ...state,
      // [key]: false,
      open: false,
      openbuyXgetY: false,
      openNormal: false,
      openABCD: false,
    });
  };
  const onAddItem = () => {
    //for non promotions
    setState({
      ...state,
      open: false,
      openbuyXgetY: false,
      openNormal: true,
      openABCD: false,
      promotions: null,
    });
  };

  const onSetTotal = (qty, amount) => {
    setState({
      ...state,
      totalQty: qty,
      totalAmount: amount,
    });
  };

  const onCheckExpiredDate = (newProducts, products) => {
    //gc not checking expireddate
    if (newProducts.length > 0 && products.length > 0) {
      if (newProducts.length !== products.length) {
        // props.updateField("items", newProducts);
        // notify("resources.po.messages.expired_promotions", "warning");
      }
    }
  };

  const onSaveOrder = (list, products, itemPromotion) => {
    if (itemPromotion !== null) {
      if (list && list.length > 0) {
        let newArray = [];
        products.map((item) => {
          let myChild = item;
          // console.log(itemPromotion);
          if (
            itemPromotion.id === get(item, "promotions.id") &&
            itemPromotion.promotion_type ===
              get(item, "promotions.promotion_type")
          ) {
            const myRes = find(list, function (o) {
              return (
                item.product_id === o.product_id &&
                item.promotions.promotion_type ===
                  o.promotions.promotion_type &&
                item.promotions.id === o.promotions.id &&
                (o.id === undefined || (o.id && o.id === item.id))
              );
            });
            if (myRes && myRes.product_id) {
              // myChild = myRes;
              newArray.push(myRes);
            }
          } else {
            newArray.push(myChild);
          }

          // console.log("myRes", myRes);
          // if (myRes && myRes.product_id) {
          //   // myChild = myRes;
          //   newArray.push(myRes);
          // } else {
          //   if (item.promotions === null) {
          //     newArray.push(myChild);
          //   }
          // }

          return "";
        });

        const newChild = filter(list, function (oi) {
          return !oi.id;
        });
        const newlistChild = union(newArray, newChild);
        const myArray = filter(newlistChild, function (ooi) {
          return ooi.qty > 0;
        });
        if (isOAAsossiate) {
          // console.log("newlistChild", newlistChild);
          const myNewItems = updatePriceDiscounted(myArray);
          props.updateField("items", myNewItems);
        } else {
          const newCheckQty = filter(myArray, function (oii) {
            if (
              get(oii, "promotions.promotion_type", "") === PromoType_B[0] ||
              get(oii, "promotions.promotion_type", "") === PromoType_B[1]
            ) {
              return true;
            }
            return !oii.foc && oii.qty > 0;
          });
          const myNewItems = updatePriceDiscounted(newCheckQty);
          props.updateField("items", myNewItems);
        }
      } else {
        const newList = filter(products, function (item) {
          // return (
          //   itemPromotion.id !== get(item, "promotions.id") &&
          //   itemPromotion.promotion_type !==
          //     get(item, "promotions.promotion_type")
          // );
          const isPush =
            get(item, "promotions.promotion_type") ===
              itemPromotion.promotion_type &&
            get(item, "promotions.id") === itemPromotion.id;
          return !isPush;
        });
        const myNewItems = updatePriceDiscounted(newList);
        props.updateField("items", myNewItems);
      }
    } else {
      if (list && list.length > 0) {
        let newArray = [];
        products.map((item) => {
          let myChild = item;
          const myRes = find(list, function (o) {
            return item.product_id === o.product_id && item.promotions === null;
          });
          if (myRes && myRes.product_id) {
            myChild = myRes;
          }
          if (myChild.promotions === null) {
            if (myChild.qty && myChild.qty > 0) {
              newArray.push(myChild);
            }
          } else {
            newArray.push(myChild);
          }

          return "";
        });

        const newChild = filter(list, function (oi) {
          return !oi.id;
        });

        const newlistChild = union(newArray, newChild);
        const myArray = filter(newlistChild, function (ooi) {
          return ooi.qty > 0;
        });
        const newCheckQty = filter(myArray, function (oii) {
          if (
            get(oii, "promotions.promotion_type", "") === PromoType_B[0] ||
            get(oii, "promotions.promotion_type", "") === PromoType_B[1]
          ) {
            return true;
          }
          return !oii.foc && oii.qty > 0;
        });
        const myNewItems = updatePriceDiscounted(newCheckQty);
        props.updateField("items", myNewItems);
      }
    }
  };
  const updatePriceDiscounted = (items) => {
    const newList = map(items, function (item) {
      const { unit_price, price, ...rest } = item;
      return { ...rest, price: unit_price ? unit_price : price };
    });

    return newList;
  };

  const onRemoveItem = (item, products) => {
    if (item && item.id) {
      setVisible({
        open: true,
        item: item,
        products: products,
      });
    } else {
      notify("resources.buttons.message.can_not_remove", "warning");
    }
  };
  const onConfirmRemove = () => {
    const { item, products } = visible;
    const newFilter = filter(products, function (io) {
      if (io.promotions === null) {
        return true;
      }
      return (
        get(io, "promotions.id") !== item.id &&
        get(io, "promotions.promotion_type") !== item.promotion_type
      );
    });

    if (newFilter !== null) {
      props.updateField("items", newFilter);
    }
    setVisible({
      open: false,
      item: null,
      products: [],
    });
  };
  const handleDialogClose = () => {
    setVisible({
      open: false,
      item: null,
      products: [],
    });
  };
  if (loading) {
    return (
      <Flex
        width={[1]}
        justifyContent='center'
        alignItems='center'
        sx={{ height: "80vh" }}
      >
        <Loading />
      </Flex>
    );
  }

  const countryData = responseCountry.data ? responseCountry.data : {};
  return (
    <Fragment>
      <Flex width={[1]} flexDirection={"column"}>
        <CountryRemarks {...props} />
        {/* <ListPromotions onSelectItem={onSelectItem} promotions={data} /> */}
        <FormDataConsumer>
          {({ formData }) => (
            <Flex width={[1]} flexDirection={"column"}>
              <Box width={[1]} py={[3]}>
                <Paper elevation={3}>
                  <Box width={[1]} style={{ minHeight: 300 }}>
                    <Box width={[1]} p={[3]}>
                      <Text fontSize={[2]} fontWeight={"bold"}>
                        {translate("resources.po.fields.order_items")}
                      </Text>
                    </Box>
                    <Flex
                      width={[1]}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      alignItems={"flex-start"}
                      px={[3]}
                      py={[2]}
                    >
                      <Button
                        variant='contained'
                        color='primary'
                        style={{
                          borderRadius: 24,
                          textTransform: "capitalize",
                          paddingLeft: 32,
                          paddingRight: 32,
                        }}
                        onClick={onAddItem}
                      >
                        {translate("resources.buttons.add_normal_price")}
                      </Button>
                    </Flex>
                    {/* <HeaderOrdersItem
                    translate={translate}
                    totalQty={state.totalQty}
                    totalAmount={state.totalAmount}
                    onAddItem={onAddItem}
                  /> */}
                    <ListOrders
                      formData={formData}
                      updateField={props.updateField}
                      translate={translate}
                      onSelectItem={onSelectItem}
                      onAddItem={onAddItem}
                      onSetTotal={(qty, amount) => onSetTotal(qty, amount)}
                      totalQty={state.totalQty}
                      totalAmount={state.totalAmount}
                      onCheckExpiredDate={(newProducts, products) =>
                        onCheckExpiredDate(newProducts, products)
                      }
                      onRemoveItem={(item, products) =>
                        onRemoveItem(item, products)
                      }
                      isOAAsossiate={isOAAsossiate}
                      country={countryData}
                      typePostUrl={props.typePostUrl}
                    />
                    <Box width={[1]} py={[3]} px={[1]}>
                      <TargetSummary
                        formData={formData}
                        translate={translate}
                      />
                    </Box>
                  </Box>
                </Paper>
              </Box>
              {state.open && (
                <DialogListGrid
                  formData={formData}
                  itemPromotion={state.promotions}
                  width={windowSize.width > 10 ? windowSize.width - 50 : 600}
                  translate={translate}
                  open={state.open}
                  totalQty={state.totalQty}
                  totalAmount={state.totalAmount}
                  setOpen={onCloseDialog}
                  onSaveOrder={onSaveOrder}
                  promotions={data}
                  isOAAsossiate={isOAAsossiate}
                  countryId={countryId}
                  country={countryData}
                />
              )}
              {state.openbuyXgetY && (
                <DialogListGridBuyXGetY
                  formData={formData}
                  itemPromotion={state.promotions}
                  width={windowSize.width > 10 ? windowSize.width - 50 : 600}
                  translate={translate}
                  open={state.openbuyXgetY}
                  totalQty={state.totalQty}
                  totalAmount={state.totalAmount}
                  setOpen={onCloseDialog}
                  onSaveOrder={onSaveOrder}
                  promotions={data}
                  isOAAsossiate={isOAAsossiate}
                  countryId={countryId}
                  country={countryData}
                />
              )}
              {state.openNormal && (
                <DialogListGridNormal
                  formData={formData}
                  itemPromotion={state.promotions}
                  width={windowSize.width > 10 ? windowSize.width - 50 : 600}
                  translate={translate}
                  open={state.openNormal}
                  totalQty={state.totalQty}
                  totalAmount={state.totalAmount}
                  setOpen={onCloseDialog}
                  onSaveOrder={onSaveOrder}
                  promotions={data}
                  isOAAsossiate={isOAAsossiate}
                  countryId={countryId}
                  country={countryData}
                />
              )}
              {state.openABCD && (
                <DialogListGridBuyABGETCD
                  formData={formData}
                  itemPromotion={state.promotions}
                  width={windowSize.width > 10 ? windowSize.width - 50 : 600}
                  translate={translate}
                  open={state.openABCD}
                  totalQty={state.totalQty}
                  totalAmount={state.totalAmount}
                  setOpen={onCloseDialog}
                  onSaveOrder={onSaveOrder}
                  promotions={data}
                  isOAAsossiate={isOAAsossiate}
                  countryId={countryId}
                  country={countryData}
                />
              )}
            </Flex>
          )}
        </FormDataConsumer>
        <DetailFields {...props} />
      </Flex>
      <Confirm
        isOpen={visible.open}
        title='resources.buttons.message.remove_promotion_title'
        content='resources.buttons.message.remove_promotion'
        onConfirm={onConfirmRemove}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

const EditFormFields = (props) => {
  const { record } = props;
  const dealerId = get(record, "dealer_id");
  const { data, loading } = useQueryWithStore({
    type: "getOne",
    resource: "dealers",
    payload: { id: dealerId },
  });
  if (loading) {
    return (
      <Flex
        width={[1]}
        justifyContent='center'
        alignItems='center'
        sx={{ height: "80vh" }}
      >
        <Loading />
      </Flex>
    );
  }
  return <MainFields {...props} countryId={get(data, "country_id")} />;
};
export default EditFormFields;
