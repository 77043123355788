import React, { Fragment, useState, useCallback } from "react"
import PropTypes from "prop-types"
import ActionDelete from "@material-ui/icons/Delete"
import { useLocation } from "react-router-dom"
import { get } from "lodash"
import inflection from "inflection"
import {
  useTranslate,
  useDelete,
  useRefresh,
  useNotify,
  useRedirect,
  CRUD_DELETE,
  Confirm,
} from "react-admin"
import ButtonIconOnly from "./ButtonIconOnly"
import { useStylesDelete, sanitizeRestPropsDelete } from "./utils"

const DeleteButton = ({
  basePath,
  classes: classesOverride,
  className,
  icon,
  label = "ra.action.delete",
  onClick,
  record,
  resource,
  isCustomLink = false,
  taburl = "",
  isNotRedirect = false,
  redirect: redirectTo,
  ...rest
}) => {
  const [open, setOpen] = useState(false)
  const translate = useTranslate()
  const notify = useNotify()
  const redirect = useRedirect()
  const refresh = useRefresh()
  const myLocation = useLocation()
  const urlPathName = get(myLocation, "pathname", "")

  const [deleteOne, { loading }] = useDelete(
    resource,
    record && record.id,
    record,
    {
      action: CRUD_DELETE,
      onSuccess: () => {
        notify("ra.notification.deleted", "warning", {
          smart_count: 1,
        })
        if (!isNotRedirect) {
          redirect(redirectTo, urlPathName)
        }
        refresh()
      },
      onFailure: error =>
        notify(
          typeof error === "string"
            ? error
            : error.message || "ra.notification.http_error",
          "warning"
        ),
      undoable: false,
    }
  )

  const handleClick = e => {
    setOpen(true)
    e.stopPropagation()
  }

  const handleDialogClose = e => {
    setOpen(false)
    e.stopPropagation()
  }

  const classes = useStylesDelete({ classes: classesOverride })

  const handleDelete = useCallback(() => {
    deleteOne()
    if (typeof onClick === "function") {
      onClick()
    }
  }, [deleteOne, onClick])

  return (
    <Fragment>
      <ButtonIconOnly
        onClick={handleClick}
        key="button"
        {...sanitizeRestPropsDelete(rest)}
      >
        {React.cloneElement(icon, {
          className: classes[`smallIcon`],
        })}
      </ButtonIconOnly>
      <Confirm
        isOpen={open}
        loading={loading}
        title="ra.message.delete_title"
        content="ra.message.delete_content"
        translateOptions={{
          name: inflection.humanize(
            translate(`resources.${resource}.name`, {
              smart_count: 1,
              _: inflection.singularize(resource),
            }),
            true
          ),
          id: record.id,
        }}
        onConfirm={handleDelete}
        onClose={handleDialogClose}
      />
    </Fragment>
  )
}

DeleteButton.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  resource: PropTypes.string,
  icon: PropTypes.element,
}

DeleteButton.defaultProps = {
  redirect: "list",
  icon: <ActionDelete />,
}

export default DeleteButton
