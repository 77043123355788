//ProductShortCodeInput
import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { get, find, isString, orderBy } from "lodash";
import { useTranslate } from "react-admin";
import { Box } from "rebass";
export default (props) => {
  const translate = useTranslate();
  const { list, record, source, updateField, label, optionValue } = props;
  // console.log(get(record, source, ""), optionValue);
  let newOptionValue = optionValue;
  if (
    (optionValue === "short_code" || optionValue === "code") &&
    !isString(get(record, source, ""))
  ) {
    newOptionValue = "id";
  }
  const myValue1 = find(list, function (oo) {
    return oo[newOptionValue] === get(record, source, "");
  });
  const myValue2 = myValue1 ? myValue1 : {};
  const [value, setValue] = useState(myValue2);

  const onChangeValue = (newValue) => {
    if (newValue !== null) {
      updateField(source, newValue[optionValue]);
    } else {
      updateField(source, "");
    }
    setValue(newValue);
  };
  useEffect(() => {
    function handleStatusChange(status) {
      setValue(status);
    }
    if (myValue2 && myValue2.id) {
      handleStatusChange(myValue2);
    }
  });
  // const sortList = sortBy(list, [
  //   function (o) {
  //     return o.packaging;
  //   },
  // ]);

  const sortList = orderBy(
    list,
    [(product) => product.packaging.toLowerCase()],
    ["asc"]
  );

  return (
    <Box pl={[2]}>
      <Autocomplete
        options={sortList}
        id={`controlled-demo-${source}-${optionValue}`}
        value={value}
        onChange={(event, newValue) => {
          onChangeValue(newValue);
        }}
        getOptionLabel={(option) => {
          return get(option, "packaging", "");
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={translate(label)}
            margin='normal'
            style={{ width: 400 }}
          />
        )}
      />
    </Box>
  );
};
