import moment from "moment";
export const dataYear = [
  { id: "2020", name: "2020/2021" },
  { id: "2021", name: "2021/2022" },
  { id: "2022", name: "2022/2023" },
  { id: "2023", name: "2023/2024" },
];

export const getCurrentYear = () => {
  const currentMonth = moment().month();
  //||currentMonth === 3
  if (currentMonth === 0 || currentMonth === 1 || currentMonth === 2) {
    const currentYear = moment().add(-1, "year").format("YYYY");
    if (currentYear * 1 < 2020) {
      return 2020;
    } else {
      return currentYear;
    }
  }
  return moment().year();
};

export const getYearList = () => {
  let data = [];
  const prevYear = moment().add(-1, "year").format("YYYY");
  const firstYear = prevYear * 1 < 2020 ? 2020 : prevYear * 1;
  for (let index = 0; index <= 3; index++) {
    const currenctYear = firstYear + index;
    data.push({
      id: firstYear + index,
      name: `${currenctYear}/${currenctYear + 1}`,
    });
  }
  return data;
};

export const getFY = (fy) => {
  const twodigitYear = moment().year(fy).format("YY");
  const currentYear = `${twodigitYear}/${twodigitYear * 1 + 1}`;
  return currentYear;
};
