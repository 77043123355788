import React from "react";
import { Flex } from "rebass";
import { get } from "lodash";
import { TextField, ArrayField } from "react-admin";
import { useStylesTheme } from "./styles";
import { withStyles, createStyles } from "@material-ui/core/styles";
import CardTable from "./CardTable";
import { ThemeProvider } from "@material-ui/styles";
import { themeTableInput } from "../../../themes";
import { TextRecord, ReferenceTextRecord, Datagrid } from "../../../components";

const mystyles = (theme) =>
  createStyles({
    root: {
      width: "100%",
    },
  });

const ViewPromotionDiscount = (props) => {
  const { formData, translate, classes, dataProducts, ...rest } = props;
  const { record } = rest;
  const myClass = useStylesTheme();
  const type_buy = get(record, "criteria.discount.buy.type", "");

  return (
    <Flex
      width={[1]}
      flexDirection={"column"}
      sx={{
        "&>div": {
          pb: [3],
        },
      }}
    >
      <CardTable {...props} title={"resources.promotions.fields.buy"}>
        <Flex flexDirection='row' width={[1]} justifyContent='flex-start'>
          <TextRecord
            {...rest}
            source='criteria.discount.buy.type'
            label={"resources.promotions.labels.criteria_type"}
          />
          {(type_buy === "ProductGroup" || type_buy === "SubProductGroup") && (
            <ReferenceTextRecord
              {...rest}
              label={"resources.promotions.labels.criteria"}
              source='criteria.discount.buy.id'
              reference='codes'
              filter={{ type: type_buy, active: true }}
              optionText='label'
            />
          )}
          {(type_buy === "ProductCode" || type_buy === "ProductShortCode") && (
            <ReferenceTextRecord
              {...rest}
              label={"resources.promotions.labels.criteria"}
              source='criteria.discount.buy.id'
              reference='products'
              optionText='packaging'
            />
          )}
        </Flex>
      </CardTable>

      <ThemeProvider theme={themeTableInput}>
        <Flex width={[1]} flexDirection={"column"}>
          <ArrayField source='criteria.discount.moqs' {...rest} label={""}>
            <Datagrid style={{ width: "100%" }} classes={myClass}>
              <TextField
                source='moq'
                style={{ width: 100, margin: 0 }}
                label={"resources.promotions.fields.moq"}
              />
              <TextField
                source='discount'
                style={{ width: 100, margin: 0 }}
                label={"resources.promotions.fields.discount"}
              />
            </Datagrid>
          </ArrayField>
        </Flex>
      </ThemeProvider>
    </Flex>
  );
};

export default withStyles(mystyles)(ViewPromotionDiscount);
