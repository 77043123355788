import React from "react";
import { Flex, Text, Box } from "rebass";
import { get } from "lodash";
//, filter, uniqBy
import NumberFormat from "react-number-format";
import Button from "@material-ui/core/Button";
import { getTotalQtyFreeProduct, PromoType_B } from "./utils";
import { getTotalFree } from "../../../../components/argrid/utilsABCD";

export const LabelField = (props) => <Text fontSize={[0]} {...props} />;

const HeaderTotal = (props) => {
  const {
    translate,
    styles = {},
    isBold = "normal",
    widthLabel = 160,
    widthValue = "80px",
  } = props;
  return (
    <Flex
      width={[1]}
      flexDirection={"row"}
      p={[3]}
      justifyContent={"space-between"}
      style={styles}
    >
      <Flex
        width={[1]}
        pr={[2]}
        flexDirection={"row"}
        justifyContent={"flex-start"}
      >
        <LabelField fontWeight={isBold} style={{ width: widthLabel }}>
          {translate("resources.po.listgrid.total_order_qty")}
        </LabelField>
        <LabelField textAlign={"right"} width={widthValue}>
          {get(props, "totalQuantity", 0) + get(props, "thisQuantity", 0)}
        </LabelField>
      </Flex>
      <Flex
        width={[1]}
        flexDirection={"row"}
        pl={[2]}
        justifyContent={"flex-start"}
      >
        <LabelField fontWeight={isBold} style={{ width: widthLabel }}>
          {translate("resources.po.listgrid.total_order_amt")}
        </LabelField>
        <LabelField textAlign={"right"} width={widthValue}>
          <NumberFormat
            value={get(props, "totalAmt", 0) + get(props, "thisAmt", 0)}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </LabelField>
      </Flex>
    </Flex>
  );
};

const HeaderThis = (props) => {
  const { thisQuantity, thisAmt, translate, widthValue = "80px" } = props;
  return (
    <Flex
      width={[1]}
      flexDirection={"row"}
      p={[3]}
      justifyContent={"space-between"}
      style={{ backgroundColor: "#F3F3F3" }}
    >
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        pr={[2]}
      >
        <LabelField fontWeight={"normal"} style={{ width: 160 }}>
          {translate("resources.po.listgrid.this_promo_order_qty")}
        </LabelField>
        <LabelField textAlign={"right"} width={widthValue}>
          {thisQuantity}
        </LabelField>
      </Flex>
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        pl={[2]}
      >
        <LabelField fontWeight={"normal"} style={{ width: 160 }}>
          {translate("resources.po.listgrid.this_promo_order_amt")}
        </LabelField>
        <LabelField textAlign={"right"} width={widthValue}>
          <NumberFormat
            value={thisAmt}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            decimalScale={2}
          />
        </LabelField>
      </Flex>
    </Flex>
  );
};

const HeaderPromotion = (props) => {
  const { translate, totalQuantity, totalAmt, thisAmt, thisQuantity } = props;
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <HeaderTotal
        totalAmt={totalAmt}
        totalQuantity={totalQuantity}
        thisAmt={thisAmt}
        thisQuantity={thisQuantity}
        translate={translate}
      />
      <HeaderThis
        translate={translate}
        thisAmt={thisAmt}
        thisQuantity={thisQuantity}
      />
    </Flex>
  );
};

const HeaderNormal = (props) => {
  const { translate, totalQuantity, totalAmt, thisAmt, thisQuantity } = props;
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <HeaderTotal
        totalAmt={totalAmt}
        totalQuantity={totalQuantity}
        translate={translate}
      />
      <HeaderThis
        translate={translate}
        thisAmt={thisAmt}
        thisQuantity={thisQuantity}
      />
    </Flex>
  );
};

// const getPriceProduct = (products, item) => {
//   const listFree = filter(products, function (o) {
//     return (
//       o.subtotal * 1 === 0 &&
//       item.id === get(o, "promotions.id", 0) &&
//       item.promotion_type === get(o, "promotions.promotion_type", "")
//     );
//   });

//   const listPrice = uniqBy(listFree, "price");
//   const objPrice =
//     listPrice && listPrice.length > 0 ? listPrice[0] : { price: 0 };
//   return get(objPrice, "price", 0);
// };

const HeaderPromo = (props) => {
  const { translate, item, thisQuantity, tempListBuy } = props;
  //tempList,
  let qtyTotalFree = getTotalQtyFreeProduct(
    item,
    thisQuantity,
    item.promotion_type === PromoType_B[0]
      ? "criteria.buy_x_get_y.moqs"
      : "criteria.buy_ab_get_cd.moqs"
  );
  if (item.promotion_type === PromoType_B[1]) {
    qtyTotalFree = getTotalFree(item, tempListBuy);
    // console.log("qtyTotalFree", qtyTotalFree);
  }

  // const getPriceFreeProduct = getPriceProduct(tempList, item);

  return (
    <Flex
      width={[1]}
      flexDirection={"row"}
      pt={[3]}
      px={[3]}
      pb={[2]}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      {item.promotion_type &&
        (item.promotion_type === PromoType_B[0] ||
          item.promotion_type === PromoType_B[1]) && (
          <Box width={[1]}>
            <Flex
              width={[1]}
              flexDirection={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              p={[2]}
              pl={[0]}
            >
              <Box>
                <LabelField
                  fontWeight={"normal"}
                  style={{ width: 160 }}
                  color={"red"}
                >
                  {translate("resources.po.listgrid.this_promo_free_qty")}
                </LabelField>
              </Box>
              <LabelField color={"red"} textAlign={"right"} width={"80px"}>
                {qtyTotalFree}
              </LabelField>
            </Flex>
            {/* <Flex
              width={[1]}
              flexDirection={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              p={[2]}
              pl={[0]}
            >
              <Box>
                <LabelField
                  fontWeight={"normal"}
                  style={{ width: 160 }}
                  color={"red"}
                >
                  {translate("resources.po.listgrid.total_saving")}
                </LabelField>
              </Box>
              <LabelField color={"red"} textAlign={"right"} width={"80px"}>
                <NumberFormat
                  value={qtyTotalFree * getPriceFreeProduct}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$"}
                  decimalScale={2}
                />
              </LabelField>
            </Flex> */}
          </Box>
        )}
      <HeaderButton {...props} />
    </Flex>
  );
};

const HeaderButton = (props) => {
  const { onSaveOrder, translate } = props;
  return (
    <Flex
      width={[1]}
      flexDirection={"row"}
      justifyContent={"flex-end"}
      pb={[2]}
    >
      <Button
        variant='contained'
        color='primary'
        style={{
          borderRadius: 24,
          textTransform: "capitalize",
          paddingLeft: 12,
          paddingRight: 12,
          paddingTop: 8,
          paddingBottom: 8,
          height: 40,
        }}
        onClick={() => onSaveOrder()}
      >
        {/* {translate("resources.po.listgrid.add_to_order")} */}
        {translate("resources.buttons.confirm")}
      </Button>
    </Flex>
  );
};

export { HeaderPromotion, HeaderNormal, HeaderButton, HeaderPromo };
