import React, { useState } from "react";
import { showAdminSales, getUser } from "../../utils";
import { get } from "lodash";
const ActiveAgGridField = (props) => {
  const [active, setActive] = useState(props.value);
  const user = getUser();

  const onActiveChange = (event) => {
    props.onActiveChange(event.target.value, props.data);
    setActive(event.target.value);
  };
  //   console.log(props);
  const role = get(user, "role", "");
  // console.log(showAdminSales(role));
  if (!showAdminSales(role)) {
    return <div>{active ? "Active" : "Inactive"}</div>;
  }
  return (
    <div>
      <select value={active} onChange={onActiveChange}>
        <option value={true}> Active </option>
        <option value={false}> InActive </option>
      </select>
    </div>
  );
};

export default ActiveAgGridField;
