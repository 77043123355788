import React, { useRef } from "react";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { find, get, isNumber, filter } from "lodash";
import {
  useTranslate,
  getFormInitialValues,
  useDataProvider,
  sanitizeEmptyValues,
  useNotify,
} from "react-admin";
import { Box } from "rebass";
import CustomFormView from "./CustomFormView";
import { HeaderCustomForm } from "./utils";

const updateShortCode = (products, criteria, parent, child) => {
  const myType = get(criteria, `${parent}.${child}.type`);
  if (myType && myType === "ProductShortCode") {
    const idProduct = get(criteria, `${parent}.${child}.id`);
    if (isNumber(idProduct)) {
      const item = find(products, function (product) {
        return product.id === idProduct;
      });
      if (item && item.id) {
        return {
          [child]: {
            ...get(criteria, `${parent}.${child}`),
            code: get(item, "short_code"),
          },
        };
      }
    }
  }
  return {};
};

const checkShortCode = (products, promotion_type, values) => {
  let newValues = values;
  const criteria = get(values, "criteria");
  if (criteria) {
    let params = {
      ...criteria,
    };
    if (promotion_type === dps.buyxgety) {
      if (get(values, "criteria.buy_x_get_y")) {
        const myParams = {
          ...get(values, "criteria.buy_x_get_y"),
          ...updateShortCode(products, criteria, "buy_x_get_y", "x"),
          ...updateShortCode(products, criteria, "buy_x_get_y", "y"),
        };
        params["buy_x_get_y"] = myParams;
      }
    } else if (promotion_type === dps.buyabgetcd) {
      if (get(values, "criteria.buy_ab_get_cd")) {
        const myParams = {
          ...get(values, "criteria.buy_ab_get_cd"),
          ...updateShortCode(products, criteria, "buy_ab_get_cd", "a"),
          ...updateShortCode(products, criteria, "buy_ab_get_cd", "b"),
          ...updateShortCode(products, criteria, "buy_ab_get_cd", "c"),
          ...updateShortCode(products, criteria, "buy_ab_get_cd", "d"),
        };
        params["buy_ab_get_cd"] = myParams;
      }
    } else if (promotion_type === dps.discount) {
      if (get(values, "criteria.discount")) {
        const myParams = {
          ...get(values, "criteria.discount"),
          ...updateShortCode(products, criteria, "discount", "buy"),
        };
        params["discount"] = myParams;
      }
    } else if (promotion_type === dps.sp) {
      if (get(values, "criteria.price")) {
        const myParams = {
          ...get(values, "criteria.price"),
          ...updateShortCode(products, criteria, "price", "buy"),
        };
        params["price"] = myParams;
      }
    }
    newValues["criteria"] = params;
  }
  return newValues;
};

const dps = {
  buyxgety: "Buy X Get Y",
  buyabgetcd: "Buy AB Get CD",
  discount: "Discount",
  sp: "Special Price",
};

const checkMOQ = (criteria) => {
  let newCriteria = criteria;
  if (criteria) {
    if (get(criteria, "buy_ab_get_cd.moqs")) {
      const newMOQs = filter(
        get(criteria, "buy_ab_get_cd.moqs", []),
        function (o) {
          return o.moq && o.moq > 0;
        }
      );
      newCriteria.buy_ab_get_cd = {
        ...criteria.buy_ab_get_cd,
        moqs: newMOQs,
      };
    }

    if (get(criteria, "buy_x_get_y.moqs")) {
      const newMOQs = filter(
        get(criteria, "buy_x_get_y.moqs", []),
        function (o) {
          return o.moq && o.moq > 0;
        }
      );
      newCriteria.buy_x_get_y = {
        ...criteria.buy_x_get_y,
        moqs: newMOQs,
      };
    }
    if (get(criteria, "discount.moqs")) {
      const newMOQs = filter(get(criteria, "discount.moqs", []), function (o) {
        return o.moq && o.moq > 0;
      });
      newCriteria.discount = {
        ...criteria.discount,
        moqs: newMOQs,
      };
    }
    if (get(criteria, "price.moqs")) {
      const newMOQs = filter(get(criteria, "price.moqs", []), function (o) {
        return o.moq && o.moq > 0;
      });
      newCriteria.price = {
        ...criteria.price,
        moqs: newMOQs,
      };
    }
  }

  return newCriteria;
};

const isProduct = (promotion_type, values) => {
  if (promotion_type === dps.buyxgety) {
    return get(values, "criteria.buy_x_get_y.x.id");
  } else if (promotion_type === dps.buyabgetcd) {
    return get(values, "criteria.buy_ab_get_cd.a.id");
  } else if (promotion_type === dps.discount) {
    return get(values, "criteria.discount.buy.id");
  } else if (promotion_type === dps.sp) {
    return get(values, "criteria.price.buy.id");
  }
  return undefined;
};

const keyPromo1 = [
  { id: "ProductGroup", value: "group_id" },
  { id: "SubProductGroup", value: "subgroup_id" },
  { id: "ProductCode", value: "code" },
  { id: "ProductShortCode", value: "short_code" },
];

const getKeyPromo = (promotion_type, values) => {
  if (promotion_type === dps.buyxgety) {
    const typePromo = get(values, "criteria.buy_x_get_y.x.type");
    const keyPromo = find(keyPromo1, function (o) {
      return o.id === typePromo;
    });
    const myId = get(values, "criteria.buy_x_get_y.x.id")
      ? get(values, "criteria.buy_x_get_y.x.id")
      : "";
    return keyPromo.value ? { [keyPromo.value]: myId } : {};
  } else if (promotion_type === dps.buyabgetcd) {
    const typePromo = get(values, "criteria.buy_ab_get_cd.a.type");
    const keyPromo = find(keyPromo1, function (o) {
      return o.id === typePromo;
    });
    const myId =
      get(values, "criteria.buy_ab_get_cd.a.id") &&
      get(values, "criteria.buy_ab_get_cd.a.id") !== ""
        ? get(values, "criteria.buy_ab_get_cd.a.id")
        : get(values, "criteria.buy_ab_get_cd.b.id");
    return keyPromo.value ? { [keyPromo.value]: myId } : {};
  } else if (promotion_type === dps.discount) {
    const typePromo = get(values, "criteria.discount.buy.type");
    const keyPromo = find(keyPromo1, function (o) {
      return o.id === typePromo;
    });
    const myId = get(values, "criteria.discount.buy.id")
      ? get(values, "criteria.discount.buy.id")
      : "";
    return keyPromo.value ? { [keyPromo.value]: myId } : {};
  } else if (promotion_type === dps.sp) {
    const typePromo = get(values, "criteria.price.buy.type");
    const keyPromo = find(keyPromo1, function (o) {
      return o.id === typePromo;
    });
    const myId = get(values, "criteria.price.buy.id")
      ? get(values, "criteria.price.buy.id")
      : "";
    return keyPromo.value ? { [keyPromo.value]: myId } : {};
    // return get(values, "criteria.price.buy.id");
  }
  return {};
};

const CustomSimplePromotionForm = ({
  initialValues,
  defaultValue,
  saving,
  showNotification,
  sourceApi,
  location,
  match,
  history,
  staticContext,
  typePostUrl,
  pathUrl,
  title,
  isShowBack,
  labelBack,
  customBackUrl,
  dataProducts,
  ...props
}) => {
  const notify = useNotify();
  let redirect = useRef(props.redirect);
  // We don't use state here for two reasons:
  // 1. There no way to execute code only after the state has been updated
  // 2. We don't want the form to rerender when redirect is changed
  const setRedirect = (newRedirect) => {
    redirect.current = newRedirect;
  };
  const finalInitialValues = getFormInitialValues(
    initialValues,
    defaultValue,
    props.record
  );
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const { pathname } = location;
  let paramsUrl = pathname;

  if (typePostUrl === "CREATE") {
    const check = `/create`;
    paramsUrl = pathname.substring(0, pathname.length - check.length);
  } else if (typePostUrl === "UPDATE") {
    const check = `/${pathUrl}/`;
    const n = pathname.indexOf(check);
    const record_id = pathname.substring(n + check.length, pathname.length);
    paramsUrl = pathname.replace(`/${pathUrl}/${record_id}`, `/${pathUrl}`);
  }
  if (customBackUrl && customBackUrl !== "") {
    paramsUrl = customBackUrl;
  }

  const submit = (values) => {
    // console.log(values);
    const finalValues = sanitizeEmptyValues(finalInitialValues, values);
    const photos = get(finalValues, "photos");
    const promotion_type = get(values, "promotion_type");
    const newCriteria = checkMOQ(get(values, "criteria"));
    if (typePostUrl === "CREATE") {
      const params = {
        data: { ...finalValues, criteria: newCriteria },
      };
      // console.log(finalValues);
      if (photos && photos !== null && photos.length > 0) {
        dataProvider
          .create(sourceApi, params)
          .then(({ data }) => {
            // console.log(data);
            if (data) {
              notify("ra.notification.created", "success", {
                smart_count: 1,
              });
            }
            history.push(paramsUrl);
          })
          .catch((error) => {
            if (error && error.message && error.message !== "") {
              notify(error.message, "warning", {
                smart_count: 1,
              });
            } else {
              notify("resources.notification.please_try_again", "warning", {
                smart_count: 1,
              });
            }
          });
      } else {
        if (promotion_type) {
          const product_id = isProduct(promotion_type, values);
          if (product_id) {
            const myfilter = getKeyPromo(promotion_type, values);
            dataProvider
              .getList("products", {
                pagination: { page: 1, perPage: 10 },
                sort: { field: "id", order: "ASC" },
                filter: myfilter,
              })
              .then(({ data }) => {
                if (data && data.length > 0) {
                  const getMyPhotos = find(data, function (o) {
                    return get(o, "photos[0]", null) !== null;
                  });
                  const myPhotos =
                    get(getMyPhotos, "photos[0]", null) !== null
                      ? [get(getMyPhotos, "photos[0]", null)]
                      : null;
                  const params1 = {
                    id: finalValues.id,
                    data: {
                      ...values,
                      photos: myPhotos,
                      criteria: newCriteria,
                    },
                    previousData: finalValues,
                  };
                  dataProvider
                    .create(sourceApi, params1)
                    .then(({ data }) => {
                      // console.log(data);
                      if (data) {
                        notify("resources.notification.updated", "success", {
                          smart_count: 1,
                        });
                      }
                      history.push(paramsUrl);
                    })
                    .catch((error) => {
                      // console.log(error.message);
                      if (error && error.message && error.message !== "") {
                        notify(error.message, "warning", {
                          smart_count: 1,
                        });
                      } else {
                        notify(
                          "resources.notification.please_try_again",
                          "warning",
                          {
                            smart_count: 1,
                          }
                        );
                      }
                    });
                } else {
                  dataProvider
                    .create(sourceApi, params)
                    .then(({ data }) => {
                      // console.log(data);
                      if (data) {
                        notify("resources.notification.updated", "success", {
                          smart_count: 1,
                        });
                      }
                      history.push(paramsUrl);
                    })
                    .catch((error) => {
                      // console.log(error.message);
                      if (error && error.message && error.message !== "") {
                        notify(error.message, "warning", {
                          smart_count: 1,
                        });
                      } else {
                        notify(
                          "resources.notification.please_try_again",
                          "warning",
                          {
                            smart_count: 1,
                          }
                        );
                      }
                    });
                }
              })
              .catch((error) => {
                console.log(error.message);
                notify("resources.notification.please_try_again", "warning", {
                  smart_count: 1,
                });
              });
          } else {
            dataProvider
              .create(sourceApi, params)
              .then(({ data }) => {
                // console.log(data);
                if (data) {
                  notify("ra.notification.created", "success", {
                    smart_count: 1,
                  });
                }
                history.push(paramsUrl);
              })
              .catch((error) => {
                if (error && error.message && error.message !== "") {
                  notify(error.message, "warning", {
                    smart_count: 1,
                  });
                } else {
                  notify("resources.notification.please_try_again", "warning", {
                    smart_count: 1,
                  });
                }
              });
          }
        }
      }
    } else if (typePostUrl === "UPDATE") {
      const newValue = {
        ...values,
        criteria: newCriteria,
      };
      const params = {
        id: finalValues.id,
        data: checkShortCode(dataProducts, promotion_type, newValue),
        previousData: finalValues,
      };
      // console.log("checkShortCode", params);

      if (photos && photos !== null && photos.length > 0) {
        dataProvider
          .update(sourceApi, params)
          .then(({ data }) => {
            // console.log(data);
            if (data) {
              notify("resources.notification.updated", "success", {
                smart_count: 1,
              });
            }
            history.push(paramsUrl);
          })
          .catch((error) => {
            // console.log(error.message);
            if (error && error.message && error.message !== "") {
              notify(error.message, "warning", {
                smart_count: 1,
              });
            } else {
              notify("resources.notification.please_try_again", "warning", {
                smart_count: 1,
              });
            }
          });
      } else {
        const product_id = isProduct(promotion_type, values);
        if (product_id) {
          const myfilter = getKeyPromo(promotion_type, values);
          dataProvider
            .getList("products", {
              pagination: { page: 1, perPage: 10 },
              sort: { field: "id", order: "ASC" },
              filter: myfilter,
            })
            .then(({ data }) => {
              if (data && data.length > 0) {
                const getMyPhotos = find(data, function (o) {
                  return get(o, "photos[0]", null) !== null;
                });
                const myPhotos =
                  get(getMyPhotos, "photos[0]", null) !== null
                    ? [get(getMyPhotos, "photos[0]", null)]
                    : null;
                const params1 = {
                  id: finalValues.id,
                  data: {
                    ...checkShortCode(dataProducts, promotion_type, newValue),
                    photos: myPhotos,
                  },
                  previousData: finalValues,
                };

                dataProvider
                  .update(sourceApi, params1)
                  .then(({ data }) => {
                    // console.log(data);
                    if (data) {
                      notify("resources.notification.updated", "success", {
                        smart_count: 1,
                      });
                    }
                    history.push(paramsUrl);
                  })
                  .catch((error) => {
                    // console.log(error.message);
                    if (error && error.message && error.message !== "") {
                      notify(error.message, "warning", {
                        smart_count: 1,
                      });
                    } else {
                      notify(
                        "resources.notification.please_try_again",
                        "warning",
                        {
                          smart_count: 1,
                        }
                      );
                    }
                  });
              } else {
                dataProvider
                  .update(sourceApi, params)
                  .then(({ data }) => {
                    // console.log(data);
                    if (data) {
                      notify("resources.notification.updated", "success", {
                        smart_count: 1,
                      });
                    }
                    history.push(paramsUrl);
                  })
                  .catch((error) => {
                    // console.log(error.message);
                    if (error && error.message && error.message !== "") {
                      notify(error.message, "warning", {
                        smart_count: 1,
                      });
                    } else {
                      notify(
                        "resources.notification.please_try_again",
                        "warning",
                        {
                          smart_count: 1,
                        }
                      );
                    }
                  });
              }
            })
            .catch((error) => {
              console.log(error.message);
              notify("resources.notification.please_try_again", "warning", {
                smart_count: 1,
              });
            });
        }
      }
    }
  };

  return (
    <Box width={[1]}>
      <HeaderCustomForm
        translate={translate}
        title={title}
        finalInitialValues={finalInitialValues}
        paramsUrl={paramsUrl}
        isShowBack={isShowBack}
        labelBack={labelBack}
      />
      <Form
        key={props.version}
        initialValues={finalInitialValues}
        onSubmit={submit}
        mutators={{
          ...arrayMutators,
          updateField: ([field, value], state, utils) => {
            utils.changeValue(state, field, () => value);
          },
        }}
        keepDirtyOnReinitialize
        destroyOnUnregister
        subscription={defaultSubscription}
        {...props}
        render={({
          form: {
            mutators: { updateField },
          },
          ...formProps
        }) => (
          <CustomFormView
            saving={formProps.submitting || saving}
            translate={translate}
            setRedirect={setRedirect}
            {...props}
            {...formProps}
            updateField={(key, value) => updateField(key, value)}
          />
        )}
      />
    </Box>
  );
};

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
};

export default CustomSimplePromotionForm;
