import React from "react";
import { get } from "lodash";
import { Show, useTranslate } from "react-admin";
import { Flex, Box } from "rebass";
// import { useLocation } from "react-router-dom"
import { Title, ListButton } from "../components";
import { ShowFormFields } from "./components";
const ActionForm = (props) => {
  const translate = useTranslate();
  // const location = useLocation()
  const { pt = 3 } = props;
  // const basepathUrl =
  //   location.search && location.search === "" ? props.basePath : "/inventories"
  return (
    <Flex
      flexDirection={"row"}
      justifyContent={"space-between"}
      width={[1]}
      pt={[pt]}
      pb={[2]}
    >
      <Box width={[1]}>
        <Title fontWeight={"bold"} fontSize={[3]}>
          {translate(props.title)}
          {` ${get(props, "data.po_number", "")}`}
        </Title>
      </Box>
      <Flex width={[1]} flexDirection={"row"} justifyContent={"flex-end"}>
        <ListButton basepath={props.basePath} record={props.data} />
      </Flex>
    </Flex>
  );
};
const POShowDealer = (props) => {
  // console.log(props)
  const { hasList, hasShow, hasEdit, ...rest } = props;
  return (
    <Show
      {...rest}
      actions={<ActionForm title={"resources.po.title"} />}
      component={"div"}
    >
      <ShowFormFields {...rest} />
    </Show>
  );
};

export default POShowDealer;
