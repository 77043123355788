export default {
  buttons: {
    chart: "Grafik",
    create_websites: "Buat Website",
    add_new_schedule: "Menambah Jadwal Baru",
    add_schedule: "Menambah Jadwal",
    edit_schedule: "Merubah Jadwal",
    add_option: "Menambah Opsi",
    add_variant: "Menambah Varian",
    add_product: "Menambah Produk",
    create: "Buat",
    cancel: "Batal",
    cancel_promotion: "Batalkan Promosi",
    delete: "Hapus",
    save: "Simpan",
    edit: "Ubah",
    generate: "Generate",
    view: "Lihat",
    download_cvs: "Unduh CSV",
    submit_order: "Masukan pesanan",
    submit_po: "Submit PO",
    add_to_order: "Tambah Produk",
    add_to_po: "Tambah ke PO",
    print_invoice: "Cetak Faktur",
    print__pro_form_invoice: "Cetak faktur pro forma",
    login: "Login",
    create_po: "Buat PO",
    save_as_draft: "Simpan sebagai draf",
    receive_goods: "Terima Barang",
    update_inventory: "Perbaharui Persediaan",
    import_from_csv: "Impor dari CSV",
    close: "Tutup",
    upload_csv: "Unggah CSV",
    upload: "Unggah",
    dashboard: "Dasbor",
    list: "Daftar",
    upload_file_csv: "Unggah File CSV …",
    or: "ATAU",
    and: "DAN",
    add_normal_price: "Tambah Add Non-Promo Item",
    showremarks: "Show Remarks",
    upload_country_phasing: "Upload Country Phasing",
    upload_dealer_target: "Upload Dealer Target",
    upload_promotions: "Upload Promosi",
    broadcast_promotion: "Broadcast Promotion",
    new: "Baru",
    upload_invoiced_transactions: "Upload Transaksi invoice",
    upload_inventory: "Upload Inventory",
    import_via_csv: "Import via CSV",
    add_to_list: "Add to List",
    reset: "Reset",
    submit: "Submit",
    view_all: "View All",
    post_to_gp: "Post to GP",
    export_csv: "Export CSV",
    sent_to_dealer: "Kirim ke Dealer",
    submit_for_approval: "Kirimkan untuk persetujuan",
    approve: "Menyetujui",
    reject: "Menolak",
    rejected: "Telah ditolak",
    upload_product: "Upload Produk",
    confirm: "Confirm",
    clear: "Clear",
    send_back_to_dealer: "Send back to dealer",
    message: {
      cancel_promotion_title: "Membatalkan Promosi",
      cancel_promotion: "Apakah Anda yakin ingin membatalkan promosi?",
      expired_promotion: "Promotion has expired",
      remove_promotion_title: "Remove Promotion",
      remove_promotion: "Are You sure want to remove?",
      can_not_remove: "Can not remove this promotion",
    },
  },
};
