export default {
  promotions: {
    title: {
      new_product: "New Dealer Promotions",
      edit_product: "Edit Dealer Promotions",
      new_cart: "New Customer Promotions",
      edit_cart: "Edit Customer Promotions",
      new_promotion: "New Promotion",
      edit_promotion: "Edit Promotion",
      promotions: "Promotions",
    },
    tabs: {
      product_rules: "Dealer Rules",
      cart_rules: "Customer Rules",
    },
    panel: {
      criteria: "Criteria",
      action: "Action",
      coupon_settings: "Coupon Settings",
      promotion_settings: "Promotion Settings",
      promotion_product: "Promotion Products",
      promotions: "Promotions",
      details: "Details",
      order_items: "Order Items",
      summary: "Summary",
      promotion_items: "Promotion Items",
      free_items: "Free Items",
      target_summary: "Target Summary",
    },
    labels: {
      criteria_type_x: "Criteria X Type",
      criteria_type_y: "Criteria Y Type",
      criteria_x: "Criteria X",
      criteria_y: "Criteria Y",
      buy_a_or_b: "Buy A or B",
      get_c_or_d: "Get C or D",
      criteria_type_a: "Criteria A Type",
      criteria_type_b: "Criteria B Type",
      criteria_a: "Criteria A",
      criteria_b: "Criteria B",
      criteria_type_c: "Criteria C Type",
      criteria_type_d: "Criteria D Type",
      criteria_c: "Criteria C",
      criteria_d: "Criteria D",
      criteria: "Criteria",
      criteria_type: "CriteriaType",
    },
    fields: {
      type: "Type",
      country: "Country",
      name: "Name",
      site_id: "Site",
      active: "Active",
      start: "Start",
      end: "End",
      start_date: "Start Date",
      end_date: "End Date",
      criteria: "Criteria",
      criteria_type: "Criteria Type",
      coupon: "Coupon",
      warehouse: "Warehouse",
      collection: "Collection",
      collections: "Collections",
      apply_lower_priority: "Apply Other Promotions With Lower Priority",
      promotion_amount: "Promotion Amount",
      promotion_type: "Promotion Type",
      need_coupon: "Need Coupon Code To Use Promotion",
      priority: "Priority",
      buy_x: "Buy X",
      get_y: "Get Y",
      max_qty_y: "Maximum Quantity of Free Y",
      auto_generate: "Auto Generate",
      qty: "Quantity",
      coupon_prefix: "Coupon Prefix",
      coupon_suffix: "Coupon Suffix",
      coupon_code_length: "Coupon Code Length",
      coupon_code: "Coupon Code",
      limit_number_use: "Limit Number of Use",
      limit_per_user: "Limit Per User",
      subtotal: "Subtotal",
      weight: "Weight",
      payment_method: "Payment Method",
      postal_code: "Postal Code",
      shipping_region: "Shipping Region",
      shipping_state: "Shipping State",
      products: "Products",
      cart: "Cart",
      categories: "Categories",
      attribute: "Attribute",
      operator: "Operator",
      discount_type: "Discount Type",
      percent: "Percent",
      amount: "Amount",
      custom_settings: "Custom Settings",
      buy: "Buy",
      get: "Get",
      discount: "Discount",
      h_qty: "Qty",
      next_purchase: "Next Purchase",
      product: "Product",
      apply_other_promotions: "Apply other promotions",
      max_qty: "Maximum Qty",
      groups: "Groups",
      moq: "MOQ",
      additional: "Additional",
      price: "Price",
      remarks: "Remarks",
      photo: "Photo",
      approval_comment: "Approval Comment",
      status: "Status",
      dealers: "Dealers",
    },
    note_text: {
      priority: "Priority of the matched Promotions to be applied first",
      percent: "Enter 20 for 20% for Percent Type or 20 for $20 for Amount",
      amount: "Enter 20 for 20% for Percent Type or 20 for $20 for Amount",
      get_y:
        "Enter 20 for 20% for Percent or 20 for $20 for Amount, 1 for 1 free quantity for Buy X Get Y",
      buy_x:
        "Discount quantity step. For example, enter 3 for Buy 3 Get Y also means Buy 6 Get 2xY",
      max_qty_y: "For example, enter 5 means maximum of 5xY can be given",
      limit_number_use: "Maximum number of redemption. Put 0 if no limit",
      limit_per_user: "Put 0 if no limit",
    },
  },
};
