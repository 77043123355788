export default {
  login: {
    titleGoogleButtinon: "Sign in with Google",
    userid: "UserId",
    username: "User name",
    password: "Password",
    error_password: "Password can't empty",
    error_username: "Username can't empty",
    error_login: "Please try again",
  },
}
