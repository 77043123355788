import React from "react";
import { TextField, ReferenceField } from "react-admin";
import MenuIcon from "@material-ui/icons/Menu";
import {
  BetterList,
  ButtonShowEditView,
  RaDateField,
  ActiveField,
} from "../components";
const PriceBookDealerList = (props) => {
  const { myType } = props;
  return (
    <BetterList
      {...props}
      actions={null}
      // rowclick={"show"}
      filter={{ type: myType }}
    >
      <TextField source='name' label={"resources.pricebook.fields.name"} />
      <ReferenceField
        source='country_id'
        label={"resources.pricebook.fields.country"}
        reference='countries'
        link={false}
      >
        <TextField source={"name"} />
      </ReferenceField>

      <RaDateField
        {...props}
        source='start_at'
        label='resources.pricebook.fields.start_at'
      />
      <RaDateField
        {...props}
        source='end_at'
        label='resources.pricebook.fields.end_at'
      />
      <ActiveField
        source='active'
        label={"resources.pricebook.fields.active"}
      />
      <ButtonShowEditView
        {...props}
        iconshow={<MenuIcon fontSize={"small"} />}
        isCustomLink={true}
        taburl='priceBooks'
        paramUrl='dealer'
      />
    </BetterList>
  );
};
export default PriceBookDealerList;
