import { numberdesimal } from "../utils";
export const columns = [
  { field: "country", headerName: "Country", width: 130 },
  { field: "dealer", headerName: "Dealer", width: 330 },
  { field: "category", headerName: "Category", width: 200 },
  { field: "target_setting", headerName: "Target Setting", width: 200 },
  { field: "speed_growth", headerName: "Speed/Growth", width: 200 },
  { field: "last_year", headerName: "Last Year", width: 160, ...numberdesimal },
  { field: "target", headerName: "Target", width: 130, ...numberdesimal },
  { field: "invoiced", headerName: "Invoiced", width: 160, ...numberdesimal },
  { field: "gap", headerName: "GAP", width: 130, ...numberdesimal },
  { field: "ratio", headerName: "Ratio %", width: 130, ...numberdesimal },
];

export const columnsDealer = [
  { field: "category", headerName: "Category", width: 200 },
  { field: "target_setting", headerName: "Target Setting", width: 200 },
  { field: "speed_growth", headerName: "Speed/Growth", width: 200 },
  { field: "last_year", headerName: "Last Year", width: 160, ...numberdesimal },
  { field: "target", headerName: "Target", width: 130, ...numberdesimal },
  { field: "invoiced", headerName: "Invoiced", width: 160, ...numberdesimal },
  { field: "gap", headerName: "GAP", width: 130, ...numberdesimal },
  { field: "ratio", headerName: "Ratio %", width: 130, ...numberdesimal },
];
export const sortModelDealer = [
  {
    field: "category",
    sort: "asc",
  },
  {
    field: "target_setting",
    sort: "asc",
  },
  {
    field: "speed_growth",
    sort: "asc",
  },
  {
    field: "last_year",
    sort: "asc",
  },
  {
    field: "target",
    sort: "asc",
  },
  {
    field: "invoiced",
    sort: "asc",
  },
  {
    field: "gap",
    sort: "asc",
  },
  {
    field: "ratio",
    sort: "asc",
  },
];
export const sortModel = [
  {
    field: "country",
    sort: "asc",
  },
  {
    field: "dealer",
    sort: "asc",
  },
  {
    field: "category",
    sort: "asc",
  },
  {
    field: "target_setting",
    sort: "asc",
  },
  {
    field: "speed_growth",
    sort: "asc",
  },
  {
    field: "last_year",
    sort: "asc",
  },
  {
    field: "target",
    sort: "asc",
  },
  {
    field: "invoiced",
    sort: "asc",
  },
  {
    field: "gap",
    sort: "asc",
  },
  {
    field: "ratio",
    sort: "asc",
  },
];

export { default as ListInvoiced } from "./ListInvoiced";
export { default as HeaderFilters } from "./HeaderFilters";
