import { numberdesimal } from "../utils";
// import { drop } from "lodash";
// function getPaginatedItems(items, page, pageSize) {
//   var pg = page || 1,
//     pgSize = pageSize || 100,
//     offset = (pg - 1) * pgSize,
//     pagedItems = drop(items, offset).slice(0, pgSize);
//   return {
//     page: pg,
//     pageSize: pgSize,
//     total: items.length,
//     total_pages: Math.ceil(items.length / pgSize),
//     listPO: pagedItems,
//   };
// }

export const columns = [
  { field: "country", headerName: "Country", width: 130 },
  { field: "dealer", headerName: "Dealer", width: 350 },
  { field: "category", headerName: "Category", width: 200 },
  { field: "target_setting", headerName: "Target Setting", width: 200 },
  { field: "last_year", headerName: "Last Year", width: 160, ...numberdesimal },
  { field: "target", headerName: "Target", width: 130, ...numberdesimal },
  { field: "po", headerName: "POA", width: 130, ...numberdesimal },
  { field: "gap", headerName: "GAP", width: 130, ...numberdesimal },
  { field: "ratio", headerName: "Ratio %", width: 130, ...numberdesimal },
];
export const columnsDealer = [
  { field: "category", headerName: "Category", width: 200 },
  { field: "target_setting", headerName: "Target Setting", width: 200 },
  { field: "last_year", headerName: "Last Year", width: 160, ...numberdesimal },
  { field: "target", headerName: "Target", width: 130, ...numberdesimal },
  { field: "po", headerName: "POB", width: 130, ...numberdesimal },
  { field: "gap", headerName: "GAP", width: 130, ...numberdesimal },
  { field: "ratio", headerName: "Ratio %", width: 130, ...numberdesimal },
];
export const sortModel = [
  {
    field: "country",
    sort: "asc",
  },
  {
    field: "dealer",
    sort: "asc",
  },
  {
    field: "category",
    sort: "asc",
  },
  {
    field: "target_setting",
    sort: "asc",
  },
  {
    field: "last_year",
    sort: "asc",
  },
  {
    field: "target",
    sort: "asc",
  },
  {
    field: "po",
    sort: "asc",
  },
  {
    field: "gap",
    sort: "asc",
  },
  {
    field: "ratio",
    sort: "asc",
  },
];
export const sortModelDealer = [
  {
    field: "category",
    sort: "asc",
  },
  {
    field: "target_setting",
    sort: "asc",
  },
  {
    field: "last_year",
    sort: "asc",
  },
  {
    field: "target",
    sort: "asc",
  },
  {
    field: "po",
    sort: "asc",
  },
  {
    field: "gap",
    sort: "asc",
  },
  {
    field: "ratio",
    sort: "asc",
  },
];

export { default as ListFilters } from "./ListFilters";
export { default as HeaderFilters } from "./HeaderFilters";
