import React from "react";
import { get } from "lodash";
import { Edit, useTranslate, useQueryWithStore, Loading } from "react-admin";
import { Flex, Box } from "rebass";
import { useLocation } from "react-router-dom";

import { BetterPoForm, Title, ListButton } from "../components";
import { EditFormFields } from "./components/promotions";
import { FooterPOToolbar } from "./components";
import { getUser } from "../utils";
const ActionForm = (props) => {
  const translate = useTranslate();
  const location = useLocation();
  const { pt = 3 } = props;

  return (
    <Flex
      flexDirection={"row"}
      justifyContent={"space-between"}
      width={[1]}
      pt={[pt]}
      pb={[2]}
    >
      <Box width={[1]}>
        <Title fontWeight={"bold"} fontSize={[3]}>
          {translate(props.title)}
          {` ${get(props, "data.po_number", "")}`}
        </Title>
      </Box>
      <Flex width={[1]} flexDirection={"row"} justifyContent={"flex-end"}>
        <ListButton
          basepath={`${location.pathname}/show`}
          record={props.data}
        />
      </Flex>
    </Flex>
  );
};

const POEditDealer = (props) => {
  // console.log(props)
  const user = getUser();
  const dealerId = get(user, "dealer_id");
  const { loaded, data } = useQueryWithStore({
    type: "getOne",
    resource: "dealers",
    payload: { id: dealerId },
  });

  if (!loaded) {
    return (
      <Flex width={[1]} justifyContent={"center"} alignItems={"center"}>
        <Loading />
      </Flex>
    );
  }
  return (
    <Edit {...props} actions={<ActionForm title={"resources.po.edit_po"} />}>
      <BetterPoForm
        {...props}
        toolbar={<FooterPOToolbar />}
        typePostUrl={"UPDATE"}
      >
        <EditFormFields
          {...props}
          countryId={get(data, "country_id", "")}
          dealerId={dealerId}
          typePostUrl={"UPDATE"}
        />
      </BetterPoForm>
    </Edit>
  );
};

export default POEditDealer;
