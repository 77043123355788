import React, { useState } from "react";
import { useLogin, useNotify, Notification, useTranslate } from "react-admin";
import { useHistory } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { Flex, Image, Text } from "rebass";
import { get, replace } from "lodash";
import Card from "@material-ui/core/Card";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Field } from "react-final-form";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(2),
  },
  withoutLabel: {
    marginBottom: theme.spacing(3),
  },
  textField: {
    width: "320px",
  },
}));

/***/

const LoginPage = (props) => {
  const { theme } = props;
  // const theme = useTheme();

  const history = useHistory();
  // console.log(history.location);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const login = useLogin();
  const [loading, setLoading] = useState(false);
  // const [show, setShow] = useState(false);
  const [visible, setVisible] = useState({
    password: false,
    confirm_password: false,
  });

  const handleClickShowPassword = (k) => {
    setVisible({ ...visible, [k]: !visible[k] });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const onSubmit = (v) => {
    if (v.email && v.password) {
      // console.log(v);
      const newRedirect = get(history, "location.search", "");
      const myRedirect = replace(newRedirect, "?redirectTo=", "");
      setLoading(true);
      const params = {
        email: v.email,
        password: v.password,
      };
      login(params, myRedirect)
        .then((response) => {
          if (myRedirect !== "" && response && response.token) {
            history.push(myRedirect === "/login" ? "/" : myRedirect);
          } else {
            history.push("/");
          }
        })
        .catch((e) => {
          // console.log("error", e.message);
          setLoading(false);
          notify("resources.login.error_login", "warning");
        });
    }
  };
  const onShowForgotPassword = () => {
    history.push("/forgot_password");
  };

  return (
    <ThemeProvider theme={theme}>
      <Flex
        width={[1]}
        bg={theme.palette.primary[500]}
        style={{ height: "100vh" }}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Card
          style={{
            width: 550,
            minHeight: 350,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 16,
            borderRadius: 16,
          }}
        >
          <Image
            src={require("../assets/images/logo.png")}
            width={200}
            mb={[3]}
          />
          <Text
            fontWeight={"bold"}
            fontSize={[3]}
            py={[2]}
            color={"rgba(0,0,0,0.6)"}
          >
            {translate("resources.buttons.login")}
          </Text>

          <Form
            onSubmit={onSubmit}
            validate={(values) => {
              const errors = {};
              if (!values.password) {
                errors.password = "Required";
              }
              if (!values.email) {
                errors.email = "Required";
              }
              return errors;
            }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Flex
                  width={[1]}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Field name='email'>
                    {({ input, meta }) => {
                      return (
                        <FormControl
                          className={clsx(classes.margin, classes.textField)}
                          variant='outlined'
                        >
                          <InputLabel htmlFor='email'>
                            {translate("resources.accounts.fields.email")}
                          </InputLabel>
                          <OutlinedInput
                            id='email'
                            type={"email"}
                            {...input}
                            labelWidth={86}
                            aria-describedby='component-error-text-email'
                          />
                          {meta.touched && meta.error && (
                            <span style={{ color: "#FF0000" }}>
                              {meta.error}
                            </span>
                          )}
                        </FormControl>
                      );
                    }}
                  </Field>
                  <Field name='password'>
                    {({ input, meta }) => (
                      <FormControl
                        className={clsx(classes.margin, classes.textField)}
                        variant='outlined'
                      >
                        <InputLabel htmlFor='password'>
                          {translate("resources.accounts.fields.password")}
                        </InputLabel>
                        <OutlinedInput
                          id='password'
                          type={visible.password ? "text" : "password"}
                          {...input}
                          labelWidth={86}
                          aria-describedby='component-error-text-password'
                          endAdornment={
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={() =>
                                  handleClickShowPassword("password")
                                }
                                onMouseDown={handleMouseDownPassword}
                                edge='end'
                              >
                                {visible.password ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {meta.touched && meta.error && (
                          <span style={{ color: "#FF0000" }}>{meta.error}</span>
                        )}
                      </FormControl>
                    )}
                  </Field>
                  <Button
                    variant='contained'
                    color='primary'
                    size='large'
                    type='submit'
                    style={{
                      minWidth: 180,
                      textTransform: "capitalize",
                      borderRadius: 24,
                    }}
                    startIcon={
                      loading ? (
                        <CircularProgress size={16} color='secondary' />
                      ) : null
                    }
                  >
                    {translate("resources.buttons.login")}
                  </Button>
                  <Flex
                    width={[1]}
                    py={[3]}
                    flexDirection={"row"}
                    justifyContent={"center"}
                  >
                    <Button
                      color='primary'
                      size='large'
                      style={{ minWidth: 320, textTransform: "capitalize" }}
                      onClick={() => onShowForgotPassword()}
                    >
                      {translate("resources.accounts.forgot_password")}
                    </Button>
                  </Flex>
                </Flex>
              </form>
            )}
          />
        </Card>
        <Notification />
      </Flex>
    </ThemeProvider>
  );
};

LoginPage.propTypes = {
  authClient: PropTypes.func,
  previousRoute: PropTypes.string,
  theme: PropTypes.object.isRequired,
};

LoginPage.defaultProps = {
  theme: {},
};

export default LoginPage;
