import React from "react";
// import { DataGrid } from "@material-ui/data-grid";
// import { columns, sortModel, sortModelDealer, columnsDealer } from "./";
import { AgGridReact } from "ag-grid-react";
// import numeral from "numeral";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { NumberParser2Decimals, lowerCaseComparator } from "../utils";
/**
 * export const columns = [
  { field: "country", headerName: "Country", width: 130 },
  { field: "dealer", headerName: "Dealer", width: 350 },
  { field: "category", headerName: "Category", width: 256 },
  { field: "target_setting", headerName: "Target Setting", width: 200 },
  { field: "packaging", headerName: "Packaging", width: 330 },
  { field: "invoiced", headerName: "Invoiced", width: 140, ...numberdesimal },
];

 */

const defaultFreeColumnDefsDealer = [
  {
    field: "category",
    headerName: "Category",
    width: 200,
    editable: false,
    resizable: true,
  },
  {
    field: "target_setting",
    headerName: "Target Setting",
    minWidth: 200,
    editable: false,
    resizable: true,
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },

  {
    field: "packaging",
    headerName: "Packaging",
    minWidth: 350,
    editable: false,
    resizable: true,
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },
  {
    field: "invoiced",
    headerName: "Invoiced",
    width: 130,
    editable: false,
    resizable: true,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
];
const defaultFreeColumnDefs = [
  {
    field: "country",
    headerName: "Country",
    width: 130,
    editable: false,
    resizable: true,
  },
  {
    field: "dealer",
    headerName: "Dealer",
    width: 200,
    editable: false,
    resizable: true,
  },
  {
    field: "category",
    headerName: "Category",
    width: 200,
    editable: false,
    resizable: true,
  },
  {
    field: "target_setting",
    headerName: "Target Setting",
    minWidth: 200,
    editable: false,
    resizable: true,
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },

  {
    field: "packaging",
    headerName: "Packaging",
    minWidth: 350,
    editable: false,
    resizable: true,
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },
  {
    field: "invoiced",
    headerName: "Invoiced",
    width: 130,
    editable: false,
    resizable: true,
    valueFormatter: NumberParser2Decimals,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
  },
];

export default (props) => {
  // const [pageSize, setPageSize] = React.useState(20);
  const { list, dealerId } = props;
  // console.log(list.length);
  const state = {
    columnDefs:
      dealerId !== null ? defaultFreeColumnDefsDealer : defaultFreeColumnDefs,
    defaultColDef: {
      flex: 1,
      editable: false,
      sortable: true,
      filter: true,
      // cellStyle: {
      //   "line-height": "40px",
      // },
    },
    getRowNodeId: function (data) {
      return data.category;
    },
  };

  // const onPageSizeChange = (e) => {
  //   // console.log(e);
  //   setPageSize(e.pageSize);
  // };
  // const heightContainer =
  //   list.length > pageSize
  //     ? pageSize * 55 + 110
  //     : list.length > 0
  //     ? pageSize * 55 + 110
  //     : 300;
  // const dataList =
  //   list && list.length > 0
  //     ? Array.from(
  //         new Set(
  //           list.map((p, index) => {
  //             return { ...p, id: index };
  //           })
  //         )
  //       )
  //     : [];
  // const sortModelDefault = dealerId !== null ? sortModelDealer : sortModel;
  // const columnDefaultDealer = dealerId !== null ? columnsDealer : columns;
  return (
    // <div style={{ height: heightContainer, width: "100%" }}>
    //   <DataGrid
    //     sortModel={sortModelDefault}
    //     rows={dataList}
    //     columns={columnDefaultDealer}
    //     pageSize={20}
    //     rowsPerPageOptions={[10, 20, 25, 50]}
    //     onPageSizeChange={onPageSizeChange}
    //   />
    // </div>
    <div className='grid-wrapper'>
      <div
        id='myGridTop20'
        className='ag-theme-balham'
        style={{ height: "50vh", width: "100%" }}
      >
        <AgGridReact
          rowHeight={40}
          headerHeight={40}
          rowData={list}
          columnDefs={state.columnDefs}
          defaultColDef={state.defaultColDef}
          animateRows={true}
        />
      </div>
    </div>
  );
};
