import React, { Fragment } from "react"
import { Box, Text, Flex } from "rebass"
import { get } from "lodash"
import LinearProgress from "@material-ui/core/LinearProgress"
import { useQueryWithStore } from "react-admin"
import IconButton from "@material-ui/core/IconButton"
import { EditIcon } from "../../components/icon_svg"
import { TrashPOIcon } from "../../components/icon_svg"
import { PromotionField } from "./customer"
import { StyledTableAction, StyledTableCell } from "./styles"

const EditProductItem = (props) => {
  const { item, onEditItem, classes, onRemoveItem, items } = props
  const { loaded, data } = useQueryWithStore({
    type: "getOne",
    resource: "products",
    payload: { id: get(item, "product_id", "") },
  })
  if (!loaded) {
    return (
      <Fragment>
        <StyledTableCell component="td" scope="row">
          <LinearProgress variant="query" />
        </StyledTableCell>
        <StyledTableCell component="td" scope="row">
          <LinearProgress variant="query" />
        </StyledTableCell>
      </Fragment>
    )
  }

  const myItem = {
    ...item,
    product: data,
  }

  return (
    <Fragment>
      <StyledTableCell component="td" scope="row">
        <Box>
          <Text>{get(data, "packaging", "")}</Text>
          <PromotionField record={data} source="name" resource_id="id" />
          {/* <Text style={{ color: "#FF0000" }} pt={[3]}>
            {get(item, "result.promotions[0].name", "Buy 12 Get 1 Free")}
          </Text> */}
        </Box>
      </StyledTableCell>
      <StyledTableCell component="td" scope="row">
        <Text>{get(data, "code", "")}</Text>
      </StyledTableCell>
      <StyledTableCell component="td" scope="row">
        <Text>{item.result.qty}</Text>
      </StyledTableCell>
      <StyledTableCell component="td" scope="row">
        <Text>{item.result.price}</Text>
      </StyledTableCell>
      <StyledTableCell component="td" scope="row">
        <Text>{item.result.subtotal}</Text>
      </StyledTableCell>
      <StyledTableAction component="td" scope="row">
        <Flex flexDirection={"row"} alignItems={"center"}>
          <IconButton
            className={classes.leftIcon}
            onClick={() => onRemoveItem(item, items)}
          >
            <TrashPOIcon fontSize="small" />
          </IconButton>

          <IconButton onClick={() => onEditItem(myItem)}>
            <EditIcon fontSize="small" />
          </IconButton>
          <Box width={"10px"} />
        </Flex>
      </StyledTableAction>
    </Fragment>
  )
}

export default EditProductItem
