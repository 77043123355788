import React from "react"
import PropTypes from "prop-types"
import Dropzone from "react-dropzone"
import Button from "@material-ui/core/Button"
import { Box } from "rebass"
import { filter } from "lodash"
import moment from "moment"
import { dataProduct } from "./utils"

const ButtonUploadCsv = (props) => {
  const {
    accept,
    disableClick,
    maxSize,
    minSize,
    multiple,
    translate,
    setData,
    period,
    notify,
  } = props

  const onDrop = (accepted, rejected) => {
    if (accepted.length > 0 && rejected.length === 0) {
      if (period !== "") {
        let formData = new FormData()
        accepted.forEach((file) => {
          formData.append("file", file, file.name)
        })

        const myData = filter(dataProduct, function (o) {
          return moment(o.created_at).format("YYYY-MM") === period
        })
        setData(myData)
      } else {
        notify(translate("resources.orders.messages.check_period"), "warning")
      }
    }
  }
  return (
    <Box p={[2]}>
      <Dropzone
        onDrop={onDrop}
        accept={accept}
        disableClick={disableClick}
        maxSize={maxSize}
        minSize={minSize}
        multiple={multiple}
      >
        {({ getRootProps, getInputProps }) => (
          <div>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Button
                variant={"contained"}
                color="primary"
                style={{ textTransform: "capitalize", borderRadius: 24 }}
              >
                {translate("resources.buttons.upload_file_csv")}
              </Button>
            </div>
          </div>
        )}
      </Dropzone>
    </Box>
  )
}

ButtonUploadCsv.propTypes = {
  accept: PropTypes.string,
  children: PropTypes.element,
  disableClick: PropTypes.bool,
  elStyle: PropTypes.object,
  input: PropTypes.object,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  multiple: PropTypes.bool,
  style: PropTypes.object,
  placeholder: PropTypes.node,
  updateField: PropTypes.func,
}

ButtonUploadCsv.defaultProps = {
  addLabel: false,
  addField: true,
  multiple: false,
  maxSize: 1000000,
  onUpload: () => {},
}

export default ButtonUploadCsv
