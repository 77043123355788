import React, { Component } from "react"
import PropTypes from "prop-types"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import FormHelperText from "@material-ui/core/FormHelperText"
import Switch from "@material-ui/core/Switch"
import Typography from "@material-ui/core/Typography"
import { addField, FieldTitle } from "react-admin"
import { Flex } from "rebass"
import { withStyles } from "@material-ui/core/styles"
import TextMessage from "./utils"
import { inputStyles } from "../../themes"
import sanitizeRestProps from "./sanitizeRestProps"

const styles = theme => {
  return {
    label: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: "bold",
      color: inputStyles.colorLabel,
      letterSpacing: inputStyles.letterSpacing,
    },
  }
}

export class BooleanInput extends Component {
  handleChange = (event, value) => {
    this.props.input.onChange(value)
  }

  render() {
    const {
      className,
      id,
      input,
      isRequired,
      label,
      source,
      resource,
      options,
      fullWidth,
      meta,
      notetext,
      classes,
      updateField,
      ...rest
    } = this.props

    const { value } = input

    return (
      <Flex flexDirection={"column"}>
        <FormGroup className={className} {...sanitizeRestProps(rest)}>
          <FormControlLabel
            htmlFor={id}
            control={
              <Switch
                id={id}
                checked={!!value}
                onChange={this.handleChange}
                {...options}
                color="primary"
              />
            }
            label={
              <Typography className={classes.label}>
                <FieldTitle
                  label={label}
                  source={source}
                  resource={resource}
                  isRequired={isRequired}
                />
              </Typography>
            }
          />
          {meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
        </FormGroup>
        {notetext && <TextMessage notetext={notetext} />}
      </Flex>
    )
  }
}

BooleanInput.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  resource: PropTypes.string,
  source: PropTypes.string,
  options: PropTypes.object,
  notetext: PropTypes.string,
  classes: PropTypes.object.isRequired,
}

BooleanInput.defaultProps = {
  options: {},
}

export default addField(withStyles(styles)(BooleanInput))
