import React, { Fragment } from "react";
import { usePermissions } from "react-admin";
import { ROLE_USER } from "../../utils";
import {
  // MenuOrders,
  MenuProducts,
  MenuAdmin,
  MenuProductsDealer,
  MenuCustomers,
  // MenuDealers,
  // MenuGCDealers,
  MenuAdminDealers,
  MenuCounrtyManager,
  MenuSettings,
} from "./contentmenus";

export const SuperAdminMenu = ({ onMenuClick, ...rest }) => {
  const { permissions } = usePermissions();
  // console.log(permissions)
  return (
    <div>
      {permissions === ROLE_USER.country_manager && (
        <Fragment>
          <MenuCounrtyManager onMenuClick={onMenuClick} {...rest} />
        </Fragment>
      )}
      {permissions === ROLE_USER.admin && (
        <Fragment>
          <MenuAdminDealers onMenuClick={onMenuClick} {...rest} />
          <MenuProducts onMenuClick={onMenuClick} {...rest} />
          <MenuAdmin onMenuClick={onMenuClick} {...rest} />
        </Fragment>
      )}
      {permissions === ROLE_USER.gc_sales_manager && (
        <Fragment>
          <MenuAdminDealers onMenuClick={onMenuClick} {...rest} />
          <MenuProducts onMenuClick={onMenuClick} {...rest} />
          <MenuSettings onMenuClick={onMenuClick} {...rest} />
        </Fragment>
      )}
      {permissions === ROLE_USER.gc_sales_associate && (
        <Fragment>
          <MenuAdminDealers onMenuClick={onMenuClick} {...rest} />
          <MenuProducts onMenuClick={onMenuClick} {...rest} />
          <MenuSettings onMenuClick={onMenuClick} {...rest} />
        </Fragment>
      )}
      {permissions === ROLE_USER.gc_oa_associate && (
        <Fragment>
          <MenuAdminDealers onMenuClick={onMenuClick} {...rest} />
          <MenuProducts onMenuClick={onMenuClick} {...rest} />
          <MenuSettings onMenuClick={onMenuClick} {...rest} />
        </Fragment>
      )}

      {permissions === ROLE_USER.dealer && (
        <Fragment>
          {/* <MenuOrders onMenuClick={onMenuClick} {...rest} /> */}
          <MenuProductsDealer onMenuClick={onMenuClick} {...rest} />
          {/* <MenuAdmin onMenuClick={onMenuClick} {...rest} /> */}
        </Fragment>
      )}
      {permissions === ROLE_USER.customer && (
        <Fragment>
          <MenuCustomers onMenuClick={onMenuClick} {...rest} />
        </Fragment>
      )}
    </div>
  );
};
