import React from "react";
import { Box } from "rebass";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import { sumBy } from "lodash";
import {
  // StyledTableAction,
  StyledTableCell,
  StyledTableRow,
  StyledTableSmall,
} from "./edit";
const TotalQuantity = (props) => {
  const { products } = props;
  const total_qty = sumBy(products, "qty");
  return (
    <Box width={[1]} pt={[2]}>
      <TableContainer component={"div"}>
        <Table aria-label='customized table'>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell
                style={{ textAlign: "left", paddingLeft: 30 }}
              ></StyledTableCell>
              <StyledTableSmall
                style={{ textAlign: "center", width: 130 }}
              ></StyledTableSmall>
              <StyledTableSmall
                style={{ textAlign: "right", width: 90 }}
              ></StyledTableSmall>
              <StyledTableSmall style={{ textAlign: "right", width: 150 }}>
                Total Qty
              </StyledTableSmall>
              <StyledTableSmall style={{ textAlign: "right", width: 80 }}>
                {total_qty}
              </StyledTableSmall>
              <StyledTableSmall
                style={{ textAlign: "right", width: 100 }}
              ></StyledTableSmall>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TotalQuantity;
