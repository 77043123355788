import React, { Fragment, useState } from "react";
import { StyledTableCell, StyledTableRow, StyledTableSmall } from "./styles";
import { Text } from "rebass";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TextField } from "@material-ui/core";
import { useGetMany, Loading } from "react-admin";
import { get, sortBy, map, find, toLower } from "lodash";

const TextInput = (props) => {
  const { item, index, onChangeItem, onBlurReceived } = props;
  const [count, setCount] = useState(get(item, "receive", 0));
  const onChangeReceived = (e) => {
    const result = item.received + e.target.value * 1;
    const myqty = item.qty;
    if (result <= myqty) {
      onChangeItem(e, index);
      setCount(e.target.value * 1);
    }
  };
  const onBlur = (e) => {
    // console.log(e.target.value, index);
    onBlurReceived(e.target.value * 1);
  };
  return (
    <TextField
      type={"number"}
      id={`received-${index}-detail`}
      name={`received-${index}-detail`}
      value={count}
      onChange={(e) => onChangeReceived(e)}
      onBlur={(e) => onBlur(e)}
      style={{
        width: 90,
        minWidth: 90,
        marginTop: -4,
        fontSize: 14,
        textAlign: "right",
      }}
    />
  );
};
const DialogGoodReceiptsContent = (props) => {
  const {
    ids,
    goodsReceipts,
    onChangeReceived,
    onBlurReceived,
    classes,
    translate,
  } = props;
  const { data, loading, error } = useGetMany("products", ids);
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>{error}</p>;
  }
  //   console.log("data", data);
  const mylist = map(goodsReceipts, function (o) {
    const product = find(data, function (pp) {
      return pp.id === o.product_id;
    });
    return {
      ...o,
      packaging: get(product, "packaging", ""),
      code: get(product, "code", ""),
    };
  });
  //   console.log("mylist", mylist);
  const myListProduct = sortBy(
    mylist,
    [
      function (o) {
        return toLower(o.packaging) && o.id;
      },
    ],
    ["asc"]
  );
  return (
    <Fragment>
      <TableContainer component={"div"}>
        <Table className={classes.table} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                {translate("resources.po.fields.product")}
              </StyledTableCell>
              <StyledTableCell>
                {translate("resources.po.fields.code")}
              </StyledTableCell>
              <StyledTableSmall>
                <Text textAlign={"center"}>
                  {translate("resources.po.fields.ordered")}
                </Text>
              </StyledTableSmall>
              <StyledTableSmall>
                {translate("resources.po.fields.invoiced")}
              </StyledTableSmall>
              <StyledTableSmall>
                {translate("resources.po.fields.received")}
              </StyledTableSmall>

              <StyledTableSmall>
                {translate("resources.po.fields.receive")}
              </StyledTableSmall>
            </TableRow>
          </TableHead>
          <TableBody>
            {myListProduct.length > 0 &&
              myListProduct.map((item, index) => {
                // console.log(item.received);
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell component='td' scope='row'>
                      <Text textAlign={"left"}>{item.packaging}</Text>
                      {/* <ProductField record={item} source='packaging' /> */}
                    </StyledTableCell>
                    <StyledTableCell component='td' scope='row'>
                      <Text textAlign={"left"}>{item.code}</Text>
                      {/* <ProductField record={item} source='code' /> */}
                    </StyledTableCell>
                    <StyledTableSmall component='td' scope='row'>
                      <Text textAlign={"center"}>{item.qty}</Text>
                    </StyledTableSmall>
                    <StyledTableSmall component='td' scope='row'>
                      <Text textAlign={"center"}>{item.invoiced}</Text>
                    </StyledTableSmall>
                    <StyledTableSmall component='td' scope='row'>
                      <Text textAlign={"center"}>{item.received}</Text>
                    </StyledTableSmall>
                    <StyledTableSmall component='td' scope='row'>
                      <TextInput
                        item={item}
                        index={index}
                        onChangeItem={(e, i) => onChangeReceived(e, i, item)}
                        onBlurReceived={(e) => onBlurReceived(e, index)}
                      />
                    </StyledTableSmall>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export default DialogGoodReceiptsContent;
