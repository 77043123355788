import React, { useState } from "react";
import { filter, map, uniqBy, flatten, orderBy } from "lodash";
import { Box, Flex } from "rebass";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { RestApiCURD } from "../../../../restClient";
import { ROLE_USER } from "../../../../utils";
// import { applyFilter } from "../utils";
export default (props) => {
  const {
    permissions,
    dataList,
    countries,
    targetSettings,
    dealers,
    categories,
    setList,
    valueCountry,
    setValueCountry,
    valueDealer,
    setValueDealer,
    myfilter,
    setMyFilter,
    valueCategory,
    setValueCategory,
    valueTargetSetting,
    setValueTargetSetting,
    isDealer,
    dealerId,
  } = props;
  const [state, setState] = useState({
    listCountries: countries,
    listDealers: dealers,
    listTargetSettings: targetSettings,
    listCategories: categories,
  });

  const onChangeCountries = (e, newValue) => {
    // console.log("change", newValue);
    setValueCountry([...newValue]);
    const paramsField = map(newValue, function (o) {
      return o.id;
    });
    // setMyFilter({
    //   ...myfilter,
    //   country_ids: paramsField,
    // });
    let newMyFilterObj = myfilter;
    if (valueDealer.length > 0 && newValue.length > 0) {
      const getDealerSelected = map(newValue, function (oCountry) {
        const newDealers = filter(valueDealer, function (oDealer) {
          return oCountry.id === oDealer.country_id;
        });
        return newDealers;
      });

      const newDealerSelected = filter(getDealerSelected, function (element) {
        return element !== undefined;
      });
      const myFlatDealer = flatten(newDealerSelected);
      setValueDealer(myFlatDealer);
      const paramsFieldDealer = map(myFlatDealer, function (o) {
        return o.id;
      });
      newMyFilterObj = {
        ...myfilter,
        country_ids: paramsField,
        dealer_ids: paramsFieldDealer,
      };
      setMyFilter({
        ...myfilter,
        country_ids: paramsField,
        dealer_ids: paramsFieldDealer,
      });
    } else {
      newMyFilterObj = {
        ...myfilter,
        country_ids: paramsField,
      };
      setMyFilter({
        ...myfilter,
        country_ids: paramsField,
      });
    }
    const newParams = { ...newMyFilterObj, country_ids: paramsField };
    getList(newParams);

    if (newValue.length > 0) {
      const newParams = map(newValue, function (o) {
        return o.id;
      });
      const newlistDealers = filter(dealers, function (i) {
        return newParams.some(function (f) {
          return i.country_id === f;
        });
      });
      setState({
        ...state,
        listDealers: newlistDealers,
      });
    } else {
      setState({
        ...state,
        listDealers: dealers,
      });
    }
  };
  const onChangeDealers = (e, newValue) => {
    setValueDealer([...newValue]);
    const paramsField = map(newValue, function (o) {
      return o.id;
    });
    setMyFilter({
      ...myfilter,
      dealer_ids: paramsField,
    });
    const newParams = { ...myfilter, dealer_ids: paramsField };
    getList(newParams);
  };
  const onChangeCategories = (e, newValue) => {
    // console.log("change", newValue);
    setValueCategory([...newValue]);
    const paramsField = map(newValue, function (o) {
      return o.id;
    });
    setMyFilter({
      ...myfilter,
      category_ids: paramsField,
    });
    const newParams = { ...myfilter, category_ids: paramsField };
    getList(newParams);

    if (newValue.length > 0) {
      // console.log(newValue);
      // const newParams = map(newValue, function (o) {
      //   return o.label;
      // });
      // const newListTarget = filter(list, function (i) {
      //   return newParams.some(function (f) {
      //     return i.category === f;
      //   });
      // });

      const lt = uniqBy(dataList, "target_setting");
      const newListTargetSetting = map(lt, function (oi) {
        return { label: oi.target_setting };
      });
      const newListTarget = filter(targetSettings, function (i) {
        return newListTargetSetting.some(function (f) {
          return i.label === f.label;
        });
      });
      // console.log(newListTarget);
      setState({
        ...state,
        listTargetSettings: newListTarget,
      });
    } else {
      setState({
        ...state,
        listTargetSettings: targetSettings,
      });
    }
  };
  const onChangeTargetSetting = (e, newValue) => {
    // console.log("change", newValue);
    setValueTargetSetting([...newValue]);
    const paramsField = map(newValue, function (o) {
      return o.id;
    });
    setMyFilter({
      ...myfilter,
      target_ids: paramsField,
    });
    const newParams = { ...myfilter, target_ids: paramsField };
    getList(newParams);
  };

  const onChangeTop = (e) => {
    // console.log(e.target.value);
    setMyFilter({
      ...myfilter,
      top: e.target.value * 1,
    });
  };
  const onBlurTop = (e) => {
    const newParams = { ...myfilter, top: e.target.value * 1 };
    // console.log(newParams);
    getList(newParams);
  };

  const getList = (params) => {
    // console.log(params);
    const paramDealerId = dealerId !== null ? `?dealer_id=${dealerId}` : "";
    RestApiCURD(`reports/top${paramDealerId}`, "POST", params)
      .then((resp) => resp.json())
      .then((response) => {
        // console.log(response);
        if (response && response.length > 0) {
          setList(response);
        } else {
          setList([]);
        }
      })
      .catch((e) => console.log(e.message));
  };
  const sortListTargetSettings = orderBy(
    state.listTargetSettings,
    [(product) => product.label.toLowerCase()],
    ["asc"]
  );
  return (
    <Flex width={[1]} flexDirection={"row"} flexWrap={"wrap"} pb={[2]}>
      {permissions !== ROLE_USER.country_manager &&
        permissions !== ROLE_USER.dealer && (
          <Box p={[1]} width={["400px"]}>
            {countries && countries.length > 0 && (
              <Autocomplete
                id='top20-countries-fields-filter'
                options={countries}
                multiple
                value={valueCountry}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) =>
                  onChangeCountries(event, newValue)
                }
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant='outlined'
                      label={option.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    variant='filled'
                    label='Country'
                    placeholder='country'
                  />
                )}
              />
            )}
          </Box>
        )}
      {!isDealer && (
        <Box p={[1]} width={["400px"]}>
          {state.listDealers && state.listDealers.length > 0 && (
            <Autocomplete
              id='top20-dealers-fields-filter'
              options={state.listDealers}
              multiple
              value={valueDealer}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => onChangeDealers(event, newValue)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant='outlined'
                    label={option.name}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  variant='filled'
                  label='Dealer'
                  placeholder='dealer'
                />
              )}
            />
          )}
        </Box>
      )}
      <Box p={[1]} width={["400px"]}>
        {categories && categories.length > 0 && (
          <Autocomplete
            id='top20-categories-fields-filter'
            options={categories}
            multiple
            value={valueCategory}
            getOptionLabel={(option) => option.label}
            onChange={(event, newValue) => onChangeCategories(event, newValue)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant='outlined'
                  label={option.label}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                variant='filled'
                label='Category'
                placeholder='category'
              />
            )}
          />
        )}
      </Box>
      <Box p={[1]} width={["400px"]}>
        {sortListTargetSettings && sortListTargetSettings.length > 0 && (
          <Autocomplete
            id='top20-targetSettings-fields-filter'
            options={sortListTargetSettings}
            multiple
            value={valueTargetSetting}
            getOptionLabel={(option) => option.label}
            onChange={(event, newValue) =>
              onChangeTargetSetting(event, newValue)
            }
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant='outlined'
                  label={option.label}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                variant='filled'
                label='Target Setting'
                placeholder=''
              />
            )}
          />
        )}
      </Box>
      <Box p={[1]} width={["400px"]}>
        <TextField
          InputLabelProps={{ shrink: true }}
          variant='filled'
          label='Top'
          placeholder=''
          type={"number"}
          value={myfilter.top}
          onChange={onChangeTop}
          onBlur={onBlurTop}
        />
      </Box>
    </Flex>
  );
};
