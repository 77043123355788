import React, { useState, useEffect } from "react";
import { Flex, Box } from "rebass";
import { withDataProvider } from "react-admin";
//Loading,
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import EmptyList from "./EmptyList";
import RowDealerTarget from "./RowDealerTarget";
import { getYearList, getFY } from "./utils";
import DealerField from "./DealerField";
import CountryFields from "./CountryFields";
import { LoadingRa } from "../../components";
const ListDealers = (props) => {
  const {
    translate,
    stateDealer,
    onSetStateDealer,
    dataProvider,
    fy,
    filter,
  } = props;
  const dataYear = getYearList();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  //dataProvider,
  // console.log(stateDealer);

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      dataProvider
        .getList("dealerTargets", {
          pagination: { page: 1, perPage: 100 },
          sort: { field: "id", order: "ASC" },
          filter: { financial_year: fy, ...filter },
        })
        .then(({ data }) => {
          // console.log(data);
          if (data) {
            setData(data);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }, 1000);

    return () => clearTimeout(timer);
  }, [fy, filter, dataProvider]);
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <Flex
        width={[1]}
        flexDirection={"row"}
        justifyContent='flex-end'
        alignItems={"center"}
        pb={[3]}
        px={[2]}
      >
        <Box pr={[3]}>
          <CountryFields
            handleChange={(v) => onSetStateDealer("country", v)}
            state={stateDealer}
            translate={translate}
          />
        </Box>
        {stateDealer.country !== "" && (
          <Box pr={[3]}>
            <DealerField
              handleChange={(v) => onSetStateDealer("dealer", v)}
              state={stateDealer}
              translate={translate}
            />
          </Box>
        )}
        <FormControl>
          <InputLabel id='dealer-date_year' style={{ minWidth: 80 }}>
            {translate("resources.targets.fields.fy")}
          </InputLabel>
          <Select
            labelId='dealer-date_year'
            id='dealer-date_year'
            value={stateDealer.fy}
            onChange={(e) => onSetStateDealer("fy", e.target.value)}
            inputProps={{ "aria-label": "Without label" }}
            style={{ width: 8, paddingLeft: 0 }}
          >
            {dataYear.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Flex>
      {data && data.length === 0 ? (
        <Box width={[1]}>
          <EmptyList
            {...props}
            title={"resources.targets.message.empty_list_dealer_target"}
            fy={getFY(stateDealer.fy)}
          />
        </Box>
      ) : loading ? (
        <LoadingRa size={"md"} />
      ) : (
        <RowDealerTarget data={data} translate={translate} />
      )}
    </Flex>
  );
};

export default withDataProvider(ListDealers);
