import { useEffect } from "react"
import { useSafeSetState } from "./hooks"
import { API_SERVER, TOKEN } from "../../restClient"

function request(url, options) {
  if (!options) {
    options = {}
  }

  if (!options.headers) {
    options.headers = new Headers({ "Content-Type": "application/json" })
  } else {
    options.headers.set("Content-Type", "application/json")
  }

  const token = localStorage.getItem(TOKEN)
  // console.log(token)
  if (token && token !== null) {
    options.headers.set("Authorization", `Bearer ${token}`)
  }
  // console.log(options)
  return fetch(`${API_SERVER}${url}`, options).then((response) =>
    response.json()
  )
}

export function useQuery(url, options) {
  const [state, setState] = useSafeSetState({
    data: undefined,
    error: null,
    loading: true,
    loaded: false,
  })
  useEffect(() => {
    setState((prevState) => ({ ...prevState, loading: true }))
    request(url, options)
      .then((response) => {
        const data = response
        setState({
          data,
          loading: false,
          loaded: true,
        })
      })
      .catch((error) => {
        setState({
          error,
          loading: false,
          loaded: false,
        })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify({ url, options }), request, setState])
  return state
}
