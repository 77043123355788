import Moment from "moment";
import { groupBy } from "lodash";
import { extendMoment } from "moment-range";
import { lowerCaseComparator } from "../utils";
const moment = extendMoment(Moment);

export const listColumnItem = [
  {
    field: "country",
    headerName: "Country",
    width: 130,
    editable: false,
    resizable: true,
  },
  {
    field: "dealer",
    headerName: "Dealer",
    width: 330,
    editable: false,
    resizable: true,
  },
  {
    field: "target_setting",
    headerName: "Target Setting",
    minWidth: 200,
    editable: false,
    resizable: true,
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },
];

export const listColumnItemDealer = [
  {
    field: "target_setting",
    headerName: "Target Setting",
    minWidth: 200,
    editable: false,
    resizable: true,
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },
];

// {
//     "country": "Indonesia",
//     "dealer": "PT ANUGERAH PHARMINDO LESTARI (COBRA)",
//     "target_setting": "G-aenial",
//     "month": "2020-10-01T00:00:00Z",
//     "qty": 55,
//     "amount": 2300,
//     "qty_gap": 0,
//     "amount_gap": 0
//   }

export const columns = [
  { field: "country", headerName: "Country", width: 130 },
  { field: "dealer", headerName: "Dealer", width: 350 },
  {
    field: "target_setting",
    headerName: "Target Setting",
    minWidth: 200,
    editable: false,
    resizable: true,
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },
];
export const columnsDealer = [
  {
    field: "target_setting",
    headerName: "Target Setting",
    minWidth: 200,
    editable: false,
    resizable: true,
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },
];
export const sortModelDealer = [
  {
    field: "target_setting",
    sort: "asc",
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },
];
export const sortModel = [
  {
    field: "country",
    sort: "asc",
  },
  {
    field: "dealer",
    sort: "asc",
  },
  {
    field: "target_setting",
    sort: "asc",
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },
];

export const getListOTD = (data) => {
  const list = groupBy(data, function (o) {
    return [o.target_setting, o.dealer, o.country];
  });
  // console.log(list);
  const toArray = Object.values(list);
  const newlist = toArray.map((item) => {
    let newItems = {};
    const monthDate = item.map((io) => {
      const test = moment(io.month);
      newItems[`amount-${test.format("MMM-YY")}`] = `${io.amount}`;
      newItems[`amount-qty-${test.format("MMM-YY")}`] = `${io.qty}`;
      // newItems[`amount_gap-${test.format("MMM-YY")}`] = `${io.amount_gap}`;
      // newItems[`amount_gap-qty-${test.format("MMM-YY")}`] = `${io.qty}`;
      // newItems[`amount-${test.format("MMM-YY")}`] = `${io.amount} - ${io.qty}`;
      // newItems[
      //   `amount_gap-${test.format("MMM-YY")}`
      // ] = `${io.amount_gap} - ${io.qty_gap}`;
      // newItems[`qty-${test.format("MMM-YY")}`] = io.qty;
      // newItems[`qty_gap-${test.format("MMM-YY")}`] = io.qty_gap;
      newItems[`country`] = io.country;
      newItems[`dealer`] = io.dealer;
      newItems[`target_setting`] = io.target_setting;
      return {
        month: io.month,
        amount: io.amount,
        qty: io.qty,
        country: io.country,
        dealer: io.dealer,
        target_setting: io.target_setting,
        // amount_gap: io.amount_gap,
        // qty_gap: io.qty_gap,
      };
    });
    return { myMonth: monthDate, ...newItems };
  });
  return newlist;
};

export { default as ListOTD } from "./ListOTD";
export { default as HeaderFilters } from "./HeaderFilters";
