import React from "react"
import { Text, Box } from "rebass"
import { get } from "lodash"
import { makeStyles } from "@material-ui/core/styles"
import LinearProgress from "@material-ui/core/LinearProgress"
import { useQuery } from "../../../components"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  progress: {
    width: 100,
  },
}))
const PromotionField = (props) => {
  const classes = useStyles()

  const { record, source, resource_id } = props

  const { loaded, data } = useQuery(
    `/products/${get(record, resource_id, "")}/promotions`
  )
  if (!loaded) {
    return (
      <Box p={[2]}>
        <LinearProgress variant="query" className={classes.progress} />
      </Box>
    )
  }
  // console.log("testing", data)
  // console.log("testing", record)
  return (
    <Box py={[2]}>
      {data &&
        data !== null &&
        data.length > 0 &&
        data.map((item, index) => {
          return (
            <Text fontSize={[0]} color={"red"} key={index} p={[1]}>
              {get(item, source, "")}
            </Text>
          )
        })}
    </Box>
  )
}

export default PromotionField
