import React from "react"
import { get } from "lodash"
import { Flex, Text } from "rebass"
import { Field } from "react-final-form"
import { NumberInput, ArrayInput, SelectInput } from "react-admin"
import { ThemeProvider } from "@material-ui/styles"
import { themeTableInput } from "../../../themes"
import SimpleFormIterator from "../SimpleFormIterator"
import { withStyles, createStyles } from "@material-ui/core/styles"
import { ArrayChipInput } from "../../"
import { dataPromotionProduct } from "../utils"
import {
  payloadDefault,
  payloadCategoriesDefault,
  payloadGroupDefault,
} from "./utils"

const mystyles = (theme) =>
  createStyles({
    root: {
      width: 400,
    },
  })

const CategoriesField = (props) => {
  const { translate, formData, updateField } = props
  const categories = get(formData, "criteria.percent.categories", [])
  const type = get(formData, "criteria.percent.type", "")
  return (
    <Flex
      flexDirection="column"
      style={{ display: type === "Category" ? "flex" : "none" }}
    >
      <Text
        fontSize={[0]}
        fontWeight={"bold"}
        style={{ color: "#616161", paddingTop: 16 }}
        pb={[2]}
      >
        {translate("resources.promotions.fields.categories")}
      </Text>
      <Field
        label={translate("resources.promotions.fields.categories")}
        name={`criteria.percent.categories`}
        resource="categories"
        component={ArrayChipInput}
        dataOptions={categories}
        optionText="name"
        updateField={updateField}
        payload={payloadCategoriesDefault}
        styleText={{
          width: 500,
        }}
      />
    </Flex>
  )
}
// const CategoriesField = (props) => {
//   const { translate, formData, updateField } = props
//   const categories = get(formData, "criteria.percent.categories", [])
//   const type = get(formData, "criteria.percent.type", "")
//   return (
//     <Flex
//       flexDirection="column"
//       style={{ display: type === "Category" ? "flex" : "none" }}
//     >
//       <Text
//         fontSize={[0]}
//         fontWeight={"bold"}
//         style={{ color: "#616161", paddingTop: 16 }}
//         pb={[2]}
//       >
//         {translate("resources.promotions.fields.categories")}
//       </Text>
//       <Field
//         label={translate("resources.promotions.fields.categories")}
//         name={`criteria.percent.categories`}
//         resource="categories"
//         component={ArrayChipInput}
//         dataOptions={categories}
//         optionText="name"
//         updateField={updateField}
//         payload={payloadCategoriesDefault}
//         styleText={{
//           width: 500,
//         }}
//       />
//     </Flex>
//   )
// }

const PercentAmount = (props) => {
  const { translate, formData, updateField, isCreate, ...rest } = props
  const products = get(formData, "criteria.percent.products", [])
  const groups = get(formData, "criteria.percent.groups", [])
  const type = get(formData, "criteria.percent.type", "")
  // console.log(type, categories)
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <SelectInput
        source="criteria.percent.type"
        label={"resources.promotions.fields.criteria_type"}
        choices={dataPromotionProduct}
        style={{ width: 180 }}
      />

      <Flex
        flexDirection="column"
        style={{ display: type === "Product" ? "flex" : "none" }}
      >
        <Text
          fontSize={[0]}
          fontWeight={"bold"}
          style={{ color: "#616161", paddingTop: 16 }}
          pb={[2]}
        >
          {translate("resources.promotions.fields.products")}
        </Text>
        <Field
          label={translate("resources.promotions.fields.products")}
          name={`criteria.percent.products`}
          resource="products"
          component={ArrayChipInput}
          dataOptions={products}
          optionText="packaging"
          updateField={updateField}
          payload={payloadDefault}
          styleText={{
            width: 500,
          }}
        />
      </Flex>
      <CategoriesField {...props} />
      <Flex
        flexDirection="column"
        style={{ display: type === "Group" ? "flex" : "none" }}
      >
        <Text
          fontSize={[0]}
          fontWeight={"bold"}
          style={{ color: "#616161", paddingTop: 16 }}
          pb={[2]}
        >
          {translate("resources.promotions.fields.groups")}
        </Text>
        <Field
          label={translate("resources.promotions.fields.groups")}
          name={`criteria.percent.groups`}
          resource="groups"
          component={ArrayChipInput}
          dataOptions={groups}
          optionText="name"
          updateField={updateField}
          payload={payloadGroupDefault}
          styleText={{ width: 500 }}
        />
      </Flex>
      <ThemeProvider theme={themeTableInput}>
        <Flex width={[1]} flexDirection={"column"}>
          <ArrayInput source="criteria.percent.discounts" {...rest} label={""}>
            <SimpleFormIterator defaultValue={{ qty: 1, discount: 1 }}>
              <NumberInput
                source="qty"
                style={{ width: 90, margin: 0 }}
                label={"resources.promotions.fields.h_qty"}
              />
              <NumberInput
                source="discount"
                style={{ width: 90, margin: 0 }}
                label={"resources.promotions.fields.discount"}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Flex>
      </ThemeProvider>
    </Flex>
  )
}

// export default PercentAmount
export default withStyles(mystyles)(PercentAmount)
