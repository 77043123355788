import React, { useState } from "react";
import { get } from "lodash";
import { Flex, Text } from "rebass";
import { useHistory } from "react-router-dom";
import { useQueryWithStore, withDataProvider, useNotify } from "react-admin";
import DealerView from "./DealerView";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RestApi } from "../../../restClient";
import { ListOrdersView } from "../../../po/components/promotions";
import { STATUSPO } from "../../../utils";
const MainView = (props) => {
  const { record, translate } = props;
  const notify = useNotify();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { data } = useQueryWithStore({
    type: "getOne",
    resource: "purchaseOrder",
    payload: { id: get(record, "purchase_order_id", 0) },
  });
  // console.log(data);
  // console.log(record);
  const status = get(data, "status", "");
  const handlePostToGC = () => {
    setLoading(true);
    if (data && data.id) {
      // RestApi(`/orders/${record.id}/postToGP`)
      RestApi(`/purchaseOrders/${data.id}/postToGP`)
        // .then((response) => response.json())
        .then((data) => {
          notify("resources.notification.updated", "success", {
            smart_count: 1,
          });
          setLoading(false);
          history.push(`/orders/${record.id}`);
        })
        .catch((e) => {
          setLoading(false);
        });
    } else {
      notify("resources.orders.messages.order_cannot_post", "warning");
      setLoading(false);
    }
  };
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <DealerView {...props} po={data} notify={notify} />
      <Flex width={[1]} flexDirection={"column"} p={[1]}>
        <Paper elevation={3}>
          <Flex width={[1]} flexDirection={"column"} p={[3]}>
            <Text color={"green"} pb={[3]}>
              {translate("resources.orders.fields.order_items")}
            </Text>

            {data && <ListOrdersView formData={data} />}
            {status === STATUSPO.sent && (
              <Flex
                width={[1]}
                flexDirection={"row"}
                justifyContent={"flex-end"}
                p={[3]}
              >
                <Button
                  color={"primary"}
                  onClick={handlePostToGC}
                  variant={"contained"}
                  style={{ borderRadius: 24, textTransform: "capitalize" }}
                  startIcon={
                    loading ? (
                      <CircularProgress
                        color='secondary'
                        style={{ width: 16, height: 16 }}
                      />
                    ) : null
                  }
                >
                  {translate("resources.buttons.post_to_gp")}
                </Button>
              </Flex>
            )}
          </Flex>
        </Paper>
      </Flex>
    </Flex>
  );
};

export default withDataProvider(MainView);
