import React from "react";
import { Flex } from "rebass";
import { filter, includes, map, isNaN, get } from "lodash";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { Button } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import numeral from "numeral";
// import NumberFormat from "react-number-format";
const moment = extendMoment(Moment);

export const NumberParserPercent = (params) => {
  const myValue = get(params, "value", "0.00");
  if (myValue === 0) {
    return "0.00";
  }
  let with2Decimals = myValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
  return `${numeral(with2Decimals).format("0,0.00")}%`;
};
export const NumberParser2Decimals = (params) => {
  //note: this function not round up the value
  const myValue = get(params, "value", "0.00");
  if (myValue === 0) {
    return "0.00";
  }

  let with2Decimals = myValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
  // console.log(myValue, with2Decimals, numeral(with2Decimals).format("0,0.00"));
  // return with2Decimals;
  return numeral(with2Decimals).format("0,0.00");
};
export const NumberParser2DecimalsWithDollar = (params) => {
  //note: this function not round up the value
  const myValue = get(params, "value", "0.00");
  if (myValue === 0) {
    return "$0.00";
  }

  let with2Decimals = myValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
  // console.log(myValue, with2Decimals, numeral(with2Decimals).format("0,0.00"));
  // return with2Decimals;
  return `$${numeral(with2Decimals).format("0,0.00")}`;
};

export const NumberParser2DecimalsWithMinus = (params) => {
  //note: this function not round up the value
  const myValue = get(params, "value", "0.00");
  if (myValue === 0) {
    return "0.00";
  }

  let with2Decimals = myValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
  // console.log(myValue, with2Decimals, numeral(with2Decimals).format("0,0.00"));
  // return with2Decimals;
  if (with2Decimals < 0) {
    const convertValue = Math.abs(with2Decimals * 1);
    return `(${numeral(convertValue).format("0,0.00")})`;
  }
  return numeral(with2Decimals).format("0,0.00");
};

export const numberdesimal = {
  type: "number",
  valueFormatter: ({ value }) => {
    // console.log(value);
    return numeral(value * 1).format("0,0");
    // return value.toFixed(2);
  },
  cellClassName: "font-tabular-nums",
};

export const NumberAmount = (params) => {
  const myValue = params.value * 1;
  const myParams = isNaN(myValue) ? 0 : myValue;
  if (myParams % 1 !== 0) {
    // console.log(myParams, numeral(myParams).format("0,0.00"));
    return numeral(myParams).format("0,0.00");
  }
  return numeral(myParams).format("0,0.00");
};

export const DateString = (data) => {
  if (data && data.value && data.value !== "") {
    return moment(data.value).format("DD-MMM-YY");
  }
  return data.value;
};

export const getAmount = (value) => {
  const myValue = value * 1;
  const myParams = isNaN(myValue) ? 0 : myValue;
  if (myParams % 1 !== 0) {
    // console.log(numeral(myParams).format("0,0.00"));
    return numeral(myParams).format("0,0.00");
  }
  return numeral(myParams).format("0,0.00");
};

export const getAmount2DecimalValue = (value) => {
  const myValue = value * 1;
  const myParams = isNaN(myValue) ? 0 : myValue;
  // if (myParams % 1 !== 0) {
  //   // console.log(numeral(myParams).format("0,0.00"));
  //   return numeral(myParams).format("0.00");
  // }
  // console.log(isInt(myParams), numeral(myParams).format("0.00"));

  return numeral(myParams).format("0,0.00");
};

export const getAmountValue = (value) => {
  const myValue = value * 1;
  const myParams = isNaN(myValue) ? 0 : myValue;
  if (myParams % 1 !== 0) {
    // console.log(numeral(myParams).format("0,0.00"));
    return numeral(myParams).format("0.00");
  }
  return myParams; //numeral(myParams).format("0,0");
};

export const NumberAmountValue = (value) => {
  return numeral(value * 1).format("0,0");
};

export const getMonthList = (rangeDate) => {
  const range = moment.range(rangeDate.start, rangeDate.end);
  const arrayMonth = Array.from(range.by("month"));

  const myMonth = map(arrayMonth, function (o) {
    return moment(o).format("MMM-YY");
  });
  return myMonth;
};

export const applyFilter = (data, filter1) => {
  let filter2 = {};
  filter(filter1, function (v, k) {
    const param = v.length > 0 ? { [k]: v } : {};
    filter2 = { ...filter2, ...param };
    return v.length > 0;
  });

  const listFilter = filter(data, function (obj) {
    const co = Object.entries(filter2).every(([prop, find]) =>
      includes(find, obj[prop])
    );
    return co;
  });
  return listFilter;
};

export const ExportCSVFile = ({ list, translate, onExportCsv }) => {
  return (
    <Flex
      width={[1]}
      flexDirection={"row"}
      justifyContent={"flex-end"}
      pb={[2]}
    >
      <Button
        variant='contained'
        color='primary'
        disabled={list.length === 0}
        startIcon={<CloudDownloadIcon />}
        style={{ textTransform: "capitalize" }}
        onClick={onExportCsv}
      >
        {translate("resources.buttons.export_csv")}
      </Button>
    </Flex>
  );
};

export const lowerCaseComparator = (t1, t2) => {
  return t1.toLowerCase().localeCompare(t2.toLowerCase());
};
