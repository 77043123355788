export default {
  contents: {
    name: "Contents",
    fields: {
      title: "Title",
      active: "Active",
      site_id: "Site",
      slug: "Slug",
      tags: "Tags",
    },
    note_text: {
      slug: "Content Url",
      tags:
        "Tag your page such as Info, FAQ, to help to group and sort the page",
    },
  },
}
