import { createMuiTheme } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

import { themeMui, inputStyles } from "../../themes";

export const themeRaTA = createMuiTheme({
  ...themeMui,
  overrides: {
    MuiFormControl: {
      marginDense: {
        marginTop: 16,
      },
    },
    MuiFormLabel: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        color: inputStyles.colorLabel,
        fontWeight: "900",
        transform: "none",
        "&$focused": {
          color: inputStyles.colorLabel,
          backgroundColor: "transparent",
          transform: "none",
        },
      },
    },
    MuiInputLabel: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        fontSize: 16,
        color: inputStyles.colorLabel,
        minWidth: 180,
        fontWeight: "900",
      },
      filled: {
        letterSpacing: inputStyles.letterSpacing,
        minWidth: 180,
        fontWeight: "900",
        "&$shrink": {
          fontSize: 16,
          "&$marginDense": {
            transform: "translate(0px, 6px) scale(0.75)",
          },
        },
        "&$marginDense": {
          fontSize: 16,
          transform: "translate(0px, 17px) scale(1)",
        },
      },
    },
    MuiInput: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        minWidth: 180,
      },
    },
    MuiFilledInput: {
      root: {
        letterSpacing: inputStyles.letterSpacing,
        backgroundColor: "transparent",
        "&$focused": {
          backgroundColor: "transparent",
        },
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
      input: {
        letterSpacing: inputStyles.letterSpacing,
        padding: 0,
        fontSize: 14,
        backgroundColor: grey[300],
        minHeight: 100,
      },
      multiline: {
        letterSpacing: inputStyles.letterSpacing,
        padding: 0,
      },
      inputMultiline: {
        letterSpacing: inputStyles.letterSpacing,
        padding: 2,
        fontSize: 14,
      },
      underline: {
        borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
        "&$focused": {
          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
          "&::before": {
            borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
          },
        },
        "&:hover": {
          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
          "&::before": {
            borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
          },
        },
        "&::before": {
          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
        },
        "&::after": {
          borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
        },
      },
    },
  },
});
