export default {
  po: {
    title: "Purchase Orders",
    edit_po: "Edit Purchase Order",
    new_po: "New Purchase Order",
    add_product_to_order: "Add Product to Order",
    tabs: {
      order_items: "Order Items",
      good_receipts: "Goods Receipts",
    },
    fields: {
      po_number: "PO Number",
      date: "Date",
      status: "Status",
      total: "Total",
      created_by: "Created By",
      created_on: "Created On",
      delivery_date: "Delivery Date",
      product: "Product",
      promotion: "Promotion",
      code: "Code",
      unit_price: "Unit Price",
      moq: "MOQ",
      qty: "Qty",
      quantity: "Quantity",
      price: "Price",
      subtotal: "Subtotal",
      remarks: "Remarks",
      order_items: "Order Items",
      order_cart: "Order Cart",
      batch_on_goods_receipts: "Batch Received on",
      ordered: "Ordered",
      received: "Received",
      category: "Category",
      free_items: "Free Items",
      ref_number: "My Reference Number",
      amount: "Amount",
      my_ref: "My Reference",
      gc_ref: "GC Reference",
      receive: "Receive",
      hours: "Hours",
      total_backorder: "Total Backorder",
      total_ordered: "Total Ordered",
      total_received: "Total Received",
      country_remarks: "Country Remarks",
      invoiced: "Invoiced",
      total_balance: "Total Balance",
    },
    table: {
      packaging: "Product Name",
      code: "Product Code",
      price: "Price",
      qty: "Qty",
      subtotal: "Subtotal",
      target: "Target",
      growth: "Growth",
      speed: "Speed",
      order_intake: "Order Intake",
      po_amt: "PO Amt",
      ytd: "YTD",
      mtd: "MTD",
      qtd: "QTD",
      mth: "/Mth T",
      qt: "/Q T",
      yt: "/Y T",
      backorder: "Backorder",
      invoice_sales: "Invoice Sales",
      total: "Total",
      discounted_price: "Discounted Price",
    },
    listgrid: {
      total_order_qty: "Total Order Qty",
      total_order_amt: "Total Order Amt",
      this_promo_order_qty: "This Promo Order Qty",
      this_promo_order_amt: "This Promo Order Amt",
      this_order_qty: "This Order Qty",
      this_order_amt: "This Order Amt",
      free: "FREE",
      this_promo_free_qty: "This Promo FREE Qty",
      total_saving: "Total Saving",
      add_to_order: "Add to Order",
      label: {
        subgroup: "Sub Group",
        group: "Product Group",
        recency: "New/Existing Product",
        category: "Product Category",
        product_packaging: "Product Packaging",
        product_name: "Product Name",
        growth: "Growth/Speed Product",
        valid_from: "Valid from",
        to: "to",
        promo_items_form: "Promotional Items Form",
        promo_items: "Promotional Items",
        normal_item_price: "Non-Promo Order Form",
        item: "Item",
        items: "Items",
        entitled: "Entitled",
        selected: "Selected",
        remaining: "Remaining",
        subtotal: "Subtotal",
        total: "Total",
        shipping: "shipping",
        tax_10: "Tax 10%",
        tax: "Tax ",
        package_charge: "Packing Charge",
      },
    },
    note_text: {
      empty_good_receipts: "You haven't received any delivery from this PO yet",
      empty_order_item: "No product in the order list yet.",
    },
    messages: {
      no_have_items: "You don’t have any item in the list yet",
      product_qty: "Invalid product or Qty",
      orderItemsEmpty: "Please add product to Order Items",
      create_order: "Create Purchase Order Not Success",
      create_order_items: "Create Order Items Not Success",
      product_added: "Product added",
      element_created: "Element Created",
      element_updated: "Element Updated",
      error_create_goods_receipts: "Create Goods Receipts Not Success",
      error_empty_item_goods_receipts: "Did you receive the goods?",
      order_qty_min: "This product has MOQ of",
      order_qty_min1: ". Please increase your order qty.",
      expired_promotions:
        "Some of the Promotions have expired. Items will be removed from Order Form",
      order_submited: "Order Submited",
      inactive_item_promotions: "Some of the Promotions have expired.",
    },
  },
};
