import React from "react";
import { get, filter } from "lodash";
import { Edit, Toolbar, usePermissions, Loading } from "react-admin";
import { BetterCustomPromotionForm } from "./";
import EditFormFields from "./EditFormFields";
import { SavePromotionButton, SaveCancelPromotionButton } from "../components";
import { showApprove } from "../../utils";
import { Flex } from "rebass";
import { useQuery } from "react-query";
import { getProducts } from "../../restClient";
export const statusList = {
  waiting: "Awaiting For Approval",
  approved: "Approved",
  rejected: "Rejected",
  cancelled: "Cancelled",
  new: "New",
  draft: "Draft",
};

const cacheTimeMinute = 1000 * 60 * 30;

const EditToolbar = (props) => {
  const { updateField, ...rest } = props;
  // console.log(dataProducts);

  const { permissions } = usePermissions();
  // console.log(rest);
  const status = get(rest, "record.status", "");
  return (
    <Toolbar {...rest}>
      {status !== "" &&
        status !== statusList.approved &&
        status !== statusList.rejected &&
        status !== statusList.cancelled && (
          <SavePromotionButton
            label={"resources.buttons.save"}
            redirect={props.redirect}
            updateField={updateField}
            style={{ marginLeft: 16, textTransform: "capitalize" }}
          />
        )}
      {status !== "" &&
        status !== statusList.waiting &&
        status !== statusList.approved &&
        status !== statusList.rejected &&
        status !== statusList.cancelled && (
          <SavePromotionButton
            label={"resources.buttons.submit_for_approval"}
            redirect={props.redirect}
            updateField={updateField}
            style={{ marginLeft: 16, textTransform: "capitalize" }}
          />
        )}
      {status !== "" &&
        status !== statusList.approved &&
        status !== statusList.rejected &&
        status !== statusList.cancelled &&
        !showApprove(permissions) && (
          <SaveCancelPromotionButton
            label={"resources.buttons.cancel_promotion"}
            redirect={props.redirect}
            updateField={updateField}
            style={{
              marginLeft: 16,
              textTransform: "capitalize",
              backgroundColor: "#FF0000",
            }}
          />
        )}
      {status !== "" &&
        status === statusList.waiting &&
        showApprove(permissions) && (
          <SavePromotionButton
            label={"resources.buttons.approve"}
            redirect={props.redirect}
            updateField={updateField}
            style={{ marginLeft: 16, textTransform: "capitalize" }}
          />
        )}
      {status !== "" &&
        status === statusList.waiting &&
        showApprove(permissions) && (
          <SavePromotionButton
            label={"resources.buttons.reject"}
            redirect={props.redirect}
            updateField={updateField}
            style={{
              marginLeft: 16,
              textTransform: "capitalize",
              backgroundColor: "#FF0000",
            }}
          />
        )}
      {status !== "" && status === statusList.approved && (
        <SavePromotionButton
          label={"resources.buttons.save"}
          redirect={props.redirect}
          updateField={updateField}
          style={{ marginLeft: 16, textTransform: "capitalize" }}
        />
      )}
    </Toolbar>
  );
};

const EditDealerPromotion = (props) => {
  const { isLoading, data } = useQuery(
    "productsListNormal",
    () => getProducts(),
    { cacheTime: cacheTimeMinute }
  );
  if (isLoading) {
    return (
      <Flex width={[1]} justifyContent={"center"} alignItems={"center"}>
        <Loading />
      </Flex>
    );
  }
  // console.log("products", data);
  // const dataProducts = useQuery(`/products`);
  const dataProducts = filter(data, function (o) {
    return o.active;
  });
  return (
    <Edit {...props}>
      <BetterCustomPromotionForm
        {...props}
        toolbar={<EditToolbar />}
        sourceApi='promotions'
        title={"resources.promotions.title.edit_promotion"}
        typePostUrl={"UPDATE"}
        pathUrl='promotions'
        customBackUrl='/promotions'
        dataProducts={dataProducts}
      >
        <EditFormFields
          {...props}
          isCreate={false}
          dataProducts={dataProducts}
        />
      </BetterCustomPromotionForm>
    </Edit>
  );
};

export default EditDealerPromotion;
