import React, { useState } from "react"
import { withStyles } from "@material-ui/core/styles"
import { useTranslate } from "react-admin"
import { styles } from "./styles"
import SubMenuItem from "./SubMenuItem"
import MenuItemLink from "../MenuItemLink"
import { ViewIcon } from "../../icon_svg"

const MenuMarketing = (props) => {
  const { classes, onMenuClick } = props
  const [openMenu, setOpenMenu] = useState(false)
  const translate = useTranslate()
  return (
    <SubMenuItem
      handleToggle={() => setOpenMenu(!openMenu)}
      isOpen={openMenu}
      classes={classes}
      sidebarIsOpen={openMenu}
      name="resources.menus.marketing"
      icon={<ViewIcon />}
    >
      <MenuItemLink
        to={`/promotions`}
        primaryText={translate(`resources.menus.promotions`, {
          smart_count: 2,
        })}
        onClick={onMenuClick}
        translate={translate}
      />
      <MenuItemLink
        to={`/banners`}
        primaryText={translate(`resources.menus.banners`, {
          smart_count: 2,
        })}
        onClick={onMenuClick}
        translate={translate}
      />
    </SubMenuItem>
  )
}

export default withStyles(styles)(MenuMarketing)
