export default {
  notification: {
    title: "Notifications",
    please_try_again: "Please try again",
    updated: "Element updated",
    add_reward: "Reward added",
    required: "Required",
    no_found: "Not Found",
  },
};
