import React, { useState, useEffect } from "react";
import { Flex } from "rebass";
import GCHeader from "./GCHeader";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { filter } from "lodash";
import { RestApiCURD } from "../../restClient";
import { getStartEndTime, reportTypeValue, getStatus } from "./utils";
import POSummaryList from "./POSummaryList";
import PODetailList from "./PODetailList";
import SOSList from "./SOSList";
import SODList from "./SODList";
import InvoiceDetailList from "./InvoiceDetailList";
import InvoiceSummaryList from "./InvoiceSummaryList";
import ReportExportCsvFile from "./ReportExportCsvFile";
const moment = extendMoment(Moment);
const endMonth = moment();
const startMonth = moment(endMonth).add(-1, "month");

const GCReport = (props) => {
  // console.log(props);
  const { dataDealers } = props;
  const [isRequest, setIsRequest] = useState(true);
  const [dealers, setDealers] = useState(dataDealers);
  const [startTimeV, setStartTimeV] = useState(startMonth);
  const [endTimeV, setEndTimeV] = useState(endMonth);
  const [state, setState] = useState({
    valueRT: "poSummary",
    valueDealer: 0,
    valueCountry: 0,
    valueStatus: "All",
    start_time: startMonth,
    end_time: endMonth,
    dateRange: "All",
  });
  const [list, setList] = useState([]);

  useEffect(() => {
    if (isRequest) {
      const status = state.valueStatus === "All" ? "" : state.valueStatus;
      const start_end_time = getStartEndTime(
        state.dateRange,
        startTimeV,
        endTimeV
      );
      const paramsStatus = getStatus(state.valueRT, status);
      // console.log("start_end_time", start_end_time);
      let params = {
        dealer_id: state.valueDealer,
        country_id: state.valueCountry,
        ...paramsStatus,
        ...start_end_time,
      };

      RestApiCURD(`reports2/${state.valueRT}`, "POST", params)
        .then((resp) => resp.json())
        .then((response) => {
          if (response) {
            if (response.message) {
              setList([]);
            } else {
              setList(response);
            }
          }
          // console.log("response", response);
          setIsRequest(false);
        })
        .catch((e) => {
          setList([]);
          setIsRequest(false);
          console.log("error", e);
        });
    }
  }, [isRequest, state, startTimeV, endTimeV]);

  // const onFetchData = () => {
  //   const status = state.valueStatus === "All" ? "" : state.valueStatus;
  //   const start_end_time = getStartEndTime(
  //     state.dateRange,
  //     startTimeV,
  //     endTimeV
  //   );
  //   const paramsStatus = getStatus(state.valueRT, status);
  //   // console.log("start_end_time", start_end_time);
  //   let params = {
  //     dealer_id: state.valueDealer,
  //     country_id: state.valueCountry,
  //     ...paramsStatus,
  //     ...start_end_time,
  //   };

  //   RestApiCURD(`reports2/${state.valueRT}`, "POST", params)
  //     .then((resp) => resp.json())
  //     .then((response) => {
  //       if (response) {
  //         if (response.message) {
  //           setList([]);
  //         } else {
  //           setList(response);
  //         }
  //       }
  //       // console.log("response", response);
  //       setIsRequest(false);
  //     })
  //     .catch((e) => {
  //       setList([]);
  //       setIsRequest(false);
  //       console.log("error", e);
  //     });
  // };
  const setValue = (k, v) => {
    // console.log(k, v);
    if (k === "valueRT" && v !== state.valueRT) {
      setState({
        ...state,
        [k]: v,
        valueStatus: "All",
      });
    } else {
      setState({
        ...state,
        [k]: v,
      });
    }
    if (k === "valueCountry" && v !== state.valueCountry) {
      if (v !== 0) {
        const filterList = filter(dataDealers, function (o) {
          return o.country_id === v;
        });
        setDealers(filterList);
      } else {
        setDealers(dataDealers);
      }
      setState({
        ...state,
        [k]: v,
        valueDealer: 0,
      });
    } else {
      setState({
        ...state,
        [k]: v,
      });
    }
    if (k === "start_time") {
      setStartTimeV(v);
    } else if (k === "end_time") {
      setEndTimeV(v);
    }
    setIsRequest(true);
  };

  // console.log("dealer", dataDealers);
  return (
    <Flex width={[1]} flexDirection={"column"}>
      <GCHeader
        {...props}
        setValue={(k, v) => setValue(k, v)}
        state={state}
        dealers={dealers}
      />
      <ReportExportCsvFile list={list} reportType={state.valueRT} />
      {/* <Box width={[1]} p={[3]}>
        {JSON.stringify(state)}
        {list.length}
      </Box> */}
      {state.valueRT === reportTypeValue.pos && <POSummaryList list={list} />}
      {state.valueRT === reportTypeValue.pod && <PODetailList list={list} />}
      {state.valueRT === reportTypeValue.sos && <SOSList list={list} />}
      {state.valueRT === reportTypeValue.sod && <SODList list={list} />}
      {state.valueRT === reportTypeValue.is && (
        <InvoiceSummaryList list={list} />
      )}
      {state.valueRT === reportTypeValue.id && (
        <InvoiceDetailList list={list} />
      )}
    </Flex>
  );
};

export default GCReport;
