import React from "react";
import { Toolbar } from "react-admin";
import { get } from "lodash";
import { SaveSelloutButton } from "../../components";

export const FooterSelloutToolbar = (props) => {
  const { updateField, isEdit, ...rest } = props;
  const isShow = get(rest, "record.status", "") === "Draft";
  if (!isShow && isEdit) {
    return <div />;
  }
  return (
    <Toolbar {...rest}>
      <SaveSelloutButton
        label={"resources.buttons.save_as_draft"}
        redirect={props.redirect}
        updateField={updateField}
        style={{
          marginLeft: 16,
          borderRadius: 24,
          textTransform: "capitalize",
          paddingLeft: 48,
          paddingRight: 48,
        }}
      />

      <SaveSelloutButton
        label={"resources.buttons.submit"}
        redirect={props.redirect}
        updateField={updateField}
        style={{
          marginLeft: 16,
          borderRadius: 24,
          textTransform: "capitalize",
          paddingLeft: 48,
          paddingRight: 48,
        }}
      />
    </Toolbar>
  );
};
