import React from "react";
import { Fragment } from "react";
import { useTranslate } from "react-admin";
import { Flex, Box, Text } from "rebass";
export default (props) => {
  const { qtyTotalFreeC, qtySelectedC } = props;
  //, qtyTotalFreeD, qtySelectedD
  const translate = useTranslate();
  return (
    <Fragment>
      <Flex
        width={[1]}
        flexDirection={"row"}
        sx={{
          "&>div": {
            px: [1],
          },
        }}
      >
        <Box width={["80px"]}>
          <Text fontSize={[0]}>
            {translate("resources.po.listgrid.label.entitled")}
          </Text>
        </Box>
        <Box width={["80px"]}>
          <Text fontSize={[0]}>
            {translate("resources.po.listgrid.label.selected")}
          </Text>
        </Box>
        <Box width={["80px"]}>
          <Text fontSize={[0]}>
            {translate("resources.po.listgrid.label.remaining")}
          </Text>
        </Box>
      </Flex>
      <Flex
        width={[1]}
        flexDirection={"row"}
        my={[1]}
        sx={{
          "&>div": {
            px: [1],
            // backgroundColor: "#33bfff",
          },
        }}
      >
        <Box width={["80px"]}>
          <Text color={"red"}>{qtyTotalFreeC}</Text>
        </Box>
        <Box width={["80px"]}>
          <Text>{qtySelectedC}</Text>
        </Box>
        <Box width={["80px"]}>
          <Text style={{ color: "#0923d9" }}>
            {qtyTotalFreeC - qtySelectedC}
          </Text>
        </Box>
      </Flex>
      {/* <Flex
        width={[1]}
        mb={[1]}
        flexDirection={"row"}
        sx={{
          "&>div": {
            px: [1],
            backgroundColor: "#33c9dc",
          },
        }}
      >
        <Box width={["80px"]}>
          <Text color={"red"}>{qtyTotalFreeD}</Text>
        </Box>
        <Box width={["80px"]}>
          <Text>{qtySelectedD}</Text>
        </Box>
        <Box width={["80px"]}>
          <Text style={{ color: "#0923d9" }}>
            {qtyTotalFreeD - qtySelectedD}
          </Text>
        </Box>
      </Flex> */}
    </Fragment>
  );
};
