export default {
  dashboard: {
    title: "Dashboard",
    reports: "Reports",
    fields: {
      country: "Country",
      dealer: "Dealer",
      category: "Category",
      start_date: "Start Date",
      end_date: "End Date",
      total_member: "Total Customer",
      total_spend: "Total Points Spend",
      total_order: "Total Orders",
      total_sales: "Total sales",
      total_avarage_order: "Average Order Value",
      total_earn: "Total Points Earned",
      daily_member: "Daily new member",
      target_setting: "Target Setting",
      speed_growth: "Speed/Growth",
      main_categories: "GCIAG 9 Main Categories",
      date_range: "Date Range",
      date_range_to: "Date Range To",
      top: "Top",
      ratio: "Ratio %",
      gap: "GAP",
      po: "PO",
      last_year: "Last Year",
      target: "Target",
    },
  },
};
