import React, { Fragment, useState } from "react";
import { Button, TextField, FormControl, InputLabel } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { Flex } from "rebass";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslate } from "react-admin";
//useRefresh
import CountryFields from "./CountryFields";
import moment from "moment";
import { KeyboardDatePicker } from "@material-ui/pickers";
import UploadCsv from "./UploadCsv";
export default (props) => {
  const { type, onSubmitUpload } = props;
  const [show, setShow] = useState(false);

  const [state, setState] = useState({
    country_id: "",
    start_at: moment().add(-1, "month").toISOString(),
    name: "",
    type: type,
    end_at: moment().toISOString(),
  });
  const translate = useTranslate();
  // const refresh = useRefresh();
  const handleChange = (k, v) => {
    setState({
      ...state,
      [k]: v,
    });
  };

  const onSubmit = (data) => {
    setShow(false);
    if (data && data.length > 0) {
      onSubmitUpload(data);
    }

    // refresh();
  };
  const start_at = moment(state.start_at).toISOString();
  const end_at = moment(state.end_at).toISOString();

  const params = {
    type: type,
    start_at: start_at,
    end_at: end_at,
    name: state.name,
    country_id: state.country_id,
  };
  return (
    <Fragment>
      <Button
        color={"primary"}
        style={{ textTransform: "capitalize" }}
        size={"small"}
        onClick={() => setShow(true)}
      >
        {translate("resources.buttons.upload")}
      </Button>
      <Dialog open={show} onClose={() => setShow(false)} maxWidth={"md"}>
        <DialogTitle>
          <Flex flexDirection={"row"} justifyContent={"space-between"}>
            <div />
            <IconButton onClick={() => setShow(false)}>
              <CloseIcon />
            </IconButton>
          </Flex>
        </DialogTitle>
        <DialogContent>
          <Flex width={[1]} flexDirection={"column"}>
            <CountryFields
              value={state.country_id}
              handleChange={(v) => handleChange("country_id", v)}
              translate={translate}
              required
            />
            <FormControl style={{ paddingTop: "20px", marginTop: 10 }}>
              <InputLabel
                htmlFor='name-native-simple'
                shrink={true}
                style={{ paddingBottom: "20px" }}
              >
                {translate("resources.pricebook.fields.name")}
              </InputLabel>
              <TextField
                value={state.name}
                onChange={(e) => handleChange("name", e.target.value)}
                inputProps={{
                  name: "name",
                  id: "name-native-simple",
                }}
              />
            </FormControl>
            <Flex
              width={["500px"]}
              flexDirection={"row"}
              justifyContent={"flex-start"}
              flexWrap={"wrap"}
              sx={{
                "&>div": {
                  pr: [2],
                },
              }}
            >
              <KeyboardDatePicker
                disableToolbar
                variant='inline'
                format='DD-MMM-yyyy'
                margin='normal'
                id='date-start_at-inline'
                label={translate("resources.pricebook.fields.start_at")}
                value={state.start_at}
                onChange={(v) => handleChange("start_at", v)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <KeyboardDatePicker
                disableToolbar
                variant='inline'
                format='DD-MMM-yyyy'
                margin='normal'
                id='date-end_at-inline'
                label={translate("resources.pricebook.fields.end_at")}
                value={state.end_at}
                onChange={(v) => handleChange("end_at", v)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Flex>
          </Flex>
        </DialogContent>

        <DialogActions>
          <Flex
            width={[1]}
            flexDirection={"row"}
            justifyContent={"center"}
            pb={[3]}
          >
            <UploadCsv
              handleSubmit={(data) => onSubmit(data)}
              params={params}
              onClose={() => setShow(false)}
              translate={translate}
              urlPath={"/priceBooks/import"}
            />
          </Flex>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
