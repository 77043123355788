import React, { Fragment, useState } from "react";
import { TextField, useTranslate, useNotify } from "react-admin";
import moment from "moment";
import { get } from "lodash";
import { Flex, Box } from "rebass";
import { BetterList, Title } from "../components";
//ActiveField
import { DialogUploadInvoice, DialogListInvoice } from "./components";
import ButtonDelete from "./components/ButtonDelete";
const DateField = ({ record, source }) => {
  const value = get(record, source, "");
  return <span>{value !== "" ? moment(value).format("DD-MMM-YYYY") : ""}</span>;
};

const InvoicedAction = ({ basePath, permissions, ...rest }) => {
  // console.log(permissions)
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const translate = useTranslate();
  const notify = useNotify();
  const onSubmitUpload = (v) => {
    setList(v);
    setOpen(true);
  };
  return (
    <Fragment>
      <Flex
        width={[1]}
        pt={[3]}
        px={[2]}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Box>
          <Title fontWeight={"bold"} fontSize={[3]}>
            {translate(`resources.invoiced.title.transactions`)}
          </Title>
        </Box>
        <Box>
          <DialogUploadInvoice
            translate={translate}
            onSubmitUpload={(v) => onSubmitUpload(v)}
            notify={notify}
            {...rest}
          />
        </Box>
      </Flex>
      {open && (
        <DialogListInvoice
          open={open}
          translate={translate}
          list={list}
          setOpen={setOpen}
          {...rest}
          notify={notify}
        />
      )}
    </Fragment>
  );
};

const BatchField = ({ record }) => {
  return <span>{record.id}</span>;
};

const ListInvoiceTransactions = (props) => {
  // console.log(props)
  return (
    <BetterList {...props} actions={<InvoicedAction {...props} />}>
      <BatchField source='id' label={"resources.invoiced.fields.batchId"} />
      {/* <TextField source='name' label={"resources.invoiced.fields.name"} />
      <TextField source='type' label={"resources.invoiced.fields.type"} /> */}
      <DateField
        source='created_at'
        label={"resources.invoiced.fields.upload_date"}
      />
      <TextField
        source='total_items'
        label={"resources.invoiced.fields.totalItems"}
      />
      <ButtonDelete />

      {/* <ActiveField source='active' label={"resources.invoiced.fields.active"} /> */}
    </BetterList>
  );
};
export default ListInvoiceTransactions;
