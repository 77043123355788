// import { find, filter, includes, get } from "lodash";
// import { toString } from "lodash";
import numeral from "numeral";

function currencyFormatter(params) {
  return numeral(params.value * 1).format("0,0.00"); // "\xA3" + formatNumber(params.value);
}
function currencyFormatterCheckNegatif(params) {
  const newValue = params.value * 1;
  const setzero = newValue < 0 ? 0 : newValue;
  return numeral(setzero).format("0,0.00"); // "\xA3" + formatNumber(params.value);
}

export const lowerCaseComparator = (t1, t2) => {
  return t1.toLowerCase().localeCompare(t2.toLowerCase());
};

export const NumberParser = (params) => {
  return Number(params.newValue);
};

export const NumberParserPercent = (params) => {
  return `${Number(params.newValue)}%`;
};

export const DGParser = (params) => {
  return params.value === true ? "DG" : "";
};
export const CRParser = (params) => {
  return params.value === true ? "CR" : "";
};

export const NumberAmount = (params) => {
  // console.log(params);
  //Number(params.data.Order * params.data.Price).toFixed(2)

  //round(params.newValue * 1, 2).toFixed(2); //
  return numeral(params.newValue * 1).format("0,0.00");
  // Number(params.newValue * 1).toFixed(2);
};

export const defaultColumnDefsNormal = (isAssociate) => [
  //  {
  //    field: "SubProductGroup",
  //    headerName: "Sub Group",
  //    editable: false,
  //    resizable: true,
  //  },
  {
    field: "code",
    headerName: "Product Code",
    editable: false,
    resizable: true,
    minWidth: 150,
    suppressMovable: true,
  },
  {
    field: "packaging",
    headerName: "Product Name",
    editable: false,
    resizable: true,
    minWidth: 400,
    suppressMovable: true,
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },

  {
    field: "dg",
    headerName: "DG",
    editable: false,
    resizable: false,
    minWidth: 60,
    suppressMovable: true,
    valueFormatter: DGParser,
  },
  {
    field: "cr",
    headerName: "CR",
    editable: false,
    resizable: false,
    minWidth: 60,
    suppressMovable: true,
    valueFormatter: CRParser,
  },
  {
    field: "registration_number",
    headerName: "Registration Number",
    editable: false,
    resizable: true,
    minWidth: 170,
    suppressMovable: true,
  },
  {
    field: "growth",
    headerName: "Speed/Growth",
    resizable: true,
    editable: false,
    minWidth: 150,
    suppressMovable: true,
  },
  {
    field: "new_existing",
    headerName: "New & Existing",
    resizable: true,
    editable: false,
    minWidth: 150,
    suppressMovable: true,
  },
  {
    field: "moq",
    headerName: "MOQ",
    editable: false,
    resizable: true,
    minWidth: 70,
    suppressMovable: true,
  },
  {
    field: "price",
    headerName: "Unit Price",
    editable: isAssociate,
    minWidth: 130,
    resizable: true,
    valueFormatter: currencyFormatterCheckNegatif,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
    suppressMovable: true,
  },
  {
    field: "qty",
    headerName: "Order Qty",
    minWidth: 130,
    resizable: true,
    valueParser: NumberParser,
    cellStyle: {
      "background-color": "rgba(0, 156, 125, 0.15)",
      "padding-top": "7px",
      "text-align": "right",
    },
    suppressMovable: true,
  },
  {
    field: "subtotal",
    headerName: "Order Amt",
    minWidth: 130,
    resizable: true,
    valueFormatter: currencyFormatter,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
    suppressMovable: true,
  },
  //  { field: "Promo", editable: false, width: 80 },
  //  { field: "Growth", editable: false },
  //  { field: "New", headerName: "Recency", editable: false },
  // { field: "Class1", editable: false },
  // { field: "Class2", editable: false },
  // { field: "Class3", editable: false },
  // { field: "Category", editable: false },
  // { field: "GlobalCode", editable: false },
];

export const defaultBXGYColumnDefs = (isAssociate) => [
  //  {
  //    field: "SubProductGroup",
  //    headerName: "Sub Group",
  //    editable: false,
  //    resizable: true,
  //  },
  {
    field: "code",
    headerName: "Product Code",
    editable: false,
    resizable: true,
    minWidth: 150,
    suppressMovable: true,
  },
  {
    field: "packaging",
    headerName: "Product Name",
    editable: false,
    resizable: true,
    minWidth: 400,
    suppressMovable: true,
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },
  {
    field: "dg",
    headerName: "DG",
    editable: false,
    resizable: false,
    minWidth: 60,
    suppressMovable: true,
    valueFormatter: DGParser,
  },
  {
    field: "cr",
    headerName: "CR",
    editable: false,
    resizable: false,
    minWidth: 60,
    suppressMovable: true,
    valueFormatter: CRParser,
  },
  {
    field: "registration_number",
    headerName: "Registration Number",
    editable: false,
    resizable: true,
    minWidth: 170,
    suppressMovable: true,
  },
  //new_existing
  {
    field: "growth",
    headerName: "Speed/Growth",
    editable: false,
    width: 132,
    resizable: true,
    suppressMovable: true,
  },
  {
    field: "new_existing",
    headerName: "New & Existing",
    editable: false,
    width: 132,
    resizable: true,
    suppressMovable: true,
  },
  {
    field: "moq",
    headerName: "MOQ",
    editable: false,
    width: 80,
    suppressMovable: true,
  },
  {
    field: "price",
    headerName: "Unit Price",
    editable: false,
    width: 100,
    resizable: true,
    valueFormatter: currencyFormatter,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
    suppressMovable: true,
  },
  {
    field: "qty",
    headerName: "Order Qty",
    width: 100,
    valueParser: NumberParser,
    cellStyle: {
      "background-color": "rgba(0, 156, 125, 0.15)",
      "padding-top": "7px",
      "text-align": "right",
    },
    suppressMovable: true,
  },
  {
    field: "subtotal",
    headerName: "Order Amt",
    width: 120,
    editable: false,
    valueFormatter: currencyFormatter,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
    suppressMovable: true,
    // valueParser: NumberAmount,
  },
  // { field: "Growth", editable: false },
  // { field: "New", headerName: "Recency", editable: false },
  //  { field: "Class1", editable: false },
  //  { field: "Class2", editable: false },
  //  { field: "Class3", editable: false },
  //  { field: "Category", editable: false },
  //  { field: "GlobalCode", editable: false },
];
export const defaultBABGCDColumnDefs = (isAssociate) => [
  //  {
  //    field: "SubProductGroup",
  //    headerName: "Sub Group",
  //    editable: false,
  //    resizable: true,
  //  },
  {
    field: "code",
    headerName: "Product Code",
    editable: false,
    resizable: true,
    minWidth: 150,
    suppressMovable: true,
  },
  {
    field: "packaging",
    headerName: "Product Name",
    editable: false,
    resizable: true,
    minWidth: 400,
    suppressMovable: true,
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },
  {
    field: "dg",
    headerName: "DG",
    editable: false,
    resizable: false,
    minWidth: 60,
    suppressMovable: true,
    valueFormatter: DGParser,
  },
  {
    field: "cr",
    headerName: "CR",
    editable: false,
    resizable: false,
    minWidth: 60,
    suppressMovable: true,
    valueFormatter: CRParser,
  },
  {
    field: "registration_number",
    headerName: "Registration Number",
    editable: false,
    resizable: true,
    minWidth: 170,
    suppressMovable: true,
  },
  //new_existing
  {
    field: "growth",
    headerName: "Speed/Growth",
    editable: false,
    width: 132,
    resizable: true,
    suppressMovable: true,
  },
  {
    field: "new_existing",
    headerName: "New & Existing",
    editable: false,
    width: 132,
    resizable: true,
    suppressMovable: true,
  },
  {
    field: "moq",
    headerName: "MOQ",
    editable: false,
    width: 80,
    suppressMovable: true,
  },
  {
    field: "price",
    headerName: "Unit Price",
    editable: isAssociate,
    width: 100,
    resizable: true,
    valueFormatter: currencyFormatter,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
    suppressMovable: true,
  },
  {
    field: "qty",
    headerName: "Order Qty",
    width: 100,
    valueParser: NumberParser,
    cellStyle: {
      "background-color": "rgba(0, 156, 125, 0.15)",
      "padding-top": "7px",
      "text-align": "right",
    },
    suppressMovable: true,
  },
  {
    field: "subtotal",
    headerName: "Order Amt",
    width: 120,
    editable: false,
    valueFormatter: currencyFormatter,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
    suppressMovable: true,
    // valueParser: NumberAmount,
  },
  // { field: "Growth", editable: false },
  // { field: "New", headerName: "Recency", editable: false },
  //  { field: "Class1", editable: false },
  //  { field: "Class2", editable: false },
  //  { field: "Class3", editable: false },
  //  { field: "Category", editable: false },
  //  { field: "GlobalCode", editable: false },
];

export const defaultColumnDefs = [
  //  {
  //    field: "SubProductGroup",
  //    headerName: "Sub Group",
  //    editable: false,
  //    resizable: true,
  //  },
  {
    field: "code",
    headerName: "Product Code",
    editable: false,
    resizable: true,
    minWidth: 150,
    suppressMovable: true,
  },
  {
    field: "packaging",
    headerName: "Product Name",
    editable: false,
    resizable: true,
    minWidth: 400,
    suppressMovable: true,
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },
  {
    field: "dg",
    headerName: "DG",
    editable: false,
    resizable: false,
    minWidth: 60,
    suppressMovable: true,
    valueFormatter: DGParser,
  },
  {
    field: "cr",
    headerName: "CR",
    editable: false,
    resizable: false,
    minWidth: 60,
    suppressMovable: true,
    valueFormatter: CRParser,
  },
  {
    field: "registration_number",
    headerName: "Registration Number",
    editable: false,
    resizable: true,
    minWidth: 170,
    suppressMovable: true,
  },
  //new_existing
  {
    field: "growth",
    headerName: "Speed/Growth",
    editable: false,
    width: 132,
    resizable: true,
    suppressMovable: true,
  },
  {
    field: "new_existing",
    headerName: "New & Existing",
    editable: false,
    width: 132,
    resizable: true,
    suppressMovable: true,
  },
  {
    field: "moq",
    headerName: "MOQ",
    editable: false,
    width: 80,
    suppressMovable: true,
  },
  {
    field: "price",
    headerName: "Unit Price",
    editable: false,
    width: 100,
    resizable: true,
    valueFormatter: currencyFormatter,
    suppressMovable: true,
  },
  {
    field: "qty",
    headerName: "Order Qty",
    width: 100,
    valueParser: NumberParser,
    cellStyle: {
      "background-color": "rgba(0, 156, 125, 0.15)",
      "padding-top": "7px",
    },
    suppressMovable: true,
  },
  {
    field: "subtotal",
    headerName: "Order Amt",
    width: 120,
    editable: false,
    valueFormatter: currencyFormatter,
    suppressMovable: true,
    // valueParser: NumberAmount,
  },
  // { field: "Growth", editable: false },
  // { field: "New", headerName: "Recency", editable: false },
  //  { field: "Class1", editable: false },
  //  { field: "Class2", editable: false },
  //  { field: "Class3", editable: false },
  //  { field: "Category", editable: false },
  //  { field: "GlobalCode", editable: false },
];
export const defaultFreeColumnDefs = (isAssociate) => [
  //  {
  //    field: "SubProductGroup",
  //    headerName: "Sub Group",
  //    editable: false,
  //    resizable: true,
  //  },
  {
    field: "code",
    headerName: "Product Code",
    editable: false,
    resizable: true,
    minWidth: 150,
    suppressMovable: true,
  },
  {
    field: "packaging",
    headerName: "Product Name",
    editable: false,
    resizable: true,
    minWidth: 400,
    suppressMovable: true,
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },
  // {
  //   field: "dg",
  //   headerName: "DG",
  //   editable: false,
  //   width: 60,
  //   suppressMovable: true,
  // },
  // {
  //   field: "cr",
  //   headerName: "CR",
  //   editable: false,
  //   width: 60,
  //   suppressMovable: true,
  // },
  {
    field: "dg",
    headerName: "DG",
    editable: false,
    resizable: false,
    minWidth: 60,
    suppressMovable: true,
    valueFormatter: DGParser,
  },
  {
    field: "cr",
    headerName: "CR",
    editable: false,
    resizable: false,
    minWidth: 60,
    suppressMovable: true,
    valueFormatter: CRParser,
  },
  {
    field: "registration_number",
    headerName: "Registration Number",
    editable: false,
    resizable: true,
    minWidth: 170,
    suppressMovable: true,
  },
  //new_existing
  {
    field: "growth",
    headerName: "Speed/Growth",
    editable: false,
    width: 132,
    resizable: true,
    suppressMovable: true,
  },
  {
    field: "new_existing",
    headerName: "New & Existing",
    editable: false,
    width: 132,
    resizable: true,
    suppressMovable: true,
  },
  {
    field: "price",
    headerName: "Unit Price",
    editable: isAssociate,
    width: 100,
    resizable: true,
    valueFormatter: currencyFormatter,
    suppressMovable: true,
  },
  {
    field: "qty",
    headerName: "Order Qty",
    width: 100,
    valueParser: NumberParser,
    cellStyle: {
      "background-color": "rgba(0, 156, 125, 0.15)",
      "padding-top": "7px",
    },
    suppressMovable: true,
  },
  {
    field: "subtotal",
    headerName: "Order Amt",
    width: 120,
    editable: false,
    valueFormatter: currencyFormatter,
    suppressMovable: true,
    // valueParser: NumberAmount,
  },
  // { field: "Growth", editable: false },
  // { field: "New", headerName: "Recency", editable: false },
  //  { field: "Class1", editable: false },
  //  { field: "Class2", editable: false },
  //  { field: "Class3", editable: false },
  //  { field: "Category", editable: false },
  //  { field: "GlobalCode", editable: false },
];

export const defaultDiscountColumnDefs = (isOAAsossiate) => [
  //  {
  //    field: "SubProductGroup",
  //    headerName: "Sub Group",
  //    editable: false,
  //    resizable: true,
  //  },
  {
    field: "code",
    headerName: "Product Code",
    resizable: true,
    editable: false,
    minWidth: 150,
    suppressMovable: true,
  },
  {
    field: "packaging",
    headerName: "Product Name",
    sort: "asc",
    editable: false,
    resizable: true,
    minWidth: 400,
    suppressMovable: true,
    comparator: lowerCaseComparator,
    sortingOrder: ["desc", "asc"],
  },
  {
    field: "dg",
    headerName: "DG",
    editable: false,
    resizable: false,
    minWidth: 60,
    suppressMovable: true,
    valueFormatter: DGParser,
  },
  {
    field: "cr",
    headerName: "CR",
    editable: false,
    resizable: false,
    minWidth: 60,
    suppressMovable: true,
    valueFormatter: CRParser,
  },
  {
    field: "registration_number",
    headerName: "Registration Number",
    editable: false,
    resizable: true,
    minWidth: 170,
    suppressMovable: true,
  },
  //new_existing
  {
    field: "growth",
    headerName: "Speed/Growth",
    editable: false,
    width: 132,
    resizable: true,
    suppressMovable: true,
  },
  {
    field: "new_existing",
    headerName: "New & Existing",
    editable: false,
    width: 132,
    resizable: true,
    suppressMovable: true,
  },
  {
    field: "moq",
    headerName: "MOQ",
    editable: false,
    width: 80,
    resizable: true,
    suppressMovable: true,
  },
  {
    field: "price",
    headerName: "Unit Price",
    editable: isOAAsossiate,
    width: 100,
    resizable: true,
    valueFormatter: currencyFormatter,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
    suppressMovable: true,
  },
  {
    field: "discount",
    headerName: "Discount",
    editable: false,
    width: 100,
    resizable: true,
    valueParser: NumberParser,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
    suppressMovable: true,
  },
  {
    field: "discounted_price",
    headerName: "Discounted Price",
    editable: false,
    width: 160,
    resizable: true,
    valueFormatter: currencyFormatter,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
    suppressMovable: true,
  },

  {
    field: "qty",
    headerName: "Order Qty",
    width: 100,
    resizable: true,
    valueParser: NumberParser,
    cellStyle: {
      "background-color": "rgba(0, 156, 125, 0.15)",
      "padding-top": "7px",
      "text-align": "right",
    },
    suppressMovable: true,
  },
  {
    field: "subtotal",
    headerName: "Order Amt",
    width: 110,
    editable: false,
    valueFormatter: currencyFormatter,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
    suppressMovable: true,
    // valueParser: NumberAmount,
  },
  // { field: "Growth", editable: false },
  // { field: "New", headerName: "Recency", editable: false },
  //  { field: "Class1", editable: false },
  //  { field: "Class2", editable: false },
  //  { field: "Class3", editable: false },
  //  { field: "Category", editable: false },
  //  { field: "GlobalCode", editable: false },
];

export const defaultSPColumnDefs = (isOAAsossiate) => [
  //  {
  //    field: "SubProductGroup",
  //    headerName: "Sub Group",
  //    editable: false,
  //    resizable: true,
  //  },
  {
    field: "code",
    headerName: "Product Code",
    resizable: true,
    editable: false,
    minWidth: 150,
    suppressMovable: true,
  },
  {
    field: "packaging",
    headerName: "Product Name",
    sort: "asc",
    editable: false,
    resizable: true,
    minWidth: 400,
    suppressMovable: true,
  },
  {
    field: "dg",
    headerName: "DG",
    editable: false,
    resizable: false,
    minWidth: 60,
    suppressMovable: true,
    valueFormatter: DGParser,
  },
  {
    field: "cr",
    headerName: "CR",
    editable: false,
    resizable: false,
    minWidth: 60,
    suppressMovable: true,
    valueFormatter: CRParser,
  },
  {
    field: "registration_number",
    headerName: "Registration Number",
    editable: false,
    resizable: true,
    minWidth: 170,
    suppressMovable: true,
  },
  //new_existing
  {
    field: "growth",
    headerName: "Speed/Growth",
    editable: false,
    width: 132,
    resizable: true,
    suppressMovable: true,
  },
  {
    field: "new_existing",
    headerName: "New & Existing",
    editable: false,
    width: 132,
    resizable: true,
    suppressMovable: true,
  },
  {
    field: "moq",
    headerName: "MOQ",
    editable: false,
    width: 80,
    resizable: true,
    suppressMovable: true,
  },
  {
    field: "price",
    headerName: "Unit Price",
    editable: isOAAsossiate,
    width: 100,
    resizable: true,
    valueFormatter: currencyFormatter,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
    suppressMovable: true,
  },
  {
    field: "special_price",
    headerName: "Special Price",
    editable: false,
    width: 120,
    resizable: true,
    valueFormatter: currencyFormatter,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
    suppressMovable: true,
  },

  {
    field: "qty",
    headerName: "Order Qty",
    width: 110,
    resizable: true,
    valueParser: NumberParser,
    cellStyle: {
      "background-color": "rgba(0, 156, 125, 0.15)",
      "padding-top": "7px",
      "text-align": "right",
    },
    suppressMovable: true,
  },
  {
    field: "subtotal",
    headerName: "Order Amt",
    width: 110,
    editable: false,
    valueFormatter: currencyFormatter,
    cellStyle: { "padding-top": "7px", "text-align": "right" },
    suppressMovable: true,
    // valueParser: NumberAmount,
  },
  // { field: "Growth", editable: false },
  // { field: "New", headerName: "Recency", editable: false },
  //  { field: "Class1", editable: false },
  //  { field: "Class2", editable: false },
  //  { field: "Class3", editable: false },
  //  { field: "Category", editable: false },
  //  { field: "GlobalCode", editable: false },
];

export const defaultSPDiscountColumnDefs = [
  //  {
  //    field: "SubProductGroup",
  //    headerName: "Sub Group",
  //    editable: false,
  //    resizable: true,
  //  },
  {
    field: "code",
    headerName: "Product Code",
    resizable: true,
    editable: false,
    minWidth: 150,
    suppressMovable: true,
  },
  {
    field: "packaging",
    headerName: "Product Name",
    sort: "asc",
    editable: false,
    resizable: true,
    minWidth: 400,
    suppressMovable: true,
  },
  {
    field: "dg",
    headerName: "DG",
    editable: false,
    resizable: false,
    minWidth: 60,
    suppressMovable: true,
    valueFormatter: DGParser,
  },
  {
    field: "cr",
    headerName: "CR",
    editable: false,
    resizable: false,
    minWidth: 60,
    suppressMovable: true,
    valueFormatter: CRParser,
  },
  {
    field: "registration_number",
    headerName: "Registration Number",
    editable: false,
    resizable: true,
    minWidth: 170,
    suppressMovable: true,
  },
  //new_existing
  {
    field: "growth",
    headerName: "Speed/Growth",
    editable: false,
    width: 132,
    resizable: true,
    suppressMovable: true,
  },
  {
    field: "new_existing",
    headerName: "New & Existing",
    editable: false,
    width: 132,
    resizable: true,
    suppressMovable: true,
  },
  {
    field: "moq",
    headerName: "Promo Price",
    editable: false,
    width: 120,
    resizable: true,
    suppressMovable: true,
  },
  {
    field: "price",
    headerName: "Unit Price",
    editable: false,
    width: 100,
    resizable: true,
    valueFormatter: currencyFormatter,
    suppressMovable: true,
  },
  {
    field: "qty",
    headerName: "Order Qty",
    width: 100,
    valueParser: NumberParser,
    cellStyle: {
      "background-color": "rgba(0, 156, 125, 0.15)",
      "padding-top": "7px",
    },
    suppressMovable: true,
  },
  {
    field: "subtotal",
    headerName: "Order Amt",
    width: 120,
    editable: false,
    valueFormatter: currencyFormatter,
    suppressMovable: true,
    // valueParser: NumberAmount,
  },
  // { field: "Growth", editable: false },
  // { field: "New", headerName: "Recency", editable: false },
  //  { field: "Class1", editable: false },
  //  { field: "Class2", editable: false },
  //  { field: "Class3", editable: false },
  //  { field: "Category", editable: false },
  //  { field: "GlobalCode", editable: false },
];
