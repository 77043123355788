export const dataType = [
  { id: "Class1", name: "Class1" },
  { id: "Class2", name: "Class2" },
  { id: "Class3", name: "Class3" },
  { id: "Category", name: "Category" },
  { id: "ProductGroup", name: "ProductGroup" },
  { id: "SubProductGroup", name: "SubProductGroup" },
  { id: "TargetSetting", name: "TargetSetting" },
];

export const dataCurrency = [
  { id: "SGD", name: "SGD" },
  { id: "USD", name: "USD" },
];
