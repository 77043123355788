import React, { useState } from "react";
import { filter, map, uniqBy, flatten, orderBy } from "lodash";
import { Box, Flex } from "rebass";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { applyFilter } from "../utils";
import { usePermissions } from "react-admin";
import { ROLE_USER } from "../../../../utils";

export default (props) => {
  const {
    dataList,
    countries,
    dealers,
    targetSettings,
    categories,
    setList,
    valueCountry,
    setValueCountry,
    valueDealer,
    setValueDealer,
    myfilter,
    setMyFilter,
    valueCategory,
    setValueCategory,
    valueTargetSetting,
    setValueTargetSetting,
    isDealer,
  } = props;
  const { permissions } = usePermissions();
  const [state, setState] = useState({
    listCountries: countries,
    listDealers: dealers,
    listTargetSettings: targetSettings,
    listCategories: categories,
  });

  const onChangeCountries = (e, newValue) => {
    setValueCountry([...newValue]);
    const paramsField = map(newValue, function (o) {
      return o.name;
    });
    // console.log(paramsField);
    let newMyFilterObj = myfilter;
    if (valueDealer.length > 0 && newValue.length > 0) {
      const getDealerSelected = map(newValue, function (oCountry) {
        const newDealers = filter(valueDealer, function (oDealer) {
          return oCountry.id === oDealer.country_id;
        });
        return newDealers;
      });

      const newDealerSelected = filter(getDealerSelected, function (element) {
        return element !== undefined;
      });
      const myFlatDealer = flatten(newDealerSelected);
      setValueDealer(myFlatDealer);
      const paramsFieldDealer = map(myFlatDealer, function (o) {
        return o.name;
      });
      newMyFilterObj = {
        ...myfilter,
        country: paramsField,
        dealer: paramsFieldDealer,
      };
      setMyFilter({
        ...myfilter,
        country: paramsField,
        dealer: paramsFieldDealer,
      });
    } else {
      newMyFilterObj = {
        ...myfilter,
        country: paramsField,
      };
      setMyFilter({
        ...myfilter,
        country: paramsField,
      });
    }

    if (newValue.length > 0) {
      const newParams = { ...newMyFilterObj, country: paramsField };
      const newFilter = applyFilter(dataList, newParams);
      setList(newFilter);
    } else {
      let filter2 = {};
      const newMyFilter = { ...newMyFilterObj, country: [] };
      filter(newMyFilter, function (v, k) {
        const param = v.length > 0 ? { [k]: v } : {};
        filter2 = { ...filter2, ...param };
        return v.length > 0;
      });
      const newFilter = applyFilter(dataList, filter2);
      setList(newFilter);
    }

    if (newValue.length > 0) {
      const newParams = map(newValue, function (o) {
        return o.id;
      });
      const newlistDealers = filter(dealers, function (i) {
        return newParams.some(function (f) {
          return i.country_id === f;
        });
      });
      setState({
        ...state,
        listDealers: newlistDealers,
      });
    } else {
      setState({
        ...state,
        listDealers: dealers,
      });
    }
  };
  const onChangeDealers = (e, newValue) => {
    setValueDealer([...newValue]);
    const paramsField = map(newValue, function (o) {
      return o.name;
    });
    setMyFilter({
      ...myfilter,
      dealer: paramsField,
    });
    if (newValue.length > 0) {
      const newParams = { ...myfilter, dealer: paramsField };
      const newFilter = applyFilter(dataList, newParams);
      setList(newFilter);
    } else {
      let filter2 = {};
      const newMyFilter = { ...myfilter, dealer: [] };
      filter(newMyFilter, function (v, k) {
        const param = v.length > 0 ? { [k]: v } : {};
        filter2 = { ...filter2, ...param };
        return v.length > 0;
      });
      const newFilter = applyFilter(dataList, filter2);
      setList(newFilter);
    }
  };
  const onChangeCategories = (e, newValue) => {
    // console.log("change", newValue);
    setValueCategory([...newValue]);
    const paramsField = map(newValue, function (o) {
      return o.label;
    });
    setMyFilter({
      ...myfilter,
      category: paramsField,
    });
    if (newValue.length > 0) {
      const newParams = { ...myfilter, category: paramsField };
      const newFilter = applyFilter(dataList, newParams);
      setList(newFilter);
    } else {
      let filter2 = {};
      const newMyFilter = { ...myfilter, category: [] };
      filter(newMyFilter, function (v, k) {
        const param = v.length > 0 ? { [k]: v } : {};
        filter2 = { ...filter2, ...param };
        return v.length > 0;
      });
      const newFilter = applyFilter(dataList, filter2);
      setList(newFilter);
    }
    if (newValue.length > 0) {
      const newParams = map(newValue, function (o) {
        return o.label;
      });
      const newListTarget = filter(dataList, function (i) {
        return newParams.some(function (f) {
          return i.category === f;
        });
      });

      const lt = uniqBy(newListTarget, "target_setting");
      const newListTargetSetting = map(lt, function (oi) {
        return { label: oi.target_setting };
      });
      // console.log(newListTargetSetting);
      setState({
        ...state,
        listTargetSettings: newListTargetSetting,
      });
    } else {
      setState({
        ...state,
        listTargetSettings: targetSettings,
      });
    }
  };
  const onChangeTargetSetting = (e, newValue) => {
    // console.log("change", newValue);
    setValueTargetSetting([...newValue]);
    const paramsField = map(newValue, function (o) {
      return o.label;
    });
    setMyFilter({
      ...myfilter,
      target_setting: paramsField,
    });
    if (newValue.length > 0) {
      const newParams = { ...myfilter, target_setting: paramsField };
      const newFilter = applyFilter(dataList, newParams);
      setList(newFilter);
    } else {
      let filter2 = {};
      const newMyFilter = { ...myfilter, target_setting: [] };
      filter(newMyFilter, function (v, k) {
        const param = v.length > 0 ? { [k]: v } : {};
        filter2 = { ...filter2, ...param };
        return v.length > 0;
      });
      const newFilter = applyFilter(dataList, filter2);
      setList(newFilter);
    }
  };
  const sortListTargetSettings = orderBy(
    state.listTargetSettings,
    [(product) => product.label.toLowerCase()],
    ["asc"]
  );
  return (
    <Flex width={[1]} flexDirection={"row"} flexWrap={"wrap"} pb={[2]}>
      {permissions !== ROLE_USER.country_manager &&
        permissions !== ROLE_USER.dealer && (
          <Box p={[1]} width={["400px"]}>
            {countries && countries.length > 0 && (
              <Autocomplete
                id={
                  props.idHeaderDealer
                    ? `${props.idHeaderDealer}-countries`
                    : "po-countries-fields-filter"
                }
                options={countries}
                multiple
                value={valueCountry}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) =>
                  onChangeCountries(event, newValue)
                }
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant='outlined'
                      label={option.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    variant='filled'
                    label='Country'
                    placeholder='country'
                  />
                )}
              />
            )}
          </Box>
        )}
      {!isDealer && (
        <Box p={[1]} width={["400px"]}>
          {state.listDealers && state.listDealers.length > 0 && (
            <Autocomplete
              id={
                props.idHeaderDealer
                  ? props.idHeaderDealer
                  : "po-dealers-fields-filter"
              }
              options={state.listDealers}
              multiple
              value={valueDealer}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => onChangeDealers(event, newValue)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant='outlined'
                    label={option.name}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  variant='filled'
                  label='Dealer'
                  placeholder='dealer'
                />
              )}
            />
          )}
        </Box>
      )}
      <Box p={[1]} width={["400px"]}>
        {state.listCategories && state.listCategories.length > 0 && (
          <Autocomplete
            id={
              props.idHeaderDealer
                ? `${props.idHeaderDealer}-categories`
                : "po-categories-fields-filter"
            }
            options={state.listCategories}
            multiple
            value={valueCategory}
            getOptionLabel={(option) => option.label}
            onChange={(event, newValue) => onChangeCategories(event, newValue)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant='outlined'
                  label={option.label}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                variant='filled'
                label='Category'
                placeholder='category'
              />
            )}
          />
        )}
      </Box>
      <Box p={[1]} width={["400px"]}>
        {sortListTargetSettings && sortListTargetSettings.length > 0 && (
          <Autocomplete
            id={
              props.idHeaderDealer
                ? `${props.idHeaderDealer}-targetsettings`
                : "po-targetSettings-fields-filter"
            }
            options={sortListTargetSettings}
            multiple
            value={valueTargetSetting}
            getOptionLabel={(option) => option.label}
            onChange={(event, newValue) =>
              onChangeTargetSetting(event, newValue)
            }
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant='outlined'
                  label={option.label}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                variant='filled'
                label='Target Setting'
                placeholder=''
              />
            )}
          />
        )}
      </Box>
    </Flex>
  );
};
