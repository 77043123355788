import React, { useState } from "react";
import { Box, Flex } from "rebass";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { useQuery } from "../../../components";
import { Loading, downloadCSV } from "react-admin";
import {
  ListOTD,
  HeaderFilters,
  listColumnItem,
  getListOTD,
  listColumnItemDealer,
} from "./otd_fields";
import { getMonthList } from "./utils";
import { get, map, filter, find } from "lodash";
import jsonExport from "jsonexport/dist";
import { ExportCSVFile, NumberParser2Decimals } from "./utils";

const moment = extendMoment(Moment);
const endMonth = moment();
const startMonth = moment(endMonth).add(-1, "month");

const MainFields = (props) => {
  const { dataList, translate, dealerId } = props;

  const myListDefault = getMonthList({
    start: new Date(props.startMonth),
    end: new Date(props.endMonth),
  });
  let newColumnList = [];
  myListDefault.map((itemMonth) => {
    newColumnList.push({
      field: `amount-${itemMonth}`,
      headerName: `Amount ${itemMonth}`,
      width: 160,
      cellStyle: { "padding-top": "7px", "text-align": "right" },
      editable: false,
      resizable: true,
      valueFormatter: NumberParser2Decimals,
    });
    newColumnList.push({
      field: `amount-qty-${itemMonth}`,
      headerName: `Qty ${itemMonth}`,
      width: 160,
      cellStyle: { "padding-top": "7px", "text-align": "right" },
      editable: false,
      resizable: true,
      valueFormatter: NumberParser2Decimals,
    });
    // newColumnList.push({
    //   field: `amount_gap-${itemMonth}`,
    //   headerName: `G/L ${itemMonth}`,
    //   width: 160,
    //   cellStyle: { "padding-top": "7px", "text-align": "right" },
    //   editable: false,
    //   resizable: true,
    //   valueFormatter: NumberAmount,
    // });
    // newColumnList.push({
    //   field: `amount_gap-qty-${itemMonth}`,
    //   headerName: `G/L Qty ${itemMonth}`,
    //   width: 160,
    //   cellStyle: { "padding-top": "7px", "text-align": "right" },
    //   editable: false,
    //   resizable: true,
    //   valueFormatter: NumberAmount,
    // });
    return "";
  });
  const [list, setList] = useState(dataList);
  const [valueCountry, setValueCountry] = useState([]);
  const [valueDealer, setValueDealer] = useState([]);
  const [valueCategory, setValueCategory] = useState([]);
  const [valueTargetSetting, setValueTargetSetting] = useState([]);
  const listC =
    dealerId && dealerId !== null ? listColumnItemDealer : listColumnItem;
  const [myData, setMyData] = useState({
    rangeDate: {
      start: moment(props.startMonth),
      end: moment(props.endMonth),
    },
    listColumn: [...listC, ...newColumnList],
    listTemporary: dataList,
  });
  const [myfilter, setMyFilter] = useState({
    country: [],
    dealer: [],
    category: [],
    target_setting: [],
  });
  const onExportCsv = () => {
    if (list && list.length > 0) {
      let newList = [];
      map(list, function (o) {
        const myMonth = o.myMonth;
        map(myMonth, function (i) {
          newList.push({
            country: get(i, "country", ""),
            dealer: get(i, "dealer", ""),
            target_setting: get(i, "target_setting", ""),
            month:
              get(i, "month", "") !== ""
                ? moment(get(i, "month", "")).format("MMM-YY")
                : "",
            amount: get(i, "amount", 0),
            // amount_gap: get(i, "amount_gap", 0),
            qty: get(i, "qty", 0),
            // qty_gap: NumberAmountValue(get(i, "qty_gap", 0)),
          });
          // console.log(i);
          return i;
        });
        return {
          country: get(o, "country", ""),
          dealer: get(o, "dealer", ""),
          target_setting: get(o, "target_setting", ""),
          month:
            get(o, "month", "") !== ""
              ? moment(get(o, "month", "")).format("MMM-YY")
              : "",
          amount: get(o, "amount", 0),
          // amount_gap: get(o, "amount_gap", 0),
          qty: get(o, "qty", 0),
          // qty_gap: get(o, "qty_gap", 0),
        };
      });

      // console.log(newList);
      //"amount_gap",
      //"qty_gap",
      jsonExport(
        newList,
        {
          headers: [
            "country",
            "dealer",
            "target_setting",
            "month",
            "amount",
            "qty",
          ],
        },
        (err, csv) => {
          if (err) return console.error(err);
          downloadCSV(csv, `otd-${moment().format("DD-MMM-YYYY-HH:mm:ss")}`);
        }
      );
    }
  };

  return (
    <Box width={[1]} py={[2]}>
      <HeaderFilters
        {...props}
        setList={setList}
        valueCountry={valueCountry}
        valueDealer={valueDealer}
        myfilter={myfilter}
        valueCategory={valueCategory}
        valueTargetSetting={valueTargetSetting}
        setValueCategory={setValueCategory}
        setValueTargetSetting={setValueTargetSetting}
        setValueCountry={setValueCountry}
        setValueDealer={setValueDealer}
        setMyFilter={setMyFilter}
        list={list}
        myData={myData}
        setMyData={setMyData}
      />
      <ExportCSVFile
        translate={translate}
        list={list}
        onExportCsv={onExportCsv}
      />
      <ListOTD {...props} list={list} myData={myData} />
    </Box>
  );
};

export default (props) => {
  const { dealerId, country, dealers, countries } = props;
  const paramDealerId = dealerId !== null ? `&dealer_id=${dealerId}` : "";
  const { loaded, data } = useQuery(
    `/reports/otd?start_date=${startMonth.toISOString()}&end_date=${endMonth.toISOString()}${paramDealerId}`
  );
  if (!loaded) {
    return (
      <Flex width={[1]} flexDirection={"column"} alignItems={"center"}>
        <Loading />
      </Flex>
    );
  }
  const newlist = getListOTD(data);
  const dataList =
    newlist && newlist.length > 0
      ? Array.from(
          new Set(
            newlist.map((p, index) => {
              return { ...p, id: index };
            })
          )
        )
      : [];
  let myDataList = dataList;
  let countryNew = country;
  if (dealerId && dealerId !== null) {
    const dealercountryNew = find(dealers, function (dl) {
      return dl.id === dealerId;
    });
    // console.log(dealercountryNew);
    countryNew = find(countries, function (ct) {
      return ct.id === dealercountryNew.country_id;
    });
  }

  if (countryNew && countryNew.id) {
    myDataList = filter(dataList, function (o) {
      return o.country === countryNew.name;
    });
  }
  return (
    <Box width={[1]} py={[1]}>
      <MainFields
        {...props}
        dataList={myDataList}
        startMonth={startMonth.toISOString()}
        endMonth={endMonth.toISOString()}
      />
    </Box>
  );
};
