import React from "react";
import { Flex, Box } from "rebass";
// import { filter, orderBy } from "lodash";
import Paper from "@material-ui/core/Paper";
import {
  // SelectInput,
  ReferenceInput,
  TextInput,
  BooleanInput,
  // NumberInput,
  AutocompleteInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
// const dataExisting = [
//   { id: "New", name: "New" },
//   { id: "Existing", name: "Existing" },
// ];

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
    },
  },
  product: {
    width: "100%",
  },
}));

const ProductFields = (props) => {
  const classes = useStyles();
  // const listProductGroup1 = filter(props.listcode, function (o) {
  //   return o.type === "ProductGroup";
  // });
  // const listProductGroup = orderBy(listProductGroup1, ["label"], ["asc"]);
  // const listSubProductGroup1 = filter(props.listcode, function (o) {
  //   return o.type === "SubProductGroup";
  // });
  // const listSubProductGroup = orderBy(listSubProductGroup1, ["label"], ["ASC"]);
  return (
    <Box width={[1]} pt={[3]}>
      <Paper elevation={3}>
        <Flex
          width={[1]}
          flexDirection={"row"}
          justifyContent={"space-between"}
          sx={{
            "&>div": {
              p: [3],
            },
          }}
          className={classes.root}
        >
          <Flex width={[1]} flexDirection={"column"}>
            <BooleanInput
              label='resources.products.fields.active'
              source='active'
            />
            <TextInput
              label='resources.products.fields.product_name'
              source='packaging'
              InputLabelProps={{ shrink: true }}
            />
            <TextInput
              label='resources.products.fields.product_code'
              source='code'
              InputLabelProps={{ shrink: true }}
            />
            {/* <SelectInput
              source={"group_id"}
              label='resources.products.fields.product_group'
              choices={listProductGroup}
              optionText={"label"}
              fullWidth
            /> */}
            <ReferenceInput
              label='resources.products.fields.product_group'
              source={"group_id"}
              reference='codes'
              className={classes.product}
              filterToQuery={(searchText) => ({ search: searchText })}
              filter={{ type: "ProductGroup", active: true }}
              sort={{ field: "label", order: "ASC" }}
              perPage={1000}
            >
              <AutocompleteInput optionText='label' />
            </ReferenceInput>
            <ReferenceInput
              label='resources.products.fields.sub_product_group'
              source={"subgroup_id"}
              reference='codes'
              className={classes.product}
              filterToQuery={(searchText) => ({ search: searchText })}
              filter={{ type: "SubProductGroup", active: true }}
              sort={{ field: "label", order: "ASC" }}
              perPage={1000}
            >
              <AutocompleteInput optionText='label' />
            </ReferenceInput>
            {/* <SelectInput
              source={"subgroup_id"}
              label='resources.products.fields.sub_product_group'
              choices={listSubProductGroup}
              optionText={"label"}
              fullWidth
            /> */}
            <TextInput
              label='resources.products.fields.short_code'
              source='short_code'
              style={{ width: 180 }}
              InputLabelProps={{ shrink: true }}
            />
            {/* <ReferenceInput
              label='resources.products.fields.sub_product_group'
              source='subgroup_id'
              reference='codes'
              filter={{ type: "SubProductGroup" }}
              style={{ width: 280 }}
              perPage={1000}
            >
              <SelectInput optionText='label' />
            </ReferenceInput> */}
          </Flex>
          <Flex width={[1]} flexDirection={"column"}>
            <Flex
              width={[1]}
              flexDirection={"row"}
              justifyContent={"flex-start"}
              sx={{
                "&>div": {
                  pr: [3],
                },
              }}
            >
              <BooleanInput label='resources.products.fields.dg' source='dg' />
              <BooleanInput label='resources.products.fields.cr' source='cr' />
            </Flex>
            <TextInput
              label='resources.products.fields.global_code'
              source='global_code'
              InputLabelProps={{ shrink: true }}
            />
            <TextInput
              label='resources.products.fields.moq'
              source='moq'
              type={"number"}
              style={{ width: 100 }}
              InputLabelProps={{ shrink: true }}
            />
            {/* <NumberInput
              label='resources.products.fields.moq'
              source='moq'
              
              style={{ width: 100 }}
              InputLabelProps={{ shrink: true }}
            /> */}
            <TextInput
              label='resources.products.fields.default_price'
              source='price'
              type={"number"}
              style={{ width: 100 }}
              InputLabelProps={{ shrink: true }}
            />
            <TextInput
              label='resources.products.fields.product_link'
              source='link'
              InputLabelProps={{ shrink: true }}
            />
            <TextInput
              label='resources.products.fields.sort_order'
              source='sort_order'
              style={{ width: 180 }}
              InputLabelProps={{ shrink: true }}
            />
          </Flex>
        </Flex>
      </Paper>
    </Box>
  );
};
//
export default ProductFields;
