import { find, filter, includes, get } from "lodash";
import dataPromotion from "./data/dataPromotion";
export const NumberParser = (params) => {
  return Number(params.newValue);
};

export const NumberAmount = (params) => {
  // console.log(params);
  //Number(params.data.Order * params.data.Price).toFixed(2)
  return Number(params.newValue);
};

export const defaultMOQS_BUYXGETY = [
  { buy: 0, get: 0, moq: 0, additional: 0 },
  { buy: 0, get: 0, moq: 0, additional: 0 },
  { buy: 0, get: 0, moq: 0, additional: 0 },
];

export const PromoType_B = [
  "Buy X Get Y",
  "Buy AB Get CD",
  "Discount",
  "Special Price",
];

export const defaultColumnDefs = [
  //  {
  //    field: "SubProductGroup",
  //    headerName: "Sub Group",
  //    editable: false,
  //    resizable: true,
  //  },
  { field: "code", headerName: "Product Code", editable: false },
  {
    field: "packaging",
    headerName: "Product Packaging",
    editable: false,
    resizable: true,
  },
  { field: "dg", editable: false, width: 80 },
  { field: "cr", editable: false, width: 80 },
  { field: "moq", headerName: "Promo MOQ", editable: false, width: 120 },
  {
    field: "price",
    headerName: "Unit Price",
    editable: false,
    width: 100,
  },
  {
    field: "Order",
    headerName: "Order Qty",
    width: 100,
    valueParser: NumberParser,
  },
  {
    field: "OrderAmt",
    headerName: "Order Amt",
    width: 120,
    editable: false,
    valueParser: NumberParser,
  },
  // { field: "Growth", editable: false },
  // { field: "New", headerName: "Recency", editable: false },
  //  { field: "Class1", editable: false },
  //  { field: "Class2", editable: false },
  //  { field: "Class3", editable: false },
  //  { field: "Category", editable: false },
  //  { field: "GlobalCode", editable: false },
];

export const defaultColumnDiscount = [
  //  {
  //    field: "SubProductGroup",
  //    headerName: "Sub Group",
  //    editable: false,
  //    resizable: true,
  //  },
  { field: "code", headerName: "Product Code", editable: false },
  {
    field: "packaging",
    headerName: "Product Packaging",
    editable: false,
    resizable: true,
  },
  { field: "dg", editable: false, width: 80 },
  { field: "cr", editable: false, width: 80 },
  { field: "moq", headerName: "Promo MOQ", editable: false, width: 120 },
  {
    field: "discount",
    width: 100,
    editable: false,
    valueParser: NumberParser,
  },
  {
    field: "price",
    headerName: "Unit Price",
    editable: false,
    width: 100,
  },
  {
    field: "Order",
    headerName: "Order Qty",
    width: 100,
    valueParser: NumberParser,
  },
  {
    field: "OrderAmt",
    headerName: "Order Amt",
    editable: false,
    width: 120,
    valueParser: NumberParser,
  },

  //  { field: "Growth", editable: false },
  //  { field: "New", headerName: "Recency", editable: false },
  //  { field: "Class1", editable: false },
  //  { field: "Class2", editable: false },
  //  { field: "Class3", editable: false },
  //  { field: "Category", editable: false },
  //  { field: "GlobalCode", editable: false },
];
export const defaultColumnSp = [
  //  {
  //    field: "SubProductGroup",
  //    headerName: "Sub Group",
  //    editable: false,
  //    resizable: true,
  //  },
  { field: "code", headerName: "Product Code", editable: false },
  {
    field: "packaging",
    headerName: "Product Packaging",
    editable: false,
    resizable: true,
  },
  { field: "dg", editable: false, width: 80 },
  { field: "cr", editable: false, width: 80 },
  { field: "moq", headerName: "Promo MOQ", editable: false, width: 120 },
  {
    field: "price",
    headerName: "Unit Price",
    editable: false,
    width: 100,
  },
  {
    field: "SpecialPrice",
    width: 120,
    headerName: "Special Price",
    editable: false,
    valueParser: NumberParser,
  },
  {
    field: "Order",
    headerName: "Order Qty",
    width: 100,
    valueParser: NumberParser,
  },
  {
    field: "OrderAmt",
    headerName: "Order Amt",
    editable: false,
    width: 120,
    valueParser: NumberParser,
  },

  //  { field: "Growth", editable: false },
  //  { field: "New", headerName: "Recency", editable: false },
  //  { field: "Class1", editable: false },
  //  { field: "Class2", editable: false },
  //  { field: "Class3", editable: false },
  //  { field: "Category", editable: false },
  //  { field: "GlobalCode", editable: false },
];

export const defaultColumnDefsNormal = [
  //  {
  //    field: "SubProductGroup",
  //    headerName: "Sub Group",
  //    editable: false,
  //    resizable: true,
  //  },
  { field: "code", headerName: "Product Code", editable: false },
  {
    field: "packaging",
    headerName: "Product Packaging",
    editable: false,
    resizable: true,
  },

  { field: "dg", editable: false, width: 80 },
  { field: "cr", editable: false, width: 80 },
  { field: "moq", editable: false, width: 80 },
  {
    field: "price",
    headerName: "Unit Price",
    editable: false,
    width: 100,
  },
  {
    field: "Order",
    headerName: "Order Qty",
    width: 100,
    valueParser: NumberParser,
  },
  {
    field: "OrderAmt",
    headerName: "Order Amt",
    width: 120,
    valueParser: NumberAmount,
  },
  //  { field: "Promo", editable: false, width: 80 },
  //  { field: "Growth", editable: false },
  //  { field: "New", headerName: "Recency", editable: false },
  // { field: "Class1", editable: false },
  // { field: "Class2", editable: false },
  // { field: "Class3", editable: false },
  // { field: "Category", editable: false },
  // { field: "GlobalCode", editable: false },
];

export const getFilterProductList = (list, filters) => {
  let listProduct = filter(list, function (o) {
    return (
      (filters.group !== "" ? o.group_id === filters.group : true) &&
      (filters.subgroup !== "" ? o.subgroup_id === filters.subgroup : true) &&
      (filters.category !== "" ? o.category_id === filters.category : true) &&
      (filters.growth !== "" ? o.class4["1"] === filters.growth : true) &&
      (filters.recency !== "" ? o.new_existing === filters.recency : true)
    );
  });
  // console.log(listProduct)
  return listProduct;
};
export const getFilterSearchProductList = (list, value) => {
  let listProduct = filter(list, function (o) {
    return value !== "" ? includes(o.packaging, value) : true;
  });
  return listProduct;
};

export const getNumber = (list, key) => {
  let count = 0;
  if (key === "tPromoOrderQty") {
    list.map((i) => {
      count = count + i.Order;
      return count;
    });
  } else if (key === "tPromoOrderAmt") {
    list.map((i) => {
      count = count + i.price * i.Order;
      return count;
    });
  }
  return count;
};

export const getFreeProduct = (list, key) => {
  let nameProduct = find(list, function (o) {
    return o.code === key;
  });
  return nameProduct;
};

export const getData = (product) => {
  const data = product.map((item) => {
    const value =
      item[dataPromotion[0].Criteria] === dataPromotion[0].Match ||
      item[dataPromotion[1].Criteria] === dataPromotion[1].Match ||
      item[dataPromotion[2].Criteria] === dataPromotion[2].Match
        ? "YES"
        : "NO";

    return { ...item, Promo: value };
  });
  return data;
};
/**ProductGroup  => group_id =>  611

SubProductGroup  => subgroup_id   621

ProductCode  => code =>  "901126"

ProductShortCode  => short_code   "901126"  */

const checkPromotion = (product, promotions) => {
  const myPromotions = find(promotions, function (o) {
    const { criteria, promotion_type } = o;
    if (promotion_type === "Buy X Get Y") {
      const buyX = get(criteria, "buy_x_get_y.x.type", "");
      if (buyX === "SubProductGroup") {
        return get(criteria, "buy_x_get_y.x.id", 0) === product.subgroup_id;
      } else if (buyX === "ProductGroup") {
        return get(criteria, "buy_x_get_y.x.id", 0) === product.group_id;
      } else if (buyX === "ProductCode") {
        return get(criteria, "buy_x_get_y.x.id", "") === product.id;
      } else if (buyX === "ProductShortCode") {
        return get(criteria, "buy_x_get_y.x.id", "") === product.id;
      }
    } else if (promotion_type === "Buy AB Get CD") {
      const buyA = get(criteria, "buy_ab_get_cd.a.type", "");
      const buyB = get(criteria, "buy_ab_get_cd.b.type", "");
      if (buyA === "ProductGroup" || buyB === "ProductGroup") {
        return (
          get(criteria, "buy_ab_get_cd.a.id", 0) === product.group_id ||
          get(criteria, "buy_ab_get_cd.b.id", 0) === product.group_id
        );
      } else if (buyA === "SubProductGroup" || buyB === "SubProductGroup") {
        return (
          get(criteria, "buy_ab_get_cd.a.id", 0) === product.subgroup_id ||
          get(criteria, "buy_ab_get_cd.b.id", 0) === product.subgroup_id
        );
      } else if (buyA === "ProductCode" || buyB === "ProductCode") {
        return (
          get(criteria, "buy_ab_get_cd.a.id", "") === product.id ||
          get(criteria, "buy_ab_get_cd.b.id", "") === product.id
        );
      } else if (buyA === "ProductShortCode" || buyB === "ProductShortCode") {
        return (
          get(criteria, "buy_ab_get_cd.a.id", "") === product.id ||
          get(criteria, "buy_ab_get_cd.b.id", "") === product.id
        );
      } else if (promotion_type === "Discount") {
        const buyType = get(criteria, "discount.buy.type", "");
        if (buyType === "ProductGroup") {
          return get(criteria, "discount.buy.id", 0) === product.group_id;
        } else if (buyType === "SubProductGroup") {
          return get(criteria, "discount.buy.id", 0) === product.subgroup_id;
        } else if (buyType === "ProductCode") {
          return get(criteria, "discount.buy.id", "") === product.id;
        } else if (buyType === "ProductShortCode") {
          return get(criteria, "discount.buy.id", "") === product.id;
        }
      } else if (promotion_type === "Special Price") {
        const buyType = get(criteria, "price.buy.type", "");
        if (buyType === "ProductGroup") {
          return get(criteria, "price.buy.id", 0) === product.group_id;
        } else if (buyType === "SubProductGroup") {
          return get(criteria, "price.buy.id", 0) === product.subgroup_id;
        } else if (buyType === "ProductCode") {
          return get(criteria, "price.buy.id", "") === product.id;
        } else if (buyType === "ProductShortCode") {
          return get(criteria, "price.buy.id", "") === product.id;
        }
      }
    }
    return false;
  });
  return myPromotions && myPromotions.id ? "YES" : "NO";
};

export const getUpdateData = (product, updateProduct, promotions) => {
  const data = product.map((item) => {
    const value = checkPromotion(item, promotions);
    // console.log(value, item);
    let orderAmt = 0;
    if (item.Order && item.Order > 0) {
      orderAmt = Number(item.Order * item.price).toFixed(2);
      // console.log(item.Order, item.Price * item.Order)
    }

    let myData = { ...item, Promo: value, OrderAmt: orderAmt };
    updateProduct.map((item) => {
      if (item.code === myData.code) {
        myData = item;
      }
      return "";
    });
    return myData;
  });
  // console.log(data);
  // const newData = concat(data, updateProduct)
  return data;
};

export const getTotalOrderNormal = (
  promotions,
  tPromoOrderQty,
  tPromoOrderAmt
) => {
  let totalOrderQty = tPromoOrderQty;
  let totalOrderAmt = tPromoOrderAmt;
  promotions.map((data) => {
    if (data.item && data.item !== null) {
      totalOrderQty = totalOrderQty + data.tPromoOrderQty;
      totalOrderAmt = totalOrderAmt + data.tPromoOrderAmt;
    }

    return "";
  });
  return {
    totalOrderQty,
    totalOrderAmt,
  };
};

export const getTotalOrderNormalDefault = (promotions) => {
  let totalOrderQty = 0;
  let totalOrderAmt = 0;
  promotions.map((data) => {
    // console.log(data, data.tPromoOrderQty)
    if (data.item === null) {
      totalOrderQty = totalOrderQty + data.tPromoOrderQty;
      totalOrderAmt = totalOrderAmt + data.tPromoOrderAmt;
    }
    return "";
  });
  return {
    totalOrderQty,
    totalOrderAmt,
  };
};

export const CheckTotalOrder = (promotions, item) => {
  const listTotal = filter(promotions, function (data) {
    return data.item && data.item !== null
      ? data.item.id === item.id &&
          data.item.promotion_type === item.promotion_type
      : false;
  });

  return listTotal.length === 0;
};

export const getTotalOrderPromo = (
  promotions,
  tPromoOrderQty,
  tPromoOrderAmt,
  item
) => {
  let totalOrderQty = 0;
  let totalOrderAmt = 0;
  promotions.map((data) => {
    if (
      data.item && data.item !== null
        ? data.item.id === item.id &&
          data.item.promotion_type === item.promotion_type
        : false
    ) {
      totalOrderQty = totalOrderQty + tPromoOrderQty;
      totalOrderAmt = totalOrderAmt + tPromoOrderAmt;
    } else {
      totalOrderQty = totalOrderQty + data.tPromoOrderQty;
      totalOrderAmt = totalOrderAmt + data.tPromoOrderAmt;
    }

    return "";
  });
  return {
    totalOrderQty,
    totalOrderAmt,
  };
};
