import React from "react";
import { filter } from "lodash";
import { Create, Loading } from "react-admin";
import { BetterCustomPromotionForm, FooterToolbar } from "./";
import FormFields from "./FormFields";
import { Flex } from "rebass";
import { useQuery } from "react-query";
import { getProducts } from "../../restClient";
import moment from "moment";
import momentTimeZone from "moment-timezone";
const tzNameSg = "Asia/Singapore";
const current = moment();
const year = current.format("YYYY");
const month = current.format("MM");
const endMonth = current.add(1, "month").format("MM");
const day = current.format("DD");
const endDay = current.add(1, "month").format("DD");
const startDefault = momentTimeZone.tz(
  `${year}-${month}-${day} 00:00:00`,
  tzNameSg
);
const endDefault = momentTimeZone.tz(
  `${year}-${endMonth}-${endDay} 23:59:59`,
  tzNameSg
);
// console.log(startDefault.utc().format());
// console.log(endDefault.utc().format());
const cacheTimeMinute = 1000 * 60 * 30;
const initValues = {
  type: "Dealer",
  need_coupon: false,
  promotion_type: "Buy X Get Y",
  apply_other_promotions: false,
  criteria: {
    buy_x_get_y: {
      x: {
        type: "ProductCode",
        id: "",
      },
      y: {
        type: "ProductCode",
        id: "",
      },
      moqs: [
        {
          moq: 0,
          buy: 0,
          get: 0,
          additional: 0,
        },
        {
          moq: 0,
          buy: 0,
          get: 0,
          additional: 0,
        },
        {
          moq: 0,
          buy: 0,
          get: 0,
          additional: 0,
        },
      ],
    },
    buy_ab_get_cd: {
      a: {
        type: "ProductCode",
        id: "",
      },
      b: {
        type: "ProductCode",
        id: "",
      },
      c: {
        type: "ProductCode",
        id: "",
      },
      d: {
        type: "ProductCode",
        id: "",
      },
      moqs: [
        {
          moq: 0,
          buy: 0,
          get: 0,
          additional: 0,
        },
        {
          moq: 0,
          buy: 0,
          get: 0,
          additional: 0,
        },
        {
          moq: 0,
          buy: 0,
          get: 0,
          additional: 0,
        },
      ],
    },
    discount: {
      buy: {
        type: "ProductCode",
        id: "",
      },
      moqs: [
        {
          moq: 0,
          discount: 0,
        },
        {
          moq: 0,
          discount: 0,
        },
        {
          moq: 0,
          discount: 0,
        },
      ],
    },
    price: {
      buy: {
        type: "ProductCode",
        id: "",
      },
      moqs: [
        {
          moq: 0,
          price: 0,
        },
        {
          moq: 0,
          price: 0,
        },
        {
          moq: 0,
          price: 0,
        },
      ],
    },
  },
  coupon: {
    auto_generate: true,
  },
  start_date: startDefault.utc().format(),
  end_date: endDefault.utc().format(),
};

const CreateDealerPromotion = (props) => {
  const { isLoading, data } = useQuery(
    "productsListNormal",
    () => getProducts(),
    { cacheTime: cacheTimeMinute }
  );
  if (isLoading) {
    return (
      <Flex width={[1]} justifyContent={"center"} alignItems={"center"}>
        <Loading />
      </Flex>
    );
  }
  const dataProducts = filter(data, function (o) {
    return o.active;
  });
  return (
    <Create {...props}>
      <BetterCustomPromotionForm
        {...props}
        toolbar={<FooterToolbar />}
        initialValues={initValues}
        sourceApi='promotions'
        title={"resources.promotions.title.new_promotion"}
        typePostUrl={"CREATE"}
        pathUrl='promotions'
        customBackUrl='/promotions'
        dataProducts={dataProducts}
      >
        <FormFields {...props} isCreate={true} dataProducts={dataProducts} />
      </BetterCustomPromotionForm>
    </Create>
  );
};

export default CreateDealerPromotion;
