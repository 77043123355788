import React, { Fragment } from "react"
// import ExpandMore from "@material-ui/icons/ExpandMore"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Typography from "@material-ui/core/Typography"
import Collapse from "@material-ui/core/Collapse"
import { withStyles } from "@material-ui/core/styles"
import { useTranslate } from "react-admin"
import { styles } from "./styles"

const SubMenuItemLink = props => {
  const {
    handleToggle,
    sidebarIsOpen,
    isOpen,
    name,
    icon,
    classes,
    children,
    location,
    page,
  } = props
  const translate = useTranslate()
  const styleItem =
    location && location.pathname === page ? classes.active : classes.listItem
  return (
    <Fragment>
      <ListItem dense button onClick={handleToggle} className={styleItem}>
        <ListItemIcon className={classes.listItemIconLink}>
          {isOpen ? icon : icon}
        </ListItemIcon>
        <ListItemText
          inset
          primary={
            isOpen ? (
              <Typography variant="body2">{translate(name)}</Typography>
            ) : (
              ""
            )
          }
          secondary={
            isOpen ? (
              ""
            ) : (
              <Typography variant="body2">{translate(name)}</Typography>
            )
          }
          className={classes.listItemTextLink}
        />
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          dense
          component="div"
          disablePadding
          className={
            sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
          }
        >
          {children}
        </List>
      </Collapse>
    </Fragment>
  )
}

export default withStyles(styles)(SubMenuItemLink)
