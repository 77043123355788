import React, { Children, cloneElement, isValidElement, useRef } from "react"
import PropTypes from "prop-types"

import get from "lodash/get"
import { withStyles } from "@material-ui/core/styles"
import FormHelperText from "@material-ui/core/FormHelperText"

import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import { useTranslate, ValidationError, FormInput } from "react-admin"
import { Flex, Box } from "rebass"
import { IconButton } from "@material-ui/core"
import { lightBlue } from "@material-ui/core/colors"
import {
  styles,
  StyledTableAction,
  StyledTableSmall,
  StyledTableHeaderRow,
  StyledTableRow,
} from "./styles"
import { TrashPOIcon, AddCircleIcon } from "../../components/icon_svg"

const SimpleFormIterator = (props) => {
  const {
    basePath,
    children,
    fields,
    meta: { error, submitFailed },
    record,
    resource,
    source,

    variant,
    margin,

    defaultValue,
    classes,
  } = props
  const translate = useTranslate()
  //disableAdd,
  //TransitionProps,
  // disableRemove,
  // We need a unique id for each field for a proper enter/exit animation
  // so we keep an internal map between the field position and an auto-increment id
  const nextId = useRef(
    fields && fields.length
      ? fields.length
      : defaultValue
      ? defaultValue.length
      : 0
  )

  // We check whether we have a defaultValue (which must be an array) before checking
  // the fields prop which will always be empty for a new record.
  // Without it, our ids wouldn't match the default value and we would get key warnings
  // on the CssTransition element inside our render method
  const ids = useRef(
    nextId.current > 0 ? Array.from(Array(nextId.current).keys()) : []
  )

  const removeField = (index) => () => {
    ids.current.splice(index, 1)
    fields.remove(index)
  }

  // Returns a boolean to indicate whether to disable the remove button for certain fields.
  // If disableRemove is a function, then call the function with the current record to
  // determining if the button should be disabled. Otherwise, use a boolean property that
  // enables or disables the button for all of the fields.
  // const disableRemoveField = (record, disableRemove) => {
  //   if (typeof disableRemove === "boolean") {
  //     return disableRemove
  //   }
  //   return disableRemove && disableRemove(record)
  // }

  const addField = () => {
    ids.current.push(nextId.current++)
    fields.push(defaultValue)
  }

  const records = get(record, source)
  return fields ? (
    <Flex width={[1]} flexDirection={"column"}>
      <Box width={["400px"]} py={[3]}>
        {submitFailed && typeof error !== "object" && error && (
          <FormHelperText error>
            <ValidationError error={error} />
          </FormHelperText>
        )}
        <TableContainer component={"div"}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <StyledTableHeaderRow>
                {Children.map(children, (input, index2) =>
                  isValidElement(input) ? (
                    <StyledTableSmall key={index2}>
                      {typeof input.props.label === "undefined"
                        ? input.props.source
                          ? translate(
                              `resources.${resource}.fields.${input.props.source}`
                            )
                          : undefined
                        : translate(input.props.label)}
                    </StyledTableSmall>
                  ) : null
                )}
                <StyledTableAction>
                  <IconButton onClick={addField}>
                    <AddCircleIcon
                      fontSize={"small"}
                      style={{
                        color: lightBlue[900],
                      }}
                    />
                  </IconButton>
                </StyledTableAction>
              </StyledTableHeaderRow>
            </TableHead>
            <TableBody>
              {fields.map((member, index) => (
                <StyledTableRow key={index}>
                  {Children.map(children, (input, index2) =>
                    isValidElement(input) ? (
                      <StyledTableSmall component="td" scope="row" key={index2}>
                        <FormInput
                          basePath={input.props.basePath || basePath}
                          input={cloneElement(input, {
                            source: input.props.source
                              ? `${member}.${input.props.source}`
                              : member,
                            index: input.props.source ? undefined : index2,
                            label: "",
                          })}
                          record={(records && records[index]) || {}}
                          resource={resource}
                          variant={variant}
                          margin={margin}
                        />
                      </StyledTableSmall>
                    ) : null
                  )}
                  <StyledTableAction component="td" scope="row">
                    <IconButton
                      className={classes.leftIcon1}
                      onClick={removeField(index)}
                      size="small"
                    >
                      <TrashPOIcon fontSize="inherit" />
                    </IconButton>
                  </StyledTableAction>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Flex>
  ) : null
}

SimpleFormIterator.defaultProps = {
  disableAdd: false,
  disableRemove: false,
}

SimpleFormIterator.propTypes = {
  defaultValue: PropTypes.any,
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  fields: PropTypes.object,
  meta: PropTypes.object,
  record: PropTypes.object,
  source: PropTypes.string,
  resource: PropTypes.string,
  translate: PropTypes.func,
  disableAdd: PropTypes.bool,
  disableRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  TransitionProps: PropTypes.shape({}),
}

// export default
export default withStyles(styles)(SimpleFormIterator)
